<div style="height: 25px;"></div>
<mat-drawer-container style = "background-color: white !important;">
    <mat-drawer-content style = "background-color: white !important;">
            <app-user-spinner style="z-index: 2000;" *ngIf="showSpinner || training == null || user == null || labels == null || progress == null || currentUnit == null">
                <mat-spinner class="spinner" [color] = "spinnerColor"></mat-spinner>
            </app-user-spinner>
        <div class="container d-sm-flex">
            <div class="container2">
                <div class = "goToTrainingDetailsNavy" *ngIf = "currentUnit.videoPath == null" (click) = "goToTrainingDetails(training)">
                    <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FbackIconNavy.png?alt=media&token=44cdf4cd-f8a1-45e7-a84b-cca8dfc95ca2" alt = "Back icon image">
                     <span style = "vertical-align: middle; padding-left: 11px;">{{training.name}}</span>
                 </div>
                <div class="imgCls" *ngIf = "(currentUnit.imagePath != null) && (showControl == false)" style="width: 100%; height: 70vh">
                    <img src={{imagePath}} alt = "unit image" style="margin: auto; width: 100%; height: 100%;  object-fit: contain;">
                </div>
                <div class="playerContainer"[class]="playerClass" *ngIf = "currentUnit.videoPath != null" style="position: relative; ">
                    <div class = "goToTrainingDetails" (click) = "goToTrainingDetails(training)">
                       <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FbackIconWhite.png?alt=media&token=c4a80eba-6c17-4e0d-80a2-03151a41afbf" alt = "Back icon image">
                        <span style = "vertical-align: middle; padding-left: 11px;">{{training.name}}</span>
                    </div>
                    <div class="arrowContainer" [class] = "pausePlayHover">
                        <div class={{arrowBackClass}} (click)="onPreviousVideo()" style = "vertical-align: middle;">
                            <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FprevVideoIconWhite.png?alt=media&token=a2671c41-8f96-4f0e-a9e6-377437e43ff0" alt = "Back arrow image">
                        </div>
                        <div class="playPuseBtn" (click)="playPauseVideo()" style = "vertical-align: middle;">
                            <mat-icon [inline]="true" style = "vertical-align: middle;">{{playPauseBtn}}</mat-icon>
                        </div>
                        <div class="arrowForward" (click)="onNextVideo()" style = "vertical-align: middle;">
                           <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextVideoIconWhite.png?alt=media&token=ef079dab-54f7-4b8c-a9c3-902674181004" alt = "Next arrow image">
                        </div>
                    </div>
                    <!-- da se disabl-uje speed rate i pic in pic: disablepictureinpicture; controlsList="nodownload noplaybackrate" -->
                    <video [class] = "videoTransparent" id="my-video" #player controls width="100%" height="100%" src={{videoPath}} type="video/mp4"
                        (ended)="onVideoFinished()" (play)="setPauseIcon()" (pause)="setPlayIcon()" controlsList="nodownload"
                        (seeking)="onSeeking()" (timeupdate)="onTimeUpdate()" (contextmenu)="onContextMenu($event)">
                    </video>
                </div>
                <div *ngIf = "currentUnit.audioPath != null" [class]="playerClass" style="margin-bottom: 15px; margin-top: 3%;">
                    <div style = "margin: auto;">
                        <div class = "col-12" style = "margin: auto; text-align: center;">
                            <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FaudioUnitIcon.png?alt=media&token=b1ffc238-d0fc-4ae9-b9f3-1a37a11fb333" alt = "Audio unit image">
                        </div>
                       <div class = "col-12" style = "margin: auto; text-align: center;">
                        <img style = "width: 40px; margin: 45px; cursor: pointer;" (click) = "onPreviousVideo()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FaudioPrev.png?alt=media&token=1e1d9af4-7066-4fde-9e0e-642f65567f1c" alt = "Previous unit image">
                        <img *ngIf = "audioPlayBtn" class = "audioPlayBtn" style = "cursor: pointer;" (click) = "playAudioUnit(currentUnit.audioPath)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FplayUnitIcon.png?alt=media&token=ed847482-343f-4c4c-a9bd-bbc810dd6fee" alt = "Play audio unit">
                        <mat-icon *ngIf = "audioPauseBtn" style = "width: 75px; height: 75px; vertical-align: middle; font-size: 75px;" (click) = "pauseAudioUnit()">pause</mat-icon>
                        <img style = "width: 40px; margin: 40px; cursor: pointer;" (click) = "onNextVideo3(1)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FaudioNext.png?alt=media&token=a0d46296-4605-452a-8c3c-5b4a826b536e" alt = "Next unit image">
                       </div>
                    </div>
                    <!-- <ngx-audio-player [playlist]="playlist" style="width: 100%" [displayTitle]="false" [autoPlay]="false"
                    muted="muted" [displayPlaylist]="false"
                    [displayDuration]="false"
                    [expanded]="true" (trackEnded)="onEnded($event)" [displayVolumeControls]="true" [displayRepeatControls]="true" 
                    [disablePositionSlider]="true"></ngx-audio-player>    -->
                </div>

                <div class = "row" *ngIf = "currentUnit.documentPath != null" [class] = "playerClass"  style="margin-bottom: 15px; margin-top: 3%;">
                    <div class = "col-12" style = "margin: auto; text-align: center;">
                        <a href="{{documentPath}}" (click) = "docDownloaded()" target="_blank">
                            <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocUnitIcon.png?alt=media&token=c8812cd7-ec47-4a67-b495-80a917a90c34" alt = "Document unit image">
                        </a>
                    </div>
                    <div class = "col-12" style = "margin: auto; text-align: center;">
                        <img style = "width: 40px; margin: 45px; cursor: pointer; margin-right: 85px;" (click) = "onPreviousVideo()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FaudioPrev.png?alt=media&token=1e1d9af4-7066-4fde-9e0e-642f65567f1c" alt = "Previous unit image">
                        <img style = "width: 40px; margin: 40px; cursor: pointer; margin-left: 85px;" (click) = "onNextVideo3(2)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FaudioNext.png?alt=media&token=a0d46296-4605-452a-8c3c-5b4a826b536e" alt = "Next unit image">
                       </div>
                </div>

                <div *ngIf = "currentUnit.text != null && currentUnit.videoPath == null && currentUnit.audioPath == null && currentUnit.imagePath == null && currentUnit.documentPath == null" [class] = "playerClass"  style="overflow-y: auto; margin: 3% auto 15px">
                   <div style = "height: 45vh; text-align: center;">
                    <div *ngIf="text">
                        <div *ngFor="let line of text.split('\n')">
                            <h5 style = "font-family: Lato, 'Alegreya'; font-size: 21px; color: #0E0D35; opacity: 0.5;">{{line}}</h5>
                          <br /> <!-- Add a line break after each line -->
                        </div>
                      </div>
                   </div>
                </div>

               <div class = "row" style = "width: 100%; margin: auto;">
                    <div class="progress" [style.width]="progress+'%'"></div>
               </div>

               <div class = "row">
                   <div class = "col-12" *ngIf = "showText && currentUnit.text != null && (currentUnit.videoPath != null || currentUnit.audioPath != null || currentUnit.imagePath != null || currentUnit.documentPath != null)">
                        <h5 style = "font-family: Lato, 'Alegreya'; font-size: 21px; color: #0E0D35; opacity: 0.5;">{{text}}</h5>
                   </div>
                    <div class = "col-12" style = "width: 100%;">
                        <div class = "sectionName font" style = "width: 100%;">
                            <span style="width: 90%; display: table-cell; padding: 15px; line-height: 30px; font-size: 33px; color: #0E0D35">
                                {{currentUnit.order}} {{currentUnit.name}}</span>
                                <span style = "width: 10%;">
                                    <mat-icon *ngIf = "!showText" (click) = "toggleUnitMessage()" style = "vertical-align: middle; cursor: pointer; color: #0E0D35;">expand_more</mat-icon>
                                    <mat-icon *ngIf = "showText" (click) = "toggleUnitMessage()" style = "vertical-align: middle; cursor: pointer; color: #0E0D35;">expand_less</mat-icon>
                                </span>
                    </div>
                    <div style = "width: 100%;">
                        <span style = "color: #0E0D35; font-size: 17px; font-weight: normal; margin-left: 35px;"> {{currentUnit.sectionName}}</span>
                    </div>
                    </div>
               </div>

               <!-- <mat-accordion>
                   <mat-expansion-panel  class = "expansionPanel2">
                       <mat-expansion-panel-header>
                           <mat-expansion-title>
                                  <div class = "sectionName font" style = "width: 100%;">
                                    <span style="display:table-cell; padding: 15px; line-height: 30px; font-size: 33px; color: #0E0D35">
                                        {{currentUnit.order}} {{currentUnit.name}}</span>
                                  </div>
                                    <div style = "width: 100%;">
                                        <span style = "color: #0E0D35; font-size: 17px; font-weight: normal; margin-left: 35px;"> {{currentUnit.sectionName}}</span>
                                    </div>
                           </mat-expansion-title>
                       </mat-expansion-panel-header>
                       <div style = "margin-top: 3%;" *ngIf = "currentUnit.text != null" [class]="playerClass">
                           <p style="font-family: Lato, 'Alegreya Sans'; color: #0E0D35; padding: 11px; font-size: 2.3vh; opacity: 0.5; word-break: break-word;">{{text}}</p>
                       </div>
                   </mat-expansion-panel>
               </mat-accordion> -->

                <!-- <mat-accordion class="example-headers-align">
                    <mat-expansion-panel style="margin-top: 10px; padding-top: 10px;" class = "expansionPanel2">
                        <mat-expansion-panel-header style = "padding-left: 0px;">
                            <mat-panel-title>
                                <div class = "row" class = "sectionName font">
                                    <div class = "col-12">
                                        <span style="display:table-cell; padding: 15px; line-height: 30px; font-size: 33px; color: #0E0D35">
                                        {{currentUnit.order}} {{currentUnit.name}}</span>
                                    </div>
                                    <div class = "col-12">
                                        <span style = "color: #0E0D35; font-size: 17px; font-weight: normal; margin-left: 35px;"> {{currentUnit.sectionName}}</span>
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf = "currentUnit.text != null" [class]="playerClass" style="margin-bottom: 15px;">
                            <div class="col-12 textUnit" style="width: 100%; height: 100%;font-family: Lato, 'Alegreya Sans'; color: #0E0D35; padding: 11px; font-size: 2.3vh; opacity: 0.5;"><p>{{text}}</p></div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion> -->
        
                <!-- WEB ROW -->
                <div class = "row webVisible" style = "padding-top: 3%; padding-bottom: 3%; border-top: 1px solid #e6e6e6; border-bottom: 1px solid #e6e6e6;">
                    <div class = "col-3">
                       <div class = "row">
                        <div class = "col-12" style = "display: flex; margin-top: 10px; text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: normal; font-size: 16px; ">
                            <div>
                                <img style = "width: 50px; border-radius: 11px;" src = {{trainer.profileImagePath}} alt = "Trainer profile image"> 
                            </div>
                           <div style = "padding-left: 10px; word-break: break-all; font-size: 16px;font-family: Lato, 'Alegreya Sans'; text-align: left;">
                            {{trainer.firstName}} {{trainer.lastName}}<br>
                            <!-- <span style = "font-size: 14px; color: #799ACF">{{trainer.email}}</span> -->
                           </div>
                        </div>
                       </div>
                    </div>
                    <div class = "col-6">
                        <div class = "row">
                            <div class = "col-3" style = "background: #FFFFFF;
                               -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                                (click)="whatToDisplay('training')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGrey.png?alt=media&token=67aa1708-b907-419c-b1e8-c51cb7d0c1f5" alt = "Training icon">
                                        <img style = "width: 20px" *ngIf = "trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGreen.png?alt=media&token=64708943-302a-4b54-8138-72ecfc358422" alt = "Training icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <h5 [style.color] = "color3" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblTraining}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class = "col-3" style = "background: #FFFFFF;
                               -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                            (click)="whatToDisplay('comments')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGrey.png?alt=media&token=5ecdfd13-50ee-4818-9c02-f42ffbc86553" alt = "Comment icon">
                                        <img style = "width: 20px" *ngIf = "clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGreen.png?alt=media&token=04fc5e1c-1b22-4058-a596-2e68720f61e6" alt = "Comment icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <h5 [style.color] = "color1" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblComments}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class = "col-3" style = "background: #FFFFFF;
                            -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                            border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                            (click)="whatToDisplay('documents')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGrey.png?alt=media&token=343bf774-b065-40a1-a0fe-d16055d6340f" alt = "Document icon">
                                        <img style = "width: 20px" *ngIf = "clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGreen.png?alt=media&token=84ef2556-2cb3-4e92-a628-da016cf00441" alt = "Document icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <h5 [style.color] = "color2" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblDocuments}}</h5>
                                    </div>
                                </div>
                            </div>
                           </div>
                    </div>
                    <div class = "col-3" style = "text-align: right;">
                        <div style = "display: inline-block; padding-right: 50px;">
                            <img style = "width: 24px; margin-top: 20px;" *ngIf = "currentUnit.order == 1" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FprevIconGrey.png?alt=media&token=2d2224a2-6607-4ba9-b2e9-14a0410e2d4c" alt = "Previous icon">
                            <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "currentUnit.order != 1" (click) = "onPreviousVideo()"  src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FprevIconNavy.png?alt=media&token=16b61700-3796-4a97-8323-4e19b315de25" alt = "Previous icon">
                        </div>
                       <div style = "display: inline-block;">
                            <img style = "cursor: pointer; margin-top: 20px;"  *ngIf = "currentUnit.videoPath != null" (click) = "onNextVideo()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                            <img style = "cursor: pointer; margin-top: 20px;"  *ngIf = "currentUnit.imagePath != null" (click) = "onNextVideo2()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                            <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "currentUnit.audioPath != null" (click) = "onNextVideo3(1)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                            <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "currentUnit.documentPath != null" (click) = "onNextVideo3(2)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                            <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "(currentUnit.text != null) && (currentUnit.videoPath == null) && (currentUnit.audioPath == null) && (currentUnit.imagePath == null) && (currentUnit.documentPath == null)" (click) = "onNextVideo2()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                        </div>
                    </div>
                </div>
        
                <!-- MOB ROW -->
                <div class = "row mobVisible" style = "margin-top: 3%;">
                    <div class = "col-6">
                       <div class = "row">
                        <div class = "col-12" style = "display: flex; margin-top: 10px; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: normal; font-size: 16px; ">
                            <div>
                                <img style = "width: 50px; border-radius: 11px;" src = {{trainer.profileImagePath}} alt = "Trainer profile image"> 
                            </div>
                           <div style = "padding-left: 10px; word-break: break-all; font-size: 16px;font-family: Lato, 'Alegreya Sans'; text-align: left;">
                            {{trainer.firstName}} {{trainer.lastName}}<br>
                            <!-- <span style = "font-size: 14px; color: #799ACF">{{trainer.email}}</span> -->
                           </div>
                        </div>
                       </div>
                    </div>
                    <div class = "col-6" style = "text-align: right;">
                        <div style = "display: inline-block; padding-right: 50px;">
                            <img style = "width: 24px; margin-top: 20px;" *ngIf = "currentUnit.order == 1" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FprevIconGrey.png?alt=media&token=2d2224a2-6607-4ba9-b2e9-14a0410e2d4c" alt = "Previous icon">
                            <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "currentUnit.order != 1" (click) = "onPreviousVideo()"  src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FprevIconNavy.png?alt=media&token=16b61700-3796-4a97-8323-4e19b315de25" alt = "Previous icon">
                        </div>
                       <div style = "display: inline-block;">
                        <img style = "cursor: pointer; margin-top: 20px;"  *ngIf = "currentUnit.videoPath != null" (click) = "onNextVideo()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                        <img style = "cursor: pointer; margin-top: 20px;"  *ngIf = "currentUnit.imagePath != null" (click) = "onNextVideo2()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                        <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "currentUnit.audioPath != null" (click) = "onNextVideo3(1)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                        <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "currentUnit.documentPath != null" (click) = "onNextVideo3(2)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                        <img style = "cursor: pointer; margin-top: 20px;" *ngIf = "(currentUnit.text != null) && (currentUnit.videoPath == null) && (currentUnit.audioPath == null) && (currentUnit.imagePath == null) && (currentUnit.documentPath == null)" (click) = "onNextVideo2()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnextIconNavy.png?alt=media&token=7da956c7-8be3-4b01-902f-e45c34804430" alt = "Next icon">
                       </div>
                    </div>
                    <div class = "col-12" style = "margin-top: 5%;">
                        <div class = "row">
                            <div class = "col-3" style = "background: #FFFFFF;
                               -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                                (click)="whatToDisplay('training')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGrey.png?alt=media&token=67aa1708-b907-419c-b1e8-c51cb7d0c1f5" alt = "Training icon">
                                        <img style = "width: 20px" *ngIf = "trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGreen.png?alt=media&token=64708943-302a-4b54-8138-72ecfc358422" alt = "Training icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <h5 [style.color] = "color3" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblTraining}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class = "col-3" style = "background: #FFFFFF;
                               -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                            (click)="whatToDisplay('comments')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGrey.png?alt=media&token=5ecdfd13-50ee-4818-9c02-f42ffbc86553" alt = "Comment icon">
                                        <img style = "width: 20px" *ngIf = "clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGreen.png?alt=media&token=04fc5e1c-1b22-4058-a596-2e68720f61e6" alt = "Comment icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <h5 [style.color] = "color1" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblComments}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class = "col-3" style = "background: #FFFFFF;
                           -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                            border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                            (click)="whatToDisplay(currentUnit.documentPath == null ? 'documents' : 'docUnit')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGrey.png?alt=media&token=343bf774-b065-40a1-a0fe-d16055d6340f" alt = "Document icon">
                                        <img style = "width: 20px" *ngIf = "clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGreen.png?alt=media&token=84ef2556-2cb3-4e92-a628-da016cf00441" alt = "Document icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <h5 [style.color] = "color2" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblDocuments}}</h5>
                                    </div>
                                </div>
                            </div>
                           </div>
                    </div>
                </div>
        
            <!-- KOMENTARI -->
            <div [style.display]="displayComments" class = "row" style = "width: 100%; margin: auto; margin-top: 3%; padding-top: 25px;">
                <div style="width: 100%; margin: auto; margin-bottom: 15px;">
                    <div style="width: 100%; margin: auto; background: #FFFFFF;
                   -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                    border-radius: 16px;font-family: Lato, 'Alegreya Sans' !important;">
                        <form (ngSubmit)="submitComment()" #insertForm="ngForm" style="width: 100%; margin: auto">
                           <div style = "width: 90%; display: inline-block;">
                            <input matInput [ngModel]="user.id" name="userId" hidden>
                            <input matInput [ngModel]="user.firstName" name="firstName" hidden>
                            <input matInput [ngModel]="user.lastName" name="lastName" hidden>
                            <input matInput [ngModel]="user.email" name="email" hidden>
                            <mat-form-field appearance="none" style="width:100%; margin: auto; color: #0E0D35 !important;">
                                <textarea matInput ngModel name="text" placeholder="{{labels.lblComment}}" style="font-size: 17px; color: #0E0D35 !important; font-family: Lato, 'Alegreya Sans' !important; padding-top: 5px !important;
                                padding-left: 10px !important;"></textarea>
                            </mat-form-field>
                           </div>
                           <div style = "width: 10%; display: inline-block; text-align: center">
                            <img style = "cursor: pointer; padding-bottom: 20px;" (click) = "submitComment()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FsendComment.png?alt=media&token=93006d25-1513-4601-bbd1-3e5d8f86f34f" alt = "Send comment icon">
                           </div>
                        </form>
                    </div>
                    <div class="comments" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                        <div class="comment font" *ngFor="let c of comments">
                            <img class="commentImg" style = "width: 50px; margin-top: 15px;" src={{c.imagePath}} alt="comment image">
                            <span class="headerText" style="display: inline-block; top: 4px; vertical-align: middle;">
                            <div>
                              <div class = "col-12" style = "top: 5px;">
                                {{c.fullName}}
                              </div>
                              <div class = "col-12" style = "top: 3px;">
                                <span style = "color: #799ACF;font-family: Lato, 'Alegreya Sans'; font-size: 13.5px;">{{c.email}}</span>
                              </div>
                            </div>
                            </span>
                            <span style="float: right; margin: 7px; font-size: 12.5px">{{c.time}}</span><br>
                           
                            <div style="margin: 10px; top: -11px; font-size: 15.5px; color: #0E0D35; opacity: 0.5;">{{c.text}}</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- DOKUMENTI -->
            <div class = "row" [style.display]="displayDocuments" style = "width: 100%; margin: auto; margin-top: 3%; padding-top: 25px;">
                <div class="documents">
                    <div class="row" style = "margin: auto;">
                        <div class = "col-12 col-sm-6" style = "margin-top: 3%;">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel class="expansionPanel" style = "padding: 0px;">
                                    <mat-expansion-panel-header style = "padding: 15px;">
                                        <div class="font1"><span style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                           {{labels.lblAdminDocs}}</span></div>
                                    </mat-expansion-panel-header>
                                    <div class="document" *ngIf="canDownloadTest && (training.testResults == true)">
                                        <div class="documentDiv">
                                            <span><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "attachment icon"></span>
                                            <span class="aligned-with-icon font headerText"> {{labels.lblTestResults}}</span>
                                        </div>
                                        <button mat-button class="buttonDocument"
                                            (click)="downloadTestResults(training.id)"><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></button>
                                    </div>
                                    <div class="document" *ngIf="canDownloadSurvey && (training.surveyResults == true)">
                                        <div class="documentDiv">
                                            <span><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "attachment icon"></span>
                                            <span class="aligned-with-icon font headerText"> {{labels.lblSurveyResults}} </span>
                                        </div>
                                        <button mat-button class="buttonDocument"
                                            (click)="downloadSurveyResults(training.id)"><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "doc icon"></button>
                                    </div>
                                    <div class="document" *ngIf="canDownloadStatement && (training.statement == true)">
                                        <div class="documentDiv">
                                            <span><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "attachment icon"></span>
                                            <span class="aligned-with-icon font headerText"> {{labels.lblStatement}} </span>
                                        </div>
                                        <button mat-button class="buttonDocument"
                                            (click)="downloadStatement(training.id)"><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "doc icon"></button>
                                    </div>
                                    <div style = "margin-top: 15px; margin-left: 7px;" *ngIf = "(canDownloadStatement == false) && (canDownloadSurvey == false) && (canDownloadTest == false)">
                                        <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}} </span>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class = "col-12 col-sm-6" style = "margin-top: 3%;">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel class="expansionPanel" style = "padding: 0px;">
                                    <mat-expansion-panel-header style = "padding: 15px;">
                                        <div class="font1"><span style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                            {{labels.lblTrainingDocs}}</span></div>
                                    </mat-expansion-panel-header>
                    
                                    <div class="document" *ngFor="let document of trainingDocuments">
                                        <div class="documentDiv">
                                            <!-- <mat-icon style="color: #0E0D35; vertical-align: middle;"> attach_file</mat-icon> -->
                                            <span><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "attachment icon"></span>
                                            <span class="aligned-with-icon font headerText1"> {{document.sectionName}}/{{document.unitName}} </span>
                                        </div>
                                       <a class="link" target="_blank" href={{document.docPath}}
                                                cdkFocusInitial><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></a>
                                    </div>
                                    <div style = "margin-top: 15px; margin-left: 7px;" *ngIf = "trainingDocuments.length == 0">
                                        <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}} </span>
                                    </div>
                    
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class = "col-12 col-sm-6" style = "margin-top: 3%;">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel class="expansionPanel" style = "padding: 0px;">
                                    <mat-expansion-panel-header style = "padding: 15px;">
                                        <div class="font1"><span style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                            {{labels.lblAdditionalDocs}}</span></div>
                                    </mat-expansion-panel-header>
                    
                                    <div>
                                        <div class="document" *ngFor="let document of userDocuments">
                                            <div class="documentDiv">
                                                <span><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "attachment icon"></span>
                                                <span class="aligned-with-icon font headerText"> {{document.name}} </span>
                                            </div>
                                            <a class="link" target="_blank" href={{document.path}}
                                                cdkFocusInitial><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "doc icon"></a>
                                        </div>
                                        <div style = "margin-top: 15px; margin-left: 7px;" *ngIf = "userDocuments.length == 0">
                                            <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}} </span>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                    
                </div>
            </div>
        
            <!-- DOKUMENT LEKCIJA -->
            <!-- <div [style.display]="displayDocumentsUnit" *ngIf = "currentUnit.documentPath != null" style = "margin-top: 5%; border-bottom: none;">
                <div class="document docUnit" style = "border-bottom: none;">
                    <div class="documentDiv" style = "border-bottom: none;">
                        <span><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58"></span>
                        <span class="aligned-with-icon font headerText"> {{labels.lblDocument}} </span>
                    </div>
                    <a class="link" target="_blank" href="{{documentPath}}" (click) = "docDownloaded()"
                        cdkFocusInitial><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247"></a>
                </div>
            </div>
         -->
            <!-- OBLASTI -->
            <div class = "row" style = "width: 100%; margin: auto; margin-top: 3%; padding-top: 25px;">
                <div style = "width: 100%;"  [style.display]="displaySections">
                    <div class = "col-12 col-sm-6" style = "padding: 0px;" *ngFor="let section of training.sections">
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel style="width: 95%; margin: auto; margin-top: 10px; padding-top: 5px; padding-bottom: 5px;" class = "expansionPanel">
                                <mat-expansion-panel-header>
                                    <div class="sectionName font"><span
                                            style="display:table-cell; padding-left: 10px; line-height: 30px; padding: 10px;">
                                            {{section.name}}</span></div>
                                </mat-expansion-panel-header>
                                <div *ngFor="let unit of section.units" [class.current]="isCurrent(unit.id)"
                                    class="unitName font" (click)="onUnitClick(unit)" style = "padding: 15px;">
                                    <span [class.navyColor] = "getIconClass(unit.id)" [class.greyColor] = "!getIconClass(unit.id)"
                                        style="width: 80%; vertical-align: middle; margin: 10px; float: left;">
                                        <span style = "vertical-align: bottom;">
                                            <img style = "width: 20px;" *ngIf = "getIconClass(unit.id)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FplayCircleIcon.png?alt=media&token=f5bd6607-1f38-4c64-b713-701237a7ef9a" alt = "unit icon">
                                            <img style = "width: 20px;" *ngIf = "!getIconClass(unit.id)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FlockIcon.png?alt=media&token=9a367470-524e-4ab5-8552-b51c727d8e08" alt = "unit icon">
                                        </span>
                                        {{unit.name}}</span>
                                    <span style = "margin: 10px; float: right;">
                                        <img style = "width: 20px;" *ngIf = "getIconClass(unit.id)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FunitIconNavy.png?alt=media&token=d93e2985-cb16-4d00-a52f-7d8b1d2e38be" alt = "unit icon">
                                        <img style = "width: 20px;" *ngIf = "!getIconClass(unit.id)" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FunitIconGrey.png?alt=media&token=3a51073e-a1e2-4208-b7ff-2f9a1f9ec89b" alt = "unit icon">
                                    </span>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
            </div>
        </div>
        
        <!-- mobile -->
        <div style = "display: none;">
            <div class="row" style="width: 100%; background-color: rgb(244, 248, 249); margin-left: 0px;">
                <div class="col-12">
                    <h2 style="margin-top: 15px; margin-bottom: 15px; float: left; color: black;font-family: Lato, 'Alegreya Sans';">
                        {{currentUnit.name}}</h2>
                    <h2 *ngIf = "showControl == true" style="margin-top: 15px; margin-bottom: 15px; float: left; color: black;font-family: Lato, 'Alegreya Sans';">
                        {{labels.lblControlQuestion}}</h2>
                </div>
            </div>
            <div>
                <div [style.background-color] = "backgroundColor" style="height:30%; text-align: center;">
                    <div style="width:100%; float: left; text-align: left; padding-left: 25px;">
                        <img *ngIf = "user.companyId == 1" src={{labels.backBtnPath1}}
                        style="width: 100px; height: 90px; cursor: pointer;" (click)="goToTrainingDetails(training)" alt = "back button icon">
                        <img *ngIf = "user.companyId != 1" src={{labels.backBtnPath}}
                        style="width: 100px; height: 90px; cursor: pointer;" alt = "Back button icon" (click)="goToTrainingDetails(training)"></div>
                    <div class="trainingName font">{{training.name}}</div>
                    <div class="progresValue font">{{progress}}% {{labels.lblCompleted}}</div>
                </div>
                <div class="progress" [style.width]="progress+'%'"></div>
            </div>
            <div>
                <div class="testQuestion" [class]="controlClass" *ngIf="showControl == true">
                    <h2 class="questionText">
                        {{currentUnit.control_question.text}}
                    </h2>
                    <div class="answer" *ngFor="let answer of currentUnit.control_question.answers"
                        [class.answerSelected]='answer.selected'>
                        <mat-checkbox [value]="answer" class="checkBox" [(ngModel)]="answer.selected"
                            (change)="onSelect(currentUnit.control_question, answer);">
                            {{answer.textAns1}}
                        </mat-checkbox>
                    </div>
                    <div>
                        <button mat-raised-button *ngIf="checked == true" class="button"
                            (click)="submit(currentUnit.control_question)" style="float: right;">
                            {{labels.lblSubmit}}
                        </button>
                    </div>
                </div>
                <div *ngIf = "(currentUnit.imagePath != null) && (showControl == false)" style="width: 100%; height: 50vh">
                    <div class="cont1" style="position:absolute;">
                        <!-- <div  (click)="onPreviousVideo()">
                            <mat-icon [inline]="true">arrow_back_ios</mat-icon>
                        </div> -->
                        <!-- <div class="playPuseBtn" (click)="videoShow(currentUnit)"> -->
                            <!-- <mat-icon [inline]="true">{{playPauseBtn}}</mat-icon> -->
                            <!-- <img class="continueBtn2" style="padding-bottom: 25px;" src={{labels.playButton}}> -->
                        <!-- </div> -->
                        <!-- <div class="arrowForward" (click)="onNextVideo()">
                            <mat-icon [inline]="true">arrow_forward_ios</mat-icon>
                        </div> -->
                    </div>
                    <img src={{imagePath}} style="margin: auto; width: 100%; height: 100%;  object-fit: contain;" alt = "Image">
                </div>
                <div class="mobPlayerContainer" [class]="playerClass" *ngIf = "currentUnit.videoPath != null" style="position: relative; margin-top:3vh;" (click)="playPauseVideo()">
                    <div class="arrowContainer" *ngIf = "showVideo == true">
                        <div class={{arrowBackClass}} (click)="onPreviousVideo()">
                            <mat-icon [inline]="true">arrow_back_ios</mat-icon>
                        </div>
                        <!-- <div class="playPuseBtn" (click)="playPauseVideo()">
                            <img class="continueBtn1" style="padding-bottom: 25px;" src={{labels.playButton}}>
                        </div> -->
                        <div class="arrowForward" (click)="onNextVideo()">
                            <mat-icon [inline]="true">arrow_forward_ios</mat-icon>
                        </div>
                    </div>
                    <video id="my-video"#player controls width="100%" height="100%" src={{videoPath}} type="video/mp4"
                    (ended)="onVideoFinished()" (play)="setPauseIcon()" (pause)="setPlayIcon()" controlsList="nodownload"
                    (seeking)="onSeeking()" (timeupdate)="onTimeUpdate()">
                    </video>
                </div>
                <div *ngIf = "currentUnit.audioPath != null" style="margin-bottom: 15px;">
                    <ngx-audio-player [playlist]="playlist" style="width: 100%" [displayTitle]="false" [autoPlay]="false"
                    muted="muted" [displayPlaylist]="false"
                    [displayDuration]="false"
                    [expanded]="true" (trackEnded)="onEnded($event)" [displayVolumeControls]="true" [displayRepeatControls]="true" 
                    [disablePositionSlider]="true"></ngx-audio-player>   
                </div>
                <div class="row">
                    <div class="col-11" *ngIf = "currentUnit.documentPath != null" style="width: 100%; height: 100%;font-family: Lato, 'Alegreya Sans'; padding: 11px; padding-bottom: 0px;">
                        <a class="document" href="{{documentPath}}" target="_blank"><mat-icon style="vertical-align: middle;">file_download</mat-icon>Dokument</a>
                    </div>
                </div>
                <div *ngIf = "currentUnit.text != null" style="margin-bottom: 15px;">
                    <div class="textUnit" style="width: 100%; height: 100%;font-family: Lato, 'Alegreya Sans'; padding: 11px; padding-left: 20px;">{{text}}</div>
                </div>
                
                
                <div class="row" *ngIf = "(showControl == false) && (currentUnit.videoPath == null)" style="width: 100%; margin-top: 5%;">
                    <div class="col-6" style="padding-left: 3%;">
                        <button (click) = "onPreviousVideo()" style="padding: 7px; font-family: 'Poppins'; color: rgb(241,106,100); background-color: transparent; border: 1px solid rgb(241,106,100); border-radius: 5px; width: 110px;">{{labels.lblBack}}</button>
                    </div>
                    <div class="col-6" style="text-align: right; padding-right: 3%;" *ngIf = "(currentUnit.audioPath == null) && (currentUnit.documentPath == null)">
                        <button [class] = "buttons" (click) = "onNextVideo2()" style="padding: 7px; font-family: 'Poppins'; border-radius: 5px; width: 110px;">{{labels.lblContinue}}</button>
                    </div>
                    <div class="col-6" style="text-align: right; padding-right: 3%;" *ngIf = "currentUnit.audioPath != null">
                        <button [class] = "buttons" (click) = "onNextVideo3(1)" style="padding: 7px; font-family: 'Poppins'; border-radius: 5px; width: 110px;">{{labels.lblContinue}}</button>
                    </div>
                    <div class="col-6" style="text-align: right; padding-right: 3%;" *ngIf = "currentUnit.documentPath != null">
                        <button [class] = "buttons" (click) = "onNextVideo3(2)" style="padding: 7px; font-family: 'Poppins'; border-radius: 5px; width: 110px;">{{labels.lblContinue}}</button>
                    </div>
                </div>
                <div class="mobUnits">
                    <div *ngIf = "status == 3" style = "width: 99%; text-align: center; margin: auto">
                        <button class="button" style = "width: 100%; text-align: center; padding: 10px; margin-top: 10px; font-size: 3.7vh; border: 1px solid rgb(241,106,100); border-radius: 3px;" (click) = "openTestDialog()">{{labels.lblTest}}</button>
                    </div>
                    <div *ngFor="let section of training.sections" class="section">
                        <mat-accordion class="example-headers-align" multi>
                            <mat-expansion-panel style="padding-top: 10px; width: 100%;">
                                <mat-expansion-panel-header style="height: 100% !important; width: 100%; margin-top: 15px;">
                                    <div class="sectionName font"><span
                                            style="display:table-cell; vertical-align: middle; padding-left: 10px; line-height: 33px;">{{section.order}}.
                                            {{section.name}}</span></div>
                                </mat-expansion-panel-header>
                                <div *ngFor="let unit of section.units" [class.current]="isCurrent(unit.id)"
                                    class="unitName font" (click)="onUnitClick(unit)">
                                    <span
                                        style="display:table-cell; vertical-align: middle; padding-left: 10px;"><span>{{section.order}}.{{unit.order}}.</span>
                                        {{unit.name}}</span>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="cardsContainer col-xs-12" style="margin-top:3vh; padding-left:0 !important;">
                    <app-user-cards></app-user-cards>
                </div>
            </div>
        </div>
        
        <script src="https://vjs.zencdn.net/7.8.4/video.js"></script>
    </mat-drawer-content>
</mat-drawer-container>