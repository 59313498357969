<app-admin-spinner *ngIf="dataSource == null || labels == null || test == null || question == null"></app-admin-spinner>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxFlex="5"></div>
    <div fxFlex="90">
        <!-- <mat-card cdkDrag>
            <div fxLayout="row" fxFlex='100'>
                <mat-card-title fxFlex="90">Answers for question</mat-card-title>
                  <mat-card-content fxFlex="40" fxFlex.sm="60" fxFlex.xs="80">
                    <button class="tableBtn" mat-raised-button (click)="insertDialog()" style="margin-right: 1vw;">
                      Add new answer
                    </button>
                    <button class="tableBtn" mat-raised-button (click)="showChangeButton()" *ngIf='value===true'>
                      Change order      
                    </button>
                    <button class="tableBtn" mat-raised-button  (click)="changeOrder()" *ngIf='value===false'>
                      Save this order      
                    </button>
                  </mat-card-content>
              </div>
        </mat-card> -->
        <div class="mat-elevation-z8">
          <div class="row header">
            <div class="col-xl-6 text-left">
              <div class="row">
                  <div class="col-12">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb" style="background-color: #182038; font-size: 15.3px;">
                          <li class="breadcrumb-item"><a style="color: #3e4b71" routerLinkActive="list-item-active"
                                  routerLink="/admin/tests"><mat-icon>home</mat-icon></a></li>
                          <li class="breadcrumb-item"><a style="color: #6678ab"
                            [routerLink]="['/admin/test-details',test.id]">{{test.name}}</a>
                          </li>
                          <li><span
                            class = "spn" style="margin-left: 10px; margin-right: 10px; color: #8293c6">></span><a style="color: #8293c6"  [routerLink]="['/admin/tests',test.id]">{{labels.lblQuestions}}</a></li>
                          <li aria-current="page" style="color: #9dacd9"><span
                                  style="margin-left: 10px; margin-right: 10px;">></span>{{labels.lblAnswers}}</li>
                      </ol>
                  </nav>
                  </div>
              </div>
             
          </div>
            <div class="col-xl-6 text-right" style="font-size: 15.5px; margin-right: 0px;">
              <mat-checkbox [ngModel]="question.random" class="example-margin checkbox" (change)="changeRandomAnswers()"
              [ngClass]="{'disabled-btn': (value1 == true) ||(assigned.length > 0)}">
              <span class="hideColumn">{{labels.lblRandom}}</span>
            </mat-checkbox>
            <button mat-raised-button class="tableBtn hideColumn" (click)="showChangeButtonAnswers()"
              *ngIf='value1===true' [disabled] = "assigned.length > 0" style="margin-right: 5px;">
              {{labels.lblChangeOrder}}
            </button>
            <button mat-raised-button class="tableBtn hideColumn" [disabled] = "assigned.length > 0" (click)="changeOrderAnswers()"
              *ngIf='value1===false' style="margin-right: 10px;">
              {{labels.lblSaveOrder}}
            </button>
          <button class="tableBtn" mat-raised-button [disabled] = "assigned.length > 0" (click)="insertDialog()" [disabled] = "assigned.length > 0" style="margin-right: 1vw; margin-top: 7px;">
            {{labels.addAnswer}}
          </button>
          </div>
          <div class="col-12">
            <hr class="hrline" style="width: 100%;">
            <h3><span style="color: #8293c6;">{{labels.lblQuestion}}:</span> {{question.text}}</h3>
          </div>
          <!-- <div class="col-12 text-left">
            <mat-form-field class="search" appearance="none" fxFlex="40%">
              <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
            </mat-form-field>
        </div> -->
          </div>
          <table matSortDisableClear="true" mat-table [dataSource]="dataSource" matSort cdkDropList [cdkDropListData]="dataSource"
          (cdkDropListDropped)="drop1($event)" #table>
            <ng-container matColumnDef="order">
              <th style="width: 10%" mat-header-cell *matHeaderCellDef mat-sort-header><span class="order">{{labels.lblOrder}}</span><span class="order1">{{labels.lblO}}</span></th>
              <td style="width: 10%" mat-cell *matCellDef="let answer">
                <mat-icon *ngIf="!question.random && !value1" style="cursor: pointer;" class="settings">
                  import_export
                </mat-icon> <span>{{answer.order}}. </span>
                <span *ngIf="question.random">R</span>
              </td>
            </ng-container>
              <!-- name Column -->
              <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblAnswerText}} </th>
                <td data-toggle="tooltip" data-placement="top" title={{answer.text}} mat-cell *matCellDef="let answer">
                  {{answer.text.substring(0,40)}} <span *ngIf = "answer.text.length > 40">...</span>
                </td>
              </ng-container>
                        <!-- email Column -->
            <ng-container matColumnDef="correct" *ngIf = "test.type != 3">
              <th style="width: 20%" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td style="width: 20%;" mat-cell *matCellDef="let answer">
                <mat-slide-toggle [checked]="answer.correct" (click)="changeStatus(answer)"
                [ngClass]="{'disabled-btn': (isDisabled(answer)) || (assigned.length > 0)}">
                  <span class="hideColumn" *ngIf="answer.correct==true">{{labels.lblCorrect}}</span>
                  <span class="hideColumn" *ngIf="answer.correct==false">{{labels.lblNotCorrect}}</span>
                </mat-slide-toggle>

              </td>
            </ng-container>
            <!-- parentId Column -->

              <!--Actions column-->
              <ng-container matColumnDef="actions">
                <th style="width: 15%;" mat-header-cell *matHeaderCellDef class="thActions"> {{labels.lblOptions}} </th>
                <td style="width: 15%" mat-cell *matCellDef="let answer" class="tdActions">
                  <div class="row d-sm-flex" style="margin: auto; padding: auto">
                    <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu"
                      style="vertical-align: middle !important; margin:auto; margin-left: 15px;">settings</mat-icon>
                    <mat-menu #settingsMenu="matMenu" class="mMenu">
                      <button mat-menu-item class="menuButton settingsMenuItem" (click)="editDialog(answer)">
                        <mat-icon style="color: #8293c6;">edit</mat-icon>
                        <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                      </button>
                      <button mat-menu-item class="menuButton settingsMenuItem"  [ngClass]="{'disabled-btn': assigned.length > 0}" (click)="deleteDialog(answer)">
                        <mat-icon style="color: #8293c6;">delete</mat-icon>
                        <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                      </button>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr 
              mat-row 
              *matRowDef="let row; columns: displayedColumns;"
              cdkDrag
              [cdkDragDisabled]="value1"
              [cdkDragData]="row"
              ></tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
          </div>
    </div>
    <div fxFlex="5"></div>
</div>

<!-- <app-admin-test-question></app-admin-test-question> -->