<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblAddAdministrator}}</h1>
<div mat-dialog-content style="overflow: unset;">
  <form (ngSubmit)="onSubmit()" #insertForm="ngForm" style="height: 500px; overflow-y: unset;">
    <div fxLayout="column">
      <mat-form-field appearance="none" fxFlex="60%">
        <mat-label>{{labels.lblFirstName}}</mat-label>
        <input matInput ngModel name="firstName" required>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="none" fxFlex="50%">
        <mat-label>{{labels.lblLastName}}</mat-label>
        <input matInput ngModel name="lastName" required>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
    </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblUserName}}</mat-label>
            <input [class] = "errorClass"  matInput ngModel name="userName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none">
          <mat-label>Kompanija</mat-label>
          <mat-select ngModel name="companyId" required>
            <mat-option [value]="1">
              Elektromreža Srbije
            </mat-option>
            <mat-option [value]="2">
              NIS
            </mat-option>
            <mat-option [value]="3">
              Evropska konsalting grupa
            </mat-option>
            <mat-option [value]="4">
              RGZ
            </mat-option>
          </mat-select>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblPassword}} {{labels.lblPassMinCharacters}}</mat-label>
          <input type="password" matInput ngModel name="password" required>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblConfirmPassword}}</mat-label>
          <input type="password" matInput ngModel name="confirmPassword" required>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none">
            <mat-label>{{labels.lblSector}}</mat-label>
            <mat-select ngModel name="departmentId" required>
              <mat-option *ngFor="let department of departments" [value]="department.id">
                {{department.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>E-mail</mat-label>
            <input matInput ngModel name="email" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <div mat-dialog-actions>
            <button mat-raised-button class="tableBtn" cdkFocusInitial type="submit">{{labels.lblAdd}}</button>
        </div>
    </div>
  </form>
</div>
