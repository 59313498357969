<app-user-spinner *ngIf="training == null || user == null || labels == null || test == null"></app-user-spinner>
<div class="container d-none d-sm-flex">
    <div class = "col-1 col-sm-3"></div>
    <div class="container2 col-11 col-sm-5">
        <div class="testHeader font" style = "margin-top: 3%">
            {{test.name}}
            <cd-timer format="user" [countdown]="true" [startTime]="currentTime" (onTick)=onTick() (onComplete)="onTimeIsUp()" #basicTimer style="float: right; color: rgb(241,106,100);" *ngIf="test.durationTime != null">[minutes] : [seconds] </cd-timer>
        </div>
        <div >
            <div class="testQuestion font">
                <h5 class = "font" style = "font-size: 15px; color: #0E0D35; margin-bottom: 7%;">{{labels.lblQuestionCapsLock}} {{test.questions[index].order}} {{labels.lblOfCL}} {{test.questions.length}}</h5>
                <h3 class="questionText font" width="100%">
                    <span style="font-weight: bold;">{{test.questions[index].text}} </span>
                    <button *ngIf="showVideoHintIcon && test.questions[index].videoHintPath" (click)="openVideoHintComponent()" class="help-btn" mat-raised-button [class]="buttons">{{labels.lblHelp}} <mat-icon>lightbulb</mat-icon></button>
                </h3>
                <div style="width: 300px; text-align: center; background: #0E0D35; height: 200px;" *ngIf = "test.questions[index].imagePath">
                    <img style="width:100%; height: 100%; margin: auto; object-fit: contain;" src = {{test.questions[index].imagePath}} alt = "test question image">
                </div>
                <h4 *ngIf="test.questions[index].type==2"><span style="color: darkorange;">*{{labels.lblSeveralCorrectAnswers}}</span></h4>
                <div class="answer" *ngFor="let answer of test.questions[index].answers"  [class.answerSelectedEMS]='answer.selected && (user.companyId == 1)' [class.answerSelectedECG]='answer.selected && (user.companyId != 1)'>
                    <!-- <input type="checkbox" id="{{answer.id}}" [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);"/> {{answer.text}} -->
                    <mat-checkbox [value]="answer" class="checkBox " [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);">
                        <span style="font-size: 2vh; font-weight: bold; white-space: pre-line;">{{answer.text}}</span>
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class = "col-1 col-sm-3" style = "position: relative;">
        <div class="buttons" style = "position: absolute; right: 0; bottom: 0;">
            <!-- <button mat-raised-button class="button" [class] = "buttons" style="float: left;" *ngIf="!(index==0)"
                (click)="previousQuestion(test.questions[index].order)">
                {{labels.lblBack}}
            </button> -->
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == true))" style = "cursor: pointer;" (click)="nextQuestion(test.questions[index].order)" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994' alt = "button image">
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == false))" class = "btnDisabled"  src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994' alt = "disabled button image">
          
            <button mat-raised-button class="button" *ngIf="(index==test.questions.length-1 && (showNextBtn == true))"
                (click)="submit(test.questions[index].order, false)">
                {{labels.lblSubmit}} <mat-icon style = "vertical-align: middle; margin-left: 15px; color: black;">arrow_forward</mat-icon>
            </button>
        </div>
    </div>
   <div class = "row">
    <div class="col-12 progress" style = "left: 0" [style.width]="testProgress+'%'"></div>
   </div>
</div>

<!-- mobile -->
<div class="mobileContainer row">
    <div class="container2 col-xs-12">
        <div class="testHeader font">
            {{labels.lblTest}}
        </div>
        <div class="testQuestion">
            <h5 class = "font" style = "font-size: 15px; color: #0E0D35;">{{labels.lblQuestionCapsLock}} {{test.questions[index].order}} {{labels.lblOfCL}} {{test.questions.length}}</h5>
            <h3 class="questionText">
                <span style="font-weight: bold; line-height: 35px;">{{test.questions[index].text}}</span>
            </h3>
            <div style="width: 300px; text-align: center; background: black; height: 200px;" *ngIf = "test.questions[index].imagePath">
                <img style="width:100%; height: 100%; margin: auto; object-fit: contain;" src = {{test.questions[index].imagePath}} alt = "test question image">
            </div>
            <h4 *ngIf="test.questions[index].type==2"><span style="color:rgb(241,106,100);">*{{labels.lblSeveralCorrectAnswers}}</span></h4>
                    <div class="answer" *ngFor="let answer of test.questions[index].answers" [class.answerSelectedEMS]='answer.selected && (user.companyId == 1)' [class.answerSelectedECG]='answer.selected && (user.companyId != 1)'>
                        <!-- <input type="checkbox" id="{{answer.id}}" [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);"/> {{answer.text}} -->
                        <mat-checkbox [value]="answer" class="checkBox " [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);">
                            <span style="font-size: 2vh; font-weight: bold; white-space: break-spaces;">{{answer.text}}</span>
                        </mat-checkbox>
                    </div>
        </div>
        <div class="buttons" style = "margin-right: 15px; text-align: right;">
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == true))" style = "cursor: pointer;" (click)="nextQuestion(test.questions[index].order)" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994' alt = "button image">
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == false))" class = "btnDisabled"  src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994' alt = "disabled button image">
          
            <button mat-raised-button class="button" *ngIf="(index==test.questions.length-1 && (showNextBtn == true))"
                (click)="submit(test.questions[index].order, false)">
                {{labels.lblSubmit}} <mat-icon style = "vertical-align: middle; margin-left: 15px; color: white;">arrow_forward</mat-icon>
            </button>
        </div>
    </div>
    <div class = "col-12" class="progress" [style.width]="testProgress+'%'"></div>
</div>
