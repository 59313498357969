import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage, base64ToFile } from 'ngx-image-cropper';
import { Subscription } from 'rxjs/internal/Subscription';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {

  labels;
  labelsSub: Subscription;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  imageUploaded:File
  image: Blob
  imageFile: File

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<ImageCropperDialogComponent>, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data
    this.imageUploaded = this.data.target.files[0]
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.image = base64ToFile(this.croppedImage);
    let fileName = "cropped" + this.imageChangedEvent.target.files[0].name
    this.imageFile = new File([this.image], fileName, { type: 'image/png' });
    console.log(this.imageFile)
  }
  imageLoaded() {
    
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onNoClick() {
    this.dialogRef.close(null)
  }

  saveImage() {
    this.dialogRef.close(this.imageFile)
  }

}
