import { Component, ElementRef, HostListener, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DashboardService } from '../dashboard.service';
import { UserDashboardService } from '../user-dashboard.service';
import { Message } from '../../models/message.model'
import { Observable, Subscription } from 'rxjs';
import { last, share } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { Chat } from '../../models/chat.model'
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { Router, UrlSerializer } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';
import { ChatZoomMeetingComponent } from './chat-zoom-meeting/chat-zoom-meeting.component';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoCallComponent } from './user-video-call/user-video-call.component';

@Component({
  selector: 'app-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss'],
  host: { '(click)': 'onClick()' }
})

export class UserMessagesComponent implements OnInit {
  items: Observable<any[]>;
  chatMessage: Message
  constructor(private storage: AngularFireStorage, private languageService: UserLanguageService, private authService: AuthService, public db: AngularFirestore, private userDashboardService: UserDashboardService, private dashboardService: DashboardService, private component: UserDashboardComponent, public snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog) {
    this.messagesHeight = "70%"
  }
  users
  userMessages$: Observable<Message[]>;
  messages: Message[]
  msgsSub: Subscription;
  user
  userSub: Subscription;
  floatClassMsg
  displayMessage
  msgsBackground
  floatDateClassMsg
  forumVisibility
  chatVisibility
  userTo
  userToFullName
  userToImg
  chatDocId: string
  chatMessages: Chat[]
  displayChatMessage
  displayImage
  displayUsersList
  notif
  lang
  langSub: Subscription;
  labels;
  labelsSub: Subscription;
  online
  userIsOnlineSub: Subscription
  showDiv
  firstTime
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('scrollMe') msgSdcroll: ElementRef
  @ViewChild('fileUpload') fileUpload: ElementRef
  @ViewChild('insertChatForm') insertChatForm: NgForm
  lastMessage
  backgroundColor
  forumBackgroundColor
  notActive
  numberOfMessages
  showSpinner
  numberOfForumMessages
  showForumSpinner
  usersCompanyId
  userHeadAdmin
  selectedFile: File
  bodyBackgroundColor
  usersList
  color
  messagesHeight: string

  userId
  routerUrl
  code
  zoomResponse
  zoomAccessToken
  zoomUserId
  zoomUserDetails
  createdMeeting
  userFullName
  ngOnInit(): void {
    this.langSub = this.languageService.lang$.subscribe((lang) => {
     setTimeout(() => {
      this.lang = lang;
      console.log("LANGGG: ", this.lang)
     }, 300);
    })
    this.firstTime = false
    this.showDiv = "forum"
    //this.msgSdcroll.nativeElement.scrollTop = this.msgSdcroll.nativeElement.scrollHeight;
    this.lastMessage = "Primer poslednje poruke iz chat-a."
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      console.log("USER")
      console.log(this.user)
      setTimeout(() => {
        this.usersCompanyId = this.user.companyId
        if (this.user.companyId == 1) {
          this.bodyBackgroundColor = "#ECF0F9"
          this.usersList = "usersEMS"
          this.color = "#808385"

        } else if (this.user.companyId == 2) {
          this.bodyBackgroundColor = "#ECF0F9"
          this.usersList = "usersECG"
          this.color = "rgb(241, 106, 100)"
        }
        else {
          this.bodyBackgroundColor = "#ECF0F9"
          this.usersList = "usersECG"
        }
        this.userId = this.user.userId
        this.userFullName = this.user.firstName + " " + this.user.lastName
        this.userHeadAdmin = this.user.headAdmin
        // this.routerUrl = this.router.url
        // console.log(this.routerUrl)
        // let parts = this.routerUrl.split("=",2)
        // this.code = parts[1]
        // this.oAuthRedirect(this.code)
        // this.updateZoomMeetingFields(this.userId)
      }, 700);
    });

    setTimeout(() => {
      this.getUsers(this.lang)
      this.getUserMessages(8, this.lang)
    }, 1300);

    // this.notifications.requestPermission()
    // this.notifications.receiveMessage()
    // this.notif = this.notifications.currentMessage


    this.forumVisibility = "forumVisible"
    this.chatVisibility = "notVisible"

    this.chatDocId = ""
    this.displayUsersList = "mobVisible"
    this.backgroundColor = ""
    this.forumBackgroundColor = true;
    this.userTo = ""

    this.showSpinner = "dontShow"
    this.numberOfMessages = 7
    this.showForumSpinner = "dontShow"
    this.numberOfForumMessages = 9

  }

  updateZoomMeetingFields(userId){
      this.db.firestore.collection("users").doc(userId).update({meetingCall: false, meetingHost: false, onMeeting: false, callFrom: ""})
  }

  oAuthRedirect(code){
    let clientInfo = 'fU1My98QC2ipHpvi5kRXA:iGvM0cVp1BsOg8i9XEzZ9l8SQygSkUpb'
    let encode = btoa(clientInfo)
    let authorization =  `Basic ${encode}`

    let data = {
      authorization: authorization,
      code: code
    }

    this.userDashboardService.zoomAccessToken(data).subscribe((response) => {
      console.log("RESPONSE FROM ZOOM API: ", response)
      this.zoomResponse = response
      this.zoomAccessToken = this.zoomResponse.access_token
      this.db.collection("users").doc(this.userId).update({zoomAccessToken: this.zoomResponse.access_token})
      this.userDashboardService.getZoomUserDetails(this.zoomResponse.access_token).subscribe((newResponse) => {
        console.log("ZOOM USER DETAILS: ", newResponse)
        this.zoomUserDetails = newResponse
        this.zoomUserId = this.zoomUserDetails.users[0].id
        this.db.collection("users").doc(this.userId).update({zoomUserId: this.zoomUserId})
      })
    })
  
  }

  startZoomMeeting(user){
    console.log("USER TO CALL")
      console.log(user)
      if(user.onMeeting == true || user.online == false){
        this.snackBar.open(this.labels.lblUserUnavailable, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }else{
        this.userDashboardService.addMeeting("").subscribe((response) => {
          console.log("RESPONSE")
          console.log(response)
          this.createdMeeting = response
          console.log(this.userId)
          console.log(user.userId)
          let ref
          let id
          if(user.isUser == true){
            ref = this.db.firestore.collection("users")
            id = user.userId
          }else if(user.isTrainer == true){
            ref = this.db.firestore.collection("trainers")
            id = user.id
          }else{
            ref = this.db.firestore.collection("administrators")
            id = user.id
          }
          this.db.firestore.collection("users").doc(this.userId).update({meetingHost: true, currentMeetingId: this.createdMeeting.meetingId})
          ref.doc(id).update({meetingHost: false, currentMeetingId: this.createdMeeting.meetingId, meetingCall: true, callFrom: this.userFullName })
        })
        this.router.navigateByUrl("/user/chat-meeting")
      }
  }

  async startVideoCall(user){

    if(user?.inCall == true || user.online == false) {
      this.userDashboardService.snackMsg(this.labels.lblUserUnavailable);
    }
    else {
      let ref, id;

      if(user.isUser == true) {
        ref = this.db.firestore.collection('users');
        id = user.userId;
      }
      else if(user.isTrainer == true) {
        ref = this.db.firestore.collection("trainers");
        id = user.id;
      }
      else {
        ref = this.db.firestore.collection("administrators");
        id = user.id;
      }

      //this.db.firestore.collection("users").doc(this.userId).update({meetingHost: true, currentMeetingId: this.createdMeeting.meetingId})
      //ref.doc(id).update({meetingHost: false, currentMeetingId: this.createdMeeting.meetingId, meetingCall: true, callFrom: this.userFullName })

      this.db.firestore.collection("users").doc(this.userId).update({
        callingUserId: user.id,
        inCall: true
      });


      let channelName = String((new Date()).getTime());
      let uid = String(this.user.userId);

      console.log("SENDING CHANNEL ", channelName);

      let token;
      await this.userDashboardService.generateAgoraToken(channelName, uid).subscribe((token) => {
        token = token;
        ref.doc(id).update({
          calledByUserId: this.user.userId,
          agoraChannel: channelName,
          agoraToken: String(token),
          inCall: true,
          calledByUserName: (this.user.firstName + " " + this.user.lastName)
        });
        const dialogRef = this.dialog.open(UserVideoCallComponent, {
          width: '500px',
          disableClose: true,
          data: { 
            userId: this.user.userId,
            agoraToken: String(token),
            agoraChannelName: channelName,
          }
        });
      });

    }
  }

  getUserMessages(numberOfMessages, lang) {
    let messages = []
    messages = []
    this.messages = []
    this.showSpinner = "dontShow"
    this.showForumSpinner = "dontShow"
    let docRef = this.db.firestore.collection('messages').orderBy('dateTime', 'desc').limit(numberOfMessages)
    let usrCompany = this.usersCompanyId
    let usrHeadAdmin = this.userHeadAdmin
    docRef.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        if (change.type == "added") {
          let message = change.doc.data()
          if ((message.companyId == usrCompany) || (usrHeadAdmin)) {
            message.timestamp = change.doc.data().dateTime
            let timestamp = change.doc.data().dateTime.seconds
            var date = new Date(timestamp * 1000);
            // var formatDate = date.getDate() +
            //   "/" + (date.getMonth() + 1) +
            //   "/" + date.getFullYear() +
            //   " " + date.getHours() +
            //   ":" + date.getMinutes()
              var today = new Date()
              var formatDate
                if(today.getDay() == date.getDay() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                  formatDate = date.getHours() +
                  ":" + date.getMinutes()
                }else if(today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                  var days = today.getDay() - date.getDay();
                  if(lang == 'srb'){
                    formatDate = "pre " + days + " dana"
                  }else if(lang == "cirilica"){
                    formatDate = "пре " + days + " дана"
                  }else{
                    formatDate = days + " days ago"
                  }
                }else{
                  var months = today.getMonth() - date.getMonth();
                  if(lang == 'srb'){
                    console.log("IFFF")
                    formatDate = "pre " + months + " meseca"
                  }else if(lang == "cirilica"){
                    formatDate = "пре " + months + " месеца"
                  }else{
                    formatDate = months + " months ago"
                  }
                }
            message.dateTime = formatDate
            messages.push(message)
          }
        }
      });
    });
    setTimeout(() => {
      messages = messages.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
      this.messages = messages
      return this.messages
    }, 700);
  }

  async submitMessage() {
    if (this.insertForm.value.textMessage != "" && this.insertForm.value.textMessage != null) {
      let companyId
      if (this.user.headAdmin) {
        companyId = 0
      } else {
        companyId = this.user.companyId
      }
      let msg = {
        type: 0,
        firstName: this.insertForm.value.firstName,
        lastName: this.insertForm.value.lastName,
        sender: this.insertForm.value.sender,
        dateTime: new Date(),
        text: this.insertForm.value.textMessage,
        imagePath: this.user.profileImagePath,
        userId: this.user.id,
        companyId: companyId
      }
      let message = msg
      let docRef = this.db.firestore.collection('messages').doc()
      docRef.set(message)
    }
    if (this.selectedFile != null) {
      let companyId
      if (this.user.headAdmin) {
        companyId = 0
      } else {
        companyId = this.user.companyId
      }
      let msg = {
        type: 3,
        firstName: this.insertForm.value.firstName,
        lastName: this.insertForm.value.lastName,
        sender: this.insertForm.value.sender,
        dateTime: new Date(),
        text: this.selectedFile.name,
        imagePath: this.user.profileImagePath,
        userId: this.user.id,
        companyId: companyId,
        fileUrl: null
      }
      let ref1 = this.storage.ref(this.selectedFile.name)
      await ref1.put(this.selectedFile)
      msg.fileUrl = await ref1.getDownloadURL().toPromise()
      let message = msg
      let docRef = this.db.firestore.collection('messages').doc()
      docRef.set(message)
      this.updateFile()
    }
    this.insertForm.controls.textMessage.reset()
  }

  displayImgAndInfo(m) {
    if (m.sender == this.user.email) {
      this.displayMessage = false
    }
    else {
      this.displayMessage = true
    }
  }

  userSearch(event) {
    let search = event.target.value;
    console.log(search)
    if (search == "") {
      this.getUsers(this.lang)
    } else {
      this.users = this.users.filter((user) => (user.firstName.includes(search) == true) || (user.lastName.includes(search) == true))
    }
  }

  floatClass(m) {
    if (m.sender == this.user.email) {
      this.floatClassMsg = "floatRight"
      this.displayMessage = false
    }
    else {
      this.floatClassMsg = "floatLeft"
      this.displayMessage = true
    }
    return this.floatClassMsg
  }

  floatDateClass(m) {
    if (m.sender == this.user.email) {
      this.floatDateClassMsg = "floatDateRight"
    }
    else {
      this.floatDateClassMsg = "floatDateLeft"
    }
    return this.floatDateClassMsg
  }


  msgBackground(m) {
    if (m.sender == this.user.email) {
      if (this.usersCompanyId == 1) {
        this.msgsBackground = "msgBackgroundEMS"
      } else {
        this.msgsBackground = "msgBackgroundECG"
      }
    }
    else {
      this.msgsBackground = "noMsgBackground"
    }
    return this.msgsBackground
  }

  displayChatImgAndInfo(m) {
    if (m.idFrom == this.user.id) {
      this.displayChatMessage = false
    }
    else {
      this.displayChatMessage = true
    }
  }

  floatChatClass(m) {
    if (m.idFrom == this.user.id) {
      this.floatClassMsg = "floatRight"
      this.displayMessage = false
    }
    else {
      this.floatClassMsg = "floatLeft1"
      this.displayMessage = true
    }
    return this.floatClassMsg
  }

  floatChatDateClass(m) {
    if (m.idFrom == this.user.id) {
      this.floatDateClassMsg = "floatDateRight"
    }
    else {
      this.floatDateClassMsg = "floatDateLeft"
    }
    return this.floatDateClassMsg
  }


  msgChatBackground(m) {
    console.log("ID FROM: ")
    console.log(m);
    console.log("USER ID FROM: ")
    console.log(this.user.id);
    if (m.idFrom == this.user.id) {
      if (this.usersCompanyId == 1) {
        this.msgsBackground = "msgBackgroundEMS"
      } else {
        this.msgsBackground = "msgBackgroundECG"
      }
    }
    else {
      this.msgsBackground = "noMsgBackground"
    }
    return this.msgsBackground
  }

  getUsers(lang) {
    let users = []
    let usrId = this.user.id
    let docRef = this.db.firestore.collection('users').where("pending", "==", false).where("deleted", "==", false).where("companyId", "==", this.usersCompanyId)
    let ref = this.db.firestore.collection('users').doc(usrId).collection('messages')
    docRef.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let user = change.doc.data()
        user.isAdmin = false
        user.isUser = true
        user.isTrainer = false
        user.profileImagePath = user.profileImagePath
        user.id = change.doc.data().userId
        ref.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.doc.data().docId == user.id) {
              let lastMessage = change.doc.data()
              if (lastMessage.idFrom == usrId) {
                user.from = true
              } else {
                user.from = false
              }
              let time = Number(change.doc.data().timestamp)
              lastMessage.time = time
              time = time / 1000
              time = time * 1000
              var date = new Date(time);
              // var formatDate = date.getDate() +
              //   "/" + (date.getMonth() + 1) +
              //   "/" + date.getFullYear() +
              //   " " + date.getHours() +
              //   ":" + date.getMinutes()
              
              var today = new Date()
              var formatDate
              if(today.getDay() == date.getDay() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                formatDate = date.getHours() +
                ":" + date.getMinutes()
              }else if(today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                var days = today.getDay() - date.getDay();
                if(lang == 'srb'){
                  formatDate = "pre " + days + " dana"
                }else if(lang == "cirilica"){
                  formatDate = "пре " + days + " дана"
                }else{
                  formatDate = days + " days ago"
                }
              }else{
                var months = today.getMonth() - date.getMonth();
                if(lang == 'srb'){
                  console.log("IFFF")
                  formatDate = "pre " + months + " meseca"
                }else if(lang == "cirilica"){
                  formatDate = "пре " + months + " месеца"
                }else{
                  formatDate = months + " months ago"
                }
              }
              lastMessage.timestamp = formatDate
              user.lastMessage = lastMessage
            }
          })
        })
        users.push(user)
      });
    });

    let docRef2 = this.db.firestore.collection('administrators').where("companyId", "==", this.usersCompanyId)
    let ref2 = this.db.firestore.collection("users").doc(usrId).collection('messages')
    docRef2.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let user = change.doc.data()
        user.isAdmin = true
        user.isUser = false
        user.isTrainer = false
        user.id = change.doc.data().id
        ref2.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.doc.data().docId == user.id) {
              let lastMessage = change.doc.data()
              if (lastMessage.idFrom == usrId) {
                user.from = true
              } else {
                user.from = false
              }
              let time = Number(change.doc.data().timestamp)
              lastMessage.time = time
              time = time / 1000
              time = time * 1000
              var date = new Date(time);
              // var formatDate = date.getDate() +
              //   "/" + (date.getMonth() + 1) +
              //   "/" + date.getFullYear() +
              //   " " + date.getHours() +
              //   ":" + date.getMinutes()
              var today = new Date()
              var formatDate
              if(today.getDay() == date.getDay() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                formatDate = date.getHours() +
                ":" + date.getMinutes()
              }else if(today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                var days = today.getDay() - date.getDay();
                if(lang == 'srb'){
                  formatDate = "pre " + days + " dana"
                }else if(lang == "cirilica"){
                  formatDate = "пре " + days + " дана"
                }else{
                  formatDate = days + " days ago"
                }
              }else{
                var months = today.getMonth() - date.getMonth();
                if(lang == 'srb'){
                  console.log("IFFF")
                  formatDate = "pre " + months + " meseca"
                }else if(lang == "cirilica"){
                  formatDate = "пре " + months + " месеца"
                }else{
                  formatDate = months + " months ago"
                }
              }
              lastMessage.timestamp = formatDate
              user.lastMessage = lastMessage
            }
          })
        })
        users.push(user)
      });
    });

    let docRef3 = this.db.firestore.collection('trainers').where("companyId", "==", this.usersCompanyId)
    let ref3 = this.db.firestore.collection("users").doc(usrId).collection('messages')
    docRef3.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let user = change.doc.data()
        user.isAdmin = false
        user.isUser = false
        user.isTrainer = true
        user.id = change.doc.data().id
        ref3.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.doc.data().docId == user.id) {
              let lastMessage = change.doc.data()
              if (lastMessage.idFrom == usrId) {
                user.from = true
              } else {
                user.from = false
              }
              let time = Number(change.doc.data().timestamp)
              lastMessage.time = time
              time = time / 1000
              time = time * 1000
              var date = new Date(time);
              // var formatDate = date.getDate() +
              //   "/" + (date.getMonth() + 1) +
              //   "/" + date.getFullYear() +
              //   " " + date.getHours() +
              //   ":" + date.getMinutes()
                var today = new Date()
                var formatDate
                if(today.getDay() == date.getDay() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                  formatDate = date.getHours() +
                  ":" + date.getMinutes()
                }else if(today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                  var days = today.getDay() - date.getDay();
                  if(lang == 'srb'){
                    formatDate = "pre " + days + " dana"
                  }else if(lang == "cirilica"){
                    formatDate = "пре " + days + " дана"
                  }else{
                    formatDate = days + " days ago"
                  }
                }else{
                  var months = today.getMonth() - date.getMonth();
                  if(lang == 'srb'){
                    console.log("IFFF")
                    formatDate = "pre " + months + " meseca"
                  }else if(lang == "cirilica"){
                    formatDate = "пре " + months + " месеца"
                  }else{
                    formatDate = months + " months ago"
                  }
                }
                lastMessage.timestamp = formatDate
              user.lastMessage = lastMessage
            }
          })
        })
        users.push(user)
      });
    });
    setTimeout(() => {
      let allusers = this.sortUsers(users)
      this.users = allusers
      return this.users
    }, 500);
  }

  getLastMsgDate(date: Date){
    console.log("POZVAO JE FUNKCIJU")
    var today = new Date()
    var formatDate
    if(today.getDay() == date.getDay() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
      formatDate = date.getHours() +
      ":" + date.getMinutes()
      console.log("FORMAT DATE::: ", formatDate);
    }else if(today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
      var days = today.getDay() - date.getDay();
      formatDate = days + " days ago"
      console.log("FORMAT DATE::: ", formatDate);
    }else{
      var months = today.getMonth() - date.getMonth();
      formatDate = months + " months ago"
      console.log("FORMAT DATE::: ", formatDate);
    }
    return formatDate

  }

  sortUsers(users) {
    let usrs1 = []
    users.forEach(u => {
      if (u.lastMessage) {
        usrs1.push(u)
      }
    });
    let usrs2 = users.filter((u) => !u.lastMessage)
    usrs1 = usrs1.sort((a, b) => (a.lastMessage.time > b.lastMessage.time ? -1 : 1))

    usrs2.forEach(u => {
      usrs1.push(u)
    });
    users = usrs1
    return users
  }

  changeForumVisibility() {
    this.showDiv = "forum"
    this.notActive = "notActive"
    this.forumVisibility = "forumVisible"
    this.chatVisibility = "notVisible"
    this.displayUsersList = "mobNotVisible"
    this.forumBackgroundColor = true
  }

  displayImg(m) {
    for (let i = 0; i < this.chatMessages.length; i++) {
      const element = this.chatMessages[i];
      const nextElement = this.chatMessages[i + 1]
      if (m.id == element.id && m.idFrom == nextElement.idFrom) {
        this.displayImage = "notVisible"
      }
      return this.displayImage
    }
    return this.displayImage
  }

  @HostListener('scroll', ['$event'])
  onScrollForumFunction(e) {
    if (!e.target.scrollTop) {
      console.log("Scrolled to top - forum")
      this.showSpinner = null
      this.numberOfForumMessages += 5
      this.showForumSpinner = null
      this.getUserMessages(this.numberOfForumMessages, this.lang)
    }
  }

  async chatWithUser(u, numberOfMessages, flg, lang) {
    if (flg == 0) {
      this.numberOfMessages = 7
      this.numberOfForumMessages = 7
    }
    this.firstTime = true
    this.showDiv = "chat"
    this.notActive = ""
    this.userTo = u
    this.userToFullName = "" + u.firstName + " " + u.lastName
    this.userToImg = u.profileImagePath
    this.chatVisibility = "chatVisible"
    this.forumVisibility = "notVisible"
    this.displayUsersList = "mobNotVisible"
    this.backgroundColor = "active"
    this.forumBackgroundColor = false
    let chatMsgs = []
    let id1 = this.user.id + "-" + u.id
    let id2 = u.id + "-" + this.user.id
    let docId: string
    this.db.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id).update({ seen: true })

    let r1 = await this.db.firestore.collection('messages').doc(id1).get()
    let r2 = await this.db.firestore.collection('messages').doc(id2).get()
    if ((!r1.exists) && (!r2.exists)) {
      if (this.user.id.hashCode >= this.userTo.id.hashCode) {
        let idDoc = this.user.id + "-" + this.userTo.id
        let doc = await this.db.firestore.collection('messages').doc(idDoc).set({ "id": false })
      } else {
        let idDoc = this.userTo.id + "-" + this.user.id
        let doc = await this.db.firestore.collection('messages').doc(idDoc).set({ "id": false })
      }
    }

    let docRef = this.db.firestore.collection('messages').get()

    docRef.then((snapshot) => {
      this.chatMessages = []
      snapshot.forEach((change) => {
        if (change.id == id1 || change.id == id2) {
          let documentId: string = change.id
          docId = documentId
          let ref = this.db.firestore.collection('messages').doc(change.id).collection(change.id).orderBy('timestamp', 'desc').limit(numberOfMessages)
          ref.onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(function (change) {
              if (change.type == "added") {
                let message = change.doc.data()
                message.id = change.doc.id
                message.timestamp = change.doc.data().timestamp
                let time = Number(change.doc.data().timestamp)
                time = time / 1000
                time = time * 1000
                var date = new Date(time);
                // var formatDate = date.getDate() +
                //   "/" + (date.getMonth() + 1) +
                //   "/" + date.getFullYear() +
                //   " " + date.getHours() +
                //   ":" + date.getMinutes()

                  var today = new Date()
                  var formatDate
                if(today.getDay() == date.getDay() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                  formatDate = date.getHours() +
                  ":" + date.getMinutes()
                }else if(today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear()){
                  var days = today.getDay() - date.getDay();
                  if(lang == 'srb'){
                    formatDate = "pre " + days + " dana"
                  }else if(lang == "cirilica"){
                    formatDate = "пре " + days + " дана"
                  }else{
                    formatDate = days + " days ago"
                  }
                }else{
                  var months = today.getMonth() - date.getMonth();
                  if(lang == 'srb'){
                    console.log("IFFF")
                    formatDate = "pre " + months + " meseca"
                  }else if(lang == "cirilica"){
                    formatDate = "пре " + months + " месеца"
                  }else{
                    formatDate = months + " months ago"
                  }
                }
                message.timestamp = formatDate
                chatMsgs.push(message)
              }
            })
          })
        }
      });
    });
    setTimeout(() => {
      this.chatDocId = docId
      this.showSpinner = "dontShow"
      this.showForumSpinner = "dontShow"
      this.chatMessages = chatMsgs.reverse()
    }, 2000);
    return this.chatMessages
  }

  isClicked(id) {
    if (id == this.userTo.id) {
      return true
    } else {
      return false
    }
  }

  @HostListener('scroll', ['$event'])
  onScrollFunction(e, userTo) {
    if (!e.target.scrollTop) {
      console.log("Scrolled to top - chat")
      this.showSpinner = null
      this.numberOfMessages += 5
      this.chatWithUser(userTo, this.numberOfMessages, 1, this.lang)
    }
  }

  backToUsersList() {
    this.displayUsersList = "mobVisible"
    this.forumVisibility = "notVisible"
    this.chatVisibility = "notVisible"
  }

  async submitChatMessage() {
    let timestamp = Math.floor(Date.now())
    let stringTimestamp = "" + timestamp

    if (this.insertChatForm.value.content != "" && this.insertChatForm.value.content != null) {
      let msg = {
        idTo: this.userTo.id,
        idFrom: this.user.id,
        timestamp: stringTimestamp,
        content: this.insertChatForm.value.content,
        fileUrl: null,
        type: 0
      }
      let message = msg
      let id: string
      if (this.chatDocId != "" && this.chatDocId != null) {
        id = String(this.chatDocId)
      }
      else {
        let idDoc: string
        if (this.user.id.hashCode >= this.userTo.id.hashCode) {
          idDoc = this.user.id + "-" + this.userTo.id
        } else {
          idDoc = this.userTo.id + "-" + this.user.id
        }
        id = String(idDoc)
      }
      let docRef = this.db.firestore.collection('messages').doc(id)
      let boolId: boolean = false
      docRef.set({
        'id': false
      })
      let ref = docRef.collection(id).doc()
      ref.set(message)

      let notifRef = this.db.firestore.collection('users').doc(this.userTo.id).collection('userNotifications').doc()
      notifRef.set({
        "content": this.insertChatForm.value.content,
        "fullname": this.user.firstName + " " + this.user.lastName,
        "receiverId": this.userTo.id,
        "timestamp": new Date(),
        "docId": notifRef.id,
        "seen": false,
        "type": 1
      })
      let lastMessage = {
        "lastMessage": this.insertChatForm.value.content,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.userTo.firstName + " " + this.userTo.lastName,
        "docId": this.userTo.id,
        "idFrom": this.user.id,
        "seen": true
      }
      let lastMessage2 = {
        "lastMessage": this.insertChatForm.value.content,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.user.firstName + " " + this.user.lastName,
        "docId": this.user.id,
        "idFrom": this.user.id,
        "seen": false
      }
      let messageRef = this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id)
      messageRef.get().then(doc => {
        if (doc.exists) {
          this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id).update(lastMessage)
        } else {
          this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id).set(lastMessage)
        }
      })

      if (this.userTo.isAdmin == true) {
        let messageRef2 = this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id)
        messageRef2.get().then(doc => {
          if (doc.exists) {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
          } else {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
          }
        })
      } else {
        if (this.userTo.isUser == true) {
          let messageRef2 = this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        } else {
          let messageRef2 = this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        }
      }
      if (this.firstTime == true) {
        this.getUsers(this.lang)
        this.firstTime = false;
      }
    }
    
    if (this.selectedFile != null) {
      let msg = {
        idTo: this.userTo.id,
        idFrom: this.user.id,
        timestamp: stringTimestamp,
        content: this.selectedFile.name,
        fileUrl: null,
        type: 3
      }
      let ref1 = this.storage.ref(this.selectedFile.name)
      await ref1.put(this.selectedFile)
      msg.fileUrl = await ref1.getDownloadURL().toPromise()

      let message = msg
      let id: string
      if (this.chatDocId != "" && this.chatDocId != null) {
        id = String(this.chatDocId)
      }
      else {
        let idDoc: string
        if (this.user.id.hashCode >= this.userTo.id.hashCode) {
          idDoc = this.user.id + "-" + this.userTo.id
        } else {
          idDoc = this.userTo.id + "-" + this.user.id
        }
        id = String(idDoc)
      }
      let docRef = this.db.firestore.collection('messages').doc(id)
      let boolId: boolean = false
      docRef.set({
        'id': false
      })
      let ref = docRef.collection(id).doc()
      ref.set(message)

      let notifRef = this.db.firestore.collection('users').doc(this.userTo.id).collection('userNotifications').doc()
      notifRef.set({
        "content": this.selectedFile.name,
        "fullname": this.user.firstName + " " + this.user.lastName,
        "receiverId": this.userTo.id,
        "timestamp": new Date(),
        "docId": notifRef.id,
        "seen": false,
        "type": 1
      })
      let lastMessage = {
        "lastMessage": this.selectedFile.name,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.userTo.firstName + " " + this.userTo.lastName,
        "docId": this.userTo.id,
        "idFrom": this.user.id,
        "seen": true
      }
      let lastMessage2 = {
        "lastMessage": this.selectedFile.name,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.user.firstName + " " + this.user.lastName,
        "docId": this.user.id,
        "idFrom": this.user.id,
        "seen": false
      }
      let messageRef = this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id)
      messageRef.get().then(doc => {
        if (doc.exists) {
          this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id).update(lastMessage)
        } else {
          this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id).set(lastMessage)
        }
      })

      if (this.userTo.isAdmin == true) {
        let messageRef2 = this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id)
        messageRef2.get().then(doc => {
          if (doc.exists) {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
          } else {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
          }
        })
      } else {
        if (this.userTo.isUser == true) {
          let messageRef2 = this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        } else {
          let messageRef2 = this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        }
      }
      this.updateFile()
    }
    this.insertChatForm.controls.content.reset()
  }

  @HostListener("click") onClick() {
    console.log("User Click using Host Listener")
    this.component.sideBarOpened = false
  }

  onFileChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "pdf" || ext == "docx" || ext == "xlsx") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: Number = +iConvert
      if (number <= 20) {
        this.selectedFile = event.target.files[0]
        this.messagesHeight = "63%"
      }
      else {
        this.snackBar.open(this.labels.lblMaxSizeDocument, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.fileUpload.nativeElement.value = ""
      }
    }
    else {
      this.snackBar.open(this.labels.lblSupportedFormatsDocument, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.fileUpload.nativeElement.value = ""
    }
    console.log(this.selectedFile)
  }

  updateFile() {
    this.messagesHeight = "72%"
    this.selectedFile = null
    this.fileUpload.nativeElement.value = ""
  }

}
