<div class="row"
    style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
    <div class="col-11"></div>
    <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
        <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
    </div>
</div>
<div class="row">
    <div class="col-12 div">
        <h3>{{labels.lblAddListMsg}}</h3>
    </div>
    <div class="logLabels" *ngIf="selectedFile" style="width: 100%; text-align: center;">
        <h4 style="color: #9dacd9; margin-left: 0px; font-family: 'Prompt', sans-serif !important;">
            {{selectedFile.name}}</h4>
    </div>
</div>
<div class="row" style="margin-top: 15px;">
    
    <input type="file" (change)="onFileChanged($event)" #fileUpload style="display: none; padding: 7px;">
    <mat-progress-bar mode="indeterminate" style="margin-top: 10px; margin-bottom: 10px" *ngIf="uploading"></mat-progress-bar>
    <div class="col-4 div">
        <button mat-raised-button class="tableBtn" style="width: 100%; text-align: center;" cdkFocusInitial
            type="submit" (click)="fileUpload.click()" *ngIf="!selectedFile">{{labels.lblAdd}}</button>
        <button mat-raised-button class="tableBtn" style="width: 100%; text-align: center;" cdkFocusInitial
            type="submit" *ngIf="selectedFile" (click)="onSaveUsers($event)">{{labels.lblSave}}</button>
    </div>
    <div class="col-4 div" style="padding: 7px;">
        <button class="tableBtn btnInstruction">
            <a class="link" target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Uputstvo-converted.pdf?alt=media&token=c7125aad-d54a-4fdf-91f9-f8026e19e3c4"
                cdkFocusInitial>{{labels.lblInstruction}}</a>
        </button>
    </div>
    <div class="col-4 div" style="padding: 7px;">
        <button class="tableBtn btnInstruction">
            <a class="link" target="_blank" *ngIf="lang=='srb' && data.flag == false"
                href="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/template.xls?alt=media&token=85b8a4b7-4ea0-4a1b-895d-1857766b55e8"
                cdkFocusInitial>{{labels.lblTemplate}}</a>
            <a class="link" target="_blank" *ngIf="lang=='eng' && data.flag == false"
                href="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/templateEng.xls?alt=media&token=f5cfd9e1-88f9-4a88-9714-4c1f02f25ba6"
                cdkFocusInitial>{{labels.lblTemplate}}</a>
            <a class="link" target="_blank" *ngIf="lang=='srb' && data.flag == true"
                href="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/templateSect.xls?alt=media&token=ebc952f4-f6cc-46e8-8bf6-318336142fc8"
                cdkFocusInitial>{{labels.lblTemplate}}</a>
            <a class="link" target="_blank" *ngIf="lang=='eng' && data.flag == true"
                href="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/templateEngSect.xls?alt=media&token=99958a93-6fdb-40c6-90a8-ceabfa147616"
                cdkFocusInitial>{{labels.lblTemplate}}</a>

        </button>
    </div>
</div>