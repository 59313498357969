<mat-drawer-container>
  <mat-drawer-content>
    <app-user-spinner *ngIf="labels == null ||  test == null"></app-user-spinner>
<div class="container">
  <div class = "row">
    <div class = "col-11 col-sm-4" style = "margin: auto; margin-top: 11%; text-align: left;">
      <h1 style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblTest}}</h1>
      <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">{{labels.lblTestInFront1}} <br>
        {{labels.forPassingTheSetionTest}} <br>
        {{labels.lblMinPoints}} <span [style.color] = "color">{{maxPoints}}%</span><br>
        {{labels.lblGodLuck}}
      </h3>
      <div class="row" style = "text-align: center;">
        <div class="col-12 col-sm-6" style = "margin: auto;">
          <button class="login-button"  style = "border: 1px solid #90A2F8 !important; color: #90A2F8; background: transparent" (click)="skipTest()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblSkip}}</h3>
              </div>
          </button>
        </div>
        <div class="col-12 col-sm-6" style = "margin: auto;">
          <button class="login-button" (click)="goToTestPage()">
              <div class = "col-11" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel">{{labels.lblContinue}}</h3>
              </div>
          </button>
        </div>
        
      </div>
    </div>
  </div>
</div>
  </mat-drawer-content>
</mat-drawer-container>