import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-hint-video-player',
  templateUrl: './hint-video-player.component.html',
  styleUrls: ['./hint-video-player.component.scss']
})
export class HintVideoPlayerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<HintVideoPlayerComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

}
