import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { BehaviorSubject, Observable, of } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { LocalStorageService } from '../localStorage-service/local-storage.service'
import { Administrator } from 'src/app/models/administrator.model';
import { MatDialog } from '@angular/material/dialog';
import { WrongLoginDataComponent } from '../../wrong-login-data/wrong-login-data.component';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
interface AdminLoginResponse {
  accessToken: string
  refreshToken: string
  admin: Administrator
  err: string
}

@Injectable({
  providedIn: 'root'
})
export class AdminAuthService {
  admin$ = new BehaviorSubject(null)
  constructor(private http: HttpClient,
    public db: AngularFirestore,
    private localStorageService: LocalStorageService,
    public dialog:MatDialog) { }

adminLogin(form): Observable<AdminLoginResponse> {
  return this.http.post<AdminLoginResponse>(this.localStorageService.serversIpAddress + `administrators/login`, form)
    .pipe(
      tap(response => {
        if(response.err == "greska"){
          this.openWrongLoginDataModal()
        }
        this.admin$.next(response.admin)
          this.setToken('token', response.accessToken)
          this.setToken('refreshToken', response.refreshToken)
          this.localStorageService.setItem('adminFlag','1');

      })
    )
}

openWrongLoginDataModal(){
  const dialogRef=this.dialog.open(WrongLoginDataComponent,{
    width:'300px'
  });
  dialogRef.afterClosed().subscribe((admin) => {
    console.log('The dialog was closed');
   
  });
}

logoutAdmin(): void {
  let docRef = this.db.firestore.collection('administrators').doc(this.admin$.value.id)
    docRef.update({
      online: false
    })
  this.localStorageService.clear()
  this.admin$.next(null)
}

getCurrentAdmin(): Observable<Administrator> {
  this.admin$.pipe(
    switchMap(admin => {
      if (admin) {
        return of(admin)
      }

      const token = this.localStorageService.getItem('token')
      if (token) {
        return this.fetchCurrentAdmin()
      }

      return of(null)
    })
  ).subscribe((err)=> console.log(err))

  return this.admin$
}

fetchCurrentAdmin(): Observable<Administrator> {
  return this.http.get<Administrator>(this.localStorageService.serversIpAddress + `administrators/current-admin`)
    .pipe(
      tap(admin => {
        this.admin$.next(admin);
      })
    );
}

private setToken(key: string, token: string): void {
  this.localStorageService.setItem(key, token);
}

refreshToken(): Observable<{accessToken: string; refreshToken: string}> {
  const refreshToken = this.localStorageService.getItem('refreshToken')
   return this.http.post<{accessToken: string; refreshToken: string}>(
    this.localStorageService.serversIpAddress + `administrators/refresh-token`,
   {
    refreshToken
   }).pipe(
    tap(response => {
     this.setToken('token', response.accessToken)
     this.setToken('refreshToken', response.refreshToken);
     this.localStorageService.setItem('adminFlag','1');
    })
  );
 }

 getAdminFlag():string {
   return this.localStorageService.getItem('adminFlag');
 }

 updateAdmin(admin:Administrator){
  this.admin$.next(admin)
}

}
