<div class="row" style="width:100%; height: 100%">
    <div class="msgs col-4" style="height: 100%;">
    <div style="padding-top:15px; padding-left: 15px; text-align: left;">
        <h3>Poruke</h3>
    </div>
    <div *ngFor="let u of users" style="width: 100%;">
        <div class="row users" style="margin:11px;">
            <div class="col-3" style="text-align:right;">
                <img src="https://st2.depositphotos.com/1006318/5909/v/600/depositphotos_59095205-stock-illustration-businessman-profile-icon.jpg" alt = "chat image">
            </div>
            <div class="col-9">
                <div class="row">
                    <div class="col-7" style="text-align: left;">{{u.firstName}} {{u.lastName}}</div>
                    <div class="col-4" style="font-family: 'Poppins' !important; font-size:12.5px; text-align:right; padding-right:0px; color: #737373;">12:07am</div>
                    <div class="col-12" style="font-family: 'Poppins' !important; font-size:12.5px; text-align: left; color: #737373;">online</div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="font-size: 14.5px; margin-top: 15px;">
                    Primer tekstualne poruke.
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="col-7 messages">
        <div class="row" style="padding:11px;  margin: auto;">
            <div class="col-2" style="text-align:right; height: 65px;">
                <img style="width: 65px; height: 65px;" src="https://st2.depositphotos.com/1006318/5909/v/600/depositphotos_59095205-stock-illustration-businessman-profile-icon.jpg" alt = "chat img">
            </div>
            <div class="col-10" style="margin-top:auto; margin-bottom:auto; padding-left:0px; margin-left: 0px;">
                <div class="row">
                    <div class="col-12 userName">Sanja Markovic</div>
                    <div class="col-12" style="color: #737373; font-size: 15px; text-align: left !important;">sanja@leste.rs</div>
                </div>
            </div>
        </div>
        <mat-divider class="divider"></mat-divider>
        <div class="row msgList" #scrollMe [scrollTop]="scrollMe.scrollHeight" style="margin: auto;">
            <div *ngFor="let i of users">
                <div class="row poruka" style="margin-bottom: 15px;">
                    <div class="col-1">
                        <img  style="margin-top: 15px;" src="https://st2.depositphotos.com/1006318/5909/v/600/depositphotos_59095205-stock-illustration-businessman-profile-icon.jpg" alt = "alt img">
                    </div>
                    <div class="col-7">
                        <div class="userMessages">
                            Primer jedne tekstualne poruke u user chat-u.
                        </div>
                    </div>
                    <div class="row" style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                        <div class="col-9 offset-2" style="color: #737373; width: 100%; text-align: left; margin-top:0xp !important; padding-top:0px !important">
                             12:07am
                        </div>
                     </div>
                </div>
               
            </div>
        </div>
        <mat-divider class="divider"></mat-divider>
        <div class="row" style="width: 100%; margin: auto;">
            <form>
                <mat-form-field appearance="none">
                    <input matInput ngModel name="textMessage" placeholder="Write a message...">
                </mat-form-field>
                <mat-icon style="color:#8c8c8c; padding-left: 20px; padding-right: 20px; width: 7%; border-left: 1px solid #d9d9d9">send</mat-icon>
            </form>
        </div>
    </div>
    </div>
    
    
