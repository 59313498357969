import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { UserLanguageService } from '../services/user-language-service/user-language.service';

@Component({
  selector: 'app-user-image-cropper-dialog',
  templateUrl: './user-image-cropper-dialog.component.html',
  styleUrls: ['./user-image-cropper-dialog.component.scss']
})
export class UserImageCropperDialogComponent implements OnInit {

  labels;
  labelsSub: Subscription;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  imageUploaded:File
  image: Blob
  imageFile: File

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<UserImageCropperDialogComponent>, private userLanguageService: UserLanguageService) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data
    this.imageUploaded = this.data.target.files[0]
    console.log(this.imageUploaded)
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.image = base64ToFile(this.croppedImage);
    let fileName = "cropped" + this.imageChangedEvent.target.files[0].name
    this.imageFile = new File([this.image], fileName, { type: 'image/png' });
    console.log(this.imageFile)
  }
  imageLoaded() {
    
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onNoClick() {
    this.dialogRef.close(null)
  }

  saveImage() {
    this.dialogRef.close(this.imageFile)
  }


}
