<mat-dialog-container>
  <mat-dialog-content>
    <div class="col-12" style="margin: auto; text-align: center;">
      <h1 mat-dialog-title class="naslov" style="margin-top: 7%; margin-bottom: 7%;">{{labels.lblNotification}}</h1>
      <div mat-dialog-content>
        <h4 style="color: white">{{data.isDoc ? labels.lblModalMessageDocument : labels.lblModalMessage}}</h4>
      </div>
      <div mat-dialog-actions class="dugmeDiv" style="margin-top: 7%; margin-bottom: 7%;">
        <button mat-button (click)="onNoClick()" class="btn"
          style="width: 70%; border-radius: 11px;">{{labels.lblContinue}}</button>
      </div>
    </div>
  </mat-dialog-content>
</mat-dialog-container>