<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblEditTraining}}  "{{data.name}}" </h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="height: 435px">
    <div fxLayout="column">
        <input type="hidden" name="id" [ngModel]="data.id">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblETrainingName}}</mat-label>
            <input matInput [ngModel]='data.name' name="name" required>
            <mat-error *ngIf = "err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field class="matFormField" appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblDescription}}</mat-label>
            <textarea class="txtArea" maxlength = "600" rows = "5" cols = "5" matInput [ngModel]="data.description" name="description" required></textarea>
            <mat-error *ngIf = "err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none">
          <mat-label>{{labels.lblTrainer}}</mat-label>
          <mat-select [ngModel]='data.trainerId' name="trainerId" [disabled] = "assigned.length > 0" required>
            <mat-option *ngFor="let trainer of trainers" [value]="trainer.trainerId">
              {{trainer.firstName}} {{trainer.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="none">
          <mat-label>{{labels.lblPriority}}</mat-label>
          <mat-select [ngModel]='data.priority' name="priority" [disabled] = "assigned.length > 0" required>
            <mat-option [value]="1">
              1
            </mat-option>
            <mat-option [value]="2">
              2
            </mat-option>
            <mat-option [value]="3">
              3
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "data.imagePath == null">
          <input 
          type="file" 
          (input)="onImageChanged($event)" 
          #imageUpload
          style="display: none;"
          >
          <button mat-raised-button type="button" class="noBtn" (click)="imageUpload.click()" [disabled] = "assigned.length > 0">{{labels.lblUploadImage}}</button>
          <!-- <mat-label *ngIf="selectedImage">
                  {{selectedImage.name}}
          </mat-label> -->
      </mat-form-filed>
      <div *ngIf = "data.imagePath != null">
        <mat-label>{{labels.lblImage}}: {{data.imageName}}</mat-label>
        <mat-icon style="float:right; cursor: pointer;" (click)="updateData()" [class.disabled-icon] = "assigned.length > 0">cancel</mat-icon>
    </div>
        <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "data.trngInfoImagePath == null">
          <input 
          type="file" 
          (input)="onCoverImageChanged($event)" 
          #imageUpload2
          style="display: none;"
          >
          <button mat-raised-button type="button" class="noBtn" (click)="imageUpload2.click()" [disabled] = "assigned.length > 0">{{labels.lblUploadImage}}</button>
          <!-- <mat-label *ngIf="selectedImage">
                  {{selectedImage.name}}
          </mat-label> -->
      </mat-form-filed>
      <div *ngIf = "data.trngInfoImagePath != null">
        <mat-label>Naslovna {{labels.lblImage}}: {{data.coverImageName}}</mat-label>
        <mat-icon style="float:right; cursor: pointer;" (click)="updateCoverData()" [class.disabled-icon] = "assigned.length > 0">cancel</mat-icon>
    </div>

        <div mat-dialog-actions>
          <mat-progress-bar mode="indeterminate" style="margin-top: 15px;" *ngIf="uploading"></mat-progress-bar>
            <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
            <button class="btn" cdkFocusInitial type="submit"  style="margin-top: 15px;">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div>


