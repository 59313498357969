import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Training } from 'src/app/models/training.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';
import { UserBeforeTestMessageComponent } from '../../user-training/user-before-test-message/user-before-test-message.component';

@Component({
  selector: 'app-user-survey-message',
  templateUrl: './user-survey-message.component.html',
  styleUrls: ['./user-survey-message.component.scss']
})
export class UserSurveyMessageComponent implements OnInit {

  labelsSub:Subscription
  labels

  langSub:Subscription
  lang

  training$: Observable<Training>
  training: Training
  trainingSub: Subscription

  user
  userSub: Subscription

  button1 = ""
  button2 = ""
  color = ""
  constructor(public dialogRef: MatDialogRef<UserBeforeTestMessageComponent>,
    private userDashboardService: UserDashboardService,
    private authService: AuthService,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, private userLanguageService: UserLanguageService, private localStorageService:LocalStorageService) { }

  ngOnInit(): void {
    this.langSub = this.userLanguageService.lang$.subscribe((lang) =>{
      this.lang = lang;
    })
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) =>{
      this.labels = labels;
    })

    this.trainingSub = this.userDashboardService.training$.subscribe((training) => {
      this.training = training;
    })

    this.userSub = this.authService.user$.subscribe((user) => {
      this.user = user
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.button1 = "buttonEMS"
          this.button2 = "buttonEMS1"
          this.color = "#c32a2a"

        }else if(this.user.companyId == 2){
          this.button1 = "buttonECG"
          this.button2 = "buttonECG1"
          this.color = "rgb(241,106,100)"
        }
        else{
          this.button1 = "buttonECG"
          this.button2 = "buttonECG1"
          this.color = "rgb(241,106,100)"
        }
      }, 200);
    })
  }

  goToHomePage(){
    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.localStorageService.setItem('questionScores', [])
    // this.localStorageService.removeItem('test')
    this.dialogRef.close()
    this.router.navigateByUrl('user/training-details');
    //this.router.navigateByUrl('user/dashboard')

    setTimeout(() => {
      window.location.reload()
    }, 250)
  }

  downloadSurveyResults(trId) {
    this.userDashboardService.downloadSurveyResults(trId, this.lang);
  }

}
