<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblAddNewTest}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #insertForm="ngForm">
    <div fxLayout="column">
        <mat-form-field  appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblType}}</mat-label>
            <mat-select ngModel name='type' #type="ngModel" required>
                <mat-option (click) = "onSelect(1)" value="1">{{labels.lblTest}}</mat-option>
                <mat-option (click) = "onSelect(2)" value="2">{{labels.lblPretest}}</mat-option>
                <mat-option (click) = "onSelect(3)" value="3">{{labels.lblSurvey}}</mat-option>
            </mat-select>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%" *ngIf = "showFields == true">
            <mat-label *ngIf = "testType == 1">{{labels.lblTestName}}</mat-label>
            <mat-label *ngIf = "testType == 2">{{labels.lblPretestName}}</mat-label>
            <mat-label *ngIf = "testType == 3">{{labels.lblSurveyName}}</mat-label>
            <input matInput ngModel name="name" #name="ngModel" required>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%" *ngIf = "showFields == true && testType == 1">
            <mat-label>{{labels.lblMinPercentage}}</mat-label>
            <input matInput number ngModel name="minPercentage" #minPercentage="ngModel" required>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-checkbox
        *ngIf = "showFields == true && testType == 1" 
        ngModel
        name="duration-time"
        [checked]="true" 
        class="example-margin checkbox" 
        (change)="showDurationTime($event)"
        >
        {{labels.lblTestDuration}}  
        </mat-checkbox >
        <mat-form-field appearance="none" fxFlex="60%" *ngIf = "showFields == true && testType == 1 && showDurTime == true">
            <mat-label>{{labels.lblTestDuration}}</mat-label>
            <input matInput number ngModel name="durationTime" #durationTime="ngModel">
        </mat-form-field>

        <!-- Checkbox za Obavezan -->
        <mat-checkbox 
        appearance="none"
        class="example-margin checkbox"
        *ngIf = "showFields == true && testType == 1"
        (click)="toggleRequired()">
            <mat-label>{{labels.lblRequiredTest}}</mat-label>
        </mat-checkbox>

        <!-- Input za broj pokusaja -->
        <mat-form-field appearance="none" fxFlex="60%" *ngIf = "showFields == true && testType == 1">
            <mat-label>{{labels.lblNumofAttemptsTest}}</mat-label>
            <input matInput number name="attempts" [(ngModel)]="attempts" required/>
        </mat-form-field>
        <mat-checkbox 
        appearance="none"
        class="example-margin checkbox"
        *ngIf = "showFields == true && testType == 1"
        (click)="toggleIsOnlyTest()">
            <mat-label>Blokiraj posle X padova?</mat-label>
        </mat-checkbox>
        <mat-form-field appearance="none" fxFlex="60%" *ngIf = "showFields == true && testType == 1">
            <mat-label>Posebna poruka posle X padova</mat-label>
            <input matInput name="customFailMessage" [(ngModel)]="customFailMessage" required/>
        </mat-form-field>

        <mat-form-field  appearance="none" fxFlex="60%" *ngIf = "showFields == true">
            <mat-label *ngIf = "admin.isAdmin == true">{{labels.lblTrainer}}</mat-label>
            <h4  *ngIf = "admin.isAdmin == false">{{labels.lblTrainer}}</h4>
            <input *ngIf = "admin.isAdmin == false" matInput ngModel={{admin.id}} name = "trainerId" hidden>
            <div *ngIf = "admin.isAdmin == false">{{admin.firstName}} {{admin.lastName}}</div>
            <mat-select *ngIf = "admin.isAdmin == true" ngModel name='trainerId' required>
                <mat-option *ngFor = "let t of trainers" [value] = "t.id">{{t.firstName}} {{t.lastName}}</mat-option>
            </mat-select>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <div>
            <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
            <button class="btn" cdkFocusInitial style="float: right" type="submit">{{labels.lblAdd}}</button>
        </div>
    </div>
  </form>
</div>


