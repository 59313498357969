<app-user-spinner *ngIf="labels == null"></app-user-spinner>
<div class="container">
  <!-- <img src="https://collegiateacademy.co.uk/wp-content/uploads/2021/01/50192-9-exam-image-hq-image-free-png.png" alt=""> -->
  <div class='passedTest' *ngIf="data.passedTest">
    <img src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/passedTest.png?alt=media&token=c8eee031-4a6a-43bd-9866-e481ffacc9ed" class="imageFade" style="margin: auto; width:90px; height:90px; margin-bottom: 15px;" alt = "test image">
    <div>
      <h1 class="doneLabel">{{labels.lblYouPassed}}</h1>
      <hr>
      <h3>{{labels.lblCongrats}} <br>
        <!--{{meeting.description}}. <br>-->
        <!--{{labels.lblYourSuccesRate}} --> <span [style.color] = "color">{{data.percentage}}%</span><br>
        {{labels.lblYouAnswered}} <span [style.color] = "color">{{data.corrcetAnswersCounter}}</span> {{labels.lblOf}} <span [style.color] = "color">{{data.questions}}</span> {{labels.lblQuestions}}<br>
        {{labels.lblYouHaveWon}}  <span [style.color] = "color">{{data.points}}</span> od <span [style.color] = "color">{{data.maxPoints}}</span> {{labels.lblPoints}}.
      </h3>
      <div class="row">
        <!--
        <div class="col"  *ngIf = "training.testResults == true">
          <button mat-button class="btn" [class] = "button1" (click)="goToHomePage()">{{labels.lblHomePage}}</button>
        </div>
        -->
        <div class="col">
          <button mat-button class="btn" [class] = "button1" (click)="goToHomePage()">{{labels.lblHomePage}}</button>
        </div>
        <div class="col">
          <button mat-button class="btn"  [class] = "button2" (click)="downloadTestResults(data.trainingId)">{{labels.lblTest}}</button>
        </div>
        <!--
        <div class="col"  *ngIf = "training.testResults == true">
          <button mat-button class="btn" [class] = "button2" (click)="downloadTestResults(training.id)">{{labels.lblTest}}</button>
        </div>
        -->
        <!--
        <div class="col" style="margin: auto" *ngIf = "!training.testResults">
          <button mat-button class="btn" [class] = "button1" (click)="goToHomePage()">{{labels.lblHomePage}}</button>
        </div>
        -->
      </div>
    </div>
  </div>

  <div class='didntPassedTest' *ngIf="!data.passedTest">
    <img class="imageFade" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/cross.png?alt=media&token=4501c85b-0f29-4fa0-9199-ad4f8b7731e1" style="margin: auto; width:90px; height:90px; margin-bottom: 10px" alt = "test failed image">
    <div>
      <h1 class="highlightOffLabel">{{labels.lblYoudDidntPassed}}</h1>
      <hr>
     
        <h3>
        {{labels.lblMinPoints}}
        <span [style.color] = "color">{{data.minPercentage}}%.</span> <br>
        {{labels.lblYourSuccesRate}} <span [style.color] = "color">{{data.percentage}}%.</span><br>
        {{labels.lblYouAnswered}} <span [style.color] = "color">{{data.corrcetAnswersCounter}}</span> {{labels.lblOf}} <span [style.color] = "color">{{data.questions}}</span> {{labels.lblQuestions}}<br>
        {{labels.lblYouHaveWon}}  <span [style.color] = "color">{{data.points}}</span> {{labels.lblOf}} <span [style.color] = "color">{{data.maxPoints}}</span> {{labels.lblPoints}}.</h3>
      <!--<h5>{{labels.lblUnsuccessfulTestMessage}}</h5>-->
      <h3 *ngIf="data.timeIsUp" [style.color] = "color">{{labels.lblTimeIsUp}}</h3>
      <div class="row">
        <div class="col" *ngIf = "btnGoToTraining">
          <button style="width: 100%;" mat-button class="btn" [class] = "button1" (click)="goToTrainingPage()">{{labels.lblBack}}</button>
        </div>
        <div class="col" *ngIf = "btnTryAgain || true"> 
          <button style="width: 100%;" mat-button class="btn" [class] = "button2" (click)="restartTest()">{{labels.lblTryAgain}}</button>
        </div>
        <div class="col">
          <button style="width: 100%;" mat-button class="btn" [class] = "button1" (click)="goToHomePage()">{{labels.lblHomePage}}</button>
        </div>
       
      </div>
    </div>
  </div>
</div>