<!-- login -->
<app-user-spinner *ngIf="!hideSpinner"></app-user-spinner>
<div class="login-page">
  <app-user-spinner
            *ngIf="!hideSpinner || lang == null || labels == null">
            <mat-spinner class="spinner"></mat-spinner>
        </app-user-spinner>
  <div class="col-xs-12 loginForm">
    <div style = "text-align: center; margin-top: 10%;">
      <img class="lesteLogo" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/logo%20za%20login.png?alt=media&token=76b67278-2605-4de3-b4f2-5a00cefc77ca
">
      
      <!-- NIS LOGO -->
      <!-- <img  class="lesteLogo" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/logo%20za%20login.png?alt=media&token=76b67278-2605-4de3-b4f2-5a00cefc77ca
" alt = "nis logo"> -->

      <!-- EMS LOGO -->
      <!-- <img class="emsLogo" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webImgs%2FEMS-logo.png?alt=media&token=3f718d73-023e-4c42-a778-eea9a52cefea"> -->
    </div>
    <div class="forma1">
      <div class="loginLabel">
        <h2 class="loginLabel">{{labels.lblLogin}}</h2>
      </div>
      <form action="" #userLoginForm="ngForm" style = "margin-top: 7%;" (ngSubmit)="onSubmit(userLoginForm, false)">
        <mat-form-field class="no-line inputFieldForm" [class.redBorder] = 'err1' appearance="none">
          <span class = "inputIcon" style = "display: inline-block">
            <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/emailLoginIcon.png?alt=media&token=0999ab04-a16b-44ca-b745-326eb4edcd23" alt = "email login icon">
          </span>
          <span style = "display: inline-block; vertical-align: middle; padding-left: 10px;">
            <input matInput required name="email" ngModel #email="ngModel" placeholder = {{labels.lblUserName}}>
          </span>
        </mat-form-field>
        <mat-form-field class="no-line inputFieldForm" [class.redBorder] = 'err2' style = "margin-top: 5%; margin-bottom: 5%;" appearance="none">
          <span class = "inputIcon" style = "display: inline-block">
            <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/passwordLoginIcon.png?alt=media&token=b3ffcdd2-f9b5-45c9-9861-ee8885d964fc" alt = "password login icon">
          </span>
         <span style = "display: inline-block; vertical-align: middle; padding-left: 10px;">
          <input matInput [type]="isActive ? 'password' : 'text'" required minlength="6" name="password" ngModel
          #password="ngModel" placeholder = {{labels.lblPassword}}>
         </span>
         <span *ngIf = "isActive" class = "inputIcon" style = "float: right; margin-right: 11px; cursor: pointer;">
           <img (click) = "changeActive()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FhidePasswordIcon.png?alt=media&token=ef1f8cdf-3566-4e81-a032-efa014a52063" alt = "password icon hide">
          </span>
        <span *ngIf = "!isActive" class = "inputIcon" style = "float: right; margin-right: 11px; cursor: pointer;">
          <img (click) = "changeActive()" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FshowPasswordIcon.png?alt=media&token=a5b96b4e-5181-4d4a-8d1c-86f2f938633d" alt = "password icon show">
          </span>
        </mat-form-field>

        <div class="row logDiv" style="margin: auto">
          <div class="col-xs-12 col-sm-12 col-xl-6 text-left" style="font-style: normal;
          font-weight: normal;
          font-size: 16px; float: left; padding-left: 0; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; cursor: pointer">
          <mat-slide-toggle [checked] = "rememberMe"  (change) = "onRememberMeClick($event)">
            <span style = "font-style: normal;
            font-weight: normal;
            font-size: 16px; float: left; padding-left: 0; color: #0E0D35;font-family: Lato, 'Alegreya Sans';">{{labels.lblRememberMe}}</span>
           </mat-slide-toggle>
          </div>
          <div class="col-xs-12 col-sm-12 col-xl-6 text-right" style="font-style: normal;
          font-weight: normal;
          font-size: 16px; float: right; padding-right:0; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; cursor: pointer" (click)="openForgotPassword()">
            {{labels.lblForgotPass}}?
          </div>
        </div>

        <button class="login-button" type="submit">
          <div class = "row">
            <div class = "col-1"></div>
            <div class = "col-9" style = "margin: auto; text-align: center;">
              <h3 class = "loginBtnLabel">{{labels.lblLogin}}</h3>
            </div>
           <div class = "col-1"><img style = "float: right; padding-right: 15px;" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/loginIcon.png?alt=media&token=331da5b1-d77f-4c81-89a1-0e35b3feea30' alt = "login icon"></div>
          </div>
        </button>

        <div class = "row" *ngIf = "wrongInfo" style = "width: 70%; margin: auto;">
          <div class = "col-12 wrongInfoDiv">
            {{labels.lblWrongLoginData}}
          </div>
        </div>

        <!-- <div class="row or">
          <div class="col-xs-5 col-sm-5" style="height:40px; padding-top:0"><div style="height:50%; border-bottom: 1px solid #CECDD8"></div></div>
          <div class="col-xs-2 col-sm-2" style="padding-top:10px; height:40px; text-align: center; margin: auto; color: #7F799B;">{{labels.lblOr}}</div>
          <div class="col-xs-5 col-sm-5" style="height:40px;text-align: center; margin: auto;"><div style="height:50%; border-bottom: 1px solid #CECDD8"></div></div>
        </div>
        <div class="row loginWith" (click) = "loginWithGoogle()" style="cursor: pointer; text-align: center;margin:auto;height:50px;margin-top: 1.5vh; font-family: Poppins; font-weight:normal;font-size:12px; background-color: white;text-transform: none; color: #7F799B; border: 1px solid #CECDD8; border-radius:7px">
          <div class="col-4 text-right" style="margin: auto;">
          <div style="float:right;height:20px;width:20px; background-size: contain; background-repeat:no-repeat; background-image: url('https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/google.png?alt=media&token=74affd5f-5cfc-467d-aca8-ed98372efd36')"></div></div>
          <div class="col-8 text-left" style="padding-left:0; margin:auto"><div style="font-family: Poppins; letter-spacing:1px;">{{labels.loginGoogle}}</div>
         </div>
         </div>
        <div class="row loginWith" (click) = "facebookLogin()" style="cursor: pointer; text-align: center;margin:auto;height:50px;margin-top: 1.5vh; font-family: Poppins; font-weight:normal;font-size:12px; background-color: white;text-transform: none; color: #7F799B; border: 1px solid #CECDD8; border-radius:7px">
          <div class="col-4 text-right" style="margin: auto;">
          <div style="float:right;height:20px;width:20px; background-size: contain; background-repeat:no-repeat; background-image: url('https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/facebookIcon.png?alt=media&token=a2fa1832-8eab-4dd1-9672-d137cabc35dd')"></div></div>
          <div class="col-8 text-left" style="padding-left:0; margin:auto"><div style="font-family: Poppins; letter-spacing:1px;">{{labels.loginFacebook}}</div>
         </div>
         </div> -->
        <!-- <div class="dontHaveAnAccount">{{labels.lblDontHaveAccount}} <span style="color: rgb(241,106,100)" (click) = "openRegisterModal()">{{labels.lblSignUp}}</span></div> -->
      </form>
    </div>

  </div>
</div>