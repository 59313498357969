import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';


@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
  providers:[]
})
export class EditDialogComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  departments:Department[]
  positions:Position[]

  labels;
  labelsSub: Subscription;
  err: boolean = false
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService:AdminLanguageService) { }
    @ViewChild('editForm') editForm:NgForm

    
  onSubmit(){
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == ""  || this.editForm.value.email == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      this.editUser(this.editForm.value)
      console.log(this.editForm.value)
      this.dialogRef.close(this.editForm.value.firstName)
    }
  }
  onNoClick(): void {
   this.dialogRef.close();
  }
  ngOnInit(): void {
    this.err = false;
    this.fetcDepartments()
    this.fetchPositions()
    this.getLang()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  fetcDepartments(){
     return this.dashboardService.fetchAllDepartments().subscribe((response)=>{
       this.departments=response as Department[]
     })
  }
  fetchPositions(){
    return this.dashboardService.fetchPositions().subscribe((response)=>{
      this.positions=response as Position[]

    })
  }

  editUser(data){
    this.dashboardService.editUser(data).subscribe((response)=>{
      console.log(response)
    })
    this.snackBar.open(this.labels.lblUserEdited, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
  }

  goToNextStep(stepper: MatStepper){
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "") {
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      stepper.next()
    }
  }
  goBack(stepper: MatStepper){
    stepper.previous()
  }
}

