import { Component, Inject, OnInit } from '@angular/core';
//import { AngularAgoraRtcService } from 'angular-agora-rtc';
//import AgoraRTC from "agora-rtc-sdk-ng"
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDashboardService } from '../../user-dashboard.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth-service/auth.service';
import { User } from 'src/app/models/user.model';

const options = {
  appId: "2ebeef2a9f3c49368cee580b6b0e00d9",
};
@Component({
  selector: 'app-user-video-call',
  templateUrl: './user-video-call.component.html',
  styleUrls: ['./user-video-call.component.scss']
})
export class UserVideoCallComponent implements OnInit {

  // rtc = {
  //   client: null,
  //   localAudioTrack: null,
  //   localVideoTrack: null,
  // };

  navigatorStream;
  callingFlag: boolean = null;
  calledFlag: boolean = null;
  user: User;
 
  constructor(private dialogRef: MatDialogRef<UserVideoCallComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private userDashboardService: UserDashboardService,
              private db : AngularFirestore,
              private authService: AuthService) {
    
  }

  async ngOnInit() {

  //  this.db.firestore.collection('users').doc(this.data.userId).get().then((res) => {
  //    this.user = res.data() as User;
  //    console.log(this.user);
  //    this.calledFlag = this.user.calledByUserId != null;
  //    this.callingFlag = this.user.callingUserId != null;
  //    if(this.calledFlag || this.callingFlag) {
  //     this.startCall()
  //    }
  //  });
  
  }

  // async leaveVideoCall(){
  //   await this.rtc.client.unpublish([this.rtc.localVideoTrack, this.rtc.localAudioTrack]);
  //   if(this.rtc.localVideoTrack) this.rtc.localVideoTrack.close();
  //   if(this.rtc.localAudioTrack) this.rtc.localAudioTrack.close();

  //   this.db.collection('users').doc(this.data.userId).update({
  //     inCall: false,
  //     callingUserId: null,
  //     calledByUserId: null
  //   });

  //   if(this.user.callingUserId) {
  //     this.db.collection('users').doc(this.user.callingUserId).update({
  //       inCall: false,
  //       callingUserId: null,
  //       calledByUserId: null
  //     });
  //   }

    // Stop stream gotten with navigator

  //   this.rtc.client.leave()
  //   this.dialogRef.close()
  // }

  //async startCall(){

    // Require mic and cam permissions before joining
   // await AgoraRTC.getDevices();

   // this.rtc.client = await AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
    //const uid = String(this.data.userId);
  //   const uid = String(this.user.userId);

  //   if(this.calledFlag) {
  //     console.log("ZOVU ME");
  //     console.log("AGORA UID ", uid);
  //     console.log("AGORA APPID ", options.appId);
  //     console.log("AGORA CHANNEL ", this.user.agoraChannel);
  //     console.log("AGORA TOKEN ", this.user.agoraToken);
  //     await this.rtc.client.join(options.appId, String(this.user.agoraChannel), this.user.agoraToken, uid);
  //   }
  //   else {
  //     console.log("AGORA UID ", uid);
  //     console.log("AGORA APPID ", options.appId);
  //     console.log("AGORA CHANNEL ", this.data.agoraChannelName);
  //     console.log("AGORA TOKEN ", this.data.agoraToken);
  //     await this.rtc.client.join(options.appId, String(this.data.agoraChannelName), this.data.agoraToken, uid);
  //   }

  //   this.rtc.client.on('user-joined', async (user) => {
  //    // this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  //    //this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

  //     console.log("SET VIDEO AND AUDIO");
  //     await this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
  //   });

  //   this.rtc.client.on('user-left', async (user, reason) => {
  //     this.leaveVideoCall();
  //   });


  //   console.log("to publish rtstracks")
  //   this.rtc.client.on('user-published', async (user, mediaType) => {

  //     if(!this.rtc.localAudioTrack) {
  //       //this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
  //       await this.rtc.client.publish(this.rtc.localAudioTrack);
  //     }

  //     if(!this.rtc.localVideoTrack) {
  //      // this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
  //       await this.rtc.client.publish(this.rtc.localVideoTrack);
  //     }

  //     if(this.rtc.localVideoTrack) {
  //       this.rtc.localVideoTrack.play('myself');
  //     }

  //     await this.rtc.client.subscribe(user, mediaType);

  //     if (mediaType == 'audio') {
  //       const remoteAudioTrack = user.audioTrack;
  //       remoteAudioTrack.play();
  //     }

  //     if(mediaType == 'video') {
  //       const remoteVideoTrack = user.videoTrack;
  //       remoteVideoTrack.play("player");
  //     }
  //   });

  // }

}
