import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {
  @ViewChild('forgotPassForm') forgotPassForm:NgForm
  labels;
  labelsSub: Subscription;
  lang
  langSub: Subscription
  constructor(
  private dashboardService: UserDashboardService,
  private languageService: UserLanguageService, public dialog:MatDialog,
  private router: Router) { }

  ngOnInit(): void {
    this.langSub = this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })
    this.labelsSub = this.languageService.labels$.subscribe((labels) =>{
      this.labels = labels;
    })
    document.getElementById('zmmtg-root').style.display = 'none'
  }

  onSubmit(formData){
    this.dashboardService.resetPassword({email: formData.value.email,lang: this.lang}).subscribe((response)=>{
      console.log(response)
    })
  }

  backToLogin(){
    this.router.navigateByUrl('/')
  }

  // cancelModal(){
  //   this.dialogRef.close()
  // }

}
