<app-admin-spinner *ngIf="trainings==null || users==null || labels == null || departments == null || successfulTrainings == null"></app-admin-spinner>
<div class="row" style="margin: auto; text-align: center">
  <div class="col-12 col-sm-3" style="margin: auto; margin-top:1vh !important; background-color: #182038; border:1px solid #182038; border-radius:5px;">
   
    <div class="row">
      <div class="col-12" style="text-align:center; margin: auto">
        <h1 style="color: #3e4b71;
        font-family: 'Prompt', sans-serif;">{{labels.lblUsers}}</h1>
      </div>
      <div class="col-12" style="text-align:center; margin: auto">
        <h1 style="color: #8293c6;
        font-family: 'Prompt', sans-serif; font-size: 35px;">{{users?.length}}</h1>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-3" style="margin: auto;margin-top:1vh !important; background-color: #182038; border:1px solid #182038; border-radius:5px;">
    <div class="row">
      <div class="col-12" style="text-align:center; margin: auto">
        <h1 style="color: #3e4b71;
        font-family: 'Prompt', sans-serif;">{{labels.lblSectors}}</h1>
      </div>
      <div class="col-12" style="text-align:center; margin: auto">
        <h1 style="color: #8293c6;
        font-family: 'Prompt', sans-serif; font-size: 35px;">{{departments?.length}}</h1>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-3" style="margin: auto; margin-top:1vh !important;  background-color: #182038; border:1px solid #182038; border-radius:5px;">
    <div class="row">
      <div class="col-12" style="text-align:center; margin: auto">
        <h1 style="color: #3e4b71;
        font-family: 'Prompt', sans-serif;">{{labels.lblTrainings}}</h1>
      </div>
      <div class="col-12" style="text-align:center; margin: auto">
        <h1 style="color: #8293c6;
        font-family: 'Prompt', sans-serif; font-size: 35px;">{{trainings?.length}}</h1>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-3 typeLabel" style="margin: auto; margin-top:1vh !important; margin-bottom: 0px;  background-color: #1494f9; border:1px solid #1494f9; border-radius:5px;">
    <div class="row">
      <div class="col-12" style="text-align:center; margin: auto">
        <h1 *ngIf = "admin?.companyId" style="color: #3e4b71;
        font-family: 'Prompt', sans-serif; margin-top: 5% !important; margin-bottom: 5% !important;"><span *ngIf = "admin.isAdmin == true">
        <span *ngIf = "admin.companyId == 1">Elektromreža Srbije</span>
        <span *ngIf = "admin.companyId == 2">NIS</span>
        <span *ngIf = "admin.companyId == 3">Evropska konsalting grupa</span>
        <span *ngIf = "admin.companyId == 4">RGZ</span>
        </span><span *ngIf = "admin.isAdmin == false">Trener kompanija</span></h1>
      </div>
    </div>
  </div>

</div>

<br>

<mat-divider></mat-divider>

<br>

<div class="row" style="margin:auto; ">
    <div class="col-12 col-xl-5" style="margin: auto !important;margin-top:3vh !important">
        <app-status-pie>

        </app-status-pie>
    </div><br>
    <div class="col-12 col-xl-6" style="margin: auto !important;margin-top:3vh !important">
      <app-widget-pie>

      </app-widget-pie>
    </div><br><br> 
    <div class="col-12 col-xl-6" style="margin: auto !important; margin-top:3vh !important;">
      <app-apex-chart>

      </app-apex-chart>
    </div>
    <div class="col-12 col-xl-5" style="margin: auto !important; margin-top:3vh !important;">
      <app-test-success-pie>
        
      </app-test-success-pie>
    </div>
   
   
</div>