import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { RequestObject } from 'src/app/models/trainingUserRequest.model';
import { User } from 'src/app/models/user.model';
import { UserTrainingsDialogComponent } from '../../admin-user/user-trainings-dialog/user-trainings-dialog.component';
import { DashboardService } from '../../dashboard.service';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { UserDashboardService } from '../../user-dashboard.service';
export interface AssignData {
  id?: string
  type?: number
  testId?: string
  pretestId?: string
  surveyId?: string
}

@Component({
  selector: 'app-assign-test',
  templateUrl: './assign-test.component.html',
  styleUrls: ['./assign-test.component.scss']
})
export class AssignTestComponent implements OnInit {

  @ViewChild('trainingsTestForm', { static: true }) trainingsTestForm: NgForm
  trainings: Training[]
  selectedCheckBoxes: string[]
  selectedTrainings: Training[]
  test$: Observable<Test>
  test: Test
  user: User
  userSub: Subscription;
  labels;
  labelsSub: Subscription;

  
  testSub: Subscription
  constructor( private userDashboarService: UserDashboardService, public snackBar: MatSnackBar, private authService: AdminAuthService, public dialogRef: MatDialogRef<UserTrainingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.userSub = this.authService.admin$.subscribe((user: User) => {
      this.user = user;
    });
    this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.data.id).pipe(share())
    this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
      this.test = test;
    });
    this.fetchTrainingsNotForTest()
    this.selectedCheckBoxes = new Array<string>()
    this.selectedTrainings = new Array<Training>()
    this.getLang();

  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  onNoClick() {
    this.dialogRef.close()
  }
  onSubmit() {
    console.log("SUBMIT")

    for (let index = 0; index < this.selectedTrainings.length; index++) {
      let dta: AssignData = {
        id: this.selectedTrainings[index].id
      }
      if(this.data.type == 1){
        dta.type = 1
        dta.testId = this.data.id
      }
      else if(this.data.type == 2){
        dta.type = 2
        dta.pretestId = this.data.id
      }
      else{
        dta.type = 3
        dta.surveyId = this.data.id
      }
      this.assignTestToTraining(dta)
    }
  }

  assignTestToTraining(dta){
    this.dashboardService.assignTestToTraining(dta).subscribe(response => {
      console.log(response)
      this.dialogRef.close()
      this.snackBar.open(this.labels.lblTestAssigned, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.fetchTrainingsNotForTest()
    })
  }

  fetchTrainingsNotForTest() {
    console.log(this.data.id)
    this.dashboardService.getTrainingsNotForTest(this.data.id, this.data.type).subscribe((trainings: Training[]) => {
      this.trainings = trainings as Training[]
      console.log(this.trainings)
    })
  }

  getTrainingId(e: any, training: Training) {
    if (e.checked) {
      this.selectedTrainings.push(training)
    } else {
      this.selectedTrainings = this.selectedTrainings.filter((element) => element.id != training.id)
    }
    console.log(this.selectedTrainings)
  }

  // updateTrainings({ id, trainings }) {
  //   console.log(trainings)
  //   this.dashboardService.updateTrainings({ id, trainings }).subscribe((response) => console.log(response))
  // }
}

