import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { DashboardService } from './../../dashboard.service'
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Department } from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import { Unit } from './../../../models/unit.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';

@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {

  @ViewChild('insertForm') insertForm: NgForm
  nameControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  departments
  labels;
  labelsSub: Subscription
  nameErr: boolean = false;
  emailErr: boolean = false;
  administrators
  admin: Administrator
  adminSub: Subscription
  constructor(private adminAuthService: AdminAuthService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.nameErr = false;
    this.emailErr = false;
    this.getAdmin()
    this.getLang()
    //this.fetchAdministrators()
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  /* fetchAdministrators() {
    this.dashboardService.fetchAdministrators().subscribe((response) => {
      this.administrators = response
    })
  } */

  onSubmit() {
    if (this.insertForm.value.name == "" || this.insertForm.value.adminId == "" || this.insertForm.value.email == "") {
      this.nameErr = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }
    if (this.insertForm.value.name != "" && this.insertForm.value.email != "" && this.insertForm.value.adminId != "") {
      if (this.sectorNameAlreadyExists(this.insertForm.value.name)) {
        this.snackBar.open(this.labels.lblSectorNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else {
        console.log(this.insertForm.value)
        this.dashboardService.insertDepartment(this.insertForm.value).subscribe((response) => {
          console.log(response)
          this.snackBar.open(this.labels.lblSectorAdded, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
          this.dialogRef.close()
        })
      }
    }
  }

  fetchDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response
    })
  }
  fetchDepartments1() {
    this.dashboardService.fetchAdminsDepartments(this.admin.departmentId).subscribe((response) => {
      this.departments = response as Department[]
    })
  }

  sectorNameAlreadyExists(sectorName: string): boolean {
    for (let index = 0; index < this.departments.length; index++) {
      if (this.departments[index].name == sectorName) {
        return true
      }
    }
    return false
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.fetchDepartments();
        }
        else{
          this.fetchDepartments1();
        }
      }, 100);
    });
  }
}
