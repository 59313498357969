<mat-dialog-container style = "background-color: #0E0D35 !important; border-radius: 35px;">
    <mat-dialog-content style = "background-color: #0E0D35 !important; border-radius: 35px;">
        <div class="testQuestion">
            <h3 class="questionText" style = "font-size: 2.3vh; font-weight: normal;">
                {{labels.lblControlQuestion.toUpperCase()}}
            </h3>
            <h3 class="questionText" style = "font-size: 3.7vh;">
                {{data.controlQuestion.text}}
            </h3>
             <h4 *ngIf="data.controlQuestion.type == 2"><span style="color:rgb(241,106,100);">*{{labels.lblSeveralCorrectAnswers}}</span></h4>
            <div class="answer" style = "display: inline-flex !important;" *ngFor="let answer of data.controlQuestion.answers"
                [class.answerSelected]='answer.selected'>
                <mat-checkbox [value]="answer" class="checkBox" style = "width: 100%;" [(ngModel)]="answer.selected"
                    (change)="onSelect(data.controlQuestion, answer);">
                    <span style = "white-space: break-spaces;">
                        {{answer.textAns1}}</span>
                </mat-checkbox>
            </div>

            <div *ngIf = "isClicked" style = "text-align: right;">
                <button mat-raised-button class="button"
                (click)="submit(data.controlQuestion)">
                {{labels.lblSubmit}} <mat-icon style = "vertical-align: middle; margin-left: 15px; color: #0E0D35;">arrow_forward</mat-icon></button></div>
        </div>
    </mat-dialog-content>
</mat-dialog-container>