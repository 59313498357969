import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import {Unit} from './../../../models/unit.model'
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  assigned
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService,
    private adminLanguageService: AdminLanguageService, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getLang()
  }
  deleteTest(){
    this.dashboardService.deleteTest(this.data.id).subscribe((response)=>{
      this.dialogRef.close()
      this.snackBar.open(this.labels.lblTestDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000  })
    })
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  isTestAssigned(){
    this.dashboardService.isTestAssigned(this.data.id).subscribe((response)=>{
    console.log(response)
     if(response == null){
      this.assigned = []
     }else{
      this.assigned = response
     }
    })
  }
  onNoClick(){
    this.dialogRef.close()
  }
}
