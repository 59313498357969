<app-admin-spinner *ngIf="dataSource == null || labels == null || training == null"></app-admin-spinner>
<div fxLayout="row table1" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf='outlet.isActivated===false'>
    <div fxFlex="5"></div>
    <div fxFlex="90">
        <!-- <mat-card class="header">
             <div fxLayout="row" fxFlex="100">
                <mat-card-title fxFlex="90">Table of Sections</mat-card-title>
                <mat-card-content fxFlex="10" fxFlex="40" fxFlex.sm="60" fxFlex.xs="80">
                    <button mat-raised-button class="tableBtn" (click)="addNewSection()" style="margin: 0.7vw;">Add new section</button>
                    <button mat-raised-button class="tableBtn" (click)="showChangeButton()" *ngIf='value===true'>
                        Change order      
                    </button>
                    <button mat-raised-button color="primary" (click)="changeOrder()" *ngIf='value===false'>
                        Save this order      
                    </button>
                    <form>
                        <button mat-raised-button color="primary" (click)="addNewSection()">Add new section</button>
                        <button mat-raised-button color="primary" (click)="addNewSection()">Add new section</button>
                    </form>
                </mat-card-content>
            </div>
        </mat-card> -->
        <div class="mat-elevation-z8">
            <div class="row header">
                <div class="col-12 col-xl-6 text-left">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb" style="background-color: #182038; font-size: 16px;">
                            <li class="breadcrumb-item"><a style="color: #3e4b71" routerLinkActive="list-item-active"
                                    routerLink="/admin/trainings"><mat-icon>home</mat-icon></a></li>
                                    <li class="breadcrumb-item" style="color: #8293c6"><a style="color: #8293c6; cursor: pointer;" [routerLink]="['/admin/training-details',training.id]"><span data-toggle="tooltip" data-placement="top" title={{training.name}}>{{training.name.substring(0,21)}}<span *ngIf = "training.name.length > 21">...</span></span></a></li>
                            <li aria-current="page" style="color: #9dacd9"> <span
                                    style="margin-left:10px; margin-right: 10px;">></span>{{labels.lblSections}}</li>
                        </ol>
                    </nav>
                </div>
                <div class="col-12 col-xl-6 addNewSectionBtn"> <button mat-raised-button class="tableBtn addNewSectionBtn1" (click)="addNewSection()" [disabled] = "assigned.length > 0"
                        >{{labels.lblAddNewSection}}</button>
                    <button mat-raised-button class="tableBtn hideColumn" (click)="showChangeButton()"
                        *ngIf='value===true' [disabled] = "assigned.length > 0">
                        {{labels.lblChangeOrder}}
                    </button>
                    <button mat-raised-button class="tableBtn hideColumn" (click)="changeOrder()" *ngIf='value===false'>
                        {{labels.lblSaveOrder}}
                    </button>
                </div>
                <div class="col-10 text-left search1">
                        <mat-form-field class="search" appearance="none" fxFlex="40%">
                            <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
                          </mat-form-field>
                </div>
            </div>
            <table matSortDisableClear="true" mat-table [dataSource]="dataSource" matSort cdkDropList [cdkDropListData]="dataSource"
                (cdkDropListDropped)="drop($event)" #table class="drag-drop-list-sections">
                <!-- userName Column -->
                <ng-container matColumnDef="order">
                    <th class="orderColumn" style="width: 10%;" mat-header-cell *matHeaderCellDef mat-sort-header><span class="order">{{labels.lblOrder}}</span><span class="order1">{{labels.lblO}}</span></th>
                    <td mat-cell *matCellDef="let trainingSection">
                        <mat-icon *ngIf="!value" style="cursor: pointer;" class="settings">
                            import_export
                        </mat-icon>{{trainingSection.order}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblSectionName}}</th>
                    <td mat-cell *matCellDef="let trainingSection" data-toggle="tooltip" data-placement="top" title={{trainingSection.name}} class="sectionName" [routerLink]="['section-detail',trainingSection.id]">
                        {{trainingSection.name.substring(0,50)}}<span *ngIf="trainingSection.name.length > 50">...</span>
                    </td>
                </ng-container>

                <!-- firstName Column -->

                <ng-container matColumnDef="numOfUnits">
                    <th style="width: 20%;" mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblNumOfUnits}}</th>
                    <td style="width: 20%;" mat-cell *matCellDef="let trainingSection" class="hideColumn"> {{trainingSection.numOfUnits}}
                    </td>
                </ng-container>
                <!--Actions column-->
                <ng-container matColumnDef="actions">
                    <th style="width: 20%;" mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
                    <td style="width: 20%;" mat-cell *matCellDef="let trainingSection">
                        <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="editSection(trainingSection)">
                            {{labels.lblEdit}}
                        </button>
                        <button class="deleteBtn d-none d-sm-inline-flex" (click)="deleteSection(trainingSection)">
                            {{labels.lblDelete}}
                        </button> -->
                        <button *ngIf = "trainingSection.numOfUnits > 0" class="addNewBtn d-sm-inline-flex"
                            [routerLink]="['section-detail',trainingSection.id]">
                            <!-- <mat-icon>view_list</mat-icon> -->
                            {{labels.lblUnits}}
                        </button>
                        <button *ngIf = "trainingSection.numOfUnits == 0" class="addNewBtnRed d-sm-inline-flex"
                            [routerLink]="['section-detail',trainingSection.id]">
                            <!-- <mat-icon>view_list</mat-icon> -->
                            {{labels.lblUnits}}
                        </button>
                        <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
                        <mat-menu #settingsMenu="matMenu" class="mMenu">
                            <button mat-menu-item class="menuButton settingsMenuItem" style="color: #8293c6;" (click)="assignTest(trainingSection)">
                                <mat-icon style = "color: #8293c6">description</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblAssignTest}}</span>
                              </button>
                            <button mat-menu-item class="menuButton settingsMenuItem"
                                (click)="editSection(trainingSection)">
                                <mat-icon style="color: #8293c6;">edit</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                            </button>
                            <button mat-menu-item class="menuButton settingsMenuItem"
                                (click)="deleteSection(trainingSection)" [disabled] = "assigned.length > 0">
                                <mat-icon style="color: #8293c6;">delete</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                            </button>
                        </mat-menu>

                        <!-- mobile -->
                        <!-- <button class="editBtn d-block d-sm-none mobBtn" (click)="editSection(trainingSection)">
                            {{labels.lblEdit}}
                        </button>
                        <button class="deleteBtn d-block d-sm-none mobBtn" (click)="deleteSection(trainingSection)">
                            {{labels.lblDelete}}
                        </button>
                        <button class="addNewBtn d-block d-sm-none mobBtn"
                            [routerLink]="['section-detail',trainingSection.id]">
                            {{labels.lblUnits}}
                        </button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragDisabled]="value"
                    [cdkDragData]="row" class="drag-drop-item-section"></tr>

            </table>

            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div fxFlex="5"></div>
</div>

<router-outlet #outlet='outlet'></router-outlet>