<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<div class="row">
    <div class="col-12" style="margin: auto">
        <form ngForm (ngSubmit)="onSubmit()" #insertForm="ngForm" class="qForm row">

            <div class="row" style="margin: auto; text-align: center; width: 100%;">
              <div class="col-12" style="margin: auto">
                <mat-form-field class="txtarea" appearance="none" fxFlex="100%" style="width: 100%">
                  <mat-label>{{labels.lblEnterQuestion}}</mat-label>
                  <input matInput ngModel name="questionText" required>
                  <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12" style="margin: auto;">
                <mat-form-field appearance="none" fxFlex="100%" style="width: 100%">
                  <mat-label>{{labels.lblType}}</mat-label>
                  <mat-select ngModel name='type' required>
                    <mat-option value="1" (click)="setTypeOfAnswer(1)">{{labels.lblOneCorrectAns}}</mat-option>
                    <mat-option value="2" (click)="setTypeOfAnswer(2)">{{labels.lblMoreThanOne}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-sm-12" style="text-align: right">
                <!-- <input type="number" name="numOfAns" (input)="setNumOfAns(4)" placeholder="upisite broj odgovora"/> -->
                <button type="submit" mat-button class="tableBtn">{{labels.lblNext}}</button>
              </div>
          </form>
    </div>
</div>
