import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  @ViewChild('insertForm') insertForm:NgForm
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  constructor(public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService,
    public adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.err = false;
    this.getLang()
  }

  onNoClick(){
    this.dialogRef.close()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  onSubmit(){
    if(this.insertForm.value.name == ""){
      this.err = true
       this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
    let data={
      id:this.data.id,
      name:this.insertForm.value.name,
      trainingId: this.data.trainingId
    }
    this.dashboardService.editSection(data).subscribe((response)=>{
      console.log(response)
      this.snackBar.open(this.labels.lblTrainingSectionEdited, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
   }
  }

}
