import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Observable, Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  downloadUrl$: Observable<string>
  downloadUrlSub: Subscription
  selectedImage: File = null
  labels;
  labelsSub: Subscription;
  err: boolean = false
  trainers
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService:AdminLanguageService, private storage : AngularFireStorage) { }
    @ViewChild('editForm') editForm:NgForm

    
  onSubmit(){
    console.log(this.editForm.value.userName)
    console.log(this.editForm.value.email)
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "" || this.editForm.value.departmentId == "" || this.editForm.value.email == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else {
      let trainer = {
        trainerId: this.data.trainerId,
        userName: this.editForm.value.userName,
        firstName: this.editForm.value.firstName,
        lastName: this.editForm.value.lastName,
        email: this.editForm.value.email,
        dateOfBirth: this.editForm.value.dateOfBirth,
        about: this.editForm.value.about,
        profileImagePath: ""
      }

      if(this.emailAlreadyExists(this.editForm.value.email)){
        this.snackBar.open(this.labels.lblEmailAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if(this.userNameAlreadyExists(this.editForm.value.userName)){
        this.snackBar.open(this.labels.lblUserNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else{
      if (this.selectedImage == null) {
        delete trainer.profileImagePath
        this.editTrainer(trainer)
            setTimeout(() => {
              this.dialogRef.close()
              this.snackBar.open(this.labels.lblTrainerEdited, 'OK', {
                verticalPosition: 'top',
                horizontalPosition: 'end',
                duration: 5000  })
            }, 2000)
      }
      else {
        let ref = this.storage.ref(this.selectedImage.name)
        ref.put(this.selectedImage).then(t => {
          this.downloadUrlSub = ref.getDownloadURL().subscribe(url => {
            trainer.profileImagePath = url
            this.editTrainer(trainer)
            console.log(this.editForm.value)
            setTimeout(() => {
              this.dialogRef.close()
            }, 2000)
          })
        })
      }
      }
    }
  }
  onNoClick(): void {
   this.dialogRef.close();
  }
  ngOnInit(): void {
    this.err = false;
    this.getLang()
    this.getTrainers()
  }
  goToNextStep(stepper: MatStepper){
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "") {
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      stepper.next()
    }
  }

  goBack(stepper: MatStepper){
    stepper.previous()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  editTrainer(data){
    this.dashboardService.editTrainer(data).subscribe((response)=>{
      console.log(response)
      this.getTrainers()
    })
  }

  onImageChanged(event) {
    this.selectedImage = event.target.files[0]
  }

  getTrainers(){
    this.dashboardService.getTrainers().subscribe((trainers)=>{
      console.log(trainers)
      this.trainers = trainers
      this.trainers = this.trainers.filter((t) => t.id != this.data.id)
  })
}

emailAlreadyExists(email: string): boolean {
  for (let index = 0; index < this.trainers.length; index++) {
    if (this.trainers[index].email == email) {
      return true
    }
  }
  return false
}

userNameAlreadyExists(userName: string): boolean {
  for (let index = 0; index < this.trainers.length; index++) {
    if (this.trainers[index].userName == userName) {
      return true
    }
  }
  return false
}
}
