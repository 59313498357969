import { Component, OnInit } from '@angular/core';
import { AuthService } from './../services/auth-service/auth.service'
import { Router } from '@angular/router'
import { DataServiceService } from '../data-service.service'
import { User } from '../../../../../Leste 3.0/models/User'
import { Subscription } from 'rxjs';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserForgotPasswordComponent } from './user-forgot-password/user-forgot-password.component';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app'
import 'firebase/auth'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { UserRegisterComponent } from './user-register/user-register.component';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
declare var FB: any
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  private user: User

  lang
  langSub: Subscription;

  labels;
  labelsSub: Subscription;

  err: boolean = false
  err1: boolean = false
  err2: boolean = false

  wrongInfo: boolean = false;

  backgroundClass: String = "backgroundLearning"
  header
  first: boolean = true
  second: boolean = false
  third: boolean = false
  showLoginPage: boolean = false
  hideSplashScreen: boolean =  false
  index: number = 1

  isActive: boolean = true;
  rememberMe: boolean = false;
  hideSpinner: boolean = true;
  constructor(private afs: AngularFirestore, private auth: AngularFireAuth, private authService: AuthService, private router: Router, private languageService: UserLanguageService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService) {
    this.languageService.setLanguage('srb');
  }

  photoUrl: string = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/LESTE%20LOGO%20LATINICA.PNG?alt=media&token=32e047fb-466b-42ba-bf5d-54b3d944ff57'
  position
  ngOnInit(): void {
    // if(this.localStorageService.getItem("splashScreenLoaded")){
    //   this.showLoginPage = true;
    //   this.hideSplashScreen = true;
    // }else{
    //   this.showLoginPage = false;
    //   this.hideSplashScreen = false;
    // }

    this.rememberMe = this.localStorageService.getItem("rememberUser")

    if(this.localStorageService.getItem("rememberUser") == true && this.localStorageService.getItem("userName") != null && this.localStorageService.getItem("pass") != null) {
      this.hideSpinner = false;
      console.log("BREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
      console.log(this.localStorageService)
      this.onSubmit({email: this.localStorageService.getItem("userName"), password: this.localStorageService.getItem("pass")}, true);
    }
    this.position = "move"
    var w = window as any
    this.err = false;
    this.langSub = this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    //document.getElementById('zmmtg-root').style.display = 'none'
   
    this.wrongInfo = false
    this.header = this.labels.lblLearning
    // w.fbAsyncInit = function () {
    //   FB.init({
    //     appId: '259968579148455',
    //     cookie: true,
    //     xfbml: true,
    //     version: 'v10.0'
    //   });

    //   FB.getLoginStatus(function(response) {
    //     console.log("login status1")
    //     console.log(response)
    //   });

    //   FB.AppEvents.logPageView();

    // };

    // (function (d, s, id) {
    //   var js, fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) { return; }
    //   js = d.createElement(s); js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));
  }
  ngOnDestroy(): void {

  }
  onSubmit(formData, flg) {
    this.hideSpinner = false;
    if(flg == true) {
      this.authService.login(formData).subscribe((response) => {
        if (response.err == "greska" || response.err == "notAccepted") {
          this.wrongInfo = true;
          this.router.navigateByUrl('/')
        }else {
          this.wrongInfo = false
          this.hideSpinner = true;
          this.router.navigateByUrl('/user/dashboard')
          //this.router.navigateByUrl('/user/splash-screen')
        }
      })
    } else {
      if (formData.value.email == "" || formData.value.password == "") {
        this.err = true;
        console.log("ERRR 222");
        if(formData.value.email == ""){
          this.err1 = true
        }
        if(formData.value.password == ""){
          this.err2 = true
        }
      } else {
        this.authService.login(formData.form.value).subscribe((response) => {
          if (response.err == "greska" || response.err == "notAccepted") {
            this.wrongInfo = true;
            console.log("ERRR 1");
            if(response.err == "notAccepted"){
              this.router.navigateByUrl('/terms-of-use')
            }else{
              this.router.navigateByUrl('/')
            }
          }else {
            this.wrongInfo = false
            this.router.navigateByUrl('/user/dashboard')
            // this.router.navigateByUrl('/user/splash-screen')
          }
          this.hideSpinner = true;
        })
      }
    }
  }

  goToLogin(){
    this.showLoginPage = true
    this.hideSplashScreen = true
    this.localStorageService.setItem("splashScreenLoaded", true)
  }

  

  loginWithGoogle() {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((result) => {
      var user = result.user
      this.authService.loginWithGoogle({ uid: user.uid, email: user.email }).subscribe((response) => {
        if (response.err == "greska") {
          this.router.navigateByUrl('/')
          console.log("ne postoji korisnik u bazi")
        } else {
          this.router.navigateByUrl('/user/dashboard')
          console.log("postoji korisnik u bazi")
        }
      })
    })
  }

  // loginWithFacebook() {
  //   FB.getLoginStatus((response) => {
  //     console.log("get login status")
  //     console.log(response)
  //     if (response.status == 'connected') {
  //       console.log("Login successful1")
       
  //     } else if (response.status == 'not_authorized') {
  //       FB.login((response) => {
  //         console.log("RESPONSE LOGIN .")
  //         console.log(response)
  //         if (response.authResponse) {
  //           console.log("Login successful")
  //         } else {
  //           console.log("Login failed")
  //         }
  //       })
  //     }else{
  //       console.log("Login failed 1.")
  //     }
  //   })
  //   console.log("BRE")
  //   this.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
  //     console.log("sign in with popup")
  //     var user = result.user
  //     console.log(user)
  //     this.authService.loginWithGoogle({ uid: user.uid, email: user.email }).subscribe((response) => {
  //       console.log("response")
  //       console.log(response)
  //       if (response.err == "greska") {
  //         this.router.navigateByUrl('/')
  //         console.log("ne postoji korisnik u bazi")
  //       } else {
  //         this.router.navigateByUrl('/user/dashboard')
  //         console.log("postoji korisnik u bazi")
  //       }
  //     })
  //   })

  // }
  
  async checkLoginState() {
    console.log("check login state")
    await FB.login(function(response){
       console.log("login")
       console.log(response)
      FB.getLoginStatus(async function(response) {
        console.log(response)
        console.log(response.status)
        if(response.status == 'connected'){
          console.log("user is connected")
           await this.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
            console.log("sign in with popup")
            var user = result.user
            console.log(user)
            this.authService.loginWithGoogle({ uid: user.uid, email: user.email }).subscribe((response) => {
              console.log("response")
              console.log(response)
              if (response.err == "greska") {
                this.router.navigateByUrl('/')
                console.log("ne postoji korisnik u bazi")
              } else {
                // this.router.navigateByUrl('/user/dashboard')
                this.router.navigateByUrl('/user/splash-screen')
                console.log("postoji korisnik u bazi")
              }
            })
          })
       
        }
       });
    },  {scope: 'email'})
  }

  facebookLogin(){
     this.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
      var user = result.user
      console.log(user)
      this.authService.loginWithGoogle({ uid: user.uid, email: user.email }).subscribe((response) => {
        if (response.err == "greska") {
          this.router.navigateByUrl('/')
          console.log("ne postoji korisnik u bazi")
        } else {
          this.router.navigateByUrl('/user/dashboard')
        }
      })
    })
  }

  selectLang(lang) {
    this.languageService.setLanguage(lang);
  }
  openForgotPassword() {
    this.router.navigateByUrl('/reset-password')
    // const dialogRef = this.dialog.open(UserForgotPasswordComponent, {
    //   disableClose: true,
    //   width: '500px',
    //   data: {lang: this.lang}
    // })
  }

  openRegisterModal() {
    const dialogRef = this.dialog.open(UserRegisterComponent, {
      disableClose: true,
      width: '600px'
    })
  }

  changeActive(){
    this.isActive = !this.isActive;
  }

  onRememberMeClick($event: MatSlideToggleChange) {
    this.rememberMe = !this.rememberMe;
    console.log("REMEMBER ME: ", this.rememberMe);
    this.localStorageService.setItem("rememberUser", this.rememberMe);
  }

}
