import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { DeleteDialogComponent } from '../../admin-user/delete-dialog/delete-dialog.component';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';
export interface DialogData{
  user:User
}
@Component({
  selector: 'app-image-delete-dialog',
  templateUrl: './image-delete-dialog.component.html',
  styleUrls: ['./image-delete-dialog.component.scss']
})
export class ImageDeleteDialogComponent implements OnInit {
  
  labels;
  labelsSub: Subscription;
  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private userDashboardService:UserDashboardService, private userLanguageService: UserLanguageService) { }

    onNoClick(): void {
      this.dialogRef.close();
     }
     deleteImage(){
       this.data.user.profileImagePath = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/noImage.jpg?alt=media&token=b8c8a3ec-2293-4657-bfd0-c10c19255144"

       this.userDashboardService.editUserInfo({
        flg: "img",
        id: this.data.user.id,
        profileImage: this.data.user.profileImagePath
      }).subscribe((response) => {
        console.log(response)
      })
         this.dialogRef.close();
     }
     
     ngOnInit(): void {
       this.getLang();
     }
   
     getLang(){
       this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
       this.labels = labels;
         })
     }
   

}
