import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { DashboardService } from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Department } from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { AngularFireStorage } from '@angular/fire/storage'
import { Trainer } from 'src/app/models/trainer.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperDialogComponent } from '../../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  @ViewChild('editForm', { static: true }) editForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  @ViewChild('imageUpload2') imageUpload2: ElementRef

  labels;
  labelsSub: Subscription;
  err: boolean = false;
  selectedImage: File = null
  selectedCoverImage: File = null
  downloadUrl$: Observable<string>
  downloadUrlSub: Subscription
  trainers: Trainer[]
  assigned
  uploading: boolean = false

  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, public adminLanguageService: AdminLanguageService, private storage: AngularFireStorage, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getImgName()
    this.getCoverImgName()
    console.log(this.data)
    this.err = false;
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.getTrainers()
    this.isTrainingAssigned()
    console.log(this.data)
  }

  onSubmit() {
    console.log('on submit')
    console.log(this.editForm.value)
    if (this.editForm.value.name == "" || this.editForm.value.description == "" || this.editForm.value.trainerId == "" || this.editForm.value.priority == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.uploading = true
      let data
      if (this.assigned.length > 0) {
        data = {
          id: this.editForm.value.id,
          name: this.editForm.value.name,
          description: this.editForm.value.description,
          priority: this.editForm.value.priority,
          imagePath: this.data.imagePath,
          trngInfoImagePath: this.data.trngInfoImagePath,
          trainerId: this.data.trainerId,
          dateCreated: new Date()
        }

      }
      else {
        data = {
          id: this.editForm.value.id,
          name: this.editForm.value.name,
          description: this.editForm.value.description,
          imagePath: this.data.imagePath,
          trngInfoImagePath: this.data.trngInfoImagePath,
          trainerId: this.editForm.value.trainerId,
          dateCreated: new Date(),
          priority: this.editForm.value.priority,
        }
      }

      if (this.selectedImage != null) {
        let ref = this.storage.ref(this.selectedImage.name)
        ref.put(this.selectedImage).then(t => {
          this.downloadUrlSub = ref.getDownloadURL().subscribe(url => {
            data.imagePath = url
            this.dashboardService.updateTraining(data).subscribe((response) => {
              console.log(response)
              this.dialogRef.close()
            })
          })
        })
      }
      if (this.selectedCoverImage != null) {
        let ref = this.storage.ref(this.selectedCoverImage.name)
        ref.put(this.selectedCoverImage).then(t => {
          this.downloadUrlSub = ref.getDownloadURL().subscribe(url => {
            data.trngInfoImagePath = url
            this.dashboardService.updateTraining(data).subscribe((response) => {
              console.log(response)
              this.dialogRef.close()
            })
          })
        })
      }

      if(this.selectedImage == null && this.selectedCoverImage == null) {
        this.uploading = false
        this.dashboardService.updateTraining(data).subscribe((response) => {
          console.log(response)
          this.snackBar.open(this.labels.lblTrainingEdited, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
          this.dialogRef.close()
        })
      }

    }
  }

  isTrainingAssigned() {
    console.log(this.data.id)
    this.dashboardService.isTrainingAssigned(this.data.id).subscribe((response) => {
      if (response == null) {
        this.assigned = []
      } else {
        this.assigned = response
      }
      console.log(this.assigned)
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }

  onImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "jpg" || ext == "jpeg" || ext == "png") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 250){ 
        this.selectedImage = event.target.files[0]
        const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
          width: "500px",
          height: "600px",
          data: event
        })

        dialogRef.afterClosed().subscribe((result) => {
          if (result == null) {
            this.imageUpload.nativeElement.value = ""
            this.updateData()
          }
          else {
            this.selectedImage = result
            this.data.imageName = this.selectedImage.name
            this.data.imagePath = ""
          }
        })
      }
      else {
        this.snackBar.open(this.labels.lblMaxFileSizeImage, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }

    }
    else {
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }


  }


  onCoverImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "jpg" || ext == "jpeg" || ext == "png") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 250){ 
        this.selectedCoverImage = event.target.files[0]
        const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
          width: "500px",
          height: "600px",
          data: event
        })

        dialogRef.afterClosed().subscribe((result) => {
          if (result == null) {
            this.imageUpload2.nativeElement.value = ""
            this.updateCoverData()
          }
          else {
            this.selectedCoverImage = result
            this.data.coverImageName = this.selectedCoverImage.name
            this.data.trngInfoImagePath = ""
          }
        })
      }
      else {
        this.snackBar.open(this.labels.lblMaxFileSizeImage, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload2.nativeElement.value = ""
      }

    }
    else {
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload2.nativeElement.value = ""
    }


  }

  getTrainers() {
    this.dashboardService.getTrainers().subscribe((trainers) => {
      this.trainers = trainers as Trainer[]
      console.log(trainers)
    })
  }

  getImgName() {
    if (this.data.imagePath != null) {
      let array = this.data.imagePath.split('?')
      let array1 = array[0].split('/')
      this.data.imageName = array1[array1.length - 1]
    }
  }
  getCoverImgName() {
    if (this.data.trngInfoImagePath != null) {
      let array = this.data.trngInfoImagePath.split('?')
      let array1 = array[0].split('/')
      this.data.coverImageName = array1[array1.length - 1]
    }
  }

  updateData() {
    this.data.imagePath = null
    this.selectedImage = null
  }
  updateCoverData() {
    this.data.trngInfoImagePath = null
    this.selectedCoverImage = null
  }

}
