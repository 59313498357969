import { Component, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard.service';
import { AdminAuthService } from 'src/app/modules/services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service';
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexFill,
  ApexDataLabels,
  ApexLegend
} from "ng-apexcharts";
import { Training } from 'src/app/models/training.model';
import { NgForm } from '@angular/forms';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
};


@Component({
  selector: 'app-status-pie',
  templateUrl: './status-pie.component.html',
  styleUrls: ['./status-pie.component.scss']
})
export class StatusPieComponent implements OnInit {
  result
  status = []
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  labels;
  labelsSub: Subscription;
  admin
  adminSub: Subscription
  lab
  nums
  stat
  trainings
  name
  trainingName
  isItChecked
  @ViewChild('checkForm', { static: true }) checkForm: NgForm
  constructor(private dashboardService:DashboardService, public adminLanguageService: AdminLanguageService, private adminAuthService: AdminAuthService) {
   }

  ngOnInit(): void {
    this.isItChecked = false
    this.name = false
    this.getLang()
    this.getAdmin()
    this.getTrainings()
    this.lab = []
    this.nums = []
    this.stat = []
  }

  checkTraining(e:any, id, name){
      console.log("OP OP OP")
      this.fetchThisTrainingStatus(id, name)
      this.isItChecked = false 
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  getTrainings(){
    this.dashboardService.getTrainings().subscribe((trainings)=>{
      this.trainings = trainings as Training[]
    })
  }

  fetchTrainingStatus(){
    let tr = "tr"
    this.dashboardService.trainingStatus({tr:tr}).subscribe((result) =>{
      this.result = result
      console.log(result)
      this.result.forEach((r) => {
        console.log("name: " + r.name)
        this.lab.push(r.name)
        this.nums.push(r.num)
        this.stat.push(r)
      })
      this.chartOptions = {
        series: this.nums,
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: false
        },
        labels: this.lab,
        fill: {
          type: "gradient"
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 250
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };
    })
  }

  fetchThisTrainingStatus(id, name){
    console.log("POZVAO")
    this.name = true
    this.trainingName = name
    this.result = []
    this.lab = []
    this.nums = []
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.dashboardService.oneTrainingStatus({id: id}).subscribe((result) =>{
            this.result = result
            console.log(result)
            this.result.forEach((r) => {
              console.log("name: " + r.name)
              this.lab.push(r.name)
              this.nums.push(r.num)
              this.stat.push(r)
            })
            this.chartOptions = {
              series: this.nums,
              chart: {
                width: 380,
                type: "donut"
              },
              dataLabels: {
                enabled: false
              },
              labels: this.lab,
              fill: {
                type: "gradient"
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 250
                    },
                    legend: {
                      position: 'bottom',
                    }
                  }
                }
              ]
            };
          })
        }else{
          this.dashboardService.oneTrainingStatusForAdminUsers(this.admin.departmentId, id).subscribe((result) =>{
            this.result = result
            console.log(result)
            this.result.forEach((r) => {
              console.log("name: " + r.name)
              this.lab.push(r.name)
              this.nums.push(r.num)
              this.stat.push(r)
            })
            this.chartOptions = {
              series: this.nums,
              chart: {
                width: 380,
                type: "donut"
              },
              dataLabels: {
                enabled: false
              },
              labels: this.lab,
              fill: {
                type: "gradient"
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 250
                    },
                    legend: {
                      position: "bottom" 
                    }
                  }
                }
              ]
            };
          })
        }
        this.isItChecked = false
      }, 100)})
  }
  
  fetchTrainingStatus1(){
    this.dashboardService.trainingStatusForAdminUsers(this.admin.departmentId).subscribe((result) =>{
      this.result = result
      console.log(result)
      this.result.forEach((r) => {
        console.log("name: " + r.name)
        this.lab.push(r.name)
        this.nums.push(r.num)
        this.stat.push(r)
      })
      this.chartOptions = {
        series: this.nums,
        chart: {
          width: 380,
          type: "donut"
        },
        dataLabels: {
          enabled: false
        },
        labels: this.lab,
        fill: {
          type: "gradient"
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 250
              },
              legend: {
                position: "bottom" 
              }
            }
          }
        ]
      };
    })
  }

  getAdmin(){
    this.result = []
    this.lab = []
    this.nums = []
    this.name = false
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.fetchTrainingStatus();
        }
        else{
          this.fetchTrainingStatus1();
        }
      }, 100);
    });
  }

}
