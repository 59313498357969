import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import {Unit} from './../../../models/unit.model'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService) { }

  ngOnInit(): void {
  }

  // deleteAnswer(){
  //   this.dashboardService.deleteAnswer(this.data.id).subscribe(response=>{
  //     this.dialogRef.close()
  //   })
  // }
  onNoClick(){
    this.dialogRef.close()
  }
}
