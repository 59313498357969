import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-evaluation-component',
  templateUrl: './evaluation-component.component.html',
  styleUrls: ['./evaluation-component.component.scss']
})

export class EvaluationComponentComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  err
  admin
  adminSub: Subscription
  attendees
  updates
  @ViewChild('insertForm') insertForm: NgForm
  constructor(private languageService: AdminLanguageService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<EvaluationComponentComponent>,
    private dashboardService: DashboardService,
    private authService: AdminAuthService,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.updates = new Map();
    this.err = false
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.adminSub = this.authService.admin$.subscribe((admin) => {
      this.admin = admin;
    });

    this.getMeetingAttendees()
  }

  getMeetingAttendees(){
    let attendees = []
    console.log("DATA: ", this.data)
    this.db.firestore.collection("zoomMeetings").doc(this.data.id).collection("evaluation").get().then((query) => {
      query.forEach((doc) => {
        let element = doc.data()
        attendees.push(element)
      })
      this.attendees = attendees
      console.log("attendees: ", this.attendees)
    })
  }

  onSubmit(){

    this.updates.forEach((doc) => {
      let docRef = this.db.firestore.collection("zoomMeetings").doc(this.data.id).collection("evaluation")
        .doc(doc.id)
      
        if (doc.grade && doc.comment) {
          docRef.update({
            grade: doc.grade,
            comment: doc.comment
          })
        }
        else if(doc.grade) {
          docRef.update({
            grade: doc.grade
          })
        }
        else if(doc.comment) {
          docRef.update({
            comment: doc.comment
          })
        }
    })
    this.dialogRef.close()
  }

  onNoClick() {
    this.dialogRef.close()
  }

  updateGrade(id, event){
    console.log("on change")
    console.log(id, event.target.value)
    let grade = Number(event.target.value)

    grade = Math.min(Math.max(1, grade),5);

    let got = this.updates.get(id);
    got = got ? got : {};
    
    Object.assign(got,{
      id: id,
      grade: grade
    })

    this.updates.set(id, got);
  }

  updateComment(id, event) {
    let comment = String(event.target.value);

    let got = this.updates.get(id);
    got = got ? got : {};

    Object.assign(got, {
      id: id,
      comment: comment
    })

    this.updates.set(id, got);
  }
}
