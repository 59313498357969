import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router'
import { UserDashboardComponent } from './user-dashboard.component'
import { SharedModule } from './../../shared/shared.module'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatDividerModule } from '@angular/material/divider'
import { MatCardModule } from '@angular/material/card'
import { UserDashboardContentComponent } from 'src/app/modules/user-dashboard-content/user-dashboard-content.component';
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatIconModule } from '@angular/material/icon'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatVideoModule } from 'mat-video'
import { UserProfileComponent } from 'src/app/modules/user-profile/user-profile.component';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { UserTrainingComponent } from './../../modules/user-training/user-training.component'
import { ChangePasswordComponent } from './../../modules/change-password/change-password.component'
import { TestComponent } from './../../modules/test/test.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'
import { UserTrainingDetailsComponent } from './../../modules/user-training-details/user-training-details.component'
import { UserCardsComponent } from './../../modules/user-cards/user-cards.component'
import { BrowserModule } from '@angular/platform-browser';
import { VgCoreModule } from '@videogular/ngx-videogular/core'
import { VgControlsModule } from '@videogular/ngx-videogular/controls'
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { UserForgotPasswordComponent } from 'src/app/modules/login/user-forgot-password/user-forgot-password.component';
import { EditUserProfileComponent } from 'src/app/modules/edit-user-profile/edit-user-profile.component';
import {MatCheckboxModule} from '@angular/material/checkbox'
import { UserTrainingMessageComponent } from 'src/app/modules/user-training/user-training-message/user-training-message/user-training-message.component';
import { UserTestMessageComponent } from 'src/app/modules/test/user-test-message/user-test-message.component';
import { UserBeforeTestMessageComponent } from 'src/app/modules/user-training/user-before-test-message/user-before-test-message.component';
import {SurveyComponent} from 'src/app/modules/survey/survey.component';
import {PreTestComponent} from 'src/app/modules/pre-test/pre-test.component';
import { UserBeforePreTestComponent } from 'src/app/modules/pre-test/user-before-pre-test/user-before-pre-test.component';
import { UserPretestMessageComponent } from 'src/app/modules/pre-test/user-pretest-message/user-pretest-message.component';
import { UserBeforeSurveyMessageComponent } from 'src/app/modules/survey/user-before-survey-message/user-before-survey-message.component';
import { UserSurveyMessageComponent } from 'src/app/modules/survey/user-survey-message/user-survey-message.component';
import { ControlCorrectComponent } from 'src/app/modules/user-training/control-correct/control-correct.component';
import { ControlFalseComponent } from 'src/app/modules/user-training/control-false/control-false.component';
import { WrongUserLoginDataComponent } from 'src/app/modules/wrong-user-login-data/wrong-user-login-data.component';
import { UserNotificationComponent } from 'src/app/modules/user-notification/user-notification.component';
import { UserChatComponent } from 'src/app/modules/user-chat/user-chat.component';
import { UserMessagesComponent } from 'src/app/modules/user-messages/user-messages.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {AngularFireMessaging, AngularFireMessagingModule} from '@angular/fire/messaging';
import { environment } from '../../../environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WelcomePageComponent } from 'src/app/modules/welcome-page/welcome-page.component';
import { WelcomeComponent } from 'src/app/modules/welcome-page/welcome/welcome.component'
import { DeleteImageComponent } from 'src/app/modules/admin-change-password/delete-image/delete-image.component';
import { ImageDeleteDialogComponent } from 'src/app/modules/edit-user-profile/image-delete-dialog/image-delete-dialog.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { SignupMessageComponent } from 'src/app/modules/signup-message/signup-message.component';
import { UserSpinnerComponent } from 'src/app/modules/user-spinner/user-spinner.component'
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { FaqPageComponent } from 'src/app/modules/faq-page/faq-page.component';
import { TermsOfUseModalComponent } from 'src/app/modules/terms-of-use-modal/terms-of-use-modal.component';
import { CdTimerModule } from 'angular-cd-timer';
import { SectionTestComponent } from 'src/app/modules/section-test/section-test.component';
import { MessageBeforeSectionTestComponent } from 'src/app/modules/section-test/message-before-section-test/message-before-section-test.component';
import { MessageAfterSectionTestComponent } from 'src/app/modules/section-test/message-after-section-test/message-after-section-test.component';
import { ConfirmPasswordComponent } from 'src/app/modules/confirm-password/confirm-password.component';
import { UserRegisterComponent } from 'src/app/modules/login/user-register/user-register.component';
import { UserImageCropperDialogComponent } from '../../modules/user-image-cropper-dialog/user-image-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule } from 'angular-calendar';
import { UserCalendarComponentComponent } from '../../modules/user-calendar-component/user-calendar-component.component';
import { AddEventComponent } from 'src/app/modules/user-calendar-component/add-event/add-event.component';
import { DeleteEventComponent } from 'src/app/modules/user-calendar-component/delete-event/delete-event.component';
import { EditEventComponent } from 'src/app/modules/user-calendar-component/edit-event/edit-event.component';
import { ZoomMeetingComponent } from 'src/app/modules/zoom-meeting/zoom-meeting.component';
import { HttpClientModule } from '@angular/common/http';
import { ChatZoomMeetingComponent } from 'src/app/modules/user-messages/chat-zoom-meeting/chat-zoom-meeting.component';
import { ZoomVerifyComponent } from 'src/app/modules/zoom-verify/zoom-verify.component';
import { ZoomTestComponent } from 'src/app/modules/zoom-test/zoom-test.component';
import { MessageBeforeZoomTestComponent } from 'src/app/modules/zoom-test/message-before-zoom-test/message-before-zoom-test.component';
import { MessageAfterZoomTestComponent } from 'src/app/modules/zoom-test/message-after-zoom-test/message-after-zoom-test.component';
import { UserVideoCallComponent } from 'src/app/modules/user-messages/user-video-call/user-video-call.component';
import { SplashScreenComponent } from 'src/app/modules/splash-screen/splash-screen.component';
import { ControlQuestionComponent } from 'src/app/modules/user-training/control-question/control-question.component';
import { UserChangePasswordComponent } from 'src/app/modules/user-profile/user-change-password/user-change-password.component';
import { RangListComponent } from 'src/app/modules/rang-list/rang-list.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import localeSrb from '@angular/common/locales/sr-Latn'

registerLocaleData(localeSrb)

@NgModule({
  declarations: [
    UserDashboardComponent,
    UserDashboardContentComponent,
    UserProfileComponent,
    UserTrainingComponent,
    ChangePasswordComponent,
    TestComponent,
    UserTrainingDetailsComponent,
    UserCardsComponent,
    UserForgotPasswordComponent,
    EditUserProfileComponent,
    UserTrainingMessageComponent,
    UserBeforeTestMessageComponent,
    UserTestMessageComponent,
    SurveyComponent,
    PreTestComponent,
    UserSurveyMessageComponent,
    UserPretestMessageComponent,
    UserBeforePreTestComponent,
    UserBeforeSurveyMessageComponent,
   
    WrongUserLoginDataComponent,
    UserNotificationComponent,
    UserChatComponent,
    UserMessagesComponent,
    WelcomeComponent,
    WelcomePageComponent,
    ImageDeleteDialogComponent,
    SignupMessageComponent,
    UserSpinnerComponent,
    FaqPageComponent,
    TermsOfUseModalComponent,
    SectionTestComponent,
    MessageBeforeSectionTestComponent,
    MessageAfterSectionTestComponent,
    ConfirmPasswordComponent,
    UserRegisterComponent,
    UserImageCropperDialogComponent,
    UserCalendarComponentComponent,
    AddEventComponent,
    DeleteEventComponent,
    EditEventComponent,
    ZoomMeetingComponent,
    ChatZoomMeetingComponent,
    ZoomVerifyComponent,
    ZoomTestComponent,
    MessageBeforeZoomTestComponent,
    MessageAfterZoomTestComponent,
    UserVideoCallComponent,
    SplashScreenComponent,
    ControlQuestionComponent,
    ControlCorrectComponent,
    ControlFalseComponent,
    UserChangePasswordComponent,
    RangListComponent
  ],
  imports: [
    HttpClientModule,
    CdTimerModule,
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    VgControlsModule,
    VgCoreModule,
    BrowserAnimationsModule,
    MatVideoModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule,
    MatRadioModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    BrowserModule,
    MatCheckboxModule,
    NgxAudioPlayerModule,
    MatSelectModule,
    MatMenuModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyACLReSOlrRk65qnoDGUL8bUoXonVLqQrU',
      authDomain: 'leste-mobile.firebaseapp.com',
      databaseURL: 'https://leste-mobile.firebaseio.com',
      projectId: 'leste-mobile',
      storageBucket: 'leste-mobile.appspot.com',
      messagingSenderId: '353573413256',
      appId: '1:353573413256:web:7b23c64899680284909a3d'
  }),
    AngularFirestoreModule,
    AngularFireMessagingModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    NgxSpinnerModule,
    ImageCropperModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    PdfViewerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserDashboardModule { }
