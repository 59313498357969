import { Component } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../../../../Leste 3.0/models/User'
import { AuthService } from 'src/app/modules/services/auth-service/auth.service';
import { UserLanguageService } from 'src/app/modules/services/user-language-service/user-language.service';
import {AngularFireStorage} from '@angular/fire/storage'

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {

  user: User
  userSub: Subscription;
  labels;
  labelsSub: Subscription;
  colorClass
  colorIconClass
  constructor( private languageService: UserLanguageService, private authService: AuthService, private storage: AngularFireStorage) {

  }
  ngOnInit() {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      // setTimeout(() => {
      //   if(this.user.companyId == 1){
      //     console.log("IF")
      //     this.colorClass =  "EMSColor"
      //     this.colorIconClass = "#c32a2a"
      //   }else if(this.user.companyId == 2){
      //     this.colorClass =  "ECGColor"
      //     this.colorIconClass = "rgb(241, 106, 100)"
      //   }
      //   else{
      //     this.colorClass =  "ECGColor"
      //     this.colorIconClass = "rgb(241, 106, 100)"
      //   }
      //  }, 200);
    });
  }

}
