import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { Administrator } from '../../../../../Leste 3.0/models/Administrator'
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { DashboardService } from '../dashboard.service';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  private admin:Administrator;
  lang
  langSub: Subscription;

  labels;
  labelsSub: Subscription;
  err: boolean = false;
  routerUrl
  code
  constructor(private dashboardService: DashboardService, public db: AngularFirestore, private router: Router, private adminAuthService: AdminAuthService,
    private adminLanguageService: AdminLanguageService, public dialog:MatDialog,
    public snackBar:MatSnackBar,
    private localStorageService: LocalStorageService, private http: HttpClient) { 
      this.adminLanguageService.setLanguage('srb');
  }

  ngOnInit(): void {
    this.err = false;
    this.langSub = this.adminLanguageService.lang$.subscribe((lang) =>{
      this.lang = lang;
    })
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) =>{
      this.labels = labels;
    })

  }

  ngOnDestroy(): void {

  }

  onSubmit(formData){
   if(formData.value.email == "" || formData.value.password == ""){
      this.err = true
   }else{
    console.log(formData.value.password);
    this.adminAuthService.adminLogin(formData.form.value).subscribe((response) => {
      console.log(response)
      if(response.err == "greska"){
        this.router.navigateByUrl('/login')
      }else{
        this.router.navigateByUrl('/admin')

        // TODO ne znam zasto se ovde stavlja ovako, ali je ovo za resetovanje sifre

        // this.db.firestore.collection('newPasswordRequests').where("changed", "==", false).get().then((query) => {
        //   query.forEach((doc) => {
        //     let email = doc.data().email
        //     if(email != ""){
        //       this.dashboardService.resetPassword({email:email}).subscribe((response)=>{
        //         this.db.firestore.collection('newPasswordRequests').where("email", "==", email).get().then((query) => {
        //           query.forEach((doc) => {
        //             this.db.firestore.collection("newPasswordRequests").doc(doc.data().id).update({changed: true})
        //           })
        //         })
        //       })
        //     }
        //   })
        //  })
      }
      
    })
   }
  }
  selectLang(lang){
    this.adminLanguageService.setLanguage(lang);
  }

  openForgotPassModal(){
    const dialogRef=this.dialog.open(ForgotPasswordComponent,{
      width:'300px',
      data: {lang: this.lang}
    });
    dialogRef.afterClosed().subscribe((admin) => {
      
      // this.snackBar.open(`${admin}`,'OK',{
      //   verticalPosition:'top',
      //   horizontalPosition:'end',
      //   duration:5000,
        
      // })
      console.log('The dialog was closed');
     
    });

  }

}
