import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ImageCropperDialogComponent } from '../../image-cropper-dialog/image-cropper-dialog.component';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
export interface DialogData{
  userId: string,
  eventId: string,
  eventTitle: string,
  eventDescription: string,
  eventDate: Date,
  hour: Number,
  minutes: Number,
  meetingId: Number,
  imagePath: String,
  imageName: String
}
@Component({
  selector: 'app-edit-meeting',
  templateUrl: './edit-meeting.component.html',
  styleUrls: ['./edit-meeting.component.scss']
})
export class EditMeetingComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  labels;
  labelsSub: Subscription;
  err
  admin
  adminSub: Subscription
  selectedImage: File = null

  uploading: boolean = false
  constructor(public dialogRef: MatDialogRef<EditMeetingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private languageService: AdminLanguageService,
    public db: AngularFirestore, public snackBar: MatSnackBar, private storage: AngularFireStorage, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.err = false
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.getFileName()
  }

  onNoClick(): void {
    this.dialogRef.close();
   }

   async onSubmit(){
      console.log("pozvao onsubmit")
      if (this.editForm.value.title == "" || this.editForm.value.description == "" || this.selectedImage == null) {
        this.err = true;
        this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      } else {
        this.uploading = true
        let data = {
          title: this.editForm.value.title,
          description: this.editForm.value.description,
          imagePath: this.data.imagePath
        }
        if (this.selectedImage !== null) {
          console.log("usao u IF")
          let ref = this.storage.ref(this.selectedImage.name)
          await ref.put(this.selectedImage)
          data.imagePath = await ref.getDownloadURL().toPromise()
        }
        this.db.firestore.collection("zoomMeetings").doc(this.data.eventId).update(data)
        this.uploading = false
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblMeetingEdites, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }

    onImageChanged(event) {
      let file = event.target.files[0]
      let selectedFileName = file.name
      let ext = selectedFileName.split(".").pop()
      if(ext == "jpg" || ext == "jpeg" || ext == "png"){
        var iConvert = (file.size / 1024 / 1024).toFixed(2);
        let number:Number = +iConvert
        if(number <= 50){
          this.selectedImage = event.target.files[0]
          const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
            width: "500px",
            height: "600px",
            data: event
          })
          dialogRef.afterClosed().subscribe((result) => {
            this.imageUpload.nativeElement.value = ""
            this.selectedImage = result
            console.log(this.selectedImage)
          })
        }
        else{
          this.snackBar.open(this.labels.lblMaxFileSizeImage , 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
          this.imageUpload.nativeElement.value = ""
        }
      }
      else{
        this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }
    }

    updateData() {
        this.data.imagePath = null
        this.selectedImage = null
    }

    getFileName(){
      if (this.data.imagePath != null) {
        let array = this.data.imagePath.split('?')
        let array1 = array[0].split('/')
        this.data.imageName = array1[array1.length - 1]
      }
    }

}
