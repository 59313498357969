<app-admin-spinner *ngIf="labels == null || dataSource == null || dataSource1 == null"></app-admin-spinner>
<div class="mat-elevation-z8">
  <div class="row header">
    <div class="col-md-10 text-left">
     <div class="row">
        <div class="col-12">
          <h2 class="lblAns"><mat-icon class="back" [inline] = "true" [routerLink]="['/admin/trainings/training-detail',section.trainingId,'section-detail',section.id]">keyboard_backspace</mat-icon>{{labels.lblControlQuestion}}</h2>
        </div>

     </div>
    </div>
    <div class="col-md-2 text-right addNewSectionBtn">
      <button style="float: right; margin: 3px;" [ngClass]="{'disabled-btn': (dataSource.length > 0)}" mat-raised-button
        class="tableBtn addNewSectionBtn1" (click)="insertQuestionDialog()">
        {{labels.lblAddNewQuestion}}
      </button>
    </div>
           
    <div class="col-12">
      <div class="row">
        <hr class="hrline" style="width: 100%;">
        <div class="col-12 col-lg-6"><h3 class="ok"><span style="color: #8293c6;">{{labels.lblSection}}:</span> {{section.name}}</h3></div>
        <div class="col-12 col-lg-6"><h3 class="ok"><span style="color: #8293c6;">{{labels.lblUnit}}:</span> {{unit.name}}</h3></div>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" #table>
    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef>{{labels.lblControlQuestion}} </th>
      <td mat-cell *matCellDef="let question" data-toggle="tooltip" data-placement="top" title={{question.text}}>
        {{question.text.substring(0,75)}}<span *ngIf="question.text.length > 75">...</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{labels.lblType}} </th>
      <td style="width: 20% !important;" mat-cell *matCellDef="let question">
        <span *ngIf='question.type===1'>{{labels.lblOneCorrectAns}}</span>
        <span *ngIf='question.type===2'>{{labels.lblMoreThanOne}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 15%;"> {{labels.lblOptions}} </th>
      <td mat-cell *matCellDef="let question" style="width: 15%">
        <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
        <mat-menu #settingsMenu="matMenu" class="mMenu">
          <button mat-menu-item class="menuButton settingsMenuItem" (click)="editQuestionDialog(question)">
            <mat-icon style="color: #8293c6;">edit</mat-icon>
            <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
          </button>
          <button mat-menu-item class="menuButton settingsMenuItem" [ngClass]="{'disabled-btn': assigned.length > 0}" (click)="deleteQuestionDialog(question)">
            <mat-icon style="color: #8293c6;">delete</mat-icon>
            <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns1;" cdkDrag [cdkDragDisabled]="value" [cdkDragData]="row">
    </tr>
  </table>
</div>

<div class="mat-elevation-z8" style="margin-top: 3%">
  <div class="row header" style = "padding: 15px;">
    <div class="col-xl-3">
      <h2 class="lblAns">{{labels.lblAnswers}}</h2>
    </div>
    <div class="col-xl-9 text-right addNewSectionBtn" style="font-size: 15.5px; margin-right: 0px;">
      <mat-checkbox *ngIf = "question[0]" [ngModel]="question[0].random" class="example-margin checkbox" (change)="changeRandomAnswers()"
        [ngClass]="{'disabled-btn': (value == true) || (assigned.length > 0)}" style="margin-top: 10px; margin-right: 20px;">
        <span>{{labels.lblRandom}}</span>
      </mat-checkbox>
        <button mat-raised-button [ngClass]="{'disabled-btn': (assigned.length > 0) || (dataSource.length == 0)}" class="tableBtn hideColumn" (click)="showChangeButtonAnswers()" *ngIf='value==true'
          style="margin-right: 5px;">
          {{labels.lblChangeOrder}}
        </button>
        <button mat-raised-button [ngClass]="{'disabled-btn': assigned.length > 0}" class="tableBtn hideColumn" (click)="changeOrderAnswers()" *ngIf='value==false'
          style="margin-right: 1vw; margin-top: 7px;">
          {{labels.lblSaveOrder}}
        </button>
        <button [ngClass]="{'disabled-btn': (assigned.length > 0) || (dataSource.length == 0)}" style="margin-top: 3px; margin-right: 3px;" mat-raised-button
          class="tableBtn addNewSectionBtn1" (click)="insertQuestionAnswerDialog()">
          {{labels.addAnswer}}
        </button>
    </div>
  </div>
  <table matSortDisableClear="true" mat-table matSort [dataSource]="dataSource1" #table1 cdkDropList [cdkDropListData]="dataSource1"
    (cdkDropListDropped)="drop1($event)">
    <!-- order Column -->
    <ng-container matColumnDef="order">
      <th style="width: 15%" mat-header-cell *matHeaderCellDef mat-sort-header><span class="order">{{labels.lblOrder}}</span></th>
      <td style="width: 15%" mat-cell *matCellDef="let answer">
        <mat-icon *ngIf="!question[0].random && !value" style="cursor: pointer;" class="settings">
          import_export
        </mat-icon>
        <span *ngIf="!question[0].random">{{answer.order}}</span>
        <span *ngIf="question[0].random">R</span>
      </td>
    </ng-container>
    <!-- name Column -->
    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblText}} </th>
      <td mat-cell *matCellDef="let answer" data-toggle="tooltip" data-placement="top" title={{answer.textAns1}}>
        {{answer.textAns1.substring(0,75)}}<span *ngIf="answer.textAns1.length > 75">...</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="correct">
      <th style="width: 10%" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td style="width: 10%" mat-cell *matCellDef="let answer">
        <mat-slide-toggle [checked]="answer.correct" (click)="changeStatus(answer)"
          [ngClass]="{'disabled-btn': (isDisabled(answer)) || (assigned.length > 0)}">
          <span class="d-none d-sm-flex" *ngIf="answer.correct==true">{{labels.lblCorrect}}</span>
          <span class="d-none d-sm-flex" *ngIf="answer.correct==false">{{labels.lblNotCorrect}}</span>
        </mat-slide-toggle>
      </td>
    </ng-container>
    <!-- parentId Column -->

    <ng-container matColumnDef="actions">
      <th style="width: 10%" mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
      <td style="width: 10%" mat-cell *matCellDef="let answer">
        <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
        <mat-menu #settingsMenu="matMenu" class="mMenu">
          <button mat-menu-item class="menuButton settingsMenuItem" (click)='editDialog(answer)'>
            <mat-icon style="color: #8293c6;">edit</mat-icon>
            <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
          </button>
          <button mat-menu-item class="menuButton settingsMenuItem" [ngClass]="{'disabled-btn': assigned.length > 0}" (click)="deleteDialog(answer)">
            <mat-icon style="color: #8293c6;">delete</mat-icon>
            <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragDisabled]="value" [cdkDragData]="row">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
</div>