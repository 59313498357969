import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, Validators, FormGroup, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { TestQuestionAnswer } from 'src/app/models/testQuestionAnswer.model';
import { Question, Answer, TestQuesetion, AdminTestQuestionsAndAnswersComponent } from '../../admin-test/admin-test-questions-and-answers/admin-test-questions-and-answers.component';
import { DeleteTestAnswerComponent } from '../../admin-test/admin-test-questions-and-answers/delete-test-answer/delete-test-answer.component';
import { DeleteTestQuestionComponent } from '../../admin-test/admin-test-questions-and-answers/delete-test-question/delete-test-question.component';
import { EditTestAnswerComponent } from '../../admin-test/admin-test-questions-and-answers/edit-test-answer/edit-test-answer.component';
import { EditTestQuestionComponent } from '../../admin-test/admin-test-questions-and-answers/edit-test-question/edit-test-question.component';
import { DashboardService } from '../../dashboard.service';
import { ImageCropperDialogComponent } from '../../image-cropper-dialog/image-cropper-dialog.component';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-test-questions-dialog',
  templateUrl: './test-questions-dialog.component.html',
  styleUrls: ['./test-questions-dialog.component.scss']
})
export class TestQuestionsDialogComponent implements OnInit {

  value1: boolean = true
  text = new FormControl('', [Validators.required]);
  points = new FormControl('', [Validators.required])
  type = new FormControl('', [Validators.required]);
  textAnswerControl = new FormControl('', [Validators.required]);
  question: Question
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  value = true
  idQuestion: string
  showOrderButton = false
  correct = false;
  @ViewChild('table1') table1: MatTable<Answer[]>
  @ViewChild('table') table: MatTable<TestQuesetion[]>
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('insertForm1') insertForm1: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  @ViewChild('videoUpload') videoUpload: ElementRef
  typeOfQuestion: number
  labels;
  labelsSub: Subscription;
  selectedImage: File = null
  selectedVideo: File = null;
  imgExtError: boolean = false;
  videoExtError: boolean = false;
  constructor(private storage: AngularFireStorage, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<TestQuestionsDialogComponent>, private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private adminLanguageService: AdminLanguageService,
    private userDashBoard: UserDashboardService
    ) { }
  displayedColumns1: string[] = ['order', 'text', 'correct', 'actions'];
  dataSource1
  dataSource
  testId: string
  textAnsErr: boolean = false;
  textErr: boolean = false;
  typeErr: boolean = false;
  assigned
  id
  imgPath: string = null;
  videoHintPath: string = null;

  ngOnInit(): void {
    this.dataSource1 = this.data.answers
    this.question = this.data
    console.log(this.question)
    console.log(this.data)
    this.testId = this.data.id;
    this.id = this.data.testId
    this.isTestAssigned()
    this.getLang()
    this.data.imgName = this.extractFilename(this.data.imagePath);
    this.data.videoName = this.extractFilename(this.data.videoHintPath);
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  extractFilename(url: String) {
    if(url == null) return null;
    return url.split('?')[0].split("/").pop();
  }

  isTestAssigned(){
    this.dashboardService.isTestAssigned(this.id).subscribe((response)=>{
    console.log(response)
     if(response == null){
       this.assigned = []
     }else{
      this.assigned = response
     }
     console.log(this.assigned)
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }

  async onSubmit() {

    if (this.imgExtError) {
      this.userDashBoard.snackMsg(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage);
      return;
    }

    if (this.videoExtError) {
      this.userDashBoard.snackMsg(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo);
      return;
    }

    if (!this.videoExtError && !this.imgExtError) {

      if (this.insertForm.value.textQ == "" || this.insertForm.value.points == "" || this.insertForm.value.type == "") {
        this.textErr = true;
        this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000  })
      } else {
        console.log(this.insertForm.value)

        if (this.selectedImage !== null) {

          let ref = this.storage.ref(this.selectedImage.name)
          await ref.put(this.selectedImage)
          this.data.imagePath = await ref.getDownloadURL().toPromise()
        }

        if (this.selectedVideo !== null) {

          const ref = this.storage.ref(this.selectedVideo.name);
          await ref.put(this.selectedVideo);
          this.data.videoHintPath = await ref.getDownloadURL().toPromise();
        }

        if (!(this.videoExtError || this.imgExtError)) {
          this.dashboardService.editQuestionForTest({
            id: this.data.id,
            testId: this.data.testId,
            text: this.insertForm.value.text,
            points: this.insertForm.value.points,
            type: this.insertForm.value.type,
            imagePath: this.data.imagePath,
            videoHintPath: this.data.videoHintPath
          }).subscribe((response) => {
            console.log(response)
            this.snackBar.open(this.labels.lblQuestionEdited, 'OK', {
              verticalPosition: 'top',
              horizontalPosition: 'end',
              duration: 5000
            })
            this.dialogRef.close()
          }) 
        }
      }
    }
  }
  

  //functions for answers
  answersForQuestion(questionId) {
    this.idQuestion = questionId
    this.dashboardService
      .fetchAnswersForQuestion(this.data.testId, questionId)
      .subscribe(response => this.dataSource1 = response)
    this.dashboardService.fetchQuestion(this.data.testId, questionId).subscribe(
      (response) => {
        this.question = response as Question
        this.data = response
      })
  }
  

  onSubmitAns() {
    console.log("BREEEEEEEEEEEEEEE")
    console.log(this.data.id)
    if (this.insertForm1.value.text == "") {
      this.textAnsErr = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000  })
    }
    else {
      if (this.dataSource1.length + 1 == 1) {
        this.dashboardService.creteAnswer({
          testId: this.data.testId,
          testQuestionId: this.data.id,
          correct: true,
          order: this.dataSource1.length + 1,
          text: this.insertForm1.value.text
        }).subscribe(response => {
          this.snackBar.open(this.labels.lblControlQuestionAnsAdded, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
          console.log(response)
        })
      }
      else {
        this.dashboardService.creteAnswer({
          testId: this.data.testId,
          testQuestionId: this.data.id,
          correct: false,
          order: this.dataSource1.length + 1,
          text: this.insertForm1.value.text
        }).subscribe(response => {
          console.log(response)
          this.snackBar.open(this.labels.lblControlQuestionAnsAdded, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
        })
      }

    }
    this.insertForm1.controls.text.reset()
    this.answersForQuestion(this.data.id);
  }


  deleteDialog(answer) {
    let flag = this.canDeleteAnswer(answer)

      const dialogRef = this.dialog.open(DeleteTestAnswerComponent, {
        width: '500px',
        data: { id: answer.id, testId: this.data.testId, testQuestionId: this.data.id, flag:flag }
      })

      dialogRef.afterClosed().subscribe(response => {
        this.dataSource = null
        this.dashboardService.fetchAnswersForQuestion(this.data.testId, this.data.id).subscribe(response => {
          this.answersForQuestion(this.data.id)
          setTimeout(() => {
            this.updateOrder1()
          }, 500);
        })
        
      })


  }
  editDialog(answer) {
    const dialogRef = this.dialog.open(EditTestAnswerComponent, {
      width: '500px',
      data: { id: answer.id, testId: this.data.testId, questionId: this.data.id, text: answer.text }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
      this.dashboardService.fetchAnswersForQuestion(this.data.testId, this.data.id).subscribe(response => {
        this.dataSource = response
      })
      this.answersForQuestion(this.data.id)
    })
  }

  changeStatus(answer) {
    if (this.question.type == 2) {
      this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: answer.id, correct: !answer.correct })
        .subscribe((response) => {
          this.dashboardService.fetchAnswersForQuestion(this.data.testId, this.data.id).subscribe(response => {
            this.dataSource1 = response
            return
          })
        })
      return
    }
    if (this.question.type == 1) {
      for (let index = 0; index < this.dataSource1.length; index++) {
        const element = this.dataSource1[index];

          if (element.id === answer.id) {
            this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: element.id, correct: !answer.correct }).subscribe((response) => {
              this.dashboardService.fetchAnswersForQuestion(this.data.testId, this.data.id).subscribe(
                response => this.dataSource1 = response
              )
            })
          } else {
            this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: element.id, correct: answer.correct }).subscribe((response) => {
              this.dashboardService.fetchAnswersForQuestion(this.data.testId, this.data.id).subscribe(
                response => this.dataSource1 = response
              )
            })
          }
        
        

      }
      return
    }

    
  }

  isDisabled(answer){
    if(this.question.type==1 && answer.correct){
      return true
    }else if(this.question.type == 2 && answer.correct){
      var flag = this.canChangeStatus(answer)
      if(flag){
        return false
      }else{
        return true
      }
    }else{
      return false
    }
  }

  canDeleteAnswer(answer: TestQuestionAnswer): boolean {
    let answers: TestQuestionAnswer[] = this.dataSource1
    let answers1 = answers.filter(a => a.id != answer.id)
    for (let index = 0; index < answers1.length; index++) {
      if (answers1[index].correct) {
        return true;
      }
    }
    return false
  }

  canChangeStatus(answer): boolean{
    let answers: TestQuestionAnswer[] = this.dataSource1
    var cnt = 0
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].correct) {
        cnt++
      }
    }
    if(cnt > 1){
      return true;
    }
    return false
  }

  changeOrderAnswers() {
    console.log(this.data.testId)
    console.log(this.data.id)
    let answers = this.dataSource1
    if (this.question.random) {
       this.dashboardService.setAnswersOrder(true, this.data.testId, this.data.id).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index];
        console.log(element)
        this.dashboardService.changeAswersOrder({ id: element.id, testId: element.testId, questionId: this.data.id, order: index + 1 }).subscribe((response) => {
          console.log(response)
          this.dashboardService.fetchAnswersForQuestion(this.data.testId, this.data.id).subscribe(response => {
            this.dataSource1 = response
            console.log('operation finshed')

          })
          this.dashboardService.setAnswersOrder(false, this.data.testId, this.data.id).subscribe(response => {
            console.log(response)
          })

        }) 

      }
    }

    this.value1 = !this.value1
  }

  updateOrder1() {
    console.log(this.data.testId)
    console.log(this.data.id)
    let answers = this.dataSource1
    if (this.question.random) {
       this.dashboardService.setAnswersOrder(true, this.data.testId, this.data.id).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index];
        console.log(element)
        this.dashboardService.changeAswersOrder({ id: element.id, testId: element.testId, questionId: this.data.id, order: index + 1 }).subscribe((response) => {
          console.log(response)
          this.dashboardService.fetchAnswersForQuestion(this.data.testId, this.data.id).subscribe(response => {
            this.dataSource1 = response
            console.log('operation finshed')

          })
          this.dashboardService.setAnswersOrder(false, this.data.testId, this.data.id).subscribe(response => {
            console.log(response)
          })

        }) 

      }
    }
  }

  drop1(event) {
    console.log('drop1')
    const prevIndex = this.dataSource1.findIndex((d) => d === event.item.data)
    moveItemInArray(this.dataSource1, prevIndex, event.currentIndex);
    this.table1.renderRows()
  }

  showChangeButtonAnswers() {

    this.value1 = !this.value1
  }

  changeRandomAnswers() {
    this.question.random = !this.question.random
  }

  onImageChanged(event) {
    this.selectedImage = event.target.files[0];
    const ext = this.selectedImage.name.split('.').pop();
    this.data.imagePath = "";
    this.data.imgName = this.selectedImage.name;

    if(ext == 'jpg' || ext == 'png' || ext == 'jpeg') {

      this.imgExtError = false;
      let fileMBSize = this.selectedImage.size/1024/1024;

      if(fileMBSize <= 5) {

        const cropperRef = this.dialog.open(ImageCropperDialogComponent,{
          height: "600px",
          width: "500px",
          data: event
        })

        cropperRef.afterClosed().subscribe((result) => {
          this.selectedImage = result;
        })
      }
      else {
        this.userDashBoard.snackMsg(this.labels.lblMaxFileSizeImage);
        this.imageUpload.nativeElement.value = "";
      }
    }
    else {
      this.imgExtError = true;
      this.userDashBoard.snackMsg(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage);
      this.imageUpload.nativeElement.value = "";
    }
  }

  onVideoChanged(event) {
    this.selectedVideo = event.target.files[0];
    const ext = this.selectedVideo.name.split('.').pop();
    this.videoExtError = ext != 'mp4';
    this.data.videoHintPath = "";
    this.data.videoName = this.selectedVideo.name;
  }

  cancelVideo() {
    this.data.videoHintPath = null;
    this.selectedVideo = null;
  }

  cancelImage() {
    this.data.imagePath = null;
    this.selectedImage = null;
  }

}



