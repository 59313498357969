import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DeleteDialogComponent } from '../../admin-user/delete-dialog/delete-dialog.component';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-delete-document-dialog',
  templateUrl: './delete-document-dialog.component.html',
  styleUrls: ['./delete-document-dialog.component.scss']
})
export class DeleteDocumentDialogComponent implements OnInit {
  labels;
  labelsSub: Subscription;

  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, public adminLanguageService: AdminLanguageService, private storage: AngularFireStorage) { }

  ngOnInit(): void {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
        })
  }

  onNoClick(){
    this.dialogRef.close()
  }

  deleteDocument(){
    console.log(this.data.user)
    console.log(this.data.document)
    this.dashboardService.deleteUsersDocument(this.data.user.id, this.data.document.id).subscribe((response)=>{
      this.storage.refFromURL(this.data.document.path).delete().subscribe(response => {
        console.log('document deleted from storage')
      })
      console.log(response)
    })
    this.snackBar.open(this.labels.lblTrainingDeleted, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
     this.dialogRef.close()
    
  }

}
