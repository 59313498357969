<div id="chart">
  <div class="menuBtn">
    <span class="h3">{{labels.lblNumOfUsersInSector}}</span>
    <button mat-button [matMenuTriggerFor]="menu" style="float: right;">
      <mat-icon style = "color: #9dacd9 !important;">menu</mat-icon>
  </button>
      <mat-menu #menu = "matMenu" class = "menu">
        <div *ngFor = "let d of deps">
          <button mat-menu-item style="color: #9dacd9;" [routerLink]="['/admin/usersInDep',d.id]">
            {{d.name}}
          </button>
        </div>
      </mat-menu>
  </div>
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions"
    [yaxis]="chartOptions.yaxis"
    [xaxis]="chartOptions.xaxis"
    [legend]="chartOptions.legend"
    [colors]="chartOptions.colors"
    [grid]="chartOptions.grid"
  ></apx-chart>
</div>
