import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Training } from 'src/app/models/training.model';
import { RequestObject } from 'src/app/models/trainingUserRequest.model';
import { DashboardService } from '../../dashboard.service';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface TrainingRequest{
  id:string
  status:number
}
@Component({
  selector: 'app-sector-trainings',
  templateUrl: './sector-trainings.component.html',
  styleUrls: ['./sector-trainings.component.scss']
})
export class SectorTrainingsComponent implements OnInit {
  @ViewChild('userTrainingsForm') userTrainingsForm:NgForm
  labels;
  labelsSub: Subscription;
 
  traininigIds
  traininigIdsUser
  trainings:Training[]
  userTrainings
  selectedCheckBoxes:string[]
  selectedTrainings:string[]
  training:Training
  reqObject:RequestObject
  assignedTrainings
  constructor(public snackBar: MatSnackBar, private db: AngularFirestore, private authService: AdminAuthService, public dialogRef: MatDialogRef<SectorTrainingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }
    ngAfterContentInit(){
      this.extractIdsUser()
      this.extractIds()
    }
  ngOnInit(): void {
    this.getLang()
    this.fetchTrainings()
    this.selectedCheckBoxes=new Array<string>()
    this.selectedTrainings=new Array<string>()
  }

  extractIds(){
    let ids=[]
    for (let index = 0; index < this.trainings.length; index++) {
      const element = this.trainings[index];
      ids.push(element.id)
    }
    this.traininigIds=ids
    
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  extractIdsUser(){
    let ids=[]
    for (let index = 0; index < this.userTrainings.length; index++) {
      const element = this.userTrainings[index];
      ids.push(element.trainingId)
    }
    this.traininigIdsUser=ids
  }
  onNoClick(){
    this.dialogRef.close()
    console.log(this.extractIds())
    console.log(this.extractIdsUser())
  }
  onSubmit(){
    console.log("submit")
    console.log(this.userTrainingsForm.value.id)
    this.updateTrainings({id:this.userTrainingsForm.value.id,trainings:this.selectedCheckBoxes})
    this.snackBar.open(this.labels.lblTrainingAssigned, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
    this.dialogRef.close()
  }
  
  fetchTrainings(){
    this.dashboardService.fetchTrainigsForSector().subscribe((trainings:Training[])=>{this.trainings=trainings as Training[]
    console.log("trainings")
    console.log(this.trainings)
    })
  }

  checkCheckBoxValue(value:string){
    this.data.trainings.forEach(element => {
      if(element.trainingId===value){
        return true
      }
      return false
    });
  }

  getTrainingId(e:any,training:Training){
    console.log("TRAINING")
    console.log(training.name)
    if(e.checked){
      this.selectedCheckBoxes.push(training.id)
      this.selectedTrainings.push(training.name)
    }else{
      this.selectedTrainings=this.selectedTrainings.filter((element)=>element != training.name)
      this.selectedCheckBoxes=this.selectedCheckBoxes.filter((element)=>element != training.id)
    }
    console.log(this.selectedTrainings)
    console.log(this.selectedCheckBoxes)
  }

  updateTrainings({id,trainings}){
    console.log("trainings")
    console.log(trainings)
    this.assignedTrainings = trainings
    this.dashboardService.updateSectorTrainings({id,trainings}).subscribe((response)=>console.log(response))
  }

}
