import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminLoginComponent } from '../admin-login/admin-login.component';
import { DashboardService } from '../dashboard.service';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { AngularFireStorage } from '@angular/fire/storage'
import { DeleteImageComponent } from './delete-image/delete-image.component';
import { ImageCropperDialogComponent } from '../image-cropper-dialog/image-cropper-dialog.component';
import { ProfileImageCropperDialogComponent } from '../profile-image-cropper-dialog/profile-image-cropper-dialog.component';


@Component({
  selector: 'app-admin-change-password',
  templateUrl: './admin-change-password.component.html',
  styleUrls: ['./admin-change-password.component.scss']
})
export class AdminChangePasswordComponent implements OnInit {
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  admin: Administrator
  adminSub: Subscription;
  labels;
  labelsSub: Subscription;
  downloadUrl$: Observable<string>
  downloadUrlSub: Subscription
  selectedDocument: File = null
  flg: string
  selectedImage: File = null
  disable: boolean
  termsOfUse
  selectedImageUrl
  @ViewChild('editForm') editForm: NgForm;
  @ViewChild('insertTermsForm') insertTermsForm: NgForm;
  @ViewChild('editImageForm') editImageForm: NgForm;
  @ViewChild('documentUpload') documentUpload: ElementRef

  constructor(
    private dashboardService: DashboardService,
    private adminLanguageService: AdminLanguageService,
    private adminAuthService: AdminAuthService,
    private localStorageService: LocalStorageService,
    public snackBar: MatSnackBar,
    private storage: AngularFireStorage,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getLang();
    this.disable = true;
    this.adminSub = this.adminAuthService.admin$.subscribe((admin: Administrator) => {
      this.admin = admin;
      console.log(this.admin)
    });
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onImageSubmit() {
    console.log(this.disable)
    if (this.selectedImage != null) {
      console.log(this.selectedImage.name)
      let imageRef = this.storage.ref(this.selectedImage.name)
      imageRef.put(this.selectedImage).then(t => {
        this.downloadUrlSub = imageRef.getDownloadURL().subscribe(url => {
          this.admin.profileImagePath = url
          console.log(url)
          this.adminAuthService.updateAdmin(this.admin)
          this.dashboardService.editAdminInfo1({
            flg: "img",
            id: this.editImageForm.value.id,
            profileImage: url
          }).subscribe((response) => {
            console.log(response)
          })
        })
      })
    }
  }

  async submitTerms() {
    if (this.selectedDocument !== null) {
      let ref = this.storage.ref(this.selectedDocument.name)
      await ref.put(this.selectedDocument)
      this.termsOfUse = await ref.getDownloadURL().toPromise()
    }
    this.dashboardService.insertTermsOfUse({
      id: this.insertTermsForm.value.id, termsOfUse: this.termsOfUse
    }).subscribe((response) => {
      console.log(response)
      this.snackBar.open(this.labels.lblTermsOfUseAdded, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    })
    this.selectedDocument = null
  }

  onSubmit() {
    this.onImageSubmit()
    if (this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.userName == "" || this.editForm.value.email == "") {
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else if (this.editForm.value.newPassword != this.editForm.value.confirmPassword) {
      this.snackBar.open(this.labels.lblPasswordFieldError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else if ((this.editForm.value.newPassword != "" && this.editForm.value.confirmPassword != "") && (this.editForm.value.newPassword === this.editForm.value.confirmPassword)) {
      this.dashboardService.editAdminInfo({
        flg: "withpass",
        id: this.editForm.value.id,
        firstName: this.editForm.value.firstName,
        lastName: this.editForm.value.lastName,
        userName: this.editForm.value.userName,
        email: this.editForm.value.email,
        password: this.editForm.value.newPassword
      }).subscribe((response) => {
        this.admin.firstName = this.editForm.value.firstName
        this.admin.lastName = this.editForm.value.lastName
        this.admin.userName = this.editForm.value.userName
        this.admin.email = this.editForm.value.email
        this.disable = true;
      })
      this.snackBar.open(this.labels.lblDataChanged, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else if (this.editForm.value.newPassword == "" && this.editForm.value.confirmPassword == "") {
      this.dashboardService.editAdminInfo({
        flg: "withoutpass",
        id: this.editForm.value.id,
        firstName: this.editForm.value.firstName,
        lastName: this.editForm.value.lastName,
        userName: this.editForm.value.userName,
        email: this.editForm.value.email
      }).subscribe((response) => {
        this.admin.firstName = this.editForm.value.firstName
        this.admin.lastName = this.editForm.value.lastName
        this.admin.userName = this.editForm.value.userName
        this.admin.email = this.editForm.value.email
        console.log("response")
        console.log(response)
        console.log(this.admin)
        this.disable = true;
      })
      this.snackBar.open(this.labels.lblDataChanged, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }

    //this.selectedImage = null
  }

  onImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "jpg" || ext == "jpeg" || ext == "png") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: Number = +iConvert
      if (number <= 5) {
        this.selectedImage = event.target.files[0]
        var fr = new FileReader();
        fr.onload = e => this.selectedImageUrl = fr.result;
        fr.readAsDataURL(this.selectedImage)
        const dialogRef = this.dialog.open(ProfileImageCropperDialogComponent, {
          width: "500px",
          height: "600px",
          data: event
        })

        dialogRef.afterClosed().subscribe((result) => {
          this.imageUpload.nativeElement.value = ""
          this.selectedImage = result
          fr.onload = e => this.selectedImageUrl = fr.result;
          fr.readAsDataURL(this.selectedImage)
          console.log(this.selectedImage)
        })
      }
      else {
        this.snackBar.open(this.labels.lblMaxSizeDocument, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }
    }
    else {
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsDocument, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteImageComponent, {
      width: '500px',
      data: { admin: this.admin }
    });
  }

  enableAll() {
    this.disable = false;
  }

  onDocumentChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "pdf" || ext == "docx") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: Number = +iConvert
      if (number <= 5) {
        this.selectedDocument = event.target.files[0]
      }
      else {
        this.snackBar.open(this.labels.lblMaxSizeDocument, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.documentUpload.nativeElement.value = ""
      }

    }
    else {
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsDocument, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.documentUpload.nativeElement.value = ""
    }
  }

  updateImage() {
    this.selectedImage = null
  }

  // onSubmit(){
  //   if(this.insertForm.value.newPassword === this.insertForm.value.newPasswordConfirm){
  //     this.dashboardService.changePassword(this.insertForm.value).subscribe((response)=>{
  //       console.log(response)
  //     })
  //     this.snackBar.open('Uspesno ste izmenili lozinku','OK',{
  //       verticalPosition:'top',
  //       horizontalPosition:'end',
  //       duration:5000})
  //   }else{
  //     this.snackBar.open('Nova lozinka i potvrda se ne poklapaju','OK',{
  //       verticalPosition:'top',
  //       horizontalPosition:'end',
  //       duration:5000,

  //     })
  //   }
  // }

}
