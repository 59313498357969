import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Administrator } from 'src/app/models/administrator.model';
import { DashboardService } from '../../dashboard.service';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
export interface DialogData{
  admin: Administrator
}

@Component({
  selector: 'app-delete-image',
  templateUrl: './delete-image.component.html',
  styleUrls: ['./delete-image.component.scss']
})
export class DeleteImageComponent implements OnInit {
  admin: Administrator
  adminSub: Subscription;
  labels;
  labelsSub: Subscription;
  constructor(private adminAuthService: AdminAuthService, public dialogRef: MatDialogRef<DeleteImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private dashboardService: DashboardService, private userLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.adminSub = this.adminAuthService.admin$.subscribe((admin: Administrator) => {
      this.admin = admin;
      console.log(this.admin)
    });
    this.getLang();
  }

  getLang(){
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
    this.labels = labels;
    })
  }

  deleteImage(){
    this.data.admin.profileImagePath = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/noImage.jpg?alt=media&token=b8c8a3ec-2293-4657-bfd0-c10c19255144"

    this.dashboardService.editAdminInfo({
     flg: "img",
     id: this.data.admin.id,
     profileImage: this.data.admin.profileImagePath
   }).subscribe((response) => {
     console.log(response)
   })
      this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
   }
}
