<mat-dialog-container>
    <mat-dialog-content>
        <h3 style="color: #FF281B;">{{labels.lblWrongAns}}</h3>
        <h4 style="color: #FFF;">{{labels.lblCorrectAns}}</h4>
        <h4 *ngFor="let i of data.text">{{i}}</h4>
        <div style="color: #FFF; margin: auto; text-align: center">
            <button class="button" [class]="buttons" mat-button (click)="onNoClick()" style="margin-top: 15px;"
                type="button" matDialogClose>{{labels.lblContinue}}</button>
        </div>
    </mat-dialog-content>
</mat-dialog-container>