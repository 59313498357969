import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { DashboardService } from '../dashboard.service'
import { Observable, Subscription } from 'rxjs'
import { User } from '../../models/user.model'
import { DataSource } from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogComponent } from './../admin-training-section-detail/edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './../admin-training-section-detail/insert-dialog/insert-dialog.component';
import { AdminControlComponent } from './../admin-training-section-detail/admin-control/admin-control.component';
import { Training } from './../../models/training.model'
import { ActivatedRoute, Router } from '@angular/router'
import { DeleteDialogComponent } from './../admin-training-section-detail/delete-dialog/delete-dialog.component'
import { TrainingSection } from 'src/app/models/training-section.model';
import { Unit } from './../../models/unit.model'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AssignTestComponent } from '../admin-training-detail/assign-test/assign-test.component';
import { share } from 'rxjs/operators';

@Component({
  selector: 'app-admin-training-section-detail',
  templateUrl: './admin-training-section-detail.component.html',
  styleUrls: ['./admin-training-section-detail.component.scss']
})
export class AdminTrainingSectionDetailComponent implements OnInit {

  @ViewChild('table') table: MatTable<Unit>
  value = true
  displayedColumns: string[] = ['order', 'name', 'duration', 'actions'];
  id: string
  sectionId: string
  dataSource
  labels;
  labelsSub: Subscription;
  training
  section
  assigned
  testName
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  constructor(public db: AngularFirestore, private dashboardService: DashboardService, private router: ActivatedRoute, public dialog: MatDialog, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.id = this.router.parent.snapshot.params['id']
    this.isTrainingAssigned()
    this.sectionId = this.router.snapshot.params['sectionId']
    console.log(this.id)
    this.getTraining(this.id)
    this.getSectionName(this.id, this.sectionId)
    this.getUnitsForSection(this.id, this.sectionId)
    this.router.params.subscribe((params) => {
      this.id = params['id']
    })
    this.router.parent.params.subscribe((params) => {
      this.id = params['id']
    })
    this.getLang()
  }

  ngAfterViewInit (){
    this.dataSource.sort = this.sort;
  }

  isTrainingAssigned() {
    this.dashboardService.isTrainingAssigned(this.id).subscribe((response) => {
      if (response == null) {
        this.assigned = []
      } else {
        this.assigned = response
      }
    })
  }

  getTraining(id) {
    return this.dashboardService.getTraining(id).subscribe((training) => {
      this.training = training;
      console.log(this.training)
    })
  }

  getSectionName(id, sectionId) {
    this.dashboardService.getSectionName(id, sectionId).subscribe((section) => {
      this.section = section
      setTimeout(() => {
       if(this.section.testId){
        this.getTest(this.section.testId)
       }else{
         this.testName = ""
       }
      }, 300);
    })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  getTest(id: string) {
    this.dashboardService.getTest(id).subscribe(test => {
      this.testName = test.name
    })
  }

  assignTest(section) {
    const dialogRef = this.dialog.open(AssignTestComponent, {
      width: '500px',
      height: '300px',
      data: { id: section.id, trainingId: section.trainingId, testId: section.testId }
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.testName = null
      this.dataSource = null
      console.log("after assign section test modal close")
      console.log(response)
      console.log(this.testName)
      this.getTest(response)
      console.log(this.testName)
      this.getUnitsForSection(this.id, this.sectionId)
    })
  }

  getUnitsForSection(id, sectionId) {
    this.dashboardService.getUnitsForSection(id, sectionId).subscribe((units) => {
      let trUnits = units as Unit[]
      trUnits = trUnits.sort((a, b) => (a.order < b.order ? -1 : 1));
      this.dataSource = new MatTableDataSource(trUnits)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
      this.dataSource.filterPredicate = (data : Unit, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());

      
    })
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  addNewUnit() {
    let units = this.dataSource.data
    const dialogRef = this.dialog.open(InsertDialogComponent, {
      width: '500px',
      data: { id: this.id, sectionId: this.sectionId, order: units.length + 1 }
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.getUnitsForSection(this.id, this.sectionId)
      this.dashboardService.getSectionsForTraining(this.training.id).pipe(share())
    })
  }
  

  openModalForControlQuestion(sectionUnit) {
    const dialogRef = this.dialog.open(AdminControlComponent, {
      width: '500px',
      data: sectionUnit
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.getUnitsForSection(this.id, this.sectionId)
    })
  }

  deleteUnit(sectionUnit) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: sectionUnit
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      console.log(response)
      this.dashboardService.getUnitsForSection(this.id, this.sectionId).subscribe((units) => {
        let trUnits = units as Unit[]
        trUnits = trUnits.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.dataSource = new MatTableDataSource(trUnits)
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator
        this.dataSource.filterPredicate = (data : Unit, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
        this.changeOrder1()
        this.dashboardService.getSectionsForTraining(this.training.id).pipe(share())
      })
    })
  }
  editUnit(sectionUnit) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '500px',
      height: '500px',
      data: { trainingId: this.id, sectionId: this.sectionId, sectionUnit: sectionUnit, type: sectionUnit.type, name: sectionUnit.name }
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.getUnitsForSection(this.id, this.sectionId)
    })
  }
  drop(event) {
    let units = this.dataSource.data
    const prevIndex = units.findIndex((d) => d === event.item.data)
    moveItemInArray(units, prevIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(units)
    this.table.renderRows()

  }
  changeOrder() {
    let units = this.dataSource.data
    console.log(units)
    for (let index = 0; index < units.length; index++) {
      const element = units[index];
      this.dashboardService.changeSectionUnitOrder({
        id: element.id,
        trainingId: element.trainingId,
        trainingSectionId: element.trainingSectionId,
        order: index + 1
      }).subscribe((response) => {
        console.log(response)
        this.getUnitsForSection(this.id, this.sectionId)
      })

    }
    this.value = !this.value
  }
  changeOrder1() {
    let units = this.dataSource.data
    console.log(units)
    for (let index = 0; index < units.length; index++) {
      const element = units[index];
      this.dashboardService.changeSectionUnitOrder({
        id: element.id,
        trainingId: element.trainingId,
        trainingSectionId: element.trainingSectionId,
        order: index + 1
      }).subscribe((response) => {
        console.log(response)
        this.getUnitsForSection(this.id, this.sectionId)
      })

    }
  }
  showChangeButton() {
    this.value = !this.value;
  }

}
