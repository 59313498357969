import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { TrainingSection } from 'src/app/models/training-section.model';
import { Training } from 'src/app/models/training.model';
import { Unit } from 'src/app/models/unit.model';
import { User } from 'src/app/models/user.model';
import { UserBeforePreTestComponent } from '../../pre-test/user-before-pre-test/user-before-pre-test.component';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  labels;
  labelsSub: Subscription;
  training: Training = null
  trainingSub: Subscription
  training$: Observable<Training>
  currentUnit: Unit
  currentUnitSub: Subscription
  currentSection: TrainingSection
  user: User
  userSub: Subscription;
  currentUnit$: Observable<Unit>
  button1
  showSpinner: boolean = true
  constructor(private authService: AuthService, private localStorageService: LocalStorageService, public dialog: MatDialog, private router: Router, private userDashboarService: UserDashboardService, public dialogRef: MatDialogRef<WelcomeComponent>, private languageService: UserLanguageService, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.getLang()
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.button1 = "buttonEMS"

        }else if(this.user.companyId == 2){
          this.button1 = "buttonECG"
        }
        else{
          this.button1 = "buttonECG"
        }
      }, 200);
    });
    this.training$ = this.userDashboarService.getTraining().pipe(share())
    this.trainingSub = this.userDashboarService.training$.subscribe((training) => {
      this.training = training;
    })
    this.currentUnitSub = this.userDashboarService.currentUnit$.subscribe((unit: Unit) => {
      setTimeout(() => {
        this.setCurrentUnit(unit)
    }, 1500);
    }) 


  }

  getLang(){
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

   goToTraining(training: Training) {
    const tr = this.user.trainings.filter((t) => t.trainingId == training.id)[0]
    this.currentUnit$ = this.userDashboarService.getCurrentUnit(training.id).pipe(share());

    if (!tr.pretestFlag && training.pretestId!="") {
      this.router.navigateByUrl('user/start-pretest');
      //this.openTestDialog();
    }
    else {
      this.localStorageService.setItem("trainingInfo", training);
      this.router.navigateByUrl('user/training');
    }
    this.changeStatus(training, 2)

    this.dialogRef.close()
  }

  changeStatus(training: Training, status: Number){
    console.log("TRAINING ID: " + training.id)
    this.userDashboarService.changeTrainingStatus(training.id, status).subscribe((result)=>{
      console.log(result)
     })
  }

  openTestDialog(): void {
    const dialogRef = this.dialog.open(UserBeforePreTestComponent, {
      disableClose: true,
      width: '350px'
    });
  }

  ondDestroy() {
    this.userSub.unsubscribe();
  }

  setCurrentUnit(unit: Unit): void {
    this.currentUnit = unit;
    this.showSpinner = false;
  }
}
