import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { DashboardService } from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Department } from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import { Unit } from './../../../models/unit.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { AngularFireStorage } from '@angular/fire/storage'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperDialogComponent } from '../../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  selectedImage: File = null
  selectedDocument: File = null
  selectedVideo: File = null
  selectedVideoMobile: File = null

  downloadUrl$: Observable<string>
  downloadUrlSub: Subscription
  unitType
  @ViewChild('editForm') editForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  @ViewChild('videoUpload') videoUpload: ElementRef
  @ViewChild('documentUpload') documentUpload: ElementRef
  @ViewChild('videoUploadMobile') videoUploadMobile: ElementRef
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  duration
  durationMobile
  video
  allVideos
  selectedAudio: File = null
  audioDur
  extension
  extensionMobile
  data1
  assigned
  uploading: boolean = false

  constructor(public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    private adminLanguageService: AdminLanguageService, private storage: AngularFireStorage,
    public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.data)
    this.err = false;
    this.unitType = this.data.sectionUnit.type
    this.getFileNames()
    this.getLang()
    this.isTrainingAssigned()
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  async onSubmit() {
    let uploaded = (this.selectedVideo != null || this.data.sectionUnit.videoPath!= null || this.data.sectionUnit.audioPath!= null || this.selectedDocument != null || this.data.sectionUnit.documentPath!= null || this.selectedImage != null || this.data.sectionUnit.imagePath!= null || (this.editForm.value.text !== "" && this.editForm.value.text != null))
    if (this.editForm.value.name == "" || !uploaded) {
      this.err = false;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.uploading = true
      this.data1 = {
        id: this.data.sectionUnit.id,
        type: Number(this.editForm.value.type),
        name: this.editForm.value.name,
        videoPath: this.data.sectionUnit.videoPath,
        videoPathMobile: this.data.sectionUnit.videoPathMobile,
        imagePath: this.data.sectionUnit.imagePath,
        documentPath: this.data.sectionUnit.documentPath,
        trainingId: this.data.trainingId,
        sectionId: this.data.sectionId,
        text: null,
        videoDuration: this.data.sectionUnit.videoDuration,
        audioDuration: this.data.sectionUnit.audioDuration,
        videoDurationMobile: this.data.sectionUnit.videoDurationMobile,
        audioDurationMobile: this.data.sectionUnit.audioDurationMobile,
        audioPath: this.data.sectionUnit.audioPath,
        audioPathMobile: this.data.sectionUnit.audioPathMobile,
        documentName: this.data.sectionUnit.documentName
      }
      if (this.selectedVideo !== null) {
        if (this.extension == "mp3") {
          console.log(this.duration)
          this.data1.audioDuration = this.formatTime(this.duration)
          let ref = this.storage.ref(this.selectedVideo.name)
          await ref.put(this.selectedVideo)
          this.data1.audioPath = await ref.getDownloadURL().toPromise()
          this.data1.videoPath = null
          this.data1.videoDuration = null
          console.log(this.data1.audioPath)
          this.data1.imagePath = null
          this.data1.documentPath = null
          this.data1.documentName = null
        }
        else if (this.extension == "mp4" || this.extension == "mov") {
          this.data1.videoDuration = this.formatTime(this.duration)
          let ref = this.storage.ref(this.selectedVideo.name)
          await ref.put(this.selectedVideo)
          this.data1.videoPath = await ref.getDownloadURL().toPromise()
          this.data1.audioPath = null
          this.data1.audioDuration = null
          this.data1.imagePath = null
          this.data1.documentPath = null
          this.data1.documentName = null
        }
      }
      if (this.selectedVideoMobile !== null) {
        if (this.extensionMobile == "mp3") {
          this.data1.audioDurationMobile = this.formatTime(this.durationMobile)
          let ref = this.storage.ref(this.selectedVideoMobile.name)
          await ref.put(this.selectedVideoMobile)
          this.data1.audioPathMobile = await ref.getDownloadURL().toPromise()
          this.data1.videoPathMobile = null
        }
        else if (this.extension == "mp4" || this.extension == "mov") {
          this.data1.videoDurationMobile = this.formatTime(this.durationMobile)
          let ref = this.storage.ref(this.selectedVideoMobile.name)
          await ref.put(this.selectedVideoMobile)
          this.data1.videoPathMobile = await ref.getDownloadURL().toPromise()
          this.data1.audioPathMobile = null
        }
      }
      if (this.selectedDocument !== null) {
        let ref = this.storage.ref(this.selectedDocument.name)
        await ref.put(this.selectedDocument)
        this.data1.documentPath = await ref.getDownloadURL().toPromise()
        this.data1.documentName = this.selectedDocument.name
        this.data1.imagePath = null
        this.data1.videoPath = null
        this.data1.videoDuration = null
        this.data1.audioPath = null
        this.data1.audioDuration = null
      }
      if (this.selectedImage !== null) {
        console.log("usao u IF")
        let ref = this.storage.ref(this.selectedImage.name)
        await ref.put(this.selectedImage)
        this.data1.imagePath = await ref.getDownloadURL().toPromise()
        console.log(this.data1.imagePath)
        this.data1.documentPath = null
        this.data1.documentName = null
        this.data1.videoPath = null
        this.data1.videoDuration = null
        this.data1.audioPath = null
        this.data1.audioDuration = null
      }
      if (this.editForm.value.text != null) {
        this.data1.text = this.editForm.value.text
      }
      console.log(this.data1)
      this.uploading = false
      this.dashboardService.editUnit(this.data1).subscribe((unit) => {
        console.log(unit)
        this.snackBar.open(this.labels.lblTrainingUnitEdited, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
      })
    }
  }

  formatTime(seconds) {
    // var hh = Math.floor(seconds / 3600)
    let m = Math.floor(seconds / 60) % 60
    let s = Math.floor(seconds) % 60;
    return (m + ":" + (s < 10 ? "0" : "") + s)
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onSelect(value: Number) {
    this.unitType = value
  }

  getVideoDuration = async (f) => {
    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
          else fileObj.onloadedmetadata = resolve;
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000);
        }),
      ]);
    };

    const objectUrl = URL.createObjectURL(f);
    // const isVideo = type.startsWith('video/');
    const video = document.createElement("video");
    video.src = objectUrl;
    await fileCallbackToPromise(video);
    this.duration = video.duration
    console.log(this.duration)
    console.log(video.duration)
    return video.duration

  }

  getVideoDurationMobile = async (f) => {
    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
          else fileObj.onloadedmetadata = resolve;
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000);
        }),
      ]);
    };

    const objectUrl = URL.createObjectURL(f);
    // const isVideo = type.startsWith('video/');
    const video = document.createElement("video");
    video.src = objectUrl;
    await fileCallbackToPromise(video);
    this.durationMobile = video.duration
    return video.duration

  }

  getAudioDuration = async (f) => {
    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
          else fileObj.onloadedmetadata = resolve;
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000);
        }),
      ]);
    };

    const objectUrl = URL.createObjectURL(f);
    const audio = document.createElement("audio");
    audio.src = objectUrl;
    await fileCallbackToPromise(audio);
    this.audioDur = audio.duration
    console.log(audio.duration)
    return audio.duration

  }


  onImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if(ext == "jpg" || ext == "jpeg" || ext == "png"){
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 50){
        this.selectedImage = event.target.files[0]
        const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
          width: "500px",
          height: "600px",
          data: event
        })
        dialogRef.afterClosed().subscribe((result) => {
          this.imageUpload.nativeElement.value = ""
          this.selectedImage = result
          this.data.sectionUnit.imageName = this.selectedImage.name
          this.data.sectionUnit.imagePath = ""
          console.log(this.selectedImage)
        })
      }
      else{
        this.snackBar.open(this.labels.lblMaxFileSizeImage , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }
  }
  onVideoChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "mp4" || ext == "mp3") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:number = +iConvert
      if(number <= 250){
        const meta = this.getVideoDuration(event.target.files[0]);
        let dur = event.target.files[0].name
        var a = dur.split('.');
        this.extension = a[a.length - 1]
        console.log(this.extension)
        this.selectedVideo = event.target.files[0]
        this.data.sectionUnit.videoName = this.selectedVideo.name
        this.data.sectionUnit.videoPath = ""
      }
      else{
        this.snackBar.open(this.labels.lblMaxSizeVideo , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.videoUpload.nativeElement.value = ""
      }
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.videoUpload.nativeElement.value = ""
    }
  }
  onVideoChangedMobile(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "mp4" || ext == "mp3") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 20){
        const meta = this.getVideoDurationMobile(event.target.files[0]);
        let dur = event.target.files[0].name
        var a = dur.split('.');
        this.extensionMobile = a[a.length - 1]
        this.selectedVideoMobile = event.target.files[0]
        this.data.sectionUnit.videoNameMobile = this.selectedVideoMobile.name
        this.data.sectionUnit.videoPathMobile = ""
      }
      else{
        this.snackBar.open(this.labels.lblMaxSizeVideo , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.videoUploadMobile.nativeElement.value = ""
      }
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.videoUploadMobile.nativeElement.value = ""
    }
  }
  onDocumentChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if(ext == "pdf" || ext == "docx"){
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 50){
        this.selectedDocument = event.target.files[0]
        this.data.sectionUnit.documentName = this.selectedDocument.name
        this.data.sectionUnit.documentPath = ""
      }
      else{
        this.snackBar.open(this.labels.lblMaxSizeDocument , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.documentUpload.nativeElement.value = ""
      }
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsDocument , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.documentUpload.nativeElement.value = ""
    }
  }

  isTrainingAssigned() {
    this.dashboardService.isTrainingAssigned(this.data.trainingId).subscribe((response) => {
      if (response == null) {
        this.assigned = []
      } else {
        this.assigned = response
      }
    })
  }

  getFileNames() {
    if (this.data.sectionUnit.videoPath != null) {
      let array = this.data.sectionUnit.videoPath.split('?')
      let array1 = array[0].split('/')
      this.data.sectionUnit.videoName = array1[array1.length - 1]
    }
    if (this.data.sectionUnit.audioPath != null) {
      let array = this.data.sectionUnit.audioPath.split('?')
      let array1 = array[0].split('/')
      this.data.sectionUnit.audioName = array1[array1.length - 1]
    }
    if (this.data.sectionUnit.imagePath != null) {
      let array = this.data.sectionUnit.imagePath.split('?')
      let array1 = array[0].split('/')
      this.data.sectionUnit.imageName = array1[array1.length - 1]
    }
    if (this.data.sectionUnit.documentPath != null) {
      let array = this.data.sectionUnit.documentPath.split('?')
      let array1 = array[0].split('/')
      // this.data.sectionUnit.documentName = array1[array1.length - 1]
    }
    if (this.data.sectionUnit.videoPathMobile != null) {
      let array = this.data.sectionUnit.videoPathMobile.split('?')
      let array1 = array[0].split('/')
      this.data.sectionUnit.videoNameMobile = array1[array1.length - 1]
    }
    if (this.data.sectionUnit.audioPathMobile != null) {
      let array = this.data.sectionUnit.audioPathMobile.split('?')
      let array1 = array[0].split('/')
      this.data.sectionUnit.audioNameMobile = array1[array1.length - 1]
    }

  }

  updateData(flag: string) {
    if (flag == "1") {
      this.data.sectionUnit.videoPath = null
      this.data.sectionUnit.videoDuration = null
    }
    else if (flag == "2") {
      this.data.sectionUnit.audioPath = null
      this.data.sectionUnit.audioDuration = null
    }
    else if (flag == "3") {
      this.data.sectionUnit.documentPath = null
    }
    else if(flag == "4"){
      this.data.sectionUnit.imagePath = null
      this.selectedImage = null
    }
    else if(flag == "5"){
      this.data.sectionUnit.videoPathMobile = null
      this.data.sectionUnit.videoDurationMobile = null
    }
    else{
      this.data.sectionUnit.audioPathMobile = null
      this.data.sectionUnit.audioDurationMobile = null
    }

  }


}
