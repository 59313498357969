<!-- <body [style.background-color] = "bodyBackgroundColor"> -->
    <!-- <app-loader></app-loader> -->
<app-user-spinner
*ngIf="user == null || labels == null || users == null || messages == null || showSpinner == null || showForumSpinner  == null">
</app-user-spinner>
<div class="row messagesList2" style="width: 100%;">
<div class="leftSide col-12 col-sm-4 col-xl-4" [class.hideDiv] = "chatVisibility == 'chatVisible'" [class.changeHeight] = "forumVisibility == 'forumVisible'" style = "padding-right: 0px; padding-left: 0px;">
    <div class="msgs">
        <div class="porukeNslov" style="padding-top:15px; text-align: center;">
            <h3 style = "margin: auto; color: #0E0D35; font-family: Lato, 'Alegreya Sans';">{{labels.lblMessages}}</h3>
            <div style = "width: 80%; margin: auto; margin-bottom: 25%;">
                <div class = "chatGroup" style = "float: left;">
                    <div class = "row" (click) = "backToUsersList()">
                        <div class = "col-12">
                            <img *ngIf = "chatVisibility == 'notVisible'" style = "width: 30px; height: 30px; border-radius: 0px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FchatUsersGrey.png?alt=media&token=c56c5675-09c6-4528-ba57-99e5fb6e93f2" alt = "chat users image">
                            <img *ngIf = "chatVisibility == 'chatVisible'" style = "width: 30px; height: 30px; border-radius: 0px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FchatUsersGreen.png?alt=media&token=43b18c9d-b5b0-4ed5-a3d7-70f3bd457ac3" alt = "chat users image">
                        </div>
                        <div class = "col-12" [style.color] = "chatVisibility == 'notVisible' ? '#e7e7e7' : '#49D14F'">
                            {{labels.lblFriends}}
                        </div>
                    </div>
                </div>
                <div class = "chatGroup" (click)="changeForumVisibility()" style = "float: right;">
                    <div class = "row">
                        <div class = "col-12">
                            <img *ngIf = "forumVisibility == 'notVisible'" style = "width: 30px; height: 30px; border-radius: 0px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGrey.png?alt=media&token=5ecdfd13-50ee-4818-9c02-f42ffbc86553" alt = "forum show image">
                            <img *ngIf = "forumVisibility == 'forumVisible'" style = "width: 30px; height: 30px; border-radius: 0px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGreen.png?alt=media&token=04fc5e1c-1b22-4058-a596-2e68720f61e6" alt = "forum hide image">
                        </div>
                        <div class = "col-12" [style.color] = "forumVisibility == 'notVisible' ? '#e7e7e7' : '#49D14F'">
                            {{labels.lblSectorGroup}}
                        </div>
                    </div>
                </div>
            </div>
            <div class = "searchDiv" style="width: 80%; text-align: left; border: 1px solid #E4DFDF; border-radius: 11px; height: 100%; background-color: white; padding: 10px; margin: auto; margin-bottom: 7%;">
                <!-- <span><img style = "display: inline-block; width: 25px; height: 25px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2Fsearch.png?alt=media&token=cc3ee9cb-c984-41bb-84cc-5afd46ca2cba"></span> -->
                <span style = "display: inline-block; vertical-align: middle;"><mat-icon [inline] = "true" style = "color: #747688; vertical-align: middle; font-size: 23px;">search</mat-icon></span>
                <span style = "display: inline-block; font-family: Lato, 'Alegreya-Sans'; font-size: 17px; vertical-align: middle;">
                    <input matInput ngModel placeholder={{labels.lblSearch}}
                    style="color: #747688; outline: none;font-family: Lato, 'Alegreya Sans';"
                    (keyup)="userSearch($event)">
                </span>
            </div>
        </div>

        <div class="msgs2" [class] = "displayUsersList">
            <!-- <div class="row users forum"
                (click)="changeForumVisibility()" style="cursor: pointer; margin:11px">
                <div class="col-3" style="padding-left: 0px;">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/forum%20logo.png?alt=media&token=6611a728-740b-4585-a197-13c034f8afd5">
                </div>
                <div class="col-9" style="padding-left:0px;">
                    <div class="row" style="padding-top: 5%; padding-bottom: 5%">
                        <div class="col-9"
                            style="text-align: left; font-weight: bold; padding-left: 0px; font-size: 16px;">Leste
                            forum</div></div>
                </div>
            </div> -->
            <div *ngFor="let u of users" style="width: 80%; margin: auto;">
                <div mat-list-item class="row users" [class]="usersList"
                    (click)="chatWithUser(u, 7, 0, lang)"
                    style="margin-top: 0px; margin-bottom:0px; cursor: pointer;">
                    <div class="col-3" style="padding-left: 0px;">
                        <img src={{u.profileImagePath}} alt = "users profile image">
                    </div>
                    <div class="col-9" [class.imgPadding]="!u.lastMessage" style="padding-left: 0px;">
                        <div class="row">
                            <div class="col-9"
                                style="text-align: left; font-weight: bold; padding-left: 0px; color:#0E0D35">
                                {{u.firstName}} {{u.lastName}}</div>
                            <div class="col-3 text-right" style="text-align: right">
                                <div *ngIf="u.online == true"
                                    style="display: inline-block; margin-left:5px; width:10px; height:10px; border:1px solid limegreen; border-radius: 50%; background-color: limegreen;">
                                </div>
                                <div *ngIf="u.online == false"
                                    style="display: inline-block; margin-left:5px; width:10px; height:10px; border:1px solid #bfbfbf; border-radius: 50%; background-color: #bfbfbf;">
                                </div>
                            </div>
                            <div class="col-7" *ngIf="u.lastMessage"
                                style="font-size:13.5px; color: #0E0D35; text-align:left; margin-top:5px; padding-left: 0px;">
                                <span *ngIf="u.from == true">{{labels.lblYou}} </span> <span
                                    *ngIf="u.lastMessage.seen == true">{{u.lastMessage.lastMessage.substring(0,8)}}</span>
                                <span *ngIf="u.lastMessage.seen == false"
                                    style="font-weight: bold; color: 0E0D35;">{{u.lastMessage.lastMessage.substring(0,8)}}</span>
                                <span *ngIf="u.lastMessage.lastMessage.length > 8">...</span>
                            </div>
                            <div class="col-5" *ngIf="u.lastMessage"
                                style="font-family: Lato !important; font-size:12.5px; text-align:right; padding-right:0px; color: #747688; margin-top: 5px; padding-right: 15px;">
                                {{u.lastMessage.timestamp}}</div>

                        </div>
                    </div>
                    <!-- <div class="row" style="width: 100%">
                        <div class="col-11" style="text-align:left; margin-top:20px;">
                            Primer jedne poruke iz chat-a.
                        </div>                                
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- forum -->
<div class="col-12 col-sm-8 col-xl-8 messages" style = "height: 88.5vh" *ngIf="showDiv == 'forum'" [class]="forumVisibility">

    <div class="messages messagesMob" style="margin-top:0px;">
        <div class="row" style="padding:11px;  margin: auto;">
            <div class="col-8 usrName" style="margin-top:auto; margin-bottom:auto; margin-left: 2%;">
                <div class="row">
                    <div class="col-12 userName" style="text-align: left;">
                        <h1
                            style="color: #0E0D35; padding:10px; float: left; margin-bottom:0px !important;font-family: Lato, 'Alegreya Sans'; font-weight: bold;">
                           {{labels.lblSectorGroup}}</h1>
                    </div>
                    <!--  <div class="col-12" style="color: #737373; font-size: 15px; text-align: left !important;">sanja@leste.rs</div> -->
                </div>
            </div>
        </div>
        <div [style.height]="messagesHeight" class="row msgList msgListNew" #scrollMe [scrollTop]="scrollMe.scrollHeight"
            (scroll)="onScrollForumFunction($event)" style="margin: auto;">
            <div *ngFor="let m of messages" style="width: 100%;">
                <div class="row poruka" [class]="displayImgAndInfo(m)" style="margin: auto;">
                    <div class = "col-6" [class]="floatClass(m)"
                        style="width: 100% !important; margin-top: 10px !important">
                        <!-- <div class="row" *ngIf="displayMessage == true"
                            style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                            <div class="col-9 offset-2 datetime"
                                style="color: #737373; width: 100%; text-align: left; margin-bottom:0xp !important; padding-bottom:0px !important;">
                                {{m.firstName}} {{m.lastName}}
                            </div>
                        </div> -->
                        <div class="userMessages" [class]="msgBackground(m)"
                            style="width: 100%; margin-top: 11px !important" *ngIf="m.fileUrl == null">
                            {{m.text}}
                        </div>
                        <!-- <div class="userMessages" [class]="msgBackground(m)"
                            style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl != null && m.idFrom==user.id">
                            <mat-icon style="float:left; color: white;">download</mat-icon>
                            <a href="{{m.fileUrl}}" style="color: white;" target="_blank">{{m.text}}</a>
                        </div>
                        <div class="userMessages" [class]="msgBackground(m)"
                            style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl != null && m.idFrom!=user.id">
                            <mat-icon style="float:left; color: white;">download</mat-icon>
                            <a href="{{m.fileUrl}}" style="color: white;" target="_blank">{{m.text}}</a>
                        </div> -->
                        <div class="mobImg" *ngIf="displayMessage == true"
                            style="margin-top:0px !important; padding-top: 1%; position: absolute; bottom: 0; right: 0;">
                            <img style = "width: 30px; height: 30px;" src={{m.imagePath}} alt = "user profile image">
                        </div>
                        <div class="row"
                            style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                            <div class="col-9 offset-2 datetime" [class]="floatDateClass(m)"
                                style="color: #737373; width: 100%; text-align: left; margin-top:0xp !important; padding-top:0px !important;">
                                {{m.dateTime}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row" style="width: 100%; margin: auto;">
            <form class="col-12" #insertForm="ngForm" (keydown.enter)="submitMessage()">
                <input matInput [ngModel]="user.firstName" name="firstName" hidden>
                <input matInput [ngModel]="user.lastName" name="lastName" hidden>
                <input matInput [ngModel]="user.email" name="sender" hidden>
                <mat-form-field appearance="none">
                    <input class="msgInput" matInput ngModel name="textMessage"
                        placeholder="{{labels.lblWriteAMessage}}">
                </mat-form-field>
                <input type="file" (change)="onFileChanged($event)" #fileUpload style="display: none;">
                <!-- <mat-icon (click)="fileUpload.click()"
                    style="color:#0E0D35; padding-left: 20px; padding-right: 20px; width: 7%; cursor: pointer; ">
                    attach_file</mat-icon> -->
                <mat-icon (click)="submitMessage()"
                    style="color:#49D14F; padding-left: 20px; padding-right: 20px; width: 7%; cursor: pointer; ">
                    send</mat-icon>
            </form>
        </div>
        <div class="row" style="width: 100%; margin: auto;" *ngIf="selectedFile != null">
            <mat-divider class="divider" style="width: 100%; margin-bottom: 12px;"></mat-divider>
            <div style="color: white; border-radius: 15px; padding: 5px; font-size: 15px;" [style.background-color]="color">
                <mat-label>{{selectedFile.name.substring(0,15)}}<span *ngIf="selectedFile.name.length > 15">...</span></mat-label>
                <mat-icon style="float:right; cursor: pointer; font-size: 15px; padding-top: 4px;" (click)="updateFile()">close</mat-icon>
            </div>
        </div>
    </div>
</div>
<!-- 1 na 1 -->
<div class="col-12 col-sm-8 col-xl-8 messages" style = "height: 88.5vh;" *ngIf="showDiv == 'chat'" [class]="chatVisibility">

    <div class="messages messagesMob" style="margin-top:0px;">
        <div class="row" style="padding:11px;">
            <div class="col-1 back" (click)="backToUsersList()" style="background-color: white; color:black">
                <span>
                    <mat-icon style="color: #0E0D35; font-weight: bold; font-size: 33px; vertical-align: middle;">
                        keyboard_backspace</mat-icon>
                </span>
            </div>
            <div class="col-1 profImg" style="text-align:right; height: 65px;">
                <img style="width: 65px; height: 65px;" src={{userToImg}} alt = "user profile image">
            </div>
            <div class="col-10 usrName" style="margin-top:auto; margin-bottom:auto; margin-left: 5%;">
                <div class="row">
                    <div class="col-11 userName" style="text-align: left;">
                        <h1 style="color: #0E0D35; padding:10px; float: left; margin-bottom:0px !important; font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 20.5px;">{{userToFullName}}</h1>
                    </div>
                    <!-- <div class="col-1" style="text-align: right;">
                        <div style = "padding-top: 10%">
                            <mat-icon style="color:rgb(241, 106, 100); cursor: pointer; font-size: 2.3rem" (click) = "startVideoCall(userTo)">phone</mat-icon>
                        </div>
                    </div> -->
                    <!--  <div class="col-12" style="color: #737373; font-size: 15px; text-align: left !important;">sanja@leste.rs</div> -->
                </div>
            </div>
        </div>
        <div class="row msgList" #scrollMessages [scrollTop]="scrollMessages.scrollHeight"
            (scroll)="onScrollFunction($event, userTo)" [style.height]="messagesHeight" style="margin: auto;">
            <div *ngFor="let m of chatMessages" style="width: 100%;">
                <div class="row poruka" [class]="displayChatImgAndInfo(m)" style="margin: auto; margin-top: 10px;">
                    <!-- <div class="row" *ngIf = "displayMessage == true" style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                        <div class="col-9 offset-2" style="color: #737373; width: 100%; text-align: left; margin-bottom:0xp !important; padding-bottom:0px !important; margin-left: 11%">
                            
                        </div>
                     </div> -->
                    <!-- <div class="col-1 mobImg" *ngIf="displayChatMessage == true" style="margin-top:0px !important">
                        <img src={{userToImg}}>
                    </div> -->
                    <div class="col-6" [class]="floatChatClass(m)" style="width: 100% !important; margin-top:0px">
                        <div class="userMessages" [class]="msgChatBackground(m)"
                            style="width: 100%; margin-top: 11px !important" *ngIf="m.fileUrl == null">
                            {{m.content}}
                        </div>
                        <!-- <div class="userMessages" [class]="msgChatBackground(m)"
                            style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl != null && m.idFrom==user.id">
                            <mat-icon style="float:left; color: white;">download</mat-icon>
                            <a href="{{m.fileUrl}}" style="color: white;" target="_blank">{{m.content}}</a>
                        </div>
                        <div class="userMessages" [class]="msgChatBackground(m)"
                            style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl != null && m.idFrom!=user.id">
                            <mat-icon style="float:left; color: white;">download</mat-icon>
                            <a href="{{m.fileUrl}}" style="color: white" target="_blank">{{m.content}}</a>
                        </div> -->
                        <div class="row"
                            style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                            <div class="col-9 offset-2 datetime" [class]="floatChatDateClass(m)"
                                style="color: #737373; width: 100%; text-align: left; margin-top:0xp !important; padding-top:0px !important;">
                                {{m.timestamp}}
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <br> -->
            </div>
        </div>
        <div class="row" style="width: 100%; margin: auto;">
            <form #insertChatForm="ngForm" (keydown.enter)="submitChatMessage()">
                <input matInput [ngModel]="user.id" name="userId" hidden>
                <mat-form-field appearance="none">
                    <input matInput ngModel name="content" placeholder="{{labels.lblWriteAMessage}}">
                </mat-form-field>
                <input type="file" (change)="onFileChanged($event)" #fileUpload style="display: none;">
                <!-- <mat-icon (click)="fileUpload.click()"
                    style="color:#0E0D35; padding-left: 20px; padding-right: 20px; width: 7%; cursor: pointer; ">
                    attach_file</mat-icon> -->
                <mat-icon (click)="submitChatMessage()"
                    style="color:#49D14F; padding-left: 20px; padding-right: 20px; width: 7%; cursor: pointer; ">
                    send</mat-icon>
            </form>
        </div>
        <div class="row" style="width: 100%; margin: auto;" *ngIf="selectedFile != null">
            <mat-divider class="divider" style="width: 100%; margin-bottom: 12px;"></mat-divider>
            <div style="color: white; border-radius: 15px; padding: 5px; font-size: 15px;" [style.background-color]="color">
                <mat-label>{{selectedFile.name.substring(0,15)}}<span *ngIf="selectedFile.name.length > 15">...</span></mat-label>
                <mat-icon style="float:right; cursor: pointer; font-size: 15px; padding-top: 4px;" (click)="updateFile()">close</mat-icon>
            </div>
        </div>
    </div>
</div>
</div>
<!-- </body> -->