<app-user-spinner *ngIf="training == null || labels == null ||  test == null"></app-user-spinner>
<div class="container">
  <div class = "row">
    <div class = "col-11 col-sm-4" style = "margin: auto; margin-top: 11%; text-align: left;">
      <h1 style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblTest}}</h1>
      <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">{{labels.lblTestInFront1}} <br>
        {{labels.forPassingTheTest}} <br>
        {{labels.lblMinPoints}} <span [style.color] = "color">{{test.minPercentage}}%.</span><br>
        {{labels.lblGodLuck}}
      </h3>
      <div class="row" style = "text-align: center;">
        <div class="col-12" style = "margin: auto;">
          <button class="login-button" (click)="goToTestPage()">
            <div class = "row">
              <div class = "col-3"></div>
              <div class = "col-5" style = "margin: auto; text-align: center;">
                <h3 class = "loginBtnLabel" style = "font-family: Lato, 'Alegreya Sans';">{{labels.lblStartQuiz}}</h3>
              </div>
             <div class = "col-3"><img style = "float: left;" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/loginIcon.png?alt=media&token=331da5b1-d77f-4c81-89a1-0e35b3feea30' alt = "login icon"></div>
            </div>
          </button>
        </div>
        <!-- <div class="col-12 col-sm-6">
          <button mat-button class="btn" [class] = "button2" (click)="downloadStatement(training.id)">{{labels.lblStatement}}</button>
        </div> -->
      </div>
    </div>
  </div>
</div>