import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard.service';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-edit-test-answer',
  templateUrl: './edit-test-answer.component.html',
  styleUrls: ['./edit-test-answer.component.scss']
})
export class EditTestAnswerComponent implements OnInit {
  @ViewChild('editForm') editForm:NgForm
  labels;
  labelsSub: Subscription;
  err: boolean = false
  constructor(public dialogRef: MatDialogRef<EditTestAnswerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.err = false;
    this.getLang()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  onSubmit(){
    if(this.editForm.value.text == ""){
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
    this.dashboardService.updateTextOfAnswer({
      id:this.data.id,
      testId:this.data.testId,
      testQuestionId:this.data.questionId,
      text:this.editForm.value.text
    }).subscribe((response)=>{
      console.log(response)
      this.snackBar.open(this.labels.lblControlQuestionAnsEdited, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
   }
  }

  onNoClick(){
    this.dialogRef.close()
  }
}
