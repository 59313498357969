<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<div class = "row" style="text-align: center;">
  <h2 style="font-family: 'Prompt', sans-serif !important; margin-left: 15px;">{{labels.addAnswer}}</h2>
  <form (ngSubmit)="onSubmitAns()" #insertForm="ngForm" class="col-12 ansForm">
    <div fxLayout="column">
      <div class="row" style="width: 100%; margin: auto">
        <div class="col-8 col-sm-10" style="margin: auto">
          <mat-form-field appearance="none" style="width: 100%">
            <mat-label>{{labels.lblAnswerText}}</mat-label>
            <textarea matInput ngModel name="text" (keydown.enter)="enterClick()" required></textarea>
            <mat-error *ngIf="textAnsErr == true" style="margin-bottom:1.5vh !important;">
              {{labels.lblfieldRequired}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-4 col-sm-2" style="margin: auto">
          <button cdkFocusInitial type="submit" class="tableBtn"
            style="padding: 7px; padding-left: 15px; padding-right: 15px;">{{labels.lblAdd}}</button>
        </div>
      </div>
    </div>
  </form>
</div>


