import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard.service';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-delete-test-question',
  templateUrl: './delete-test-question.component.html',
  styleUrls: ['./delete-test-question.component.scss']
})
export class DeleteTestQuestionComponent implements OnInit {
  labels;
  labelsSub: Subscription
  constructor(public dialogRef: MatDialogRef<DeleteTestQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getLang()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  onNoClick(){
    this.dialogRef.close()
  }

  deleteTest(){
    this.dashboardService.deleteQuestionForTest(this.data.testId, this.data.id).subscribe((response)=>{
      this.dialogRef.close()
      this.snackBar.open(this.labels.lblQuestionDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    })
  }
}
