import { Component, OnInit,ViewChild ,Inject} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator'
import {MatTableDataSource} from '@angular/material/table'
import { DashboardService } from '../dashboard.service'
import {Observable, Subscription } from 'rxjs'
import { User } from '../../models/user.model'
import {DataSource} from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import {DeleteDialogComponent} from '../admin-user/delete-dialog/delete-dialog.component'
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './insert-dialog/insert-dialog.component';
import { UserTrainingsDialogComponent } from '../admin-user/user-trainings-dialog/user-trainings-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThrowStmt } from '@angular/compiler';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { MatTableModule } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router';
import { Department } from 'src/app/models/department.model';
import { Administrator } from 'src/app/models/administrator.model';
import { EditDialogComponent as EditSectorDialogComponent} from '../admin-department/edit-dialog/edit-dialog.component';
import { AddListMessageComponent } from '../admin-user/add-list-message/add-list-message.component';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-admin-user-sector',
  templateUrl: './admin-user-sector.component.html',
  styleUrls: ['./admin-user-sector.component.scss']
})
export class AdminUserSectorComponent implements OnInit {

  bigChart=[]
  displayedColumns: string[] = ['userName', 'firstName', 'lastName','departmentName', 'actions'];
  dataSource;
  labels;
  labelsSub: Subscription;
  id:string
  department:Department
  departments:Department[]
  administrators:Administrator[]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
 
  constructor(private router:ActivatedRoute,private dashboardService:DashboardService,public dialog: MatDialog,public snackBar:MatSnackBar, public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.router.params.subscribe((params)=>{
      this.id=params['id']
      this.getUsers();
      this.getDepartment()
    })
    
    this.getLang();
  }
  ngAfterViewInit():void{
    
  }

  getUsers(){
    this.dashboardService.getUsersInDep(this.id).subscribe((users)=>{
      let usrs = users as User[]
      usrs = usrs.sort((a, b) => a.userName.localeCompare(b.userName))
      this.dataSource = users
      this.getDepartments();
      console.log("KORISNICI")
      console.log(this.dataSource)
    })
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  openDeleteDialog(user): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent,{
      width:'500px',
      data:{name:user.firstName,id:user.userId}
    });
   console.log(user.userId)
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getUsers()
    });
    
  }
  openEditDialog(user){
    console.log(user)

    const dialogRef = this.dialog.open(EditDialogComponent,{
      width:'500px',
      data:user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getUsers()
    });
  }
  openInsertDialog(){
    const dialogRef = this.dialog.open(InsertDialogComponent,{
      width:'500px',
      height:'600px',
      data: this.department
      
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getUsers()
      console.log('The dialog was closed');
    });
  }

  openUserTrainingsDialog(user){
    const dialogRef=this.dialog.open(UserTrainingsDialogComponent,{
      width:'550px',
      data:user
    })
    dialogRef.afterClosed().subscribe((response)=>{
      this.dataSource = null      
      this.getUsers()
    
  })
  }

  getDepartment(){
    this.dashboardService.getDepartmentById(this.id).subscribe((dep)=>{
      this.department=dep as Department
    })
  }

  getDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
      this.dataSource.forEach(user => {
        this.departments.forEach(dep => {
          if (dep.id == user.departmentId) {
            user.departmentName = dep.name
          }
        })
      });
      this.dataSource = new MatTableDataSource(this.dataSource)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort
      this.dataSource.filterPredicate = (data : User, filter: string) => data.userName.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
    })

  }

  openEditSectorDialog(department){
    
    const dialogRef=this.dialog.open(EditSectorDialogComponent,{
      width:'500px',
      data:department
    })

    dialogRef.afterClosed().subscribe((response)=>{
        this.dataSource = null      
        this.getDepartment()
        this.getUsers()
    })
  }

  openAddListDialog(){
    const dialogRef = this.dialog.open(AddListMessageComponent,{
      width:'400px',
      data: {flag:true, departmentId: this.department.id}
      
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getUsers()
      console.log('The dialog was closed');
    });
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }


}



