<mat-dialog-container>
    <mat-dialog-content>
        <app-user-spinner
            *ngIf="training == null || user == null || labels == null || training.name == null || showSpinner">
            <mat-spinner class="spinner"></mat-spinner>
        </app-user-spinner>
        <h2 style="font-family: Lato, 'Alegreya Sans'; color: white; margin: 7%;">{{training.isTestOnly ?
            labels.lblWelcomeTest : labels.LblWelcome}}
            "{{training.name}}"</h2>
        <div style="margin: auto; text-align: center">
            <button class="button" mat-button (click)="goToTraining(training)" type="button"
                style="margin-top:15px; padding:0px; padding-left:15px !important; padding-right:15px;">{{labels.lblContinue}}</button>
        </div>
    </mat-dialog-content>
</mat-dialog-container>