import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {
  user: User
  userSub: Subscription;
  labels;
  labelsSub: Subscription;
  @ViewChild('editForm') editForm: NgForm;
  constructor(private authService: AuthService,
    private userLanguageService: UserLanguageService,
    private dashboardService: UserDashboardService,
    public snackBar: MatSnackBar, public dialog: MatDialog, private storage: AngularFireStorage, public dialogRef: MatDialogRef<UserChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onSubmit() {
    if(this.editForm.value.currentPassword == this.localStorageService.getItem('pass')) {
      if (this.editForm.value.newPassword != this.editForm.value.confirmPassword) {
        this.snackBar.open(this.labels.lblPasswordFieldError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      } else if ((this.editForm.value.newPassword != "" && this.editForm.value.confirmPassword != "") && (this.editForm.value.newPassword === this.editForm.value.confirmPassword)) {
        this.dashboardService.editUserInfo({
          flg: "withpass",
          id: this.editForm.value.id,
          firstName: this.editForm.value.firstName,
          lastName: this.editForm.value.lastName,
          userName: this.editForm.value.userName,
          email: this.editForm.value.email,
          password: this.editForm.value.newPassword
        }).subscribe((response) => {
          this.snackBar.open(this.labels.lblDataChanged, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
          this.dialogRef.close()
        })
      } 
    } else {
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }
  }

}
