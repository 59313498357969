import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { DashboardService } from '../dashboard.service'
import { Observable, Subscription } from 'rxjs'
import { User } from '../../models/user.model'
import { DataSource } from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component'
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './insert-dialog/insert-dialog.component';
import { UserTrainingsDialogComponent } from './user-trainings-dialog/user-trainings-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThrowStmt } from '@angular/compiler';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { Department } from 'src/app/models/department.model';
import { AddListMessageComponent } from './add-list-message/add-list-message.component';
import { AddPendingUserComponent } from './add-pending-user/add-pending-user.component';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit {
  displayedColumns: string[] = ['userName', 'firstName', 'lastName', 'departmentName', 'actions'];
  dataSource
  labels;
  labelsSub: Subscription;
  departments: Department[]

  admin:Administrator
  adminSub:Subscription

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  constructor(private adminAuthService: AdminAuthService, private dashboardService: DashboardService, public dialog: MatDialog, public snackBar: MatSnackBar, public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getAdmin()
    this.getLang();

  }
  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.getUsers();
        }
        else{
          this.getUsers1();
        }
      }, 100);
    });
  }

  getUsers() {
    this.dashboardService.getUsers().subscribe((users) => {
      console.log(users)
      let usrs = users as User[]
      usrs = usrs.sort((a, b) => a.userName.localeCompare(b.userName))
      this.dataSource = usrs
      this.getDepartments();
    })
  }
  getUsers1() {
    this.dashboardService.getUsersInDep(this.admin.departmentId).subscribe((users) => {
      let usrs = users as User[]
      usrs = usrs.sort((a, b) => a.userName.localeCompare(b.userName))
      this.dataSource = usrs
      this.getDepartments();
    })
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  openDeleteDialog(user): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: { name: user.firstName, id: user.userId }
    });
    console.log(user.userId)
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      setTimeout(() => {
        if(this.admin.headAdmin){
          this.getUsers();
        }
        else{
          this.getUsers1();
        }
      }, 1000);

    });

  }
  openEditDialog(user) {

    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '500px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      if(this.admin.headAdmin){
        this.getUsers();
      }
      else{
        this.getUsers1();
      }
    });
  }

  insertPendingUserDialog(user) {

    const dialogRef = this.dialog.open(AddPendingUserComponent, {
      width: '500px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      if(this.admin.headAdmin){
        this.getUsers();
      }
      else{
        this.getUsers1();
      }
    });
  }

  openInsertDialog() {
    const dialogRef = this.dialog.open(InsertDialogComponent, {
      width: '500px',
      height: '600px'

    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      if(this.admin.headAdmin){
        this.getUsers();
      }
      else{
        this.getUsers1();
      }
    });
  }

  openUserTrainingsDialog(user) {
    const dialogRef = this.dialog.open(UserTrainingsDialogComponent, {
      width: '550px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      if(this.admin.headAdmin){
        this.getUsers();
      }
      else{
        this.getUsers1();
      }
    });
  }

  getDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
      this.dataSource.forEach(user => {
        this.departments.forEach(dep => {
          if (dep.id == user.departmentId) {
            user.departmentName = dep.name
          }
        })
      });
      this.dataSource = new MatTableDataSource(this.dataSource)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort
      this.dataSource.filterPredicate = (data : User, filter: string) => data.userName.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());

    })

  }

  openAddListDialog() {
    const dialogRef = this.dialog.open(AddListMessageComponent, {
      width: '400px',
      data: { flag: false, departmentId: "" }

    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      if(this.admin.headAdmin){
        this.getUsers();
      }
      else{
        this.getUsers1();
      }
    });
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value;
  }


}



export class UserDataSource extends DataSource<any>{
  constructor(private dasboardService: DashboardService) {
    super()
  }

  connect(): Observable<User[]> {
    return this.dasboardService.fetchUsers()
  }

  disconnect() { }


}
