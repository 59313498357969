<div>
  <div class="row"
    style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
    <div class="col-11"></div>
    <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
      <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
    </div>
  </div>
  <h1 mat-dialog-title>{{labels.lblAddTrainer}}</h1>
  <mat-horizontal-stepper #stepper style="height: 470px;">
    <mat-step [stepControl]="firstFormGroup">
      <ng-template matStepLabel>{{labels.lblNewUser}}</ng-template>
      <div>
        <form (ngSubmit)="onSubmit()" #insertForm="ngForm" style="height:480px">
          <div fxLayout="column">
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>{{labels.lblUserName}}</mat-label>
              <input matInput ngModel name="userName" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>{{labels.lblFirstName}}</mat-label>
              <input matInput ngModel name="firstName" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="none" fxFlex="50%">
              <mat-label>{{labels.lblLastName}}</mat-label>
              <input matInput ngModel name="lastName" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
           
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>{{labels.lblPassword}} {{labels.lblPassMinCharacters}}</mat-label>
              <input type="password" matInput ngModel name="password" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>{{labels.lblConfirmPassword}}</mat-label>
              <input type="password" matInput ngModel name="confirmPassword" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
           
            <div style="text-align: right">
              <button cdkFocusInitial (click) = "goToNextStep(stepper)" type="button" class="tableBtn" style="float: right; padding: 7px; padding-left: 10px; padding-right: 10px;">Nastavi</button>
          </div>
          </div>
          <mat-step [stepControl]="secondFormGroup">
            <ng-template matStepLabel>{{labels.lblOtherInfo}}</ng-template>
            <button class="backBtn" (click)="goBack(stepper)">{{labels.lblBack}}</button>
            <div fxLayout="column">
              <mat-form-field appearance="none" fxFlex="60%">
                <mat-label>E-mail</mat-label>
                <input matInput ngModel name="email" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="none" *ngIf = "admin.headAdmin">
                <mat-label>Kompanija</mat-label>
                <mat-select ngModel name="companyId" required>
                  <mat-option [value]="1">
                    Elektromreža Srbije
                  </mat-option>
                  <mat-option [value]="2">
                    NIS
                  </mat-option>
                  <mat-option [value]="3">
                    Evropska konsalting grupa
                  </mat-option>
                  <mat-option [value]="4">
                    RGZ
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="none" *ngIf = "!admin.headAdmin">
                <mat-label>Kompanija</mat-label>
                <div *ngIf = "admin.companyId == 1"> Elektromreža Srbije</div>
                <div *ngIf = "admin.companyId == 2"> NIS</div>
                <div *ngIf = "admin.companyId == 3">  Evropska konsalting grupa</div>
                <div *ngIf = "admin.companyId == 4">  RGZ</div>
                <input matInput name = "companyId" [ngModel] = "admin.companyId" hidden>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="none" fxFlex="60%">
                <mat-label>{{labels.lblSelectADate}}</mat-label>
                <input matInput [matDatepicker]="picker" ngModel name="dateOfBirth">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <!-- <mat-form-filed appearance="none" fxFlex="60%">
                <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
                <div style="margin-top: 10px; margin-bottom: 25px;">
                  <mat-label style="color: #3e4b71;">{{labels.lblChooseProfilePicture}} </mat-label>
                  <mat-icon class="camera" (click)="imageUpload.click()">camera_alt</mat-icon>
                  <mat-label *ngIf="selectedImage">
                    {{selectedImage.name}}
                  </mat-label>
                </div> -->
                <!-- <button mat-raised-button type="button" style="margin-bottom: 15px;" (click)="imageUpload.click()">{{labels.lblUploadImage}}</button> -->
                
                <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-filed> -->
              <mat-form-filed class="matFormField" appearance="none" fxFlex="60%">
                <mat-label style="color: #3e4b71;">{{labels.lblBio}}</mat-label>
                <textarea matInput ngModel name="about" maxlength="250" rows = "5" cols = "5" style=" margin-bottom: 15px; height: 70px;" class="txtArea" required></textarea>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-filed>
              <div mat-dialog-actions>
                <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
                <button mat-raised-button class="tableBtn" (click) = "onSubmit()" type="submit">{{labels.lblAdd}}</button>
              </div>
            </div>
          </mat-step>
        </form>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>