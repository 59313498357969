import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  
  backgroundClass: String = "backgroundLearning"
  header
  first: boolean = true
  second: boolean = false
  third: boolean = false
  showLoginPage: boolean = false
  hideSplashScreen: boolean =  false
  index: number = 1
  constructor(private router: Router, private languageService: UserLanguageService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.header = this.labels.lblLearning
  }

  toNextSlide(index){
    //this.localStorageService.setItem("splashScreenLoaded", true)
    this.index += 1;
    if(this.index == 2){
      this.backgroundClass = "backgroundSinergy"
      this.first = false;
      this.second = true;
      this.third = false;
      this.header = this.labels.lblSinergy
    }else if(this.index == 3){
      this.backgroundClass = "backgroundTechnology"
      this.first = false;
      this.second = false;
      this.third = true;
      this.header = this.labels.lblTechnology
    }else{
      this.first = false;
      this.second = false;
      this.third = false;
      this.hideSplashScreen = true
      this.goToHome()
    }
  }

  goToHome(){
    this.router.navigateByUrl('/user/dashboard')
  }

}
