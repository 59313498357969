<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<h1 mat-dialog-title *ngIf="dataSource1.length == 0">{{labels.lblAddControlQuestion}}</h1>
<h1 mat-dialog-title *ngIf="dataSource1.length != 0">{{labels.lblEditControlQuestion}}</h1>
<div class="control">
  <!-- <button mat-raised-button (click)="isEditable = !isEditable">
    {{!isEditable ? 'Enable edit mode' : 'Disable edit mode'}}
  </button> -->
  <mat-horizontal-stepper linear #stepper>
    <mat-step [stepControl]="firstFormGroup" [editable]="true">
      <ng-template matStepLabel>{{labels.lblQuestion}}</ng-template>
      <form ngForm (ngSubmit)="onSubmit()" #insertForm="ngForm" class="qForm row" *ngIf="dataSource1.length === 0">

        <div class="row" style="margin: auto; text-align: center; width: 100%;" *ngIf="dataSource1.length === 0">
          <div class="col-12 col-sm-5" style="margin: auto">
            <mat-form-field class="txtarea" appearance="none" fxFlex="100%" style="width: 100%" [class]="questionDisplay">
              <mat-label>{{labels.lblEnterQuestion}}</mat-label>
              <input matInput ngModel name="questionText" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-5" style="margin: auto;">
            <mat-form-field appearance="none" fxFlex="100%" style="width: 100%" [class]="questionDisplay">
              <mat-label>{{labels.lblType}}</mat-label>
              <mat-select ngModel name='type' required>
                <mat-option value="1" (click)="setTypeOfAnswer(1)">{{labels.lblOneCorrectAns}}</mat-option>
                <mat-option value="2" (click)="setTypeOfAnswer(2)">{{labels.lblMoreThanOne}}</mat-option>
              </mat-select>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm-12" [class]="questionDisplay" style="text-align: right">
          <!-- <input type="number" name="numOfAns" (input)="setNumOfAns(4)" placeholder="upisite broj odgovora"/> -->
          <button type="submit" mat-button (click)="goToNextStep(stepper)" class="tableBtn">{{labels.lblNext}}</button>
        </div>
      </form>

      <div class="mat-elevation-z8 controlTable" *ngIf="dataSource1.length != 0">
        <table mat-table matSort [dataSource]="dataSource1" #table1>

          <!-- name Column -->
          <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblText}} </th>
            <td mat-cell *matCellDef="let question">
              <!-- <button mat-fab color="basic">
                <mat-icon>
                    open_with
                </mat-icon>
              </button> -->
              {{question.text}}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
            <td mat-cell *matCellDef="let question">
              <!-- <button class="editBtn d-none d-sm-inline-flex" (click)='editQuestionDialog(question)'>
              {{labels.lblEdit}}
            </button>
            <button class="deleteBtn d-none d-sm-inline-flex" (click)="deleteQuestionDialog(question)">
              {{labels.lblDelete}}
            </button> -->
              <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
              <mat-menu #settingsMenu="matMenu" class="mMenu">
                <button mat-menu-item class="menuButton settingsMenuItem" (click)="editQuestionDialog(question)">
                  <mat-icon style="color: #8293c6;">edit</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                </button>
                <button mat-menu-item class="menuButton settingsMenuItem" (click)="deleteQuestionDialog(question)">
                  <mat-icon style="color: #8293c6;">delete</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                </button>
              </mat-menu>

              <!-- mobile -->
              <!-- <button class="editBtn d-block d-sm-none mobBtn" (click)='editQuestionDialog(question)'>
                {{labels.lblEdit}}
              </button>
              <button class="deleteBtn d-block d-sm-none mobBtn" (click)="deleteQuestionDialog(question)">
                {{labels.lblDelete}}
              </button> -->

            </td>
          </ng-container>

          <!--Actions column-->
          <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns1;" cdkDrag [cdkDragDisabled]="value"
            [cdkDragData]="row"></tr>
        </table>
      </div>

    </mat-step>
    <mat-step [stepControl]="secondFormGroup" [editable]="true">
      <form ngForm (ngSubmit)="submitAns()" #insertForm1="ngForm" class="ansForm">
        <ng-template matStepLabel>{{labels.lblAnswers}}</ng-template>

        <!-- <mat-label *ngIf='typeOfQuestion==1'>ONE CORRECT ANSWER</mat-label> 
          <mat-label *ngIf='typeOfQuestion==2'>MORE THAN ONE CORRECT ANSWER</mat-label>  -->
          <div class="row" style="margin: auto; width:100%">
            <div class="col-12 col-sm-8">
              <mat-form-field class="txtarea" appereance="none" style="width: 100%">
                <mat-label>{{labels.lblEnterQuestion}}</mat-label>
                <input matInput [ngModel]="insertedAns" name="textAns1" required>
                <mat-error *ngIf="err1 == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-4" style="margin: auto; text-align: right">
              <button type="submit" mat-button class="tableBtn">{{labels.lblAdd}}</button>
            </div>
          </div>
        
      </form>
      <div class="mat-elevation-z8 controlTable">
        <table mat-table matSort [dataSource]="dataSource" #table>

          <!-- name Column -->
          <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblText}} </th>
            <td mat-cell *matCellDef="let answer">
              <!-- <button mat-fab color="basic">
                <mat-icon>
                    open_with
                </mat-icon>
              </button> -->
              {{answer.textAns1}}
            </td>
          </ng-container>

          <!-- ORDER Column -->
          <!-- <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ORDER</th>
            <td mat-cell *matCellDef="let answer"> {{answer.order}} </td>
        </ng-container> -->
          <!-- email Column -->
          <ng-container matColumnDef="correct">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let answer">
              <mat-slide-toggle [checked]="answer.correct" (click)="changeStatus(answer)">
                <span class="d-none d-sm-flex" *ngIf="answer.correct==true">{{labels.lblCorrect}}</span>
                <span class="d-none d-sm-flex" *ngIf="answer.correct==false">{{labels.lblNotCorrect}}</span>
              </mat-slide-toggle>

            </td>
          </ng-container>
          <!-- parentId Column -->

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
            <td mat-cell *matCellDef="let answer">
              <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
              <mat-menu #settingsMenu="matMenu" class="mMenu">
                <button mat-menu-item class="menuButton settingsMenuItem" (click)='editDialog(answer)'>
                  <mat-icon style="color: #8293c6;">edit</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                </button>
                <button mat-menu-item class="menuButton settingsMenuItem" (click)="deleteDialog(answer)">
                  <mat-icon style="color: #8293c6;">delete</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                </button>
              </mat-menu>

              <!-- mobile -->
              <!-- <button class="editBtn  d-block d-sm-none mobBtn" (click)='editDialog(answer)'>
                {{labels.lblEdit}}
              </button>
              <button class="deleteBtn  d-block d-sm-none mobBtn" (click)="deleteDialog(answer)">
                {{labels.lblDelete}}
              </button> -->

            </td>
          </ng-container>

          <!--Actions column-->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragDisabled]="value"
            [cdkDragData]="row"></tr>
        </table>
      </div>
    </mat-step>
    <!-- <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <p>You are now done.</p>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button (click)="stepper.reset()">Reset</button>
      </div>
    </mat-step> -->
  </mat-horizontal-stepper>
</div>