import { Position } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Department } from 'src/app/models/department.model';
import { Trainer } from 'src/app/models/trainer.model';
import { Training } from 'src/app/models/training.model';
import { EditDialogComponent } from '../admin-trainer/edit-dialog/edit-dialog.component';
import { DashboardService } from '../dashboard.service';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-admin-trainer-profile',
  templateUrl: './admin-trainer-profile.component.html',
  styleUrls: ['./admin-trainer-profile.component.scss']
})
export class AdminTrainerProfileComponent implements OnInit {

  displayedColumns: string[] = ['name', 'description', 'dateCreated'];
  departments: Department[]
  positions: Position[]

  labels;
  labelsSub: Subscription;
  err: boolean = false
  id: string
  data: Trainer
  dataSource
  admin
  adminSub: Subscription
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  constructor(private adminAuthService: AdminAuthService, private router: ActivatedRoute, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService, public dialog: MatDialog,) { }
  @ViewChild('editForm') editForm: NgForm

  ngOnInit(): void {
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log("Iz sajdbara")
      console.log(this.admin)
    });
    this.router.params.subscribe((params) => {
      this.router.params.subscribe((params) => {
        this.id = params['id']
        this.fetchTrainer()
      this.getTrainings()
      })
    })
    this.err = false;
    this.getLang()
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  getTrainings() {
    this.dashboardService.getTrainersTrainings(this.id).subscribe(trainings => {
      let trngs = trainings as Training[]
      console.log(trngs)
      this.dataSource = new MatTableDataSource(trngs)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
      this.dataSource.filterPredicate = (data : Training, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());

    })
  }
  onSubmit() {
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "" || this.editForm.value.departmentId == "" || this.editForm.value.email == "") {
      // this.errorClass = "errorClass"
      this.err = true
    } else {
      this.editUser(this.editForm.value)
      console.log(this.editForm.value)
    }
  }
  onNoClick(): void {
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  fetchTrainer() {
    return this.dashboardService.getTrainer(this.id).subscribe((response) => {
      this.data = response as Trainer
      console.log(this.data)
    })
  }

  editUser(data) {
    this.dashboardService.editUser(data).subscribe((response) => {
      console.log(response)
    })
  }

  openEditDialog(user) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '500px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.data = null
      this.dataSource = null
      this.fetchTrainer()
      this.getTrainings()
    });
  }

}
