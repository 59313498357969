<app-admin-spinner *ngIf="dataSource == null || labels == null "></app-admin-spinner>
<div fxLayout="row table1" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf="outlet.isActivated===false">
  <div fxFlex="5"></div>
  <div fxFlex="90">
    <!-- <mat-card cdkDrag>
            <div fxLayout="row" fxFlex="100">
                <mat-card-title fxFlex="90">{{labels.lblTrainings}}</mat-card-title>
                  <mat-card-content fxFlex="10" fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                    <button class="tableBtn" mat-raised-button (click)="openInsertDialog()">{{labels.lblAddTraining}}</button>
              
                  </mat-card-content>
              </div>
        </mat-card> -->
    <div class="mat-elevation-z8">
      <div class="row header">
        <div class="col-md-6 text-left">
          <h5 class="tableName">{{labels.lblTrainings}}</h5>
        </div>
        <div class="col-md-6 text-right"><button class="tableBtn" mat-raised-button
            (click)="openInsertDialog()">{{labels.lblAddTraining}}</button>
            <button class="tableBtn" mat-raised-button
            (click)="openCopyDialog()" style="margin-left:20px;">{{labels.lblCopyTraining}}</button>
          </div>
        <div class="col-10 text-left search1">
          <mat-form-field class="search" appearance="none" fxFlex="40%">
            <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
          </mat-form-field>
      </div>
      </div>
      <table matSortDisableClear="true" mat-table [dataSource]="dataSource" matSort>

        <!-- userName Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblETrainingName}}</th>
          <td mat-cell data-toggle="tooltip" class="tdTrainingName" data-placement="top" title={{training.name}} *matCellDef="let training" [routerLink]="['/admin/training-details',training.id]" style="cursor: pointer;"> {{training.name.substring(0,25)}}<span *ngIf="training.name.length > 25">...</span> </td>
        </ng-container>

        <!-- firstName Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblDescription}}</th>
          <td mat-cell data-toggle="tooltip" data-placement="top" title={{training.description}} *matCellDef="let training" class="hideColumn"> {{training.description.substring(0,30)}}<span *ngIf="training.description.length > 30">...</span></td>
        </ng-container>

        <!-- dateCreated Column -->
        <ng-container matColumnDef="dateCreated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblDateCreated}}</th>
          <td mat-cell data-toggle="tooltip" data-placement="top" title={{training.dateCreated}} *matCellDef="let training" class="hideColumn"> {{training.date}}</td>
        </ng-container>

        <!--Actions column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
          <td mat-cell *matCellDef="let training">
            <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="openEditDialog(training)">
                        {{labels.lblEdit}}
                      </button>
                      <button class="deleteBtn d-none d-sm-inline-flex" (click)="openDeleteDialog(training)">
                        {{labels.lblDelete}}
                      </button> -->

           <!--  <button class="editBtn d-sm-inline-flex" color="primary"
              [routerLink]="['training-detail',training.id]">
              {{labels.lblSections}}
            </button>
            <button class="addNewBtn d-sm-inline-flex" color="basic" (click)="assignTest(training)">
              Dodeli
            </button> -->
            <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
            <mat-menu #settingsMenu="matMenu" class="mMenu">
              <button mat-menu-item class="menuButton settingsMenuItem"  [routerLink]="['/admin/training-details',training.id]">
                <mat-icon style="color: #8293c6;">subject</mat-icon>
                <span class="aligned-with-icon">{{labels.lblMoreDetails}}</span>
              </button>
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openEditDialog(training)">
                <mat-icon style="color: #8293c6;">edit</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
              </button>
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(training)" [disabled]= "training.assignedUsers.length > 0">
                <mat-icon style="color: #8293c6;">delete</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
              </button>
            </mat-menu>

            <!-- mobile -->
            <!-- <button class="editBtn d-block d-sm-none mobBtn" (click)="openEditDialog(training)">
              {{labels.lblEdit}}
            </button>
            <button class="deleteBtn d-block d-sm-none mobBtn" (click)="openDeleteDialog(training)">
              {{labels.lblDelete}}
            </button>
            <button class="editBtn d-block d-sm-none mobBtn" color="primary"
              [routerLink]="['training-detail',training.id]">
              {{labels.lblSections}}
            </button>
            <button class="addNewBtn d-block d-sm-none mobBtn" color="basic" (click)="assignTest(training)">
              Dodeli
            </button> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div fxFlex="5"></div>
</div>

<router-outlet #outlet="outlet"></router-outlet>