import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-edit-control-question-dialog',
  templateUrl: './edit-control-question-dialog.component.html',
  styleUrls: ['./edit-control-question-dialog.component.scss']
})
export class EditControlQuestionDialogComponent implements OnInit {
  @ViewChild('editForm') editForm:NgForm
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  constructor(public dialogRef: MatDialogRef<EditControlQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.err = false;
    this.getLang()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  onNoClick(){
    this.dialogRef.close()
  }

  onSubmit(){
    if(this.editForm.value.questionText == "" || this.editForm.value.questionText == null){
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
    this.dashboardService.updateTextOfControlQuestion({
      id:this.data.id,
      trainingId: this.data.trainingId,
      sectionId: this.data.sectionId,
      unitId: this.data.unitId,
      text:this.editForm.value.questionText
      
    }).subscribe((response)=>{
      console.log(response)
      this.snackBar.open(this.labels.lblControlQuestionEdited, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
    }
  }
}
