import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
export interface DialogData{
  id:string,
  name:string
}
@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  labels;
  labelsSub: Subscription;
  constructor( public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }


  onNoClick(): void {
   this.dialogRef.close();
  }
  deleteTrainer(){
    console.log(this.data.id)
      this.dashboardService.deleteTrainer(this.data.id)
      this.dashboardService.getTrainers().subscribe((data)=>{
        console.log(data)
      })
      this.dialogRef.close();
      this.snackBar.open(this.labels.lblTrainerDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000  })
  }
  ngOnInit(): void {
    this.getLang();
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
    this.labels = labels;
      })
  }

}
