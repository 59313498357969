import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/modules/services/auth-service/auth.service';
import { UserLanguageService } from 'src/app/modules/services/user-language-service/user-language.service';
import { UserDashboardService } from 'src/app/modules/user-dashboard.service';
import { UserVideoCallComponent } from 'src/app/modules/user-messages/user-video-call/user-video-call.component';
import { UserNotificationComponent } from 'src/app/modules/user-notification/user-notification.component';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {
  lang
  langSub: Subscription;

  labels;
  labelsSub: Subscription;

  user
  userSub: Subscription;

  notifications
  numOfNotifications = 0
  numOfNotifSub: Subscription

  homePage
  profilePage
  messagesPage
  faqPage
  calendar
  unsubscribe
  unsub
  msgsNotifications
  backgroundColor
  showPhoneIcon

  meeting = []

  userId
  constructor(private db: AngularFirestore, private authService: AuthService, private router: Router, private languageService: UserLanguageService, private userDashboarService: UserDashboardService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.homePage = "active"
    this.profilePage = "notActive"
    this.messagesPage = "notActive"
    this.faqPage = "notActive"
    this.calendar = "notActive"

    this.langSub = this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user) => {
      console.log("*****1******")
      this.user = user;
      console.log(this.user)
      console.log("*****2******")
      setTimeout(() => {
        this.userId = this.user?.userId
        this?.getNotifications(this.user)
        this.getMessagesNotifications(this.user)
        this.getUsersChatMeeting(this.user)
        if (this.user.companyId == 1) {
          console.log("IF")
          this.backgroundColor = "#808385"
        } else if (this.user.companyId == 2) {
          this.backgroundColor = "#ffe7ef"
        }
        else {
          this.backgroundColor = "#ffe7ef"
        }
       console.log("meeting from oninit: ", this.meeting)
      }, 500);

    });
  }

  getUsersChatMeeting(user){
    let meetings = []
    let docRef = this.db.firestore.collection("users").where("userId", "==", user.userId)
    .onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function(change){
        console.log(change.doc.data())
        let meeting = change.doc.data()
        console.log("DETECTED CHANGE ", meeting)
       if(meeting.inCall == true){
          meetings.push(meeting)
       }else{
         meetings.pop()
       }
      })
    })
   setTimeout(() => {
    this.meeting = meetings
   }, 2000);
  }
  
  goToMeeting(){
    this.router.navigateByUrl("/user/chat-meeting")
  }
  
  endCall(){
    this.db.collection("users").doc(this.userId).update({meetingCall: false, callFrom: "", onMeeting: false})
  }
  
  //  getZoomMeetings(){
  //     let events = []
  //     let docRef = this.db.firestore.collection("zoomMeetings")
  //     docRef.onSnapshot(function (snapshot) {
  //       snapshot.docChanges().forEach(function (change) {
  //         let event = change.doc.data()
  //         event.start = new Date(change.doc.data().date.seconds * 1000)
  //         events.push(event)
  //       });
  //     });
  //     console.log("events::: ", events)
  //     return events
  //   }
  
    // startZoomMeeting(){
    //   const dialogRef = this.dialog.open(ChatZoomMeetingComponent, {
    //     width: '650px'
    //   });
    //   dialogRef.afterClosed().subscribe((result) => {
    //       console.log("Dialog closed.")
    //     })
    // }
  
  
    selectLang(lang) {
      this.languageService.setLanguage(lang);
    }
  
  
    logout(): void {
      this.unsub();
      this.authService.logout();
      this.router.navigateByUrl('/');
    }
  
    setToSeen() {
      //this.notifications = []
      let docRef = this.db.firestore.collection('users').doc(this.user.id).collection('userNotifications')
      docRef.get().then((query) => {
        query.forEach((doc) => {
          let notification = doc.data()
          if ((notification.seen == false) && (notification.type == 2)) {
            let docRef2 = docRef.doc(doc.id)
            docRef2.update({
              seen: true
            })
          }
        })
      })
    }
    msgsToSeen() {
      let docRef = this.db.firestore.collection('users').doc(this.user.id).collection('userNotifications')
      docRef.get().then((query) => {
        query.forEach((doc) => {
          let notification = doc.data()
          if ((notification.seen == false) && (notification.type == 1)) {
            let docRef2 = docRef.doc(doc.id)
            docRef2.update({
              seen: true
            })
          }
        })
      })
    }
  
    getNotifications(user): any {
      let notifs = []
      this.unsub = this.db.firestore.collection('users').doc(user.id).collection('userNotifications').orderBy("timestamp", "desc")
        .onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            let notification = change.doc.data()
            if ((notification.seen == false) && (notification.type == 2)) {
              notification.id = change.doc.id
              notification.timestamp = change.doc.data().timestamp
              let time = change.doc.data().timestamp
              var date = new Date(time.seconds * 1000);
              var formatDate = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear()
  
              notification.timestamp = formatDate
              notifs.push(notification)
              // let audio = new Audio("https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/pristine-609.mp3?alt=media&token=8ea1871b-86cb-4d20-98dd-d2f72c358221")
              // audio.play()
            }
            else if (change.type == 'modified') {
              notifs.forEach(n => {
                notifs.pop()
              })
            }
          })
        })
      setTimeout(() => {
        this.notifications = notifs
      }, 2000);
    }
  
    getMessagesNotifications(user): any {
      let msgs = []
      this.unsub = this.db.firestore.collection('users').doc(user.id).collection('userNotifications').orderBy("timestamp", "desc")
        .onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            let notification = change.doc.data()
            if ((notification.seen == false) && (notification.type == 1)) {
              notification.id = change.doc.id
              notification.timestamp = change.doc.data().timestamp
              let time = change.doc.data().timestamp
              var date = new Date(time.seconds * 1000);
              var formatDate = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear()
  
              notification.timestamp = formatDate
              msgs.push(notification)
              // let audio = new Audio("https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/pristine-609.mp3?alt=media&token=8ea1871b-86cb-4d20-98dd-d2f72c358221")
              // audio.play()
            }
            else if (change.type == 'modified') {
              msgs.forEach(n => {
                msgs.pop()
              })
            }
          })
        })
      setTimeout(() => {
        this.msgsNotifications = msgs
      }, 2000);
    }

    answerCall() {
      let dialogRef = this.dialog.open(UserVideoCallComponent,{
        width: "500px",
        disableClose: true,
        data: {
          userId: this.user.userId
        }
      });
    }

    changeColor(select: String) {
      switch (select) {
        case "home":
          this.homePage = "active"
          this.profilePage = "notActive"
          this.messagesPage = "notActive"
          this.faqPage = "notActive"
          this.calendar = "notActive"
          break;
        case "profile":
          this.homePage = "notActive"
          this.profilePage = "active"
          this.messagesPage = "notActive"
          this.faqPage = "notActive"
          this.calendar = "notActive"
          break;
        case "messages":
          this.homePage = "notActive"
          this.profilePage = "notActive"
          this.messagesPage = "active"
          this.faqPage = "notActive"
          this.calendar = "notActive"
          this.msgsToSeen()
          break;
        case "faq":
          this.homePage = "notActive"
          this.profilePage = "notActive"
          this.messagesPage = "notActive"
          this.faqPage = "active"
          this.calendar = "notActive"
          break;
        case "notifications":
          this.openNotificationDialog()
          this.homePage = "notActive"
          this.profilePage = "notActive"
          this.messagesPage = "notActive"
          this.faqPage = "notActive"
          this.calendar = "active"
          break;
      }
    }

    openNotificationDialog() {
      const dialogRef = this.dialog.open(UserNotificationComponent, {
        width: '100%',
        height: "95%",
        hasBackdrop: true,
        backdropClass: 'blurBackground'
        });
    }
}
