import { CdkTreeModule } from '@angular/cdk/tree';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Chat } from 'src/app/models/chat.model';
import { Message } from 'src/app/models/message.model';
import { User } from 'src/app/models/user.model';
import { DashboardService } from '../dashboard.service';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { AuthService } from '../services/auth-service/auth.service';
@Component({
  selector: 'app-admin-chat',
  templateUrl: './admin-chat.component.html',
  styleUrls: ['./admin-chat.component.scss']
})
export class AdminChatComponent implements OnInit {
  users
  labels
  labelsSub: Subscription
  clicked: string = ""
  constructor(private adminAuthService: AdminAuthService, private authService: AuthService, public db: AngularFirestore, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService, public snackBar: MatSnackBar, private storage: AngularFireStorage,
    private router: Router) {
    this.messagesHeight = "70%"
  }
  messages: Message[]
  msgsSub: Subscription;
  user
  userSub: Subscription;
  floatClassMsg
  displayMessage
  msgsBackground
  floatDateClassMsg
  forumVisibility
  chatVisibility
  userTo
  userToFullName
  chatDocId: string
  chatMessages1: Chat[]
  displayChatMessage
  displayImage
  displayUsersList
  forumBackgroundColor
  lastMessage
  backgroundColor
  notActive
  userToImg
  showDiv
  numberOfMessages
  showSpinner
  numberOfForumMessages
  showForumSpinner
  color
  firstTime
  messagesHeight: string
  selectedFile: File

  userId
  routerUrl
  code
  zoomResponse
  zoomAccessToken
  zoomUserId
  zoomUserDetails
  createdMeeting
  userFullName
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('insertChatForm') insertChatForm: NgForm
  @ViewChild('fileUpload') fileUpload: ElementRef
  notif
  ngOnInit(): void {
    this.showDiv = "forum"
    this.firstTime = false
    this.userSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.user = admin;
      console.log("user")
      console.log(this.user)
      setTimeout(() => {
        this.userId = this.user.id
        this.userFullName = this.user.firstName + " " + this.user.lastName
        this.routerUrl = this.router.url
        console.log(this.routerUrl)
        let parts = this.routerUrl.split("=",2)
        this.code = parts[1]
        this.oAuthRedirect(this.code)
        this.updateZoomMeetingFields(this.userId)
      }, 500)
    });
    setTimeout(() => {
      this.getUsers()
    }, 1500);
    this.labelsSub = this.adminLanguageService.labels$.subscribe(labels => {
      this.labels = labels
    })

    setTimeout(() => {
      this.getUserMessages(8)
    }, 1000);
    this.forumVisibility = "forumVisible1"
    this.chatVisibility = "notVisible"
    this.forumBackgroundColor = true;
    this.chatDocId = ""
    this.backgroundColor = ""
    this.displayUsersList = "mobVisible"
    this.userTo = ""

    this.showSpinner = "dontShow"
    this.numberOfMessages = 8
    this.showForumSpinner = "dontShow"
    this.numberOfForumMessages = 9
  }

  updateZoomMeetingFields(userId){
    this.db.firestore.collection("users").doc(userId).update({meetingCall: false, meetingHost: false, onMeeting: false, callFrom: ""})
}

oAuthRedirect(code){
  let clientInfo = 'fU1My98QC2ipHpvi5kRXA:iGvM0cVp1BsOg8i9XEzZ9l8SQygSkUpb'
  let encode = btoa(clientInfo)
  let authorization =  `Basic ${encode}`

  let data = {
    authorization: authorization,
    code: code,
    for: 'chat'
  }

  this.dashboardService.zoomAccessToken(data).subscribe((response) => {
    console.log("RESPONSE FROM ZOOM API: ", response)
    this.zoomResponse = response
    this.zoomAccessToken = this.zoomResponse.access_token
    console.log("USER ID: ", this.userId)
    this.db.collection("administrators").doc(this.userId).update({zoomAccessToken: this.zoomResponse.access_token})
    this.dashboardService.getZoomUserDetails(this.zoomResponse.access_token).subscribe((newResponse) => {
      console.log("ZOOM USER DETAILS: ", newResponse)
      this.zoomUserDetails = newResponse
      this.zoomUserId = this.zoomUserDetails.users[0].id
      this.db.collection("administrators").doc(this.userId).update({zoomUserId: this.zoomUserId})
    })
  })

}

startZoomMeeting(user){
  console.log("USER TO CALL")
    console.log(user)
    if(user.onMeeting == true || user.online == false){
      this.snackBar.open(this.labels.lblUserUnavailable, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      this.dashboardService.addChatMeeting("").subscribe((response) => {
        console.log("RESPONSE")
        console.log(response)
        this.createdMeeting = response
        console.log(this.userId)
        console.log(user.userId)
        let ref
        let id
        if(user.isUser == true){
          ref = this.db.firestore.collection("users")
          id = user.userId
        }else if(user.isTrainer == true){
          ref = this.db.firestore.collection("trainers")
          id = user.id
        }else{
          ref = this.db.firestore.collection("administrators")
          id = user.id
        }
        this.db.firestore.collection("administrators").doc(this.userId).update({meetingHost: true, currentMeetingId: this.createdMeeting.meetingId})
        ref.doc(id).update({meetingHost: false, currentMeetingId: this.createdMeeting.meetingId, meetingCall: true, callFrom: this.userFullName })
      })
      this.router.navigateByUrl("/admin/chat-meeting")
    }
}

  getUsers() {
    let users = []
    let usrId = this.user.id
    let docRef
    if (this.user.headAdmin) {
      docRef = this.db.firestore.collection('users').where("pending", "==", false).where("deleted", "==", false)
    } else {
      docRef = this.db.firestore.collection('users').where("pending", "==", false).where("deleted", "==", false).where("companyId", "==", this.user.companyId)
    }
    let ref
    if (this.user.isAdmin) {
      ref = this.db.firestore.collection('administrators').doc(usrId).collection('messages')
    } else {
      ref = this.db.firestore.collection('trainers').doc(usrId).collection('messages')
    }
    docRef.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let user = change.doc.data()
        user.isAdminChat = false
        user.isTrainer = false
        user.isUser = true
        user.id = change.doc.data().userId
        user.profileImagePath = user.profileImagePath
        ref.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.doc.data().docId == user.id) {
              let lastMessage = change.doc.data()
              if (lastMessage.idFrom == usrId) {
                user.from = true
              } else {
                user.from = false
              }
              let time = Number(change.doc.data().timestamp)
              lastMessage.time = time
              time = time / 1000
              time = time * 1000
              var date = new Date(time);
              var formatDate = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear() +
                " " + date.getHours() +
                ":" + date.getMinutes()
              lastMessage.timestamp = formatDate
              user.lastMessage = lastMessage
            }
          })
        })
        users.push(user)
      });
    });

    let docRef2
    let docRefB
    if (this.user.isAdmin) {
      docRef2 = this.db.firestore.collection('administrators')
      if (this.user.headAdmin) {
        docRefB = this.db.firestore.collection('administrators')
      } else {
        docRefB = this.db.firestore.collection('administrators').where("companyId", "==", this.user.companyId)
      }
    } else {
      docRef2 = this.db.firestore.collection('trainers')
      if (this.user.headAdmin) {
        docRefB = this.db.firestore.collection('trainers')
      } else {
        docRefB = this.db.firestore.collection('trainers').where("companyId", "==", this.user.companyId)
      }
    }
    let ref2 = docRef2.doc(usrId).collection('messages')
    docRefB.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let user = change.doc.data()
        user.isAdminChat = true
        user.isTrainer = false
        user.isUser = false
        user.id = change.doc.data().id
        ref2.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.doc.data().docId == user.id) {
              let lastMessage = change.doc.data()
              if (lastMessage.idFrom == usrId) {
                user.from = true
              } else {
                user.from = false
              }
              let time = Number(change.doc.data().timestamp)
              lastMessage.time = time
              time = time / 1000
              time = time * 1000
              var date = new Date(time);
              var formatDate = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear() +
                " " + date.getHours() +
                ":" + date.getMinutes()
              lastMessage.timestamp = formatDate
              user.lastMessage = lastMessage
            }
          })
        })
        users.push(user)
      });
    });

    let docRef3
    let ref3
    if (this.user.isAdmin) {
      if (this.user.headAdmin) {
        docRef3 = this.db.firestore.collection('trainers')
      } else {
        docRef3 = this.db.firestore.collection('trainers').where("companyId", "==", this.user.companyId)
      }
      ref3 = this.db.firestore.collection('administrators').doc(usrId).collection('messages')
    } else {
      if (this.user.headAdmin) {
        docRef3 = this.db.firestore.collection('administrators')
      } else {
        docRef3 = this.db.firestore.collection('administrators').where("companyId", "==", this.user.companyId)
      }
      docRef3 = this.db.firestore.collection('administrators')
      ref3 = this.db.firestore.collection('trainers').doc(usrId).collection('messages')
    }

    docRef3.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let user = change.doc.data()
        user.isAdminChat = false
        user.isTrainer = true
        user.isUser = false
        user.id = change.doc.data().id
        ref3.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.doc.data().docId == user.id) {
              let lastMessage = change.doc.data()
              if (lastMessage.idFrom == usrId) {
                user.from = true
              } else {
                user.from = false
              }
              let time = Number(change.doc.data().timestamp)
              lastMessage.time = time
              time = time / 1000
              time = time * 1000
              var date = new Date(time);
              var formatDate = date.getDate() +
                "/" + (date.getMonth() + 1) +
                "/" + date.getFullYear() +
                " " + date.getHours() +
                ":" + date.getMinutes()
              lastMessage.timestamp = formatDate
              user.lastMessage = lastMessage
            }
          })
        })
        users.push(user)
      });
    });
    setTimeout(() => {
      let allusers = this.sortUsers(users)
      this.users = allusers
      return this.users
     }, 500);
  }

  sortUsers(users){
    let usrs1 = []
      users.forEach(u => {
        if(u.lastMessage){
          usrs1.push(u)
        }
      });
      let usrs2 = users.filter((u) => !u.lastMessage)
      usrs1 = usrs1.sort((a, b) => (a.lastMessage.time > b.lastMessage.time ? -1 : 1))
     
      usrs2.forEach(u => {
        usrs1.push(u)
      });
      users = usrs1
      return users
  }

  userSearch(event) {
    let search = event.target.value;
    console.log(search)
    let usrs = []
    if (search == "") {
      this.getUsers()
    } else {
      this.users = this.users.filter((user) => (user.firstName.includes(search) == true) || (user.lastName.includes(search) == true))
    }
  }

  getUserMessages(numberOfMessages) {
    let messages = []
    messages = []
    this.messages = []
    this.showSpinner = "dontShow"
    this.showForumSpinner = "dontShow"
    let docRef = this.db.firestore.collection('messages').orderBy('dateTime', 'desc').limit(numberOfMessages)
    let usrCompany = this.user.companyId
    let usrHeadAdmin = this.user.headAdmin
    docRef.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        if (change.type == "added") {
          let message = change.doc.data()
          if ((message.companyId == usrCompany) || (usrHeadAdmin)) {
            message.timestamp = change.doc.data().dateTime
            let timestamp = change.doc.data().dateTime.seconds
            var date = new Date(timestamp * 1000);
            var formatDate = date.getDate() +
              "/" + (date.getMonth() + 1) +
              "/" + date.getFullYear() +
              " " + date.getHours() +
              ":" + date.getMinutes()
            message.dateTime = formatDate
            messages.push(message)
          }
        }
      });
    });
    setTimeout(() => {
      console.log(messages)
      messages = messages.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
      this.messages = messages
      console.log("messages")
      console.log(this.messages)
      return this.messages
    }, 700);
  }

  @HostListener('scroll', ['$event'])
  onScrollForumFunction(e) {
    if (!e.target.scrollTop) {
      console.log("Scrolled to top - forum")
      this.showSpinner = null
      this.numberOfForumMessages += 5
      this.showForumSpinner = null
      this.getUserMessages(this.numberOfForumMessages)
      console.log(this.messages)
    }
  }



  async submitMessage() {
    if (this.insertForm.value.textMessage != "" && this.insertForm.value.textMessage != null) {
      let companyId
      if (this.user.headAdmin) {
        companyId = 0
      } else {
        companyId = this.user.companyId
      }
      let msg = {
        type: 0,
        firstName: this.insertForm.value.firstName,
        lastName: this.insertForm.value.lastName,
        sender: this.insertForm.value.sender,
        dateTime: new Date(),
        text: this.insertForm.value.textMessage,
        imagePath: this.user.profileImagePath,
        userId: this.user.id,
        companyId: companyId
      }
      let message = msg
      let docRef = this.db.firestore.collection('messages').doc()
      docRef.set(message)

    }
    if (this.selectedFile != null) {
      let companyId
      if (this.user.headAdmin) {
        companyId = 0
      } else {
        companyId = this.user.companyId
      }
      let msg = {
        type: 3,
        firstName: this.insertForm.value.firstName,
        lastName: this.insertForm.value.lastName,
        sender: this.insertForm.value.sender,
        dateTime: new Date(),
        text: this.selectedFile.name,
        imagePath: this.user.profileImagePath,
        userId: this.user.id,
        fileUrl: null,
        companyId: companyId
      }
      let ref1 = this.storage.ref(this.selectedFile.name)
      await ref1.put(this.selectedFile)
      msg.fileUrl = await ref1.getDownloadURL().toPromise()
      let message = msg
      let docRef = this.db.firestore.collection('messages').doc()
      docRef.set(message)
      this.updateFile()
    }
    this.insertForm.controls.textMessage.reset()
  }

  displayImgAndInfo(m) {
    if (m.sender == this.user.email) {
      this.displayMessage = false
    }
    else {
      this.displayMessage = true
    }
  }

  @HostListener('scroll', ['$event'])
  onScrollFunction(e, userTo) {
    if (!e.target.scrollTop) {
      console.log("Scrolled to top - chat")
      this.showSpinner = null
      this.numberOfMessages += 5
      this.chatWithUser(userTo, this.numberOfMessages, 1)
    }
  }

  floatClass(m) {
    if (m.sender == this.user.email) {
      this.floatClassMsg = "floatRight"
      this.displayMessage = false
    }
    else {
      this.floatClassMsg = "floatLeft"
      this.displayMessage = true
    }
    return this.floatClassMsg
  }

  floatDateClass(m) {
    if (m.sender == this.user.email) {
      this.floatDateClassMsg = "floatDateRight"
    }
    else {
      this.floatDateClassMsg = "floatDateLeft"
    }
    return this.floatDateClassMsg
  }


  msgBackground(m) {
    if (m.sender == this.user.email) {
      this.msgsBackground = "msgBackground"
    }
    else {
      this.msgsBackground = "noMsgBackground"
    }
    return this.msgsBackground
  }

  displayChatImgAndInfo(m) {
    if (m.idFrom == this.user.id) {
      this.displayChatMessage = false
    }
    else {
      this.displayChatMessage = true
    }
  }

  floatChatClass(m) {
    if (m.idFrom == this.user.id) {
      this.floatClassMsg = "floatRight"
      this.displayMessage = false
    }
    else {
      this.floatClassMsg = "floatLeft1"
      this.displayMessage = true
    }
    return this.floatClassMsg
  }

  floatChatDateClass(m) {
    if (m.idFrom == this.user.id) {
      this.floatDateClassMsg = "floatDateRight"
    }
    else {
      this.floatDateClassMsg = "floatDateLeft"
    }
    return this.floatDateClassMsg
  }


  msgChatBackground(m) {
    if (m.idFrom == this.user.id) {
      this.msgsBackground = "msgBackground"
    }
    else {
      this.msgsBackground = "noMsgBackground"
    }
    return this.msgsBackground
  }

  changeForumVisibility() {
    this.showDiv = "forum"
    this.notActive = "notActive"
    this.forumVisibility = "forumVisible"
    this.chatVisibility = "notVisible"
    this.displayUsersList = "mobNotVisible"
    this.forumBackgroundColor = true
  }

  displayImg(m) {
    for (let i = 0; i < this.chatMessages1.length; i++) {
      const element = this.chatMessages1[i];
      const nextElement = this.chatMessages1[i + 1]
      if (m.id == element.id && m.idFrom == nextElement.idFrom) {
        this.displayImage = "notVisible"
      }
      return this.displayImage
    }
    return this.displayImage
  }

  async chatWithUser(u, numberOfMessages, flg) {
    this.firstTime = true
    if (flg == 0) {
      this.numberOfMessages = 7
      this.numberOfForumMessages = 7
    }
    this.showDiv = "chat"
    this.notActive = ""
    this.userTo = u
    this.userToImg = u.profileImagePath
    this.userToFullName = "" + u.firstName + " " + u.lastName
    this.chatVisibility = "chatVisible"
    this.forumVisibility = "notVisible"
    this.displayUsersList = "mobNotVisible"
    this.backgroundColor = "active"
    this.forumBackgroundColor = false
    let chatMsgs1 = []
    let id1 = this.user.id + "-" + u.id
    let id2 = u.id + "-" + this.user.id
    let docId: string

    if (this.user.isAdmin == true) {
      this.db.collection("administrators").doc(this.user.id).collection("messages").doc(this.userTo.id).update({ seen: true })
    } else {
      this.db.collection("trainers").doc(this.user.id).collection("messages").doc(this.userTo.id).update({ seen: true })
    }
    console.log("print 1")

    let r1 = await this.db.firestore.collection('messages').doc(id1).get()
    let r2 = await this.db.firestore.collection('messages').doc(id2).get()
    if ((!r1.exists) && (!r2.exists)) {
      if (this.user.id.hashCode >= this.userTo.id.hashCode) {
        let idDoc = this.user.id + "-" + this.userTo.id
        let doc = await this.db.firestore.collection('messages').doc(idDoc).set({ "id": false })
      } else {
        let idDoc = this.userTo.id + "-" + this.user.id
        let doc = await this.db.firestore.collection('messages').doc(idDoc).set({ "id": false })
      }
    }
    let docRef = this.db.firestore.collection('messages').get()
    docRef.then(snapshot => {
      snapshot.forEach(change => {
        this.chatMessages1 = []
        console.log("print 2")
        if (change.id == id1 || change.id == id2) {
          let documentId: string = change.id
          docId = documentId
          let ref = this.db.firestore.collection('messages').doc(change.id).collection(change.id).orderBy('timestamp', 'desc').limit(numberOfMessages)
          ref.onSnapshot(function (snapshot) {
            snapshot.docChanges().forEach(function (change) {
              if (change.type == "added") {
                console.log("print 4")
                let message = change.doc.data()
                message.id = change.doc.id
                message.timestamp = change.doc.data().timestamp
                let time = Number(change.doc.data().timestamp)
                time = time / 1000
                time = time * 1000
                var date = new Date(time);
                var formatDate = date.getDate() +
                  "/" + (date.getMonth() + 1) +
                  "/" + date.getFullYear() +
                  " " + date.getHours() +
                  ":" + date.getMinutes()
                message.timestamp = formatDate
                chatMsgs1.push(message)
                console.log(chatMsgs1)
              }
            })
          })
        }
      })
    })
    setTimeout(() => {
      console.log("print 5")
      this.chatDocId = docId
      this.showSpinner = "dontShow"
      this.showForumSpinner = "dontShow"
      this.chatMessages1 = chatMsgs1.reverse()
    }, 700);
    // return this.chatMessages
  }

  backToUsersList() {
    this.displayUsersList = "mobVisible"
    this.forumVisibility = "notVisible"
    this.chatVisibility = "notVisible"
  }

  isClicked(id) {
    if (id == this.userTo.id) {
      return true
    } else {
      return false
    }
  }

  async submitChatMessage() {
    let timestamp = Math.floor(Date.now())

    let stringTimestamp = "" + timestamp
    if (this.insertChatForm.value.content != "" && this.insertChatForm.value.content != null) {
      let msg = {
        idTo: this.userTo.id,
        idFrom: this.user.id,
        timestamp: stringTimestamp,
        content: this.insertChatForm.value.content,
        type: 0
      }
      let message = msg
      let id: string
      if (this.chatDocId != "" && this.chatDocId != null) {
        id = String(this.chatDocId)
      } else {
        let idDoc: string
        if (this.user.id.hashCode >= this.userTo.id.hashCode) {
          idDoc = this.user.id + "-" + this.userTo.id
        } else {
          idDoc = this.userTo.id + "-" + this.user.id
        }
        id = String(idDoc)
      }
      let docRef = this.db.firestore.collection('messages').doc(id)
      docRef.set({
        'id': id
      })
      let ref = docRef.collection(id).doc()
      ref.set(message)

      let notifRef = this.db.firestore.collection('users').doc(this.userTo.id).collection('userNotifications').doc()
      notifRef.set({
        "content": this.insertChatForm.value.content,
        "fullname": this.user.firstName + " " + this.user.lastName,
        "receiverId": this.userTo.id,
        "timestamp": new Date(),
        "docId": notifRef.id,
        "seen": false,
        "type": 1
      })

      let lastMessage = {
        "lastMessage": this.insertChatForm.value.content,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.userTo.firstName + " " + this.userTo.lastName,
        "docId": this.userTo.id,
        "idFrom": this.user.id,
        "seen": true
      }
      let lastMessage2 = {
        "lastMessage": this.insertChatForm.value.content,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.user.firstName + " " + this.user.lastName,
        "docId": this.user.id,
        "idFrom": this.user.id,
        "seen": false
      }
      if (this.user.isAdmin == true) {
        let messageRef = this.db.firestore.collection("administrators").doc(this.user.id).collection("messages").doc(this.userTo.id)
        messageRef.get().then(doc => {
          if (doc.exists) {
            this.db.firestore.collection("administrators").doc(this.user.id).collection("messages").doc(this.userTo.id).update(lastMessage)
          } else {
            this.db.firestore.collection("administrators").doc(this.user.id).collection("messages").doc(this.userTo.id).set(lastMessage)
          }
        })
      } else {
        let messageRef = this.db.firestore.collection("trainers").doc(this.user.id).collection("messages").doc(this.userTo.id)
        messageRef.get().then(doc => {
          if (doc.exists) {
            this.db.firestore.collection("trainers").doc(this.user.id).collection("messages").doc(this.userTo.id).update(lastMessage)
          } else {
            this.db.firestore.collection("trainers").doc(this.user.id).collection("messages").doc(this.userTo.id).set(lastMessage)
          }
        })
      }

      if (this.userTo.isAdmin == true) {
        let messageRef2 = this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id)
        messageRef2.get().then(doc => {
          if (doc.exists) {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
          } else {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
          }
        })
      } else {
        if (this.userTo.isUser == true) {
          let messageRef2 = this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        } else {
          let messageRef2 = this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        }
      }
      if(this.firstTime == true){
        console.log("IF")
        this.getUsers()
        this.firstTime = false;
      }
    }
    if (this.selectedFile != null) {
      let msg = {
        idTo: this.userTo.id,
        idFrom: this.user.id,
        timestamp: stringTimestamp,
        content: this.selectedFile.name,
        fileUrl: null,
        type: 3
      }
      let ref1 = this.storage.ref(this.selectedFile.name)
      await ref1.put(this.selectedFile)
      msg.fileUrl = await ref1.getDownloadURL().toPromise()

      let message = msg
      let id: string
      if (this.chatDocId != "" && this.chatDocId != null) {
        id = String(this.chatDocId)
      }
      else {
        let idDoc: string
        if (this.user.id.hashCode >= this.userTo.id.hashCode) {
          idDoc = this.user.id + "-" + this.userTo.id
        } else {
          idDoc = this.userTo.id + "-" + this.user.id
        }
        id = String(idDoc)
      }
      let docRef = this.db.firestore.collection('messages').doc(id)
      let boolId: boolean = false
      docRef.set({
        'id': false
      })
      let ref = docRef.collection(id).doc()
      ref.set(message)

      let notifRef = this.db.firestore.collection('users').doc(this.userTo.id).collection('userNotifications').doc()
      notifRef.set({
        "content": this.selectedFile.name,
        "fullname": this.user.firstName + " " + this.user.lastName,
        "receiverId": this.userTo.id,
        "timestamp": new Date(),
        "docId": notifRef.id,
        "seen": false,
        "type": 1
      })
      let lastMessage = {
        "lastMessage": this.selectedFile.name,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.userTo.firstName + " " + this.userTo.lastName,
        "docId": this.userTo.id,
        "idFrom": this.user.id,
        "seen": true
      }
      let lastMessage2 = {
        "lastMessage": this.selectedFile.name,
        "timestamp": stringTimestamp,
        "chatId": id,
        "name": this.user.firstName + " " + this.user.lastName,
        "docId": this.user.id,
        "idFrom": this.user.id,
        "seen": false
      }
      let messageRef = this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id)
      messageRef.get().then(doc => {
        if (doc.exists) {
          this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id).update(lastMessage)
        } else {
          this.db.firestore.collection("users").doc(this.user.id).collection("messages").doc(this.userTo.id).set(lastMessage)
        }
      })

      if (this.userTo.isAdmin == true) {
        let messageRef2 = this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id)
        messageRef2.get().then(doc => {
          if (doc.exists) {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
          } else {
            this.db.firestore.collection("administrators").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
          }
        })
      } else {
        if (this.userTo.isUser == true) {
          let messageRef2 = this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("users").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        } else {
          let messageRef2 = this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id)
          messageRef2.get().then(doc => {
            if (doc.exists) {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).update(lastMessage2)
            } else {
              this.db.firestore.collection("trainers").doc(this.userTo.id).collection("messages").doc(this.user.id).set(lastMessage2)
            }
          })
        }
      }
      this.updateFile()
    }
    this.insertChatForm.controls.content.reset()
  }

  onFileChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "pdf" || ext == "docx" || ext == "xlsx") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: Number = +iConvert
      if (number <= 20) {
        this.selectedFile = event.target.files[0]
        this.messagesHeight = "63%"
      }
      else {
        this.snackBar.open(this.labels.lblMaxSizeDocument, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.fileUpload.nativeElement.value = ""
      }
    }
    else {
      this.snackBar.open(this.labels.lblSupportedFormatsDocument, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.fileUpload.nativeElement.value = ""
    }
    console.log(this.selectedFile)
  }

  updateFile() {
    this.messagesHeight = "72%"
    this.selectedFile = null
    this.fileUpload.nativeElement.value = ""
  }

}
