import { Component, OnInit } from '@angular/core';
import { AuthService } from './modules/services/auth-service/auth.service';
import { Observable, Subscription } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { UserLanguageService } from './modules/services/user-language-service/user-language.service';
import { AdminLanguageService } from './modules/services/admin-language-service/admin-language.service';
import { Administrator } from './models/administrator.model';
import { AdminAuthService } from './modules/services/admin-auth-service/admin-auth.service';
import { LocalStorageService } from './modules/services/localStorage-service/local-storage.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  lang$: Observable<String>;
  labels$;
  adminLang$: Observable<String>;
  adminLabels$;
  title = 'frontend';
routerSubscription: Subscription
  constructor(private router: Router, private localStorageService: LocalStorageService, private authService: AuthService, private adminAuthService: AdminAuthService, private languageService: UserLanguageService, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    console.log('app component refreshed')
    this.lang$ = this.languageService.getLanguage().pipe(share());
    this.labels$ = this.languageService.getLabels().pipe(share());

    this.adminLang$ = this.adminLanguageService.getLanguage().pipe(share());
    this.adminLabels$ = this.adminLanguageService.getLabels().pipe(share());
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {
        if(document.querySelector('mat-drawer-content') != null) document.querySelector('mat-drawer-content').scrollTop = 0;
      });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }



}
