import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { DashboardService } from '../../dashboard.service';
import { ImageCropperDialogComponent } from '../../image-cropper-dialog/image-cropper-dialog.component';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

export interface Question {
  text: string
  type: number
  random:boolean
}
@Component({
  selector: 'app-insert-question-dialog',
  templateUrl: './insert-question-dialog.component.html',
  styleUrls: ['./insert-question-dialog.component.scss']
})
export class InsertQuestionDialogComponent implements OnInit {
  value1: boolean = true
  value: boolean = true
  textErr: boolean = false;
  question: Question
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  idQuestion: string
  showOrderButton = false
  correct = false;
  typeOfQuestion: number
  labels;
  labelsSub: Subscription;
  testId
  dataSource
  assigned
  imgPath: null
  imgExtError: boolean = false;
  videoHintPath: string = null;
  videoExtError: boolean = false;
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  @ViewChild('videoUpload') videoUpload: ElementRef
  test
  test$: Observable<Test>
  testSub: Subscription
  selectedImage: File = null
  selectedVideo: File = null
  defaultValue: Number
  uploading:boolean = false

  constructor(private storage: AngularFireStorage, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertQuestionDialogComponent>, private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private adminLanguageService: AdminLanguageService,
    private userDashboarService: UserDashboardService) { }

  ngOnInit(): void {
    this.defaultValue = 1;
    this.getLang()
    console.log(this.data.testId)
    this.testId = this.data.testId
    // this.isTestAssigned()
    console.log("ORDER")
    console.log(this.data.order)
    this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.testId).pipe(share())
    this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
      this.test = test;
    });
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }

  setTypeOfAnswer(number) {
    console.log(number)
    this.typeOfQuestion = number
  }

  snackError(errMsg: string) {
    this.snackBar.open(errMsg, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
  }

  async onSubmit() {

    if (this.imgExtError) {
      this.snackError(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage);
      return;
    }

    if (this.videoExtError) {
      this.snackError(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo);
      return;
    }

    if (this.insertForm.value.textQ == "" || this.insertForm.value.points == "" || this.insertForm.value.type == "") {
      this.textErr = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.uploading = true
      if (this.selectedImage !== null) {
        let ref = this.storage.ref(this.selectedImage.name)
        await ref.put(this.selectedImage)
        this.imgPath = await ref.getDownloadURL().toPromise()
      }

      if (this.selectedVideo !== null) {
        let ref = this.storage.ref(this.selectedVideo.name);
        await ref.put(this.selectedVideo);
        this.videoHintPath = await ref.getDownloadURL().toPromise();
      }

      this.uploading = false
      
      if(!(this.videoExtError || this.imgExtError)) {
        this.dashboardService.insertQuestionForTest({
          testId: this.testId,
          text: this.insertForm.value.textQ,
          order: this.data.order + 1,
          points: this.insertForm.value.points,
          type: this.insertForm.value.type,
          imagePath: this.imgPath,
          videoHintPath: this.videoHintPath
        }).subscribe((response) => {
          console.log(response)
        })
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblQuestionAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
  
    // this.insertForm.controls.textQ.reset()
    // this.insertForm.controls.points.reset()
    // this.insertForm.controls.type.reset()
    this.fetchQuestionsForTest()
  }

  fetchQuestionsForTest() {
    this.dashboardService.fetchQuestionsForTest(this.testId).subscribe((response) => {
      this.dataSource = response
    })
  }

  onImageChanged(event) {

    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()

    if(ext == "jpg" || ext == "jpeg" || ext == "png"){

      this.imgExtError = false;
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert

      if(number <= 250){
        this.selectedImage = event.target.files[0]
        const dialogRef=this.dialog.open(ImageCropperDialogComponent,{
          width:"500px",
          height: "600px",
          data:event
        })
    
        dialogRef.afterClosed().subscribe((result)=>{
          this.selectedImage = result
          console.log(this.selectedImage)
        })
      }
      else{
        this.snackBar.open(this.labels.lblMaxFileSizeImage , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }
    }
    else{
      this.imgExtError = true;
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }
  }

  enterClick(){
    return false;
  }

  updateImage(){
    this.imgExtError = true;
    this.selectedImage = null
  }

  onVideoChanged(event) {

    this.selectedVideo = event.target.files[0];
    const ext = this.selectedVideo.name.split('.').pop();

    console.log("EXT: " + console.log(ext));

    if(ext == 'mp4') {
      this.videoExtError = false;
    }
    else{
      this.videoExtError = true;
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.videoUpload.nativeElement.value = "";
    }
  }

  updateVideo() {
    this.videoExtError = false;
    this.selectedVideo = null;
  }

}
