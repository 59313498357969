
<div class = "row" style = "width: 100%; margin: auto; background-color: #0E0D35; padding: 5%; border-radius: 21px;">
    <div class = "col-12" style = "margin: auto; text-align: center">
        <h1 style = "font-family: Lato, 'Alegreya Sans'; font-size: 31px; font-weight: bold; color: white; margin: auto;">{{labels.lblRangList}}</h1>
    </div>
    <div *ngFor = "let user of users">
        <div class = "mainDiv">
            <img *ngIf = "user.order == 2" class = "userImg second" src = {{user.profileImagePath}} alt = "second user image">
            <div *ngIf = "user.order == 2" class = "circle secondCircle">{{user.order}}</div>
        </div>
        <div class = "mainDiv">
            <img *ngIf = "user.order == 1" class = "userImg first" src = {{user.profileImagePath}} alt = "first user image">
            <div *ngIf = "user.order == 1" class = "circle firstCircle">{{user.order}}</div>
        </div>
        <div class = "mainDiv">
            <img *ngIf = "user.order == 3" class = "userImg third" src = {{user.profileImagePath}} alt = "third user image">
            <div *ngIf = "user.order == 3" class = "circle thirdCircle">{{user.order}}</div>
        </div>
    </div>
</div>
    
<div *ngFor = "let user of users" class = "row" style = "width: 100%; margin: auto;">
    <div *ngIf = "user.order != 1 && user.order != 2 && user.order != 3" class = "col-12" style = "padding: 15px; border-bottom: 1px solid #e5e5e5;">
        <div style = "width: 55%; position:relative; margin: auto;">
            <span style = "font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-size: 19px; vertical-align: middle; padding-right: 25px; font-weight: bold;">{{user.order}}</span><img style = "width: 50px; height: 50px; border-radius: 50px;" src = {{user.profileImagePath}} alt = "user profile image"><span style = "font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-size: 19px; padding-left: 15px; vertical-align: middle;">{{user.firstName}} {{user.lastName}}</span> <span style = "position:absolute; top: 33%; right: 0;font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-size: 17px; font-weight: bold;">{{user.totalPoints}}</span>
        </div>
    </div>
</div>