import { Component, OnInit,ViewChild ,Inject} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator'
import {MatTableDataSource} from '@angular/material/table'
import { DashboardService } from '../dashboard.service'
import {Observable, Subscription } from 'rxjs'
import { User } from '../../models/user.model'
import {DataSource} from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogComponent } from './../admin-training/edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './../admin-training/insert-dialog/insert-dialog.component';
import {Training} from './../../models/training.model'
import {Router} from '@angular/router'
import {DeleteDialogComponent} from './../admin-training/delete-dialog/delete-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar';
import { AssignTestComponent } from './assign-test/assign-test.component';
import {AdminLanguageService} from 'src/app/modules/services/admin-language-service/admin-language.service'
import { createDirective } from '@angular/compiler/src/core';
import { CopyTrainingComponent } from './copy-training/copy-training.component';
export interface DialogTrainingData {
  name: string;
  description: string;
}
@Component({
  selector: 'app-admin-training',
  templateUrl: './admin-training.component.html',
  styleUrls: ['./admin-training.component.scss']
})

export class AdminTrainingComponent implements OnInit {

  displayedColumns: string[] = ['name', 'description', 'dateCreated', 'actions'];
  dataSource; 
  labels;
  labelsSub: Subscription;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort:MatSort

  constructor(private dashboardService:DashboardService,public dialog: MatDialog,public snackBar:MatSnackBar,public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getTrainings();
    this.getLang();
  }

  
  
  getTrainings(){
    this.dashboardService.getTrainings().subscribe((trainings)=>{
      let trngs = trainings as Training[]
      trngs.forEach(t => {
        t.date = t.dateCreated.toLocaleString()
        t.date = t.date.substring(8,10)+"."+t.date.substring(5,7)+"."+t.date.substring(0,4)+"."
      })
      console.log(trngs)
      this.dataSource=new MatTableDataSource(trngs)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
      this.dataSource.filterPredicate = (data : Training, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());

    })
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  openEditDialog(training){
    const dialogRef=this.dialog.open(EditDialogComponent,{
      width:"500px",
      height: "600px",
      data:training
    })

    dialogRef.afterClosed().subscribe((result)=>{
      this.dataSource = null
      this.getTrainings()
    })
  }
  openDeleteDialog(training){
    const dialogRef=this.dialog.open(DeleteDialogComponent,{
      width:"500px",
      data:training
    })
    dialogRef.afterClosed().subscribe((result)=>{
      this.dataSource = null
      this.getTrainings()
    })
  }

  openInsertDialog(){
    const dialogRef=this.dialog.open(InsertDialogComponent,{
      width:'500px',
      height: '600px'

    })
    dialogRef.afterClosed().subscribe(response=>{
      this.dataSource = null
      this.getTrainings()
    })
  }

  openCopyDialog(){
    const dialogRef=this.dialog.open(CopyTrainingComponent,{
      width:'500px'
    })
    dialogRef.afterClosed().subscribe(response=>{
      this.dataSource = null
      this.getTrainings()
    })
  }

  assignTest(training){

    const dialogRef=this.dialog.open(AssignTestComponent,{
      width:'500px',
      data:{id: training.id, pretestId: training.pretestId, testId: training.testId, surveyId: training.surveyId}
    })

    dialogRef.afterClosed().subscribe(response=>{
      this.dataSource = null
      this.getTrainings()
    })
  }

 
}

