import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { AuthService } from '../../services/auth-service/auth.service';

@Component({
  selector: 'app-control-false',
  templateUrl: './control-false.component.html',
  styleUrls: ['./control-false.component.scss']
})
export class ControlFalseComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  user
  userSub: Subscription
  buttons
  constructor(private authService: AuthService, public adminLanguageService: AdminLanguageService, public dialogRef3: MatDialogRef<ControlFalseComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.getLang()
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      console.log(this.user)
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.buttons = "buttonEMS"

        }else if(this.user.companyId == 2){
          this.buttons = "buttonECG"
        }
        else{
          this.buttons = "buttonECG"
        }
      }, 200);
    });
  }

  onNoClick(){
    this.dialogRef3.close()
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
}
