import { Component, OnInit,ViewChild,Inject, AfterViewInit, AfterContentInit } from '@angular/core';

import {DashboardService} from './../../dashboard.service'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { NgForm } from '@angular/forms';
import { Training } from 'src/app/models/training.model';
import {RequestObject} from './../../../models/trainingUserRequest.model'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { User } from 'src/app/models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface TrainingRequest{
  id:string
  status:number
}
@Component({
  selector: 'app-user-trainings-dialog',
  templateUrl: './user-trainings-dialog.component.html',
  styleUrls: ['./user-trainings-dialog.component.scss']
})
export class UserTrainingsDialogComponent implements OnInit,AfterViewInit,AfterContentInit {

  @ViewChild('userTrainingsForm',{static:true}) userTrainingsForm:NgForm
  traininigIds
  traininigIdsUser
  trainings:Training[]
  userTrainings
  selectedCheckBoxes:string[]
  selectedTrainings:string[]
  training:Training
  reqObject:RequestObject
  user: User
  userSub: Subscription;
  labels;
  labelsSub: Subscription;
  assignedTrainings
  err
  constructor(public snackBar: MatSnackBar, private db: AngularFirestore, private authService: AdminAuthService, public dialogRef: MatDialogRef<UserTrainingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }

  ngAfterContentInit(){
    // this.extractIdsUser()
    // this.extractIds()
  }
  ngAfterViewInit(){

  }
  ngOnInit(): void {
    this.userSub = this.authService.admin$.subscribe((user: User) => {
      this.user = user;
    });
    // this.fetchTrainings()
    this.fetchTrainingsForUser()
    this.selectedCheckBoxes=new Array<string>()
    this.selectedTrainings=new Array<string>()
    this.getLang();

  }
  extractIds(){
    let ids=[]
    for (let index = 0; index < this.trainings.length; index++) {
      const element = this.trainings[index];
      ids.push(element.id)
    }
    this.traininigIds=ids
    
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  extractIdsUser(){
    let ids=[]
    for (let index = 0; index < this.userTrainings.length; index++) {
      const element = this.userTrainings[index];
      ids.push(element.trainingId)
    }
    this.traininigIdsUser=ids
  }
  onNoClick(){
    this.dialogRef.close()
    console.log(this.extractIds())
    console.log(this.extractIdsUser())
  }
  onSubmit(){
    let fromDate
      let toDate
      if(this.userTrainingsForm.value.fromDate == "" || this.userTrainingsForm.value.fromDate == null){
        fromDate = null
      }else{
        fromDate = this.userTrainingsForm.value.fromDate
      }
      if(this.userTrainingsForm.value.toDate == "" || this.userTrainingsForm.value.toDate == null){
        toDate = null
      }else{
        toDate = this.userTrainingsForm.value.toDate
      }
      this.updateTrainings({id:this.userTrainingsForm.value.id,fromDate: fromDate, toDate: toDate, trainings:this.selectedCheckBoxes})
      let id:string = this.userTrainingsForm.value.id
      let docRef = this.db.firestore.collection('users').doc(id).collection('userNotifications').doc()
      docRef.set({
          "receiverId": this.userTrainingsForm.value.id,
          "fullName": this.user.firstName + " " + this.user.lastName,
          "timestamp": new Date(),
          "email": this.user.email,
          "profileImagePath": this.user.profileImagePath,
          "docId": docRef.id,
          "content": "Dodela treninga: " + this.selectedTrainings,
          "seen": false,
          "type": 2
      })
  }

  // fetchTrainings(){
  //   this.dashboardService.fetchTrainigs().subscribe((trainings:Training[])=>{this.trainings=trainings as Training[]
  //   console.log("trainings")
  //   console.log(this.trainings)
  //   })
  // }
  fetchTrainingsForUser(){
    this.dashboardService.fetchTrainingsForUser(this.data.id).subscribe((trainings: Training[]) => {
      this.trainings = trainings as Training[]
      console.log("trainings")
      console.log(this.trainings)
    })
  }

  checkCheckBoxValue(value:string){

    this.data.trainings.forEach(element => {
      if(element.trainingId===value){
        return true
      }
      return false
    });
  }

  getTrainingId(e:any,training:Training){
    console.log("TRAINING")
    console.log(training.name)
    if(e.checked){
      this.selectedCheckBoxes.push(training.id)
      this.selectedTrainings.push(training.name)
    }else{
      this.selectedTrainings=this.selectedTrainings.filter((element)=>element != training.name)
      this.selectedCheckBoxes=this.selectedCheckBoxes.filter((element)=>element != training.id)
    }
    console.log(this.selectedTrainings)
    console.log(this.selectedCheckBoxes)
  }

  updateTrainings({id, fromDate, toDate, trainings}){
    console.log("trainings")
    console.log(trainings)
    this.assignedTrainings = trainings
    this.dashboardService.updateTrainings({id,fromDate, toDate, trainings}).subscribe((response)=>{
      console.log(response)
      this.snackBar.open(this.labels.lblTrainingAssigned, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
    
  }
}
