<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblEditQuestion}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="height: 330px;">
      <input type="hidden" [ngModel]="data.id" name="id">
      <input type="hidden" [ngModel]="data.testId" name="testId">
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblEnterQuestion}}</mat-label>
            <textarea matInput [ngModel]="data.text" name="text" required></textarea>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-filed appearance="none" fxFlex="60%" *ngIf = " data.imagePath == null">
        <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
        <div style="margin-bottom: 11px;">
            <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
            <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
        </div>
        </mat-form-filed>
        <div *ngIf = " data.imagePath != null">
            <mat-label>{{labels.lblImage}}: {{data.imageName}}</mat-label>
            <mat-icon style="float:right; cursor: pointer;" (click)="updateData()">cancel</mat-icon>
        </div>
        <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "data.videoHintPath == null">
            <input type="file" (change)="onVideoChanged($event)" #videoUpload style="display: none;"/>
            <div style="margin-bottom: 11px;">
                <mat-label style="color: #3e4b71;">{{labels.lblUploadVideoHint}} </mat-label>
                <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
            </div>
        </mat-form-filed>
        <div *ngIf = "data.videoHintPath != null">
            <mat-label>{{labels.lblVideo}}: {{data.videoName}}</mat-label>
            <mat-icon style="float:right; cursor: pointer;" (click)="cancelVideo()">cancel</mat-icon>
        </div>
        <mat-form-field appearance="none" *ngIf = "test.type != 3" fxFlex="60%">
            <mat-label>{{labels.lblPoints}}</mat-label>
            <input matInput number [ngModel]="data.points" *ngIf = "assigned.length == 0" name="points" required>
            <div *ngIf = "assigned.length > 0">
                {{data.points}}</div>
                <input matInput number [ngModel]="data.points" *ngIf = "assigned.length > 0" name="points" hidden>  
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblType}}</mat-label>
            <!-- <mat-select [ngModel]='data.type' name='type' required>
                <mat-option value="1">{{labels.lblOneCorrectAns}}</mat-option>
                <mat-option value="2">{{labels.lblMoreThanOne}}</mat-option>
            </mat-select> -->
            <input matInput [ngModel] = "data.type" name = "type" style="color: white" hidden>
            <div>
                <span *ngIf = "data.type == 1">{{labels.lblOneCorrectAns}}</span>
                <span *ngIf = "data.type == 2">{{labels.lblMoreThanOne}}</span>
                <span *ngIf = "data.type == 3">{{labels.lblTextAns}}</span>
            </div>
            <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error> -->
        </mat-form-field>
        <div mat-dialog-actions>
            <mat-progress-bar mode="indeterminate" style="margin-top: 15px;" *ngIf="uploading"></mat-progress-bar>
            <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
            <button cdkFocusInitial class="tableBtn" type="submit" style="margin-top: 15px;">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div>



