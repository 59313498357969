<app-admin-spinner *ngIf="labels == null || departments == null || dataSource == null"></app-admin-spinner>
<div class="font table1" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
  <div fxFlex="5"></div>
  <div fxFlex="90">
    <!-- <mat-card class="header">
        <div fxLayout="row" fxFlex="100">
          <mat-card-title fxFlex="90">{{labels.lblUsers}}</mat-card-title>
            <mat-card-content fxFlex="10" fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
              <button  mat-raised-button class="tableBtn" (click)="openInsertDialog()">{{labels.lblAddNewUser}}</button>
            </mat-card-content>
        </div>
      </mat-card> -->
    <div class="mat-elevation-z8">
      <div class="row header">
        <div class="col-12 col-md-3 text-left">
          <h5 class="tableName">{{labels.lblUsers}}</h5>
        </div>
        <div class="col-12 col-md-9 text-right">
          <button mat-raised-button class="tableBtn" style="margin-right: 20px;" (click)="openInsertDialog()">{{labels.lblAddNewUser}}</button>
          <button mat-raised-button class="tableBtn addListBtn" (click)="openAddListDialog()">{{labels.lblAddList}}</button>
        </div>
        <div class="col-10 text-left search1">
            <mat-form-field class="search" appearance="none" fxFlex="40%">
              <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
            </mat-form-field>
        </div>
      </div>
      <table matSortDisableClear="true" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <!-- userName Column -->
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >{{labels.lblUserName}} </th>
          <td mat-cell *matCellDef="let user" class="userName" [routerLink]="['/admin/user-profile',user.id]"> {{user.userName}} </td>
        </ng-container>

        <!-- firstName Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn"> {{labels.lblFirstName}} </th>
          <td mat-cell *matCellDef="let user" class="hideColumn"> {{user.firstName}} </td>
        </ng-container>

        <!-- LastName Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn"> {{labels.lblLastName}} </th>
          <td mat-cell *matCellDef="let user" class="hideColumn"> {{user.lastName}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="departmentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn"> {{labels.lblSectorGroup}} </th>
          <td mat-cell *matCellDef="let user" class="hideColumn userName" [routerLink]="['/admin/usersInDep',user?.departmentId]"> {{user.departmentName}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn"> {{labels.lblEmail}} </th>
          <td mat-cell *matCellDef="let user" class="hideColumn"> {{user.email}} </td>
        </ng-container> -->
        <!-- style="color: #19a97e" -->
        <!--Actions column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width: 270px;"> {{labels.lblOptions}} </th>
          <td mat-cell *matCellDef="let user">
            <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="openEditDialog(user)">
                        {{labels.lblEdit}}
                      </button>
                      <button class="deleteBtn d-none d-sm-inline-flex"  (click)="openDeleteDialog(user)">
                        {{labels.lblDelete}}
                      </button> -->
            <button class="addNewBtn" (click)="openUserTrainingsDialog(user)" *ngIf = "user.pending != true && user.numOfTrainings > 0">
              <!-- <mat-icon>plus_one</mat-icon> -->
              {{labels.lblAssignTraining}}
            </button>
            <button class="addNewBtnRed" (click)="openUserTrainingsDialog(user)" *ngIf = "user.pending != true && user.numOfTrainings == 0">
              <!-- <mat-icon>plus_one</mat-icon> -->
              {{labels.lblAssignTraining}}
            </button>
            <button class="addPendingBtn addNewBtn d-none d-sm-inline-flex" (click) = "insertPendingUserDialog(user)" *ngIf = "user.pending == true">
              <!-- <mat-icon>plus_one</mat-icon> -->
              Prihvati
            </button>
            <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu" *ngIf = "user.pending != true">settings</mat-icon>
            <mat-menu #settingsMenu="matMenu" class="mMenu">
              
              <button mat-menu-item class="menuButton settingsMenuItem" [routerLink]="['/admin/user-profile',user.id]">
                <mat-icon style="color: #8293c6;">subject</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblMoreDetails}}</span>
              </button>
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openEditDialog(user)">
                <mat-icon style="color: #8293c6;">edit</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
              </button>
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(user)">
                <mat-icon style="color: #8293c6;">delete</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator class="paginator" [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div fxFlex="5"></div>
</div>
<router-outlet></router-outlet>