<app-user-spinner *ngIf="test == null || labels == null"></app-user-spinner>
<div class="container">
  <!-- <img class="imageFade" style="border:1px solid transparent; border-radius:50%; margin-bottom:20px;" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testIcon.png?alt=media&token=b7ba3f32-124c-4fef-82f3-a2e2c858f4e5" alt=""> -->
    <div class = "row">
      <div class = "col-11 col-sm-4" style = "margin: auto; margin-top: 11%; text-align: left;">
        <h1 style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblSurvey}}</h1>
        <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">
          {{labels.lblSurveyIsContained}}  <span [style.color] = "color">{{test.questions.length}}</span> {{labels.lblQuestions}} <br>
        {{labels.lblYourOpinion}} <br>
          {{labels.lblInOrderToKnow}} <br>
          {{labels.lblToImprove}}.
        </h3>
        <!-- <div mat-dialog-actions class="dugmeDiv">
          <button mat-button class="btn" [class] = "button1" (click)="goToTestPage()">{{labels.lblContinue}}</button>
        </div> -->
        <div class="row" style = "text-align: center;">
          <div class="col-12" style = "margin: auto;">
            <button class="login-button" (click)="goToTestPage()">
              <div class = "row">
                <div class = "col-3"></div>
                <div class = "col-5" style = "margin: auto; text-align: center;">
                  <h3 class = "loginBtnLabel">{{labels.lblStartSurvey}}</h3>
                </div>
               <div class = "col-3"><img style = "float: left;" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/loginIcon.png?alt=media&token=331da5b1-d77f-4c81-89a1-0e35b3feea30' alt = "login icon"></div>
              </div>
            </button>
          </div>
      </div>
    </div>
  </div>
