<app-admin-spinner *ngIf="admin == null || labels == null"></app-admin-spinner>
<div class="row edit-profile" style="width: 100%; margin: auto; margin-top: 1vh;  margin-bottom: 1.5vh;">
    <div class="col-12 col-xl-2 profileImg" style="width:130px;">
        <!-- <img src="https://st2.depositphotos.com/1006318/5909/v/600/depositphotos_59095205-stock-illustration-businessman-profile-icon.jpg"> -->
        <div style="width: 130px; height: 130px; margin:auto; position: relative">
            <!-- User Profile Imag-->
            <div style="width: 130px; height: 130px; position: absolute" *ngIf="!selectedImage"><img
                    src={{admin.profileImagePath}} alt = "admin profile image"></div>
            <div style="width: 130px; height: 130px; position: absolute" *ngIf="selectedImage"><img
                    src={{selectedImageUrl}} alt = "admin profile image" #profileImg></div>
            <div style="width:100%; height:100%; position: absolute; top: 80px; left: -5px"
                *ngIf="admin.profileImagePath!='https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/noImage.jpg?alt=media&token=b8c8a3ec-2293-4657-bfd0-c10c19255144' && selectedImage==null">
                <mat-icon (click)="openDeleteDialog()"
                    style="color: #3e4b71; cursor: pointer; font-size: 40px; float: left">
                    dangerous</mat-icon>
            </div>
            <div style="width:100%; height:100%; position: absolute; top: 80px; left: -5px"
                *ngIf="selectedImage!=null">
                <mat-icon (click)="updateImage()"
                    style="color: #3e4b71; cursor: pointer; font-size: 40px; float: left">
                    dangerous</mat-icon>
            </div>
            <div style="width:100%; height:100%; position: absolute; top: 80px; left: 100px">
                <form #editImageForm="ngForm">
                    <input type="hidden" name="id" [ngModel]="admin.id">
                    <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
                    <mat-icon *ngIf="selectedImage==null && disable==false && admin.profileImagePath=='https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/noImage.jpg?alt=media&token=b8c8a3ec-2293-4657-bfd0-c10c19255144'" (click)="imageUpload.click()"
                        style="color: #3e4b71; cursor: pointer; font-size: 40px; float: left !important;">
                        camera_alt</mat-icon>

                    <!-- <div class="logLabels" *ngIf="selectedImage" style="width: 100%;">
                        <h5 style="color: #9dacd9; margin-left: 0px; font-family: 'Prompt', sans-serif !important;">{{"
                            " + labels.lblSelectedImage + ": " + selectedImage.name}}</h5>
                    </div> -->
                </form>
            </div>
        </div>

    </div>
    <div class="col-12 col-xl-8 profileInfo" style="position: relative">
        <h2 style="margin-bottom: 0px; color: #9dacd9; font-family: 'Prompt', sans-serif !important;">{{admin.firstName
            + " "+ admin.lastName}}</h2>
        <h4 style="color: #3e4b71; font-family: 'Prompt', sans-serif !important;">{{admin.email}}</h4>
    </div>
</div>

<div class="row edit-form" style="margin: auto; text-align: center">
    <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="margin: auto;">
        <input type="hidden" name="id" [ngModel]="admin.id">
        <mat-form-field appearance="none" class="col-12 col-lg-5 form-field">
            <div class="logLabels">{{labels.lblFirstName}}</div>
            <input matInput [ngModel]="admin.firstName" name="firstName" *ngIf="disable == false">
            <input matInput [ngModel]="admin.firstName" name="firstName" *ngIf="disable == true" hidden>
            <div class="inp" *ngIf="disable == true">{{admin.firstName}}</div>
        </mat-form-field>
        <mat-form-field appearance="none" class="col-12 col-lg-5 form-field">
            <div class="logLabels">{{labels.lblLastName}}</div>
            <input matInput [ngModel]="admin.lastName" name="lastName" *ngIf="disable == false">
            <input matInput [ngModel]="admin.lastName" name="lastName" *ngIf="disable == true" hidden>
            <div class="inp" *ngIf="disable == true">{{admin.lastName}}</div>
        </mat-form-field>
        <mat-form-field appearance="none" class="col-12 col-lg-5 form-field">
            <div class="logLabels">{{labels.lblUserName}}</div>
            <input matInput [ngModel]="admin.userName" name="userName" hidden>
            <div class="inp">{{admin.userName}}</div>
        </mat-form-field>
        <mat-form-field appearance="none" class="col-12 col-lg-5 form-field">
            <div class="logLabels">E-mail</div>
            <input matInput [ngModel]="admin.email" name="email" hidden>
            <div class="inp">{{admin.email}}</div>
        </mat-form-field>
        <mat-form-field appearance="none" class="col-12 col-lg-5 form-field">
            <div class="logLabels">{{labels.lblNewPassword}}</div>
            <input type="password" matInput ngModel name="newPassword" [disabled]="disable == true">
        </mat-form-field>
        <mat-form-field appearance="none" class="col-12 col-lg-5 form-field">
            <div class="logLabels">{{labels.lblConfirmPassword}}</div>
            <input type="password" matInput ngModel name="confirmPassword" [disabled]="disable == true">
        </mat-form-field>
        <div mat-dialog-actions *ngIf="disable == true" style="margin:auto; text-align: center;margin-bottom:1vh;">
            <button class="mainBtn btn" cdkFocusInitial type="button" style="margin:auto;"
                (click)="enableAll()">{{labels.lblEdit}}</button>
        </div>
        <div mat-dialog-actions *ngIf="disable == false" style="margin:auto; text-align: center;margin-bottom:1vh;">
            <button class="mainBtn btn" cdkFocusInitial type="submit" style="margin:auto;">{{labels.lblSave}}</button>
        </div>
    </form>
</div>

<!-- terms of use -->
<div class="row" style="margin-top: 3%;">
    <div class="col-10 col-xl-6 sectorInfo" style="margin-bottom: 3%;">
        <div class="trainingCard" style="background-color:#182038 !important">
            <h2 class="basicInfo">Podaci o kompaniji</h2>
            <mat-card style="padding-top: 0px;">
                <mat-card-content>
                    <div class="row info">
                        <div class="col-6">
                            <h3>Naziv kompanije: </h3>
                        </div>
                        <div class="col-6">
                            <h3 style="color: #9dacd9; float: right;">Naziv</h3>
                        </div>
                    </div>
                    <div class="row info">
                        <div class="col-6">
                            <h3>{{labels.lblAdministrator}}: </h3>
                        </div>
                        <div class="col-6">
                            <h3 style="color: #9dacd9; float: right">{{admin.firstName}} {{admin.lastName}}</h3>
                        </div>
                    </div>
                    <!-- <div class="row info">
                        <div class="col-6">
                            <h3 style="color: #1494f9">{{labels.lblTermsOfUse}}:</h3>
                        </div>
                        <div class="col-6">
                            <form (ngSubmit)="submitTerms()" #insertTermsForm="ngForm"
                                style="margin: auto; text-align: right">
                                <input type="hidden" name="id" [ngModel]="admin.id">
                                <mat-form-filed appearance="none">
                                    <input type="file" (change)="onDocumentChanged($event)" #documentUpload
                                        style="display: none;">
                                    <button mat-raised-button *ngIf="selectedDocument == null" class="addBtn"
                                        style="float:right" type="button"
                                        (click)="documentUpload.click()">{{labels.lblAdd}}</button>
                                    <mat-label class="docLabel" style="color: #9dacd9;" *ngIf="selectedDocument">
                                        {{selectedDocument.name}}
                                    </mat-label>
                                </mat-form-filed>
                                <div mat-dialog *ngIf="selectedDocument"
                                    style="width: 100%; margin:auto; text-align: right;">
                                    <button class="mainBtn btn2" cdkFocusInitial type="submit"
                                        style="margin:auto;">{{labels.lblSave}}</button>
                                </div>
                            </form>
                        </div>
                    </div> -->
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>