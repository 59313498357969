import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Meeting } from 'src/app/models/meeting.model';
import { Test } from 'src/app/models/test.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-message-before-zoom-test',
  templateUrl: './message-before-zoom-test.component.html',
  styleUrls: ['./message-before-zoom-test.component.scss']
})
export class MessageBeforeZoomTestComponent implements OnInit {

  labels;
  test: Test;
  meeting: Meeting;
  user: User;
  button1;
  button2;
  color;

  constructor(public dialogRef: MatDialogRef<MessageBeforeZoomTestComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private router: Router,
              private localStorageService : LocalStorageService,
              private userLanguageService : UserLanguageService,
              private userDashboardService: UserDashboardService,
              private authService: AuthService) { }

  ngOnInit(): void {
    //console.log("BEFOREDATA ", this.data);
    this.meeting = this.data.meeting;

    this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userDashboardService.getTestForTraining(this.meeting.testId).subscribe((test) => {
      this.test = test;
    })

    this.authService.user$.subscribe((user: User) => {
          this.user = user;
          setTimeout(() => {
            if(this.user.companyId == 1){
              this.button1 = "buttonEMS"
              this.button2 = "buttonEMS1"
              this.color = "#c32a2a"
            
            }else if(this.user.companyId == 2){
              this.button1 = "buttonECG"
              this.button2 = "buttonECG1"
              this.color = "rgb(241,106,100)"
            }
            else{
              this.button1 = "buttonECG"
              this.button2 = "buttonECG1"
              this.color = "rgb(241,106,100)"
            }
          }, 200);
        });

  }

  goToZoomTest() {
    this.localStorageService.setItem("meetingForTest", this.data.meeting);
    this.router.navigateByUrl("user/zoom-test");
    this.dialogRef.close();
  }

}
