import { Component, ElementRef, Input, OnInit, ChangeDetectorRef, EventEmitter, HostListener, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserDashboardService } from '../user-dashboard.service';
import { TestQuestionScore } from 'src/app/models/testQuestionScore.model'
import { TestQuestionAnswerScore } from 'src/app/models/testQuestionAnswerScore.model'
import { TestQuestionAnswer } from 'src/app/models/testQuestionAnswer.model';
import { TestQuestion } from 'src/app/models/testQuestion.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserPretestMessageComponent } from './user-pretest-message/user-pretest-message.component'
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';


@Component({
  selector: 'app-pre-test',
  templateUrl: './pre-test.component.html',
  styleUrls: ['./pre-test.component.scss'],
  host: { '(click)': 'onClick()'}
})
export class PreTestComponent implements OnInit {

  training$: Observable<Training>
  trainingSub: Subscription
  training: Training

  test$: Observable<Test>
  testSub: Subscription
  test: Test

  userSub: Subscription
  user: User

  labelsSub; Subscription
  labels

  index: number = 0
  checked: boolean = false

  questionScores: TestQuestionScore[] = []

  testProgress
  showNextBtn
  backgroundColor
  buttons
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  constructor(private userLanguageService: UserLanguageService, public dialog: MatDialog, private authService: AuthService, private userDashboarService: UserDashboardService, private localStorageService: LocalStorageService, private component: UserDashboardComponent) { }

  ngOnInit(): void {
    this.showNextBtn = false
    this.training$ = this.userDashboarService.getTraining().pipe(share())
    this.trainingSub = this.userDashboarService.training$.subscribe((training: Training) => {
      this.training = training;
    });

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.backgroundColor = "#bbbcbf"
          this.buttons = "buttonEMSGray"

        }else if(this.user.companyId == 2){
          this.backgroundColor = "rgb(241,106,100)"
          this.buttons = "buttonECG"
        }
        else{
          this.backgroundColor = "rgb(241,106,100)"
          this.buttons = "buttonECG"
        }
      }, 200);
    });
    this.test$ = this.userDashboarService.getPretestForTraining(this.localStorageService.getItem('pretestId')).pipe(share())
    this.testSub = this.userDashboarService.pretest$.subscribe((test: Test) => {
      this.test = test;
      setTimeout(() => {
        this.index = this.localStorageService.getItem('currentQuestionIndex')
        let p = (this.index) / this.test.questions.length * 100
        this.testProgress = Math.floor(p)
      }, 100);
    });

    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    let scores = this.localStorageService.getItem('questionScores')
    if (scores == null) {
      this.questionScores = []
    }
    else {
      this.questionScores = scores
    }

  }
  @HostListener("click") onClick(){
    this.component.sideBarOpened = false
  }

  nextQuestion(order: number) {
    let questionAnswerScores: TestQuestionAnswerScore[] = []
    let currentQuestion: TestQuestion = this.test.questions[order - 1]
    let nextQuestion: TestQuestion = this.test.questions[order]
    this.getScoreForQuestion(questionAnswerScores, currentQuestion)
    let show
    
    this.localStorageService.setItem('questionScores', this.questionScores)

    if (!(this.test.questions.length == order)) {
      this.index = order
      let p = (this.index)/this.test.questions.length*100
      this.testProgress = Math.floor(p)
      this.localStorageService.setItem('currentQuestionIndex', order)
      this.localStorageService.setItem('pretest', this.test)
      for (let i = 0; i < nextQuestion.answers.length; i++) {
        const ans = nextQuestion.answers[i];
        console.log("*******")
        console.log(ans)
        if(ans.selected == true){
          show = true;
          break;
        }
      }
      if(show == true){
        this.showNextBtn = true
      }else{
        this.showNextBtn = false
      }
    }
    else {
    }


  }

  previousQuestion(order: number) {
    this.showNextBtn = true;
    if (order != 1) {
      this.index = order - 2
      let p = (this.index)/this.test.questions.length*100
      this.testProgress = Math.floor(p)
      this.localStorageService.setItem('currentQuestionIndex', order - 2)
      this.localStorageService.setItem('pretest', this.test)
    }
    else {
    }

  }

  submit(order: number) {
    this.testProgress = 100
    this.nextQuestion(order)
    let corrcetAnswersCounter = 0
    let questions = this.test.questions.length
    let points = 0
    let minPercentage = this.test.minPercentage
    let maxPoints = 0
    for (let index = 0; index < this.test.questions.length; index++) {
      maxPoints+=this.test.questions[index].points
    }
    this.questionScores.forEach(qs => {
      if (qs.correct) {
        corrcetAnswersCounter++
        for (let index = 0; index < this.test.questions.length; index++) {
          if (this.test.questions[index].id == qs.testQuestionId) {
            points += this.test.questions[index].points
          }
        }
      }
    })
    let p: number = corrcetAnswersCounter / this.questionScores.length * 100
    let percentage: number = Math.floor(p)
    let passedTest: boolean = false
    if (percentage >= minPercentage)
      passedTest = true
      
    this.openTestDialog(corrcetAnswersCounter, percentage, questions, points, minPercentage, maxPoints, passedTest)
    this.userDashboarService.setQuestionScores(this.questionScores, false, true).subscribe((response) => {
      console.log(response)
    })

    this.localStorageService.removeItem('pretest')
    this.localStorageService.removeItem('questionScores')
    this.user.trainings.forEach(element => {
      if(element.trainingId == this.training.id){
        element.pretestFlag = true
      }
    });
    this.authService.updateUser(this.user)
  }

  getScoreForQuestion(questionAnswerScores: TestQuestionAnswerScore[], currentQuestion: TestQuestion) {
    let questionScore: TestQuestionScore
    questionScore = { id: this.getId(), testQuestionId: currentQuestion.id, userId: this.user.id, correct: false, answerScores: questionAnswerScores, testId:this.test.id}

    currentQuestion.answers.forEach(a => {
      let answerScore: TestQuestionAnswerScore
      let checked: boolean
      if (a.selected) {
        checked = true
      }
      else {
        checked = false
      }
      answerScore = { id: this.getId(), testQuestionScoreId: questionScore.id, testQuestionAnswerId: a.id, checked: checked, correct: this.isItCorrect(checked, a) }
      questionAnswerScores.push(answerScore)
    })

    let flag: boolean = true
    for (let index = 0; index < questionAnswerScores.length; index++) {
      if (!questionAnswerScores[index].correct) {
        flag = false
        break;
      }
    }
    //questionScore = {id: this.getId(), testQuestionId: this.selectedRadio.testQuestionId, userId: this.user.id, correct: flag, answerScores:questionAnswerScores}
    questionScore.testQuestionId = currentQuestion.id
    questionScore.correct = flag
    questionScore.testId = this.test.id
    questionScore.answerScores = questionAnswerScores

    this.addQuestionScore(this.questionScores, questionScore)
  }

  addQuestionScore(scores: TestQuestionScore[], score: TestQuestionScore) {
    let flag = 0
    for (let index = 0; index < scores.length; index++) {
      if (scores[index].testQuestionId == score.testQuestionId) {
        scores[index] = score
        flag = 1
        break;
      }
    }
    if (!flag) {
      scores.push(score)
    }

  }

  isItCorrect(checked: boolean, answer: TestQuestionAnswer): boolean {
    if ((checked && answer.correct) || (!checked && !answer.correct)) {
      return true
    }
    else return false
  }

  getId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  onSelect(question: TestQuestion, answer: TestQuestionAnswer) {
    if (question.type == 1) {
      question.answers.forEach((x) => { if (x.id !== answer.id) x.selected = false; })
    }
    else {
      question.answers.forEach((x) => {
        if (x.id == answer.id) {
          if (x.selected) {
            x.selected = false
            x.selected = true
          }
          else {
            x.selected = true
            x.selected = false
          }
        }
      })
    }

    let show
    for (let i = 0; i < question.answers.length; i++) {
      const ans = question.answers[i];
      console.log("*******")
      console.log(ans)
      if(ans.selected == true){
        show = true;
        break;
      }
    }
    if(show == true){
      this.showNextBtn = true
    }else{
      this.showNextBtn = false
    }

  }

  openTestDialog(corrcetAnswersCounter: number, percentage: number, questions: number, points: number, minPercentage: number, maxPoints: number, passedTest: boolean): void {
    const dialogRef = this.dialog.open(UserPretestMessageComponent, {
      backdropClass: "blurBackground2",
      disableClose: true,
      width: '650px',
      data: { corrcetAnswersCounter: corrcetAnswersCounter, percentage: percentage, questions: questions, points: points, minPercentage: minPercentage, maxPoints: maxPoints, passedTest: passedTest }
    });


  }

}
