<div class="row"
    style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
    <div class="col-11"></div>
    <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
        <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
    </div>
</div>
<div class="row">
    <div class="col-12 div">
        <h3>{{labels.lblAddDocument}}</h3>
    </div>
    <div class="logLabels" *ngIf="selectedFile" style="width: 100%; text-align: center;">
        <h4 style="color: #9dacd9; margin-left: 0px; font-family: 'Prompt', sans-serif !important;">
            {{selectedFile.name}}</h4>
    </div>
</div>
<div class="row" style="margin-top: 15px;">
    
    <input type="file" (change)="onFileChanged($event)" #fileUpload style="display: none; padding: 7px;">
    <mat-progress-bar mode="indeterminate" style="margin-top: 10px; margin-bottom: 10px" *ngIf="uploading"></mat-progress-bar>
    <div class="col-4 div">
        <button mat-raised-button class="tableBtn" style="width: 100%; text-align: center;" cdkFocusInitial
            type="submit" (click)="fileUpload.click()" *ngIf="!selectedFile">{{labels.lblAdd}}</button>
        <button mat-raised-button class="tableBtn" style="width: 100%; text-align: center;" cdkFocusInitial
            type="submit" *ngIf="selectedFile" (click)="onSaveDocument()">{{labels.lblSave}}</button>
    </div>
</div>
