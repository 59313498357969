<app-admin-spinner *ngIf="dataSource == null || labels == null || test == null"></app-admin-spinner>
<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf='outlet.isActivated===false'>
    <div fxFlex="5"></div>
    <div fxFlex="90">
        <!-- <mat-card cdkDrag>
            <div fxLayout="row" fxFlex='100'>
                <mat-card-title fxFlex="90">Table of Questions</mat-card-title>
                  <mat-card-content fxFlex="40" fxFlex.sm="60" fxFlex.xs="80">
                    <button class="tableBtn" mat-raised-button (click)="openInsertQuestionDialog()" style="margin-right: 1vw;">
                      Add new question
                    </button>
                    <button class="tableBtn" mat-raised-button (click)="showChangeButton()" *ngIf='value===true'>
                      Change order      
                    </button>
                    <button class="tableBtn" mat-raised-button  (click)="changeOrder()" *ngIf='value===false'>
                      Save this order      
                    </button>
                  </mat-card-content>
              </div>
        </mat-card> -->
        <div class="mat-elevation-z8">
          <div class="row header">
            <div class="col-xl-5 text-left">
              <nav aria-label="breadcrumb">
                  <ol class="breadcrumb" style="background-color: #182038; font-size: 16px;">
                      <li class="breadcrumb-item"><a style="color: #3e4b71" routerLinkActive="list-item-active"
                              routerLink="/admin/tests"><mat-icon>home</mat-icon></a></li>
                      <li class="breadcrumb-item"><a [routerLink]="['/admin/test-details',test.id]" style="color: #8293c6">{{test.name}}</a></li>
                      <li aria-current="page" style="color: #9dacd9"> <span
                              style="margin-left:10px; margin-right: 10px;">></span>{{labels.lblQuestions}}</li>
                  </ol>
              </nav>
          </div>
            <div class="col-xl-7 text-right" [disabled] = "assigned.length > 0">
            <mat-checkbox [ngModel]="test.random" class="example-margin checkbox" (change)="changeRandom()"
            [ngClass]="{'disabled-btn': (value == true) ||(assigned.length > 0)}">
                <span class="hideColumn">{{labels.lblRandom}}</span>
              </mat-checkbox>
              <button mat-raised-button [disabled] = "assigned.length > 0" class="tableBtn" (click)="showChangeButton()" *ngIf='value===true'
                style="margin-right: 10px">
                {{labels.lblChangeOrder}}
              </button>
              <button mat-raised-button [disabled] = "assigned.length > 0" class="tableBtn" (click)="changeOrder()" *ngIf='value===false'
                style="margin-right: 10px;">
                {{labels.lblSaveOrder}}
              </button>
              <button class="tableBtn" style="margin-top: 7px !important;" [disabled] = "assigned.length > 0" mat-raised-button (click)="openInsertQuestionDialog(test)" style="margin-right: 1vw;">
                {{labels.lblAddNewQuestion}}
              </button></div>
              <div class="col-10 text-left search1">
                <mat-form-field class="search" appearance="none" fxFlex="40%">
                  <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
                </mat-form-field>
            </div>
          </div>
            <table 
            mat-table 
            [dataSource]="dataSource" 
            matSort 
            cdkDropList 
            [cdkDropListData]="dataSource" 
            (cdkDropListDropped)="drop($event)"
            #table
            matSortDisableClear="true"
            >
          
            <!-- ORDER Column -->
            <ng-container matColumnDef="order">
              <th style="width: 10%;" mat-header-cell *matHeaderCellDef mat-sort-header><span class="order">{{labels.lblOrder}}</span><span class="order1">{{labels.lblO}}</span></th>
              <td style="width: 10%;" mat-cell *matCellDef="let question">
                <mat-icon *ngIf="!test.random && !value" style="cursor: pointer;" class="settings">
                  import_export
                </mat-icon>
                <span>{{question.order}}. </span> <span *ngIf="test.random"> R </span>
              </td>
            </ng-container>

            <!-- parentId Column -->
            <ng-container matColumnDef="type">
              <th style="width: 10%" mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblType}}</th>
              <td style="width: 10%" mat-cell *matCellDef="let question">
                <span *ngIf='question.type===1'>{{labels.lblOneCorrectAns}}</span>
                <span *ngIf='question.type===2'>{{labels.lblMoreThanOne}}</span>
                <span *ngIf='question.type===3'>{{labels.lblTextAns}}</span>
                <!-- <span *ngIf='question.type===3'>TEXT ANSWER</span>  -->
              </td>
            </ng-container>

            <ng-container matColumnDef="text">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblQuestionText}} </th>
              <td data-toggle="tooltip" data-placement="top" title={{question.text}} class="questionTD" mat-cell *matCellDef="let question">
                {{question.text.substring(0,47)}}<span *ngIf = "question.text.length > 47">...</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="points">
              <th style="width: 10%" mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblPoints}}</th>
              <td style="width: 10%" mat-cell *matCellDef="let question" class="hideColumn"> {{question.points}} </td>
            </ng-container>
              <!--Actions column-->
              <ng-container matColumnDef="actions">
                <th class = "option"  mat-header-cell *matHeaderCellDef style="text-align: center"> {{labels.lblOptions}} </th>
                <td class = "option" mat-cell *matCellDef="let question"> 
                  <div class="row d-sm-flex" style="float:right; padding:auto;">

                    <button *ngIf = "question.type != 3 && question.numOfAnswers > 0" class="addNewBtn d-sm-inline-flex col-xs-11" style="color: #0da243;"  [routerLink]="[question.id,'question-answers']">
                      <!-- <mat-icon>beenhere</mat-icon> -->
                     {{labels.lblAnswers}}
                    </button>
                    <button *ngIf = "question.type != 3 && question.numOfAnswers == 0" class="addNewBtn d-sm-inline-flex col-xs-11" style="color: red; border: 1px solid red;"  [routerLink]="[question.id,'question-answers']">
                      <!-- <mat-icon>beenhere</mat-icon> -->
                     {{labels.lblAnswers}}
                    </button>
  
                    <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu"
                      style="vertical-align: middle !important; margin:auto; margin-left: 15px;">settings</mat-icon>
                    <mat-menu #settingsMenu="matMenu" class="mMenu">
                      <button mat-menu-item class="menuButton settingsMenuItem" (click)="openEditDialog(question)">
                        <mat-icon style="color: #8293c6;">edit</mat-icon>
                        <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                      </button>
                      <button mat-menu-item class="menuButton settingsMenuItem" [ngClass]="{'disabled-btn': assigned.length > 0}" (click)="openDeleteDialog(question)" [disabled] = "assigned.length > 0">
                        <mat-icon style="color: #8293c6;">delete</mat-icon>
                        <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                      </button>
                    </mat-menu>
                  </div>   
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row 
              *matRowDef="let row; columns: displayedColumns;"
              cdkDrag
              [cdkDragDisabled]="value"
              [cdkDragData]="row"
              ></tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
          </div>
    </div>
    <div fxFlex="5"></div>
</div>
<router-outlet #outlet='outlet'></router-outlet>
<!-- <app-admin-test-question></app-admin-test-question> -->