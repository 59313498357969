import { Component, OnInit } from '@angular/core';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-admin-faq',
  templateUrl: './admin-faq.component.html',
  styleUrls: ['./admin-faq.component.scss']
})
export class AdminFaqComponent implements OnInit {
  labelsSub; Subscription
  labels
  constructor(private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

}
