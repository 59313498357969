import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid
} from "ng-apexcharts";
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard.service';
import { AdminAuthService } from 'src/app/modules/services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: any;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  tooltip
};

@Component({
  selector: 'app-apex-chart',
  templateUrl: './apex-chart.component.html',
  styleUrls: ['./apex-chart.component.scss']
})
export class ApexChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  labels;
  labelsSub: Subscription;

  admin
  adminSub: Subscription
  result
  users
  names
  nums
  deps
  names1
  constructor(public router: Router, private dashboardService:DashboardService, public adminLanguageService: AdminLanguageService, private adminAuthService: AdminAuthService) {
    
  }

   ngOnInit(): void {
    this.names = []
    this.nums = []
    this.names1 = []
    this.getAdmin()
    this.getLang()
  }

  fetchDepartments(){
    this.dashboardService.fetchAllDepartments().subscribe((result) => {
      this.deps = result
    })
  }

  fetchAdminsDepartments(depId){
    this.dashboardService.fetchAdminsDepartments(depId).subscribe((result) => {
      this.deps = result
    })
  }

  fetchUsersPerDeps1(){
    this.dashboardService.fetchUsersPerAdminDeps(this.admin.departmentId).subscribe((result) =>{
      this.result = result
      this.result.forEach((r) => {
        if(r.sector.length > 15){
          let name = r.sector.split(" ")
          if((name[0].length < 8) && (name[1].length < 8)){
            name = []
            let first = r.sector.split(" ").slice(0, 2);
            let second = r.sector.split(" ").slice(2)
            name.push(first.join(" "))
            name.push(second.join(" "))
            console.log(first)
            console.log(second)
          }
          this.names.push(name)
        }else{
          this.names.push(r.sector)
        }
        this.nums.push(r.num)
      })
      this.chartOptions = {
        series: [
          {
            name: this.labels.lblNumOfUsers,
            data: this.nums
          }
        ],
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          },
          events: {
            click: function(chart, w, e) {
              console.log(chart)
              console.log(w)
              console.log(e)
              this.goToTraining()
            }
          }
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#546E7A",
          "#26a69a",
          "#D10CE8"
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: 
            this.names
          ,
          labels: {
            style: {
              colors: [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#546E7A",
                "#26a69a",
                "#D10CE8"
              ],
              fontSize: "10px"
            },
             rotate: -80
          }
        }
      };
    })
  }

  
  fetchUsersPerDeps(){
    this.dashboardService.fetchUsersPerDeps("ok").subscribe((result) =>{
      this.result = result
      this.result = this.result.sort((a, b) => (a.num > b.num ? -1 : 1));
      console.log(this.result)
      this.result.forEach((r) => {
        if(r.sector.length > 15){
          let name = r.sector.split(" ")
          if((name[0].length < 8) && (name[1].length < 8)){
            name = []
            let first = r.sector.split(" ").slice(0, 2);
            let second = r.sector.split(" ").slice(2)
            name.push(first.join(" "))
            name.push(second.join(" "))
            console.log(first)
            console.log(second)
          }
          this.names.push(name)
        }else{
          this.names.push(r.sector)
        }
        this.nums.push(r.num)
      })
      this.chartOptions = {
        series: [
          {
            name: this.labels.lblNumOfUsers,
            data: this.nums
          }
        ],
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false
          }
        },
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#546E7A",
          "#26a69a",
          "#D10CE8"
        ],
        plotOptions: {
          bar: {
            columnWidth: "55%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        grid: {
          show: false
        },
        xaxis: {
          categories: 
            this.names,
          labels: {
            style: {
              colors: [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#546E7A",
                "#26a69a",
                "#D10CE8"
              ],
              fontSize: "10px"
            },
             rotate: -75
          }
        }
      };
    })
  }

  goToTraining(){
    this.router.navigateByUrl("/admin/trainings")
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          console.log("USAO U IF")
          this.fetchUsersPerDeps();
          this.fetchDepartments()
        }
        else{
          console.log("USAO U ELSE")
          this.fetchUsersPerDeps1();
          this.fetchAdminsDepartments(this.admin.departmentId);
        }
      }, 100);
    });
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
 
}
