import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class WebRequestService {

  readonly ROOT_URL="https://learning.lesteplatform.com:8443/"
  constructor(private http:HttpClient) { }

  get(uri){
    return this.http.get(`${this.ROOT_URL}/${uri}`)
  }
  post(uri,data){
    return this.http.post(`${this.ROOT_URL}/${uri}`,data)
  }
  patch(uri,data){
    return this.http.patch(`${this.ROOT_URL}/${uri}`,data)
  }
  delete(uri){
    return this.http.delete(`${this.ROOT_URL}/${uri}`)
  }
}
