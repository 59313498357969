<div fxLayout="row">
<div fxFlex="20%" fxFlex.xs="0%" fxFlex.sm="10%"></div>
<div fxFlex="60%" fxFlex.xs="100%" fxFlex.sm="80%">
    <mat-card fxLayout="column">
        <div fxFlex="100%">
        <mat-card-title align="middle" style="margin-bottom: 30px;">
            Change password
        </mat-card-title>
        </div>
        <div fxFlex="100%">
        <form>
        <div fxLayout="column">
            <mat-form-field appearance="fill" fxFlex="60%">
                <mat-label>Old password</mat-label>
                <input matInput ngModel name="old-password" placeholder="Enter old password">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="60%">
                <mat-label>New password</mat-label>
                <input matInput ngModel name="new-password" placeholder="Enter new password">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="60%">
                <mat-label>New password confirm</mat-label>
                <input matInput ngModel name="new-password-confirm" placeholder="Confirm new password">
            </mat-form-field>
            <button mat-raised-button color="primary">Change</button>
        </div>
        </form>
    </div>
    </mat-card>
</div>
<div fxFlex="20" fxFlex.xs="0%" fxFlex.sm="10%"></div>
</div>
