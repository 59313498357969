import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { UserLanguageService } from '../services/user-language-service/user-language.service';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {
  id
  constructor(private router: ActivatedRoute, private languageService: UserLanguageService, public db: AngularFirestore) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.params['id']
    console.log("ID: ")
    console.log(this.id)
    this.updateUserField(this.id)
  }

  updateUserField(id){
    let docRef = this.db.firestore.collection('users').doc(id)
    docRef.update({emailVerified: true})
  }

}
