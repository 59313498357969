<app-user-spinner *ngIf="trainings==null || user==null || labels == null"></app-user-spinner>
<div class="content">
    <div class="container">
        <div class = "row" style = "width: 70%; margin: auto; text-align: center">
            <div class = "col-2 col-sm-4" style = "text-align: left">
                <span>
                    <span [matMenuTriggerFor]="langMenu">
                        <img *ngIf="lang=='srb'" style="cursor: pointer;" class="flag" alt = "serbian flag"
                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FSrpFlgIcon.png?alt=media&token=83f46cdf-2bee-4aa6-b9c8-fe292b4cb930" alt="">
                        <img *ngIf="lang=='cirilica'" style="cursor: pointer;" class="flag" alt = "cyrillic flag"
                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FSrpFlgIcon.png?alt=media&token=83f46cdf-2bee-4aa6-b9c8-fe292b4cb930" alt="">
                        <img *ngIf="lang=='eng'" style="width: 15%; cursor: pointer;" class="flag" alt = "english flag"
                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FEngFlgIcon.png?alt=media&token=f9ada641-9847-48d0-bb8a-544087545606" alt="">
                    </span>
                    <mat-menu #langMenu="matMenu" class = "langMenu" style = "padding-left: 10px;">
                        <button mat-menu-item class="menuButton" (click)="selectLang('srb')">
                            <img class = "flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FSrpFlgIcon.png?alt=media&token=83f46cdf-2bee-4aa6-b9c8-fe292b4cb930" alt="">
                            <span class="aligned-with-icon-lang"> {{labels.lblSerbian}}</span> <mat-icon *ngIf = "lang == 'srb'" style = "vertical-align: middle; padding-left: 30px; color: #0E0D35;">done</mat-icon>
                        </button>
                        <button mat-menu-item class="menuButton" (click)="selectLang('cirilica')" style = "margin-top: 10px;">
                            <img class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FSrpFlgIcon.png?alt=media&token=83f46cdf-2bee-4aa6-b9c8-fe292b4cb930" alt="">
                            <span class="aligned-with-icon-lang"> {{labels.lblSerbianCyrillic}}</span> <mat-icon *ngIf = "lang == 'cirilica'" style = "vertical-align: middle; padding-left: 30px; color: #0E0D35;">done</mat-icon>
                        </button>
                        <button mat-menu-item class="menuButton" (click)="selectLang('eng')" style = "margin-top: 10px;">
                            <img class="flag" style = "width: 46px; height: 46px;"
                                src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FEngFlgIcon.png?alt=media&token=f9ada641-9847-48d0-bb8a-544087545606"
                                alt="">
                            <span class="aligned-with-icon-lang"> {{labels.lblEnglish}}</span> <mat-icon *ngIf = "lang == 'eng'" style = "vertical-align: middle; padding-left: 30px; color: #0E0D35;">done</mat-icon>
                        </button>
                    </mat-menu>
                  </span>
            </div>
            <div class = "col-8 col-sm-4" style = "margin: auto;">
                <img mat-card-image class="card-image" src={{user.profileImagePath}} alt="User profile image">
            </div>
            <div class = "col-2 col-sm-4" style = "text-align: right;">
                <span style = "cursor: pointer; padding-right: 15px;" routerLink = "/user/calendar">
                    <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FcalendarIcon.png?alt=media&token=0dcbbd8e-d153-4851-af32-658f01446fbd" alt = "calendar image">
                  </span>
            </div>
            <div class = "col-12" style = "margin-top: 3%;">
                <h3 class = "font name">{{user.firstName + " "+ user.lastName}}</h3>
            </div>
            <div class = "row" style = "width: 100%; margin: auto; margin-top: 1%;">
                <div class = "col-6 userInfo" style = "text-align: right; border-right: 1px solid #0E0D35">
                    {{user.position}}
                </div>
                <div class = "col-6 userInfo" style = "text-align: left;">
                    {{user.position}}
                </div>
                <div class = "col-12 userInfo" style = "margin-top: 3%; font-size: 17px;">
                    {{user.email}}
                </div>
            </div>
        </div>

        <div class = "row cardsWidth" style = "margin: auto; margin-top: 3%;">
            <div style = "width: 30%; -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); background-color: white;
            border-radius: 20px; margin: auto; margin-left: 0px; padding-top: 25px; padding-bottom: 25px; padding-right: 10px; padding-left: 10px;">
                <div class = "col-12" style = "text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 20px;">
                    {{user.notStarted}}
                </div>
                <div class = "col-12" style = "text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans';">
                    <span style = "font-family: Lato, 'Alegreya Sans'; font-weight: bold;">{{labels.lblNotStarted}}</span>
                </div>
            </div>
            <div style = "width: 30%; -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
            border-radius: 20px; margin: auto; padding-top: 25px; padding-bottom: 25px; padding-right: 10px; padding-left: 10px;">
                <div class = "col-12" style = "text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 20px;">
                    {{user.started}}
                </div>
                <div class = "col-12" style = "text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans';">
                    <span style = "font-family: Lato, 'Alegreya Sans'; font-weight: bold;">{{labels.lblStarted}}</span>
                </div>
            </div>
            <div style = "width: 30%; -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
            border-radius: 20px; margin: auto; margin-right: 0px; padding-top: 25px; padding-bottom: 25px; padding-right: 10px; padding-left: 10px;">
                <div class = "col-12" style = "text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 20px;">
                    {{user.finished}}
                </div>
                <div class = "col-12" style = "text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans';">
                    <span style = "font-family: Lato, 'Alegreya Sans'; font-weight: bold;">{{labels.lblFinished}}</span>
                </div>
            </div>
        </div>

        <div class = "row cardsWidth" style = "margin: auto; margin-top: 3%;">
            <div style = "width: 50%; text-align: left;">
                <button class = "blueBtn" (click) = "openChangePasswordDialog()">{{labels.lblChangePassword}}</button>
            </div>
            <div style = "width: 50%; text-align: right;">
                <button class = "greenBtn" (click) = "openEditProfileDialog()"><img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FeditIcon.png?alt=media&token=aa9980a2-8abc-400e-b350-fa49de7fb0b3" alt = "Edit icon"><span style = "padding-left: 11px; vertical-align: middle;">{{labels.lblChangeLang}}</span></button>
            </div>
        </div>

        <div class = "row" style = "width: 70%; margin: auto; margin-top: 3%; margin-bottom: 3%;">
            <div class = "col-12" style = "padding-left: 0px; color: #0E0D35; opacity: 0.5;font-family: Lato, 'Alegreya Sans'; font-size: 15.5px;">
                {{user.description}}
            </div>
        </div>

    </div>
</div>