<div class="row">
    <div class="col-12 col-sm-6">
        <div class="trainingCard">
            <mat-card style="padding: 20px;">
                <hr>
                <mat-card-content>
                    <div class="row info">
                        <div class="col-4">
                            <h3>{{labels.lblSectorName}}: </h3>
                        </div>
                        <div class="col-8">
                            <h3  style="color: #9dacd9">{{department.name}}</h3>
                        </div>
                    </div>
                    <div class="row info">
                        <div class="col-4">
                            <h3>{{labels.lblAdministrator}}: </h3>
                        </div>
                        <div class="col-8">
                            <h3 style="color: #9dacd9">{{department.adminFullName}}</h3>
                        </div>
                    </div>
                    <div class="row info">
                        <div class="col-4">
                            <h3>{{labels.lblEmail}}: </h3>
                        </div>
                        <div class="col-8">
                            <h3 style="color: #9dacd9">{{department.email}}</h3>
                        </div>
                    </div>
                </mat-card-content>
              </mat-card>
        </div>
    </div>
</div>

