import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from 'src/app/modules/services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service'
import { AuthService } from 'src/app/modules/services/auth-service/auth.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  labels;
  labelsSub: Subscription;

  admin
  adminSub: Subscription

  dashboardClass: String = "notSelectedItem"
  usersClass: String = "notSelectedItem"
  trainingsClass: String = "notSelectedItem"
  departmentsClass: String = "notSelectedItem"
  administratorsClass: String = "notSelectedItem"
  testsClass: String = "notSelectedItem"
  changePassClass: String = "notSelectedItem"
  generateReportsClass: String = "notSelectedItem"
  msgsClass: String = "notSelectedItem"
  sectorsGroupsClass: String = "notSelectedItem"
  trainersClass: String = "notSelectedItem"
  testSurveyClass: String = "notSelectedItem"
  faqClass: String = "notSelectedItem"
  calendarClass: String = "notSelectedItem"
  zoomClass: String = "notSelectedItem"
  usersExpanded = false
  trainingsExpanded = false

  constructor(private adminAuthService: AdminAuthService, public adminLanguageService: AdminLanguageService, private router: Router) { }

  ngOnInit(): void {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
    });

  }

  changeClass(select: String) {
    switch (select) {
      case "dashboard":
        this.dashboardClass = "selectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "user":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "selectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        this.toggleUsers()
        break;
      case "trainings":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "selectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        this.toggleTrainings()
        break;
      case "departments":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "selectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "tests":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "selectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "changepassword":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "selectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "generateReports":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "selectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "messages":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "selectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "sectorsGroups":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "selectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "trainers":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "selectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "testSurvey":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "selectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "faq":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "selectedItem"
        this.administratorsClass = "notSelectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
      case "administrators":
        this.dashboardClass = "notSelectedItem";
        this.usersClass = "notSelectedItem";
        this.trainingsClass = "notSelectedItem";
        this.departmentsClass = "notSelectedItem";
        this.testsClass = "notSelectedItem";
        this.changePassClass = "notSelectedItem";
        this.generateReportsClass = "notSelectedItem";
        this.msgsClass = "notSelectedItem";
        this.sectorsGroupsClass = "notSelectedItem"
        this.trainersClass = "notSelectedItem"
        this.testSurveyClass = "notSelectedItem"
        this.faqClass = "notSelectedItem"
        this.administratorsClass = "selectedItem";
        this.calendarClass = "notSelectedItem";
        this.zoomClass = "notSelectedItem";
        break;
        case "calendar":
          this.dashboardClass = "notSelectedItem";
          this.usersClass = "notSelectedItem";
          this.trainingsClass = "notSelectedItem";
          this.departmentsClass = "notSelectedItem";
          this.testsClass = "notSelectedItem";
          this.changePassClass = "notSelectedItem";
          this.generateReportsClass = "notSelectedItem";
          this.msgsClass = "notSelectedItem";
          this.sectorsGroupsClass = "notSelectedItem"
          this.trainersClass = "notSelectedItem"
          this.testSurveyClass = "notSelectedItem"
          this.faqClass = "notSelectedItem"
          this.administratorsClass = "notSelectedItem";
          this.calendarClass = "selectedItem";
          this.zoomClass = "notSelectedItem";
          break;
          case "zoom":
            this.dashboardClass = "notSelectedItem";
            this.usersClass = "notSelectedItem";
            this.trainingsClass = "notSelectedItem";
            this.departmentsClass = "notSelectedItem";
            this.testsClass = "notSelectedItem";
            this.changePassClass = "notSelectedItem";
            this.generateReportsClass = "notSelectedItem";
            this.msgsClass = "notSelectedItem";
            this.sectorsGroupsClass = "notSelectedItem"
            this.trainersClass = "notSelectedItem"
            this.testSurveyClass = "notSelectedItem"
            this.faqClass = "notSelectedItem"
            this.administratorsClass = "notSelectedItem";
            this.calendarClass = "notSelectedItem";
            this.zoomClass = "selectedItem";
            break;
    }
  }

  toggleTrainings() {
    this.trainingsExpanded = !this.trainingsExpanded
    this.usersExpanded = false
  }

  toggleUsers() {
    this.usersExpanded = !this.usersExpanded
    this.trainingsExpanded = false
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  goToZoomMeetingsPage(){
    const url = 'https://zoom.us/oauth/authorize?response_type=code&client_id=fU1My98QC2ipHpvi5kRXA&redirect_uri=http://localhost:1738/admin/zoom-meetings'
    this.router.navigateByUrl(url)
  }

}
