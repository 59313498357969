<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblDeleteUsr}} "{{data.training.name}}"</h1>
<div mat-dialog-content>
  <p>{{labels.lblDeleteUser}}</p>
</div>
<div mat-dialog-actions style="display: block; text-align: right">
  <!-- <button class="noBtn" mat-button (click)="onNoClick()">{{labels.lblNo}}</button> -->
  <button class="btn" style="float: right; margin-left: 10px; margin-bottom: 20px;" (click)="deleteTraining()" cdkFocusInitial>{{labels.lblDelete}}</button>
  <button class="btn" style="float: right; margin-left: 10px; margin-bottom: 20px;" (click)="deleteTestResults()" cdkFocusInitial>{{labels.lblDeleteTestResults}}</button>
</div>
