import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import { Unit } from '../../models/unit.model'
import { MatDialog } from '@angular/material/dialog';
import { UserBeforePreTestComponent } from '../pre-test/user-before-pre-test/user-before-pre-test.component';
import { TrainingSection } from 'src/app/models/training-section.model';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { Test } from 'src/app/models/test.model';
import { Trainer } from 'src/app/models/trainer.model';
import { DashboardService } from '../dashboard.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { UserDocument } from 'src/app/models/userDocument.model';
import { WelcomeComponent } from '../welcome-page/welcome/welcome.component';

@Component({
  selector: 'app-user-training-details',
  templateUrl: './user-training-details.component.html',
  styleUrls: ['./user-training-details.component.scss'],
  host: { '(click)': 'onClick()'}
})
export class UserTrainingDetailsComponent implements OnInit {

  training$: Observable<Training>
  testData: any
  currentUnit$: Observable<Unit>
  status$: Observable<Number>
  user: User
  userSub: Subscription;
  test$: Observable<Test>
  trainer

  lang
  langSub: Subscription;

  labels;
  labelsSub: Subscription;

  training: Training
  trainingSub: Subscription

  status: Number
  statusSub: Subscription

  unitsOrder$: Observable<Number>
  unitsOrder: Number
  unitsOrderSub: Subscription

  displayOverview: String = "none";
  displayDocuments: String = "none";
  displayComments: String = "block";
  displayInstructor: String = "none";
  displaySections: String = "none"

  overviewButtonClass: String = "buttonSelected";
  documentsButtonClass: String = "button";
  commentsButtonClass: String = "button";
  instructorButtonClass: String = "button";

  currentUnit: Unit
  currentUnitSub: Subscription
  currentSection: TrainingSection

  canDownloadTest: Boolean = false
  canDownloadSurvey: Boolean = false
  canDownloadStatement = false

  comments
  trainingId
  trainingDocuments

  progress$: Observable<Number>
  progress;
  progressSub: Subscription;
  backgroundColor
  buttonStyle
  // loading = true;
  // loaded = false;
  userDocuments:UserDocument[]
  clickedComment: boolean
  clickedCouments: boolean
  trngClicked: boolean

  color1
  color2
  color3
  showSpinner: boolean = true
  @ViewChild('insertForm') insertForm:NgForm
  constructor(public db: AngularFirestore, private dashboardService: DashboardService, private component: UserDashboardComponent, private localStorageService: LocalStorageService, public dialog: MatDialog, private router: Router, private authService: AuthService, private languageService: UserLanguageService, private userDashboarService: UserDashboardService) {
    // router.events.subscribe((event: RouterEvent) => {
    //   this.navigationInterceptor(event)
    // })
   }

  ngOnInit(): void {
    this.langSub = this.languageService.lang$.subscribe((lang) =>{
      this.lang = lang;
    })

    this.showSpinner = true;
      
    this.trainingSub = this.userDashboarService.training$.subscribe((training) => {
      this.training = training;
      console.log("TRAINING: ", this.training)
    })
    
    this.authService.fetchCurrentUser().subscribe((user:User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          console.log("IF")
          this.backgroundColor = "#bbbcbf"
          this.buttonStyle = "buttonEMS"
        }else if(this.user.companyId == 2){
          this.backgroundColor = "#ffe7ef"
          this.buttonStyle = "buttonECG"
        }
        else{
          this.backgroundColor = "#ffe7ef"
          this.buttonStyle = "buttonECG"
        }
        this.overviewButtonClass = ""
        this.documentsButtonClass = ""
        this.commentsButtonClass = ""
        this.instructorButtonClass = ""
        this.userDashboarService.fetchUsersDocuments(user.id).subscribe(response => {
          this.userDocuments = response as UserDocument[]
        })
        this.userDashboarService.fetchTrainingDocuments(user.id, this.localStorageService.getItem('trainingId')).subscribe(response => {
          this.trainingDocuments = response
          console.log("response")
          console.log(response)
        })
       }, 200);

       user.trainings.forEach(usrTr => {
        if(usrTr.trainingId == this.localStorageService.getItem('trainingId')){
          this.canDownloadSurvey = usrTr.surveyFlag
          this.canDownloadTest = usrTr.successful
          if(usrTr.status == 3){
            this.canDownloadStatement = true
          }
          else{
            this.canDownloadStatement = false
          }
        }
      });

    });
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.trainingSub = this.userDashboarService.training$.subscribe((training) => {
      this.training = training;
      setTimeout(() => {
        this.fetchTrainer(this.training.trainerId)
      }, 300);
    })
    
    const trainingId = this.localStorageService.getItem("trainingId")
    const userTestDetailsSub = this.userDashboarService.getUserTestDetails(trainingId).subscribe((testDetails) => {
      this.testData = testDetails;
    })

    this.training$ = this.userDashboarService.getTraining().pipe(share())
    this.trainingId = this.localStorageService.getItem('trainingId');
    this.comments = this.getComments()
    this.status$ = this.userDashboarService.getTrainingStatus().pipe(share())
    this.statusSub = this.userDashboarService.status$.subscribe((status) => {
      this.status = status;
    })

    this.clickedComment = true;
    this.clickedCouments = false;
    this.color1 = "#49D14F"
    this.color2 = "#e5e5e5";
    this.color3 = "#e5e5e5";

    this.currentUnitSub = this.userDashboarService.currentUnit$.subscribe((unit: Unit) => {
      setTimeout(() => {
        this.setCurrentUnit(unit)
        this.showSpinner = false;
    }, 1500);
    }) 

    this.progressSub = this.userDashboarService.progress$.subscribe((progress) => {
      this.progress = progress;
    })
    this.progress$ = this.userDashboarService.getCurrentUsersProgress(this.localStorageService.getItem('trainingId')).pipe(share())

    this.unitsOrder$ = this.userDashboarService.getCurrentUnitsOrder(this.localStorageService.getItem('trainingId')).pipe(share());
    this.unitsOrderSub = this.userDashboarService.unitsOrder$.subscribe((order) => {
      this.unitsOrder = order;
      console.log("units order:", order);
    })
    
  }
  ngAfterViewInit() {
    console.log("AFTER INIT BRE")
    //this.showSpinner = false;
    setTimeout(() => window.scroll(0, 0), 0);
  }

  onActivate(event) {
    setTimeout(() => window.scroll(0, 0), 0);
  }
  
  ondDestroy() {
    this.userSub.unsubscribe();
    this.statusSub.unsubscribe();
  }

  @HostListener("click") onClick(){
    this.component.sideBarOpened = false
  }

  goToHomePage(){
    this.router.navigateByUrl('/user/dashboard')
  }

  goToTraining(training: Training, status: Number) {
    if(this.testData?.isBlocked || this.training.successful) {
      return;
    }
    if(status == 1){
      this.userDashboarService.setTraining(training)
      const dialogRef = this.dialog.open(WelcomeComponent, {
        disableClose: true,
        hasBackdrop: true,
        backdropClass: "blurBackground",
        width: '500px',
      });
    }else{
    console.log(training)
    console.log(this.user.trainings)
    const tr = this.user.trainings.filter((t) => t.trainingId == training.id)[0]
    console.log(tr)
    this.currentUnit$ = this.userDashboarService.getCurrentUnit(training.id).pipe(share());

    if (!tr.pretestFlag && training.pretestId!="") {
      this.localStorageService.setItem("trainingInfo", training);
      this.test$ = this.userDashboarService.getTestForTraining(this.localStorageService.getItem('pretestId')).pipe(share())
      this.router.navigateByUrl('user/start-pretest');
      console.log('ima pretest')
    }
    else {
      this.localStorageService.setItem("trainingInfo", training);
      this.router.navigateByUrl('user/training');
    }
    this.changeStatus(training, 2)
  }
  }


  changeStatus(training: Training, status: Number){
    this.userDashboarService.changeTrainingStatus(training.id, status).subscribe((result)=>{
      console.log(result)
     })
  }

  openTestDialog(): void {
    const dialogRef = this.dialog.open(UserBeforePreTestComponent, {
      disableClose: true,
      width: '350px'
    });
  }

  setCurrentUnit(unit: Unit): void {
    this.currentUnit = unit;
    //this.showSpinner = false;
  }

  fetchTrainer(id) {
    return this.dashboardService.getTrainer(id).subscribe((response) => {
      this.trainer = response as Trainer
    })
  }

  whatToDisplay(string: String) {
    switch (string) {
      case "documents":
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "block";
        this.displayComments = "none";
        this.displayInstructor = "none";
        this.displaySections = "none"
        //buttons
        // this.overviewButtonClass = this.button;
        // this.documentsButtonClass = this.buttonSelected;
        // this.commentsButtonClass = this.button;
        // this.instructorButtonClass = this.button;
        this.clickedComment = false;
        this.clickedCouments = true;
        this.trngClicked = false;
        this.color1 = "#e5e5e5"
        this.color2 = "#49D14F";
        this.color3 = "#e5e5e5"
        break;
      case "comments":
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "none";
        this.displayComments = "block";
        this.displayInstructor = "none";
        this.displaySections = "none"
        this.clickedComment = true;
        this.clickedCouments = false;
        this.trngClicked = false;
        this.color1 = "#49D14F"
        this.color2 = "#e5e5e5";
        this.color3 = "#e5e5e5"
        //buttons
        // this.overviewButtonClass = this.button;
        // this.documentsButtonClass = this.button;
        // this.commentsButtonClass = this.buttonSelected;
        // this.instructorButtonClass = this.button;
        break;
        case "training":
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "none";
        this.displayComments = "none";
        this.displayInstructor = "none";
        this.displaySections = "block"
        this.clickedComment = false;
        this.clickedCouments = false;
        this.trngClicked = true
        this.color1 = "#e5e5e5"
        this.color2 = "#e5e5e5";
        this.color3 = "#49D14F"
        //buttons
        // this.overviewButtonClass = this.button;
        // this.documentsButtonClass = this.button;
        // this.commentsButtonClass = this.buttonSelected;
        // this.instructorButtonClass = this.button;
        break;
    }
  }

  downloadStatement(trId) {
    this.userDashboarService.downloadStatement(trId, this.lang);
  }
  downloadSurveyResults(trId) {
    this.userDashboarService.downloadSurveyResults(trId, this.lang);
  }
  downloadTestResults(trId) {
    this.userDashboarService.downloadTestResults(trId, this.lang);
  }

  getComments(){
    let comments = []
    let docRef = this.db.firestore.collection("trainings").doc(this.trainingId).collection('comments').orderBy('time', 'asc')
    //let userRef = await this.db.firestore.collection("users")
    docRef.onSnapshot(function(snapshot) {
        
        snapshot.docChanges().forEach( function(change) {
            let comment = change.doc.data()
            comment.dateTime = change.doc.data().time
            let timestamp = change.doc.data().time.seconds
            var date = new Date(timestamp*1000);
            var formatDate = date.getDate()+
            "/"+(date.getMonth()+1)+
            "/"+date.getFullYear()+
            " "+date.getHours()+
            ":"+date.getMinutes()
            comment.time = formatDate
            let userId = comment.userId
            //let user = await userRef.doc(userId).get()
            //let imgPath = user.data().profileImagePath
            //comment.profileImagePath = imgPath
            comments.push(comment)
        });
      });
      console.log(comments)
      return comments
  }

  submitComment(){
    if(this.insertForm.value.text != "" && this.insertForm.value.text != null){
      let docRef = this.db.firestore.collection("trainings").doc(this.trainingId).collection('comments').doc()
      let comment = {
        id: docRef.id,
        trainingId: this.trainingId,
        userId: this.insertForm.value.userId,
        fullName: this.insertForm.value.firstName + " " + this.insertForm.value.lastName,
        email: this.insertForm.value.email,
        time: new Date(),
        text: this.insertForm.value.text,
        imagePath: this.user.profileImagePath
    }
    
    docRef.set(comment)
    this.insertForm.controls.text.reset()

    }
  }

}
