import { Position } from '@angular/compiler';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';
import { Administrator } from 'src/app/models/administrator.model';
import { Department } from 'src/app/models/department.model';
import { User } from 'src/app/models/user.model';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  departments:Department[]
  admins: Administrator[]

  labels;
  labelsSub: Subscription;
  err: boolean = false
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService:AdminLanguageService) { }
    @ViewChild('editForm') editForm:NgForm

    
  onSubmit(){
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "" || this.editForm.value.departmentId == "" || this.editForm.value.email == "" ||  this.editForm.value.companyId == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }
    else{
      if(this.emailAlreadyExists(this.editForm.value.email)){
        this.snackBar.open(this.labels.lblEmailAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if(this.userNameAlreadyExists(this.editForm.value.userName)){
        this.snackBar.open(this.labels.lblUserNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else{
        console.log(this.editForm.value)
        this.editUser(this.editForm.value)
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblAdminEdited, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
  }
  onNoClick(): void {
   this.dialogRef.close();
  }
  ngOnInit(): void {
    this.err = false;
    this.fetcDepartments()
    this.getLang()
    this.getAdmins()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  fetcDepartments(){
     return this.dashboardService.fetchAllDepartments().subscribe((response)=>{
       this.departments=response as Department[]
     })
  }
  getAdmins() {
    this.dashboardService.fetchAdministrators().subscribe((response) => {
      this.admins = response as Administrator[]
      console.log(this.data.id)
      this.admins = this.admins.filter((user) => user.id != this.data.id)
      console.log(this.admins)
    })
  }

  editUser(data){
    this.dashboardService.editAdmin(data).subscribe((response)=>{
      console.log(response)
    })
  }


  emailAlreadyExists(email: string): boolean {
    for (let index = 0; index < this.admins.length; index++) {
      if (this.admins[index].email == email) {
        return true
      }
    }
    return false
  }

  userNameAlreadyExists(userName: string): boolean {
    for (let index = 0; index < this.admins.length; index++) {
      if (this.admins[index].userName == userName) {
        return true
      }
    }
    return false
  }
  
}
