import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-delete-section-dialog',
  templateUrl: './delete-section-dialog.component.html',
  styleUrls: ['./delete-section-dialog.component.scss']
})
export class DeleteSectionDialogComponent implements OnInit {

  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
    this.labels = labels;
      })
  }

  onNoClick(){
    this.dialogRef.close()
  }

  deleteTest(){
    this.dashboardService.deleteTestForSection(this.data).subscribe((response)=>{
      console.log(response)
    })
    this.snackBar.open(this.labels.lblTestDeleted, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
     this.dialogRef.close()
  }

}
