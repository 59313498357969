<app-admin-spinner *ngIf="dataSource == null || labels == null || department == null"></app-admin-spinner>
<div class="row" style="margin: auto">
  <div class="col-11 col-xl-7 sectorInfo" style="margin: auto; margin-bottom: 3%;">
      <div class="trainingCard" style="background-color:#182038 !important">
        <h2 class="basicInfo">{{labels.lblBasicInfo}}</h2>
          <mat-card style="padding-top: 0px;">
              <mat-card-content>
                  <div class="row info">
                      <div class="col-12 col-lg-6">
                          <h3>{{labels.lblSectorName}}: </h3>
                      </div>
                      <div class="col-12 col-lg-6 addNewSectionBtn">
                          <h3  style="color: #9dacd9;">{{department.name}}</h3>
                      </div>
                  </div>
                  <!-- <div class="row info">
                      <div class="col-6">
                          <h3>{{labels.lblAdministrator}}: </h3>
                      </div>
                      <div class="col-6">
                          <h3 style="color: #9dacd9; float: right">{{department.adminFullName}}</h3>
                      </div>
                  </div> -->
                  <div class="row info">
                      <div class="col-12 col-lg-6">
                          <h3>{{labels.lblEmail}}: </h3>
                      </div>
                      <div class="col-12 col-lg-6 addNewSectionBtn">
                          <h3 style="color: #9dacd9;">{{department.email}}</h3>
                      </div>
                  </div>
                  <div class="row info" >
                    <div class="col-12 text-right">
                      <button mat-raised-button class="tableBtn"  (click)="openEditSectorDialog(department)">{{labels.lblEditSector}}</button>
                    </div>
                 </div>
              </mat-card-content>
            </mat-card>
      </div>
  </div>
</div>



<div class="font table1" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxFlex="5"></div>
    <div fxFlex="90">
      <!-- <mat-card class="header">
          <div fxLayout="row" fxFlex="100">
            <mat-card-title fxFlex="90">{{labels.lblUsers}}</mat-card-title>
              <mat-card-content fxFlex="10" fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                <button  mat-raised-button class="tableBtn" (click)="openInsertDialog()">{{labels.lblAddNewUser}}</button>
              </mat-card-content>
          </div>
        </mat-card> -->
      <div class="mat-elevation-z8">
        <div class="row header">
          <div class="col-12 col-xl-5 text-left">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb"  style="background-color: #182038; font-size: 14.5px;">
                <li class="breadcrumb-item"><a style="color: #8293c6" routerLinkActive="list-item-active" routerLink="/admin/departments">{{labels.lblSectors}}</a></li>
                <li class="breadcrumb-item active" aria-current="page" style="color: #9dacd9">{{department.name}}</li>
                </ol>
          </nav>
        </div>
          <div class="col-12 col-xl-7 text-right">
            <button mat-raised-button class="tableBtn" style="margin-right: 20px;"(click)="openInsertDialog()">{{labels.lblAddNewUser}}</button>
            <button mat-raised-button class="tableBtn addListBtn" (click)="openAddListDialog()">{{labels.lblAddList}}</button>
          </div>
          <div class="col-10 text-left search1">
            <mat-form-field class="search" appearance="none" fxFlex="40%">
              <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
            </mat-form-field>
        </div>
        </div>
        <table matSortDisableClear="true" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  
          <!-- userName Column -->
          <ng-container matColumnDef="userName" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblUserName}} </th>
            <td mat-cell *matCellDef="let user" style="cursor: pointer;" class="userName" [routerLink]="['/admin/user-profile',user.id]"> {{user.userName}} </td>
          </ng-container>
  
          <!-- firstName Column -->
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn"> {{labels.lblFirstName}} </th>
            <td mat-cell *matCellDef="let user" class="hideColumn"> {{user.firstName}} </td>
          </ng-container>
  
          <!-- LastName Column -->
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn"> {{labels.lblLastName}} </th>
            <td mat-cell *matCellDef="let user" class="hideColumn"> {{user.lastName}} </td>
          </ng-container>
  
          <!-- Symbol Column -->
          <ng-container matColumnDef="departmentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblSectorGroup}}</th>
            <td mat-cell *matCellDef="let user" class="hideColumn userName" [routerLink]="['/admin/usersInDep', department.id]"> {{user.departmentName}} </td>
          </ng-container>
          <!-- style="color: #19a97e" -->
          <!--Actions column-->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="options"> {{labels.lblOptions}} </th>
            <td mat-cell *matCellDef="let user" class="options">
              <button class="addNewBtn d-sm-inline-flex" (click)="openUserTrainingsDialog(user)">
                {{labels.lblAssignTraining}}
              </button>
              <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
              <mat-menu #settingsMenu="matMenu" class="mMenu">
                <button mat-menu-item class="menuButton settingsMenuItem" (click)="openEditDialog(user)">
                  <mat-icon style="color: #8293c6;">edit</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                </button>
                <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(user)">
                  <mat-icon style="color: #8293c6;">delete</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                </button>
              </mat-menu>
  
              <!-- mobile -->
              <!-- <button class="editBtn d-block d-sm-none mobBtn" (click)="openEditDialog(user)">
                {{labels.lblEdit}}
              </button>
              <button class="deleteBtn d-block d-sm-none mobBtn" (click)="openDeleteDialog(user)">
                {{labels.lblDelete}}
              </button>
              <button class="addNewBtn d-block d-sm-none mobBtn" (click)="openUserTrainingsDialog(user)">
                {{labels.lblAssignTraining}}
              </button> -->
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
  
      </div>
    </div>
    <div fxFlex="5"></div>
  </div>

  