import { Component, OnInit } from '@angular/core';
import { AngularFirestore} from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss']
})
export class UserNotificationComponent implements OnInit {

  constructor(private languageService:UserLanguageService, private db: AngularFirestore, private authService: AuthService,
    public dialog: MatDialog, public dialogRef: MatDialogRef<UserNotificationComponent>) { }
  user: User
  userSub: Subscription;
  notifications
  labels;
  labelsSub: Subscription;
  limit
  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) =>{
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
    });
    this.limit = 4
    this.notifications = this.getNotifications2(this.limit)
    // this.setToSeen()
  }

  getNotifications2(limit){
    console.log("user")
    console.log(this.user.id)
    let notificationList = []
    let docRef = this.db.firestore.collection('users').doc(this.user.id).collection('userNotifications').where("type", "==", 2).orderBy("timestamp","desc").limit(limit)
    docRef.onSnapshot(function(snapshot) {
      snapshot.docChanges().forEach(function(change) {
        let notification = change.doc.data()
        notification.id = change.doc.id
        notification.timestamp = change.doc.data().timestamp
        let time = change.doc.data().timestamp.seconds
        var date = new Date(time*1000);
        var formatDate = date.getDate()+
        "/"+(date.getMonth()+1)+
        "/"+date.getFullYear()+
        " "+date.getHours()+
        ":"+date.getMinutes()
        notification.timestamp = formatDate
        console.log(notification)
        notificationList.push(notification)
      })
    })
    setTimeout(() => {
      this.notifications = notificationList
      console.log(this.notifications)
      return this.notifications
    }, 2000);
  }

  loadMore(){
    this.limit += 2;
    this.getNotifications2(this.limit);
  }

  setToSeen(){
    let docRef = this.db.firestore.collection('users').doc(this.user.id).collection('userNotifications')
    docRef.onSnapshot(function(snapshot) {
      snapshot.docChanges().forEach(function(change) {
        let notification = change.doc.data()
        if(notification.seen == false){
          notification.id = change.doc.id
          let docRef2 = docRef.doc(change.doc.id)
          docRef2.update({
            seen: true
          })
        }
      })
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }
  
}
