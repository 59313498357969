<footer>
    <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
        <div class = "col-1 col-sm-4">

        </div>
    <div class = "col-10 col-sm-4 footerRow" style = "text-align: center">
        <!-- <div class = " lstItem">
            <div class = "row itemContainer" [class] = "faqPage" (click) = "changeColor('faq')" routerLink="/user/rang-list" style = "margin: auto; text-align: center">
                <div class = "col-12">
                    <img *ngIf = "faqPage == 'notActive'" style = "width: 30px; height: 30px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/rangListGrey.png?alt=media&token=f78d7c85-ef89-4362-9ae8-e7b5ac715407">
                    <img *ngIf = "faqPage == 'active'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FrangListGreen.png?alt=media&token=7b97c70e-250b-4ca2-959e-0b10655c7bbf">
                </div>
                <div class = "col-12 footerLabel">
                    {{labels.lblRangList}}
                </div>
            </div>
        </div> -->
        <div class = " lstItem">
            <div class = "row itemContainer" [class] = "calendar" (click) = "changeColor('notifications')" style = "margin: auto; text-align: center">
                <div class = "col-12 imgCol">
                    <img class = "img" *ngIf = "calendar == 'notActive'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FVectornotificationsIconBlack.png?alt=media&token=2ed285d7-8994-4580-b144-b3d2bad57b54" alt = "Notification icon">
                    <img class = "img" *ngIf = "calendar == 'active'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FnotifGreen.png?alt=media&token=5f6ed3d0-8e5b-4b2d-8ce3-2f519f885f50" alt = "Notification icon">
                </div>
                <div class = "col-12 footerLabel">
                   {{labels.lblNotifications}}
                </div>
            </div>
        </div>
        <div class = "lstItem">
            <div class = "row itemContainer" [class] = "homePage" (click) = "changeColor('home')" routerLink="/user/dashboard" style = "margin: auto; text-align: center">
                <div  class = "col-12 imgCol">
                    <img class = "img" *ngIf = "homePage == 'notActive'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FGrouptrainingsIconBlack.png?alt=media&token=f8d90720-6afb-411b-a1ef-9cd2dc41103b" alt = "Training icon">
                    <img class = "img" *ngIf = "homePage == 'active'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrainingsGreen.png?alt=media&token=05c2a9e2-61bb-41eb-aead-1bf3534075c9" alt = "Training icon">
                </div>
                <div class = "col-12 footerLabel">
                    {{labels.lblTrainings}}
                </div>
            </div>
        </div>
        <!-- <div class = "lstItem">
            <div class = "row itemContainer" [class] = "messagesPage" (click) = "changeColor('messages')" routerLink="/user/messages" style = "margin: auto; text-align: center">
                <div class = "col-12">
                    <img *ngIf = "messagesPage == 'notActive'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FchatGrey.png?alt=media&token=e3c4c184-7beb-4a66-a198-3b685acb4057">
                    <img *ngIf = "messagesPage == 'active'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FchatGreen.png?alt=media&token=60515b54-36f7-4097-8095-195ee09caef8">
                </div>
                <div class = "col-12 footerLabel">
                    {{labels.lblChatRoom}}
                </div>
            </div>
        </div> -->
        <div class = " lstItem">
            <div class = "row itemContainer" [class] = "profilePage" (click) = "changeColor('profile')" routerLink="/user/user-profile" style = "margin: auto; text-align: center">
                <div class = "col-12 imgCol">
                    <img class = "img" *ngIf = "profilePage == 'notActive'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FVectorprofileIconBlack.png?alt=media&token=6a84075c-7435-4420-8b26-8b41e4e9c9ca" alt = "Profile icon">
                    <img class = "img" *ngIf = "profilePage == 'active'" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FprofileGreen.png?alt=media&token=43a87488-f8cc-48a0-b286-1174dbd6a2c0" alt = "Profile icon">
                </div>
                <div class = "col-12 footerLabel">
                    {{labels.lblProfile}}
                </div>
            </div>
        </div>
    </div>
    <div class = "col-1 col-sm-4"></div>
    </div>
</footer>