<mat-drawer-container style = "height: 100%;">
  <mat-drawer-content>
    <div style = "width: 65%; margin: auto;">
        <!-- <div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
      <div class="col-11"></div>
      <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon>
      </div>
      </div> -->
    <div style="text-align: center; margin-top: 1.5%;">
      <!-- <img class="lesteLogo" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/LogoLESTE.png?alt=media&token=f8976808-1888-4484-948b-b6f268bdbee3"> -->

      <!-- NIS LOGO -->
      <img class="lesteLogo"
      width="350px"
        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/logo%20za%20login.png?alt=media&token=76b67278-2605-4de3-b4f2-5a00cefc77ca" alt = "nis logo">

      <!-- EMS LOGO -->
      <!-- <img class="emsLogo" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webImgs%2FEMS-logo.png?alt=media&token=3f718d73-023e-4c42-a778-eea9a52cefea"> -->
    </div>
    <h3 style="margin-top: 3%; margin-bottom: 5%; text-align: center; font-size: 4.9vh; font-weight: bold;">
      {{labels.lblTermsOfUse}}</h3>

    <!-- <pdf-viewer
      [src]="source"
      [render-text]="true"
      [show-borders]="false"
      style = "display: block; height: 250px; position: relative; box-shadow: 0px 10px 35px rgba(111, 126, 201, 0.25);"
      class = "pdfViewer"></pdf-viewer> -->

    <div class="pdfViewer"
      style="height: 370px; position: relative; overflow-y: scroll; overflow-x: hidden; background-color: transparent; font-family: Lato, 'Alegreya-Sans'; font-size: 18.5px;">
      <p style="color: #0E0D35; opacity: 0.5;">
       <b>Обавештење о обради података личности</b> <br><br>
Привредно друштво ”ДРУШТВО ЗА ИСТРАЖИВАЊЕ, ПРОИЗВОДЊУ, ПРЕРАДУ,
ДИСТРИБУЦИЈУ И ПРОМЕТ НАФТЕ И НАФТНИХ ДЕРИВАТА И ИСТРАЖИВАЊЕ И
ПРОИЗВОДЊУ ПРИРОДНОГ ГАСА НАФТНА ИНДУСТРИЈА СРБИЈЕ А.Д. НОВИ САД
(НИС а.д. Нови Сад), МБ: 20084693, са седиштем у Новом Саду, на адреси Народног фронта
бр. 12, има својство заједничког руковаоца у смислу члана 43. став 1. Закона о заштити
података о личности (”Сл. Гласник РС”, бр. 87/2018, у даљем тексту ЗЗПЛ), са сваким
понаособ од следећих привредних друштава: привредно друштво ”Нафтагас-Технички сервиси
д.о.о. Зрењанин” МБ: 20801794, са седиштем у Зрењанину на адреси Београдска 26; привредно
друштво ”Нафтагас-Нафтни сервиси д.о.о. Нови Сад” МБ: 20801786, са седиштем у Новом
Саду на адреси Пут Шајкашког одреда 9; привредно друштво ”Нафтагас-Транспорт д.о.о. Нови
Сад” МБ: 20829923 са седиштем у Новом Саду, на адреси Народног фронта 12; привредно
друштво ”Научно-технолошки центар НИС-Нафтагас д.о.о. Нови Сад” МБ: 20802421 са
седиштем у Новом Саду, на адреси Народног фронта 12 и привредно друштво ”НИС Петрол
а.д. Београд” МБ: 20094630 са седиштем у Београду, на адреси Маршала Бирјузова 3-5, и
Агенција за привремено запошљавање Manpower Business Solutions d.o.o., са седиштем у
Београду, ул. Булевар Милутина Миланковића11Г, матични број: 20666528.<br>
Полазећи од основних начела, НИС а.д. Нови Сад у име заједничких руковаоца, поступајући у
својству руковаоца података личности (”Руковалац”) овим путем обавештава запослене и
радно ангажована лица о свим битним аспектима прикупљања и обраде њихових података о
личности.
Полазећи од основних начела, Руковалац овим путем обавештава лица чији се подаци обрађују
о свим битним аспектима прикупљања и обраде њихових података о личности.<br>
<b>1. Шта је податак о личности?</b><br>
Сваки податак који се односи на физичко лице и идентификује то лице, непосредно или
посредно, а посебно на основу ознаке идентитета, као што је име и идентификациони број,
податак о локацији, идентификатор у електронским комуникационим мрежама или један,
односно више обележја његовог физичког, физиолошког, генетског, менталног, економског,
културног и друштвеног идентитета.<br>
<b>2. Које податке о личности обрађује Руковалац?</b><br>
Име и презиме запослених и радно-ангажованих лица Руковаоца, назив радног места, позиција,
службена е-маил адреса, САП број, име и презиме непосредног руководиоца, службена е-маил
адреса руководиоца.<br>
<b>3. Шта је правни основ обраде?</b><br>
Подаци о личности обрађују се на основу правних прописа, као и уговорног-правног односа
између Руковаоца и радно-ангажованих лица.<br>
<b>4. Која је сврха обраде података о личности?</b><br>
Руковалац обрађује податке о личности у сврху обучавања запослених и радно-ангажованих
лица.<br>
<b>5. Како се чувају подаци о личности и које мере заштите се примењују?</b><br>
Руковалац података о личности ће на одговарајући начин заштитити податке о личности из
тачке 1. овог Обавештења од злоупотребе, уништења, губитка, неовлашћених промена или
приступа, односно предузеће све неопходне техничке, кадровске и организационе мере

заштите података, у складу са утврђеним стандардима и поступцима, као и да утврди обавезу
лица која су запослена на обради, да чувају тајност података.<br>
<b>6. Која права има лице чији се подаци обрађују?</b><br>
У односу на податке о личности, лице чији се подаци обрађују има следећа права:
- право да од Руковаоца затражи приступ подацима о личности и информацијама које се тичу
обраде укључујући право на достављање копије података које Руковалац обрађује (члан 26.
ЗЗПЛ);<br>
- право да затражи исправку нетачно унетих података и допуну тих података (члан 29. ЗЗПЛ);<br>
- право да затражи брисање података (члан 30. ЗЗПЛ);<br>
- право на ограничење обраде (члан 31. ЗЗПЛ);<br>
- право на преносивост података (члан 36. ЗЗПЛ);<br>
- право на приговор (уколико је основ обраде легитиман интерес руковаоца или треће стране,<br>
односно обављање послова у јавном интересу или извршење законом прописаних овлашћења
Руковаоца, члан 37. ЗЗПЛ);<br>
- право на опозив пристанка (уколико је основ за обраду пристанак лица чији се подаци о
личности обрађују, лан 15. ЗЗПЛ) – Опозив пристанка не утиче на допуштеност обраде које је
вршена на основу пристанка пре опозива,<br>
- право да се на њега или њу не примењује одлука донета искључиво на основу
аутоматизоване обраде, укључујући и профилисање, ако се том одлуком производе правне
последице по то лице или та одлука значајно утиче на његов/њен положај (члан 38. ЗЗПЛ);<br>
- право да буде обавештен о повреди података о личности, ако та повреда података о личности
може да произведе висок ризик по права и слободе физичких лица (члан 53. ЗЗПЛ);<br>
- право подношења притужбе Поверенику за приступ информацијама од јавног значаја и
заштиту података о личности;<br>
- право на судску заштиту ако сматра да су му/јој повређена права из ЗЗПЛ (члан 84. ЗЗПЛ);<br>
- друга права гарантована важећим ЗЗПЛ.<br>
Лице на које се подаци односе може се обратити Руковаоцу Захтевом за остваривање права,
чији је образац објављен на порталу Друштва. Лице захтев може поднети у писаном или
електронском облику, на званичну поштанску адресу Друштва (Народног фронта број 12, Нови
Сад) или на официјелну адресу електронске поште Функције за корпоративну заштиту
(fkz@nis.rs).<br>
Руковалац ће у односу на остваривање права пружити лицу чији су подаци прикупљени све
неопходне додатне информације, као и помоћ, у складу са условима и на начин прописан
важећим ЗЗПЛ.<br>
<b>7. Извоз података о личности са територије Републике Србије у другу државу
  или међународну организацију</b>
Подаци о личности се не износе у другу државу или део њене територије или међународну
организацију.<br>
<b>8. У ком року се чувају подаци о личности?</b><br>
Подаци о личности се чувају 10 година од тренутка одслушане-завршене обуке.<br>
<b>9. Контакт подаци за добијање додатних обавештења о обради</b><br>
Лице чији се подаци обрађују, у вези са свим питањима која се односе на обраду личних
података укључујући и начин остваривања права у увид у документе, се може обратити
Функцији за корпоративну заштиту, достављањем на e-mail адресу Функције fkz@nis.rs или на
званичну адресу НИС а.д. Нови Сад nis@nis.rs.
<br><br>
У Београду, 2022. године<br>
Ања Мученски<br>
Руководилац Сектора за тренинг и развој<br>
У ИМЕ ЗАЈЕДНИЧКИХ РУКОВАОЦА
      </p>
      <div class="row fadeOut" style="width: 100%; position: sticky -webkit-sticky; bottom: 0">
        <button class="login-button" (click)="acceptTerms()">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-9" style="margin: auto; text-align: center;">
              <h3 class="loginBtnLabel">{{labels.lblAccept}}</h3>
            </div>
            <div class="col-1"><img style="float: right; padding-right: 15px;"
                src='https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/loginIcon.png?alt=media&token=331da5b1-d77f-4c81-89a1-0e35b3feea30' alt = "login icon">
            </div>
          </div>
        </button>
      </div>
    </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>