import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth-service/auth.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
//import { ZoomMtg } from '@zoomus/websdk';
@Component({
  selector: 'app-chat-zoom-meeting',
  templateUrl: './chat-zoom-meeting.component.html',
  styleUrls: ['./chat-zoom-meeting.component.scss']
})
export class ChatZoomMeetingComponent implements OnInit {
  signatureEndpoint = 'http://localhost:1738/user/zoom-meeting'
  apiKey = '984gQm5jSnaZ2wFJ16TrGA'
  apiSecret = '5h3I41usJymgjzBSPfFAGFxelkJGkRbH0fdZ'
  meetingNumber
  // leaveUrl = 'http://localhost:1738/user/messages'
  leaveUrl = "https://zoom.us/oauth/authorize?response_type=code&client_id=fU1My98QC2ipHpvi5kRXA&redirect_uri=http%3A%2F%2Flocalhost%3A1738%2Fuser%2Fmessages"
  userName = 'Klijent'
  userEmail = ''
  passWord = 'testPass'
  registrantToken = ''
  signature
  meetingId
  attendees
  userSub: Subscription
  user
  userId
  constructor(private languageService: UserLanguageService,
    public db: AngularFirestore, public snackBar: MatSnackBar,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.userSub = this.authService.user$.subscribe((user) => {
      this.user = user;
     setTimeout(() => {
       let role = "1"
       let meetingHost = false
       this.db.firestore.collection("users").doc(this.user.userId).get().then((doc) => {
          let user = doc.data()
          console.log("user: ", user)
          meetingHost = doc.data().meetingHost
          if(meetingHost == true){
            console.log("USAO U IF")
           role = "1"
          }else{
            console.log("USAO U ELSE")
           role = "0"
          }
          //this.getSignature(user.currentMeetingId,user.userName, role)
       })
      this.userId = this.user.userId
      this.db.firestore.collection("users").doc(this.user.userId).update({onMeeting: true})
     }, 300);
    });

    // ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
    //   console.log("ON USER LEAVE")
    //   console.log(data);
    //   this.db.firestore.collection("users").doc(this.userId).update({onMeeting: false, meetingCall: false, meetingHost: false})
    // });
  }

  // getSignature(meetingId, userName, role) {
  //   console.log("ROLE", role)
  //   console.log("meeting id: ", meetingId)
  //   console.log("username: ", userName)
  //   ZoomMtg.preLoadWasm();
  //   ZoomMtg.prepareJssdk();
  //   console.log("meeting id: ", meetingId)
  //   this.signature = ZoomMtg.generateSignature({
  //    meetingNumber: String(meetingId),
  //    apiKey: this.apiKey,
  //    apiSecret: this.apiSecret,
  //    role: role,
  //    success: res => {
  //      console.log(res.result)
  //    }
  //   });
  //   this.startMeeting(this.signature, meetingId, userName)
  //  }
 
  //  startMeeting(signature, meetingId, userName) {
  //    console.log(signature)
  //    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.7/lib', '/av');
  //    ZoomMtg.preLoadWasm();
  //    ZoomMtg.prepareJssdk();
     //document.getElementById('zmmtg-root').style.display = 'block'
    //  document.getElementById('zmmtg-root').style.width = '500px'
    //  document.getElementById('zmmtg-root').style.height = '500px'
    //  document.getElementById('zmmtg-root').style.zIndex = '999'
  //    document.getElementById('containerId').style.height = '0px';
  //    ZoomMtg.init({
  //      leaveUrl: this.leaveUrl,
  //      success: (success) => {
  //        console.log(success)
         
  //        ZoomMtg.join({
  //          signature: signature,
  //          meetingNumber: meetingId,
  //          userName: userName,
  //          apiKey: this.apiKey,
  //          passWord: this.passWord,
  //          success: (success) => {
  //            console.log(success)
  //            console.log("GET ATTENDEES LIST: ")
  //            ZoomMtg.getAttendeeslist({
  //             success: function (res) {
  //               console.log(res, "get getAttendeeslist");
  //             }
  //           });
  //          },
  //          error: (error) => {
  //            console.log("ERROR 1")
  //            console.log(error)
  //          }
  //        })
 
  //      },
  //      error: (error) => {
  //       console.log("ERROR ")
  //        console.log(error)
  //      }
  //    })
  //  }
}
