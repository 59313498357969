import { EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { TrainingSection } from 'src/app/models/training-section.model';
import { Training } from 'src/app/models/training.model';
import { Unit } from 'src/app/models/unit.model';
import { User } from 'src/app/models/user.model';
import { VideoScore } from 'src/app/models/videoScore.model';
import { AuthService } from '../services/auth-service/auth.service';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import { UserTrainingMessageComponent } from './user-training-message/user-training-message/user-training-message.component'
import { UserBeforeTestMessageComponent } from './user-before-test-message/user-before-test-message.component'
import { UserBeforeSurveyMessageComponent } from './../survey/user-before-survey-message/user-before-survey-message.component';
import { ControlQuestionAnswers } from 'src/app/models/controlQuestionAnswers.model';
import { ControlQuestion } from 'src/app/models/controlQuestion.model';
import { ControlCorrectComponent } from './control-correct/control-correct.component';
import { ControlFalseComponent } from './control-false/control-false.component';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { Track } from 'ngx-audio-player';
import { SectionTestComponent } from '../section-test/section-test.component';
import { MessageBeforeSectionTestComponent } from '../section-test/message-before-section-test/message-before-section-test.component';
import { UserDocument } from 'src/app/models/userDocument.model';
import { NgForm } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { Trainer } from 'src/app/models/trainer.model';
import { ControlQuestionComponent } from './control-question/control-question.component';

export interface controlQuestion {
  text: string
  type: number
}
@Component({
  selector: 'app-user-training',
  templateUrl: './user-training.component.html',
  styleUrls: ['./user-training.component.scss'],
  host: { '(click)': 'onClick()' }
})
export class UserTrainingComponent implements OnInit {
  audio = new Audio()

  user: User
  userSub: Subscription;

  lang
  langSub: Subscription;

  labels;
  labelsSub: Subscription;

  training$: Observable<Training>
  training: Training
  trainingSub: Subscription

  userScores$: Observable<VideoScore[]>
  userScores: VideoScore[]
  scoresSub: Subscription

  currentUnit$: Observable<Unit>
  currentUnit: Unit
  currentUnitSub: Subscription

  currentSection: TrainingSection
  path: string = ""
  videoPath: string = ""
  imagePath: string = ""
  documentPath: string = ""
  text: string = ""
  audioPath: string = ""
  test$: Observable<Test>

  progress$: Observable<Number>
  progress: Number
  progressSub: Subscription
  supposedCurrentTime: number

  arrowBackClass: string = "arrowBack"

  audioPlayBtn: boolean = true;
  audioPauseBtn: boolean = false;
  
  @ViewChild('player') player: ElementRef;
  @ViewChild('player1') player1: ElementRef;
  playPauseBtn: string = "play_arrow"

  playerClass: string = ""
  controlClass: string = ""
  checked: boolean = false;
  flg: boolean = false;
  showControl: boolean = false;
  usersExpanded = false
  playlist: Track[]
  showVideo: boolean
  pausePlayBtn
  videoTransparent
  pausePlayHover
  status$: Observable<Number>
  status: Number
  statusSub: Subscription
  backgroundColor
  buttons
  button
  audioFinished
  documentDownloaded

  comments
  trainingId
  trainingDocuments
  userDocuments:UserDocument[]
  clickedComment: boolean
  clickedCouments: boolean
  trngClicked: boolean
  color1
  color2
  color3
  displayOverview: String = "none";
  displayDocuments: String = "none";
  displayComments: String = "none";
  displaySections: String = "block";
  displayInstructor: String = "none";
  displayDocumentsUnit: String = "none";
  canDownloadTest: Boolean = false
  canDownloadSurvey: Boolean = false
  canDownloadStatement = false

  trainer:Trainer
  trainerSub:Subscription
  trainer$

  visitedUnits = []
  showText: boolean = true;
  showSpinner: boolean = true;
  spinnerColor: string = "#49D14F";
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  @ViewChild('insertForm') insertForm:NgForm
  constructor(public db: AngularFirestore, public dialog: MatDialog, private userDashboarService: UserDashboardService, private router: Router, private authService: AuthService, private languageService: UserLanguageService, private localStorageService: LocalStorageService, private component: UserDashboardComponent) {
    this.supposedCurrentTime = 0
  }

  ngOnInit(): void {
    this.audioFinished = false
    this.documentDownloaded = false
    this.playlist = []
    this.langSub = this.languageService.lang$.subscribe((lang) =>{
      this.lang = lang;
    })
    this.training$ = this.userDashboarService.getTraining().pipe(share());

    this.trainingSub = this.userDashboarService.training$.subscribe((training) => {
      this.training = training;
    })

    this.currentUnit$ = this.userDashboarService.getCurrentUnit(this.localStorageService.getItem('trainingId')).pipe(share());

    this.currentUnitSub = this.userDashboarService.currentUnit$.subscribe((unit: Unit) => {
      setTimeout(() => {
        this.setCurrentUnit(unit)
        this.showSpinner = false;
      },3000);
    })

    this.trainer$ = this.userDashboarService.getTrainerForTraining(this.localStorageService.getItem('trainingId')).pipe(share());
    this.trainerSub = this.userDashboarService.trainer$.subscribe((trainer) => {
      this.trainer = trainer;
      console.log(trainer)
    })

    this.clickedComment = false;
    this.clickedCouments = false;
    this.trngClicked = true;
    this.color1 = "#e5e5e5"
    this.color2 = "#e5e5e5";
    this.color3 = "#49D14F"

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      console.log(this.user)
      setTimeout(() => {
        // if(this.user.companyId == 1){
        //   this.backgroundColor = "#bbbcbf"
        //   this.buttons = "buttonEMSGray"
        //   this.button = "buttonEMS"

        // }else if(this.user.companyId == 2){
        //   this.backgroundColor = "rgb(241,106,100)"
        //   this.buttons = "buttonECG"
        //   this.button = "buttonECG"
        // }
        // else{
        //   this.backgroundColor = "rgb(241,106,100)"
        //   this.buttons = "buttonECG"
        //   this.button = "buttonECG"
        // }
        this.userDashboarService.fetchUsersDocuments(user.id).subscribe(response => {
          this.userDocuments = response as UserDocument[]
        })
        this.userDashboarService.fetchTrainingDocuments(user.id, this.localStorageService.getItem('trainingId')).subscribe(response => {
          this.trainingDocuments = response
          console.log(response)
        })
        
       user.trainings.forEach(usrTr => {
        if(usrTr.trainingId == this.localStorageService.getItem('trainingId')){
          this.canDownloadSurvey = usrTr.surveyFlag
          this.canDownloadTest = usrTr.successful
          if(usrTr.status == 3){
            this.canDownloadStatement = true
          }
          else{
            this.canDownloadStatement = false
          }
        }
      });
      
      }, 500);
    });

    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.progress$ = this.userDashboarService.getCurrentUsersProgress(this.localStorageService.getItem('trainingId')).pipe(share());
    this.progressSub = this.userDashboarService.progress$.subscribe((progress) => {
      this.progress = progress;
    })
   
    this.scoresSub = this.authService.usersScores$.subscribe((scores) => {
      this.userScores = scores;
    })
    this.trainingId = this.localStorageService.getItem('trainingId');
    this.comments = this.getComments()
    this.userScores$ = this.authService.getCurrentUsersVideoScores(this.localStorageService.getItem('trainingId')).pipe(share());

    this.checked = false;

    this.localStorageService.removeItem('test')
    this.localStorageService.removeItem('survey')
    this.localStorageService.removeItem('pretest')
    this.pausePlayBtn = this.labels.playButton
    this.videoTransparent = "videoTransparent"
    this.pausePlayHover = "playBtn"
    this.status$ = this.userDashboarService.getTrainingStatus().pipe(share())
    this.statusSub = this.userDashboarService.status$.subscribe((status) => {
      this.status = status;
    })

    
  }
  @HostListener("click") onClick() {
    this.component.sideBarOpened = false
  }

  setCurrentUnit(unit: Unit): void {
    console.log("POZVAO SET CURRENT UNIT")
    console.log("CURRENT UNIT: ", unit)
    console.log("CURRENT UNIT SECTION NAME: ", unit.sectionName)
    this.currentUnit = unit;
    this.getUnitIcons()
    this.visitedUnits.push(this.currentUnit)
    this.showText = true;
    console.log(this.currentUnit)
    this.playlist = []
    this.showControlQuestion()
    if(unit.videoPath != null){
      this.videoPath = unit.videoPath;
      this.showVideo = true;
      this.playerClass = "show"
      this.playPauseBtn = "play_arrow"
      this.videoTransparent = "videoTransparent"
      this.pausePlayHover = "playBtn"
    } 
    if(unit.documentPath != null){
      this.displayDocuments = "none";
      this.color1 = "#49D14F"
      this.documentPath = unit.documentPath
    } 
    if(unit.imagePath != null){
      this.imagePath = unit.imagePath
      this.showVideo = false;
    }
    if(unit.text != null){
      this.text = unit.text
    }
    if(unit.audioPath != null){
      this.audioPath = unit.audioPath
      let play = {
        title: unit.name,
        link: unit.audioPath}
      this.playlist.push(play)
      this.audio.src = unit.audioPath
    }
    // if(unit.imagePath != null && unit.videoPath != null){
    //   this.videoPath = unit.videoPath;
    //   this.showVideo = false;
    //   this.playerClass = "dontShow"
    // }
    this.localStorageService.setItem('currentUnit', unit)
   
    console.log(this.currentUnit)
  }

  playAudioUnit(audioPath){
    this.audio.src = audioPath;
    this.audio.play()
    this.audioPlayBtn = false;
    this.audioPauseBtn = true;
    this.audio.onended = () => this.onEnded()
  }

  pauseAudioUnit(){
    this.audio.pause()
    this.audioPlayBtn = true;
    this.audioPauseBtn = false;
  }

  videoShow(currentUnit: Unit){
   if(currentUnit.videoPath != null){
    this.showVideo = true;
    this.playerClass = "show"
    this.pausePlayHover = "playBtn"
   }
  }

  isCurrent(unitId) {
    if (unitId == this.currentUnit.id) {
      return true
    }
    else {
      return false
    }
  }

  canPlayNextVideo(): boolean {
    let element
    for (let index = 0; index < this.userScores.length; index++) {
      if (this.userScores[index].trainingUnitId == this.currentUnit.id) {
        element = this.userScores[index];
        break;
      }
    }
    console.log(element)
    if (!(element.numberOfVisits == 0)) {
      if(this.currentUnit.documentPath != null){
        this.documentDownloaded = true;
      }
      if(this.currentUnit.audioPath != null){
        this.audioFinished = true;
      }
      return true
    }
    else {
      return false
    }
  }

  getUnitIcons() {
    this.visitedUnits = [];
    let flg = false;
    let trSections = []
    if(this.localStorageService.getItem("trainingInfo") != null){
      trSections = this.localStorageService.getItem("trainingInfo")
    } else {
      trSections = this.training.sections
    }
    for(let i = 0; i < trSections.length; i++){
      let trainingSections = trSections.sort((a,b) => (a.order < b.order ? -1 : 1))
      let section = trainingSections[i]
      for(let j = 0; j < section.units.length; j++) {
        let sectionUnits = section.units.sort((a, b) => (a.order < b.order ? -1 : 1))
        let unit = sectionUnits[j]
        console.log("UNIT::: ", unit.name)
        if((unit.id != this.currentUnit.id) && (flg == false)) {
          console.log("PUSH VISITED UNIT", unit.name)
          this.visitedUnits.push(unit)
        } else {
          console.log("UNIT: ", unit.name)
          console.log("CURRENT UNIT", this.currentUnit.name)
          flg = true;
          break;
        }
      }
    }
  }

  getIconClass(unitId: String) {
    let flg = false;
    for(let i = 0; i < this.visitedUnits.length; i++) {
      let visitedUnit = this.visitedUnits[i].id;
      if(visitedUnit == unitId){
        flg = true;
        break;
      }
    }
    return flg;
  }
  
  playNextVideo() {
    this.pausePlayBtn = this.labels.playButton
    let sectionId = this.currentUnit.trainingSectionId
    this.training.sections.forEach((s) => {
      if (sectionId == s.id) {
        this.currentSection = s;
      }
    })
    if (this.hasNextUnit(this.currentUnit.order, this.currentSection)) {

      let unit = this.getNextUnit(this.currentUnit.order, this.currentSection)
      this.showControlQuestion()
      this.setCurrentUnit(unit)
    }
    else if (this.hasNextSection(this.currentSection)) {
      let elem
      if(this.currentSection.testId != null){
        // this.userScores = this.userScores.sort((a, b) => (a.order < b.order ? -1 : 1));
        // for (let index = 0; index < this.userScores.length; index++) {
        //   if (this.userScores[index].trainingUnitId == this.currentUnit.id) {
        //     elem = this.userScores[index+1];
        //     break;
        //   }
        // }
          this.localStorageService.setItem("currentSectionId", this.currentSection.id)
          this.localStorageService.setItem("testSectionId", this.currentSection.testId)
          this.router.navigateByUrl('/user/start-section-test')
        
      }
        let section = this.getNextSection(this.currentSection)
        let unit = this.getNextUnit(0, section)
        this.showControlQuestion()
        this.setCurrentUnit(unit)
    }
    else {
      this.changeTrainingStatus(this.training, 3)
      if(this.currentSection.testId != null){
        let element
        this.userScores = this.userScores.sort((a, b) => (a.order < b.order ? -1 : 1));
        for (let index = 0; index < this.userScores.length; index++) {
          if (this.userScores[index].trainingUnitId == this.currentUnit.id) {
            element = this.userScores[index];
            break;
          }
        }
          this.localStorageService.setItem("currentSectionId", this.currentSection.id)
          this.localStorageService.setItem("testSectionId", this.currentSection.testId)
          this.router.navigateByUrl('/user/start-section-test')
       
      }else{
        if(this.training.testId!= null && this.training.testId!= ""){
          this.openTestDialog();
        }
        else{
          if(this.training.surveyId!= null && this.training.surveyId!= ""){
            //this.test$ = this.userDashboarService.getSurveyForTraining(this.localStorageService.getItem('surveyId')).pipe(share())
            this.router.navigateByUrl('user/survey')
            this.openSurveyDialog()
          }
        }
      }
     
    }

  }

  onEnded(){
    this.audioFinished = true
    this.updateVideoScore(this.currentUnit)
    this.playNextVideo()
  }

  docDownloaded(){
    this.documentDownloaded = true
    this.updateVideoScore(this.currentUnit)
  }

  hasNextUnit(unitOrder: number, currSection: TrainingSection): boolean {
    let nextUnitOrder = unitOrder + 1

    for (let unit of currSection.units) {
      if (unit.order == nextUnitOrder) {
        return true
      }
    }
    return false
  }

  getNextUnit(unitOrder: number, currSection: TrainingSection): Unit {
    let nextUnitOrder = unitOrder + 1
    for (let unit of currSection.units) {
      if (unit.order == nextUnitOrder) {
        return unit
      }
    }
    return null
  }

  hasNextSection(currSection: TrainingSection): boolean {
    let sectionOrder = currSection.order
    let nextSectionOrder = sectionOrder + 1
    for (let section of this.training.sections) {
      if (section.order == nextSectionOrder) {
        return true
      }
    }
    return false

  }

  getNextSection(currSection: TrainingSection): TrainingSection {
    let sectionOrder = currSection.order
    let nextSectionOrder = sectionOrder + 1
    for (let section of this.training.sections) {
      if (section.order == nextSectionOrder) {
        return section
      }
    }
    return null
  }

  playPreviousVideo() {
    let sectionId = this.currentUnit.trainingSectionId
    this.training.sections.forEach((s) => {
      if (sectionId == s.id) {
        this.currentSection = s;
      }
    })
    if (this.hasPreviousUnit(this.currentUnit.order, this.currentSection)) {
      this.arrowBackClass = "arrowBack"
      let unit = this.getPreviousUnit(this.currentUnit.order, this.currentSection)
      this.setCurrentUnit(unit)
    }
    else if (this.hasPreviousSection(this.currentSection)) {
      this.arrowBackClass = "arrowBack"
      let section = this.getPreviousSection(this.currentSection)
      let unit = this.getPreviousUnit(section.units[section.units.length - 1].order + 1, section)
      this.setCurrentUnit(unit)
    }
    else {
      this.arrowBackClass = "noPrevious"
    }
  }

  hasPreviousUnit(unitOrder: number, currSection: TrainingSection): boolean {
    let prevtUnitOrder = unitOrder - 1

    for (let unit of currSection.units) {
      if (unit.order == prevtUnitOrder) {
        return true
      }
    }
    return false
  }

  getPreviousUnit(unitOrder: number, currSection: TrainingSection): Unit {
    let prevtUnitOrder = unitOrder - 1
    for (let unit of currSection.units) {
      if (unit.order == prevtUnitOrder) {
        return unit
      }
    }
    return null
  }

  hasPreviousSection(currSection: TrainingSection): boolean {
    let sectionOrder = currSection.order
    let prevSectionOrder = sectionOrder - 1
    for (let section of this.training.sections) {
      if (section.order == prevSectionOrder) {
        return true
      }
    }
    return false

  }
  getPreviousSection(currSection: TrainingSection): TrainingSection {
    let sectionOrder = currSection.order
    let prevSectionOrder = sectionOrder - 1
    for (let section of this.training.sections) {
      if (section.order == prevSectionOrder) {
        return section
      }
    }
    return null
  }
  playPauseVideo() {
    if (!this.player.nativeElement.paused && !this.player.nativeElement.ended && this.player.nativeElement.currentTime > 0) {
      this.player.nativeElement.pause()
      // this.playPauseBtn = "play_arrow"
      this.pausePlayBtn = this.labels.playButton
      this.videoTransparent = "videoTransparent"
      this.pausePlayHover = "playBtn"
    }
    else {
      this.player.nativeElement.play()
      this.pausePlayBtn = this.labels.pauseButton
      this.videoTransparent = ""
      this.pausePlayHover = "pauseBtn"
      // this.playPauseBtn = "pause"
    }
  }

  setPlayIcon() {
    this.playPauseBtn = "play_arrow"
  }
  setPauseIcon() {
    this.playPauseBtn = "pause"
  }
  goToTrainingDetails(training: Training) {
    this.userDashboarService.setTraining(training)
    this.router.navigateByUrl('user/training-details');
  }

  openMessageDialog(): void {
    const dialogRef = this.dialog.open(UserTrainingMessageComponent, {
      data: {
        isDoc: this.currentUnit.documentPath != null
      },
      disableClose: true,
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.playPauseVideo()
    })

  }
  openTestDialog(): void {
    this.test$ = this.userDashboarService.getTestForTraining(this.training.testId).pipe(share())
    // const dialogRef = this.dialog.open(UserBeforeTestMessageComponent, {
    //   disableClose: true,
    //   width: '350px'
    // });
    this.router.navigateByUrl('user/start-test');
  }

  openSurveyDialog(): void {
    const dialogRef = this.dialog.open(UserBeforeSurveyMessageComponent, {
      disableClose: true,
      width: '350px',
    });
  }

  onVideoFinished() {
    if(document.fullscreenElement){
      document.exitFullscreen()
    }
    this.updateVideoScore(this.currentUnit)
    this.playNextVideo()
  }

  onContextMenu(event: Event) {
    event.preventDefault()
  }

  onNextVideo() {
    
    if (this.canPlayNextVideo()) {
      this.playNextVideo()
    }
    else {
      this.player.nativeElement.pause()
      this.playPauseBtn = "play_arrow"
      this.openMessageDialog()
    }
  }

  onNextVideo3(type) {
    if (this.canPlayNextVideo()) {
     if(type == 1){
       if(this.audioFinished == true){
        this.playNextVideo()
       } else {
        this.playPauseBtn = "play_arrow"
        this.openMessageDialog()
      }
     }else{
      if(this.documentDownloaded == true){
        this.playNextVideo()
       } else {
        this.playPauseBtn = "play_arrow"
        this.openMessageDialog()
      }
     }
    }
    else {
      this.playPauseBtn = "play_arrow"
      this.openMessageDialog()
    }
  }

  onNextVideo2() {
    this.updateVideoScore(this.currentUnit)
    if (this.canPlayNextVideo()) {
      this.playNextVideo()
    }
    else {
      this.player.nativeElement.pause()
      this.playPauseBtn = "play_arrow"
      this.openMessageDialog()
    }
  }

  onPreviousVideo() {
    this.playPreviousVideo()
  }
  onUnitClick(unit: Unit) {
    this.showControl = false;
    if (this.canPlaySelectedVideo(unit)) {
      this.pausePlayBtn = this.labels.playButton
      this.setCurrentUnit(unit)
    }
    else {
      if(this.showVideo == true){
        this.player.nativeElement.pause()
      }
      this.playPauseBtn = "play_arrow"
      this.openMessageDialog()
    }
  }
  canPlaySelectedVideo(unit: Unit): boolean {
    let sectionId = unit.trainingSectionId
    this.training.sections.forEach((s) => {
      if (sectionId == s.id) {
        this.currentSection = s;
      }
    })
    if (unit.order == 1 && this.currentSection.order == 1) {
      //this.arrowBackClass='noPrevious'
      return true
    }
    if (this.hasPreviousUnit(unit.order, this.currentSection)) {
      let element
      let prevUnit = this.getPreviousUnit(unit.order, this.currentSection)
      for (let index = 0; index < this.userScores.length; index++) {
        if (this.userScores[index].trainingUnitId == prevUnit.id) {
          element = this.userScores[index];
          break;
        }
      }
      if (!(element.numberOfVisits == 0)) {
        return true
      }
      else {
        return false
      }

    }
    else {
      if (this.hasPreviousSection(this.currentSection)) {
        let element
        let section = this.getPreviousSection(this.currentSection)
        let prevUnit = this.getPreviousUnit(section.units[section.units.length - 1].order + 1, section)
        for (let index = 0; index < this.userScores.length; index++) {
          if (this.userScores[index].trainingUnitId == prevUnit.id) {
            element = this.userScores[index];
            break;
          }
        }
        if (!(element?.numberOfVisits == 0)) {
          return true
        }
        else {
          return false
        }

      }
    }
  }

  updateVideoScore(unit: Unit) {
    let videoScore: VideoScore
    
    this.userScores.forEach((score) => {
      if (score.trainingUnitId == unit.id) {
        videoScore = score
        score.numberOfVisits += 1
      }
    })
    
    this.userDashboarService.updateVideoScore(videoScore).subscribe((response) => {
      
      this.progress$ = this.userDashboarService.getCurrentUsersProgress(this.localStorageService.getItem('trainingId')).pipe(share());
    })
  }

  goToTestPage() {
    this.router.navigateByUrl('user/test')
  }

  showControlQuestion() {
    let element
    for (let index = 0; index < this.userScores.length; index++) {
      if (this.userScores[index].trainingUnitId == this.currentUnit.id) {
        element = this.userScores[index];
        break;
      }
    }
    if ((element?.numberOfVisits == 1) && (this.currentUnit.control_question != null)) {
      this.showControl = true;
      //modalni za kontrolno pitanje
      this.openControlQuestionDialog(this.currentUnit.control_question)
      this.playerClass = "show"
      this.controlClass = "showControl" //nebitno
    }
    else {
      this.playerClass = "show"
      this.controlClass = "dontShow"
      this.showControl = false;
    }
  }

  openControlQuestionDialog(controlQuestion): void {
    const dialogRef = this.dialog.open(ControlQuestionComponent, {
      disableClose: true,
      width: '550px',
      data: {controlQuestion: controlQuestion}
    });
  }

  onSelect(question: ControlQuestion, answer: ControlQuestionAnswers) {
    this.checked = true;
    if (question.type == 1) {
      question.answers.forEach((x) => { if (x.id !== answer.id) x.selected = false; })
    }
    else {
      question.answers.forEach((x) => {
        if (x.id == answer.id) {
          if (x.selected) {
            x.selected = false
            x.selected = true
          }
          else {
            x.selected = true
            x.selected = false
          }
        }
      })
    }

  }

  submit(question: ControlQuestion) {
    // this.playerClass = "show"
    this.controlClass = "dontShow"
    this.checked = false;
    if (question.type == 1) {
      question.answers.forEach((x) => {
        if (x.selected) {
          if (x.correct == true) {
            const dialogRef = this.dialog.open(ControlCorrectComponent, {
              disableClose: true,
              width: '400px',
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.playerClass = "show"
              this.controlClass = "dontShow"
              this.showControl = false;
            })
          } else {
            let correctAns = []
            question.answers.forEach((t) => {
              if (t.correct) {
                correctAns.push(t.textAns1)
                const dialogRef = this.dialog.open(ControlFalseComponent, {
                  disableClose: true,
                  width: '400px',
                  data: {
                    text: correctAns
                  }
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.playerClass = "show"
                  this.controlClass = "dontShow"
                  this.showControl = false;
                })
              }
            })
          }
        }
      })
    } else {
      for (let index = 0; index < question.answers.length; index++) {
        const element = question.answers[index];
        if (element.correct) {
          if (element.selected == true) {
            this.flg = false;
          } else {
            this.flg = true;
            break;
          }
        }
        if (element.correct == false) {
          if (element.selected == true) {
            this.flg = true;
            break;
          }
        }

      }
      // question.answers.forEach((x) => { if (x.correct){
      //     if(x.selected == true){
      //       this.flg = false;
      //     }else{
      //       this.flg = true;
      //     }
      // }})
      if (this.flg == false) {
        const dialogRef = this.dialog.open(ControlCorrectComponent, {
          disableClose: true,
          width: '400px',
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.playerClass = "show"
          this.controlClass = "dontShow"
          this.showControl = false;
        })
      } else {
        let correctAnswers = []
        question.answers.forEach((t) => {
          if (t.correct) {
            correctAnswers.push(t.textAns1)
            const dialogRef = this.dialog.open(ControlFalseComponent, {
              disableClose: true,
              width: '400px',
              data: {
                text: correctAnswers
              }
            });
            dialogRef.afterClosed().subscribe((result) => {
              this.playerClass = "show"
              this.controlClass = "dontShow"
              this.showControl = false;
            })
          }
        })
      }
    }
  }

  changeTrainingStatus(training: Training, status: Number) {
    this.userDashboarService.changeTrainingStatus(training.id, status).subscribe((result) => {
      console.log(result)
    })
  }

  onSeeking() {
    // if(!this.canPlayNextVideo()){
      var delta = this.player.nativeElement.currentTime - this.supposedCurrentTime;
      if (Math.abs(delta) > 0.01) {
        this.player.nativeElement.currentTime = this.supposedCurrentTime
      }
    // }
  }

  onTimeUpdate() {
    if (!this.player.nativeElement.seeking) {
      this.supposedCurrentTime = this.player.nativeElement.currentTime;
    }
  }

  toggleUsers() {
    this.usersExpanded = !this.usersExpanded
  }

  whatToDisplay(string: String) {
    switch (string) {
      case "documents":
        this.displayDocumentsUnit = "none"
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "block";
        this.displayComments = "none";
        this.displayInstructor = "none";
        //buttons
        // this.overviewButtonClass = this.button;
        // this.documentsButtonClass = this.buttonSelected;
        // this.commentsButtonClass = this.button;
        // this.instructorButtonClass = this.button;
        this.displaySections = "none"
        this.clickedComment = false;
        this.clickedCouments = true;
        this.trngClicked = false
        this.color1 = "#e5e5e5"
        this.color2 = "#49D14F";
        this.color3 = "#e5e5e5"
        break;
      case "comments":
        //divs
        this.displayDocumentsUnit = "none"
        this.displayOverview = "none";
        this.displayDocuments = "none";
        this.displayComments = "block";
        this.displayInstructor = "none";
        this.displaySections = "none"
        this.clickedComment = true;
        this.clickedCouments = false;
        this.trngClicked = false
        this.color1 = "#49D14F"
        this.color2 = "#e5e5e5";
        this.color3 = "#e5e5e5"
        //buttons
        // this.overviewButtonClass = this.button;
        // this.documentsButtonClass = this.button;
        // this.commentsButtonClass = this.buttonSelected;
        // this.instructorButtonClass = this.button;
        break;
      case "training":
        this.displayDocumentsUnit = "none"
        //divs
        this.displayOverview = "none";
        this.displayDocuments = "none";
        this.displayComments = "none";
        this.displayInstructor = "none";
        this.displaySections = "block"
        this.clickedComment = false;
        this.clickedCouments = false;
        this.trngClicked = true
        this.color1 = "#e5e5e5"
        this.color2 = "#e5e5e5";
        this.color3 = "#49D14F"
        //buttons
        // this.overviewButtonClass = this.button;
        // this.documentsButtonClass = this.button;
        // this.commentsButtonClass = this.buttonSelected;
        // this.instructorButtonClass = this.button;
        break;
    }
  }

  downloadStatement(trId) {
    this.userDashboarService.downloadStatement(trId, this.lang);
  }
  downloadSurveyResults(trId) {
    this.userDashboarService.downloadSurveyResults(trId, this.lang);
  }
  downloadTestResults(trId) {
    this.userDashboarService.downloadTestResults(trId, this.lang);
  }

  getComments(){
    let comments = []
    let docRef = this.db.firestore.collection("trainings").doc(this.trainingId).collection('comments').orderBy('time', 'asc')
    //let userRef = await this.db.firestore.collection("users")
    docRef.onSnapshot(function(snapshot) {
        
        snapshot.docChanges().forEach( function(change) {
            let comment = change.doc.data()
            comment.dateTime = change.doc.data().time
            let timestamp = change.doc.data().time.seconds
            var date = new Date(timestamp*1000);
            var formatDate = date.getDate()+
            "/"+(date.getMonth()+1)+
            "/"+date.getFullYear()+
            " "+date.getHours()+
            ":"+date.getMinutes()
            comment.time = formatDate
            let userId = comment.userId
            //let user = await userRef.doc(userId).get()
            //let imgPath = user.data().profileImagePath
            //comment.profileImagePath = imgPath
            comments.push(comment)
        });
      });
      return comments
  }

  submitComment(){
    if(this.insertForm.value.text != "" && this.insertForm.value.text != null){
      let docRef = this.db.firestore.collection("trainings").doc(this.trainingId).collection('comments').doc()
      let comment = {
        id: docRef.id,
        trainingId: this.trainingId,
        userId: this.insertForm.value.userId,
        fullName: this.insertForm.value.firstName + " " + this.insertForm.value.lastName,
        email: this.insertForm.value.email,
        time: new Date(),
        text: this.insertForm.value.text,
        imagePath: this.user.profileImagePath
    }
    
    docRef.set(comment)
    this.insertForm.controls.text.reset()

    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  toggleUnitMessage() {
    this.showText = !this.showText;
  }

}
