<div class="row" style = "width: 65%; margin: auto">
    <div class="col-12 text-center calendarHeader">
      <div class="row">
        <div class="col-md-5" style="text-align: left;">
          <button
          class = "tableBtn"
          (click) = "addEvent()"
          style = "float: left;"
          >
            {{labels.lblAddEvent}}
        </button>
        </div>
        <div class="col-md-2">
          <h3 class="month">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
        <div class="col-md-5" style="text-align: right;">
          <div class="btn-group">
            <div
              class = "tableBtnReverse viewP"
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              {{labels.lblPreviousM}}
            </div>
            <div
              class = "tableBtn viewP"
              style="margin-left: 10px;"
              mwlCalendarToday
              [(viewDate)]="viewDate"
            >
              {{labels.lblToday}}
            </div>
            <div
              class = "tableBtnReverse viewP"
              style="margin-left: 10px;"
              mwlCalendarNextView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
            >
              {{labels.lblNextM}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div [ngSwitch]="view" class="col-12 calendar" *ngIf  = "reload != false">
      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
      >
      </mwl-calendar-month-view>
    </div>
      
  </div>
