<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblEditSector}} "{{data.name}}"</h1>
<div mat-dialog-content >
  <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="height: 350px;">
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblSectorName}}</mat-label>
            <input matInput [ngModel]="data.name" name="name" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>E-mail</mat-label>
            <input matInput [ngModel]="data.email" name="email" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%" *ngIf="(admin.departmentId != data.id && !admin.headAdmin) || admin.headAdmin">
            <mat-label>{{labels.lblParentSector}}</mat-label>
            <mat-select [ngModel]="data.parentId" name='parentId'>
                <mat-option *ngFor="let department of departments1" [value]="department.id">
                    {{department.name}}
                </mat-option>
                <mat-option *ngIf="admin.headAdmin" [value]="''">
                    /
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblAdministrator}}</mat-label>
            <mat-select [ngModel]="data.adminId" name='adminId' required>
                <mat-option *ngFor="let admin of administrators" [value]="admin.id">
                    {{admin.firstName}} {{admin.lastName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field> -->

        <div mat-dialog-actions>
            <!-- <button  class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
            <button class="btn" cdkFocusInitial type="submit">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div>



