<div>
    <div class="row"
      style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
      <div class="col-11"></div>
      <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
        <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
      </div>
    </div>
    <h1 mat-dialog-title>{{labels.lblEditTrainer}} "{{data.firstName}}"</h1>
    <div mat-dialog-content>
     <mat-horizontal-stepper linear #stepper style = "height: 100%">
      <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="height:480px">
      <mat-step [stepControl]="firstFormGroup" [editable]="true">
        <ng-template matStepLabel>{{labels.lblBasicInfo}}</ng-template> 
         
            <div fxLayout="column">
              <mat-form-field appearance="none" fxFlex="60%">
                <mat-label>{{labels.lblUserName}}</mat-label>
                <input matInput [ngModel]="data.userName" name="userName" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="none" fxFlex="60%">
                <mat-label>{{labels.lblFirstName}}</mat-label>
                <input matInput [ngModel]="data.firstName" name="firstName" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="none" fxFlex="50%">
                <mat-label>{{labels.lblLastName}}</mat-label>
                <input matInput [ngModel]="data.lastName" name="lastName" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="none" fxFlex="60%">
                <mat-label>E-mail</mat-label>
                <input matInput [ngModel]="data.email" name="email" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
              <div style="text-align: right">
                <button cdkFocusInitial (click) = "goToNextStep(stepper)" type="button" class="tableBtn" style="float: right; padding: 7px; padding-left: 10px; padding-right: 10px;">Nastavi</button>
              </div>
              </div>
            </mat-step>

            <mat-step  [stepControl]="secondFormGroup" [editable]="false">
              <ng-template matStepLabel>{{labels.lblOtherInfo}}</ng-template>
              <button class="backBtn" (click)="goBack(stepper)">{{labels.lblBack}}</button>
              <div fxLayout="column">
                <mat-form-field appearance="none" fxFlex="60%">
                  <mat-label>{{labels.lblSelectADate}}</mat-label>
                  <input matInput [matDatepicker]="picker" [ngModel]="data.dateOfBirth" name="dateOfBirth">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
               <!--  <mat-form-filed appearance="none" fxFlex="60%">
                  <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
                  <div style="margin-top: 10px; margin-bottom: 25px;">
                    <mat-label style="color: #3e4b71;">{{labels.lblChooseProfilePicture}} </mat-label>
                    <mat-icon class="camera" (click)="imageUpload.click()">camera_alt</mat-icon>
                    <mat-label *ngIf="selectedImage">
                      {{selectedImage.name}}
                    </mat-label> -->
                    <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error> -->
                 <!--  </div> -->
                  <!-- <button mat-raised-button type="button" style="margin-bottom: 15px;" (click)="imageUpload.click()">{{labels.lblUploadImage}}</button> -->
                  
               <!--  </mat-form-filed> -->
                <mat-form-filed class="matFormField" appearance="none" fxFlex="60%">
                  <mat-label style="color: #3e4b71;">{{labels.lblBio}}</mat-label>
                  <textarea maxlength="250" rows = "5" cols = "5" matInput [ngModel]="data.about" name="about" class="txtArea" style=" margin-bottom: 15px; height: 70px;" required></textarea>
                  <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error> -->
                </mat-form-filed>
                <div mat-dialog-actions>
                  <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
                  <button mat-raised-button class="tableBtn" style="margin-left: 5px !important;" (click) = "onSubmit()" type="submit">{{labels.lblEdit}}</button>
                </div>
              </div>
            </mat-step>
          </form>
     </mat-horizontal-stepper>
     </div>
  </div>


