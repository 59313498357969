import { Component, OnInit,ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts'
import { DashboardService } from '../dashboard.service';
import {MatPaginator} from '@angular/material/paginator'
import {MatTableDataSource} from '@angular/material/table'
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { Subscription } from 'rxjs';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { UserDashboardService } from '../user-dashboard.service';

export interface PeriodicElement{
  name:string,
  position:number,
  weight:number,
  symbol:string
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  bigChart=[]
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  users
  departments
  trainings
  labels;
  labelsSub: Subscription
  result
  successfulTrainings
  admin
  adminSub: Subscription
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  constructor(private dashboardService:DashboardService, private userDashboardService: UserDashboardService, private adminLanguageService: AdminLanguageService, private adminAuthService: AdminAuthService) { }


  ngOnInit(): void {
    this.getAdmin()
    this.getTrainings()
    this.getLang()
    this.trainingSuccess()
    // this.userDashboardService.callScheduler()
  }

  getUsers(){
    this.dashboardService.getUsers().subscribe((result)=>{
      this.users = result
    })
  }
  getUsers1(){
    this.dashboardService.getUsersInDep(this.admin.departmentId).subscribe((result)=>{
      this.users = result
    })
  }

  trainingSuccess(){
    let tr = "training"
    this.dashboardService.fetchTrainingSuccess({training:tr}).subscribe((result) => {
      this.successfulTrainings = result
      console.log(this.successfulTrainings)
    })
  }

  fetchDepartments(){
    this.dashboardService.fetchAllDepartments().subscribe((result)=>{
      this.departments=result
    })
  }
  fetchDepartments1(){
    this.dashboardService.fetchAdminsDepartments(this.admin.departmentId).subscribe((result)=>{
      this.departments=result
    })
  }

  getTrainings(){
    this.dashboardService.getTrainings().subscribe((result)=>{
      this.trainings=result
    })
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.fetchDepartments();
          this.getUsers()
        }
        else{
          this.fetchDepartments1();
          this.getUsers1()
        }
      }, 100);
    });
  }

}
