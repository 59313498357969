<div>
    <div class="row"
        style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
        <div class="col-11"></div>
        <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
            <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
        </div>
    </div>
    <h2 mat-dialog-title>{{labels.lblAssignDocumentsToTrainings}} "{{data.name}}"</h2>
    <div mat-dialog-content>
        <form>
            <div class="col-sm-12" style="width: 100%; padding-left:0;">
                <mat-checkbox [checked]="data.testResults"
                 class="example-margin checkbox" (change)="selectValue($event,'1')">
                    {{labels.lblTestResults}}
                </mat-checkbox>
                <mat-checkbox [checked]="data.surveyResults"
                     class="example-margin checkbox" (change)="selectValue($event,'2')">
                    {{labels.lblSurveyResults}}
                </mat-checkbox>
                <mat-checkbox [checked]="data.statement"
                     class="example-margin checkbox" (change)="selectValue($event,'3')">
                    {{labels.lblStatement}}
                </mat-checkbox>
                <mat-checkbox [checked]="data.genericWorkPlace"
                     class="example-margin checkbox" (change)="selectValue($event,'4')">
                    {{labels.lblGenericWorkPlaceDoc}}
                </mat-checkbox>
                <div style="margin: auto;">
                    <button type="button" class="btn"
                        style="float:right; padding-right: 10px; padding-left: 10px; margin-right: 0px !important"
                        mat-button (click)="onSubmit()">{{labels.lblAssign}}</button>
                </div>
            </div>
        </form>
    </div>