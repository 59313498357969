import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth-service/auth.service';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardService } from '../user-dashboard.service';

@Component({
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.scss'],
})
export class TermsOfUseModalComponent implements OnInit {
  constructor(
    private languageService: UserLanguageService,
    public db: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private userDashboardService: UserDashboardService,
    private localStorageService: LocalStorageService
  ) {}

  labels;
  labelsSub: Subscription;
  source;

  totalPages: number;
  page: number = 1;
  isLoaded: boolean = false;
  file;
  retrievedFile;
  url;
  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    });
    //this.source = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
    // this.source = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/000000%2Fmodel.pdf?alt=media&token=4a6092e1-51e4-40aa-b563-dbc2bb68f3b1';
    //this.getTermsOfUse()
  }

  // getTermsOfUse(){
  //   this.userDashboardService.getTermsOfUse(this.data.user?.departmentId).subscribe((response) => {
  //     console.log("RESPONSE: ", response)
  //     this.source = String(response)
  //     console.log("SOURCE: ", this.source)
  //   })
  // }

  acceptTerms() {
    this.userDashboardService
      .acceptToS({ usrId: this.localStorageService.getItem('usrId') })
      .subscribe(
        (res) => {
          console.log(res);

          let data1 = {
            email: this.localStorageService.getItem('userName'),
            password: this.localStorageService.getItem('pass'),
          };
          this.authService.login(data1).subscribe((response) => {
            if (response.err == 'greska') {
              this.router.navigateByUrl('/');
            } else {
              this.router.navigateByUrl('/user/dashboard');
              //this.router.navigateByUrl('/user/splash-screen')
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
  }

  // acceptTerms() {
  //   this.router.navigateByUrl('/user/dashboard');
  // }

  // onNoClick(): void {
  //   this.dialogRef.close();
  // }

  // nextPage() {
  //   this.page += 1;
  // }

  // previousPage() {
  //   this.page -= 1;
  // }

  // afterLoadComplete(pdfData: any) {
  //   this.totalPages = pdfData.numPages
  //   this.isLoaded = true;
  // }
}
