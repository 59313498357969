<mat-drawer-container>
  <mat-drawer-content>
    <app-user-spinner *ngIf="trainings == null || user == null || labels == null">

    </app-user-spinner>
<body [class] = "colorClass">
  <!-- NASLOV -->
  <div class = "row" style = "margin: auto; padding: 25px;">
    <div class = "d-none d-sm-block col-sm-3"></div>
    <div class = "col-3 col-sm-4" style = "margin: auto; text-align: center">
      <h3 class = "naslov" style = "color: #0E0D35;font-family: Lato, 'Alegreya Sans', 'Alegreya Sans'; font-size: 30px; font-weight: bold;">{{labels.lblTrainings}}</h3>
    </div>
    <div class = "col-9 col-sm-3" style = "text-align: right;">
      <!-- <span style = "cursor: pointer; padding-right: 15px;" routerLink = "/user/calendar">
        <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FcalendarIcon.png?alt=media&token=0dcbbd8e-d153-4851-af32-658f01446fbd">
      </span> -->

      <span style = "cursor: pointer; vertical-align: middle; margin-right: 10px;" routerLink="/user/faq">
        <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webImgs%2FhomeIcon1.png?alt=media&token=f55962fc-39ce-44f5-a7f2-139e4e71c0ea" alt = "faq icon">
      </span>

      <button (click)="logout()" style="background-color: #f1f1f1; vertical-align: middle; font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-size: 16px; padding: 7px; border: 1px solid #f1f1f1; border-radius: 8px;">
        <mat-icon style = "vertical-align: middle; color: #0E0D35;">exit_to_app</mat-icon>
        <span class = "mobHidden">{{labels.lblLogOut}}</span>
      </button>

    </div>
  </div>
  <!-- HOME MAIN IMG -->
  <div class = "coverImageWrap">
    <img class="coverImage" style = "width: 100%;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Naslovna-fotka.png?alt=media&token=0efd5995-03be-43a0-8fb5-5990c9c4c427">
  </div>
  <!-- OBUKE -->
<div class="row">
  <div class="col-12 col-lg-11 content" *ngIf="showTrainings && toggle">
    <div *ngFor="let training of trainings" class="cardsInline">
      <div *ngIf="(training.show == true) && (!training.successful)" title={{training.name}} class="card"
        (click)="goToTrainingDetails(training)">
        <img class="imageWrapNfinished" src={{training.imagePath}} alt="training image" style="">
        <div class="contentWrap">
          <div class="nFinishedTrainingHeader">
            {{training.name.substring(0,50)}}
          </div>
          <div class="infoItem">
            <div class="label">{{training.isTestOnly ? labels.lblTestStatus : labels.lblTrainingStatus}}</div>
            <div class="value">{{training.status == 1 ?
              labels.lblNotStarted : labels.lblStarted}}</div>
          </div>
          <div class="infoItem">
            <div class="label">{{labels.lblTrainingDueDate}}</div>
            <div class="value">{{training.toDate ? training.toDate : "/"}}</div>
          </div>
          <div *ngIf="!training.isTestOnly" class="infoItem">
            <div class="label">{{labels.lblTrainingProgres}}</div>
            <div class="value">{{training.progress}}%</div>
          </div>
        </div>
        <!-- <div class="property font col-12" style="padding-left:0px;"><mat-icon style="color: #C8C8C8; vertical-align: middle;"> schedule</mat-icon>
              <span class="aligned-with-icon font" style = "color: #C8C8C8; font-size: 14.5px; vertical-align: middle;"> {{training.duration}} </span>
            </div> -->
      </div>

    </div>
  </div>


  <div class="col-12 col-lg-11 content-all" *ngIf="showTrainings && !toggle">
    <table class="mat-table all-trainings-table">

      <thead>
      <th class="training-order-id">#</th>
      <th>{{labels.lblTrainingName}}</th>
      <th>{{labels.lblTrainingStatus}}</th>
      <th>{{labels.lblTest}}</th>
      <th>{{labels.lblTrainingDueDate}}</th>
      </thead>

      <tbody *ngFor="let training of trainings; let i = index">
        <tr (click)="goToTrainingDetails(training)" [style.background-color]="training.successful ? '#49D14F' : 'transparent'"  title={{training.name}}>
          <td class="training-order-id">{{i + 1}}.</td>
          <td>{{training.name}} {{training.trainerName}}</td>
          <td>

            <ng-template [ngIf]="training.successful">
              {{ labels.lblFinished }}
            </ng-template>

            <ng-template [ngIf]="training.status == 1 && !training.successful">
              {{ labels.lblNotStarted }}
            </ng-template>

            <ng-template [ngIf]="training.status != 1 && !training.successful">
              {{ labels.lblStarted }}
            </ng-template>

          </td>
          <td>
            <ng-template [ngIf]="training.successful">
              {{ labels.lblYouPassed.toString().toLowerCase() }}
            </ng-template>
            <ng-template [ngIf]="!(training.successful)">
              {{ labels.lblYoudDidntPassed.toString().toLowerCase() }}
            </ng-template>
          </td>
          <td>{{training.successful ? training.toDate :  training.toDate}}</td>

        </tr>
      </tbody>


    </table>
  </div>

  <!-- zoom -->
  <!-- <div *ngIf = "!showTrainings" class="col-12 col-lg-11"  [ngClass]="{'content': toggle, 'content-all': !toggle}">
    <div *ngFor="let meeting of meetings" class="cardsInline">
      <div class="card">
        <mat-card class="mat-elevation-z4" (click)="goToTrainingDetails(training)">
          <div style="width: 280px; text-align: center; background: #C8C8C8; margin-top: -16px; margin-left: -16px; border-radius: 15px;">
           <img mat-card-image src={{meeting.imagePath}} alt = "meeting image" style="width: 100%; height: 180px; margin: auto; object-fit: contain;">
          </div><br>
            <mat-card-content style="height: 50px; margin-bottom: 5px;">
              <mat-card-title class="header font">{{meeting.title.substring(0,35)}}</mat-card-title>
            </mat-card-content>
            <div class="property font col-12" style="padding-left: 0px; padding-right: 0px;">
              <span>
                <mat-icon style="color: #C8C8C8; vertical-align: middle;"> schedule</mat-icon>
              <span class="aligned-with-icon font" style = "color: #C8C8C8; font-size: 14.5px; vertical-align: middle;"> {{meeting.date1}} </span>
              </span>
              <span>
                <button *ngIf="!meeting.finished && meeting.started" mat-button class="mainBtn button font" style="margin: auto; background-color: #49D14F; color: white; float: right; border-radius: 10px;" [routerLink]="['/user/zoom-meeting', meeting.meetingId]"><span>{{labels.lblJoin}}</span>
                </button>
                <button *ngIf="meeting.finished && meeting.testId" mat-button class="mainBtn button font" (click)="goToZoomTestMessage(meeting)" style="margin: auto; background-color: #49D14F; color: white; float: right; border-radius: 10px;">
                  {{labels.lblStart + " " + labels.lblTest}}
                </button>
              </span>
            </div>
         </mat-card>
      </div>

    </div>
  </div> -->
</div>
<div [ngClass]="{'narrow': toggle, 'wide': !toggle}" class="col-12 col-lg-4" style="padding-left: 25px; margin-right: 0px; text-align: left; font-size:2vh; margin-left: 2%; margin-bottom: 3%;">
  <div class = "row">
    <div class="col-4">
      <button mat-button class="mainBtn viewAllButton button font" [class] = "buttonStyle" (click)="toggleClass()" style = "background-color: #49D14F; border-radius: 10px; color: white; font-weight: bold;" *ngIf = "toggle == true">{{labels.lblShowAll}}</button>
      <button mat-button class="mainBtn viewAllButton button font" [class] = "buttonStyle" style="margin: auto; background-color: #49D14F; border-radius: 10px; color: white; font-weight: bold;" (click)="toggleClass()" *ngIf = "toggle == false ">{{labels.lblBack}}</button></div>
    <!-- <div class = "col-4" style = "padding-left: 5px;">
      <button mat-button class="mainBtn viewAllButton button font" [class] = "buttonStyle" (click)="whatToShow()" style = "background-color: #0E0D35; border-radius: 10px; color: white; font-weight: bold;"><span *ngIf = "!showTrainings">{{labels.lblTrainings}}</span><span *ngIf = "showTrainings">{{labels.lblMeetings}}</span></button>
    </div> -->
  </div>
</div>

<!-- ZAVRSENE OBUKE -->
<div class = "row" style = "width: 100%; margin: 20px auto 40px; background-color: white;">
  <div class = "col-12 col-lg-11" style = "margin:auto; text-align: left;">
    <h3 style = "color: #0E0D35;font-family: Lato, 'Alegreya Sans', 'Alegreya Sans'; font-size: 30px; font-weight: bold;">{{labels.lblFinishedTrainings}}</h3>
  </div>
    <div class="col-12 col-lg-11 content" style="display: flex; gap: 20px;">
      <div *ngFor="let training of trainings" class="cardsInline">
          <div *ngIf="training.successful" title={{training.name}} class="finishedTrainingWrap" (click)="goToTrainingDetails(training)">
             <img class="imageWrap" mat-card-image src={{training.imagePath}} alt = "training image">
              <div class="contentWrap">
                <h4 class="completedTrainingName">{{training.name.substring(0,50)}}</h4>
              </div>
           </div>
      </div>
    </div>
  </div>

<!-- <div class="row">
  <div class="col-12 col-lg-10"  [ngClass]="{'content': toggle, 'content-all': !toggle}" [style.background-color] = "backgroundColor">
    <div *ngFor="let meeting of meetings" class="cardsInline">
      <div class="card">
        <mat-card class="mat-elevation-z4">
          <div style="width: 280px; text-align: center; background: #C8C8C8; margin-top: -16px; margin-left: -16px; border-radius: 15px;">
           <img mat-card-image src={{meeting.imagePath}} style="width: 100%; height: 180px; margin: auto; object-fit: contain;">
          </div><br>
             <div class = "row">
                <div class = "col-12">
                  <h3 style = "margin-bottom: 0px; font-family: 'Poppins';"><mat-icon style="color:  rgb(220,69,0); vertical-align: middle;"> today</mat-icon> {{meeting.date1}}</h3>
                </div>
             </div>
               <hr style="margin-bottom:5px;">
               <mat-card-content style="height: 50px; margin-bottom: 5px;">
                 <mat-card-title class="header font">{{meeting.title.substring(0,35)}}</mat-card-title>
               </mat-card-content>
           <mat-card-actions style = "margin-bottom: 5px; text-align: center;">
            <button *ngIf="!meeting.finished || !meeting.testId" mat-button class="mainBtn button font" [class] = "buttonStyle1" style="margin: auto; background-color: white; width: 85%;" [routerLink]="['/user/zoom-meeting', meeting.meetingId]" [disabled] = "meeting.started == false" [class.isNext] = "meeting.started"><span>{{labels.lblTrainingNotStarted}}</span>
              </button>
             <button *ngIf="meeting.finished && meeting.testId" mat-button class="mainBtn button font" [class]="buttonStyle1" (click)="goToZoomTestMessage(meeting)">
               {{labels.lblStart + " " + labels.lblTest}}
             </button>
           </mat-card-actions>
         </mat-card>
      </div>

    </div>
  </div>
</div> -->

</body>
  </mat-drawer-content>
</mat-drawer-container>
