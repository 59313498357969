import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import {Unit} from './../../../models/unit.model'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {

  @ViewChild('insertForm') insertForm:NgForm
  dataSource1
  labels;
  labelsSub: Subscription;
  textAnsErr: boolean = false
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertDialogComponent>, private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.fetchAnswersForQuestion()
    this.getLang()
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onNoClick(){
    this.dialogRef.close()
  }

  fetchAnswersForQuestion(){
  this.dashboardService
  .fetchAnswersForQuestion(this.data.testId,this.data.questionId)
  .subscribe(response=>this.dataSource1=response)
}

enterClick(){
  return false;
}

  onSubmitAns() {
    console.log(this.dataSource1)
    if (this.insertForm.value.text == "") {
      this.textAnsErr = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }
    else {
      let flg = false
      for (let i = 0; i < this.dataSource1.length; i++) {
        const element = this.dataSource1[i].text
        if(this.insertForm.value.text.toLowerCase() == element.toLowerCase()){
         flg = true;
         break;
        }
      }
      if(flg == false){
        if(this.data.type == 3){
          this.dashboardService.creteAnswer({
            testId: this.data.testId,
            testQuestionId: this.data.questionId,
            correct: false,
            order: this.dataSource1.length + 1,
            text: this.insertForm.value.text
          }).subscribe(response => {
            console.log(response)
          })
          this.dialogRef.close()
        }else if(this.dataSource1.length + 1 == 1) {
          this.dashboardService.creteAnswer({
            testId: this.data.testId,
            testQuestionId: this.data.questionId,
            correct: true,
            order: this.dataSource1.length + 1,
            text: this.insertForm.value.text
          }).subscribe(response => {
            console.log(response)
            this.dialogRef.close()
            this.snackBar.open(this.labels.lblControlQuestionAnsAdded, 'OK', {
              verticalPosition: 'top',
              horizontalPosition: 'end',
              duration: 5000
            })
          })
  
        }
        else {
          this.dashboardService.creteAnswer({
            testId: this.data.testId,
            testQuestionId: this.data.questionId,
            correct: false,
            order: this.dataSource1.length + 1,
            text: this.insertForm.value.text
          }).subscribe(response => {
            console.log(response)
          })
          this.dialogRef.close()
        }
      }else{
        this.snackBar.open(this.labels.lblAnswerExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
    this.insertForm.controls.text.reset()
   
  }
}
  
