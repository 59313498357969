import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DashboardService } from '../../dashboard.service';
import { Unit } from './../../../models/unit.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatTable } from '@angular/material/table'
import { ActivatedRoute } from '@angular/router';
import { DeleteControlDialogComponent } from '../delete-control-dialog/delete-control-dialog.component';
import { EditControlDialogComponent } from '../edit-control-dialog/edit-control-dialog.component';
import { DeleteControlQuestionDialogComponent } from '../delete-control-question-dialog/delete-control-question-dialog.component';
import { EditControlQuestionDialogComponent } from '../edit-control-question-dialog/edit-control-question-dialog.component';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface controlAnswer {
  textAns1: string
  correct: boolean
}
export interface controlQuestion {
  questionText: string
  type: number
  text: string
}
// const ELEMENT_DATA: controlAnswer[] = [
//   {idControl: 0, textAns1: "vazi", correctAns:false}
// ];

@Component({
  selector: 'app-admin-control',
  templateUrl: './admin-control.component.html',
  styleUrls: ['./admin-control.component.scss']
})
export class AdminControlComponent implements OnInit {
  insertedAns = ""
  questionType: number
  question: controlQuestion
  questionDisplay: string
  field: number
  typeOfQuestion: number
  numOfAns: number
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  id: string
  flg: string
  con_id: string
  questionId: string
  correct = false;
  sectionId: string
  trainingId: string
  idKontrolno = 0
  err: boolean = false;
  err1: boolean = false;
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('insertForm1') insertForm1: NgForm
  @ViewChild('table1') table1: MatTable<controlQuestion[]>
  @ViewChild('table') table: MatTable<controlAnswer[]>
  unitId: string
  labels;
  labelsSub: Subscription;
  constructor(public dialogRef: MatDialogRef<AdminControlComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private router: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar) { }
  displayedColumns: string[] = ['text', 'correct', 'actions'];
  displayedColumns1: string[] = ['text', 'actions'];
  dataSource1
  dataSource
  ngOnInit(): void {
    this.err = false;
    this.err1 = false;
    this.unitId = this.data.id
    this.sectionId = this.data.trainingSectionId
    this.trainingId = this.data.trainingId
    this.fetchControlQuestion()
    this.fetchAnswersForControlQuestion()
    this.getLang()
    // this.firstFormGroup = this._formBuilder.group({
    //   firstCtrl: ['', Validators.required]
    // });
    // this.secondFormGroup = this._formBuilder.group({
    //   secondCtrl: ['', Validators.required]
    // });
  }

  onNoClick() {
    this.dialogRef.close()
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  fetchAnswersForControlQuestion() {
    this.dashboardService
      .fetchAnswersForControlQuestion(this.trainingId, this.sectionId, this.data.id)
      .subscribe(response => this.dataSource = response)
    this.dashboardService
      .fetchControlQuestion(this.trainingId, this.sectionId, this.data.id)
      .subscribe((response) => {
        this.dataSource1 = response
        this.question = response as controlQuestion
      })
  }

  fetchControlQuestion() {
    console.log(this.trainingId)
    console.log(this.sectionId)
    console.log(this.data.id)
    this.dashboardService
      .fetchControlQuestion(this.trainingId, this.sectionId, this.data.id)
      .subscribe((response) => {
        this.dataSource1 = response
        this.question = response as controlQuestion
      })
  }

  onSubmit() {
    if (this.insertForm.value.questionText == "" || this.insertForm.value.type == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.dashboardService.insertControlQuestion({
        flg: 'jeste',
        id: this.data.id,
        trainingSectionId: this.sectionId,
        trainingId: this.trainingId,
        text: this.insertForm.value.questionText,
        type: this.insertForm.value.type
      }).subscribe((response) => {
        console.log(response)
      })
      this.questionDisplay = "none";
    }
  }

  goToNextStep(stepper: MatStepper) {
    if (this.insertForm.value.questionText == "" || this.insertForm.value.type == "") {
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      stepper.next()
    }
  }

  // promeniCorrect(e:any){
  //   if(e.checked){
  //     this.correct = true;
  //   }else{
  //     this.correct = false;
  //   }
  // }

  submitAns() {
    if (this.insertForm1.value.textAns1 == "") {
      this.err1 = true;
      this.err = false;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.dashboardService.insertControlQuestion({
        flg: 'nije',
        con_id: this.data.id,
        sectionId: this.sectionId,
        trainingId: this.trainingId,
        textAns1: this.insertForm1.value.textAns1,
        correct: false
      }).subscribe((response) => {
        console.log(response)
        this.insertedAns = ""
        setTimeout(() => {
          this.fetchAnswersForControlQuestion()
        }, 1000)
      })
    }
    //  this.fetchAnswersForControlQuestion()

    // this.dataSource.push({
    //     idControl: this.idKontrolno,
    //     textAns1: this.insertForm1.value.textAns1,
    //     correctAns: this.correct
    // });
    // this.table.renderRows();
  }

  setTypeOfAnswer(number) {
    console.log(number)
    this.typeOfQuestion = number
  }

  changeStatus(answer) {
    console.log(this.question[0].type)
    // this.dashboardService.changeControlAnswerStatus({id:answer.id,correct:!answer.correct})
    // .subscribe((response)=>{
    //   console.log(response)
    //   this.dashboardService.fetchAnswersForControlQuestion(this.data.id).subscribe(response=>{
    //     this.dataSource=response
    //     return 
    //   })
    // })

    if (this.question[0].type == 2) {
      this.dashboardService.changeControlAnswerStatus({ trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.unitId, questionId: this.question[0].id, id: answer.id, correct: !answer.correct })
        .subscribe((response) => {
          console.log(response)
          this.dashboardService.fetchAnswersForControlQuestion(this.trainingId, this.sectionId, this.data.id).subscribe(response => {
            this.dataSource = response
            return
          })
        })
      return
    }
    if (this.question[0].type == 1) {
      for (let index = 0; index < this.dataSource.length; index++) {
        const element = this.dataSource[index];

        if (element.id === answer.id) {
          this.dashboardService.changeControlAnswerStatus({ trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.unitId, questionId: this.question[0].id, id: element.id, correct: !answer.correct }).subscribe((response) => {
            this.dashboardService.fetchAnswersForControlQuestion(this.trainingId, this.sectionId, this.data.id).subscribe(
              response => this.dataSource = response
            )
          })
        } else {
          this.dashboardService.changeControlAnswerStatus({ trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.unitId, questionId: this.question[0].id, id: element.id, correct: answer.correct }).subscribe((response) => {
            this.dashboardService.fetchAnswersForControlQuestion(this.trainingId, this.sectionId, this.data.id).subscribe(
              response => this.dataSource = response
            )
          })
        }

      }
      return
    }
  }

  setNumOfAns(num) {
    this.numOfAns = num;
    console.log("broj odgovora")
    console.log(num);
  }

  deleteDialog(answer) {
    const dialogRef = this.dialog.open(DeleteControlDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.unitId, questionId: answer.trainingUnitId, id: answer.id }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
        this.fetchAnswersForControlQuestion()
    })

  }

  editDialog(answer) {
    const dialogRef = this.dialog.open(EditControlDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.unitId, questionId: answer.trainingUnitId, id: answer.id, textAns1: answer.textAns1 }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
        this.fetchAnswersForControlQuestion()
    })

  }

  deleteQuestionDialog(question) {
    const dialogRef = this.dialog.open(DeleteControlQuestionDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, id: question.unitId }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource1 = null
      this.dashboardService.fetchControlQuestion(this.trainingId, this.sectionId, question.unitId).subscribe(response => {
        this.dataSource1 = response
      })
      this.fetchControlQuestion()
    })
  }

  editQuestionDialog(question) {
    console.log(question)
    const dialogRef = this.dialog.open(EditControlQuestionDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.unitId, id: question.id, questionText: question.text }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource1 = null
      this.fetchControlQuestion()
    })
  }

}
