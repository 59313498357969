import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit {
  @ViewChild('insertForm') insertForm: NgForm
  labels;
  labelsSub: Subscription;
  err
  admin
  adminSub: Subscription
  constructor(private languageService: AdminLanguageService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddEventComponent>,
    private dashboardService: DashboardService,
    private authService: AdminAuthService) { }

  ngOnInit(): void {
    this.err = false
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.adminSub = this.authService.admin$.subscribe((admin) => {
      this.admin = admin;
    });
  }

  onSubmit(){
    console.log("pozvao onsubmit")
    if (this.insertForm.value.title == "" || this.insertForm.value.description == "" || this.insertForm.value.date == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      let data = {
        title: this.insertForm.value.title,
        description: this.insertForm.value.description,
        date: this.insertForm.value.date
      }
      this.dashboardService.addEvent(data).subscribe((response) => {
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblEventAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      })
    }
  }

  onNoClick() {
    this.dialogRef.close()
  }

}
