import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Subscription } from 'rxjs';
import { Department } from 'src/app/models/department.model';
import { Position } from 'src/app/models/positon.model';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-add-pending-user',
  templateUrl: './add-pending-user.component.html',
  styleUrls: ['./add-pending-user.component.scss']
})
export class AddPendingUserComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  departments:Department[]
  positions:Position[]
  errorClass = ""
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  isCompleted
  isEditable: boolean
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddPendingUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }
    @ViewChild('insertForm') insertForm:NgForm
  ngOnInit(): void {
    this.err = false;
    this.fetcDepartments()
    this.fetchPositions()
    this.getLang();
  }

  onSubmit(){
    console.log("ON SUBMIT")
    console.log(this.insertForm.value.email)
    console.log(this.data.email)
    if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == "" || this.insertForm.value.departmentId == "" || this.insertForm.value.email == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
    if(this.insertForm.value.password == this.insertForm.value.confirmPassword){
      this.insertUser(this.insertForm.value)
      console.log(this.insertForm.value)
      setTimeout(() => {
        this.dialogRef.close()
      },2000)
    }else{
      this.snackBar.open(this.labels.lblPasswordError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }
    }
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  onNoClick(): void {
  this.dialogRef.close();
  }

  fetcDepartments(){
    return this.dashboardService.fetchAllDepartments().subscribe((response)=>{
      this.departments=response as Department[]
    })
  }
  fetchPositions(){
    return this.dashboardService.fetchPositions().subscribe((response)=>{
      this.positions=response as Position[]

    })
  }
  getUsers(){
    this.dashboardService.getUsers().subscribe((users)=>{
      console.log(users)
    })
  }

  insertUser(data){
    this.dashboardService.insertPendingUser(data).subscribe((response)=>{
      this.getUsers()
      console.log("response")
      console.log(response)
      this.snackBar.open(this.labels.lblUserAdded, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    })
  }

  goToNextStep(stepper: MatStepper){
    if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == "") {
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      if(this.insertForm.value.password == this.insertForm.value.confirmPassword){
        this.isCompleted = true
        stepper.next()
      }else{
        this.snackBar.open(this.labels.lblPasswordError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
    this.isCompleted = false;
  }
  goBack(stepper: MatStepper){
    stepper.previous()
  }

}
