<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblEditAdministrator}} "{{ data.firstName }}"</h1>
<div mat-dialog-content style="overflow:unset;">
  <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="height: 450px; overflow-y: unset;">
    <div fxLayout="column">
        <input type="hidden" name="id" [ngModel]="data.id">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblUserName}}</mat-label>
            <input matInput [ngModel]="data.userName" name="userName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblFirstName}}</mat-label>
            <input matInput [ngModel]="data.firstName" name="firstName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblLastName}}</mat-label>
            <input matInput [ngModel]="data.lastName" name="lastName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none">
          <mat-label>Kompanija</mat-label>
          <mat-select [ngModel]="data.companyId" name="companyId" required>
            <mat-option [value]="1">
              Elektromreža Srbije
            </mat-option>
            <mat-option [value]="2">
              NIS
            </mat-option>
            <mat-option [value]="3">
              Evropska konsalting grupa
            </mat-option>
            <mat-option [value]="4">
              RGZ
            </mat-option>
          </mat-select>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" style="color:#8293c6">
            <mat-label>{{labels.lblSector}}</mat-label>
            <mat-select [ngModel]="data.departmentId" name="departmentId" style="background-color:#182038;color:#8293c6" required>
              <mat-option *ngFor="let department of departments" [value]="department.id" style="background-color:#182038;color:#8293c6">
                {{department.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>E-mail</mat-label>
            <input matInput [ngModel]="data.email" name="email">
        </mat-form-field>
        <div mat-dialog-actions>
            <button class="btn" style="margin-left: 0px;" cdkFocusInitial type="submit">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div>
