import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-delete-control-question-dialog',
  templateUrl: './delete-control-question-dialog.component.html',
  styleUrls: ['./delete-control-question-dialog.component.scss']
})
export class DeleteControlQuestionDialogComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteControlQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getLang()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  deleteControlQuestion(){
    this.dashboardService.deleteControlQuestion(this.data.trainingId, this.data.sectionId, this.data.id).subscribe((response)=>{
      console.log(response)
      this.snackBar.open(this.labels.lblControlQuestionDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
  }

  onNoClick(){
    this.dialogRef.close()
  }
}
