<app-admin-spinner *ngIf="dataSource == null || labels == null"></app-admin-spinner>
<div fxLayout="row table1" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf="outlet.isActivated===false">
  <div fxFlex="5"></div>
  <div fxFlex="90">
    <!-- <mat-card cdkDrag>
            <div fxLayout="row" fxFlex='100'>
                <mat-card-title fxFlex="90">Table of Tests</mat-card-title>
                  <mat-card-content fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                    <button class="tableBtn" mat-raised-button (click)="openInsertDialog()">Add new test</button>
              
                  </mat-card-content>
              </div>
        </mat-card> -->
    <div class="mat-elevation-z8">
      <div class="row header">
        <div class="col-md-4 text-left">
          <h5 class="tableName">{{labels.lblTest}}/{{labels.lblSurvey}}</h5>
        </div>
        <div class="col-md-8 text-right"><button class="tableBtn" mat-raised-button
            (click)="openInsertDialog()">{{labels.lblAddNewTest}}</button></div>
        <div class="col-10 text-left search1">
          <mat-form-field class="search" appearance="none" fxFlex="40%">
            <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
          </mat-form-field>
      </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20% !important">{{labels.lblType}}</th>
          <td style="width: 20% !important;" mat-cell *matCellDef="let test">
            <span *ngIf='test.type===1'>{{labels.lblTest}}</span>
            <span *ngIf='test.type===2'>{{labels.lblPretest}}</span>
            <span *ngIf='test.type===3'>{{labels.lblSurvey}}</span>
          </td>
        </ng-container>

        
        <!-- email Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblTestSurveyName}} </th>
          <td mat-cell *matCellDef="let test" data-toggle="tooltip" data-placement="top" title={{test.name}} [routerLink]="['/admin/test-details',test.id]" class="testName">
            {{test.name.substring(0,20)}} <span *ngIf="test.name.length > 20">...</span> </td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="minPercentage">
          <th style = "width: 20%" mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblMinPercentage}} </th>
          <td style = "width: 20%" mat-cell *matCellDef="let test" class="hideColumn">
            {{test.minPercentage}} <span *ngIf="test.type == 1">%</span> </td>
        </ng-container>


        <!-- dateCreated Column -->
        <ng-container matColumnDef="dateCreated">
          <th style="width: 20%" mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblDateCreated}}</th>
          <td style="width: 20%;" mat-cell data-toggle="tooltip" data-placement="top" title={{test.dateCreated}}
            *matCellDef="let test" class="hideColumn"> {{test.date}}</td>
        </ng-container>

        <!--Actions column-->
        <ng-container matColumnDef="actions">
          <th style="width:10%" mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
          <td style="width: 10%;" mat-cell *matCellDef="let test">
            <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="openEditDialog(test)">
                        {{labels.lblEdit}}
                      </button>
                      <button class="deleteBtn d-none d-sm-inline-flex" (click)="openDeleteDialog(test)">
                        {{labels.lblDelete}}
                      </button> -->

            <!-- <button class="addNewBtn d-sm-inline-flex" [routerLink]="['/admin/tests',test.id]">
              {{labels.lblQuestions}}
            </button> -->
            <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
            <mat-menu #settingsMenu="matMenu" class="mMenu">
              <button mat-menu-item class="menuButton settingsMenuItem" [routerLink]="['/admin/test-details',test.id]">
                <mat-icon style="color: #8293c6;">subject</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblMoreDetails}}</span>
              </button>
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openEditDialog(test)">
                <mat-icon style="color: #8293c6;">edit</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
              </button>
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(test)"
                [disabled]="test.assignedToTrainings.length > 0">
                <mat-icon style="color: #8293c6;">delete</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
              </button>
            </mat-menu>

            <!-- mobile -->
            <!-- <button class="editBtn d-block d-sm-none mobBtn" (click)="openEditDialog(test)">
              {{labels.lblEdit}}
            </button>
            <button class="deleteBtn d-block d-sm-none mobBtn" (click)="openDeleteDialog(test)">
              {{labels.lblDelete}}
            </button>

            <button class="addNewBtn d-block d-sm-none mobBtn" (click)="openTestStepper(test)">
              {{labels.lblQuestions}}
            </button> -->

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div fxFlex="5"></div>
</div>
<router-outlet #outlet="outlet"></router-outlet>
<!-- <app-admin-test-question></app-admin-test-question> -->