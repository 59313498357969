import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
//import { ZoomMtg } from '@zoomus/websdk';

@Component({
  selector: 'app-admin-chat-meeting',
  templateUrl: './admin-chat-meeting.component.html',
  styleUrls: ['./admin-chat-meeting.component.scss']
})
export class AdminChatMeetingComponent implements OnInit {
  apiKey = '984gQm5jSnaZ2wFJ16TrGA'
  apiSecret = '5h3I41usJymgjzBSPfFAGFxelkJGkRbH0fdZ'
  meetingNumber
  // leaveUrl = 'http://localhost:1738/user/messages'
  leaveUrl = "https://zoom.us/oauth/authorize?response_type=code&client_id=fU1My98QC2ipHpvi5kRXA&redirect_uri=http%3A%2F%2Flocalhost%3A1738%2Fadmin%2Fmessages"
  userName = 'Klijent'
  userEmail = ''
  passWord = 'testPass'
  registrantToken = ''
  signature
  meetingId
  attendees
  userSub: Subscription
  user
  userId
  constructor(public db: AngularFirestore, public snackBar: MatSnackBar, private authService: AdminAuthService) { }

  ngOnInit(): void {
    this.userSub = this.authService.admin$.subscribe((admin) => {
      this.user = admin;
      setTimeout(() => {
        let role = "1"
        let meetingHost = false
        this.db.firestore.collection("administrators").doc(this.user.id).get().then((doc) => {
           let user = doc.data()
           console.log("user: ", user)
           meetingHost = doc.data().meetingHost
           if(meetingHost == true){
             console.log("USAO U IF")
            role = "1"
           }else{
             console.log("USAO U ELSE")
            role = "0"
           }
           //this.getSignature(user.currentMeetingId,user.userName, role)
        })
       this.userId = this.user.userId
       this.db.firestore.collection("administrators").doc(this.user.id).update({onMeeting: true})
      }, 300);
    })
     
  }

  // getSignature(meetingId, userName, role) {
  //   console.log("ROLE", role)
  //   console.log("meeting id: ", meetingId)
  //   console.log("username: ", userName)
  //   ZoomMtg.preLoadWasm();
  //   ZoomMtg.prepareJssdk();
  //   console.log("meeting id: ", meetingId)
  //   this.signature = ZoomMtg.generateSignature({
  //    meetingNumber: String(meetingId),
  //    apiKey: this.apiKey,
  //    apiSecret: this.apiSecret,
  //    role: role,
  //    success: res => {
  //      console.log(res.result)
  //    }
  //   });
  //   this.startMeeting(this.signature, meetingId, userName)
  //  }
 
  //  startMeeting(signature, meetingId, userName) {
  //    console.log(signature)
  //    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.7/lib', '/av');
  //    ZoomMtg.preLoadWasm();
  //    ZoomMtg.prepareJssdk();
  //    //document.getElementById('zmmtg-root').style.display = 'block'
  //   //  document.getElementById('zmmtg-root').style.width = '500px'
  //   //  document.getElementById('zmmtg-root').style.height = '500px'
  //   //  document.getElementById('zmmtg-root').style.zIndex = '999'
  //    document.getElementById('containerId').style.height = '0px';
  //    ZoomMtg.init({
  //      leaveUrl: this.leaveUrl,
  //      success: (success) => {
  //        console.log(success)
         
  //        ZoomMtg.join({
  //          signature: signature,
  //          meetingNumber: meetingId,
  //          userName: userName,
  //          apiKey: this.apiKey,
  //          passWord: this.passWord,
  //          success: (success) => {
  //            console.log(success)
  //            console.log("GET ATTENDEES LIST: ")
  //            ZoomMtg.getAttendeeslist({
  //             success: function (res) {
  //               console.log(res, "get getAttendeeslist");
  //             }
  //           });
  //          },
  //          error: (error) => {
  //            console.log("ERROR 1")
  //            console.log(error)
  //          }
  //        })
 
  //      },
  //      error: (error) => {
  //       console.log("ERROR ")
  //        console.log(error)
  //      }
  //    })
  //  }

}
