import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('forgotPassForm') forgotPassForm:NgForm
  labels;
  labelsSub: Subscription;
  constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>,@Inject(MAT_DIALOG_DATA) public data,
  private dashboardService:DashboardService,
  private adminLanguageService: AdminLanguageService, public dialog:MatDialog) { }

  ngOnInit(): void {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) =>{
      this.labels = labels;
    })
  }
  onSubmit(formData){
    this.dashboardService.resetPassword({email: formData.value.email,lang: this.data.lang}).subscribe((response)=>{
      console.log(response)
      this.dialogRef.close()
    })
  }

  cancelModal(){
    this.dialogRef.close()
  }
}
