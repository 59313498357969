<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblEditUnit}} "{{data.name}}"</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #editForm="ngForm" >
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblUnitName}}</mat-label>
            <input matInput [ngModel]='data.name' name="name" required>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblType}}</mat-label>
            <mat-select [ngModel]="data.type" name='type' *ngIf = "assigned.length == 0" required>
              <mat-option (click) = "onSelect(1)" value="1">{{labels.lblVideo}}</mat-option>
              <mat-option (click) = "onSelect(2)" value="2">{{labels.lblAudio}}</mat-option>
              <mat-option (click) = "onSelect(3)" value="3">{{labels.lblImageUnit}}</mat-option>
              <mat-option (click) = "onSelect(4)" value="4">{{labels.lblDocument}}</mat-option>
              <mat-option (click) = "onSelect(5)" value="5">{{labels.lblTextUnit}}</mat-option>
            </mat-select>
            <input *ngIf = "assigned.length > 0" matInput [ngModel] = "data.type" name = "type" style="color: white" hidden>
            <div *ngIf = "assigned.length > 0">
                <span *ngIf = "data.type == 1">{{labels.lblVideo}}</span>
                <span *ngIf = "data.type == 2">{{labels.lblAudio}}</span>
                <span *ngIf = "data.type == 3">{{labels.lblImageUnit}}</span>
                <span *ngIf = "data.type == 4">{{labels.lblDocument}}</span>
                <span *ngIf = "data.type == 5">{{labels.lblTextUnit}}</span>
            </div>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <div class="div" *ngIf = "unitType == 1">
            <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "assigned.length == 0 && data.sectionUnit.videoPath == null">
                <input 
                type="file" 
                (change)="onVideoChanged($event)" 
                #videoUpload
                style="display: none;"
                >
                <mat-label style="color: #3e4b71;">{{labels.lblUploadVideo}}</mat-label>
                <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
            </mat-form-filed>
            <div *ngIf = "data.sectionUnit.videoPath != null" style="width: 100%;">
                <div *ngIf="data.sectionUnit.videoPath != null">
                    <mat-label >{{labels.lblVideo}}: {{data.sectionUnit.videoName}}</mat-label>
                    <mat-icon *ngIf = "assigned.length == 0" style="float:right; cursor: pointer;" (click)="updateData(1)">cancel</mat-icon>
                </div>
            </div>
        </div>
        <div class="div" *ngIf = "unitType == 2">
            <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "assigned.length == 0 && data.sectionUnit.audioPath == null">
                <input 
                type="file" 
                (change)="onVideoChanged($event)" 
                #videoUpload
                style="display: none;"
                >
                <mat-label style="color: #3e4b71;">{{labels.lblUploadAudio}}</mat-label>
                <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
            </mat-form-filed>
            <div *ngIf = "data.sectionUnit.audioPath != null" style="width: 100%;">
                <div>
                    <mat-label >{{labels.lblAudio}}: {{data.sectionUnit.audioName}}</mat-label>
                    <mat-icon *ngIf = "assigned.length == 0" style="float:right; cursor: pointer;" (click)="updateData(2)">cancel</mat-icon>
                </div>
            </div>
        </div>
        <div class="div" *ngIf = "unitType == 1">
            <mat-form-filed appearance="none" fxFlex="80%" *ngIf = "assigned.length == 0 && data.sectionUnit.videoPathMobile == null">
                <input 
                type="file" 
                (change)="onVideoChangedMobile($event)" 
                #videoUploadMobile
                style="display: none;"
                >
                <mat-label style="color: #3e4b71;">{{labels.lblUploadVideo}} {{labels.lblForMobile}}</mat-label>
                <mat-icon class="icon" (click)="videoUploadMobile.click()">videocam</mat-icon>
            </mat-form-filed>
            <div *ngIf = "data.sectionUnit.videoPathMobile != null" style="width: 100%;">
                <div *ngIf="data.sectionUnit.videoPathMobile != null">
                    <mat-label >{{labels.lblVideo}} {{labels.lblForMobile}}: {{data.sectionUnit.videoNameMobile}}</mat-label>
                    <mat-icon *ngIf = "assigned.length == 0" style="float:right; cursor: pointer;" (click)="updateData(5)">cancel</mat-icon>
                </div>
            </div>
        </div>
        <div class="div" *ngIf = "unitType == 2">
            <mat-form-filed appearance="none" fxFlex="80%" *ngIf = "assigned.length == 0 && data.sectionUnit.audioPathMobile == null">
                <input 
                type="file" 
                (change)="onVideoChangedMobile($event)" 
                #videoUploadMobile
                style="display: none;"
                >
                <mat-label style="color: #3e4b71;">{{labels.lblUploadAudio}} {{labels.lblForMobile}}</mat-label>
                <mat-icon class="icon" (click)="videoUploadMobile.click()">videocam</mat-icon>
            </mat-form-filed>
            <div *ngIf = "data.sectionUnit.audioPathMobile != null" style="width: 100%;">
                <div *ngIf="data.sectionUnit.audioPathMobile != null">
                    <mat-label >{{labels.lblAudio}} {{labels.lblForMobile}}: {{data.sectionUnit.audioNameMobile}}</mat-label>
                    <mat-icon *ngIf = "assigned.length == 0" style="float:right; cursor: pointer;" (click)="updateData(6)">cancel</mat-icon>
                </div>
            </div>
        </div>
        <div class="div" *ngIf = "unitType == 4">
            <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "assigned.length == 0 && data.sectionUnit.documentPath == null">
                <input 
                type="file" 
                (change)="onDocumentChanged($event)" 
                #documentUpload
                style="display: none;"
                >
                <mat-label style="color: #3e4b71;">{{labels.lblUploadDoc}} </mat-label>
                <mat-icon class="icon" (click)="documentUpload.click()">description</mat-icon>
            </mat-form-filed>
            <div *ngIf = "data.sectionUnit.documentPath != null" style="width: 100%;">
                <mat-label>{{labels.lblDoc}}: {{data.sectionUnit.documentName}}</mat-label>
                <mat-icon *ngIf = "assigned.length == 0" style="float:right; cursor: pointer;" (click)="updateData(3)">cancel</mat-icon>
            </div>
        </div>
        <div class="div" *ngIf = "unitType == 3">
            <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "assigned.length == 0 && data.sectionUnit.imagePath == null">
                <input 
                type="file" 
                (change)="onImageChanged($event)" 
                #imageUpload
                style="display: none;"
                >
                <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
                <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
            </mat-form-filed>
            <div *ngIf = "data.sectionUnit.imagePath != null" style="width: 100%;">
                <mat-label>{{labels.lblImage}}: {{data.sectionUnit.imageName}}</mat-label>
                <mat-icon *ngIf = "assigned.length == 0" style="float:right; cursor: pointer;" (click)="updateData(4)">cancel</mat-icon>
            </div>
        </div>
        <mat-form-filed appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblMessageForUnit}}</mat-label>
            <textarea matInput [ngModel]='data.sectionUnit.text' name="text" style="border:1px solid #9dacd9; border-radius: 7px;"></textarea>
        </mat-form-filed>
        <div mat-dialog-actions>
            <mat-progress-bar mode="indeterminate" style="margin-top: 20px;" *ngIf="uploading"></mat-progress-bar>
            <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
            <button class="btn" cdkFocusInitial type="submit" style="float: right; margin-top: 11px;">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div>


