import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';
import { UserBeforeTestMessageComponent } from '../../user-training/user-before-test-message/user-before-test-message.component';

@Component({
  selector: 'app-user-before-survey-message',
  templateUrl: './user-before-survey-message.component.html',
  styleUrls: ['./user-before-survey-message.component.scss']
})
export class UserBeforeSurveyMessageComponent implements OnInit {

  test: Test
  testSub: Subscription
  survey$: Observable<Test>

  labelsSub:Subscription
  labels

  user
  userSub: Subscription

  button1
  color
  index
  testProgress
  constructor(private authService: AuthService,
    private userDashboardService: UserDashboardService, private userLanguageService: UserLanguageService,
    private router: Router, private localStorageService:LocalStorageService) { }

  ngOnInit(): void {
    this.localStorageService.setItem('currentQuestionIndex', 0)

    // this.testSub = this.userDashboardService.survey$.subscribe((test) => {
    //   console.log("SURVEY BEFORE SURVEY")
    //   this.test = test;
    //   console.log(this.test)
    // })

    this.userDashboardService.getSurveyForTraining(this.localStorageService.getItem('surveyId')).subscribe((test: Test) => {
      console.log("SURVEY before: ")
      this.test = test;
        console.log(this.test)
    });


    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) =>{
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.button1 = "buttonEMS"
          this.color = "#49D14F"

        }else if(this.user.companyId == 2){
          this.button1 = "buttonECG"
          this.color = "#49D14F"
        }
        else{
          this.button1 = "buttonECG"
          this.color = "#49D14F"
        }
      }, 200);
    });
  }

  goToTestPage(){
    //window.location.reload()
    // this.dialogRef.close()
    this.router.navigateByUrl('user/survey')
  }

}
