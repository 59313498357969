import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { Unit } from 'src/app/models/unit.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';
import { UserBeforeTestMessageComponent } from '../../user-training/user-before-test-message/user-before-test-message.component';

@Component({
  selector: 'app-user-pretest-message',
  templateUrl: './user-pretest-message.component.html',
  styleUrls: ['./user-pretest-message.component.scss']
})
export class UserPretestMessageComponent implements OnInit {

  test: Test
  testSub: Subscription

  labels;
  labelsSub: Subscription;

  training$: Observable<Training>
  training: Training
  trainingSub: Subscription

  user
  userSub: Subscription
  button1
  color
  constructor(public dialogRef: MatDialogRef<UserBeforeTestMessageComponent>,
    private userDashboardService: UserDashboardService,
    private authService: AuthService,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, private userLanguageService: UserLanguageService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.button1 = "buttonEMS"
          this.color = "#c32a2a"

        }else if(this.user.companyId == 2){
          this.button1 = "buttonECG"
          this.color = "rgb(241,106,100)"
        }
        else{
          this.button1 = "buttonECG"
          this.color = "rgb(241,106,100)"
        }
      }, 200);
    });
    this.training$ = this.userDashboardService.getTraining().pipe(share());

    this.trainingSub = this.userDashboardService.training$.subscribe((training) => {
      this.training = training;
    })

    this.testSub = this.userDashboardService.pretest$.subscribe((test) => {
      this.test = test;
    })

    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

  }

  goToTraining() {
    this.dialogRef.close()
    setTimeout(() => {
      this.localStorageService.setItem('currentQuestionIndex', 0)
      this.localStorageService.setItem('questionScores', [])
      //const trainingId = this.localStorageService.getItem('trainingId')
      this.router.navigateByUrl('user/training')
    }, 100);


  }




}
