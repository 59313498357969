import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { DashboardService } from 'src/app/modules/dashboard.service';
import { ImageCropperDialogComponent } from 'src/app/modules/image-cropper-dialog/image-cropper-dialog.component';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service';
import { UserDashboardService } from 'src/app/modules/user-dashboard.service';

@Component({
  selector: 'app-edit-test-question',
  templateUrl: './edit-test-question.component.html',
  styleUrls: ['./edit-test-question.component.scss']
})
export class EditTestQuestionComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  @ViewChild('videoUpload') videoUpload: ElementRef
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  testId
  assigned
  selectedImage: File = null
  selectedVideo: File = null
  imgPath: null
  imgExtError: boolean = false;
  videoExtError: boolean = false;
  test
  test$: Observable<Test>
  testSub: Subscription
  uploading: boolean = false
  constructor(private storage: AngularFireStorage, public dialogRef: MatDialogRef<EditTestQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar,
    private userDashboarService: UserDashboardService, public dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.data)
    this.err = false;
    this.getLang()
    this.getFileNames()
    this.testId = this.data.testId
    console.log(this.testId)
    this.isTestAssigned()
    this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.testId).pipe(share())
    this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
      this.test = test;
    });
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  isTestAssigned() {
    this.dashboardService.isTestAssigned(this.testId).subscribe((response) => {
      console.log(response)
      if (response == null) {
        this.assigned = []
      } else {
        this.assigned = response
      }
      console.log(this.assigned)
    })
  }

  async onSubmit() {
    if (this.editForm.value.text == "" || this.editForm.value.points == "" || this.editForm.value.type == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else if (!this.imgExtError && !this.videoExtError) {
      this.uploading = true

      if (this.selectedImage !== null) {
        let ref = this.storage.ref(this.selectedImage.name)
        await ref.put(this.selectedImage)
        this.data.imagePath = await ref.getDownloadURL().toPromise()
      }

      if (this.selectedVideo !== null) {
        let ref = this.storage.ref(this.selectedVideo.name);
        await ref.put(this.selectedVideo);
        this.data.videoHintPath = await ref.getDownloadURL().toPromise();
      }

      this.dashboardService.editQuestionForTest({
        id: this.editForm.value.id,
        testId: this.editForm.value.testId,
        text: this.editForm.value.text,
        points: this.editForm.value.points,
        type: this.editForm.value.type,
        imagePath: this.data.imagePath,
        videoHintPath: this.data.videoHintPath
      }).subscribe((response) => {
        this.uploading = false
        console.log(response)
        this.snackBar.open(this.labels.lblQuestionEdited, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
      })
    }
  }

  onNoClick() {
    this.dialogRef.close()
  }

  onImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "jpg" || ext == "jpeg" || ext == "png") {
      this.imgExtError = false;
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: Number = +iConvert
      if (number <= 5) {
        this.selectedImage = event.target.files[0]
        const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
          width: "500px",
          height: "600px",
          data: event
        })

        dialogRef.afterClosed().subscribe((result) => {
          this.imageUpload.nativeElement.value = ""
          this.selectedImage = result
          this.data.imageName = this.selectedImage.name
          this.data.imagePath = ""
        })
      }
      else {
        this.snackBar.open(this.labels.lblMaxFileSizeImage, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }

    }
    else {
      this.imgExtError = true;
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }
  }

  getFileNames() {
    if (this.data.imagePath != null) {
      let array = this.data.imagePath.split('?')
      let array1 = array[0].split('/')
      this.data.imageName = array1[array1.length - 1]
    }

    if (this.data.videoHintPath != null) {
      let array = this.data.videoHintPath.split('?')
      let array1 = array[0].split('/')
      this.data.videoName = array1.pop();
    }

  }

  updateData() {
    this.data.imagePath = null
    this.selectedImage = null
  }

  cancelVideo() {
    this.data.videoHintPath = null
    this.selectedVideo = null
  }

  onVideoChanged(event) {
    this.selectedVideo = event.target.files[0];
    this.data.videoName = this.selectedVideo.name;
    this.data.videoHintPath = "";
    const ext = this.selectedVideo.name.split('.').pop()

    if(ext == "mp4") {
      this.videoExtError = false;
    }
    else {
      this.videoExtError = true;
      this.userDashboarService.snackMsg(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo);
    }
  }

}
