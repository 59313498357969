<div class="container">
    <!-- <img class="imageFade" style="border:1px solid transparent; border-radius:50%; margin-bottom:20px;" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testIcon.png?alt=media&token=b7ba3f32-124c-4fef-82f3-a2e2c858f4e5" alt=""> -->
    <div class='row'>
        <div class = "col-11 col-sm-11" style = "margin: auto; margin-top: 11%;">
            <h1 class="doneLabel" style = "font-weight: bold;font-family: Lato, 'Alegreya Sans'; color: white; font-size: 40px;">{{labels.lblPreTest}} {{labels.doneLabel}}</h1>
            <h3 style = "font-family: Lato, 'Alegreya Sans'; color: white; opacity: 0.5; font-weight: normal; margin-top: 7%; font-size: 21px;">
                {{training.name}}. <br>
                {{labels.lblYourSuccesRate}} <span class = "procenat" [style.color] = "color">{{data.percentage}}%</span><br>
                {{labels.lblYouAnswered}} <span class = "procenat" [style.color] = "color">{{data.corrcetAnswersCounter}}</span> {{labels.lblOf}}
                <span [style.color] = "color" class = "procenat">{{data.questions}}</span> {{labels.lblQuestions}}<br>
                {{labels.lblYouHaveWon}} <span class = "procenat" [style.color] = "color">{{data.points}}</span> {{labels.lblOf}} <span
                [style.color] = "color" class = "procenat" >{{data.maxPoints}}</span> {{labels.lblPoints}}.
            </h3>
            <div class="row" style = "text-align: center;">
                <div class="col-12" style = "margin: auto;">
                  <button class="login-button" (click)="goToTraining()">
                      <div class = "col-11" style = "margin: auto; text-align: center;">
                        <h3 class = "loginBtnLabel">{{labels.lblNext}}</h3>
                      </div>
                  </button>
                </div>
              </div>
        </div>
    </div>
</div>
        