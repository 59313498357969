<div id="chart">
  <div style="text-align: center;">
    <h3>{{labels.lblTrainingSuccessRate}}</h3>
  </div>
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [responsive]="chartOptions.responsive"
    ></apx-chart>
  </div>
  