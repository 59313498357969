<html>
    <head></head>
    <body style="background: #0d1122 !important;">
        <div class = "row" style="padding-top: 11%;">
            <div class = "col-12 col-lg-6" style="margin: auto; text-align: center; padding: 30px; border: 2px solid rgb(241,106,100); border-radius: 7px;  background-color:rgb(250,240,239);">
                <h2>Uspešno ste verifikovali Vašu e-mail adresu.</h2>
                <h2>Redirektujte se na našu stranu:</h2>
                <h3><a href="https://leste.lesteplatform.com" style = "font-size: 23px; color: darkslategray; font-family: 'Poppins';">LESTE ePlatform</a></h3>
            </div>
        </div>
    </body>
</html>

