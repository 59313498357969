import { Component, Inject, OnInit } from '@angular/core';
//import { ZoomMtg } from '@zoomus/websdk';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-zoom-meeting',
  templateUrl: './admin-zoom-meeting.component.html',
  styleUrls: ['./admin-zoom-meeting.component.scss']
})
export class AdminZoomMeetingComponent implements OnInit {
  apiKey = '984gQm5jSnaZ2wFJ16TrGA'
  apiSecret = '5h3I41usJymgjzBSPfFAGFxelkJGkRbH0fdZ'
  meetingNumber
  role = 0
  leaveUrl = "https://zoom.us/oauth/authorize?response_type=code&client_id=cDQPVo5qSoyvYSPlwTQZJw&redirect_uri=https://leste.lesteplatform.com/admin/zoom-meetings"
  userName = 'Klijent'
  userEmail = ''
  passWord = 'testPass'
  registrantToken = ''
  signature
  meetingId
  meetingIdSub: Subscription
  meetingId$: Observable<Number>
  attendees
  user
  userSub: Subscription

  usersWhoLeft = ""
  id
  constructor(public httpClient: HttpClient,  @Inject(DOCUMENT) document, private db: AngularFirestore, private dashboardService: DashboardService,
  private authService: AdminAuthService,
  private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.userSub = this.authService.admin$.subscribe((user) => {
      this.user = user;
     setTimeout(() => {
      //this.getSignature(this.router.snapshot.params['id'], this.user.userName)
     }, 200);
    });
    this.id = this.router.snapshot.params['id']
    console.log("route id: ", this.id)
    let ref = this.db.firestore.collection("zoomMeetings")
    let router = this.router.snapshot.params['id']
    let userLeft = []
    let userRef = this.db.firestore.collection("users")
    // ZoomMtg.inMeetingServiceListener('onUserLeave', function (data) {
    //   console.log("ON USER LEAVE: ", data);
    //   userLeft.push(data.userName)
    //   let at = []
    //    ZoomMtg.getAttendeeslist({
    //           success: function (res) {
    //             at = res.result.attendeesList
    //             console.log("ATTENDEES ADMIN LIST", at)
    //             console.log("users who left: ", userLeft)
    //             if(userLeft.length == at.length-1){
    //                  ref.where("meetingId", "==", Number(router)).get().then((query) => {
    //                   query.forEach(doc => {
    //                     ref.doc(doc.id).update({finished: true, next: false, started: false})
    //                     userLeft.forEach((u) => {
    //                       userRef.where("userName", "==", u).get().then((query1) => {
    //                         query1.forEach((doc1) => {
    //                           userRef.doc(doc1.id).collection("userZoomEvents").where("id", "==", doc.id).get().then((query2) => {
    //                             query2.forEach((doc2) => {
    //                               userRef.doc(doc1.id).collection("userZoomEvents").doc(doc2.id).update({finishDate: new Date()})
    //                             })
    //                           })
    //                         })
    //                       })
    //                     })
    //                   });
    //                 })
    //             }
    //           }
    //     });
    // });
  }

  getMeetingId(userName){
    let events = []
    let docRef = this.db.firestore.collection("zoomMeetings").where("finished", "==", false).where("next", "==", true)
    docRef.get().then((snapshot) => {
      snapshot.forEach((change) => {
        let event = change.data()
        let meetingId = change.data().meetingId
        console.log(meetingId)
        //this.getSignature(change.data().meetingId, userName)
        // event.start = new Date(change.doc.data().date.seconds * 1000)
        // events.push(event)
      });
    });
  }

  // getSignature(meetingId, userName) {
  //   console.log("meeting id: ", meetingId)
  //   this.signature = ZoomMtg.generateSignature({
  //    meetingNumber: String(meetingId),
  //    apiKey: this.apiKey,
  //    apiSecret: this.apiSecret,
  //    role: '1',
  //    success: res => {
  //      console.log(res.result)
  //    }
  //   });
  //   this.startMeeting(this.signature, meetingId, userName)
  //  }
 
  //   startMeeting(signature, meetingId, userName) {
  //    console.log(signature)
  //    document.getElementById('zmmtg-root').style.display = 'block'
  //    document.getElementById('containerId').style.height = '0px';
  //    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.7/lib', '/av');
  //    ZoomMtg.preLoadWasm();
  //    ZoomMtg.prepareJssdk();
  //    ZoomMtg.init({
  //      leaveUrl: this.leaveUrl,
  //      success: (success) => {
  //        console.log(success)
         
  //        ZoomMtg.join({
  //          signature: signature,
  //          meetingNumber: meetingId,
  //          userName: userName,
  //          apiKey: this.apiKey,
  //          passWord: this.passWord,
  //          success: (success) => {
  //            console.log(success)
  //            console.log("Success")
  //           this.db.firestore.collection("zoomMeetings").where("meetingId", "==", Number(meetingId)).get().then((query) => {
  //             query.forEach((doc) => {
  //               console.log("STARTED MEETING: ", doc.data())
  //               this.db.firestore.collection("zoomMeetings").doc(doc.id).update({started: true})
  //             })
  //           })
           
  //          },
  //          error: (error) => {
  //            console.log("ERROR 1")
  //            console.log(error)
  //          }
  //        })
 
  //      },
  //      error: (error) => {
  //       console.log("ERROR ")
  //        console.log(error)
  //      }
  //    })
  //  }

}
