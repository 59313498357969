import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DashboardService } from './../../dashboard.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Department } from './../../../models/department.model'
import { Position } from './../../../models/positon.model'
import { Subscription } from 'rxjs';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {
  username = new FormControl('', [Validators.required]);
  passwordControl = new FormControl('', [Validators.required]);
  firstNameControl = new FormControl('', [Validators.required]);
  lastNameControl = new FormControl('', [Validators.required]);
  departmentControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  departments: Department[]
  positions: Position[]
  errorClass = ""
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  isCompleted
  isEditable: boolean
  admin: Administrator
  adminSub: Subscription
  constructor(private adminAuthService: AdminAuthService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService, private _formBuilder: FormBuilder) { }


  @ViewChild('insertForm') insertForm: NgForm


  onSubmit() {
    console.log("ON SUBMIT")
    if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == "" || this.insertForm.value.departmentId == "" || this.insertForm.value.email == "" || this.insertForm.value.position == "" || this.insertForm.value.positionId == "" || this.insertForm.value.companyId == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      if (this.insertForm.value.password == this.insertForm.value.confirmPassword) {
        this.insertUser(this.insertForm.value)
      } else {
        this.snackBar.open(this.labels.lblPasswordError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.isCompleted = false;
    this.err = false;
    this.getAdmin()
    this.fetchPositions()
    this.getLang();
  }
  fetchDepartments() {
    return this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
    })
  }
  fetchDepartments1() {
    return this.dashboardService.fetchAdminsDepartments(this.admin.departmentId).subscribe((response) => {
      this.departments = response as Department[]
    })
  }
  fetchPositions() {
    return this.dashboardService.fetchPositions().subscribe((response) => {
      this.positions = response as Position[]

    })
  }
  getUsers() {
    this.dashboardService.getUsers().subscribe((users) => {
      console.log(users)
    })
  }

  insertUser(data) {
    this.dashboardService.insertUser(data).subscribe((response) => {
      console.log(response)
      if (response.code == "Username already in use") {
        this.snackBar.open(this.labels.lblUserNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if (response.code == "auth/email-already-in-use") {
        this.snackBar.open(this.labels.lblEmailAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if (response.code == "auth/weak-password") {
        this.snackBar.open(this.labels.lblWeakPassword, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else {
        this.getUsers()
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblUserAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }

    })
  }

  goToNextStep(stepper: MatStepper) {
    if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == "") {
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      if (this.insertForm.value.password == this.insertForm.value.confirmPassword) {
        this.isCompleted = true
        stepper.next()
      } else {
        this.snackBar.open(this.labels.lblPasswordError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
    this.isCompleted = false;
  }
  goBack(stepper: MatStepper) {
    stepper.previous()
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.fetchDepartments();
        }
        else{
          this.fetchDepartments1();
        }
      }, 100);
    });
  }

}
