import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user.model';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';


@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
  providers:[]
})
export class EditDialogComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  departments:Department[]
  positions:Position[]
  users: User[]

  labels;
  labelsSub: Subscription;
  
  admin: Administrator
  adminSub: Subscription

  err: boolean = false
  constructor(private adminAuthService: AdminAuthService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService:AdminLanguageService) { }
    @ViewChild('editForm') editForm:NgForm

    
  onSubmit(){
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "" || this.editForm.value.departmentId == "" || this.editForm.value.email == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }
    else{
      if(this.emailAlreadyExists(this.editForm.value.email)){
        this.snackBar.open(this.labels.lblEmailAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if(this.userNameAlreadyExists(this.editForm.value.userName)){
        this.snackBar.open(this.labels.lblUserNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else{
        this.editUser(this.editForm.value)
        console.log(this.editForm.value)
        this.dialogRef.close(this.editForm.value.firstName)
        this.snackBar.open(this.labels.lblUserEdited, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
  }
  onNoClick(): void {
   this.dialogRef.close();
  }
  ngOnInit(): void {
    this.err = false;
    this.getAdmin()
    this.fetchPositions()
    this.getLang()
    this.getUsers()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  fetchDepartments(){
     return this.dashboardService.fetchAllDepartments().subscribe((response)=>{
       this.departments=response as Department[]
     })
  }
  fetchDepartments1() {
    return this.dashboardService.fetchAdminsDepartments(this.admin.departmentId).subscribe((response) => {
      this.departments = response as Department[]
    })
  }
  fetchPositions(){
    return this.dashboardService.fetchPositions().subscribe((response)=>{
      this.positions=response as Position[]

    })
  }

  editUser(data){
    this.dashboardService.editUser(data).subscribe((response)=>{
      console.log(response)
    })
  }

  goToNextStep(stepper: MatStepper){
    if (this.editForm.value.userName == "" || this.editForm.value.firstName == "" || this.editForm.value.lastName == "" || this.editForm.value.password == "") {
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      stepper.next()
    }
  }

  goBack(stepper: MatStepper){
    stepper.previous()
  }

  emailAlreadyExists(email: string): boolean {
    for (let index = 0; index < this.users.length; index++) {
      if (this.users[index].email == email) {
        return true
      }
    }
    return false
  }

  userNameAlreadyExists(userName: string): boolean {
    for (let index = 0; index < this.users.length; index++) {
      if (this.users[index].userName == userName) {
        return true
      }
    }
    return false
  }

  getUsers() {
    this.dashboardService.getUsers().subscribe((response) => {
      this.users = response as User[]
      console.log(this.data.id)
      this.users = this.users.filter((user) => user.id != this.data.id)
      console.log(this.users)
    })
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.fetchDepartments();
        }
        else{
          this.fetchDepartments1();
        }
      }, 100);
    });
  }
}
