import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Administrator } from 'src/app/models/administrator.model';
import { Department } from 'src/app/models/department.model';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {

  username = new FormControl('', [Validators.required]);
  passwordControl = new FormControl('', [Validators.required]);
  firstNameControl = new FormControl('', [Validators.required]);
  lastNameControl = new FormControl('', [Validators.required]);
  departmentControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);

  administrators
  departments: Department[]
  departmantsLeft: Department[]
  errorClass = ""
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  isCompleted
  isEditable: boolean
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService, private _formBuilder: FormBuilder) { }

  @ViewChild('insertForm') insertForm: NgForm


  onSubmit() {
    console.log("ON SUBMIT")
    console.log(this.insertForm.value)
    if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == "" || this.insertForm.value.departmentId == "" || this.insertForm.value.email == "" || this.insertForm.value.companyId == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllFieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      if (this.insertForm.value.password == this.insertForm.value.confirmPassword) {
        this.insertUser(this.insertForm.value)
      } else {
        this.snackBar.open(this.labels.lblPasswordError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.isCompleted = false;
    this.err = false;
    this.fetcDepartments()
    this.getLang();
  }
  fetcDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
    })
  }

  insertUser(data) {
    this.dashboardService.insertAdmin(data).subscribe((response) => {
      console.log(response)
      if (response.code == "Username already in use") {
        this.snackBar.open(this.labels.lblUserNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if (response.code == "auth/email-already-in-use") {
        this.snackBar.open(this.labels.lblEmailAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if (response.code == "auth/weak-password") {
        this.snackBar.open(this.labels.lblWeakPassword, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else {
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblAdminAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }

    })
  }


}
