import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import {Unit} from './../../../models/unit.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  departments
  departments1
  labels;
  labelsSub: Subscription
  administrators
  err
  admin: Administrator
  adminSub: Subscription
  @ViewChild('editForm') editForm:NgForm
  constructor(private adminAuthService: AdminAuthService, public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getAdmin()
    this.getLang()
    //this.fetchAdministrators()
    this.err = false;
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  /* fetchAdministrators(){
    this.dashboardService.fetchAdministrators().subscribe((response)=>{
      this.administrators=response
    })
  } */

  onNoClick(){
    this.dialogRef.close()
  }
  onSubmit(){
    if(this.editForm.value.name == "" || this.editForm.value.email == "" || this.editForm.value.adminId == ""){
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })

    }
    else{
      if(this.sectorNameAlreadyExists(this.editForm.value.name)){

        this.snackBar.open(this.labels.lblSectorNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
       
      }
      else{
      this.editDepartment(this.data.id,this.editForm.value)
      this.snackBar.open(this.labels.lblSectorEdited, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
      }
    }
    
  }
  fetchDepartments(){
    this.dashboardService.fetchAllDepartments().subscribe((departments)=>{
      this.departments=departments
      console.log(this.departments)
    })
  }
  fetchDepartments1(){
    this.dashboardService.fetchAvailableParents(this.data.id).subscribe((departments)=>{
      this.departments1 = departments.filter((department) => department.id != this.data.id)
      
      console.log(this.departments1)
    })
  }
  fetchDepartments2() {
    this.dashboardService.fetchAvailableParentsNotHeadAdmin(this.admin.departmentId, this.data.id).subscribe((response) => {
      this.departments1 = response.filter((department) => department.id != this.data.id)
    })
  }
  sectorNameAlreadyExists(sectorName: string): boolean {
    for (let index = 0; index < this.departments1.length; index++) {
      if (this.departments1[index].name == sectorName) {
        return true
      }
    }
    return false
  }
  editDepartment(id,data){
    console.log(data)
    this.dashboardService.editDepartment(id,data).subscribe((response)=>{
      console.log(response)
    })
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.fetchDepartments1();
        }
        else{
          this.fetchDepartments2();
        }
      }, 100);
    });
  }
}
