<div class="row"
    style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
    <div class="col-11"></div>
    <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
        <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <mat-horizontal-stepper linear #stepper>
            <mat-step [stepControl]="firstFormGroup" [editable]="true">
                <ng-template matStepLabel>{{labels.lblQuestion}}</ng-template>
                <div class="mat-elevation-z8">
                    <!-- <div class="row header">
                  <div class="col-md-6 text-left"><h5 class="tableName">{{labels.lblQuestions}}</h5></div> -->
                    <!-- <div class="col-md-6 text-right">
                    <button class="tableBtn" mat-raised-button style="margin-right: 1vw;">
                    Add new question
                  </button>
                  <button class="tableBtn hideColumn" mat-raised-button *ngIf='value===true'>
                    Change order      
                  </button>
                  <button class="tableBtn hideColumn" mat-raised-button  *ngIf='value===false'>
                    Save this order      
                  </button></div> -->
                    <!-- </div> -->

                    <form (ngSubmit)="onSubmit()" #insertForm="ngForm" class="qForm">
                        <div fxLayout="column">
                            <div class="row" style="width: 100%; margin: auto">
                                <div class="col-12" style="width: 100%;">
                                    <!-- <input type="hidden" name="id" [ngModel]="data.id">
                                    <input type="hidden" name="testId" [ngModel]="data.testId"> -->
                                    <mat-form-field appearance="none" style="max-width: 100%; width: 100%;">
                                        <mat-label>{{labels.lblQuestionText}}</mat-label>
                                        <textarea matInput [ngModel]="data.text" name="text" required></textarea>
                                        <mat-error *ngIf="textErr == true" style="margin-bottom:1.5vh !important;">
                                            {{labels.lblfieldRequired}}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" style="width: 100%;">
                              <div class="col-11" style="width: 100%; margin: auto">
                                <mat-form-filed appearance="none" fxFlex="60%" *ngIf = " data.imagePath == null">
                                <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
                                <div style="margin-bottom: 11px;">
                                    <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
                                    <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
                                </div>
                                </mat-form-filed>
                                <div *ngIf = " data.imagePath != null">
                                    <mat-label>{{labels.lblImage}}: {{data.imageName}}</mat-label>
                                    <mat-icon style="float:right; cursor: pointer;" (click)="cancelImage()">cancel</mat-icon>
                                </div>
                              </div>
                            </div>
                            <div class="row" style="width: 100%;">
                              <div class="col-11" style="width: 100%; margin: auto">
                                <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "data.videoHintPath == null">
                                    <input type="file" (change)="onVideoChanged($event)" #videoUpload style="display: none;"/>
                                    <div style="margin-bottom: 11px;">
                                        <mat-label style="color: #3e4b71;">{{labels.lblUploadVideoHint}} </mat-label>
                                        <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
                                    </div>
                                </mat-form-filed>
                                <div *ngIf = "data.videoHintPath != null">
                                    <mat-label>{{labels.lblVideo}}: {{data.videoName}}</mat-label>
                                    <mat-icon style="float:right; cursor: pointer;" (click)="cancelVideo()">cancel</mat-icon>
                                </div>
                              </div>
                            </div>
                            <div class="row" style="width: 100%; margin: auto">
                                <div class="col-4">
                                    <mat-form-field appearance="none" style="max-width: 100%; width: 100%;">
                                        <mat-label>{{labels.lblPoints}}</mat-label>
                                        <input *ngIf = "assigned.length == 0" type="number" min="1" max="10" matInput number [ngModel]="data.points"
                                            name="points" required>
                                        <div *ngIf = "assigned.length > 0">
                                          {{data.points}}
                                        </div>
                                        <input *ngIf = "assigned.length > 0" type="number" min="1" max="10" matInput number [ngModel]="data.points"
                                            name="points" hidden>
                                        <mat-error *ngIf="textErr == true" style="margin-bottom:1.5vh !important;">
                                            {{labels.lblfieldRequired}}</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-4" style="text-align: right;">
                                    <mat-form-field appearance="none" fxFlex="60%">
                                        <mat-label>{{labels.lblType}}</mat-label>
                                        <!-- <mat-select [ngModel]='data.type' name='type' required>
                                <mat-option value="1">{{labels.lblOneCorrectAns}}</mat-option>
                                <mat-option value="2">{{labels.lblMoreThanOne}}</mat-option>
                            </mat-select> -->
                                        <input matInput [ngModel]="data.type" name="type" style="color: white" hidden>
                                        <div>
                                            <span *ngIf="data.type == 1">{{labels.lblOneCorrectAns}}</span>
                                            <span *ngIf="data.type == 2">{{labels.lblMoreThanOne}}</span>
                                            <span *ngIf="data.type == 3">{{labels.lblTextAns}}</span>
                                        </div>
                                        <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error> -->
                                    </mat-form-field>
                                </div>
                                <div class="col-12 col-lg-4" style="width: 100%; text-align: right;">
                                    <button cdkFocusInitial
                                        style="margin-top: 15px; padding: 7px; padding-left: 10px; padding-right: 10px;"
                                        type="submit" class="tableBtn btnEdit">{{labels.lblEdit}}</button>
                                </div>
                            </div>

                        </div>
                    </form>

                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                </div>

            </mat-step>
            <mat-step [stepControl]="secondFormGroup" [editable]="false" *ngIf = "(data.type == 1) || (data.type == 2)">
                <ng-template matStepLabel>{{labels.lblAnswers}}</ng-template>

                <form (ngSubmit)="onSubmitAns()" #insertForm1="ngForm" class="ansForm">
                    <div fxLayout="column">
                        <div class="row" style="width: 100%; margin: auto">
                            <div class="col-12 col-lg-10" style="margin: auto">
                              <!-- <input type="hidden" name="id" [ngModel]="data.id">
                              <input type="hidden" name="testId" [ngModel]="data.testId"> -->
                                <mat-form-field appearance="none" style="width: 100%">
                                    <mat-label>{{labels.lblAnswerText}}</mat-label>
                                    <textarea matInput ngModel name="text" required></textarea>
                                    <mat-error *ngIf="textAnsErr == true" style="margin-bottom:1.5vh !important;">
                                        {{labels.lblfieldRequired}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-lg-2" style="margin: auto">
                                <button cdkFocusInitial type="submit" class="tableBtn"
                                    style="padding: 7px; padding-left: 15px; padding-right: 15px;" [ngClass]="{'disabled-btn': assigned.length > 0}">{{labels.lblAdd}}</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="mat-elevation-z8">
                    <!-- <div class="row header"> -->
                        <!-- <div class="col-md-6 text-left"><h5 class="tableName">{{labels.lblAnswers}}</h5></div> -->
                        <!-- <div class="col-md-6 text-right"><button class="tableBtn" mat-raised-button (click)="insertDialog()" style="margin-right: 1vw;">
                    Add new answer
                  </button>
                  <button class="tableBtn hideColumn" mat-raised-button (click)="showChangeButton()" *ngIf='value===true'>
                    Change order      
                  </button>
                  <button class="tableBtn hideColumn" mat-raised-button  (click)="changeOrder()" *ngIf='value===false'>
                    Save this order      
                  </button></div> -->
                    <!-- </div> -->
                    <div class="row header">
                        <div class="row" style="width:100%" [ngClass]="{'disabled-btn': assigned.length > 0}">
                          <mat-checkbox [ngModel]="question.random" class="example-margin checkbox col-12 col-lg-5" (change)="changeRandomAnswers()"
                            [ngClass]="{'disabled-btn': value1}">
                            <span>{{labels.lblRandom}}</span>
                          </mat-checkbox>
                         <div class="col-12 col-lg-5">
                          <button mat-raised-button class="tableBtn btn1" (click)="showChangeButtonAnswers()"
                          *ngIf='value1===true'>
                          {{labels.lblChangeOrder}}
                        </button>
                         </div>
                          <div class="col-12 col-lg-5">
                            <button mat-raised-button class="tableBtn btn1" (click)="changeOrderAnswers()"
                            *ngIf='value1===false'>
                            {{labels.lblSaveOrder}}
                          </button>
                          </div>
                        </div>
                      </div>
                      <table mat-table [dataSource]="dataSource1" matSort cdkDropList [cdkDropListData]="dataSource1"
                        (cdkDropListDropped)="drop1($event)" #table1>
            
                        <!-- ORDER Column -->
                        <ng-container matColumnDef="order">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%"><span class="order">{{labels.lblOrder}}</span><span class="order1">{{labels.lblO}}</span></th>
                          <td mat-cell *matCellDef="let answer" style="width: 10%">
                            <mat-icon *ngIf="!question.random && !value1" style="cursor: pointer;" class="settings">
                              import_export
                            </mat-icon> <span *ngIf="!question.random">{{answer.order}}</span>
                            <span *ngIf="question.random">R</span>
                          </td>
                        </ng-container>
            
                        <!-- name Column -->
                        <ng-container matColumnDef="text">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblAnswerText}} </th>
                          <td mat-cell *matCellDef="let answer">
                            {{answer.text}}
                          </td>
                        </ng-container>
            
                        <ng-container matColumnDef="correct">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%">Status</th>
                          <td mat-cell *matCellDef="let answer" style="width: 10%">
                            <mat-slide-toggle [checked]="answer.correct" (click)="changeStatus(answer)"
                              [ngClass]="{'disabled-btn': isDisabled(answer) || (assigned.length > 0)}">
                              <span class="hideColumn" *ngIf="answer.correct==true">{{labels.lblCorrect}}</span>
                              <span class="hideColumn" *ngIf="answer.correct==false">{{labels.lblNotCorrect}}</span>
                            </mat-slide-toggle>
            
                          </td>
                        </ng-container>
                        <!-- parentId Column -->
            
                        <!--Actions column-->
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef class="thActions"> {{labels.lblOptions}} </th>
                          <td mat-cell *matCellDef="let answer" class="tdActions">
                            <div class="row d-sm-flex" style="margin: auto; padding: auto">
                              <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu"
                                style="vertical-align: middle !important; margin:auto; margin-left: 15px;">settings</mat-icon>
                              <mat-menu #settingsMenu="matMenu" class="mMenu">
                                <button mat-menu-item class="menuButton settingsMenuItem" (click)="editDialog(answer)">
                                  <mat-icon style="color: #8293c6;">edit</mat-icon>
                                  <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                                </button>
                                <button mat-menu-item class="menuButton settingsMenuItem" (click)="deleteDialog(answer)" [ngClass]="{'disabled-btn': assigned.length > 0}">
                                  <mat-icon style="color: #8293c6;">delete</mat-icon>
                                  <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                                </button>
                              </mat-menu>
            
                              <!-- <button class="editBtn d-none d-sm-inline-flex col-xs-11" style="margin-bottom:7px;margin-top:7px; width:80px; padding-left:17px;" (click)="editDialog(answer)">
                                 
                                  {{labels.lblEdit}}
                                </button>
                                <button class="deleteBtn d-none d-sm-inline-flex col-xs-11" style="margin-bottom:7px;margin-top:7px; width:80px; padding-left:17px;" (click)="deleteDialog(answer)">
                                 
                                  {{labels.lblDelete}}
                                </button> -->
                            </div>
                            <!-- mobile -->
                            <!-- <div class="row d-flex d-sm-none">
                              <button class="editBtn d-block d-sm-none col-xs-11 mobBtn" (click)="editDialog(answer)">
                                {{labels.lblEdit}}
                              </button>
                              <button class="deleteBtn d-block d-sm-none col-xs-11 mobBtn" (click)="deleteDialog(answer)">
                                {{labels.lblDelete}}
                              </button>
                            </div> -->
                          </td>
                        </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns1;" cdkDrag [cdkDragDisabled]="value1"
                          [cdkDragData]="row"></tr>
                      </table>

                    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>

</div>