<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblAddNewUnit}}</h1>
<div mat-dialog-content>
  <!-- <form (ngSubmit)="onSubmit()" #insertForm="ngForm" style="height: 100%;">
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblUnitName}}</mat-label>
            <input matInput ngModel name="name" #name="ngModel" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <div class="div">
          <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedVideo">
            <input 
            type="file" 
            (change)="onVideoChanged($event)" 
            #videoUpload
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadVideo}} / {{labels.lblUploadAudio}}</mat-label>
            <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedVideo" style="width: 100%;">
          {{labels.lblVideoAudio}}: {{selectedVideo.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateVideo()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div">
          <mat-form-filed appearance="none" fxFlex="80%" *ngIf="!selectedVideoMobile">
            <input 
            type="file" 
            (change)="onVideoChangedMobile($event)" 
            #videoUploadMobile
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadVideo}} / {{labels.lblUploadAudio}} {{labels.lblForMobile}}</mat-label>
            <mat-icon class="icon" (click)="videoUploadMobile.click()">videocam</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedVideoMobile" style="width: 100%;">
          {{labels.lblVideoAudioMobile}}: {{selectedVideoMobile.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateVideo()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div">
          <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedDocument">
            <input 
            type="file" 
            (change)="onDocumentChanged($event)" 
            #documentUpload
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadDoc}} </mat-label>
            <mat-icon class="icon" (click)="documentUpload.click()">description</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedDocument" style="width: 100%;">
          {{labels.lblDocument}}: {{selectedDocument.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateDocument()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div">
          <mat-form-filed appearance="none" fxFlex="60%"  *ngIf="!selectedImage">
            <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
              <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
              <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
          </mat-form-filed>
          <mat-label *ngIf="selectedImage" style="width: 100%;">
            {{labels.lblImage}}: {{selectedImage.name}}
            <mat-icon style="float:right; cursor: pointer;" (click)="updateImage()">cancel</mat-icon>
          </mat-label>
        </div>
        <mat-form-filed appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblMessageForUnit}}</mat-label>
            <textarea matInput ngModel name="text" style="border:1px solid #9dacd9; border-radius: 7px;"></textarea>
        </mat-form-filed>
        <div>
          <mat-progress-bar mode="indeterminate" style="margin-top: 20px;" *ngIf="uploading"></mat-progress-bar>
            <button class="btn" cdkFocusInitial style="float: right; margin-top: 11px;" type="submit">{{labels.lblAdd}}</button>
        </div>
    </div>
  </form> -->


  <form (ngSubmit)="onSubmit()" #insertForm="ngForm" style="height: 100%;">
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblUnitName}}</mat-label>
            <input matInput ngModel name="name" #name="ngModel" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field  appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblType}}</mat-label>
          <mat-select ngModel name='type' #type="ngModel" required>
              <mat-option (click) = "onSelect(1)" value="1">{{labels.lblVideo}}</mat-option>
              <mat-option (click) = "onSelect(2)" value="2">{{labels.lblAudio}}</mat-option>
              <mat-option (click) = "onSelect(3)" value="3">{{labels.lblImageUnit}}</mat-option>
              <mat-option (click) = "onSelect(4)" value="4">{{labels.lblDocument}}</mat-option>
              <mat-option (click) = "onSelect(5)" value="5">{{labels.lblTextUnit}}</mat-option>
          </mat-select>
          <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>
        <div class="div" *ngIf = "unitType == 1">
          <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedVideo">
            <input 
            type="file" 
            (change)="onVideoChanged($event)" 
            #videoUpload
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadVideo}}</mat-label>
            <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedVideo" style="width: 100%;">
          {{labels.lblVideo}}: {{selectedVideo.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateVideo()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div" *ngIf = "unitType == 2">
          <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedVideo">
            <input 
            type="file" 
            (change)="onVideoChanged($event)" 
            #videoUpload
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadAudio}}</mat-label>
            <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedVideo" style="width: 100%;">
          {{labels.lblAudio}}: {{selectedVideo.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateVideo()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div" *ngIf = "unitType == 1">
          <mat-form-filed appearance="none" fxFlex="80%" *ngIf="!selectedVideoMobile">
            <input 
            type="file" 
            (change)="onVideoChangedMobile($event)" 
            #videoUploadMobile
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadVideo}}{{labels.lblForMobile}}</mat-label>
            <mat-icon class="icon" (click)="videoUploadMobile.click()">videocam</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedVideoMobile" style="width: 100%;">
          {{labels.lblVideo}} {{labels.lblForMobile}}: {{selectedVideoMobile.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateVideo()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div" *ngIf = "unitType == 2">
          <mat-form-filed appearance="none" fxFlex="80%" *ngIf="!selectedVideoMobile">
            <input 
            type="file" 
            (change)="onVideoChangedMobile($event)" 
            #videoUploadMobile
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadAudio}} {{labels.lblForMobile}}</mat-label>
            <mat-icon class="icon" (click)="videoUploadMobile.click()">videocam</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedVideoMobile" style="width: 100%;">
          {{labels.lblAudio}} {{labels.lblForMobile}}: {{selectedVideoMobile.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateVideo()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div"  *ngIf = "unitType == 4">
          <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedDocument">
            <input 
            type="file" 
            (change)="onDocumentChanged($event)" 
            #documentUpload
            style="display: none;"
            >
            <mat-label style="color: #3e4b71;">{{labels.lblUploadDoc}} </mat-label>
            <mat-icon class="icon" (click)="documentUpload.click()">description</mat-icon>
        </mat-form-filed>
        <mat-label *ngIf="selectedDocument" style="width: 100%;">
          {{labels.lblDocument}}: {{selectedDocument.name}}
          <mat-icon style="float:right; cursor: pointer;" (click)="updateDocument()">cancel</mat-icon>
        </mat-label>
        </div>
        <div class="div"  *ngIf = "unitType == 3">
          <mat-form-filed appearance="none" fxFlex="60%"  *ngIf="!selectedImage">
            <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
              <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
              <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
          </mat-form-filed>
          <mat-label *ngIf="selectedImage" style="width: 100%;">
            {{labels.lblImage}}: {{selectedImage.name}}
            <mat-icon style="float:right; cursor: pointer;" (click)="updateImage()">cancel</mat-icon>
          </mat-label>
        </div>
        <mat-form-filed appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblMessageForUnit}}</mat-label>
            <textarea matInput ngModel name="text" style="border:1px solid #9dacd9; border-radius: 7px; min-height: 150px;"></textarea>
        </mat-form-filed>
        <div>
          <mat-progress-bar mode="indeterminate" style="margin-top: 20px;" *ngIf="uploading"></mat-progress-bar>
            <button class="btn" cdkFocusInitial style="float: right; margin-top: 11px;" type="submit">{{labels.lblAdd}}</button>
        </div>
    </div>
  </form>
</div>


