<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<h1 mat-dialog-title>{{labels.lblAddMeeting}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #insertForm="ngForm">
    <div fxLayout="column">

      <mat-form-field appearance="none" fxFlex="60%">
        <mat-label>{{labels.lblMeetingTitle}}:</mat-label>
        <input matInput ngModel name="title" #name="ngModel" required>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>

      <mat-form-field class="matFormField" appearance="none" fxFlex="60%">
        <mat-label>{{labels.lblDescription}}: </mat-label>
        <textarea class="txtArea" style = "margin-top: 0.5vh" matInput maxlength="150" rows="3" cols="5" ngModel name="description"
          #description="ngModel" required></textarea>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>
      
      <!-- <mat-form-field appearance="none" fxFlex="60%">
        <mat-label>{{labels.lblDate}}: </mat-label> 
        <input matInput [matDatepicker]="picker" ngModel name="date" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field> -->

      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="picker" ngModel name="date"  placeholder={{labels.lblDate}}>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker>
        </ngx-mat-datetime-picker>
     </mat-form-field>

     <!-- <div style="width: 100%"> -->
      <!-- <mat-form-field appearance="none" class="inputField" fxFlex="60%" style = "width: 20%">
        <mat-label>{{labels.lblHours}}: </mat-label>
        <span class='number-wrapper'>
          <input type = "number" step = "1" min = "0" max = "23" matInput ngModel name="startTimeHour" #startTimeHour="ngModel" required>
        </span>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="none" class="inputField" fxFlex="60%" style = "width: 20%">
        <mat-label>{{labels.lblMinutes}}: </mat-label>
        <input type = "number" step = "1" min = "0" max = "23" matInput ngModel name="startTimeMinutes" #startTimeMinutes="ngModel" required>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field> -->

      <mat-form-field appearance="none" class="inputField" fxFlex="60%">
        <mat-label>{{labels.lblDuration}} (min): </mat-label>
        <input type = "number" min = "0" matInput ngModel name="duration" #duration="ngModel" required>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>
     <!-- </div> -->

     <mat-form-field appearance="none">
      <mat-label>{{labels.lblSector}}</mat-label>
      <mat-select ngModel name="departmentId" required>
        <mat-option *ngFor="let department of departments" [value]="department.id">
          {{department.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="none" fxFlex="60%">
      <mat-label>{{labels.lblTest}}</mat-label>
      <mat-select ngModel name='test'>
          <mat-option *ngFor='let test of tests' value="{{test.id}}">{{test.name}}</mat-option>
      </mat-select>
  </mat-form-field>
    
     <!-- <mat-form-field appearance="none" fxFlex="60%">
      <mat-label>{{labels.lblPassword}} {{labels.lblPassMinCharacters}}</mat-label>
      <input type="password" matInput ngModel name="password" required>
      <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="none" fxFlex="60%">
      <mat-label>{{labels.lblConfirmPassword}}</mat-label>
      <input type="password" matInput ngModel name="confirmPassword" required>
      <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
    </mat-form-field> -->
     <div class="col-11" style="width: 100%; margin-top: 5%; padding-left: 0px;">
      <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedImage">
        <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
        <div style="margin-bottom: 11px;">
          <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
          <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
        </div>
      </mat-form-filed>
      <mat-label *ngIf="selectedImage">
        {{labels.lblImage}}: {{selectedImage.name}}
        <mat-icon style="float:right; cursor: pointer;" (click)="updateImage()">cancel</mat-icon>
      </mat-label>
    </div>
      <div class = "divForBtn">
        <button class="tableBtn" cdkFocusInitial type="submit" style="margin-top: 25px; float: right">{{labels.lblSave}}</button>
      </div>
      <div class="col-12">
        <mat-progress-bar mode="indeterminate" style="margin-top: 15px;" *ngIf="uploading"></mat-progress-bar>
      </div>
    </div>
  </form>
</div>