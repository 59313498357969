import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/services/auth-service/auth.service';
import {AdminLanguageService} from 'src/app/modules/services/admin-language-service/admin-language.service'
import { Administrator } from 'src/app/models/administrator.model';
import { Subscription } from 'rxjs';
import { AdminAuthService } from 'src/app/modules/services/admin-auth-service/admin-auth.service';
import { HostListener } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  lang
  langSub: Subscription;

  labels;
  labelsSub: Subscription;
  screenWidth

  sideBarOpened = false

  meeting = []

  admin
  adminSub: Subscription
  adminId
  @Output() toggleSideBarForMe :EventEmitter<any>=new EventEmitter()

 
  constructor(private authService : AuthService, private adminAuthService: AdminAuthService, private router:Router, public adminLanguageService: AdminLanguageService,
    private db: AngularFirestore) { }

  ngOnInit(): void {
    this.langSub = this.adminLanguageService.lang$.subscribe((lang) =>{
      this.lang = lang;
    })

    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) =>{
      this.labels = labels;
    })

    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin
      setTimeout(() => {
        this.adminId = this?.admin?.id
        this.getUsersChatMeeting(this.admin)
      }, 500)
    })

    this.toggleSideBar()
  }

  getUsersChatMeeting(user){
    let meetings = []
    let docRef = this.db.firestore.collection("administrators").where("id", "==", user.id)
    .onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function(change){
        console.log(change.doc.data())
        let meeting = change.doc.data()
        console.log("meeting call: ", meeting)
       if(meeting.meetingCall == true){
          meetings.push(meeting)
       }else{
         meetings.pop()
       }
      })
    })
   setTimeout(() => {
    this.meeting = meetings
   }, 2000);
  }

  endCall(){
    this.db.collection("administrators").doc(this.adminId).update({meetingCall: false, callFrom: "", onMeeting: false})
  }
  
  goToMeeting(){
    this.router.navigateByUrl("/user/chat-meeting")
  }

  toggleSideBar(){
    this.toggleSideBarForMe.emit()
    setTimeout(()=>{
      window.dispatchEvent(new Event('resize'))
    },300)
  }

  logoutAdmin(): void {
    this.adminAuthService.logoutAdmin();
    this.router.navigateByUrl('/login');
  }

  selectLang(lang){
    this.adminLanguageService.setLanguage(lang);
  }

  goToSettings(){
    this.router.navigateByUrl('/admin/change-password')
  }

  // @HostListener('window:resize', ['$event'])
  //   getScreenSize(event?) {
  //         this.screenWidth = window.innerWidth;
  //         console.log(this.screenWidth);
  //         if(this.screenWidth < 993){
  //          this.sideBarOpened = false
  //         }
  //   }

}
