<app-admin-spinner
    *ngIf="users == null || labels == null || user == null || messages == null || forumVisibility == null || showSpinner == null || showForumSpinner  == null">
</app-admin-spinner>
<div class="row">
    <div class="col-12 col-xl-4">
        <div class="msgs" [class]="displayUsersList">
            <div class="porukeNslov" style="text-align: left;">
                <h3>{{labels.lblMessages}}</h3>
                <div style="width: 100%; text-align: left">
                    <input matInput ngModel placeholder={{labels.lblSearch}}
                        style="border: 1px solid transparent; border-radius: 7px; height: 100%; border: 1px solid transparent; background-color: #202a4a; outline: none; color: grey;"
                        (keyup)="userSearch($event)">
                </div>
            </div>
            <hr />
            <div class="msgs2">
                <div class="row users forum" [class.activeForum]="forumBackgroundColor"
                    (click)="changeForumVisibility()"
                    style="cursor: pointer; margin:11px; margin-top: 0px; margin-bottom: 0px;">
                    <div class="col-3" style="padding-left: 0px;">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/forum%20logo.png?alt=media&token=6611a728-740b-4585-a197-13c034f8afd5" alt = "forum logo">
                    </div>
                    <div class="col-9" style="padding-left: 0px;">
                        <div class="row" style="padding-top: 5%; padding-bottom: 5%">
                            <div class="col-9"
                                style="text-align: left; font-weight: bold; padding-left: 0px; font-size: 16px; color: #9dacd9">
                                Leste forum</div>
                            <!-- <div class="col-8" style="font-size:13.5px; color: #8293c6; text-align:left; margin-top:15px; padding-left: 0px;">
                                {{lastMessage.substring(0,20)}}...
                            </div>
                            <div class="col-4" style="font-family: 'Poppins' !important; font-size:12.5px; text-align:right; padding-right:0px; color: #8293c6; margin-top: 15px;">27/3/2021</div> -->
                        </div>
                    </div>
                </div>
                <div *ngFor="let u of users" [class]="notActive" style="width: 100%;">
                    <div class="row users" (click)="chatWithUser(u, 5, 0)" [class.active]="isClicked(u.id)"
                        style="margin:11px; cursor: pointer; margin-top: 0px; margin-bottom: 0px;">
                        <div class="col-3" style="padding-left: 0px;">
                            <img src={{u.profileImagePath}} alt = "profile image">
                        </div>
                        <div class="col-9" [class.imgPadding]="!u.lastMessage" style="padding-left: 0px;">
                            <div class="row">
                                <div class="col-9"
                                    style="text-align: left; font-weight: bold; padding-left: 0px; color: #9dacd9">
                                    {{u.firstName}} {{u.lastName}}</div>
                                <div class="col-3 text-right" style="text-align: right">
                                    <div *ngIf="u.online == true"
                                        style="display: inline-block; margin-left:5px; width:10px; height:10px; border:1px solid limegreen; border-radius: 50%; background-color: limegreen;">
                                    </div>
                                    <div *ngIf="u.online == false"
                                        style="display: inline-block; margin-left:5px; width:10px; height:10px; border:1px solid #bfbfbf; border-radius: 50%; background-color: #bfbfbf;">
                                    </div>
                                </div>
                                <div class="col-7" *ngIf="u.lastMessage"
                                    style="font-size:13.5px; color: #8293c6; text-align:left; margin-top:5px; padding-left: 0px;">
                                    <span *ngIf="u.from == true">{{labels.lblYou}} </span><span
                                        *ngIf="u.lastMessage.seen == true">{{u.lastMessage.lastMessage.substring(0,10)}}</span>
                                    <span *ngIf="u.lastMessage.seen == false"
                                        style="font-weight: bold; color: white;">{{u.lastMessage.lastMessage.substring(0,10)}}</span>
                                    <span *ngIf="u.lastMessage.lastMessage.length > 10">...</span>
                                </div>
                                <div class="col-5" *ngIf="u.lastMessage"
                                    style="font-family: 'Poppins' !important; font-size:12.5px; text-align:right; padding-right:0px; color: #8293c6; margin-top: 5px; padding-right: 15px;">
                                    {{u.lastMessage.timestamp}}</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- forum -->
    <div class="col-12 col-xl-8 messages" *ngIf="showDiv == 'forum'" [class]="forumVisibility">
        <!-- <div class="col-12 back" (click) = "backToUsersList()" style="background-color: transparent; color:black">
        <span><mat-icon style="color: black; font-weight: bold; font-size: 33px; color: #13abf9; vertical-align: sub;">keyboard_backspace</mat-icon></span>
    </div> -->
        <div class="messages messagesMob" style="margin-top: 0px;">
            <div class="row" style="padding:11px;  margin: auto;">
                <div class="col-2 back" (click)="backToUsersList()" style="background-color: transparent; color:black">
                    <span>
                        <mat-icon
                            style="color: black; font-weight: bold; font-size: 33px; color: #13abf9; vertical-align: sub;">
                            keyboard_backspace</mat-icon>
                    </span>
                </div>
                <div class="col-1" style="text-align:right; height: 65px;">
                    <img style="width: 65px; height: 65px;"
                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/forum%20logo.png?alt=media&token=6611a728-740b-4585-a197-13c034f8afd5" alt = "profile image">
                </div>
                <div class="col-8 usrName" style="margin-top:auto; margin-bottom:auto; margin-left: 2%;">
                    <div class="row">
                        <div class="col-12 userName" style="text-align: left;">
                            <h1
                                style="padding:10px; float: left; margin-bottom:0px !important; font-family: 'Prompt', sans-serif !important;">
                                Leste Forum</h1>
                        </div>
                        <!--  <div class="col-12" style="color: #737373; font-size: 15px; text-align: left !important;">sanja@leste.rs</div> -->
                    </div>
                </div>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="row msgList" #scrollMe [scrollTop]="scrollMe.scrollHeight"
                (scroll)="onScrollForumFunction($event)" style="margin: auto;" [style.height]="messagesHeight">
                <div *ngFor="let m of messages" style="width: 100%;">
                    <div class="row poruka" [class]="displayImgAndInfo(m)"
                        style="margin-bottom: 0px !important; margin-top: 10px;">
                        <div class="col-1 mobImg" *ngIf="displayMessage == true"
                            style="margin-top:0px !important;padding-top: 1%;">
                            <img src={{m.imagePath}} alt = "users profile image">
                        </div>
                        <div class="col-7" [class]="floatClass(m)"
                            style="width: 100% !important; margin-top:0px !important;">
                            <div class="row" *ngIf="displayMessage == true"
                                style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                                <div class="col-9 offset-2 datetime"
                                    style="width: 100%; text-align: left; margin-bottom:0xp !important; padding-bottom:0px !important;">
                                    {{m.firstName}} {{m.lastName}}
                                </div>
                            </div>
                            <div class="userMessages" [class]="msgChatBackground(m)"
                                style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl == null">
                                {{m.text}}
                            </div>
                            <div class="userMessages" [class]="msgChatBackground(m)"
                                style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl != null">
                                <mat-icon style="float:left; ">download</mat-icon>
                                <a href="{{m.fileUrl}}" target="_blank" style="color: #8293c6;;">{{m.text}}</a>
                            </div>
                            <div class="row"
                                style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                                <div class="col-9 offset-2 datetime" [class]="floatDateClass(m)"
                                    style="width: 100%; text-align: left; margin-top:0xp !important; padding-top:0px !important;">
                                    {{m.dateTime}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="row" style="width: 100%; margin: auto;" (keydown.enter)="submitMessage()">
                <form class="col-12" #insertForm="ngForm">
                    <input matInput [ngModel]="user.firstName" name="firstName" hidden>
                    <input matInput [ngModel]="user.lastName" name="lastName" hidden>
                    <input matInput [ngModel]="user.email" name="sender" hidden>
                    <mat-form-field appearance="none" style="width: 85%;">
                        <input class="msgInput" matInput ngModel name="textMessage"
                            placeholder={{labels.lblWriteAMessage}}>
                    </mat-form-field>
                    <input type="file" (change)="onFileChanged($event)" #fileUpload style="display: none;">
                    <mat-icon (click)="fileUpload.click()"
                        style="color: #13abf9; padding-left: 10px; padding-right: 20px; width: 7%; border-left: 1px solid #3e4b71; cursor: pointer; ">
                        attach_file</mat-icon>
                    <mat-icon (click)="submitMessage()"
                        style="width: 10%; cursor: pointer; color: #13abf9;padding-left:20px; padding-right: 20px; width: 7%; border-left: 1px solid #3e4b71">
                        send</mat-icon>
                </form>
            </div>
            <div class="row" style="width: 100%; margin: auto;" *ngIf="selectedFile != null">
                <mat-divider class="divider" style="width: 100%; margin-bottom: 12px;"></mat-divider>
                <div
                    style="border-radius: 15px; padding: 5px; font-size: 15px; background-color: #13abf9;color: white;">
                    <mat-label>{{selectedFile.name.substring(0,15)}}<span
                            *ngIf="selectedFile.name.length > 15">...</span></mat-label>
                    <mat-icon style="float:right; cursor: pointer; font-size: 15px; padding-top: 4px;"
                        (click)="updateFile()">close</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <!-- one to one -->
    <div class="col-12 col-xl-8 messages" *ngIf="showDiv == 'chat'" [class]="chatVisibility">
        <!-- <div class="col-12 back" (click) = "backToUsersList()" style="background-color: transparent; color:black">
        <span><mat-icon style="color: black; font-weight: bold; font-size: 33px; color: #13abf9; vertical-align: sub;">keyboard_backspace</mat-icon></span>
    </div> -->
        <div class="messages messagesMob" style="margin-top: 0px !important">
            <div class="row" style="padding:11px;">
                <div class="col-2 back" (click)="backToUsersList()" style="background-color: transparent; color:black">
                    <span>
                        <mat-icon
                            style="color: black; font-weight: bold; font-size: 33px; color: #13abf9; vertical-align: sub;">
                            keyboard_backspace</mat-icon>
                    </span>
                </div>
                <div class="col-1" style="text-align:right; height: 65px;">
                    <img style="width: 65px; height: 65px;" src={{userTo.profileImagePath}} alt = "users profile image">
                </div>
                <div class="col-10 usrName" style="margin-top:auto; margin-bottom:auto; margin-left: 5%;">
                    <div class="row">
                        <div class="col-11 userName" style="text-align: left;">
                            <h1
                                style="padding:10px; float: left; margin-bottom:0px !important; font-family: 'Prompt', sans-serif !important;">
                                {{userToFullName}}</h1>
                        </div>
                        <!-- <div class="col-1" style="text-align: right;">
                            <div style = "padding-top: 10%">
                                <mat-icon style="color: #13abf9; cursor: pointer; font-size: 2.3rem" (click) = "startZoomMeeting(userTo)">phone</mat-icon>
                            </div>
                        </div> -->
                        <!-- <div class="col-12" style="color: #3e4b71; font-size: 15px; text-align: left !important;">sanja@leste.rs</div> -->
                    </div>
                </div>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="row msgList" #scrollMe [scrollTop]="scrollMe.scrollHeight"
                (scroll)="onScrollFunction($event, userTo)" style="margin: auto;" [style.height]="messagesHeight">
                <div *ngFor="let m of chatMessages1" style="width: 100%;">
                    <div class="row poruka" [class]="displayChatImgAndInfo(m)"
                        style="margin-bottom: 0px !important; margin-top: 10px;">
                        <!-- <div class="row" *ngIf = "displayMessage == true" style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                        <div class="col-9 offset-2" style="width: 100%; text-align: left; margin-bottom:0xp !important; padding-bottom:0px !important; margin-left: 11%">
                            {{m.firstName}} {{m.lastName}}
                        </div>
                     </div> -->
                        <div class="col-1 mobImg" *ngIf="displayChatMessage == true"
                            style="margin-top:0px !important;padding-top: 1%;">
                            <img src={{userTo.profileImagePath}} alt = "users profile image">
                        </div>
                        <div class="col-7" [class]="floatChatClass(m)" style="width: 100% !important; margin-top:0px;">
                            <div class="userMessages" [class]="msgChatBackground(m)"
                                style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl == null">
                                {{m.content}}
                            </div>
                            <div class="userMessages" [class]="msgChatBackground(m)"
                                style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl != null && m.idFrom==user.id">
                                <mat-icon style="float:left; color: white;">download</mat-icon>
                                <a href="{{m.fileUrl}}" style="color: white;" target="_blank">{{m.content}}</a>
                            </div>  
                            <div class="userMessages" [class]="msgChatBackground(m)"
                                style="width: 100%; margin-top:0px !important" *ngIf="m.fileUrl != null && m.idFrom!=user.id">
                                <mat-icon style="float:left; color: #8293c6;;">download</mat-icon>
                                <a href="{{m.fileUrl}}" style="color: #8293c6;;" target="_blank">{{m.content}}</a>
                            </div> 
                            <div class="row"
                                style="width:100%; font-size: 10px; color: #3e4b71; text-align: left;margin-top:0px !important; padding: top 0px !important;">
                                <div class="col-9 offset-2 datetime" [class]="floatChatDateClass(m)"
                                    style="width: 100%; text-align: left; margin-top:0xp !important; padding-top:0px !important;">
                                    {{m.timestamp}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider class="divider"></mat-divider>
            <div class="row" style="width: 100%; margin: auto;">
                <form #insertChatForm="ngForm" (keydown.enter)="submitChatMessage()">
                    <input matInput [ngModel]="user.id" name="userId" hidden>
                    <mat-form-field appearance="none" style="width: 85%;">
                        <input matInput ngModel name="content" placeholder={{labels.lblWriteAMessage}}>
                    </mat-form-field>
                    <input type="file" (change)="onFileChanged($event)" #fileUpload style="display: none;">
                    <mat-icon (click)="fileUpload.click()"
                        style="color: #13abf9; padding-left: 10px; padding-right: 20px; width: 7%; border-left: 1px solid #3e4b71; cursor: pointer; ">
                        attach_file</mat-icon>
                    <mat-icon (click)="submitChatMessage()"
                        style="color: #13abf9; padding-left: 20px; padding-right: 20px; width: 7%; border-left: 1px solid #3e4b71; cursor: pointer;">
                        send</mat-icon>
                </form>
            </div>
            <div class="row" style="width: 100%; margin: auto;" *ngIf="selectedFile != null">
                <mat-divider class="divider" style="width: 100%; margin-bottom: 12px;"></mat-divider>
                <div
                    style="border-radius: 15px; padding: 5px; font-size: 15px; background-color: #13abf9;color: white;">
                    <mat-label>{{selectedFile.name.substring(0,15)}}<span
                            *ngIf="selectedFile.name.length > 15">...</span></mat-label>
                    <mat-icon style="float:right; cursor: pointer; font-size: 15px; padding-top: 4px;"
                        (click)="updateFile()">close</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>