import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { EditMeetingComponent } from './edit-meeting/edit-meeting.component';
import { DeleteMeetingComponent } from './delete-meeting/delete-meeting.component';
import { AddMeetingComponent } from './add-meeting/add-meeting.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpClientModule} from '@angular/common/http';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { Meeting } from '../../models/meeting.model'
import { EvaluationComponentComponent } from './evaluation-component/evaluation-component.component';
// import { AssingZoomTestComponent } from './assing-zoom-test/assing-zoom-test.component';
@Component({
  selector: 'app-admin-zoom-meetings',
  templateUrl: './admin-zoom-meetings.component.html',
  styleUrls: ['./admin-zoom-meetings.component.scss']
})
export class AdminZoomMeetingsComponent implements OnInit {
  labels
  labelsSub: Subscription

  admin
  adminSub: Subscription
  userId = ""

  events
  reload = false
  displayedColumns: string[] = ['title', 'description', 'date', 'actions'];
  dataSource;
  routerUrl = ""
  code = ""
  zoomResponse
  zoomAccessToken
  zoomUserDetails
  zoomUserId
  meetings
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort:MatSort
  constructor(public dialog: MatDialog, private languageService: AdminLanguageService,  public db: AngularFirestore,
    private modal: NgbModal, private authService: AdminAuthService, private dashboardService: DashboardService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.adminSub = this.authService.admin$.subscribe((admin) => {
      this.admin = admin;
      this.events = []
      setTimeout(() => {
        this.userId = this.admin.id
        this.getEvents(this.admin.id)
        this.routerUrl = this.router.url
        let parts = this.routerUrl.split("=",2)
        this.code = parts[1]
        this.oAuthRedirect(this.code)
      }, 500)
    })
   
  }

   oAuthRedirect(code){
    let clientInfo = 'cDQPVo5qSoyvYSPlwTQZJw:q6uWS4uPu5gazI7YFdij6D8TwaKdUp48'
    let encode = btoa(clientInfo)
    let authorization =  `Basic ${encode}`

    let data = {
      authorization: authorization,
      code: code,
      for: 'training'
    }

    this.dashboardService.zoomAccessToken(data).subscribe((response) => {
      console.log("RESPONSE FROM ZOOM API: ", response)
      this.zoomResponse = response
      this.zoomAccessToken = this.zoomResponse.access_token
      this.db.collection("administrators").doc(this.userId).update({zoomAccessToken: this.zoomResponse.access_token})
      this.dashboardService.getZoomUserDetails(this.zoomResponse.access_token).subscribe((newResponse) => {
        console.log("ZOOM USER DETAILS: ", newResponse)
        this.zoomUserDetails = newResponse
        this.zoomUserId = this.zoomUserDetails.users[0].id
        this.db.collection("administrators").doc(this.userId).update({zoomUserId: this.zoomUserId})
      })
    })
  
  }

  startZoomMeeting(m){
    let now = new Date()
    let nowTime = now.getTime()
    let meetingDate = m.date
    let meetingTimeBefore15 = meetingDate.getTime() - 900000
    console.log("Now: ", nowTime)
    console.log("Meeting time 15: ", meetingTimeBefore15)
    console.log("Now date: ", now)
    console.log("Meeting date 15: ", meetingDate)
    if(nowTime < meetingTimeBefore15){
      this.snackBar.open(this.labels.lblCantStartMeeting, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      const url = `/admin/zoom-meeting/${m.meetingId}`
      this.router.navigateByUrl(url)
    }
  }

  // assignTest(meeting) {

  //   const dialogRef = this.dialog.open(AssingZoomTestComponent, {
  //     width: '500px',
  //     height: '300px',
  //     data: { id: meeting.id, testId: meeting.testId }
  //   })
  // }

  getEvents(id){
    let events = []
    let usersId = id
    let docRef = this.db.firestore.collection("zoomMeetings").where("adminId", "==", id)
    docRef.get().then((snapshot) => {
      snapshot.forEach((change) => {
        let event = change.data()
        event.id = change.id
        let date = new Date(change.data().correctDate.seconds * 1000)
        var formatDate = date.getDate() +
        "/" + (date.getMonth() + 1) +
        "/" + date.getFullYear() +
        " " + date.getHours() +
        ":" + date.getMinutes()
        event.date = new Date(change.data().correctDate.seconds * 1000)
        event.date1 = formatDate
        events.push(event)
        events = events.sort((a,b) => (a.started && !b.started ? -1 : 1))
      });
        this.dataSource=new MatTableDataSource(events)
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator
    });
  }

  openEvaluationDialog(meeting){
    console.log("meeting for evaluation", meeting)
    const dialogRef = this.dialog.open(EvaluationComponentComponent, {
      disableClose: true,
      width: '450px',
      data: meeting
    });
    dialogRef.afterClosed().subscribe((result) => {
        console.log("Dialog closed.")
        this.dataSource = null
        this.getEvents(this.userId)
      })
  }
 
   addEvent(): void {
     const dialogRef = this.dialog.open(AddMeetingComponent, {
       disableClose: true,
       width: '450px',
       height: '81%'
     });
     dialogRef.afterClosed().subscribe((result) => {
         console.log("Dialog closed.")
         this.dataSource = null
         this.getEvents(this.userId)
       })
   }
 
   deleteEvent(eventToDelete) {
     const dialogRef = this.dialog.open(DeleteMeetingComponent, {
       disableClose: true,
       width: '400px',
       data: {userId: this.userId, eventId: eventToDelete.id, meetingId: eventToDelete.meetingId}
     });
     dialogRef.afterClosed().subscribe((result) => {
         console.log("Delete dialog closed.")
         this.dataSource = null
         this.getEvents(this.userId)
       })
   }
 
   editEvent(eventToEdit){
     const dialogRef = this.dialog.open(EditMeetingComponent, {
       disableClose: true,
       width: '400px',
       data: {userId: this.userId, eventId: eventToEdit.id, eventTitle: eventToEdit.title, eventDescription: eventToEdit.description, eventDate: eventToEdit.date, meetingId: eventToEdit.meetingId, hour: eventToEdit.hour, minutes: eventToEdit.minutes, imagePath: eventToEdit.imagePath}
     });
     dialogRef.afterClosed().subscribe((result) => {
         console.log("Edit dialog closed.")
         this.dataSource = null
         this.getEvents(this.userId)
       })
   }
}
