import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit {
  @ViewChild('insertForm') insertForm: NgForm
  labels;
  labelsSub: Subscription;
  err
  user
  userSub: Subscription
  buttonStyle
  buttonStyle1
  constructor(private userLanguageService: UserLanguageService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddEventComponent>,
    private dashboardService: UserDashboardService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.err = false
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
     setTimeout(() => {
      if(this.user.companyId == 1){
        this.buttonStyle = "buttonEMSCal"
        this.buttonStyle1 = "buttonEMS1Cal"
      }else if(this.user.companyId == 2){
        this.buttonStyle = "buttonECGCal"
        this.buttonStyle1 = "buttonECG1Cal"
      }
      else{
        this.buttonStyle = "buttonECGCal"
        this.buttonStyle1 = "buttonECG1Cal"
      }
     }, 300);
    });
  }

  onSubmit(){
    console.log("pozvao onsubmit")
    if (this.insertForm.value.title == "" || this.insertForm.value.description == "" || this.insertForm.value.date == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      let data = {
        title: this.insertForm.value.title,
        description: this.insertForm.value.description,
        date: this.insertForm.value.date
      }
      this.dashboardService.addEvent(data).subscribe((response) => {
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblEventAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      })
    }
  }

  onNoClick() {
    this.dialogRef.close()
  }
}
