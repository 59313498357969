import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UserDashboardModule } from './layouts/user-dashboard/user-dashboard.module';
import { DefaultModule } from './layouts/default/default.module';

import { JwtModule } from '@auth0/angular-jwt';
import { LoginService } from './modules/login.service';
import { AuthService } from './modules/services/auth-service/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './modules/services/tokenInterceptor-service/token-interceptor.service';
import { FormsModule } from '@angular/forms';
import { HintVideoPlayerComponent } from './modules/hint-video-player/hint-video-player.component';


@NgModule({
  declarations: [
    AppComponent,
    HintVideoPlayerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    FormsModule,
    UserDashboardModule,
    JwtModule.forRoot({
      config: {
      }
    })
  ],
  providers: [LoginService, AuthService, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
  bootstrap: [AppComponent]
})

export class AppModule { }
