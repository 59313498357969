import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import {Unit} from './../../../models/unit.model'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Test } from 'src/app/models/test.model';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent implements OnInit {

  @ViewChild('editForm') editForm:NgForm
  self = this;
  labels;
  labelsSub: Subscription;
  assigned: any;
  testId
  adminSub
  admin
  trainers
  trainer
  showDurTime
  isItChecked
  constructor(public dialogRef: MatDialogRef<EditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar, private adminAuthService: AdminAuthService) { }

  ngOnInit(): void {
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
    });
    this.getLang()
    this.testId = this.data.id
    if(this.data.durationTime != null){
      console.log("IF")
      console.log(this.data.durationTime)
      this.isItChecked = true
      this.showDurTime = true
    }else{
      console.log("ELSE")
      console.log(this.data.durationTime)
      this.isItChecked = false
      this.showDurTime = false
    }
    this.getTrainers()
    console.log(this.testId)
    this.isTestAssigned()
  }
  
  getTrainers(){
    this.dashboardService.getTrainers().subscribe((trainers)=>{
      this.trainers = trainers
      this.trainers.forEach(t => {
        if(t.id == this.data.trainerId){
          this.trainer = "" + t.firstName + " " + t.lastName + ""
        }
      });
    })
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  isTestAssigned(){
    this.dashboardService.isTestAssigned(this.testId).subscribe((response)=>{
      this.updateAssigned(response);
    });
  }

  updateAssigned(resp) {
    if(resp == null) {
      this.assigned = [];
    }
    else {
      this.assigned = resp;
    }
    console.log("ASSIGNED");
    console.log(this.assigned);
  }

  onSubmit(){
    console.log(this.editForm.value);
    if(this.editForm.value.name == "" || this.editForm.value.minPercentage == "" || this.editForm.value.type == ""){
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }else{
      let test :Test = this.editForm.value
      if(this.editForm.value.durationTime){
        test.durationTime = this.editForm.value.durationTime
      }else{
        test.durationTime = null
      }
      if(this.editForm.value.minPercentage){
        test.minPercentage = Number(this.editForm.value.minPercentage)
      }
      test.dateCreated = new Date()
      test.required = this.data.required;
    this.dashboardService.editTest(test).subscribe((response)=>{
      console.log(response)
      this.dialogRef.close()
      this.snackBar.open(this.labels.lblTestEdited, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000  })
    })
   }
  }
  onNoClick(){
    this.dialogRef.close()
  }
  showDurationTime(e:any){
    if (e.checked){
      this.showDurTime = true
    }else{
      this.showDurTime = false;
      this.data.durationTime = null
      this.editForm.controls.durationTime.reset()
    }
  }

  isAssigned() {
    return this.assigned?.length > 0;
  }

}
