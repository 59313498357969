import { DataSource } from '@angular/cdk/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Observable } from 'rxjs';
import { Administrator } from 'src/app/models/administrator.model';
import { Department } from 'src/app/models/department.model';
import { User } from 'src/app/models/user.model';
import { AddListMessageComponent } from '../admin-user/add-list-message/add-list-message.component';
import { AddPendingUserComponent } from '../admin-user/add-pending-user/add-pending-user.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './insert-dialog/insert-dialog.component';
import { UserTrainingsDialogComponent } from '../admin-user/user-trainings-dialog/user-trainings-dialog.component';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-admin-administrator',
  templateUrl: './admin-administrator.component.html',
  styleUrls: ['./admin-administrator.component.scss']
})
export class AdminAdministratorComponent implements OnInit {

  displayedColumns: string[] = ['userName', 'firstName', 'lastName', 'departmentName', 'actions'];
  dataSource
  labels;
  labelsSub: Subscription;
  departments: Department[]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  constructor(private dashboardService: DashboardService, public dialog: MatDialog, public snackBar: MatSnackBar, public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getAdministrators();
    this.getLang();

  }
  ngAfterViewInit(): void {


  }

  getAdministrators() {
    this.dashboardService.fetchAdministrators().subscribe((admins) => {
      let admns = admins as Administrator[]
      admns = admns.sort((a, b) => a.userName.localeCompare(b.userName))
      this.dataSource = admns
      console.log(this.dataSource)
      this.getDepartments();
    })
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  openDeleteDialog(admin): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: { name: admin.firstName, id: admin.id }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      setTimeout(() => {
        this.getAdministrators()
      }, 1000);

    });

  }
  openEditDialog(user) {

    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '550px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getAdministrators()
    });
  }

  insertPendingUserDialog(user) {

    const dialogRef = this.dialog.open(AddPendingUserComponent, {
      width: '500px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getAdministrators()
    });
  }

  openInsertDialog() {
    const dialogRef = this.dialog.open(InsertDialogComponent, {
      width: '500px',
      height: '100%'

    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getAdministrators()
    });
  }

  openUserTrainingsDialog(user) {
    const dialogRef = this.dialog.open(UserTrainingsDialogComponent, {
      width: '500px',
      data: user
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getAdministrators()
    });
  }

  getDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
      this.dataSource.forEach(user => {
        this.departments.forEach(dep => {
          if (dep.id == user.departmentId) {
            user.departmentName = dep.name
          }
        })
      });
      this.dataSource = new MatTableDataSource(this.dataSource)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort
      this.dataSource.filterPredicate = (data : User, filter: string) => data.userName.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());

    })

  }

  openAddListDialog() {
    const dialogRef = this.dialog.open(AddListMessageComponent, {
      width: '400px',
      data: { flag: false, departmentId: "" }

    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getAdministrators()
      console.log('The dialog was closed');
    });
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value;
  }


}



export class UserDataSource extends DataSource<any>{
  constructor(private dasboardService: DashboardService) {
    super()
  }

  connect(): Observable<User[]> {
    return this.dasboardService.fetchUsers()
  }

  disconnect() { }

}
