import { Component, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DashboardService } from 'src/app/modules/dashboard.service';
import HC_exporting from 'highcharts/modules/exporting'
import { Subscription } from 'rxjs';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service';
import { AdminAuthService } from 'src/app/modules/services/admin-auth-service/admin-auth.service';
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
@Component({
  selector: 'app-test-success-pie',
  templateUrl: './test-success-pie.component.html',
  styleUrls: ['./test-success-pie.component.scss']
})
export class TestSuccessPieComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  result
  success = []
  pieChartColors
  labels;
  labelsSub: Subscription;
  admin
  adminSub: Subscription
  lab
  nums
  constructor(private dashboardService:DashboardService, public adminLanguageService: AdminLanguageService, private adminAuthService: AdminAuthService) {
   
   }

  ngOnInit(): void {
    this.lab = []
    this.nums = []
    this.getLang()
    this.getAdmin()
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  trainingSuccess(){
    let training = "training"
    this.dashboardService.trainingSuccess({training:training}).subscribe((result) =>{
      this.result = result
      console.log(result)
      this.result.forEach((r) => {
        console.log("name: " + r.name)
        // let d = {
        //     name: r.name,
        //     y: r.num
        // }
        // this.success.push(d)
        this.lab.push(r.name)
        this.nums.push(r.num)
        this.chartOptions = {
          series:this.nums,
          chart: {
            width: 380,
            type: "pie"
          },
          labels: this.lab,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 250
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        };
      })
    })
  }

  trainingSuccess1(){
    let training = "training"
    this.dashboardService.trainingSuccessForAdminUsers(this.admin.departmentId).subscribe((result) =>{
      this.result = result
      console.log(result)
      this.result.forEach((r) => {
        console.log("name: " + r.name)
        // let d = {
        //     name: r.name,
        //     y: r.num
        // }
        // this.success.push(d)
        this.lab.push(r.name)
        this.nums.push(r.num)
        this.chartOptions = {
          series:this.nums,
          chart: {
            width: 380,
            type: "pie"
          },
          labels: this.lab,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 250
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        };
      })
    })
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.trainingSuccess();
        }
        else{
          this.trainingSuccess1();
        }
      }, 100);
    });
  }

}
