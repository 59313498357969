import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserBeforeSurveyMessageComponent } from '../../survey/user-before-survey-message/user-before-survey-message.component';
import { UserDashboardService } from '../../user-dashboard.service';
import { UserBeforeTestMessageComponent } from '../../user-training/user-before-test-message/user-before-test-message.component';

@Component({
  selector: 'app-message-before-section-test',
  templateUrl: './message-before-section-test.component.html',
  styleUrls: ['./message-before-section-test.component.scss']
})
export class MessageBeforeSectionTestComponent implements OnInit {

  constructor(
    private userDashboardService: UserDashboardService, private languageService: UserLanguageService,
    private router: Router, private localStorageService: LocalStorageService,
    private authService: AuthService,
    public dialog: MatDialog) { }

    sectionTest$: Observable<Test>
    test: Test
    testSub: Subscription
  
    labels;
    labelsSub: Subscription;

    maxPoints
    
    user
    userSub: Subscription

    button1
    color

    status
    training
    trainingSub: Subscription
  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.trainingSub = this.userDashboardService.training$.subscribe((training) => {
      this.training = training;
      console.log("training: ", this.training)
    })
    this.userDashboardService.status$.subscribe((status) => {
      this.status = status;
      console.log("status 1")
      console.log(this.status)
    })
    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.sectionTest$ = this.userDashboardService.getSectionTestForTraining(this.localStorageService.getItem("testSectionId")).pipe(share());
    this.testSub = this.userDashboardService.sectionTest$.subscribe((test) => {
      console.log(test)
      setTimeout(() => {
        this.test = test;
        this.maxPoints = test.minPercentage
      }, 500);
    })
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.button1 = "buttonEMS"
          this.color = "#c32a2a"

        }else if(this.user.companyId == 2){
          this.button1 = "buttonECG"
          this.color = "rgb(241,106,100)"
        }
        else{
          this.button1 = "buttonECG"
          this.color = "rgb(241,106,100)"
        }
      }, 200);
    });
  }

  goToTestPage() {
    this.router.navigateByUrl('user/section-test')
  }

  skipTest(){
    if (this.status == 3) {
      console.log("status 3 - if")
      if(this.training.testId!= null && this.training.testId!= ""){
        this.openTestDialog();
      }
      else{
        if(this.training.surveyId!= null && this.training.surveyId!= ""){
          //this.test$ = this.userDashboarService.getSurveyForTraining(this.localStorageService.getItem('surveyId')).pipe(share())
          this.router.navigateByUrl('user/survey')
          this.openSurveyDialog()
        }else{
          this.router.navigateByUrl("/user/dashboard")
        }
      }
    } else {
      this.router.navigateByUrl("/user/training")
    }
  }

  openSurveyDialog(): void {
    this.router.navigateByUrl('/user/start-survey')
  }

  openTestDialog(): void {
    console.log("Open test dialog")
    // this.test$ = this.userDashboardService.getTestForTraining(this.training.testId).pipe(share())
    console.log("test id:")
    console.log(this.training.testId)
    this.router.navigateByUrl("/user/start-test")
  }

}
