import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, Validators, NgForm, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Observable, Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {
  username = new FormControl('', [Validators.required]);
  passwordControl = new FormControl('', [Validators.required]);
  firstNameControl = new FormControl('', [Validators.required]);
  lastNameControl = new FormControl('', [Validators.required]);
  departmentControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);

  selectedImage: File = null
  errorClass = ""
  labels;
  labelsSub: Subscription;
  err: boolean = false;
  downloadUrl$: Observable<string>
  downloadUrlSub: Subscription
  @ViewChild('div') form: ElementRef
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isCompleted
  admin
  adminSub: Subscription
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService,
    private adminAuthService: AdminAuthService,
    private adminLanguageService: AdminLanguageService, private storage: AngularFireStorage) {}


  @ViewChild('insertForm') insertForm: NgForm


  onSubmit() {
    console.log("pozvao submit!!!")
    console.log(this.insertForm.value.companyId)
    if (this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.email == "" || this.insertForm.value.password == "" || this.insertForm.value.confirmPassword == "" || this.insertForm.value.companyId == "") {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      if(this.insertForm.value.password == this.insertForm.value.confirmPassword){
        
      let trainer = {
        userName: this.insertForm.value.userName,
        firstName: this.insertForm.value.firstName,
        lastName: this.insertForm.value.lastName,
        email: this.insertForm.value.email,
        dateOfBirth: this.insertForm.value.dateOfBirth,
        about: this.insertForm.value.about,
        password: this.insertForm.value.password,
        profileImagePath: "",
        companyId: Number(this.insertForm.value.companyId)
      }
      if (this.selectedImage == null) {
        trainer.profileImagePath = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/noImage.jpg?alt=media&token=b8c8a3ec-2293-4657-bfd0-c10c19255144"
        this.insertTrainer(trainer)
            console.log(this.insertForm.value)
            // setTimeout(() => {
            //   this.dialogRef.close()
            //   this.snackBar.open(this.labels.lblTrainerInserted, 'OK', {
            //     verticalPosition: 'top',
            //     horizontalPosition: 'end',
            //     duration: 5000  })
            // }, 2000)
      }
      else {
        let ref = this.storage.ref(this.selectedImage.name)
        ref.put(this.selectedImage).then(t => {
          this.downloadUrlSub = ref.getDownloadURL().subscribe(url => {
            trainer.profileImagePath = url
            this.insertTrainer(trainer)
            console.log(this.insertForm.value)
            // setTimeout(() => {
            //   this.dialogRef.close()
            //   this.snackBar.open(this.labels.lblTrainerInserted, 'OK', {
            //     verticalPosition: 'top',
            //     horizontalPosition: 'end',
            //     duration: 5000  })
            // }, 2000)
          })
        })
      }
      }else{
        this.snackBar.open(this.labels.lblPasswordError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
    })
    this.err = false;
    this.isCompleted = false;
    this.getLang();
  }

  getTrainers(){
    this.dashboardService.getTrainers().subscribe((trainers)=>{
      console.log(trainers)
  })
}

goToNextStep(stepper: MatStepper) {
  if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == "") {
    this.err = true
    this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
  } else {
    if (this.insertForm.value.password == this.insertForm.value.confirmPassword) {
      this.isCompleted = true
      stepper.next()
    } else {
      this.snackBar.open(this.labels.lblPasswordError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }
  }
  this.isCompleted = false;
}
  insertTrainer(data) {
    this.dashboardService.insertTrainer(data).subscribe((response) => {
      if(response.code == "Username already in use"){
        this.snackBar.open(this.labels.lblUserNameAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if(response.code == "auth/email-already-in-use"){
        this.snackBar.open(this.labels.lblEmailAlreadyExists, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else if(response.code =="auth/weak-password"){
        this.snackBar.open(this.labels.lblWeakPassword, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      else{
        this.getTrainers()
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblTrainerInserted, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    })
  }

  onImageChanged(event) {
    this.selectedImage = event.target.files[0]
  }

  goBack(stepper: MatStepper){
    stepper.previous()
  }

}


