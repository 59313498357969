<app-admin-spinner *ngIf="labels == null || admin == null || result == null"></app-admin-spinner>
<div id="chart">
  <div class="menuBtn">
  <span class="h3">{{labels.lblNumOfUsersInTraining}}</span>
    <button mat-button [matMenuTriggerFor]="menu" style="float: right;">
      <mat-icon style = "color: #9dacd9 !important;">menu</mat-icon>
  </button>
      <mat-menu #menu = "matMenu" class="menu">
        <div *ngFor = "let t of trainings">
          <button mat-menu-item style="color: #9dacd9; " [routerLink]="['/admin/training-details',t.id]">
            {{t.name}}
          </button>
        </div>
      </mat-menu>
  </div>
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [yaxis]="chartOptions.yaxis"
      [xaxis]="chartOptions.xaxis"
      [legend]="chartOptions.legend"
      [colors]="chartOptions.colors"
      [grid]="chartOptions.grid"
    ></apx-chart>
  </div>
  
