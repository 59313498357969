import { DataSource } from '@angular/cdk/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Subscription, Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { Trainer } from 'src/app/models/trainer.model';
import { DeleteDialogComponent } from '../admin-trainer/delete-dialog/delete-dialog.component';
import { EditDialogComponent } from '../admin-trainer/edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from '../admin-trainer/insert-dialog/insert-dialog.component';
import { UserTrainingsDialogComponent } from '../admin-user/user-trainings-dialog/user-trainings-dialog.component';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { MatTableDataSource } from '@angular/material/table';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';

@Component({
  selector: 'app-admin-trainer',
  templateUrl: './admin-trainer.component.html',
  styleUrls: ['./admin-trainer.component.scss']
})
export class AdminTrainerComponent implements OnInit {

  bigChart=[]
  displayedColumns: string[] = [ 'firstName', 'lastName', 'email','actions'];
  dataSource;
  labels;
  labelsSub: Subscription;
  admin
  adminSub: Subscription
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort:MatSort
  bre
  constructor(private adminAuthService: AdminAuthService, private dashboardService:DashboardService,public dialog: MatDialog,public snackBar:MatSnackBar, public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
    });
    this.getTrainers();
    this.getLang();
    
  }
  ngAfterViewInit():void{
    
  }

  

  getTrainers(){
    this.dashboardService.getTrainers().subscribe((trainers)=>{
      let trs = trainers as Trainer[]
      this.dataSource = new MatTableDataSource(trs)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
      this.dataSource.filterPredicate = (data : Trainer, filter: string) => data.firstName.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
    })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  openDeleteDialog(trainer): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent,{
      width:'500px',
      data:{name:trainer.firstName,id:trainer.trainerId}
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getTrainers()
    });
    
  }
  openEditDialog(trainer){
    const dialogRef = this.dialog.open(EditDialogComponent,{
      width:'500px',
      height:'500px',
      data:trainer
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
     this.getTrainers()
    });
  }

  openInsertDialog(){
    const dialogRef = this.dialog.open(InsertDialogComponent,{
      width:'500px',
      height:'600px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getTrainers()
    });
  }

  openUserTrainingsDialog(trainer){
    const dialogRef=this.dialog.open(UserTrainingsDialogComponent,{
      width:'300px',
      data:trainer
    })
  }


}



export class UserDataSource extends DataSource<any>{
      constructor(private dasboardService:DashboardService){
        super()
      }

      connect():Observable<User[]>{
        return this.dasboardService.fetchUsers()
      }

      disconnect(){}

}
