<div class="row faqRow1">
    <div class="col-12 col-sm-7" style="margin: auto; text-align: center;">
        <div class = "row" style = "width: 100%; margin: auto;">
            <div class = "col-1">
                <img routerLink="/user/dashboard" style = "cursor: pointer" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FbackIcon.png?alt=media&token=d3776f59-033a-4d06-b5f3-ba9e925bc495" alt = "faq page image">
            </div>
            <div class = "col-10">
                <h3>FAQ </h3>
            </div>
        </div>
    </div>
</div>
<div class="row faqRow">
    <div class="col-12 col-sm-7 faqDiv">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q1}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: Lato !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a1}}</span>
               </div>

            </mat-expansion-panel>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q2}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: Lato !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a2}}</span>
               </div>

            </mat-expansion-panel>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q3}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: Lato !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a3}}</span>
               </div>

            </mat-expansion-panel>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q4}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: Lato !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a4}}</span>
               </div>

            </mat-expansion-panel>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q6}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: Lato !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a6}}</span>
               </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>