import { Injectable } from '@angular/core';
import { WebRequestService } from '../web-request.service';
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private webRequestService:WebRequestService) { }

  fetchUsers(){
    return this.webRequestService.get('users').subscribe((response)=>{
      console.log(response)
    })
  }
  checkUserCredentials(data):Observable<any>{
    return this.webRequestService.post('users/login',data)
  }

  confirmLogin():Observable<any>{
    return this.webRequestService.get('users/confirmLogin')
  }
}
