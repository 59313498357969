import { Component, Inject, OnInit } from '@angular/core';
//import { ZoomMtg } from '@zoomus/websdk';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { UserDashboardService } from '../user-dashboard.service';
import { share } from 'rxjs/operators';
import { AuthService } from '../services/auth-service/auth.service';
import { ActivatedRoute } from '@angular/router';

// ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.7/lib', '/av');
// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareJssdk();

@Component({
  selector: 'app-zoom-meeting',
  templateUrl: './zoom-meeting.component.html',
  styleUrls: ['./zoom-meeting.component.scss']
})
export class ZoomMeetingComponent implements OnInit {
 
 signatureEndpoint = 'http://localhost:1738/user/zoom-meeting'
 apiKey = '984gQm5jSnaZ2wFJ16TrGA'
 apiSecret = '5h3I41usJymgjzBSPfFAGFxelkJGkRbH0fdZ'
 meetingNumber
 role = 0
 leaveUrl = 'http://localhost:1738/user/dashboard'
 userName = 'Klijent'
 userEmail = ''
 passWord = 'testPass'
 registrantToken = ''
 signature
 meetingId
 meetingIdSub: Subscription
 meetingId$: Observable<Number>
 attendees
 user
 userSub: Subscription
  constructor(public httpClient: HttpClient,  @Inject(DOCUMENT) document, private db: AngularFirestore, private dashboardService: UserDashboardService,
  private authService: AuthService,
  private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.userSub = this.authService.user$.subscribe((user) => {
      this.user = user;
     setTimeout(() => {
      //this.getSignature(this.router.snapshot.params['id'], this.user.userName, this.user.userId)
     }, 200);
    });
  }

  getMeetingId(id, userName){
    console.log("HELOUUU")
    let events = []
    let docRef = this.db.firestore.collection("zoomMeetings").where("finished", "==", false).where("next", "==", true)
    docRef.get().then((snapshot) => {
      snapshot.forEach((change) => {
        let event = change.data()
        let meetingId = change.data().meetingId
        console.log(meetingId)
        //this.getSignature(change.data().meetingId, userName, id)
      });
    });
  }

  // getSignature(meetingId, userName, id) {
  //   console.log("meeting id: ", meetingId)
  //   this.signature = ZoomMtg.generateSignature({
  //    meetingNumber: String(meetingId),
  //    apiKey: this.apiKey,
  //    apiSecret: this.apiSecret,
  //    role: '0',
  //    success: res => {
  //      console.log(res.result)
  //    }
  //   });
  //   this.startMeeting(this.signature, meetingId, userName, id)
  //  }
 
  //   startMeeting(signature, meetingId, userName, id) {
  //    console.log(signature)
  //    document.getElementById('zmmtg-root').style.display = 'block'
  //    document.getElementById('containerId').style.height = '0px';
  //    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.7/lib', '/av');
  //    ZoomMtg.preLoadWasm();
  //    ZoomMtg.prepareJssdk();
  //    ZoomMtg.init({
  //      leaveUrl: this.leaveUrl,
  //      success: (success) => {
  //        console.log(success)
         
  //        ZoomMtg.join({
  //          signature: signature,
  //          meetingNumber: meetingId,
  //          userName: userName,
  //          apiKey: this.apiKey,
  //          passWord: this.passWord,
  //          success: (success) => {
  //            console.log(success)
  //            console.log("GET ATTENDEES LIST: ")
  //            ZoomMtg.getAttendeeslist({
  //             success: function (res) {
  //               console.log("GET ATTENDEES LIST", res);
  //               console.log(res.length)
  //             }
  //           });
  //           this.db.firestore.collection("zoomMeetings").where("meetingId", "==", Number(meetingId)).get().then((query) => {
  //             query.forEach((doc) => {
  //               console.log("USAO U FOR")
  //               let attendeesList = doc.data().attendeesList
  //              if(!attendeesList.includes(userName)){
  //               attendeesList.push(userName)
  //               console.log("AL: ", attendeesList)
  //               this.db.firestore.collection("zoomMeetings").doc(doc.id).update({attendeesList: attendeesList})
  //               let ref = this.db.firestore.collection("zoomMeetings").doc(doc.id).collection("evaluation").where("userName", "==", userName).get().then((query2) => {
  //                 query2.forEach((doc2) => {
  //                   this.db.firestore.collection("zoomMeetings").doc(doc.id).collection("evaluation").doc(doc2.id).update({grade: 5})
  //                 })
  //               })
  //              }
  //             })
  //           })
  //          },
  //          error: (error) => {
  //            console.log("ERROR 1")
  //            console.log(error)
  //          }
  //        })
 
  //      },
  //      error: (error) => {
  //       console.log("ERROR ")
  //        console.log(error)
  //      }
  //    })
  //  }

}
