import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TestQuestionAnswer } from 'src/app/models/testQuestionAnswer.model';
import { AdminTestQuestionsAndAnswersComponent } from '../../admin-test/admin-test-questions-and-answers/admin-test-questions-and-answers.component';
import { DeleteTestAnswerComponent } from '../../admin-test/admin-test-questions-and-answers/delete-test-answer/delete-test-answer.component';
import { DeleteTestQuestionComponent } from '../../admin-test/admin-test-questions-and-answers/delete-test-question/delete-test-question.component';
import { EditTestAnswerComponent } from '../../admin-test/admin-test-questions-and-answers/edit-test-answer/edit-test-answer.component';
import { EditTestQuestionComponent } from '../../admin-test/admin-test-questions-and-answers/edit-test-question/edit-test-question.component';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
export interface TestQuesetion {
  text: string
  order: number
  points: number
}
export interface Question {
  text: string
  type: number
}
export interface Answer {
  text: string
  order: number
  correct: boolean
}

@Component({
  selector: 'app-add-question-dialog',
  templateUrl: './add-question-dialog.component.html',
  styleUrls: ['./add-question-dialog.component.scss']
})
export class AddQuestionDialogComponent implements OnInit {

  text = new FormControl('', [Validators.required]);
  points = new FormControl('', [Validators.required])
  type = new FormControl('', [Validators.required]);
  textAnswerControl = new FormControl('', [Validators.required]);
  question: Question
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  value = true
  idQuestion: string
  showOrderButton = false
  correct = false;
  @ViewChild('table1') table1: MatTable<Answer[]>
  @ViewChild('table') table: MatTable<TestQuesetion[]>
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('insertForm1') insertForm1: NgForm
  typeOfQuestion: number
  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminTestQuestionsAndAnswersComponent>, private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private adminLanguageService: AdminLanguageService) { }
  displayedColumns: string[] = ['order', 'type', 'text', 'points', 'actions'];
  displayedColumns1: string[] = ['order', 'text', 'correct', 'actions'];
  dataSource
  dataSource1
  testId: string
  textAnsErr: boolean = false;
  textErr: boolean = false;
  typeErr: boolean = false;
  ngOnInit(): void {
    this.textAnsErr = false;
    this.textErr = false;
    this.typeErr = false;
    console.log("TEST ID :::")
    this.testId = this.data.id;
    console.log(this.testId)
    this.fetchQuestionsForTest()
    this.getLang()
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }

  fetchQuestionsForTest() {
    this.dashboardService.fetchQuestionsForTest(this.testId).subscribe((response) => {
      this.dataSource = response
    })
  }

  onSubmit() {
    if (this.insertForm.value.textQ == "" || this.insertForm.value.points == "" || this.insertForm.value.type == "") {
      this.textErr = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000  })
    } else {
      this.dashboardService.insertQuestionForTest({
        testId: this.testId,
        text: this.insertForm.value.textQ,
        order: this.dataSource.length + 1,
        points: this.insertForm.value.points,
        type: this.insertForm.value.type
      }).subscribe((response) => {
        console.log(response)
      })
    }
    this.insertForm.controls.textQ.reset()
    this.insertForm.controls.points.reset()
    this.insertForm.controls.type.reset()
    this.fetchQuestionsForTest()
  }

  setTypeOfAnswer(number) {
    console.log(number)
    this.typeOfQuestion = number
  }

  openEditDialog(test) {
    const dialogRef = this.dialog.open(EditTestQuestionComponent, {
      width: '500px',
      data: test
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dashboardService.fetchQuestionsForTest(this.testId).subscribe((response) => this.dataSource = response)
    })
  }

  openDeleteDialog(test) {
    const dialogRef = this.dialog.open(DeleteTestQuestionComponent, {
      width: '500px',
      data: test
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.dashboardService.fetchQuestionsForTest(this.testId).subscribe(response => {
        this.dataSource = response
        // this.changeOrder()
      })
    })
  }

  // changeOrder(){
  //   let questions=this.dataSource
  //   for (let index = 0; index < questions.length; index++) {
  //     const element = questions[index];
  //     this.dashboardService.changeOrderOfQuestions({id:element.id,testId:element.testId,order:index+1}).subscribe((response)=>{
  //       console.log(response)
  //       this.dashboardService.fetchQuestionsForTest(this.testId).subscribe(response=>{
  //         this.dataSource=response
  //         console.log('operation finshed')

  //       })

  //     })

  //   }
  //   this.value=!this.value
  // }
  // drop(event){
  //   const prevIndex=this.dataSource.findIndex((d)=>d===event.item.data)
  //   moveItemInArray(this.dataSource, prevIndex, event.currentIndex);
  //   this.table.renderRows()
  // }
  // showChangeButton(){

  //   this.value=!this.value
  // }

  // showOrderButtons(){
  //   this.showOrderButton=!this.showOrderButton
  // }


  //functions for answers
  answersForQuestion(questionId) {
    this.idQuestion = questionId
    this.dashboardService
      .fetchAnswersForQuestion(this.testId, questionId)
      .subscribe(response => this.dataSource1 = response)
    this.dashboardService.fetchQuestion(this.testId, this.idQuestion).subscribe(
      (response) => {
        console.log(response)
        this.question = response as Question
      })
  }

  onSubmitAns() {
    if (this.insertForm1.value.text == "") {
      this.textAnsErr = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000  })
    }
    else {
      if (this.dataSource1.length + 1 == 1) {
        this.dashboardService.creteAnswer({
          testId: this.testId,
          testQuestionId: this.idQuestion,
          correct: true,
          order: this.dataSource1.length + 1,
          text: this.insertForm1.value.text
        }).subscribe(response => {
          console.log(response)
        })
      }
      else {
        this.dashboardService.creteAnswer({
          testId: this.testId,
          testQuestionId: this.idQuestion,
          correct: false,
          order: this.dataSource1.length + 1,
          text: this.insertForm1.value.text
        }).subscribe(response => {
          console.log(response)
        })
      }

    }
    this.insertForm1.controls.text.reset()
    this.answersForQuestion(this.idQuestion);
  }
  deleteDialog(answer) {
    let flag = this.canDeleteAnswer(answer)

      const dialogRef = this.dialog.open(DeleteTestAnswerComponent, {
        width: '500px',
        data: { id: answer.id, testId: this.testId, testQuestionId: this.idQuestion, flag:flag }
      })

      dialogRef.afterClosed().subscribe(response => {
        this.dashboardService.fetchAnswersForQuestion(this.testId, this.idQuestion).subscribe(response => {
          this.dataSource = response
          // this.changeOrder()
        })
        this.answersForQuestion(this.idQuestion)
      })


  }
  editDialog(answer) {
    const dialogRef = this.dialog.open(EditTestAnswerComponent, {
      width: '500px',
      data: { id: answer.id, testId: this.testId, questionId: this.idQuestion, text: answer.text }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dashboardService.fetchAnswersForQuestion(this.testId, this.idQuestion).subscribe(response => {
        this.dataSource = response
      })
      this.answersForQuestion(this.idQuestion)
    })
  }
  changeStatus(answer) {
    //this.idQuestion == answer.testQuestionId
    console.log("tip: " + this.question.type)
    if (this.question.type == 2) {
      this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: answer.id, correct: !answer.correct })
        .subscribe((response) => {
          console.log(response)
          this.dashboardService.fetchAnswersForQuestion(this.testId, this.idQuestion).subscribe(response => {
            this.dataSource1 = response
            return
          })
        })
      return
    }
    if (this.question.type == 1) {
      for (let index = 0; index < this.dataSource1.length; index++) {
        const element = this.dataSource1[index];

          if (element.id === answer.id) {
            this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: element.id, correct: !answer.correct }).subscribe((response) => {
              this.dashboardService.fetchAnswersForQuestion(this.testId, this.idQuestion).subscribe(
                response => this.dataSource1 = response
              )
            })
          } else {
            this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: element.id, correct: answer.correct }).subscribe((response) => {
              this.dashboardService.fetchAnswersForQuestion(this.testId, this.idQuestion).subscribe(
                response => this.dataSource1 = response
              )
            })
          }
        
        

      }
      return
    }

    
  }

  isDisabled(answer){
    console.log(this.question.type)
    if(this.question.type==1 && answer.correct){
      return true
    }else if(this.question.type == 2 && answer.correct){
      var flag = this.canChangeStatus(answer)
      if(flag){
        return false
      }else{
        return true
      }
    }else{
      return false
    }
  }

  canDeleteAnswer(answer: TestQuestionAnswer): boolean {
    let answers: TestQuestionAnswer[] = this.dataSource1
    let answers1 = answers.filter(a => a.id != answer.id)
    for (let index = 0; index < answers1.length; index++) {
      if (answers1[index].correct) {
        return true;
      }
    }
    return false
  }

  canChangeStatus(answer): boolean{
    let answers: TestQuestionAnswer[] = this.dataSource1
    var cnt = 0
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].correct) {
        cnt++
      }
    }
    if(cnt > 1){
      return true;
    }
    return false
  }

  goBack(stepper: MatStepper){
    stepper.previous()
  }



}
