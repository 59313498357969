<app-admin-spinner *ngIf="dataSource == null || labels == null || test == null || test.name == null || test.type == null"></app-admin-spinner>

<div class="row">
  <div class="col-12 col-xl-6 sectorInfo" style="margin-bottom: 3%;">
    <div class="trainingCard" style="background-color:#182038 !important">
      <h2 class="basicInfo">{{labels.lblBasicInfo}}</h2>
        <mat-card style="padding-top: 0px;">
            <mat-card-content>
                <div class="row info">
                    <div class="col-6">
                        <h3 *ngIf = "test.type == 1">{{labels.lblTestName}}: </h3>
                        <h3 *ngIf = "test.type == 2">{{labels.lblPretestName}}: </h3>
                        <h3 *ngIf = "test.type == 3">{{labels.lblSurveyName}}: </h3>
                    </div>
                    <div class="col-6">
                        <h3  style="color: #9dacd9; float: right;">{{test.name}}</h3>
                    </div>
                </div>
                <div class="row info">
                  <div class="col-6">
                      <h3>{{labels.lblType}}: </h3>
                  </div>
                  <div class="col-6">
                      <h3 *ngIf = "test.type == 1" style="color: #9dacd9; float: right">{{labels.lblTest}}</h3>
                      <h3 *ngIf = "test.type == 2" style="color: #9dacd9; float: right">{{labels.lblPretest}}</h3>
                      <h3 *ngIf = "test.type == 3" style="color: #9dacd9; float: right">{{labels.lblSurvey}}</h3>
                  </div>
                </div>

              <!-- Obavezan polje -->
              <div class="row info" *ngIf = 'test.type == 1'>
                <div class="col-6">
                  <h3>{{labels.lblRequiredTest}}: </h3>
                </div>
                <div class="col-6">
                  <h3 *ngIf = "test.required" style="float: right;">{{labels.lblYes}}</h3>
                  <h3 *ngIf = "!test.required" style="float: right;">{{labels.lblNoStrict}}</h3>
                </div>
              </div>

              <!-- Broj pokusaja polje -->
              <div class="row info" *ngIf = "test.type == 1 && test.numberOfTestDrops">
                <div class="col-6">
                  <h3>{{labels.lblNumofAttemptsTest}}: </h3>
                </div>
                <div class="col-6">
                  <h3 style="float: right;">{{test.numberOfTestDrops}}</h3>
                </div>
              </div>


              <div class="row info" *ngIf = "test.trainerId">
                <div class="col-6">
                    <h3>{{labels.lblTrainer}}: </h3>
                </div>
                <div class="col-6">
                    <h3 style="color: #9dacd9; float: right; cursor: pointer; color: #14abf9;"  [routerLink]="['/admin/trainer-profile',test.trainerId]">{{trainer}}</h3>
                </div>
              </div>

                <div class="row info" *ngIf = "test.minPercentage">
                    <div class="col-6">
                        <h3>{{labels.lblMinPercentage}}: </h3>
                    </div>
                    <div class="col-6">
                        <h3 style="color: #9dacd9; float: right">{{test.minPercentage}}%</h3>
                    </div>
                </div>
                <div class="row info" *ngIf = "test.durationTime">
                  <div class="col-6">
                      <h3>{{labels.lblTestDuration}}: </h3>
                  </div>
                  <div class="col-6">
                      <h3 style="color: #9dacd9; float: right">{{test.durationTime}}</h3>
                  </div>
                </div>
                
                <div class="row" style="text-align: right;">
                  <div class="col-12 text-right" style="text-align: right;">
                    <button mat-raised-button class="tableBtn" style="float: right;"
                    (click)="openEditTestDialog(test)"><span *ngIf = "test.type == 1">{{labels.lblEditTest}}</span>
                    <span *ngIf = "test.type == 2">{{labels.lblEditPretest}}</span>
                    <span *ngIf = "test.type == 3">{{labels.lblEditSurvey}}</span>
                  </button>
                  </div>
                </div>
            </mat-card-content>
          </mat-card>
    </div>
  </div>
  
  <div class="col-12 col-xl-6">
    <div class="testCard">
      <div class="row">
        <div class="col-12 text-right" style="margin-bottom: 15px; text-align: right;">
          <button mat-raised-button class="tableBtn" style="margin-right: 20px;" 
          [routerLink]="['/admin/tests',test.id]">{{labels.lblQuestions}}</button>
        </div>
      </div>
      <div *ngFor="let question of test.questions">
        <mat-accordion class="example-headers-align" multi style="width: 70%;">
          <mat-expansion-panel style="padding-top: 10px;">
            <mat-expansion-panel-header>
              <div class="question"><span data-toggle="tooltip" data-placement="top" title={{question.text}} style="display:table-cell; vertical-align: middle; padding-left: 10px; float: left;">
                <span>{{question.order}}. </span> <span *ngIf = "test.random == true"> [R] </span>
                <span class = "questionText mobileSpan" [routerLink]="['/admin/tests', question.testId, question.id, 'question-answers']">{{question.text.substring(0,9)}}</span> <span class="mobileSpan" *ngIf = "question.text.length > 9">...</span>
                <span class = "questionText webSpan" [routerLink]="['/admin/tests', question.testId, question.id, 'question-answers']">{{question.text.substring(0,18)}}</span> <span class="webSpan" *ngIf = "question.text.length > 18">...</span> <span class="points" *ngIf = "test.type == 1"> ({{labels.lblPoints}}: {{question.points}})</span></span>
                <mat-icon class="settingsSection" [matMenuTriggerFor]="settingsMenu" (click)="stopPropagation($event)">
                  settings</mat-icon>
                <mat-menu #settingsMenu="matMenu" class="mMenu">
                  <button mat-menu-item class="menuButton settingsMenuItem" (click)="openTestStepper(question)">
                    <mat-icon style="color: #8293c6;" >edit</mat-icon>
                    <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                  </button>
                  <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteQuestionDialog(question)" [disabled] = "assigned.length > 0">
                    <mat-icon style="color: #8293c6;">delete</mat-icon>
                    <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                  </button>
                </mat-menu>
              </div>
            </mat-expansion-panel-header>
            <div *ngFor="let answer of question.answers" class="answer">
              <span data-toggle="tooltip" data-placement="top" title={{answer.text}} [class.correct]="answer.correct == true"
                style="display:table-cell; vertical-align: middle; padding-left: 10px;">
                <span>{{answer.order}}. </span> <span *ngIf = "question.random == true"> [R] </span>  {{answer.text.substring(0,21)}}<span *ngIf = "answer.text.length > 21">...</span></span>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
  
</div>
<div fxLayout="row" class="table1" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf="outlet.isActivated===false">
  <div fxFlex="5"></div>
  <div fxFlex="90">
    <!-- <mat-card cdkDrag>
              <div fxLayout="row" fxFlex="100">
                  <mat-card-title fxFlex="90">{{labels.lblTrainings}}</mat-card-title>
                    <mat-card-content fxFlex="10" fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                      <button class="tableBtn" mat-raised-button (click)="openInsertDialog()">{{labels.lblAddTraining}}</button>
                
                    </mat-card-content>
                </div>
          </mat-card> -->
    <div class="mat-elevation-z8">
      <div class="row header">
        <div class="col-md-6 text-left">
          <h5 class="tableName">{{labels.lblTrainings}}</h5>
        </div>
        <div class="col-md-6 text-right"><button class="tableBtn" mat-raised-button
            (click)="openTrainingsTestDialog(test)">{{labels.lblAssignTest}}</button></div>
        <div class="col-10 text-left search1">
          <mat-form-field class="search" appearance="none" fxFlex="40%">
              <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
          </mat-form-field>
      </div>
      </div>

      <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true">

        <!-- userName Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblETrainingName}}</th>
          <td mat-cell data-toggle="tooltip" data-placement="top" title={{training.name}} *matCellDef="let training" [routerLink]="['/admin/training-details',training.id]" class="trainingName">
            {{training.name.substring(0,21)}}<span *ngIf="training.name.length > 21">...</span> </td>
        </ng-container>

        <!-- firstName Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblDescription}}</th>
          <td mat-cell data-toggle="tooltip" data-placement="top" title={{training.description}}
            *matCellDef="let training" class="hideColumn"> {{training.description.substring(0,21)}}<span
              *ngIf="training.description.length > 21">...</span></td>
        </ng-container>

        <!--Actions column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
          <td mat-cell *matCellDef="let training">
            <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
            <mat-menu #settingsMenu="matMenu" class="mMenu">
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(training)">
                <mat-icon style="color: #8293c6;">delete</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div fxFlex="5"></div>
</div>

<div fxLayout="row" class="table1" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf="outlet.isActivated===false">
  <div fxFlex="5"></div>
  <div fxFlex="90">
    <!-- <mat-card cdkDrag>
              <div fxLayout="row" fxFlex="100">
                  <mat-card-title fxFlex="90">{{labels.lblTrainings}}</mat-card-title>
                    <mat-card-content fxFlex="10" fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                      <button class="tableBtn" mat-raised-button (click)="openInsertDialog()">{{labels.lblAddTraining}}</button>
                
                    </mat-card-content>
                </div>
          </mat-card> -->
    <div class="mat-elevation-z8">
      <div class="row header">
        <div class="col-md-6 text-left">
          <h5 class="tableName">{{labels.lblSections}}</h5>
        </div>
        <div class="col-10 text-left search1">
          <mat-form-field class="search" appearance="none" fxFlex="40%">
              <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter1($event.target.value)" placeholder={{labels.lblSearch}}></span>
          </mat-form-field>
      </div>
      </div>

      <table mat-table [dataSource]="dataSource1" matSort matSortDisableClear="true">
        <ng-container matColumnDef="trainingName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblETrainingName}}</th>
          <td mat-cell data-toggle="tooltip" data-placement="top" title={{section.trainingName}} *matCellDef="let section" >
            {{section.trainingName.substring(0,21)}}<span *ngIf="section.trainingName.length > 21">...</span> </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblSectionName}}</th>
          <td mat-cell data-toggle="tooltip" data-placement="top" title={{section.name}} *matCellDef="let section" >
            {{section.name.substring(0,21)}}<span *ngIf="section.name.length > 21">...</span> </td>
        </ng-container>


        <!--Actions column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
          <td mat-cell *matCellDef="let section">
            <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
            <mat-menu #settingsMenu="matMenu" class="mMenu">
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteSectionDialog(section)">
                <mat-icon style="color: #8293c6;">delete</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div fxFlex="5"></div>
</div>

<router-outlet #outlet="outlet"></router-outlet>