import { Component, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { share, subscribeOn } from 'rxjs/operators';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './insert-dialog/insert-dialog.component';
import { DashboardService } from '../dashboard.service';
import {MatTable, MatTableDataSource} from '@angular/material/table'
import {moveItemInArray} from '@angular/cdk/drag-drop'
import { DeleteTestAnswerComponent } from '../admin-test/admin-test-questions-and-answers/delete-test-answer/delete-test-answer.component';
import { EditTestAnswerComponent } from '../admin-test/admin-test-questions-and-answers/edit-test-answer/edit-test-answer.component';
import { TestQuestionAnswer } from 'src/app/models/testQuestionAnswer.model';
import { Observable, Subscription } from 'rxjs';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import { Test } from 'src/app/models/test.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
export interface Question{
  text:string
  type:number
  random:boolean
}
export interface Answer {
  text: string
  order: number
  correct: boolean
}
@Component({
  selector: 'app-admin-test-question-answer',
  templateUrl: './admin-test-question-answer.component.html',
  styleUrls: ['./admin-test-question-answer.component.scss']
})
export class AdminTestQuestionAnswerComponent implements OnInit {
  question:Question
  questionId:string
  testId:string
  
  value=true 
  showOrderButton=false
  @ViewChild('table') table:MatTable<Answer[]>
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  labels;
  labelsSub: Subscription;
  assigned
  value1: boolean = true
  constructor(private router:ActivatedRoute,private dashboardService:DashboardService,private dialog:MatDialog,
    private userDashboarService: UserDashboardService, private adminLanguageService: AdminLanguageService) { }

  displayedColumns: string[] = ['order','text','correct','actions'];
  dataSource
  test:Test
  test$: Observable<Test>
  testSub: Subscription
  
  ngOnInit(): void {
    this.questionId=this.router.snapshot.params['questionId']
    this.router.params.subscribe((params)=>this.questionId=params['questionId'])
    this.testId=this.router.snapshot.parent.params['id']
    this.router.parent.params.subscribe(params=>this.testId=params['id'])
    this.isTestAssigned()
    this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.testId).pipe(share())
    this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
      this.test = test;
      setTimeout(() => {
        if(this.test.type == 3){
          this.displayedColumns = ['order','text','actions'];
        }else{
          this.displayedColumns = ['order','text','correct','actions'];
        }
      }, 250);
    });
    this.getLang()
    this.fetchAnswersForQuestion()
    this.fetchQuestion()
  }

  isTestAssigned(){
    this.dashboardService.isTestAssigned(this.testId).subscribe((response)=>{
    console.log(response)
     if(response == null){
       this.assigned = []
     }else{
      this.assigned = response
     }
     console.log(this.assigned)
    })
  }

  fetchAnswersForQuestion(){
    this.dashboardService
    .fetchAnswersForQuestion(this.testId,this.questionId)
    .subscribe(response=>{
      let answers = response as TestQuestionAnswer[]
      answers = answers.sort((a, b) => (a.order < b.order ? -1 : 1));
      this.dataSource = new MatTableDataSource(answers)
      setTimeout(() => {
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data : TestQuestionAnswer, filter: string) => data.text.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
      }, 2000);
    })
  }

  fetchQuestion(){
    this.dashboardService.fetchQuestion(this.testId,this.questionId).subscribe(
      (response)=>{
        console.log(response)
        this.question=response as Question
      }
      
    )
  }

  insertDialog(){
    const dialogRef=this.dialog.open(InsertDialogComponent,{
      width:'500px',
      data:{questionId:this.questionId, testId:this.testId, order:this.dataSource.length+1, type: this.test.type}
    })
    dialogRef.afterClosed().subscribe(response=>{
      this.dataSource = null
      this.fetchAnswersForQuestion()
      this.dashboardService.fetchQuestionsForTest(this.testId).pipe(share())
    })
  }
  
  deleteDialog(answer) {
    let flag = this.canDeleteAnswer(answer)

    const dialogRef = this.dialog.open(DeleteTestAnswerComponent, {
      width: '500px',
      data: { id: answer.id, testId: this.testId, testQuestionId: this.questionId, flag: flag }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
      this.fetchAnswersForQuestion()
      setTimeout(() => {
        this.changeOrderAnswers1()
      }, 1000);
    })


  }
  editDialog(answer) {
    const dialogRef = this.dialog.open(EditTestAnswerComponent, {
      width: '500px',
      data: { id: answer.id, testId: this.testId, questionId: this.questionId, text: answer.text }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
      this.fetchAnswersForQuestion()
    })
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  canDeleteAnswer(answer: TestQuestionAnswer): boolean {
    let answers: TestQuestionAnswer[] = this.dataSource.data
    let answers1 = answers.filter(a => a.id != answer.id)
    for (let index = 0; index < answers1.length; index++) {
      if (answers1[index].correct) {
        return true;
      }
    }
    return false
  }

 
  drop1(event) {
    console.log('drop1')
    let answers = this.dataSource.data
    const prevIndex = answers.findIndex((d) => d === event.item.data)
    moveItemInArray(answers, prevIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(answers)
    this.table.renderRows()
  }

  isDisabled(answer) {
    if (this.question.type == 1 && answer.correct) {
      return true
    } else if (this.question.type == 2 && answer.correct) {
      var flag = this.canChangeStatus(answer)
      if (flag) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
    
  }

  changeRandomAnswers() {
    this.question.random = !this.question.random
  }

  canChangeStatus(answer): boolean {
    let answers: TestQuestionAnswer[] = this.dataSource.data
    var cnt = 0
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].correct) {
        cnt++
      }
    }
    if (cnt > 1) {
      return true;
    }
    return false
  }

  changeStatus(answer) {
    //this.idQuestion == answer.testQuestionId
    console.log("tip: " + this.question.type)
    if (this.question.type == 2) {
      this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: answer.id, correct: !answer.correct })
        .subscribe((response) => {
          this.fetchAnswersForQuestion()
        })
    }
    else if (this.question.type == 1) {
      for (let index = 0; index < this.dataSource.data.length; index++) {
        const element = this.dataSource.data[index];

        if (element.id === answer.id) {
          this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: element.id, correct: !answer.correct }).subscribe((response) => {
            this.fetchAnswersForQuestion()
          })
        } else {
          this.dashboardService.changeAnswerStatus({ testId: answer.testId, testQuestionId: answer.testQuestionId, id: element.id, correct: answer.correct }).subscribe((response) => {
            this.fetchAnswersForQuestion()
          })
        }
      }
      return
    }

  }

  changeOrderAnswers() {
    let answers = this.dataSource.data
    if (this.question.random) {
       this.dashboardService.setAnswersOrder(true, this.testId, this.questionId).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index];
        this.dashboardService.changeAswersOrder({ id: element.id, testId: element.testId, questionId: this.questionId, order: index + 1 }).subscribe((response) => {
          this.fetchAnswersForQuestion()
          this.dashboardService.setAnswersOrder(false, this.testId, this.questionId).subscribe(response => {
            console.log(response)
          })
        }) 
      }
    }
    this.value1 = !this.value1
  }

  changeOrderAnswers1() {
    let answers = this.dataSource.data
    if (this.question.random) {
       this.dashboardService.setAnswersOrder(true, this.testId, this.questionId).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index];
        this.dashboardService.changeAswersOrder({ id: element.id, testId: element.testId, questionId: this.questionId, order: index + 1 }).subscribe((response) => {
          this.fetchAnswersForQuestion()
          this.dashboardService.setAnswersOrder(false, this.testId, this.questionId).subscribe(response => {
            console.log(response)
          })
        }) 
      }
    }
  }

  showChangeButtonAnswers() {

    this.value1 = !this.value1
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

}
