import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {DashboardService} from './../../dashboard.service'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import {Position} from './../../../models/positon.model'
import { Subscription } from 'rxjs';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import {FormControl, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {
    username = new FormControl('', [Validators.required]);
    passwordControl = new FormControl('', [Validators.required]);
    firstNameControl = new FormControl('', [Validators.required]);
    lastNameControl = new FormControl('', [Validators.required]);
    departmentControl = new FormControl('', [Validators.required]);
    emailControl = new FormControl('', [Validators.required]);

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    departments:Department[]
    positions:Position[]
    errorClass = ""
    labels;
    labelsSub: Subscription;
    err: boolean = false;
    constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }


    @ViewChild('insertForm') insertForm:NgForm

          
    onSubmit(){
      console.log(this.insertForm.value)
      if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == ""  || this.insertForm.value.email == "" || this.insertForm.value.position == "") {
        // this.errorClass = "errorClass"
        this.err = true
        this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }else{
      if(this.insertForm.value.password == this.insertForm.value.confirmPassword){
        let user:any
        user = this.insertForm.value
        user.departmentId = this.data.id
        console.log(user)
        this.insertUser(user)
        setTimeout(() => {
          this.dialogRef.close()
        },2000)
      }else{
        this.snackBar.open(this.labels.lblPasswordError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
      }
    }
    getLang(){
      this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
        this.labels = labels;
        })
    }
    onNoClick(): void {
    this.dialogRef.close();
    }
    ngOnInit(): void {
      this.err = false;
      this.fetcDepartments()
      this.fetchPositions()
      this.getLang();
    }
    fetcDepartments(){
      return this.dashboardService.fetchAllDepartments().subscribe((response)=>{
        this.departments=response as Department[]
      })
    }
    fetchPositions(){
      return this.dashboardService.fetchPositions().subscribe((response)=>{
        this.positions=response as Position[]

      })
    }
    getUsers(){
      this.dashboardService.getUsers().subscribe((users)=>{
        console.log(users)
      })
    }

    insertUser(data){
      this.dashboardService.insertUser(data).subscribe((response)=>{
        this.getUsers()
        console.log("response")
        console.log(response)
        this.snackBar.open(this.labels.lblUserAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      })
    }

    goToNextStep(stepper: MatStepper){
      if (this.insertForm.value.userName == "" || this.insertForm.value.firstName == "" || this.insertForm.value.lastName == "" || this.insertForm.value.password == "") {
        this.err = true
        this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }else{
        if(this.insertForm.value.password == this.insertForm.value.confirmPassword){
          stepper.next()
        }else{
          this.snackBar.open(this.labels.lblPasswordError, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
        }
      }
    }

    goBack(stepper: MatStepper){
      stepper.previous()
    }

}

