<div class="login-page">

  <div class="language">
    <span>{{labels.lblLanguage}}</span>
    <button mat-icon-button [matMenuTriggerFor]="langMenu">
      <img *ngIf="lang=='srb'" class="flag" style="margin-left: -15px;" alt = "srb lang"
        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
        <img *ngIf="lang=='cirilica'" class="flag" alt = "cirilica lang"
        style="margin-left: -15px;"
        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
      <img *ngIf="lang=='eng'" class="flag" alt = "eng lang"
        style="margin-left: -15px;"
        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-engleski1.png?alt=media&token=236e883a-232b-437e-91a0-3b07e21dc490" alt="">
    </button>
    <mat-menu #langMenu="matMenu" class="mMenu">
      <button mat-menu-item class="menuButton" (click)="selectLang('srb')">
        <img class="flag" alt = "srb flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
        <span class="aligned-with-icon"> {{labels.lblSerbian}}</span>
      </button>
      <button mat-menu-item class="menuButton" (click)="selectLang('cirilica')">
        <img alt = "cyrillic flag" class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
        <span class="aligned-with-icon">  {{labels.lblSerbianCyrillic}}</span>
      </button>
      <button mat-menu-item class="menuButton" (click)="selectLang('eng')">
        <img alt = "english flag" class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-engleski1.png?alt=media&token=236e883a-232b-437e-91a0-3b07e21dc490"
          alt="">
        <span class="aligned-with-icon"> {{labels.lblEnglish}}</span>
      </button>
    </mat-menu>
  </div>

    <div class="picture">
      <img class="lesteLogo" alt = "leste logo" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/logo%202.png?alt=media&token=940ce535-e4b5-4738-b9ca-4d89f38924df">
    </div>

    <mat-card class="mat-elevation-z12" style="background-color: #182038;
    color: #9dacd9; text-align: center;">
    <!-- <a mat-list-item class="font"  routerLinkActive="list-item-active" href="https://zoom.us/oauth/authorize?response_type=code&client_id=fU1My98QC2ipHpvi5kRXA&redirect_uri=http%3A%2F%2Flocalhost%3A1738%2Flogin">{{labels.lblMeeting}}</a> -->
    <h3 style="margin: auto; color: #9dacd9; font-size: 25px;margin-top:25px; font-family: 'Prompt', sans-serif !important;">{{labels.lblWelcome}}</h3>
    <h5 style="margin: auto; color: #9dacd9; margin-top:15px; font-size:15px; font-family: 'Prompt', sans-serif !important;">{{labels.lblLogInMsg}}</h5>
      <!-- <mat-card-title style="text-align:center;">SignIn</mat-card-title> -->
      <mat-tab-group class="example-stretched-tabs" style="background-color: #182038;
      color: #9dacd9;">
        <mat-tab style="background-color: #182038;">
            <mat-card-content>
              <form action="" #adminLoginForm="ngForm" (ngSubmit)="onSubmit(adminLoginForm)">
                <mat-form-field class = "inputField" appearance="none">
                  <input
                    class = "inputField"
                    matInput
                    placeholder="{{labels.lblUserName}} (Email)"
                    required
                    name="email"
                    ngModel
                    #email="ngModel"
                    style="padding:10px">
                    <mat-error *ngIf="err == true && email.invalid">{{labels.lblEmailRequired}}</mat-error>
                  <!-- <mat-icon matSuffix>email</mat-icon> -->
                </mat-form-field>
                <mat-form-field class = "inputField" appearance="none">
                  <input
                    class = "inputField"
                    matInput
                    placeholder="{{labels.lblPassword}}"
                    type="password"
                    required
                    name="password"
                    ngModel
                    #password="ngModel"
                    style="padding:10px">
                    <mat-error
                      *ngIf="err == true && password.errors && password.errors.required">
                     {{labels.lblPassRequired}}
                    </mat-error>
                    <!-- <mat-error
                      *ngIf="err == true && password.errors && password.errors.minlength">
                      Password must be of length 6
                    </mat-error> -->
                  <!-- <mat-icon matSuffix>lock</mat-icon> -->
                </mat-form-field>
                <!-- <mat-checkbox>Remember Me!</mat-checkbox> -->
                 <button
                  mat-raised-button
                  class="login-button">
                  {{labels.lblLogIn}}
                </button>
                  <h5 class="forgotPass" (click)="openForgotPassModal()">{{labels.lblForgotPass}}</h5>
                <!-- <button
                  mat-raised-button
                  class="login-button">
                  Login with Google
                </button> -->
              </form>
            </mat-card-content>
        </mat-tab>
         <!-- <mat-tab label="Sign Up">
          <mat-card-content>
            <form action="" #userSignUpForm="ngForm" (ngSubmit)="onSubmit(userSignUpForm)">
              <mat-form-field>
                <input
                  matInput
                  placeholder="User Name"
                  required
                  name="username"
                  ngModel
                  #username="ngModel">
                <mat-icon matSuffix>perm_identity</mat-icon>
                <mat-error
                  *ngIf="username.invalid">
                  Username is required
                </mat-error>
  
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Email"
                  required
                  name="email"
                  ngModel
                  #email="ngModel">
                  <mat-error *ngIf="email.invalid">Email is required</mat-error>
                <mat-icon matSuffix>email</mat-icon>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  placeholder="Password"
                  type="password"
                  required
                  minlength="6"
                  name="password"
                  ngModel
                  #password="ngModel">
                  <mat-error
                    *ngIf="password.errors && password.errors.required">
                    Password is required
                  </mat-error>
                  <mat-error
                    *ngIf="password.errors && password.errors.minlength">
                    Password must be of length 6
                  </mat-error>
                <mat-icon matSuffix>lock</mat-icon>
  
              </mat-form-field>
              <mat-form-field>
                  <input
                  matInput
                  placeholder="Password"
                  type="password"
                  required
                  minlength="6"
                  name="repassword"
                  ngModel
                  #repassword="ngModel">
                  <mat-error
                    *ngIf="repassword.errors && repassword.errors.required">
                    Re-Enter Password is required
                  </mat-error>
                <mat-icon matSuffix>lock</mat-icon>
              </mat-form-field>
              <button
                mat-raised-button
                color="primary"
                class="login-button">
                Sign Up
              </button>
            </form>
          </mat-card-content>
        </mat-tab> -->
      </mat-tab-group>
    </mat-card>
  </div>
  
