import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserLanguageService } from '../services/user-language-service/user-language.service';

@Component({
  selector: 'app-signup-message',
  templateUrl: './signup-message.component.html',
  styleUrls: ['./signup-message.component.scss']
})
export class SignupMessageComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  constructor(public dialogRef: MatDialogRef<SignupMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data,public userLanguageService: UserLanguageService) { }

  ngOnInit(): void {
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
        })
  }

  onNoClick(){
    this.dialogRef.close()
  }

}
