import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DeleteDialogComponent } from 'src/app/modules/admin-user/delete-dialog/delete-dialog.component';
import { UserLanguageService } from 'src/app/modules/services/user-language-service/user-language.service';

@Component({
  selector: 'app-user-training-message',
  templateUrl: './user-training-message.component.html',
  styleUrls: ['./user-training-message.component.scss']
})
export class UserTrainingMessageComponent implements OnInit {ž

  labels;
  labelsSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>, 
    private languageService: UserLanguageService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }


  onNoClick(): void {
    this.dialogRef.close();
   }

}
