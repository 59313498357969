<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblEditUser}} {{ data.firstName }}</h1>
<!-- <div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #editForm="ngForm">
    <div fxLayout="column">
        <input type="hidden" name="id" [ngModel]="data.id">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblUserName}}</mat-label>
            <input matInput [ngModel]="data.userName" name="userName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblFirstName}}</mat-label>
            <input matInput [ngModel]="data.firstName" name="firstName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblLastName}}</mat-label>
            <input matInput [ngModel]="data.lastName" name="lastName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblSelectADate}}</mat-label>
            <input matInput [matDatepicker]="picker" [ngModel]="data.dateOfBirth" name="dateOfBirth">
            <mat-datepicker-toggle matSuffix [for]="picker" style="color:#8293c6"></mat-datepicker-toggle>
            <mat-datepicker #picker style="color:#8293c6"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="none" style="color:#8293c6">
            <mat-label>{{labels.lblSector}}</mat-label>
            <mat-select [ngModel]="data.departmentId" name="departmentId" style="background-color:#182038;color:#8293c6" required>
              <mat-option *ngFor="let department of departments" [value]="department.id" style="background-color:#182038;color:#8293c6">
                {{department.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none">
            <mat-label>{{labels.lblPosition}}</mat-label>
            <mat-select [ngModel]="data.positionId" name="positionId">
              <mat-option *ngFor="let position of positions" [value]="position.id">
                {{position.positionName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>E-mail</mat-label>
            <input matInput [ngModel]="data.email" name="email">
        </mat-form-field>
        <div mat-dialog-actions>
            <button class="btn" style="margin-left: 0px;" cdkFocusInitial type="submit">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div> -->

<mat-horizontal-stepper linear #stepper>
  <form (ngSubmit)="onSubmit()"  #editForm="ngForm">
  <mat-step [stepControl]="firstFormGroup" [editable]="true">
    <ng-template matStepLabel>{{labels.lblBasicInfo}}</ng-template>
    <div>
      <!-- <div class="row header">
        <div class="col-md-6 text-left"><h5 class="tableName">{{labels.lblQuestions}}</h5></div>
      </div> -->
        <div fxLayout="column">
          <input type="hidden" name="id" [ngModel]="data.id">
          <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblFirstName}}</mat-label>
            <input matInput [ngModel]="data.firstName" name="firstName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="50%">
            <mat-label>{{labels.lblLastName}}</mat-label>
            <input matInput [ngModel]="data.lastName" name="lastName" required>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
            <mat-form-field appearance="none" fxFlex="60%">
                <mat-label>{{labels.lblUserName}}</mat-label>
                <input matInput [ngModel]="data.userName" name="userName" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
            <div style="text-align: right">
              <button cdkFocusInitial (click) = "goToNextStep(stepper)" type="button" class="tableBtn" style="float: right; padding: 7px; padding-left: 10px; padding-right: 10px;">Nastavi</button>
          </div>
        </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup" [editable]="false">
    <ng-template matStepLabel>{{labels.lblOtherInfo}}</ng-template>
    <button class="backBtn" (click)="goBack(stepper)">{{labels.lblBack}}</button>
      <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
          <mat-label>E-mail</mat-label>
          <input matInput [ngModel]="data.email" name="email" required>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblSelectADate}}</mat-label>
          <input matInput [matDatepicker]="picker" [ngModel]="data.dateOfBirth" name="dateOfBirth">
          <mat-datepicker-toggle matSuffix [for]="picker" style="color:#8293c6"></mat-datepicker-toggle>
          <mat-datepicker #picker style="color:#8293c6"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="none" style="color:#8293c6">
          <mat-label>{{labels.lblSector}}</mat-label>
          <mat-select [ngModel]="data.departmentId" name="departmentId" style="background-color:#182038;color:#8293c6" required>
            <mat-option *ngFor="let department of departments" [value]="department.id" style="background-color:#182038;color:#8293c6">
              {{department.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblPosition}}</mat-label>
          <input matInput [ngModel]="data.position" name="position">
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none">
          <mat-label>{{labels.lblGenericWorkplace}}</mat-label>
          <mat-select [ngModel]="data.positionId" name="positionId">
            <mat-option *ngFor="let position of positions" [value]="position.id">
              {{position.positionName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-filed appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblDescription}}</mat-label>
          <textarea matInput [ngModel]="data.description" name="description" style="border:1px solid #9dacd9; border-radius: 7px;"></textarea>
          <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error> -->
        </mat-form-filed>
        <mat-form-field appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblPhone}}</mat-label>
          <input matInput [ngModel]="data.phoneNumber" name="phoneNumber">
        </mat-form-field>
        <div>
          <button style="float:right; padding-right: 10px; padding-left: 10px;" mat-raised-button (click) = "onSubmit()" class="tableBtn" cdkFocusInitial type="submit">{{labels.lblEdit}}</button>
        </div>
        </div>
  </mat-step>
</form>
</mat-horizontal-stepper>


