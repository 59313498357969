import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-copy-training',
  templateUrl: './copy-training.component.html',
  styleUrls: ['./copy-training.component.scss']
})
export class CopyTrainingComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  err
  trainings
  @ViewChild('insertForm') insertForm: NgForm
  constructor(private router: Router, public snackBar: MatSnackBar,  private dashboardService: DashboardService, public adminLanguageService: AdminLanguageService, public dialog: MatDialog, public dialogRef: MatDialogRef<CopyTrainingComponent>) { }

  ngOnInit(): void {
    this.err = false;
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.getTrainings()
  }

  getTrainings(){
    this.dashboardService.getTrainings2().subscribe((trainings)=>{
      this.trainings = trainings
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }

  onSubmit() {
    if (this.insertForm.value.name == "" || this.insertForm.value.trainingId == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      let data = {
        name: this.insertForm.value.name,
        trainingId: this.insertForm.value.trainingId
      }
      this.dashboardService.copyTraining(data).subscribe((response) => {
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblTrainingAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        const url = `/admin/training-details/${response}`;
        this.router.navigateByUrl(url);
      })
    }
  }

}
