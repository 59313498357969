import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import { AngularFireStorage } from '@angular/fire/storage'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageDeleteDialogComponent } from './image-delete-dialog/image-delete-dialog.component';
import { UserImageCropperDialogComponent } from '../user-image-cropper-dialog/user-image-cropper-dialog.component';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss']
})
export class EditUserProfileComponent implements OnInit {
  user: User
  userSub: Subscription;
  labels;
  labelsSub: Subscription;
  lang
  langSub: Subscription;
  @ViewChild('editForm') editForm: NgForm;

  constructor(private authService: AuthService,
    private userLanguageService: UserLanguageService,
    private dashboardService: UserDashboardService,
    public snackBar: MatSnackBar, public dialog: MatDialog, private storage: AngularFireStorage, public dialogRef: MatDialogRef<EditUserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router) { }

  ngOnInit(): void {
    this.langSub = this.userLanguageService.lang$.subscribe((lang) => {
      this.lang = lang;
      console.log("LANG: ", this.lang)
    })
    this.getLang();
  }

  getLang() {
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
 
  onSubmit() {
      let firstName = this.data.firstName
      let lastName = this.data.lastName
      let email = this.data.email
      if(this.editForm.value.firstName != ""){
        firstName = this.editForm.value.firstName
      }
      if(this.editForm.value.lastName != ""){
        lastName = this.editForm.value.lastName
      }
      if(this.editForm.value.email != ""){
        email = this.editForm.value.email
      }
      console.log(this.editForm.value.lastName)
      this.dashboardService.editUserInfo({
        flg: "withoutpass",
        id: this.editForm.value.id,
        firstName: firstName,
        lastName: lastName,
        userName: this.editForm.value.userName,
        email: email
      }).subscribe((response) => {
        this.snackBar.open(this.labels.lblDataChanged, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
        console.log(response)
      })
  }

  selectLang(lang) {
    this.userLanguageService.setLanguage(lang);
  }

  logout(): void {
    this.authService.logout();
    this.dialogRef.close();
    this.router.navigateByUrl('/');
  }

}
