import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardService } from '../user-dashboard.service';

@Component({
  selector: 'app-rang-list',
  templateUrl: './rang-list.component.html',
  styleUrls: ['./rang-list.component.scss']
})
export class RangListComponent implements OnInit {
  labels;
  labelsSub: Subscription;

  users: User[]
  constructor(private languageService: UserLanguageService, private userDashboardService: UserDashboardService) { }

  ngOnInit(): void {

    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.fetchUsersByCompany()
  }

  fetchUsersByCompany() {
    this.userDashboardService.fetchUsersByCompany().subscribe(users => {
      let usrs = users as User[]
      //usrs = usrs.sort((a, b) => (a.totalPoints > b.totalPoints ? -1 : 1));;
      this.users = usrs
      console.log("RANG LIST USERS", this.users)
    })
  }

}
