import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { DashboardService } from '../dashboard.service'
import { Observable, Subscription } from 'rxjs'
import { User } from '../../models/user.model'
import { DataSource } from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort';
import { MatDialog, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './insert-dialog/insert-dialog.component';
import { Training } from './../../models/training.model'
import { ActivatedRoute, Router } from '@angular/router'
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component'
import { TrainingSection } from 'src/app/models/training-section.model';
import { Unit } from './../../models/unit.model'
import { AdminTestQuestionsAndAnswersComponent } from './admin-test-questions-and-answers/admin-test-questions-and-answers.component';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { Test } from 'src/app/models/test.model';


@Component({
  selector: 'app-admin-test',
  templateUrl: './admin-test.component.html',
  styleUrls: ['./admin-test.component.scss']
})
export class AdminTestComponent implements OnInit {

  displayedColumns: string[] = ['type','name', 'minPercentage', 'dateCreated','actions'];
  dataSource
  labels;
  labelsSub: Subscription;
  assigned

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  constructor(private dashboardService: DashboardService, public dialog: MatDialog,
    private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getTests()
    this.getLang()
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value;
  }

  getTests() {
    this.dashboardService.fetchTests().subscribe((response) => {
      let tests = response as Test[]
      tests = tests.sort((a, b) => a.name.localeCompare(b.name))
      tests.forEach(t => {
        t.date = t.dateCreated.toLocaleString()
        t.date = t.date.substring(8,10)+"."+t.date.substring(5,7)+"."+t.date.substring(0,4)+"."
      })
      this.dataSource = new MatTableDataSource(tests)
      this.dataSource.paginator = this.paginator;
     
      this.dataSource.sort = this.sort
      this.dataSource.filterPredicate = (data : Test, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
     
    })
  }
  openInsertDialog() {
    const dialogRef = this.dialog.open(InsertDialogComponent, {
      width: '500px'
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.getTests()
    })

  }

  openEditDialog(test) {
    console.log(test)
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '500px',
      height: '500px',
      data: test
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.getTests()
    })
  }
  openDeleteDialog(test) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: test
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.getTests()
    })
  }

  openTestStepper(test) {
    const dialogRef = this.dialog.open(AdminTestQuestionsAndAnswersComponent, {
      width: '650px',
      data: test
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.getTests()
    })
  }
}
