import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { UserXTrainings } from 'src/app/models/userXTrainings.model';
import { EditControlQuestionDialogComponent } from '../../admin-training-section-detail/edit-control-question-dialog/edit-control-question-dialog.component';
import { DashboardService } from '../../dashboard.service';
import { AuthService } from '../../services/auth-service/auth.service';
import { LocalStorageService } from '../../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserBeforeSurveyMessageComponent } from '../../survey/user-before-survey-message/user-before-survey-message.component';
import { UserDashboardService } from '../../user-dashboard.service';
import { UserBeforeTestMessageComponent } from '../../user-training/user-before-test-message/user-before-test-message.component';
import { MessageBeforeSectionTestComponent } from '../message-before-section-test/message-before-section-test.component';

@Component({
  selector: 'app-message-after-section-test',
  templateUrl: './message-after-section-test.component.html',
  styleUrls: ['./message-after-section-test.component.scss']
})
export class MessageAfterSectionTestComponent implements OnInit {
  test: Test
  testSub: Subscription

  labels;
  labelsSub: Subscription;

  lang;
  langSub: Subscription;

  training$: Observable<Training>
  training: Training
  trainingSub: Subscription

  user$: Observable<User>
  user: User
  userSub: Subscription
  section
  test$: Observable<Test>
  status$: Observable<Number>
  status: Number
  statusSub: Subscription
  button1
  color

  userXTraining$: Observable<UserXTrainings>
  userXTrainingSub: Subscription
  userXTraining

  sectionTest

  btnTryAgain: boolean = false
  btnGoToTraining: boolean = true
  btnContinue: boolean = false
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<MessageAfterSectionTestComponent>,
    private userDashboardService: UserDashboardService,
    private authService: AuthService,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: any, private userLanguageService: UserLanguageService, private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private db: AngularFirestore) { }

  ngOnInit(): void {

    console.log("DATA FROM SECTIONTEST: ", this.data)

    this.langSub = this.userLanguageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })
    this.training$ = this.userDashboardService.getTraining().pipe(share());

    this.trainingSub = this.userDashboardService.training$.subscribe((training) => {
      this.training = training;
      console.log("training: ", this.training)
    })

    this.userXTraining$ = this.userDashboardService.getUserXTrainings(this.localStorageService.getItem('trainingId')).pipe(share());
    this.userXTrainingSub = this.userDashboardService.userXTraining$.subscribe((usrXTr: UserXTrainings) => {
        console.log("user x training msg after section test")
        this.userXTraining = usrXTr;
        console.log(this.userXTraining)
    })

   this.getSectionTest()


    // this.dashboardService.getSectionForTest(this.localStorageService.getItem('currentSectionId'), this.localStorageService.getItem('trainingId')).subscribe(response => {
    //   setTimeout(() => {
    //     this.section = response
    //     console.log("section: ")
    //     console.log(this.section)
    //   }, 500);
    // })

    // this.testSub = this.userDashboardService.test$.subscribe((test) => {
    //   setTimeout(() => {
    //     this.test = test;
    //   }, 500);

    // })
    /* setTimeout(() => {
      this.status$ = this.userDashboardService.getTrainingStatus().pipe(share())
      this.statusSub = this.userDashboardService.status$.subscribe((status) => {
        this.status = status;
        console.log("status 1")
        console.log(this.status)
      })
    }, 300); */

    this.userDashboardService.status$.subscribe((status) => {
      this.status = status;
      console.log("status 1")
      console.log(this.status)
    })

    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      console.log("user: ", this.user)
      setTimeout(() => {
        if (this.user.companyId == 1) {
          this.button1 = "buttonEMS"
          this.color = "#c32a2a"

        } else if (this.user.companyId == 2) {
          this.button1 = "buttonECG"
          this.color = "rgb(241,106,100)"
        }
        else {
          this.button1 = "buttonECG"
          this.color = "rgb(241,106,100)"
        }
      }, 200);
    });

    this.userDashboardService.getTestForTraining(this.localStorageService.getItem("testId")).subscribe((test) => {
      setTimeout(() => {
        this.test = test;
        console.log("TEST FROM MESSAGE AFTER SECTON TEST ")
        console.log(this.test)
      }, 500);
    })

    this.userDashboardService.getSurveyForTraining(this.localStorageService.getItem('surveyId')).subscribe((test: Test) => {
      console.log("SURVEY before 1: ")
      this.test = test;
        console.log(this.test)
    });

  }

  goToTestPage() {
    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.localStorageService.setItem('questionScores', [])
    this.localStorageService.setItem('sectionTest', null)
    this.localStorageService.setItem('minutesLeft', this.sectionTest.durationTime)
    this.localStorageService.setItem("testSectionId", this.sectionTest.id)
    this.localStorageService.setItem('secondsLeft', 0)
    this.dialogRef.close()
    // const dialogRef = this.dialog.open(MessageBeforeSectionTestComponent, {
    //   disableClose: true,
    //   width: '350px'
    // });
    // dialogRef.afterClosed().subscribe((response) => {
    //   window.location.reload()
    // })
    this.router.navigateByUrl('/user/start-section-test')
    
  }

  async getSectionTest(){
    let docRef = await this.db.firestore.collection("tests").doc(this.data.section.testId).get()
    this.sectionTest = docRef.data()
    console.log("SECTION TEST DATA: ", this.sectionTest)
    if(this.sectionTest.required){
      if(this.data.passedTest){
        this.btnContinue = true
        this.btnGoToTraining = false
        this.btnTryAgain = false
      }else{
        if(Number(this.data.numOfTestDrops) == Number(this.sectionTest.numberOfTestDrops)){
          this.userDashboardService.updateVideoScoresForSection({trainingId: this.data.section.trainingId, sectionId: this.data.section.id, testId: this.sectionTest.id}).subscribe((response) => {
            console.log("UNIT::: ", response)
            this.localStorageService.setItem('currentUnit', response)
          })
          this.btnGoToTraining = true
          this.btnTryAgain = false
        }else{
          this.btnGoToTraining = false
          this.btnTryAgain = true
        }
      }
    }else{
      this.btnContinue = true
      this.btnGoToTraining = false
      this.btnTryAgain = false
    }
   
  }

  goToHomePage(){
    this.dialogRef.close()
    this.router.navigateByUrl("/user/training")
  }

  goToTraining() {
    console.log("status on click")
    console.log(this.status)
    if (this.status == 3) {
      console.log("status 3 - if")
      this.dialogRef.close()
      if(this.training.testId!= null && this.training.testId!= ""){
        this.openTestDialog();
      }
      else{
        if(this.training.surveyId!= null && this.training.surveyId!= ""){
          //this.test$ = this.userDashboarService.getSurveyForTraining(this.localStorageService.getItem('surveyId')).pipe(share())
          this.router.navigateByUrl('user/survey')
          this.openSurveyDialog()
        }else{
          this.router.navigateByUrl("/user/dashboard")
        }
      }
    } else {
      this.dialogRef.close()
      this.router.navigateByUrl('user/training');
    }
  }

  openSurveyDialog(): void {
    this.router.navigateByUrl('/user/start-survey')
  }

  ngOnDestroy() {
    this.trainingSub.unsubscribe();
    this.section = null
  }

  openTestDialog(): void {
    console.log("Open test dialog")
    // this.test$ = this.userDashboardService.getTestForTraining(this.training.testId).pipe(share())
    console.log("test id:")
    console.log(this.training.testId)
    this.router.navigateByUrl('/user/start-test')
  }

}
