<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<div class="row">
    <form (ngSubmit)="onSubmit()" #insertForm="ngForm" class="qForm" (keydown.enter)="enterClick()" style="height: 230px;">
        <div fxLayout="column">
          <div class="row" style="width: 100%; margin: auto">
            <div class="col-12" style="width: 100%;">
              <mat-form-field appearance="none" style="max-width: 100%; width: 100%;">
                <mat-label>{{labels.lblQuestionText}}</mat-label>
                <textarea matInput ngModel name="textQ" required></textarea>
                <mat-error *ngIf="textErr == true" style="margin-bottom:1.5vh !important;">
                  {{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="width: 100%;">
            <div class="col-11" style="width: 100%; margin: auto">
              <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedImage">
                <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;">
                <div style="margin-bottom: 11px;">
                  <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
                  <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
                </div>
              </mat-form-filed>
              <mat-label *ngIf="selectedImage">
                {{labels.lblImage}}: {{selectedImage.name}}
                <mat-icon style="float:right; cursor: pointer;" (click)="updateImage()">cancel</mat-icon>
              </mat-label>
            </div>
            <div class="col-11" style="width: 100%; margin: auto">
              <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedVideo">
                <input type="file" (change)="onVideoChanged($event)" #videoUpload style="display: none;">
                <div style="margin-bottom: 11px;">
                  <mat-label style="color: #3e4b71;">{{labels.lblUploadVideoHint}} </mat-label>
                  <mat-icon class="icon" (click)="videoUpload.click()">videocam</mat-icon>
                </div>
              </mat-form-filed>
              <mat-label *ngIf="selectedVideo">
                {{labels.lblVideo}}: {{selectedVideo.name}}
                <mat-icon style="float:right; cursor: pointer;" (click)="updateVideo()">cancel</mat-icon>
              </mat-label>
            </div>
          </div>

          <div class="row" style="width: 100%; margin: auto">
            <div class="col-4" *ngIf = "test.type != 3">
              <mat-form-field class="pointsField" appearance="none" style="max-width: 100%; width: 100%;">
                <mat-label>{{labels.lblPoints}}</mat-label>
                <span class='number-wrapper'>
                  <input type="number" min="1" max="10" matInput number ngModel="{{defaultValue}}" name="points" required>
                </span>
                <mat-error *ngIf="textErr == true" style="margin-bottom:1.5vh !important;">
                  {{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-4" style="text-align: right;">
              <mat-form-field appearance="none" style="max-width: 100%; width: 100%;">
                <mat-label>{{labels.lblType}}</mat-label>
                <mat-select ngModel name='type' required>
                  <mat-option value="1" (click)="setTypeOfAnswer(1)">{{labels.lblOneCorrectAns}}</mat-option>
                  <mat-option value="2" (click)="setTypeOfAnswer(2)">{{labels.lblMoreThanOne}}</mat-option>
                  <mat-option value="3" *ngIf = "test.type == 3" (click)="setTypeOfAnswer(3)">{{labels.lblTextAns}}</mat-option>
                  <!-- <mat-option value="3" (click)="setTypeOfAnswer(3)">TEXT ANSWER</mat-option> -->
                </mat-select>
                <mat-error *ngIf="textErr == true" style="margin-bottom:1.5vh !important;">
                  {{labels.lblfieldRequired}}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-4" style="width: 100%; text-align: right;">
              
              <button cdkFocusInitial
                style="margin-top: 15px; float: right; padding: 7px; padding-left: 10px; padding-right: 10px;"
                type="submit" class="tableBtn">{{labels.lblAdd}}</button>
            </div>
            <div class="col-12">
              <mat-progress-bar mode="indeterminate" style="margin-top: 15px;" *ngIf="uploading"></mat-progress-bar>
            </div>
          </div>
    
        </div>
      </form>
    
</div>