<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<div class="row">
    <div class="col-12">
        <form ngForm (ngSubmit)="submitAns()" #insertForm1="ngForm" class="ansForm">
            <ng-template matStepLabel>{{labels.lblAnswers}}</ng-template>
              <div class="row" style="margin: auto; width:100%">
                <div class="col-12">
                  <mat-form-field class="txtarea" appereance="none" style="width: 100%">
                    <mat-label>{{labels.lblEnterAswer}}</mat-label>
                    <input matInput [ngModel]="insertedAns" name="textAns1" (keydown.enter)="enterClick()" required>
                    <mat-error *ngIf="err1 == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 text-right" style="margin: auto; text-align: right">
                  <button type="submit" mat-button class="tableBtn">{{labels.lblAdd}}</button>
                </div>
              </div>
            
          </form>
    </div>
</div>
