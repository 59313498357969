<mat-dialog-container>
  <app-user-spinner *ngIf="labels == null"></app-user-spinner>
<div class = "row" style = "margin-top: 10%; margin-bottom: 10%;">
    <div class = "col-7" style = "margin: auto;">
        <h1 style = "margin: auto; color: white;font-family: Lato, 'Alegreya Sans'; font-size: 20px; font-weight: bold; text-align: center;">{{labels.lblEdit}}</h1>
    </div>
    <div style = "width: 100%; margin: auto;">
        <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="margin: auto; text-align: center;">
            <!-- <input type="hidden" name="id" [ngModel]="data.id">
            <input type="hidden" name="userName" [ngModel]="data.userName">
            <mat-form-field appearance="none" class="col-8 form-field" style = "margin: auto">
            <input type="text" class = "inputField" matInput ngModel name="firstName" placeholder="{{labels.lblFirstName}}">
            </mat-form-field>
            <mat-form-field appearance="none" class="col-8 form-field" style = "margin: auto">
                <input type="text" class = "inputField" matInput ngModel name="lastName" placeholder={{labels.lblLastName}}>
            </mat-form-field>
            <mat-form-field appearance="none" class="col-8 form-field" style = "margin: auto">
                <input type="text" class = "inputField" matInput ngModel name="email" placeholder={{labels.lblEmail}}>
            </mat-form-field> -->
            <mat-form-field appearance="none" class = "col-8"  style = "margin: auto">
              <mat-select [ngModel]="lang" class="matSelect inputField" required>
                <mat-option [value] = "lang" (click)="selectLang('srb')">
                  {{labels.lblSerbian}}
                </mat-option>
                <mat-option [value] = "lang" (click)="selectLang('cirilica')">
                  {{labels.lblSerbianCyrillic}}
                </mat-option>
                <mat-option [value] = "lang" (click)="selectLang('eng')">
                  {{labels.lblEnglish}}
                </mat-option>
              </mat-select>
            </mat-form-field>
              <!-- <div mat-dialog-actions style="margin:auto; text-align: center; margin-top: 3.9%; ">
                <button class="greenBtn" style = "background-color: #799ACF; border: 1px solid #799ACF;" type="button" (click) = "logout()">{{labels.lblLogOut}}</button>
              </div> -->
            <!-- <div mat-dialog-actions style="margin:auto; text-align: center; margin-top: 3.9%; margin-bottom:1vh;">
                <button class="greenBtn" cdkFocusInitial type="submit">{{labels.lblSave}}</button>
            </div> -->
        </form>
    </div>
</div>
</mat-dialog-container>