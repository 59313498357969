<app-user-spinner *ngIf="training == null || user == null || labels == null || trainer == null || comments == null">
</app-user-spinner>
<div class="cards">
    <button class="noOutline d-none d-sm-inline-block" mat-button [class]="commentsButtonClass"
        (click)="whatToDisplay('comments')">{{labels.lblComments}}</button>
    <button class="noOutline d-none d-sm-inline-block" mat-button [class]="documentsButtonClass"
        (click)="whatToDisplay('documents')">{{labels.lblDocuments}}</button>
        
    <div class="row mobileCards">
        <div class="col-xs-12">
            <button class="noOutline" mat-button [class]="overviewButtonClass"
                (click)="whatToDisplay('overview')">{{labels.lblOverview}}</button>
        </div>
        <div class="col-xs-12">
            <button class="noOutline" mat-button [class]="documentsButtonClass"
                (click)="whatToDisplay('documents')">{{labels.lblDocuments}}</button>
        </div>
        <div class="col-xs-12">
            <button class="noOutline" mat-button [class]="instructorButtonClass"
                (click)="whatToDisplay('instructor')">{{labels.lblInstructor}}</button>
        </div>
        <div class="col-xs-12">
            <button class="noOutline" mat-button [class]="commentsButtonClass"
                (click)="whatToDisplay('comments')">{{labels.lblComments}}</button>
        </div>
    </div>

    <div class="overview" [style.display]="displayOverview" [style.background-color] = "backgroundColor">
        <span class="font heading">{{training.name}}</span><br><br>
        <span class="font bio">{{training.description}}</span><br><br>
    </div>
    <div class="documents" [style.display]="displayDocuments">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font1"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                        <mat-icon [class] = "iconColor" style="vertical-align: middle; margin-right: 10px;">people</mat-icon>{{labels.lblAdminDocs}}</span></div>
                </mat-expansion-panel-header>
                <div class="document" [style.background-color] = "docBackground" *ngIf="canDownloadTest && (training.testResults == true)">
                    <div class="documentDiv">
                        <mat-icon style="color:  rgb(58,147,101);"> insert_drive_file</mat-icon>
                        <span class="aligned-with-icon font headerText"> {{labels.lblTestResults}}</span>
                    </div>
                    <button mat-button class="buttonDocument" [style.background-color] = "documentBtn"
                        (click)="downloadTestResults(training.id)">{{labels.lblDownload}}</button>
                </div>
                <div class="document" [style.background-color] = "docBackground" *ngIf="canDownloadSurvey && (training.surveyResults == true)">
                    <div class="documentDiv">
                        <mat-icon style="color:  rgb(58,147,101);"> insert_drive_file</mat-icon>
                        <span class="aligned-with-icon font headerText"> {{labels.lblSurveyResults}} </span>
                    </div>
                    <button mat-button class="buttonDocument" [style.background-color] = "documentBtn"
                        (click)="downloadSurveyResults(training.id)">{{labels.lblDownload}}</button>
                </div>
                <div class="document" [style.background-color] = "docBackground" *ngIf="canDownloadStatement && (training.statement == true)">
                    <div class="documentDiv">
                        <mat-icon style="color:  rgb(58,147,101);"> insert_drive_file</mat-icon>
                        <span class="aligned-with-icon font headerText"> {{labels.lblStatement}} </span>
                    </div>
                    <button mat-button class="buttonDocument" [style.background-color] = "documentBtn"
                        (click)="downloadStatement(training.id)">{{labels.lblDownload}}</button>
                </div>
                <div *ngIf = "(canDownloadStatement == false) && (canDownloadSurvey == false) && (canDownloadTest == false)">
                    <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}} </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font1"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                        <mat-icon [class] = "iconColor" style="vertical-align: middle; margin-right: 10px;">subject</mat-icon>{{labels.lblTrainingDocs}}</span></div>
                </mat-expansion-panel-header>

                <div class="document" [style.background-color] = "docBackground" *ngFor="let document of trainingDocuments">
                    <div class="documentDiv">
                        <mat-icon style="color:  rgb(58,147,101); vertical-align: middle;"> insert_drive_file</mat-icon>
                        <span class="aligned-with-icon font headerText1"> {{document.sectionName}}/{{document.unitName}} </span>
                    </div>
                    <button mat-button class="buttonDocument" [style.background-color] = "documentBtn"><a class="link" target="_blank" href={{document.docPath}}
                            cdkFocusInitial>{{labels.lblDownload}}</a></button>
                </div>
                <div *ngIf = "trainingDocuments.length == 0">
                    <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}} </span>
                </div>

            </mat-expansion-panel>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font1"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                        <mat-icon [class] = "iconColor" style="vertical-align: middle; margin-right: 10px;">note_add</mat-icon>{{labels.lblAdditionalDocs}}</span></div>
                </mat-expansion-panel-header>

                <div>
                    <div class="document" [style.background-color] = "docBackground" *ngFor="let document of userDocuments">
                        <div class="documentDiv">
                            <mat-icon style="color:  rgb(58,147,101);"> insert_drive_file</mat-icon>
                            <span class="aligned-with-icon font headerText"> {{document.name}} </span>
                        </div>
                        <button mat-button class="buttonDocument" [style.background-color] = "documentBtn"><a class="link" target="_blank" href={{document.path}}
                                cdkFocusInitial>{{labels.lblDownload}}</a></button>
                    </div>
                    <div *ngIf = "userDocuments.length == 0">
                        <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}} </span>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        
    </div>
    <div class="instructor" [style.display]="displayInstructor" [style.background-color] = "backgroundColor">
        <div class="instructorContainer" [style.background-color] = "backgroundColor">
            <div style="display:inline-block; background-color: black; width: 120px; height: 130px;" alt = "instructor image">
                <img class="instructorImg" src={{trainer.profileImagePath}} alt="">
            </div>
            <div style="margin-top: 10px; margin-left: 25px;">
                <span class="headerText font">{{trainer.firstName}} {{trainer.lastName}}</span><br>
                <div class=" font" style="margin-top: 10px;">
                    <mat-icon style="color: rgb(242,180,61)"> email</mat-icon>
                    <span class="aligned-with-iconn font">{{trainer.email}}</span>
                </div>
                <!-- <span class="instructorProperty font">
                    <mat-icon style="color:  rgb(220,69,0);">visibility</mat-icon>
                    <span class="aligned-with-icon font"> 1.268 {{labels.lblStudents}}</span>
                </span>
                <span class="instructorProperty font">
                    <mat-icon style="color:  rgb(58,147,101);"> play_circle_outline</mat-icon>
                    <span class="aligned-with-icon font"> 35 {{labels.lblCourses}} </span><br><br>
                </span> -->
                <div class="font bio">{{trainer.about}}</div>
            </div>
        </div>
    </div>
    <div [style.display]="displayComments" style="margin-bottom: 15px;">
        <div class="comments" #scrollMe [scrollTop]="scrollMe.scrollHeight != null ? scrollMe.scrollHeight : 0">
            <div class="comment font" [style.background-color] = "backgroundColor" *ngFor="let c of comments">
                <img class="commentImg" src={{c.imagePath}} alt="comment image">
                <span class="headerText" style=" top:4px">{{c.fullName}}</span>
                <span style="float: right; margin:5px">{{c.time}}</span><br>
                <div style="margin: 10px; top: -11px; font-size: 15.5px;">{{c.text}}</div>
            </div>
        </div>
        <div style="width: 100%; margin: auto;">
            <form (ngSubmit)="submitComment()" #insertForm="ngForm" style="width: 100%; margin: auto">
                <input matInput [ngModel]="user.id" name="userId" hidden>
                <input matInput [ngModel]="user.firstName" name="firstName" hidden>
                <input matInput [ngModel]="user.lastName" name="lastName" hidden>
                <mat-form-field appearance="none" style="width:100%; margin: auto; color: black !important;">
                    <textarea matInput ngModel name="text" placeholder="{{labels.lblComment}}" style="color: black !important; padding-top: 5px !important;
                    padding-left: 10px !important;"></textarea>
                </mat-form-field>
                <div style="width: 100%; text-align: center">
                    <button type="submit" class="commentBtn" [class] = "buttonStyle">{{labels.lblSend}}</button>
                </div>
            </form>
        </div>
    </div>
</div>