<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<h1 mat-dialog-title>{{labels.lblAddTraining}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #insertForm="ngForm" style="height: 435px !important;">
    <div fxLayout="column">
      <mat-form-field appearance="none" fxFlex="60%">
        <mat-label>{{labels.lblETrainingName}}</mat-label>
        <input matInput ngModel name="name" #name="ngModel" required>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>
      <mat-form-field class="matFormField" appearance="none" fxFlex="60%">
        <mat-label>{{labels.lblDescription}}</mat-label>
        <textarea class="txtArea" matInput maxlength="750" rows="5" cols="5" ngModel name="description"
          #description="ngModel" required></textarea>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="none">
        <mat-label>{{labels.lblTrainer}}</mat-label>
        <mat-select ngModel name="trainerId" required>
          <mat-option *ngFor="let trainer of trainers" [value]="trainer.trainerId">
            {{trainer.firstName}} {{trainer.lastName}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="none">
        <mat-label>{{labels.lblPriority}}</mat-label>
        <mat-select ngModel name="priority" required>
          <mat-option [value]="1">
            1
          </mat-option>
          <mat-option [value]="2">
            2
          </mat-option>
          <mat-option [value]="3">
            3
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="none" fxFlex="60%">
        <mat-label>Procenjena duzina obuke (min)</mat-label>
        <input matInput number name="duration" [(ngModel)]="duration" required/>
    </mat-form-field>
      <mat-checkbox 
      appearance="none"
      class="example-margin checkbox"
      (click)="toggleIsTestOnly()">
          <mat-label>Da li je samo test?</mat-label>
      </mat-checkbox>
      <mat-form-filed appearance="none" fxFlex="60%" *ngIf="!selectedImage">
        <input type="file" (change)="onImageChanged($event)" #imageUpload style="display: none;" required>
        <button mat-raised-button class="noBtn" type="button"
          (click)="imageUpload.click()">{{labels.lblUploadImage}}</button>
      </mat-form-filed>
      <mat-label *ngIf="selectedImage">
        {{labels.lblImage}}: {{selectedImage.name}}
        <mat-icon style="float:right; cursor: pointer;" (click)="updateImage()">cancel</mat-icon>
      </mat-label>

      <!-- fotografija za naslovnu stranu -->
      <mat-form-filed appearance="none" style = "margin-top: 15px;" fxFlex="60%" *ngIf="!selectedImage2">
        <input type="file" (change)="onImageChanged2($event)" #imageUpload2 style="display: none;" required>
        <button mat-raised-button class="noBtn" type="button"
          (click)="imageUpload2.click()">{{labels.lblUploadCoverImage}}</button>
      </mat-form-filed>
      <mat-label *ngIf="selectedImage2">
        {{labels.lblImage}}: {{selectedImage2.name}}
        <mat-icon style="float:right; cursor: pointer;" (click)="updateImage2()">cancel</mat-icon>
      </mat-label>

      <div mat-dialog-actions>
        <mat-progress-bar mode="indeterminate" style="margin-top: 15px;" *ngIf="uploading"></mat-progress-bar>
        <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
        <button class="btn" cdkFocusInitial type="submit" style="margin-top: 15px;">{{labels.lblAdd}}</button>
      </div>
    </div>
  </form>
</div>