import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Department } from 'src/app/models/department.model';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-admin-about-sectors',
  templateUrl: './admin-about-sectors.component.html',
  styleUrls: ['./admin-about-sectors.component.scss']
})
export class AdminAboutSectorsComponent implements OnInit {
  id
  labels;
  labelsSub: Subscription;
  department
  constructor(private dashboardService:DashboardService,
    private router:ActivatedRoute,
    public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.id=this.router.snapshot.params['id']
    this.getDepartment()
    this.getLang()
  }

  getDepartment(){
    this.dashboardService.getDepartmentById(this.id).subscribe((dep)=>{
      this.department=dep as Department
      console.log(this.department)
    })
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

}
