<head>
    <link href="https://vjs.zencdn.net/7.8.4/video-js.css" rel="stylesheet" />

    <!-- If you'd like to support IE8 (for Video.js versions prior to v7) -->
    <script src="https://vjs.zencdn.net/ie8/1.1.2/videojs-ie8.min.js"></script>
</head>

<app-header (toggleSideBarForMe)="sideBarToggler()">ovo je header</app-header>

<mat-drawer-container id = "containerId">
    <mat-drawer mode="side" [opened]="sideBarOpened">
        <app-sidebar>
            ovo je sidebar
        </app-sidebar>
    </mat-drawer>
<mat-drawer-content>
    <router-outlet></router-outlet>
</mat-drawer-content>
</mat-drawer-container>
<app-footer>ovo je footer</app-footer>