import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Administrator } from 'src/app/models/administrator.model';
import { Department } from 'src/app/models/department.model';
import { DashboardService } from '../dashboard.service';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-generate-reports',
  templateUrl: './generate-reports.component.html',
  styleUrls: ['./generate-reports.component.scss']
})
export class GenerateReportsComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  lang;
  langSub: Subscription;
  users
  departments
  trainings
  list
  menuVisibility: boolean = false
  checked: boolean = false;
  generator: boolean = false;
  generatorList: number = 0;
  reportType: boolean = true;
  dates: boolean = false;
  generatorCheckbox: boolean = false;
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('insertFormUsers') insertFormUsers: NgForm
  @ViewChild('insertFormSectors') insertFormSectors: NgForm
  usersForReport = new FormControl();
  admin: Administrator
  adminSub: Subscription

  downloadingSub:Subscription
  downloading :boolean = false


  constructor(public snackBar: MatSnackBar, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService, private adminAuthService: AdminAuthService, private db: AngularFirestore) { }


  ngOnInit(): void {
    this.langSub = this.adminLanguageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })
    this.reportType = true;
    this.generator = false;
    this.generatorList = 0;
    this.dates = false;
    this.generatorCheckbox = false;
    this.getLang()
    this.getAdmin()
    //this.getTrainings()
    this.getTrainingsAndEvents()
    this.list = [{ name: "Sektori", selected: false }, { name: "Korisnici", selected: false }]
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  getUsers() {
    this.dashboardService.getUsersNoPending().subscribe((result) => {
      this.users = result
      console.log(this.users)
    })
  }
  getUsers1() {
    this.dashboardService.getUsersInDep(this.admin.departmentId).subscribe((result) => {
      this.users = result
    })
  }

  getTrainings() {
    this.dashboardService.getTrainings().subscribe((result) => {
      this.trainings = result
    })
  }

  getTrainingsAndEvents(){
    this.dashboardService.getTrainingsAndEvents().subscribe((result) => {
      this.trainings = result
      console.log("TRAININGS AND EVENTS: ", this.trainings)
    })
  }

  fetchDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((result) => {
      this.departments = result
    })
  }
  fetchDepartments1() {
    this.dashboardService.fetchAdminsDepartments(this.admin.departmentId).subscribe((response) => {
      this.departments = response
    })
  }

  generateReport() {
    let type = this.insertForm.value.type
   
    if(type == '3'){
      
      this.downloadingSub = this.dashboardService.downloading$.subscribe((downloading) => {
        this.downloading = downloading;
      })
      this.downloading = true
      console.log(this.downloading)
      this.dashboardService.generateExcelReport("trId", type, this.lang, this.admin.headAdmin, this.admin.departmentId)
    }
    else{
      let trainingId = this.insertForm.value.trainingId.id
      let isZoomTraining = this.insertForm.value.trainingId.zoomTraining
      this.dashboardService.generatReportAdmin(trainingId, type, this.lang, this.admin.headAdmin, this.admin.departmentId, isZoomTraining)
    }
    
  }

  generateReportForSectors() {
    console.log('generate report for sectors')
    let type = this.insertFormSectors.value.type
    let trainingId = this.insertFormSectors.value.trainingId.id
    let isZoomTraining = this.insertFormSectors.value.trainingId.zoomTraining
    let fromDate = this.insertFormSectors.value.fromDate
    let toDate = this.insertFormSectors.value.toDate
    let sectorId = this.insertFormSectors.value.depId
    if (fromDate == "") {
      fromDate = null
    }
    if (toDate == "") {
      toDate = null
    }
    let response = this.dashboardService.generateReportForSectors(trainingId, type, fromDate, toDate, sectorId, this.lang, isZoomTraining)
    console.log(response)
  }

  generateReportForUsers() {
    let type = this.insertFormUsers.value.type
    let trainingId = this.insertFormUsers.value.trainingId.id
    let isZoomTraining = this.insertFormUsers.value.trainingId.zoomTraining
    let fromDate = this.insertFormUsers.value.fromDate
    let toDate = this.insertFormUsers.value.toDate
    if (fromDate == "") {
      fromDate = null
    }
    if (toDate == "") {
      toDate = null
    }

    console.log("TR ID: ", trainingId)
    console.log("IS TRAINING ZOOM: ", isZoomTraining)
    this.dashboardService.generateReportForUsers(trainingId, type, fromDate, toDate, this.usersForReport.value, this.lang, isZoomTraining)
  }

  getAdmin() {
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if (admin.headAdmin) {
          this.fetchDepartments();
          this.getUsers()
        }
        else {
          this.fetchDepartments1();
          this.getUsers1()
        }
      }, 100);
    });
  }

  changeMenuVisibility(value) {
    if (value == '1') {
      this.menuVisibility = true
    }
    else if (value == '2') {
      this.menuVisibility = true
    }
    else {
      this.menuVisibility = false
    }

  }
}
