import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  startOfMonth,
  startOfWeek,
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfWeek,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  format
} from 'date-fns';
import { Observable, Subject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { MatDialog } from '@angular/material/dialog';
import { AddEventComponent } from './add-event/add-event.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { share } from 'rxjs/operators';
import { Event } from 'src/app/models/event.model'
import { DeleteEventComponent } from './delete-event/delete-event.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { DashboardService } from '../dashboard.service';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-admin-calendar',
  templateUrl: './admin-calendar.component.html',
  styleUrls: ['./admin-calendar.component.scss']
})
export class AdminCalendarComponent implements OnInit {
  labels
  labelsSub: Subscription
  admin
  adminSub: Subscription
  userId = ""
  
  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  events: CalendarEvent[]
  reload = false

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  constructor(public dialog: MatDialog, private languageService: AdminLanguageService,  public db: AngularFirestore,
    private modal: NgbModal, private authService: AdminAuthService, private dashboardService: DashboardService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.adminSub = this.authService.admin$.subscribe((admin) => {
      this.admin = admin;
      this.events = []
      setTimeout(() => {
        this.userId = this.admin.id
        this.events = this.getEvents(this.admin.id)
        console.log("bre", this.events)
        this.dayClicked({date: new Date(), events: this.events})
      }, 500)
    })
      setTimeout(() => {
        this.reload = true
      }, 1500);
  }

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  getEvents(id){
    let actions: CalendarEventAction[] = [
      {
        label: this.labels.lblEdit + " ",
        a11yLabel: 'Edit',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent('Edited', event);
        },
      },
      {
        label: " " + this.labels.lblDelete,
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent('Deleted', event);
        },
      },
    ];
    
    let events = []
    let usersId = id
    let docRef = this.db.firestore.collection("administrators").doc(usersId).collection("events")
    docRef.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let event = change.doc.data()
        event.start = new Date(change.doc.data().date.seconds * 1000)
        event.actions = actions
        event.color = colors.red
        events.push(event)
      });
    });
    let docRef2 = this.db.firestore.collection("zoomMeetings")
    docRef2.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let event = change.doc.data()
        event.start = new Date(change.doc.data().date.seconds * 1000)
        event.color = colors.blue
        events.push(event)
      });
    });
    console.log("events: ", events)
    return events
  }

  handleEvent(action: string, event): void {
    console.log("Action: ", action)
    console.log("Event", event)
    if(action == "Deleted"){
      this.deleteEvent(event);
    }else if(action == "Edited"){
      this.editEvent(event)
    }
   }
 
   addEvent(): void {
     this.openAddEventDialog()
   }
 
   openAddEventDialog(): void {
     const dialogRef = this.dialog.open(AddEventComponent, {
       disableClose: true,
       width: '400px',
     });
     dialogRef.afterClosed().subscribe((result) => {
         console.log("Dialog closed.")
         this.reload = false
         this.events = this.getEvents(this.userId)
         setTimeout(() => {
           this.reload = true
         }, 1000);
       })
   }
 
   deleteEvent(eventToDelete: CalendarEvent) {
     const dialogRef = this.dialog.open(DeleteEventComponent, {
       disableClose: true,
       width: '400px',
       data: {userId: this.userId, eventId: eventToDelete.id}
     });
     dialogRef.afterClosed().subscribe((result) => {
         console.log("Delete dialog closed.")
         this.reload = false
         this.events = this.getEvents(this.userId)
         setTimeout(() => {
           this.reload = true
         }, 1000);
       })
   }
 
   editEvent(eventToEdit){
     const dialogRef = this.dialog.open(EditEventComponent, {
       disableClose: true,
       width: '400px',
       data: {userId: this.userId, eventId: eventToEdit.id, eventTitle: eventToEdit.title, eventDescription: eventToEdit.description, eventDate: eventToEdit.start}
     });
     dialogRef.afterClosed().subscribe((result) => {
         console.log("Edit dialog closed.")
         this.reload = false
         this.events = this.getEvents(this.userId)
         setTimeout(() => {
           this.reload = true
         }, 1000);
       })
   }
 
   setView(view: CalendarView) {
     this.view = view;
   }
 
   closeOpenMonthViewDay() {
     this.activeDayIsOpen = false;
   }
  

}
