import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../localStorage-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserLanguageService {
  lang$ = new BehaviorSubject(null);
  labels$ = new BehaviorSubject(null);

  constructor(private localStorageService: LocalStorageService) { }

  getLanguage(): Observable<String> {
    this.lang$.pipe(
      switchMap(lang => {
        const language = this.localStorageService.getItem('lang') || "cirilica"
        if (language) {
          return language
        }

        return of(null)
      })
    ).subscribe((err) => console.log(err))

    return this.lang$
  }

  setLanguage(lang) {
    this.localStorageService.setItem('lang', lang);
    this.lang$.next(lang);
    if (this.lang$.value == "eng") {
      this.labels$.next(this.labelsEng);
    }else if(this.lang$.value == "cirilica"){
      this.labels$.next(this.labelsSrb2);
    }
    else {
      this.labels$.next(this.labelsSrb);
    }
  }

  getLabels() {
    this.lang$.next(this.localStorageService.getItem('lang'))
    if (this.lang$.value == "eng") {
      this.labels$.next(this.labelsEng);
    }else if(this.lang$.value == "cirilica"){
      this.labels$.next(this.labelsSrb2);
    }else {
      this.labels$.next(this.labelsSrb);
    }
    this.labels$.pipe(
      switchMap(labels => {
        if (labels) {
          return of(labels)
        }
      })
    ).subscribe((err) => console.log(err))

    return this.labels$
  }

  labelsSrb = {
    //userd-dashboard-content
    lblTraining: "Obuka",
    lblHomeMessageEMS: "Dobrodošli na EMS platformu za elektronsku edukaciju",
    lblHomeMessage: "Dobrodošli na platformu za elektronsku edukaciju",
    lblShowAll: "Vidi sve obuke",
    lblTrainingNotStarted: "Započni",
    lblTrainingStarted: "Nastavi",
    lblTrainingFinished: "Pogledaj",
    //user-profile
    lblMyCourses: "Moji kursevi",
    lblEdit: "Izmeni",
    lblFinished: "završeno",
    lblStarted: "započeta",
    lblStart: "Započni",
    lblNotStarted: "Nije započeto",
    lblDateOfBirth: "datum rođenja",
    lblInfo: "Info",
    lblContinue: "Nastavi",
    lblContinueUnit: "Nastavi",
    lblSelectedImage: "Izabrana slika",
    lblDelPicture: "Obriši profilnu sliku",
    lblDeleteThisUsersProfilePicture: "Da li ste sigurni da želite da obrišete profilnu sliku?",
    lblDelEvent: "Obriši događaj",
    lblDeleteThisEvent: "Da li ste sigurni da želite da obrišete događaj?",
    lblDelete: "Obrisi",
    lblNo: "Ne, hvala",
    lblHelp: "Pomoć",
    lblNoDocuments: "Trenutno nemate dokumenata za preuzimanje.",
    lblDocument: "Dokument",

    //main-header
    lblSerbian: "Srpski",
    lblSerbianCyrillic: "Српски",
    lblEnglish: "English",
    lblLogOut: "Odjavi se",
    lblChangeLang: "Promeni jezik",

    //training-details
    lblDuration: "Trajanje",
    lblOverview: "Pregled",
    lblDocuments: "Dokumenta",
    lblInstructor: "Trener",
    lblComments: "Komentari",
    lblDownload: "Preuzmi",
    lblTestResults: "Rezultati testa",
    lblSurveyResults: "Rezultati ankete",
    lblCertificate: "Sertifikat",
    lblStudents: "studenata",
    lblCourses: "kurseva",
    lblEmail: "E pošta",
    lblPersonResponsible: "Odgovorna osoba",
    lblSections: "Oblasti",
    lblStatus: "Status",
    lblProgress: "Progres",
    lblViewed: "pogledano",
    lblNumOfUnits: "lekcije",
    lblNumOfSections: "oblasti",
    lblduration: "trajanje",

    //login
    lblLanguage: "Jezik",
    lblLogin: "Prijavi se",
    lblPassword: "Lozinka",
    lblForgotPass: "Zaboravljena lozinka",
    lblSingIn: "Uloguj se",
    loginGoogle: "Prijavite se preko Google naloga",
    loginFacebook: "Prijavite se preko Facebook naloga",
    lblDontHaveAccount: "Nemate nalog?",
    lblSignUp: "Registrujte se.",
    lblPassRequired: "Lozinka je obavezna.",
    lblEmailRequired: "Email je obavezan.",
    lblSignUpMessage: "Uspešno ste se registrovali. Sačekajte potvrdu od administratora kako biste mogli da se ulogujete.",

    lblEnterEmail: "Ukoliko želite da resetujete lozinku u polje upišite e-mail adresu",
    lblSend: "Pošalji",
    lblAcceptTerms: "Da biste nastavili morate da prihvatite uslove korišćenja.",

    //edit-profile
    lblUserName: "Korisničko ime",
    lblFirstName: "Ime",
    lblLastName: "Prezime",
    lblNewPassword: "Nova lozinka",
    lblConfirmPassword: "Potvrdi novu lozinku",
    lblSave: "Sačuvaj",

    //test
    lblNext: "Nastavi",
    lblBack: "Nazad",
    lblSubmit: "Potvrdi",
    lblQuestionsCompleted: "završeno",
    lblStartQuiz: "Započni test",
    //before-pre-test modal
    lblTestInFront: "Pred vama je pre-test kojim merimo",
    lblYourKnowledge: "vaše zanje pre obuke.",
    lblTotalOf: " Imate ukupno",
    lblQuestions: "pitanja.",
    lblMaxPoints: "Maksimalan broj bodova je",
    lblGoodLuck: "Srećno!",
    lblTimeIsUp: "Vreme je isteklo!",

    //pre-test modal
    lblYourSuccesRate: "Vaš procenat uspešnosti je",
    lblYouAnswered: "Odgovorili ste tačno na",
    lblOf: "od",
    lblYouHaveWon: "Osvojili ste ukupno",
    lblPoints: "poena",
    lblPointsWithA: "poen (a)",

    //before test modal
    lblTestInFront1: "Pred vama je test za proveru znanja",
    forPassingTheTest: "Za uspešno polaganje finalnog testa",
    forPassingTheSetionTest: "Za uspešno polaganje testa",
    lblMinPoints: " neophodno je da Vaš procenat uspešnosti bude",

    //test modal
    lblYouPassed: "POLOŽILI STE!",
    lblYoudDidntPassed: "NISTE POLOŽILI!",
    lblCongrats: "Čestitamo položili ste test za obuku",
    lblSectionCongrats: "Čestitamo položili ste test za oblast",
    lblUnsuccessfulTestMessage: "Morate ponovo da prođete osnovnu obuku, o čijem datumu ćete biti blagovremeno obavešteni.",
    lblHomePage: "Početna",
    lblTryAgain: "Pokušaj ponovo",

    //before survey modal
    lblSurveyIsContained: "Anketa se sastoji iz",
    lblYourOpinion: "Vaše mišljenje nam je veoma važno",
    lblInOrderToKnow: "kako bi znali na koji način ",
    lblToImprove: "da unapredimo Vaše iskustvo na platformi",

    // survey modal
    lblThankYouforFilling: "Hvala vam što ste odvojili vreme i popunili anketu",
    lblKlikomNaDugme: "Klikom na dugme ANKETA možete preuzeti popunjenu anketu",
    lblThankYou: "HVALA!",
    lblSurvey:"Anketa",
    lblPreTest: "Pretest",
    lblTest: "Test",

    //training
    lblCompleted: "odgledano",
    backBtnPath: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/nazad%20dugme.png?alt=media&token=e6b34c1c-ae19-4628-a605-234942a31646",
    backBtnPath1: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Nazad%20dugme%20latinica.png?alt=media&token=467523e2-b56b-45c4-8d76-2213f4d5dad9",
    playButton: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Srp%20peach%20button.png?alt=media&token=58abea34-271a-467c-81ae-7ea0717cf0dc",

    //training modal
    lblNotification: "Obaveštenje",

    lblModalMessage: "Da bi ste prešli na sledeću lekciju, prethodnu lekciju morate pogledati do kraja.",
    lblModalMessageDocument: "Morate da preuzmete dokument, kako biste nastavili na sledeću lekciju.",

    lblWrongLoginData: "Pogrešno ste uneli korisničko ime ili lozinku.",

    lblSeveralCorrectAnswers: "Ovo pitanje ima više tačnih odgovora.",
    lblQuestionPoints: "Pitanje nosi",
    lblOk: "U redu",
    lblDashboard: "Početna strana",
    lblUserProfile: "Profil",
    lblStatement: "Izjava",

    //messages
    lblMessages: "Poruke",
    lblWriteAMessage: "Napišite poruku...",
    lblYou: "Vi: ",
    lblSectorGroup: "Sektor grupa",

    //notifications
    lblNotifications: "Obaveštenja",
    lblDeleteAll: "Obriši sve",
    lblNoNotifications: "Nemate nijedno obaveštenje",

    //user cards
    lblComment: "Napišite komentar...",

    //welcome
    lblWelcome: "Dobrodošli na elektronsku obuku",
    lblWelcomeTest: "Dobrodošli na test",

    lblDataChanged: "Uspešno ste izmenili podatke.",
    lblUserDocuments: "Korisnička dokumenta",

    lblTermsOfUse: "Uslovi korišćenja",
    lblAccept: "Prihvatam",
    lblSection: "Oblast",

    //Q&A
    lblFAQ: "Najčešće postavljena pitanja",
    q1: "Kako da promenim jezik na platformi?",
    q2: "Šta ako sam zaboravio/la lozinku za pristup platformi?",
    q3: "Kako da pokrenem obuku?",
    q4: "Da li mogu da preskačem videe u okviru treninga?",
    q5: "Da li mogu da se vratim na lekciju koju sam pogledao/la da se podsetim gradiva?",
    q6: "Kako da preuzmem dokumente nakon završene obuke?",
    q7: "Kako da se ulogujem na platformu?",
    a1: "Jezik na platformi se menja klikom na zastavu.",
    a2: "Da biste resetovali lozinku za pristup platformi, potrebno je da unesete Vašu mail adresu, i na mail će Vam biti poslata nova lozinka za pristup platformi.",
    a3: "Obuku pokrećete klikom na dugme „Započni“ u okviru početne ili info stranice obuke.",
    a4: "Niste u mogućnosti da preskaćete videe u toku treninga.",
    a5: "Možete da ponovo da pustite lekciju koju ste pogledali.",
    a6: "Dokumente možete da preuzmete iz profila ili sa info stranice treninga.",
    a7: "Na platformu se logujete uneseći vaše korisničko ime, odnosno email adresu i lozinku koja vam je poslata na mail.",
    pauseButton: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/srp%20peach%20pause.png?alt=media&token=295e702d-6495-45ae-9b4b-669889725787",
    lblOr: "ILI",
    lblEmailVerified: "Uspešno ste verifikovali Vašu e-mail adresu. Redirektujte se na našu stranu: ",
    lblControlQuestion: "Kontrolno pitanje",
    lblAdminDocs: "Admin dokumenti",
    lblTrainingDocs: "Trening dokumenti",
    lblAdditionalDocs: "Dodatni dokumenti",
    emsLogo: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/ems%20logo%20latinica.png?alt=media&token=4beb6204-c9e6-4ff0-acd7-16c9c9d87dd0",
    lblSearch: "Pretraga",
    lblWrongFormat: "Pogrešan format.",
    lblSupportedFormatsImage: "Podržani formati su: jpg, jpeg, png",
    lblSupportedFormatsDocument: "Podržani formati su: docx, pdf i xlsx",
    lblMaxFileSizeImage:"Maksimalna veličina slike je 5MB",
    lblCalendar: "Kalendar",
    lblPreviousM: "Prethodni",
    lblNextM: "Sledeći",
    lblToday: "Danas",
    lblAddEvent: "Dodaj događaj",
    lblEventTitle: "Naslov događaja",
    lblDescription: "Opis",
    lblDate: "Datum",
    lblEditEvent: "Izmeni događaj",
    lblEventEdites: "Uspešno ste izmenili događaj!",
    lblEventDeleted: "Uspešno ste obrisali događaj!",
    lblEventAdded: "Uspešno ste dodali događaj!",
    lblPasswordFieldError: "Nova i potvrdjena lozinka moraju biti iste",
    lblMeetings: "Zoom sastanci",
    lblSkip: "Preskoči test",
    lblUserUnavailable: "Korisnik je zauzet.",
    lblCalling: "zove...",
    lblBackToLogin: "Nazad na prijavu",
    lblRememberMe: "Zapamti me",
    lblRequestAReset: "Zatraži reset",
    lblLearning: "Učenje",
    lblSinergy: "Sinergija",
    lblTechnology: "Tehnologija",
    lblSkip1: "Preskoči",
    lblNext1: "Dalje",
    lblStartSurvey: "Započni anketu",
    lblStartPretest: "Započni pretest",
    lblQuestionCapsLock: "PITANJE",
    lblOfCL: "OD",
    lblRangList: "Rang lista",
    lblTrainings: "Obuke",
    lblChatRoom: "Chat soba",
    lblProfile: "Profil",
    lblFinishedTrainings: "Završene obuke",
    lblEditProfile: "Izmeni profil",
    lblChangePassword: "Promeni lozinku",
    lblAllfieldsError: "Morate popuniti sva polja kako biste nastavili.",
    lblLangChange: "Promena jezika",
    lblTrainingAuthor: "Trener i autor treninga",
    lblTrainingAuthorTest: "Trener i autor testa",
    aboutTraining: "O obuci",
    aboutTest: "O testu",
    lblLoadMore: "Učitaj još",
    lblJoin: "Pridruži se",
    lblFriends: "Prijatelji",
    lblAgo: "pre",
    lblDays: "dana",
    lblMonths: "meseca",
    lblTrainingStatus: "Status obuke",
    lblTestStatus: "Status testa",
    lblTrainingName: "Naziv eTreninga",
    lblTrainingDueDate: "Rok za završetak",
    lblTrainingProgres: "Progres"
  }
  labelsEng = {
    //userd-dashboard-content
    lblTraining: "Training",
    lblHomeMessageEMS: "Welcome to the EMS e-learning platform",
    lblHomeMessage: "Welcome to the e-learning platform",
    lblShowAll: "Show all",
    lblTrainingNotStarted: "Start",
    lblTrainingStarted: "Continue",
    lblTrainingFinished: "View",
    //user-profile
    lblMyCourses: "My courses",
    lblEdit: "Edit",
    lblFinished: "finished",
    lblStarted: "started",
    lblStart: "Start",
    lblNotStarted: "not started",
    lblDateOfBirth: "date of birth",
    lblInfo: "Info",
    lblContinue: "Continue",
    lblContinueUnit: "Continue",
    lblSelectedImage: "Selected image",
    lblDelPicture: "Delete profile picture",
    lblDeleteThisUsersProfilePicture: "Are you sure you want to delete profile picture?",
    lblDelEvent: "Delete event",
    lblDeleteThisEvent: "Are you sure you want to delete this event?",
    lblDelete: "Delete",
    lblNo: "No, thanks",
    lblHelp: "Help",
    lblNoDocuments: "There is no documents to download.",
    lblDocument: "Document",
    //main-header
    lblSerbian: "Srpski",
    lblSerbianCyrillic: "Српски",
    lblEnglish: "English",
    lblLogOut: "Log out",
    lblChangeLang: "Change language",

    //training-details
    lblDuration: "Duration",
    lblOverview: "Overview",
    lblDocuments: "Documents",
    lblInstructor: "Trainer",
    lblComments: "Comments",
    lblDownload: "Download",
    lblTestResults: "Test results",
    lblSurveyResults: "Survey results",
    lblCertificate: "Certificate",
    lblStudents: "students",
    lblCourses: "courses",
    lblEmail: "Email",
    lblPersonResponsible: "Person responsible",
    lblSections: "Sections",
    lblStatus: "Status",
    lblProgress: "Progress",
    lblViewed: "viewed",
    lblNumOfUnits: "units",
    lblNumOfSections: "sections",
    lblduration: "duration",

    //login
    lblLanguage: "Language",
    lblLogin: "Log in",
    lblPassword: "Password",
    lblForgotPass: "Forgot password",
    lblSingIn: "Sign in",
    loginGoogle: "Sign with Google",
    loginFacebook: "Sign with facebook",
    lblDontHaveAccount: "Don't have an account?",
    lblSignUp: "Sign up.",
    lblPassRequired: "Password is required",
    lblEmailRequired: "Email is required.",
    lblSignUpMessage: "You have successfully registered. Wait for confirmation from the administrator so you can log in.",

    lblEnterEmail: "If you want to reset your password, enter your e-mail address in the field",
    lblSend: "Send",
    lblAcceptTerms: "You must accept the terms of use to continue.",
    lblTermsOfUse: "Terms of use",
    lblAccept: "Accept",

    //edit-profile

    lblUserName: "Username",
    lblFirstName: "First Name",
    lblLastName: "Last Name",
    lblNewPassword: "New password",
    lblConfirmPassword: "Confirm new password",
    lblSave: "Save",
    lblPasswordFieldError: "New password and confirm password must be same",

    //test
    lblNext: "Next",
    lblBack: "Back",
    lblSubmit: "Submit",
    lblQuestionsCompleted: "completed",
    lblStartQuiz: "Start quiz",
    //before-pre-test modal
    lblTestInFront: "In front of you is a test for checking",
    lblYourKnowledge: "your knowledge before course",
    lblTotalOf: "There is total of",
    lblQuestions: " questions.",
    lblMaxPoints: "Maximum number of points is",
    lblGoodLuck: "Good Luck!",
    lblTimeIsUp: "Time is up!",

    //pre-test modal
    lblYourSuccesRate: "Your success rate is",
    lblYouAnswered: "You have answered correctly on",
    lblOf: "of",
    lblYouHaveWon: "You have won",
    lblPoints: "points",
    lblPointsWithA: "point (s)",

    //before test modal
    lblTestInFront1: "In front of you is a test for checking your knowledge",
    forPassingTheTest: "In order to pass the test",
    forPassingTheSetionTest: "In order to pass the test",
    lblMinPoints: " your success rate needs to be at least",

    //test modal
    lblYouPassed: "YOU PASSED THE TEST!",
    lblYoudDidntPassed: "YOU DIDN'T PASSED THE TEST!",
    lblCongrats: "Congratulations, you passed the test for course",
    lblSectionCongrats: "Congratulations, you passed the test for section",
    lblUnsuccessfulTestMessage: "Please try again. Without passing the test you can't finish the course.",
    lblHomePage: "Home Page",
    lblTryAgain: "Try again",

    //before survey modal
    lblSurveyIsContained: "Survey is contained of",
    lblYourOpinion: "Your opinion is very important to us",
    lblInOrderToKnow: "in order to know in which way we can",
    lblToImprove: "improve user expirience on the platform",

    // survey modal
    lblThankYouforFilling: "Thank you for making time to fill the survey",
    lblKlikomNaDugme: "By clicking on button SURVEY you can download survey results",
    lblThankYou: "THANK YOU!",
    lblSurvey:"Survey",
    lblPreTest: "Pretest",
    lblTest: "Test",

    //training
    lblCompleted: "completed",
    backBtnPath: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/back%20dugme.png?alt=media&token=ab1d8b9b-7cc2-4318-a1d8-c0d30982ed6e",
    backBtnPath1: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/back%20dugme%20latinica.png?alt=media&token=bf7d3723-f805-421a-b058-592ccb84cb1f",
    playButton: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Engl%20peach%20button.png?alt=media&token=6027664e-7bc7-4dca-81c3-bb7d15445d67",
    lblTrainingName: "eTraining name",


    //training modal
    lblNotification: "Notification",
    lblModalMessage: "To view the next unit, you have to watch current unit to the end.",
    lblModalMessageDocument: "You have to download document first, then you can continue to next lession.",

    lblWrongLoginData: "Wrong username or password",
    lblOk: "Ok",

    lblSeveralCorrectAnswers: "This question has more than one correct answer",
    lblQuestionPoints: "Question for",
    lblDashboard: "Dashboard",
    lblUserProfile: "User profile",
    lblStatement: "Statement",

     //messages
     lblMessages: "Messages",
     lblWriteAMessage: "Write a message...",
     lblYou: "You: ",

     //notifications
     lblNotifications: "Notifications",
     lblDeleteAll: "Clear all",
     lblNoNotifications: "No new notifications",

     //user cards
    lblComment: "Write a comment...",
    lblSection: "Section",
    //welcome
    lblWelcome: "Welcome to the training",
    lblWelcomeTest: "Welcome to the test",

    lblDataChanged: "You have successfully changed the data.",
    lblUserDocuments: "User documents",
    pauseButton: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/engl%20peach%20pause.png?alt=media&token=a9ba29c5-2e10-4bfc-8f91-e85be0c729d9",

    //Q&A
    lblFAQ: "Frequently asked question",
    q1: "How do I change the language on the platform?",
    q2: "What if I forgot my platform access password?",
    q3: "How do I start training?",
    q4: "Can I skip videos during training?",
    q6: "How do I download the documents after the training?",
    a1: "The language on the platform can be changed by clicking on the flag.",
    a2: "To reset the password, you need to enter your e-mail address, and a new password will be sent to you by e-mail.",
    a3: "Start the training by clicking the Start button on the home or info page of the training.",
    a4: "You are not able to skip videos during training.",
    a6: "You can download the documents from the profile or from the training info page.",
    lblOr: "OR",
    lblEmailVerified: "Uspešno ste verifikovali Vašu e-mail adresu. Redirektujte se na našu stranu: ",
    lblControlQuestion: "Control question",
    lblAdminDocs: "Admin documents",
    lblTrainingDocs: "Training documents",
    lblAdditionalDocs: "Additional documents",
    lblSearch: "Search",
    lblWrongFormat: "Wrong format.",
    lblSupportedFormatsImage: "Supported formats are: jpg, jpeg, png",
    lblSupportedFormatsDocument: "Supported formats are: docx, pdf and xslx",
    lblMaxFileSizeImage:"Max image size is 5MB",
    lblCalendar: "Calendar",
    emsLogo: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/ems%20logo%20latinica.png?alt=media&token=4beb6204-c9e6-4ff0-acd7-16c9c9d87dd0",
    lblPreviousM: "Previous",
    lblNextM: "Next",
    lblToday: "Today",
    lblAddEvent: "Add event",
    lblEventTitle: "Event title",
    lblDescription: "Description",
    lblDate: "Date",
    lblEditEvent: "Edit event",
    lblEventEdites: "Event edited successfully!",
    lblEventDeleted: "Event deleted successfully!",
    lblEventAdded: "Event added successfully!",
    lblMeetings: "Zoom meetings",
    lblSkip: "Skip test",
    lblUserUnavailable: "User is unavailable.",
    lblCalling: "calling...",
    lblBackToLogin: "Back to login",
    lblRememberMe: "Remember me",
    lblRequestAReset: "Request a reset",
    lblLearning: "Learning",
    lblSinergy: "Sinergy",
    lblTechnology: "Technology",
    lblSkip1: "Skip",
    lblNext1: "Next",
    lblStartSurvey: "Start survey",
    lblStartPretest: "Start pretest",
    lblQuestionCapsLock: "QUESTION",
    lblOfCL: "OF",
    lblRangList: "Rang list",
    lblTrainings: "Trainings",
    lblChatRoom: "Chat room",
    lblProfile: "Profile",
    lblFinishedTrainings: "Finished trainings",
    lblEditProfile: "Edit profile",
    lblChangePassword: "Change password",
    lblAllfieldsError: "You must fill all fields in order to continue.",
    lblLangChange: "Change language",
    lblTrainingAuthor: "Trainer and training author",
    lblTrainingAuthorTest: "Trainer and test author",
    aboutTraining: "About training",
    aboutTest: "About test",
    lblLoadMore: "Load more",
    lblJoin: "Join",
    lblSectorGroup: "Sector group",
    lblFriends: "Friends",
    lblAgo: "ago",
    lblDays: "days",
    lblMonths: "months",
    lblTrainingStatus: "Training status",
    lblTestStatus: "Test status",
    lblTrainingDueDate: "Due date",
    lblTrainingProgres: "Progress"
  }

  labelsSrb2 = {
    lblTraining: "Обука",
    lblFAQ: "Најчешће постављена питања",
    q1: "Како да променим језик на платформи?",
    q2: "Шта ако сам заборавио/ла лозинку за приступ платформи?",
    q3: "Како да покренем обуку?",
    q4: "Да ли могу да прескачем видее у оквиру тренинга?",
    q5: "Да ли могу да се вратим на лекцију коју сам погледао/ла да се подсетим градива?",
    q6: "Како да преузмем документе након завршене обуке?",
    q7: "Како да се улогујем на платформу?",
    a1: "Језик на платформи се мења кликом на заставу.",
    a2: "Да бисте ресетовали лозинку за приступ платформи, потребно је да унесете Вашу маил адресу, и на маил ће Вам бити послата нова лозинка за приступ платформи.",
    a3: "Обуку покрећете кликом на дугме „Започни“ у оквиру почетне или инфо странице обуке.",
    a4: "Нисте у могућности да прескаћете видее у току тренинга.",
    a5: "Можете да поново да пустите лекцију коју сте погледали.",
    a6: "Документе можете да преузмете из профила или са инфо странице тренинга.",
    a7: "На платформу се логујете унесећи ваше корисничко име, односно емаил адресу и лозинку која вам је послата на маил.",

    lblHomeMessageEMS: "Добродошли на ЕМС платформу за електронску едукацију",
    lblHomeMessage: "Добродошли на платформу за електронску едукацију",
    lblShowAll: "Види све обуке",
    lblTrainingNotStarted: "Започни",
    lblTrainingStarted: "Настави",
    lblTrainingFinished: "Погледај",
    lblMyCourses: "Моји курсеви",
    lblEdit: "Измени",
    lblFinished: "завршено",
    lblStarted: "започетa",
    lblStart: "Започни",
    lblNotStarted: "није започетa",
    lblDateOfBirth: "датум рођења",
    lblInfo: "Инфо",
    lblContinue: "Настави",
    lblContinueUnit: "Настави",
    lblSelectedImage: "Изабрана слика",
    lblDelPicture: "Обриши профилну слику",
    lblDeleteThisUsersProfilePicture: "Да ли сте сигурни да желите да обришете профилну слику?",
    lblDelEvent: "Обриши догађај",
    lblDeleteThisEvent: "Да ли сте сигурни да желите да обришете догађај?",
    lblDelete: "Обриши",
    lblNo: "Не , хвала",
    lblHelp: "Помоћ",
    lblNoDocuments: "Тренутно нема докумената за преузимање.",
    lblSerbian: "Srpski",
    lblSerbianCyrillic: "Српски",
    lblEnglish: "Енглески",
    lblLogOut: "Одјави се",
    lblChangeLang: "Промени језик",
    lblDuration: "Трајање",
    lblOverview: "Преглед",
    lblDocuments: "Документа",
    lblInstructor: "Тренер",
    lblComments: "Коментари",
    lblDownload: "Преузми",
    lblTestResults: "Резултати теста",
    lblSurveyResults: "Резултати анкете",
    lblCertificate: "Сертификат",
    lblStudents: "студената",
    lblCourses: "курсева",
    lblEmail: "Е пошта",
    lblPersonResponsible: "Одговорна особа",
    lblSections: "Области",
    lblStatus: "Статус",
    lblProgress: "Прогрес",
    lblViewed: "погледано",
    lblNumOfUnits: "лекције",
    lblNumOfSections: "области",
    lblduration: "трајање",
    lblLanguage: "Језик",
    lblLogin: "Пријави се",
    lblPassword: "Лозинка",
    lblForgotPass: "Заборављена лозинка",
    lblSingIn: "Улогуј сe",
    loginGoogle: "Пријавите се преко Google налога",
    loginFacebook: "Пријавите се преко Facebook налога",
    lblDontHaveAccount: "Немате налог?",
    lblSignUp: "Региструјте сe.",
    lblPassRequired: "Лозинка је обавезна.",
    lblEmailRequired: "Емаил је обавезан.",
    lblSignUpMessage: "Успешно сте се регистровали. Сачекајте потврду од администратора како бисте могли да се улогујете.",
    lblEnterEmail: "Уколико желите да ресетујете лозинку у поље упишите е-маил адресу",
    lblSend: "Пошаљи",
    lblAcceptTerms: "Да бисте наставили морате да прихватите услове коришћења.",
    lblUserName: "Корисничко име",
    lblFirstName: "Име",
    lblLastName: "Презиме",
    lblNewPassword: "Нова лозинка",
    lblConfirmPassword: "Потврди нову лозинку",
    lblSave: "Сачувај",
    lblNext: "Настави",
    lblBack: "Назад",
    lblSubmit: "Потврди",
    lblQuestionsCompleted: "завршено",
     lblTestInFront: "Пред вама је пре-тест којим меримо",
    lblYourKnowledge: "ваше зање пре обуке.",
    lblTotalOf: " Имате укупно",
    lblQuestions: "питања.",
    lblMaxPoints: "Максималан број бодова је",
    lblGoodLuck: "Срећно!",
    lblTimeIsUp: "Време је истекло!",
    lblYourSuccesRate: "Ваш проценат успешности je",
    lblYouAnswered: "Одговорили сте тачно на",
    lblOf: "од",
    lblYouHaveWon: "Освојили сте укупно",
    lblPoints: "поена",
    lblPointsWithA: "поена (а)",
    lblTestInFront1: "Пред вама је тест за проверу знања",
    forPassingTheTest: "За успешно полагање финалног теста",
    forPassingTheSetionTest: "За успешно полагање теста",
    lblMinPoints: " неопходно је да Ваш проценат успешности буде",
    lblYouPassed: "ПОЛОЖИЛИ СТЕ!",
    lblYoudDidntPassed: "НИСТЕ ПОЛОЖИЛИ!",
    lblCongrats: "Честитамо положили сте тест за обуку",
    lblSectionCongrats: "Честитамо положили сте тест за област",
    lblUnsuccessfulTestMessage: "Морате поново да прођете основну обуку, o чијем датуму ћете бити благовремено обавештени.",
    lblHomePage: "Почетна",
    lblTryAgain: "Покушај поново",
    lblSurveyIsContained: "Анкета се састоји из",
    lblYourOpinion: "Ваше мишљење нам је веома важно",
    lblInOrderToKnow: "како би знали на који начин ",
    lblToImprove: "да унапредимо Ваше искуство на платформи",
    lblThankYouforFilling: "Хвала вам што сте одвојили време и попунили анкету",
    lblKlikomNaDugme: "Кликом на дугме АНКЕТА можете преузети попуњену анкету",
    lblThankYou: "ХВАЛА!",
    lblSurvey:"Анкета",
    lblPreTest: "Претест",
    lblTest: "Тест",
    lblCompleted: "одгледано",
    backBtnPath: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Nazad%20dugme%20cirilica.png?alt=media&token=c1d894f3-f916-4edc-a683-fc81a575b971",
    backBtnPath1: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Nazad%20dugme%20cirilica.png?alt=media&token=60b7a91b-1527-4a55-b797-030c4d9284ee",
    playButton: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/cirilica%20peach%20bez%20bg.png?alt=media&token=294b2d71-5100-4603-b5f2-0d97fa5cf3a4",
    lblNotification: "Обавештење",
    lblModalMessage: "Да би сте прешли на следећу лекцију морате погледати тренутну до краја.",
    lblModalMessageDocument: "Морате да преузмете документ, како бисте наставили на следећу лекцију.",
    lblWrongLoginData: "Погрешно сте унели корисничко име или лозинку.",
    lblSeveralCorrectAnswers: "Ово питање има више тачних одговора.",
    lblQuestionPoints: "Питање носи",
    lblOk: "У реду",
    lblDashboard: "Почетна страна",
    lblUserProfile: "Профил",
    lblStatement: "Изјава",
    lblMessages: "Поруке",
    lblWriteAMessage: "Напишите поруку...",
    lblYou: "Ви: ",
    lblNotifications: "Обавештења",
    lblDeleteAll: "Обриши све",
    lblNoNotifications: "Немате ниједно обавештење",
    lblComment: "Напишите коментар...",
    lblWelcome: "Добродошли на електронску обуку",
    lblWelcomeTest: "Добродошли на тест",
    lblDataChanged: "Успешно сте изменили податке.",
    lblUserDocuments: "Корисничка документа",
    lblTermsOfUse: "Услови коришћења",
    lblAccept: "Прихватам",
    lblSection: "Област",
    pauseButton: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/srp%20peach%20pause.png?alt=media&token=295e702d-6495-45ae-9b4b-669889725787",
    lblOr: "ИЛИ",
    lblControlQuestion: "Kontrolno pitanje",
    lblDocument: "Документ",
    lblAdminDocs: "Админ документи",
    lblTrainingDocs: "Тренинг документи",
    lblAdditionalDocs: "Додатни документи",
    lblSearch: "Претрага",
    lblWrongFormat: "Погрешан формат.",
    lblSupportedFormatsImage: "Подржани формати су: jpg, jpeg, png",
    lblSupportedFormatsDocument: "Подржани формати су: docx, pdf и xslx",
    lblMaxFileSizeImage:"Максимална целичина слике je 5MB",
    lblCalendar: "Календар",
    emsLogo: "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/ems%20logo%20cirilica.png?alt=media&token=356e2280-14d9-47d9-a926-9fbf6e230985",
    lblPreviousM: "Претходни",
    lblNextM: "Следећи",
    lblToday: "Данас",
    lblAddEvent: "Додај догађај",
    lblEventTitle: "Наслов догађаја",
    lblDescription: "Опис",
    lblDate: "Датум",
    lblEditEvent: "Измени догађај",
    lblEventEdites: "Успешно сте изменили догађај!",
    lblEventDeleted: "Успешно сте обрисали догађај!",
    lblEventAdded: "Успешно сте додали догађај!",
    lblPasswordFieldError: "Нова и потврђена лозинка морају бити исте",
    lblMeetings: "Zoom sастанци",
    lblTrainingName: "Назив еТренинга",
    lblSkip: "Прескочи тест",
    lblUserUnavailable: "Корисник је заузет",
    lblCalling: "зове...",
    lblBackToLogin: "Назад на пријаву",
    lblRememberMe: "Запамти ме",
    lblRequestAReset: "Затражи ресет",
    lblLearning: "Учење",
    lblSinergy: "Синергија",
    lblTechnology: "Технологија",
    lblSkip1: "Прескочи",
    lblNext1: "Даље",
    lblStartQuiz: "Започни квиз",
    lblStartSurvey: "Започни анкету",
    lblStartPretest: "Започни претест",
    lblQuestionCapsLock: "ПИТАЊЕ",
    lblOfCL: "ОД",
    lblRangList: "Ранг листа",
    lblTrainings: "Обуке",
    lblChatRoom: "Чет соба",
    lblProfile: "Профил",
    lblFinishedTrainings: "Завршене обуке",
    lblEditProfile: "Измени профил",
    lblChangePassword: "Промени лозинку",
    lblAllfieldsError: "Морате попунити сва поља како бисте наставили.",
    lblLangChange: "Промена језика",
    lblTrainingAuthor: "Тренер и аутор тренинга",
    lblTrainingAuthorTest: "Тренер и аутор теста",
    aboutTraining: "О обуци",
    aboutTest: "О тесту",
    lblLoadMore: "Учитај још",
    lblJoin: "Придружи се",
    lblSectorGroup: "Сектор група",
    lblFriends: "Пријатељи",
    lblAgo: "пре",
    lblDays: "дана",
    lblMonths: "месеца",
    lblTrainingStatus: "Статус обуке:",
    lblTestStatus: "Статус теста",
    lblTrainingDueDate: "Рок за завршетак:",
    lblTrainingProgres: "Прогрес:"
  }
}
