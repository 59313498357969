import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-assign-documents',
  templateUrl: './assign-documents.component.html',
  styleUrls: ['./assign-documents.component.scss']
})
export class AssignDocumentsComponent implements OnInit {
  lang: string
  langSub: Subscription;
  labels;
  labelsSub: Subscription;
  documents: string[] = []

  constructor(private authService: AdminAuthService, public dialogRef: MatDialogRef<AssignDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getLang();
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onSubmit() {
    let changes = {
      id: this.data.id,
      testResults: this.data.testResults,
      surveyResults:  this.data.surveyResults,
      statement: this.data.statement,
      genericWorkPlace: this.data.genericWorkPlace
    }
    this.dashboardService.updateTraining(changes).subscribe((response) => {
      console.log(response)
      this.dialogRef.close()
    })
  }

  selectValue(e: any, value) {
    if (e.checked) {
      switch (value) {
        case '1':
          this.data.testResults = true
          break;
        case '2':
          this.data.surveyResults = true
          break;
        case '3':
          this.data.statement = true
          break;
        case '4':
          this.data.genericWorkPlace = true
          break;

        default:
          break;
      }
    } else {
      switch (value) {
        case '1':
          this.data.testResults = false
          break;
        case '2':
          this.data.surveyResults = false
          break;
        case '3':
          this.data.statement = false
          break;
        case '4':
          this.data.genericWorkPlace = false
          break;

        default:
          break;
      }
    }
    console.log(this.data)
  }

  onNoClick() {
    this.dialogRef.close()
  }

}
