import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import { AssignTestComponent } from './assign-test/assign-test.component';
import { EditDialogComponent } from '../admin-test/edit-dialog/edit-dialog.component';
import { TestQuestionsDialogComponent } from './test-questions-dialog/test-questions-dialog.component';
import { TestQuestion } from 'src/app/models/testQuestion.model';
import { DeleteTestQuestionComponent } from '../admin-test/admin-test-questions-and-answers/delete-test-question/delete-test-question.component';
import { AddQuestionDialogComponent } from './add-question-dialog/add-question-dialog.component';
import { AdminTestQuestionsAndAnswersComponent } from '../admin-test/admin-test-questions-and-answers/admin-test-questions-and-answers.component'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TrainingSection } from 'src/app/models/training-section.model';
import { DeleteSectionDialogComponent } from './delete-section-dialog/delete-section-dialog.component'

@Component({
  selector: 'app-admin-about-test',
  templateUrl: './admin-about-test.component.html',
  styleUrls: ['./admin-about-test.component.scss']
})
export class AdminAboutTestComponent implements OnInit {
  labels;
  labelsSub: Subscription
  id
  test: Test
  test$: Observable<Test>
  testSub: Subscription
  displayedColumns: string[] = ['name', 'description', 'actions'];
  displayedColumns1: string[] = ['trainingName', 'name', 'actions'];
  dataSource;
  dataSource1;
  assigned
  trainers
  trainer
  sections: TrainingSection[]
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private dashboardService: DashboardService,
    private router: ActivatedRoute,
    public adminLanguageService: AdminLanguageService,
    private userDashboarService: UserDashboardService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.id = this.router.snapshot.params['id']
    this.getLang()
    this.isTestAssigned()
    this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.id).pipe(share())
    this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
      this.test = test;
      setTimeout(() => {
        this.getTrainings()
        this.getSections()
        this.getTrainers()
      }, 200);
    });

  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  public doFilter1 = (value: string) => {
    this.dataSource1.filter = value
  }

  getTrainers() {
    this.dashboardService.getTrainers().subscribe((trainers) => {
      this.trainers = trainers
      this.trainers.forEach(t => {
        if (t.id == this.test.trainerId) {
          this.trainer = "" + t.firstName + " " + t.lastName + ""
        }
      });
    })
  }

  ngOnDestroy() {
    this.dataSource = null;
    this.test.name = null;
    this.test.type = null;
    this.test.questions = [];
    this.test.minPercentage = null;
    this.test = null
    this.testSub.unsubscribe()
    this.test$ = null
    console.log(this.test)
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  getTrainings() {
    this.dashboardService.getTrainingsForTest(this.id, this.test.type).subscribe((trainings) => {
      let trngs = trainings as Training[]
      this.dataSource = new MatTableDataSource(trngs)
      this.dataSource.sort = this.sort.toArray()[0]
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.dataSource.filterPredicate = (data: Training, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
    })
  }
  getSections() {
    this.dashboardService.getSectionsForTest(this.id).subscribe((sections) => {
      this.sections = sections as TrainingSection[]
      console.log(this.sections)
      let sects = sections as TrainingSection[]
      this.dataSource1 = new MatTableDataSource(sects)
      this.dataSource1.sort = this.sort.toArray()[1]
      this.dataSource1.paginator = this.paginator.toArray()[1]
      this.dataSource1.filterPredicate = (data: TrainingSection, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
    })
  }

  openDeleteDialog(training) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: { training: training, test: this.test }
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getTrainings()
    })
  }

  openDeleteSectionDialog(section) {
    const dialogRef = this.dialog.open(DeleteSectionDialogComponent, {
      width: "500px",
      data: section
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource1 = null
      this.getSections()
    })
  }

  isTestAssigned() {
    this.dashboardService.isTestAssigned(this.id).subscribe((response) => {
      console.log(response)
      if (response == null) {
        this.assigned = []
      } else {
        this.assigned = response
      }
      console.log(this.assigned)
    })
  }

  assignedLength() {
    if (this.assigned.length > 0) {
      return true
    } else {
      return false
    }
  }

  openTrainingsTestDialog(test) {
    const dialogRef = this.dialog.open(AssignTestComponent, {
      width: '300px',
      data: test
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getTrainings()
    })
  }

  openEditTestDialog(test) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '500px',
      data: test
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.test = null
      this.dataSource = null
      this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.id).pipe(share())
      this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
        this.test = test;
        setTimeout(() => {
          this.getTrainings()
          this.getTrainers()
        }, 200);
      });
    })

  }

  openTestStepper(testQuestion: TestQuestion) {
    const dialogRef = this.dialog.open(TestQuestionsDialogComponent, {
      width: '650px',
      data: testQuestion
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.test = null
      this.dataSource = null
      this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.id).pipe(share())
      this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
        this.test = test;
        setTimeout(() => {
          this.getTrainings()
          this.getTrainers()
        }, 200);
      });
    })
  }

  openDeleteQuestionDialog(question) {
    const dialogRef = this.dialog.open(DeleteTestQuestionComponent, {
      width: '500px',
      data: question
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.test = null
      this.dataSource = null
      this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.id).pipe(share())
      this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
        this.test = test;
        setTimeout(() => {
          this.getTrainings()
          this.getTrainers()
        }, 200);
      });
    })
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  openInserQuestionDialog(test) {
    const dialogRef = this.dialog.open(AdminTestQuestionsAndAnswersComponent, {
      width: '650px',
      data: test
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.test = null
      this.dataSource = null
      this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.id).pipe(share())
      this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
        this.test = test;
        setTimeout(() => {
          this.getTrainings()
          this.getTrainers()
        }, 200);
      });
    })
  }




}
