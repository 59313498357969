<mat-dialog-container>
    <div class = "row" style = "margin-top: 10%; margin-bottom: 10%;">
        <div class = "col-7" style = "margin: auto;">
            <h1 style = "margin: auto; color: white;font-family: Lato, 'Alegreya Sans'; font-size: 20px; font-weight: bold; text-align: center;">{{labels.lblChangePassword}}</h1>
        </div>
        <div style = "margin: auto;">
            <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="margin: auto; text-align: center;">
                <input type="hidden" name="id" [ngModel]="data.id">
                <input type="hidden" name="firstName" [ngModel]="data.firstName">
                <input type="hidden" name="lastName" [ngModel]="data.lastName">
                <input type="hidden" name="userName" [ngModel]="data.userName">
                <input type="hidden" name="email" [ngModel]="data.email">
                <mat-form-field appearance="none" class="col-8 form-field" style = "margin: auto">
                <input type="password" class = "inputField" matInput ngModel name="currentPassword" placeholder="Trenutna lozinka">
                </mat-form-field>
                <mat-form-field appearance="none" class="col-8 form-field" style = "margin: auto">
                    <input type="password" class = "inputField" matInput ngModel name="newPassword" placeholder={{labels.lblNewPassword}}>
                </mat-form-field>
                <mat-form-field appearance="none" class="col-8 form-field" style = "margin: auto">
                    <input type="password" class = "inputField" matInput ngModel name="confirmPassword" placeholder={{labels.lblConfirmPassword}}>
                </mat-form-field>
                <div mat-dialog-actions style="margin:auto; text-align: center; margin-top: 3.9%; margin-bottom:1vh;">
                    <button class="greenBtn" cdkFocusInitial type="submit">{{labels.lblSave}}</button>
                </div>
            </form>
        </div>
    </div>
</mat-dialog-container>