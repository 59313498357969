<app-user-spinner *ngIf="events == null"></app-user-spinner>
<div class="row" style = "width: 65%; margin: auto">
  <div class="col-12 text-center calendarHeader">
    <div class="row">
      <div class="col-sm-10" style = "margin: auto; margin-top: 3%; margin-bottom: 3%">
        <span mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
          <mat-icon>arrow_back_ios</mat-icon>
        </span>
        <span class="month">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}</span>
        <span  mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
          <mat-icon>arrow_forward_ios</mat-icon>
        </span>
      </div>
    </div>
  </div>
  <br />
  <div [ngSwitch]="view" class="col-12 calendar" *ngIf  = "reload != false">
    <mwl-calendar-month-view
      [viewDate]="viewDate"
      [events]="events"
      [locale]="locale"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      [weekStartsOn]="1"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
    >
    </mwl-calendar-month-view>
  </div>
    
</div>
  <br /><br /><br />
  
  <div class = "row" style = "width: 65%; margin: auto">
    <div class = "col-10 col-sm-5" style = "margin: auto; margin-top: 25px;" *ngFor = "let event of events">
      <div class = "row">
        <div class = "col-2" [style.background] = "event.zoomTraining ? '#799ACF' : '#49D14F'" style = "border-radius: 7px;">
          <div class = "littleDateTime">
            {{event.date | date: "MMM": '' : locale}}
          </div>
          <div class = "littleDateTime">
            {{event.date | date: "d"}}
          </div>
        </div>
        <div class = "col-10 dateTime" style = "padding-top: 10px;">
          {{event.date |  date:"fullDate": '' : locale}}
        </div>
      </div>
      <div class = "row">
        <div class = "col-2">

        </div>
        <div class = "col-10 eventsList">
          <span style = "padding: 5px;">
            <img src = {{event.imagePath}} style = "width: 45px; height: 45px; border-radius: 11px;" alt = "calendar image">
          </span>
         <span style = "vertical-align: middle; padding: 5px; font-weight: bold; color: white;">
          {{event.name}}
         </span>
        </div>
      </div>
    </div>
  </div>
  <!-- <h3>
    Edit events
    <button class="btn btn-primary float-right" (click)="addEvent()">
      Add new
    </button>
    <div class="clearfix"></div>
  </h3>
  
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Title</th>
          <th>Starts at</th>
          <th>Remove</th>
        </tr>
      </thead>
  
      <tbody>
        <tr *ngFor="let event of events">
          <td>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="event.title"
              (keyup)="refresh.next()"
            />
          </td>
          <td>
            <input
              class="form-control"
              type="text"
              mwlFlatpickr
              [(ngModel)]="event.start"
              (ngModelChange)="refresh.next()"
              [altInput]="true"
              [convertModelValue]="true"
              [enableTime]="true"
              dateFormat="Y-m-dTH:i"
              altFormat="F j, Y H:i"
              placeholder="Not set"
            />
          </td>
          <td>
            <button class="btn btn-danger" (click)="deleteEvent(event)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Event action occurred</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Action:
        <pre>{{ modalData?.action }}</pre>
      </div>
      <div>
        Event:
        <pre>{{ modalData?.event | json }}</pre>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        OK
      </button>
    </div>
  </ng-template> -->