import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { DashboardService } from './../../dashboard.service'
import { User } from './../../../models/user.model'
import { Subscription } from 'rxjs';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { Training } from 'src/app/models/training.model';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  user: User,
  training: Training
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  userData: User[]

  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService) { }


  onNoClick(): void {
    this.dialogRef.close();
  }
  deleteUser() {
    console.log(this.data.user)
    console.log(this.data.training)
    this.dashboardService.deleteUserXtrainings(this.data.user.id, this.data.training.id).subscribe((response)=>{
      console.log(response)
    })
    this.snackBar.open(this.labels.lblUserDeleted, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.getLang();
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

}
