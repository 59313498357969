<div id="chart">
  <div class="menuBtn" style="text-align: center;">
      <span class="h3" *ngIf = "name == false">{{labels.lblTrainingsStatus}}</span>
      <span class="h3" *ngIf = "name == true">Status "{{trainingName}}"</span>
    <button mat-button [matMenuTriggerFor]="menu" style="float: right;">
      <mat-icon style="color: #9dacd9 !important;">menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="menu" style="padding-left: 5px;">
      <div style="color: #9dacd9; font-family: 'Prompt', sans-serif !important; padding-left: 5px;">
        <input ngModel type="radio" name="duration-time" [checked]="true" style="padding-left: 5px;"
          class="example-margin checkbox" style="color: #9dacd9; font-family: 'Prompt', sans-serif;"
          (change)="getAdmin()">
        {{labels.lblAllTrainings}}
      </div>
      <!-- <button mat-menu-item style="color: #9dacd9; " (click) = "getAdmin()">
              {{labels.lblAllTrainings}}
            </button> -->
      <div *ngFor="let training of trainings"
        style="color: #9dacd9; font-family: 'Prompt', sans-serif !important; padding-left: 5px;">
        <input ngModel type="radio" name="duration-time" [value]="training" class="example-margin checkbox"
          style="padding-left: 5px; margin-top: 10px;" (change)="checkTraining($event, training.id, training.name)">
        {{training.name}}
      </div>

      <!-- <div *ngFor = "let t of trainings">
            <button mat-menu-item style="color: #9dacd9; " (click) = "fetchThisTrainingStatus(t.id, t.name)">
              {{t.name}}
            </button>
          </div> -->
    </mat-menu>
  </div>
  <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [labels]="chartOptions.labels"
    [fill]="chartOptions.fill" [dataLabels]="chartOptions.dataLabels" [responsive]="chartOptions.responsive">
  </apx-chart>
</div>