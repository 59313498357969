<div>
  <div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important; margin-bottom: 10px;"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "cancelModal()">cancel</mat-icon></div></div>
    <mat-card-content>
      <h4 style="font-family: 'Prompt', sans-serif !important; ;">{{labels.lblEnterEmail}}</h4>
        <form action="" #forgotPassForm="ngForm" (ngSubmit)="onSubmit(forgotPassForm)">
          <mat-form-field class = "inputField" appearance="none">
            <input
              class = "inputField"
              matInput
              placeholder="Email"
              required
              name="email"
              ngModel
              #email="ngModel"
              style="padding:10px">
          </mat-form-field> <br>
           <button type="submit"
            class="tableBtn">
            {{labels.lblSend}}</button>
        </form>
      </mat-card-content>
</div>
