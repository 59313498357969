import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Department } from 'src/app/models/department.model';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component'
import { UserTrainingsDialogComponent } from './user-trainings-dialog/user-trainings-dialog.component'
import { EditDialogComponent as EditSectionDialogComponent } from '../admin-training-detail/edit-dialog/edit-dialog.component'
import { InsertDialogComponent as InsertSectionDialog } from '../admin-training-detail/insert-dialog/insert-dialog.component'
import { DeleteDialogComponent as DeleteSectionDialogComponent } from '../admin-training-detail/delete-dialog/delete-dialog.component'
import { EditDialogComponent as EditUnitDialogComponent } from '../admin-training-section-detail/edit-dialog/edit-dialog.component'
import { InsertDialogComponent as InsertUnitDialog } from '../admin-training-section-detail/insert-dialog/insert-dialog.component'
import { DeleteDialogComponent as DeleteUnitDialogComponent } from '../admin-training-section-detail/delete-dialog/delete-dialog.component'
import { EditDialogComponent as EditTrainingDialogComponent } from '../admin-training/edit-dialog/edit-dialog.component'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AssignTestComponent } from '../admin-training/assign-test/assign-test.component';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';
import { AssignDocumentsComponent } from './assign-documents/assign-documents.component';

@Component({
  selector: 'app-admin-about-training',
  templateUrl: './admin-about-training.component.html',
  styleUrls: ['./admin-about-training.component.scss']
})
export class AdminAboutTrainingComponent implements OnInit {
  displayedColumns: string[] = ['userName', 'firstName', 'lastName', "dueDate", 'departmentName', 'actions'];
  id: string
  labels;
  labelsSub: Subscription;

  training: Training
  trainingSub: Subscription
  training$
  dataSource
  departments: Department[]
  assigned
  admin
  adminSub: Subscription
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  constructor(private adminAuthService: AdminAuthService, private dashboardService: DashboardService,
    private router: ActivatedRoute,
    public adminLanguageService: AdminLanguageService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
    });
    this.id = this.router.snapshot.params['id']
    this.isTrainingAssigned()
    this.getTraining(this.id)
    this.getLang()
    this.getAdmin()
  }

  ngOnDestroy() {
    this.dataSource = null;
    this.training.name = null;
    this.training = null
    this.trainingSub.unsubscribe()
    console.log(this.training)
  }

  setToNull() {
    this.training.name = null;
    this.training.description = null;
    this.training.pretestName = null;
    this.training.testName = null;
    this.training.surveyName = null;
    this.training.sections = null;
    this.training.trainerName = null
    this.training = null
    this.trainingSub.unsubscribe()
    console.log(this.training)
  }
  // setToNull(){
  //   this.training.sections = null;
  // }

  getTraining(id) {
    this.training$ = this.dashboardService.getTrainingWithSections(id).pipe(share())
    this.trainingSub = this.dashboardService.training$.subscribe((training: Training) => {
      this.training = training;
      console.log(this.training)
      setTimeout(() => {
        this.getTrainer(training.trainerId)
        this.getSurvey(training.surveyId)
        this.getTest(training.testId)
        this.getPretest(training.pretestId)
      }, 400);


    });
  }

  isTrainingAssigned() {
    this.dashboardService.isTrainingAssigned(this.id).subscribe((response) => {
      if (response == null) {
        this.assigned = []
      } else {
        this.assigned = response
      }
    })
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  getUsers() {
    this.dashboardService.getUsersForTrainings(this.id).subscribe((users) => {
      let usrs = users as User[]
      usrs = usrs.sort((a, b) => a.userName.localeCompare(b.userName))
      this.dataSource = usrs
      this.getDepartments();
    })
  }

  getUsers1() {
    this.dashboardService.getAdminsUsersForTrainings(this.admin.departmentId, this.id).subscribe((users) => {
      let usrs = users as User[]
      usrs = usrs.sort((a, b) => a.userName.localeCompare(b.userName))
      this.dataSource = usrs
      this.getDepartments();
    })
  }

  getDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
      this.dataSource.forEach(user => {
        this.departments.forEach(dep => {
          if (dep.id == user.departmentId) {
            user.departmentName = dep.name
          }
        })
      });
      this.dataSource = new MatTableDataSource(this.dataSource)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort
      this.dataSource.filterPredicate = (data: User, filter: string) => data.userName.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
    })
  }

  openDeleteDialog(training, user) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: "500px",
      data: { training: training, user: user }
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      this.getUsers()
    })
  }

  openUserTrainingsDialog(training) {
    const dialogRef = this.dialog.open(UserTrainingsDialogComponent, {
      width: '500px',
      data: {training: training, admin: this.admin}
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.dataSource = null
      if(this.admin.headAdmin){
        this.getUsers()
      }
      else{
        this.getUsers1()
      }
      
    })
  }

  getTrainer(id: string) {
    this.dashboardService.getTrainer(id).subscribe(trainer => {
      this.training.trainerName = trainer.firstName + " " + trainer.lastName
    })
  }

  getTest(id: string) {
    this.dashboardService.getTest(id).subscribe(test => {
      this.training.testName = test.name
    })
  }
  getPretest(id: string) {
    this.dashboardService.getTest(id).subscribe(test => {
      this.training.pretestName = test.name
    })
  }
  getSurvey(id: string) {
    this.dashboardService.getTest(id).subscribe(test => {
      this.training.surveyName = test.name
    })
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  openEditTrainingDialog(training) {
    const dialogRef = this.dialog.open(EditTrainingDialogComponent, {
      width: "500px",
      data: training
    })
    dialogRef.afterClosed().subscribe((result) => {
      this.setToNull()
      this.getTraining(this.id)
    })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value;
  }

  openAddSectionDialog() {
    const dialogRef = this.dialog.open(InsertSectionDialog, {
      width: '500px',
      data: { id: this.training.id, order: this.training.sections.length + 1 }
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.dashboardService.getSectionsForTraining(this.id).subscribe((response) => {
        this.setToNull()
        this.getTraining(this.id)
      })
    })
  }

  deleteSection(trainingSection) {
    const dialogRef = this.dialog.open(DeleteSectionDialogComponent, {
      width: '500px',
      data: trainingSection
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.setToNull()
      this.dashboardService.getSectionsForTraining(this.id).subscribe((response) => {
        this.getTraining(this.id)
        setTimeout(() => {
          this.changeSectionsOrder()
        }, 3000);
        
      })
    })
  }

  editSection(trainingSection) {
    const dialogRef = this.dialog.open(EditSectionDialogComponent, {
      width: '500px',
      data: trainingSection
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.setToNull()
      this.dashboardService.getSectionsForTraining(this.id).subscribe((response) => {
        this.getTraining(this.id)
      })
    })
  }

  addNewUnit(section) {
    const dialogRef = this.dialog.open(InsertUnitDialog, {
      width: '500px',
      data: { id: this.training.id, sectionId: section.id, order: section.units.length + 1 }
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.setToNull()
      this.dashboardService.getSectionsForTraining(this.id).subscribe((response) => {
        this.getTraining(this.id)
      })
    })
  }

  deleteUnit(sectionUnit) {
    const dialogRef = this.dialog.open(DeleteUnitDialogComponent, {
      width: '500px',
      data: sectionUnit
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.setToNull()
      this.getTraining(this.id)
      setTimeout(() => {
        this.changeUnitsOrder()
      }, 3000);
    })
  }
  editUnit(sectionUnit, section) {
    const dialogRef = this.dialog.open(EditUnitDialogComponent, {
      width: '500px',
      data: { trainingId: this.id, sectionId: section.id, sectionUnit: sectionUnit, type: sectionUnit.type, name: sectionUnit.name }
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.setToNull()
      this.dashboardService.getSectionsForTraining(this.id).subscribe((response) => {
        this.getTraining(this.id)
      })
    })
  }

  assignTest(training) {

    const dialogRef = this.dialog.open(AssignTestComponent, {
      width: '500px',
      data: { id: training.id, pretestId: training.pretestId, testId: training.testId, surveyId: training.surveyId }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.setToNull()
      this.dashboardService.getSectionsForTraining(this.id).subscribe((response) => {
        this.getTraining(this.id)
      })
      console.log("training: ", this.training)
    })
  }

  changeUnitsOrder() {
    let sections = this.training.sections
    console.log(sections)
    for (let index = 0; index < sections.length; index++) {
      let units = sections[index].units
      for (let index1 = 0; index1 < units.length; index1++) {
        const element = units[index1];
        element.order = index1 + 1
        this.dashboardService.changeSectionUnitOrder(element).subscribe((response) => {
          console.log(response)
        })
      }
    }
    this.getTraining(this.id)
  }

  changeSectionsOrder() {
    console.log('usao u sekcije')
    let sections = this.training.sections
    console.log(sections)
    for (let index = 0; index < sections.length; index++) {
      const element = sections[index];
      element.order = index + 1
      this.dashboardService.changeTrainingSectionsOrder(element).subscribe((response) => {
        console.log(response)
      })
    }
    this.getTraining(this.id)
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.getUsers();
        }
        else{
          this.getUsers1();
        }
      }, 100);
    });
  }

  openAssignDocumentsModal(training){
    const dialogRef = this.dialog.open(AssignDocumentsComponent, {
      width: '500px',
      data: training
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.setToNull()
      this.dashboardService.getSectionsForTraining(this.id).subscribe((response) => {
        this.getTraining(this.id)
      })
    })
  }
}
