<app-user-spinner *ngIf="lang == null || user == null || labels == null"></app-user-spinner>
<mat-toolbar [style.background-color] = "backgroundColor">
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSideBar()">
            <mat-icon>menu</mat-icon>
        </button>
        <!-- <button type="button" class="btn btn-secondary" title="" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right" data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." data-bs-original-title="Popover Title">Right</button> -->
       
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end" class="header1">
            <ul fxLayout="row" fxLayoutGap="20px" style = "font-weight: bold; letter-spacing: 0.5px;">
                <li [style.visibility]="(meeting.length > 0) ? 'visible' : 'hidden'">
                    <button mat-button [matMenuTriggerFor]="zoomMeetingMenu">
                        <div class="ring">
                            <div class="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
                                <div class="coccoc-alo-ph-circle"></div>
                                <div class="coccoc-alo-ph-circle-fill"></div>
                                <div class="coccoc-alo-ph-img-circle"> <mat-icon style="cursor: pointer;" class="phoneIcon">phone</mat-icon></div>
                            </div>
                        </div>
                    </button>
                    <mat-menu #zoomMeetingMenu = "matMenu" class = "zoomMatMenu">
                        <div *ngFor = "let m of meeting" style = "margin: 10px;">
                            <h5 style = "margin: auto; font-family: 'Poppins'; font-size: 17px; text-align: center;">{{m.calledByUserName}} {{labels.lblCalling}}</h5>
                        </div>
                       <div class = "row" style = "margin: auto; margin-bottom: 10px;">
                        <div class = "col-6" style = "text-align: center; border-right: 1px solid gray;">
                            <mat-icon style="color: green; cursor: pointer;" (click)="answerCall()">phone</mat-icon>
                        </div>
                        <div class = "col-6" style = "text-align: center">
                            <mat-icon (click) = "endCall()" style = "color: red; cursor: pointer;">phone_disabled</mat-icon>
                        </div>
                       </div>
                    </mat-menu>
                </li>

                <!-- <li class="menuItem" *ngIf = "showPhoneIcon.length > 0">
                    <mat-icon [routerLink]="['/user/zoom-meeting']" style="cursor: pointer;">phone</mat-icon>
                </li> -->
                <li class="menuItem">
                    <button class = "msgsBtn" [style.background-color] = "backgroundColor" [class] = "homePage" (click) = "changeColor('home')" mat-list-item routerLinkActive="list-item-active" routerLink="/user/dashboard"><mat-icon class="matIcon">home</mat-icon><span class="menuText">{{labels.lblDashboard}}</span></button>
                </li>
                <li class="menuItem">
                    <button class = "msgsBtn" [style.background-color] = "backgroundColor" [class] = "profilePage" (click) = "changeColor('profile')" mat-list-item routerLinkActive="list-item-active"><mat-icon class="matIcon">people</mat-icon><span class="menuText">{{labels.lblUserProfile}}</span></button>
                </li>
                <li class="menuItem">
                    <button class = "msgsBtn" [style.background-color] = "backgroundColor" [class] = "messagesPage" (click) = "changeColor('messages')">
                        <a style="position: relative" routerLink="/user/messages">
                            <mat-icon class="matIcon">textsms</mat-icon>
                        <span class="zvonce zvonce1 badge badge-success">{{msgsNotifications.length}}</span>
                        <span class="menuText">{{labels.lblMessages}}</span>
                        </a>
                    </button>
                </li>
                <li class="menuItem">
                    <button class = "msgsBtn" [style.background-color] = "backgroundColor" [class] = "calendar" (click) = "changeColor('calendar')" mat-list-item routerLinkActive="list-item-active" routerLink="/user/calendar"><span class="menuText">{{labels.lblCalendar}}</span></button>
                </li>
                <li class="menuItem">
                    <button class = "msgsBtn" [style.background-color] = "backgroundColor" [class] = "faqPage" (click) = "changeColor('faq')" mat-list-item routerLinkActive="list-item-active" routerLink="/user/faq"><span class="menuText">FAQ</span></button>
                </li>
                <li class="iconPadding iconTop">
                    <button mat-button [matMenuTriggerFor]="notificationsMenu">
                        <mat-icon >notifications_none</mat-icon>
                        <span class="zvonce badge badge-success">{{notifications.length}}</span>
                    </button>
                    <mat-menu #notificationsMenu="matMenu">
                        <div class="container" style="padding-left:0px; padding-right:0px; ">
                            <div class="notifMenu" *ngFor="let n of notifications">
                                <div class="row notification" mat-list-item
                                    routerLinkActive="list-item-active"  >
                                    <!-- <div class="col-9 text" *ngIf="n.type == 1">
                                        <div class="row">
                                            <div class="col-1" style="margin: auto; padding-left: 0px;">
                                                <mat-icon style="color: #7bea7b;">textsms</mat-icon>
                                            </div>
                                            <div class="col-10">
                                                Nova poruka od korisnika {{ n.fullname}}
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-9 text" *ngIf="n.type == 2">
                                        <div class="row">
                                            <div class="col-1" style="margin: auto; padding-left: 0px;">
                                                <mat-icon style="color: #8ac7db;">article</mat-icon>
                                            </div>
                                            <div class="col-10">
                                                {{n.content}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 date">
                                        {{n.timestamp}}
                                    </div>
                                </div>
                            </div>
                            <div class="notifMenu" *ngIf="notifications.length==0">
                                <div class="row notification" mat-list-item
                                    routerLinkActive="list-item-active" >
                                    <div class="col-1" style="margin: auto; text-align: center;">
                                        <mat-icon>notifications_none</mat-icon>
                                    </div>
                                    <div class="col-8 text">
                                       <h4 style="font-family: 'Poppins';">{{labels.lblNoNotifications}}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row notification clear" mat-list-item
                                    routerLinkActive="list-item-active" style="padding: 7px;" (click)="setToSeen()" *ngIf="notifications.length!=0">
                                    <div class="col-8 text" style="color: black; margin: auto; text-align: center; ">
                                        <mat-icon style="font-size: 21px;">delete</mat-icon>{{labels.lblDeleteAll}}
                                    </div>
                            </div>
                        </div>
                    </mat-menu>
                </li>
               <!--  <li>
                    <button mat-icon-button>
                        <mat-icon>
                            settings
                        </mat-icon>
                    </button>
                </li> -->
                <li class="iconTop">
                    <button mat-icon-button [matMenuTriggerFor]="langMenu">
                        <img *ngIf="lang=='srb'" style="width:85px; height:45px" class="flag"
                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                        <img *ngIf="lang=='cirilica'" style="width:85px; height:45px" class="flag"
                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                        <img *ngIf="lang=='eng'" style="width:85px; height:45px" class="flag"
                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-engleski1.png?alt=media&token=236e883a-232b-437e-91a0-3b07e21dc490" alt="">
                    </button>
                    <mat-menu #langMenu="matMenu">
                        <button mat-menu-item class="menuButton" (click)="selectLang('srb')">
                            <img class = "flag" style="width:95px; height:50px" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                            <span class="aligned-with-icon"> {{labels.lblSerbian}}</span>
                        </button>
                        <button mat-menu-item class="menuButton" (click)="selectLang('cirilica')">
                            <img class="flag" style="width:95px; height:50px" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                            <span class="aligned-with-icon"> {{labels.lblSerbianCyrillic}}</span>
                        </button>
                        <button mat-menu-item class="menuButton" (click)="selectLang('eng')">
                            <img class="flag"
                                style="width:95px; height:50px"
                                src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-engleski1.png?alt=media&token=236e883a-232b-437e-91a0-3b07e21dc490"
                                alt="">
                            <span class="aligned-with-icon"> {{labels.lblEnglish}}</span>
                        </button>
                    </mat-menu>
                </li>
                <li class="iconTop">
                    <button class="iconPadding" mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="logout()" style="font-family: 'Poppins';">
                            <mat-icon>exit_to_app</mat-icon>
                            {{labels.lblLogOut}}
                        </button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>