import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  Inject
} from '@angular/core';
import {
  startOfMonth,
  startOfWeek,
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfWeek,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  format,
} from 'date-fns';
import { Observable, Subject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEventComponent } from './add-event/add-event.component';
import { UserDashboardService } from '../user-dashboard.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { share } from 'rxjs/operators';
import { Event } from 'src/app/models/event.model'
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { WebRequestService } from 'src/app/web-request.service';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { DeleteEventComponent } from './delete-event/delete-event.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import localeCyrl from '@angular/common/locales/sr-Cyrl'
import { registerLocaleData } from '@angular/common';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#799ACF',
    secondary: '#799ACF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#49D14F',
    secondary: '#49D14F'
  },
  white: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF'
  }
};

@Component({
  selector: 'app-user-calendar-component',
  templateUrl: './user-calendar-component.component.html',
  styleUrls: ['./user-calendar-component.component.scss']
})
export class UserCalendarComponentComponent {
  lang
  langSub: Subscription;
  labels
  labelsSub: Subscription
  user
  userSub: Subscription
  buttonStyle
  buttonStyle1
  events1

  locale: String = "sr-Latn"

  events$: Observable<CalendarEvent[]>
  eventsSub: Subscription
  showCalendar: boolean = false
  userId = ""


  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  events: CalendarEvent[]
  reload = false

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  constructor(public dialog: MatDialog, private userLanguageService: UserLanguageService, public db: AngularFirestore,
    private modal: NgbModal, private authService: AuthService, private dashboardService: UserDashboardService,
    private http: HttpClient, private webRequestService: WebRequestService, private localStorageService: LocalStorageService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.langSub = this.userLanguageService.lang$.subscribe((lang) => {
      this.lang = lang;
      setTimeout(() => {
        if(this.lang == "eng"){
          this.locale = "en"
        } else if(this.lang == "cirilica"){
          registerLocaleData(localeCyrl)
          this.locale = "sr-Cyrl"
        }
      }, 100);
    })

    // this.getEvents()

    // this.events$ = this.dashboardService.getEvents().pipe(share())
    // this.eventsSub = this.dashboardService.events$.subscribe((events) => {
    //   this.events = events
    //   console.log("events: ", this.events)
    // });

    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      this.events = []
      setTimeout(() => {
        this.userId = this.user.userId
        this.events = this.getEvents(this.user.userId)
        console.log("bre", this.events)
        this.dayClicked({ date: new Date(), events: this.events })
      }, 500)
    })
    setTimeout(() => {
      this.reload = true
    }, 1500);
  }

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  // getEvents(){
  //    this.dashboardService.getEvents().subscribe((events: CalendarEvent[]) => {
  //     this.events1 = events
  //   })
  // }

  getEvents(id) {
    let events = []
    let usersId = id
    let docRef = this.db.firestore.collection("users").doc(usersId).collection("userTrainings")
    docRef.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let event = change.doc.data()
        if (event.toDate != null) {
          let date = new Date(change.doc.data().toDate.seconds * 1000)
          event.date = date.toString()
          event.timestamp = change.doc.data().toDate.seconds
          var formatDate = date.getDate() +
            "/" + (date.getMonth() + 1) +
            "/" + date.getFullYear()
           
          event.dateTime = formatDate
          event.start = new Date(change.doc.data().toDate.seconds * 1000)
          event.color = colors.green
          let actions: CalendarEventAction[] = [
            {
              label: event.name + " " + "( " + event.dateTime + ")",
              a11yLabel: 'Edit',
              onClick: ({ event }: { event: CalendarEvent }): void => {
                this.doNothing();
              },
            },
          ];
          event.actions = actions
          events.push(event)
        }
      });
    });

    let docRef2 = this.db.firestore.collection("users").doc(usersId).collection("userZoomEvents")
    docRef2.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let event = change.doc.data()
        let date = new Date(change.doc.data().correctDate.seconds * 1000)
        event.date = date.toString()
        event.start = new Date(change.doc.data().correctDate.seconds * 1000)
        event.timestamp = change.doc.data().correctDate.seconds
        var formatDate = date.getDate() +
          "/" + (date.getMonth() + 1) +
          "/" + date.getFullYear()
        event.dateTime = formatDate
        let actions: CalendarEventAction[] = [
          {
            label: event.name + " "+ "( " + event.dateTime + ")",
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
              this.doNothing();
            },
          },
        ];
        event.actions = actions
        event.color = colors.blue
        events.push(event)
      });
    });
    console.log("events: ", events)
    setTimeout(() => {
      events = events.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
      console.log("after sorted events: ", events)
    }, 1000);
    
    this.showCalendar = true
    return events
  }


  doNothing(){
    console.log("OK")
  }

  sortEvents(events) {
    let events1 = []
    events1 = events.sort((a, b) => (a.start > b.start ? -1 : 1))
    console.log("SORT EVENTS BRE: ", events1)
    return events1
  }

  // eventTimesChanged({
  //   event,
  //   newStart,
  //   newEnd,
  // }: CalendarEventTimesChangedEvent): void {
  //   this.events = this.events.map((iEvent) => {
  //     if (iEvent === event) {
  //       return {
  //         ...event,
  //         start: newStart,
  //         end: newEnd,
  //       };
  //     }
  //     return iEvent;
  //   });
  //   this.handleEvent('Dropped or resized', event);
  // }

  handleEvent(action: string, event): void {
    console.log("Action: ", action)
    console.log("Event", event)
    if (action == "Deleted") {
      this.deleteEvent(event);
    } else if (action == "Edited") {
      this.editEvent(event)
    }
  }

  addEvent(): void {
    this.openAddEventDialog()
  }

  openAddEventDialog(): void {
    const dialogRef = this.dialog.open(AddEventComponent, {
      disableClose: true,
      width: '400px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("Dialog closed.")
      this.reload = false
      this.events = this.getEvents(this.userId)
      setTimeout(() => {
        this.reload = true
      }, 1000);
    })
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    const dialogRef = this.dialog.open(DeleteEventComponent, {
      disableClose: true,
      width: '400px',
      data: { userId: this.userId, eventId: eventToDelete.id }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("Delete dialog closed.")
      this.reload = false
      this.events = this.getEvents(this.userId)
      setTimeout(() => {
        this.reload = true
      }, 1000);
    })
  }

  editEvent(eventToEdit) {
    const dialogRef = this.dialog.open(EditEventComponent, {
      disableClose: true,
      width: '400px',
      data: { userId: this.userId, eventId: eventToEdit.id, eventTitle: eventToEdit.title, eventDescription: eventToEdit.description, eventDate: eventToEdit.start }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("Edit dialog closed.")
      this.reload = false
      this.events = this.getEvents(this.userId)
      setTimeout(() => {
        this.reload = true
      }, 1000);
    })
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


}
