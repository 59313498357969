import { Component, ElementRef, Input, OnInit, ChangeDetectorRef, EventEmitter, HostListener, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { Training } from 'src/app/models/training.model';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserDashboardService } from '../user-dashboard.service';
import { TestQuestionScore } from 'src/app/models/testQuestionScore.model'
import { TestQuestionAnswerScore } from 'src/app/models/testQuestionAnswerScore.model'
import { TestQuestionAnswer } from 'src/app/models/testQuestionAnswer.model';
import { TestQuestion } from 'src/app/models/testQuestion.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UserTestMessageComponent } from './user-test-message/user-test-message.component';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserXTrainings } from 'src/app/models/userXTrainings.model';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { HintVideoPlayerComponent } from '../hint-video-player/hint-video-player.component';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  host: { '(click)': 'onClick()' }
})
export class TestComponent implements OnInit {
  @ViewChild('basicTimer') timer

  training$: Observable<Training>
  trainingSub: Subscription
  training: Training

  test$: Observable<Test>
  testSub: Subscription
  test: Test

  userSub: Subscription
  user: User

  labelsSub; Subscription
  labels

  currentTime: number

  index: number = 0
  checked: boolean = false

  questionScores: TestQuestionScore[] = []

  haveSurvey$: Observable<boolean>

  userXTraining$: Observable<UserXTrainings>
  userXTraining: UserXTrainings
  userXTrainingSub: Subscription

  testProgress = 0
  showNextBtn

  backgroundColor
  buttons
  numOfTestDrops = 0
  userId
  testId
  trainingId
  showVideoHintIcon: boolean = false;
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  constructor(private userLanguageService: UserLanguageService, public dialog: MatDialog, private authService: AuthService, private userDashboarService: UserDashboardService, private localStorageService: LocalStorageService, private component: UserDashboardComponent) { }

  ngOnInit(): void {
    this.showNextBtn = false;
    this.training$ = this.userDashboarService.getTraining().pipe(share())
    this.trainingSub = this.userDashboarService.training$.subscribe((training: Training) => {
      this.training = training;
      setTimeout(() => {
        this.trainingId = this.training.id
      }, 300)
    });

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        this.userId = this.user.userId
        if(this.user.companyId == 1){
          this.backgroundColor = "#bbbcbf"
          this.buttons = "buttonEMSGray"

        }else if(this.user.companyId == 2){
          this.backgroundColor = "rgb(241,106,100)"
          this.buttons = "buttonECG"
        }
        else{
          this.backgroundColor = "rgb(241,106,100)"
          this.buttons = "buttonECG"
        }
      }, 200);
    });
    /* this.test$ = this.userDashboarService.getTestForTraining(this.localStorageService.getItem('testId')).pipe(share())
    this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
      console.log(test)
      this.test = test;
      this.setTimer()
      setTimeout(() => {
        this.index = this.localStorageService.getItem('currentQuestionIndex')
        let p = (this.index) / this.test.questions.length * 100
        this.testProgress = Math.floor(p)
      }, 100);
    }); */

    this.userDashboarService.getTestForTraining(this.localStorageService.getItem('testId')).subscribe(response => {
      setTimeout(() => {
        this.test = response as Test
        this.testId = this.test.id
        this.setTimer()
        this.index = this.localStorageService.getItem('currentQuestionIndex')
        let p = (this.index) / this.test.questions.length * 100
        this.testProgress = Math.floor(p)
        this.setFinalTry(this.test.id,this.test.numberOfTestDrops);
      }, 300);
    })

    this.userXTraining$ = this.userDashboarService.getUserXTrainings(this.localStorageService.getItem('trainingId')).pipe(share());
    this.userXTrainingSub = this.userDashboarService.userXTraining$.subscribe((usrXTr: UserXTrainings) => {
        console.log("user x training test")
        this.userXTraining = usrXTr;
        console.log(this.userXTraining)
    })

    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    let scores = this.localStorageService.getItem('questionScores')
    if (scores == null) {
      this.questionScores = []
    }
    else {
      this.questionScores = scores
    }
  }

  @HostListener("click") onClick() {
    this.component.sideBarOpened = false
  }

  nextQuestion(order: number) {
    console.log("this.questioNSc")
    console.log(this.questionScores)
    this.questionScores = []
    let questionAnswerScores: TestQuestionAnswerScore[] = []
    let currentQuestion: TestQuestion = this.test.questions[order - 1]
    let nextQuestion: TestQuestion = this.test.questions[order]
    this.getScoreForQuestion(questionAnswerScores, currentQuestion)

    this.localStorageService.setItem('questionScores', this.questionScores)
    let show
    if (!(this.test.questions.length == order)) {
      this.index = order
      let p = (this.index) / this.test.questions.length * 100
      this.testProgress = Math.floor(p)
      this.localStorageService.setItem('currentQuestionIndex', order)
      this.localStorageService.setItem('test', this.test)
      for (let i = 0; i < nextQuestion.answers.length; i++) {
        const ans = nextQuestion.answers[i];
        if (ans.selected == true) {
          show = true;
          break;
        }
      }
      if (show == true) {
        this.showNextBtn = true
      } else {
        this.showNextBtn = false
      }
    }
    else {
    }


  }

  previousQuestion(order: number) {
    this.showNextBtn = true;
    if (order != 1) {
      this.index = order - 2
      let p = (this.index) / this.test.questions.length * 100
      this.testProgress = Math.floor(p)
      this.localStorageService.setItem('currentQuestionIndex', order - 2)
      this.localStorageService.setItem('test', this.test)
    }
    else {
    }

  }

  submit(order: number, timeIsUp: boolean) {
    if (this.test.durationTime != null) {
      this.timer.stop()
    }
    this.testProgress = 100
    this.nextQuestion(order)
    console.log(this.questionScores)
    if (this.questionScores.length < this.test.questions.length) {
      this.createRemainingScores()
    }
    let corrcetAnswersCounter = 0
    let questions = this.test.questions.length
    let points = 0
    let minPercentage = this.test.minPercentage
    let maxPoints = 0
    for (let index = 0; index < this.test.questions.length; index++) {
      maxPoints += this.test.questions[index].points
    }
    this.questionScores.forEach(qs => {
      if (qs.correct) {
        corrcetAnswersCounter++
        for (let index = 0; index < this.test.questions.length; index++) {
          if (this.test.questions[index].id == qs.testQuestionId) {
            points += this.test.questions[index].points
          }
        }
      }
    })
    let p: number = corrcetAnswersCounter / this.questionScores.length * 100
    let percentage: number = Math.floor(p)
    let passedTest: boolean = false
    if (percentage >= minPercentage) {
      passedTest = true
      this.openTestDialog(corrcetAnswersCounter, percentage, questions, points, minPercentage, maxPoints, passedTest, timeIsUp, this.numOfTestDrops, this.trainingId)
    }else{
      console.log("USAO U ELSE")
      this.userDashboarService.updateNumberOfTestDrops({userId: this.userId, testId: this.testId}).subscribe(result => {
        console.log("NUM OF TEST DROPS: ", result)
        setTimeout(() => {
          this.numOfTestDrops = Number(result)
          this.openTestDialog(corrcetAnswersCounter, percentage, questions, points, minPercentage, maxPoints, passedTest, timeIsUp, this.numOfTestDrops, this.trainingId)
        }, 300)
        console.log("Num of test drops2: ", this.numOfTestDrops)
      })
    }
    console.log(this.questionScores)
    this.userDashboarService.updateNumberOfTestVisits({userId: this.userId, testId: this.testId})
    
    this.userDashboarService.updateNumberOfAttempts(this.localStorageService.getItem('trainingId'))
    this.userDashboarService.setQuestionScoresFailed(this.questionScores, false, false).subscribe((response) => {
      console.log(response)
    })
    if (passedTest && !this.userXTraining.successful) {
      this.userDashboarService.setQuestionScores(this.questionScores, false, false).subscribe((response) => {
        console.log(response)
      })
    }
    this.localStorageService.setItem('test', null)
    this.localStorageService.removeItem('questionScores')


  }

  getScoreForQuestion(questionAnswerScores: TestQuestionAnswerScore[], currentQuestion: TestQuestion) {
    let questionScore: TestQuestionScore
    questionScore = { id: this.getId(), testQuestionId: currentQuestion.id, userId: this.user.id, correct: false, answerScores: questionAnswerScores, testId: this.test.id }

    currentQuestion.answers.forEach(a => {
      let answerScore: TestQuestionAnswerScore
      let checked: boolean
      if (a.selected) {
        checked = true
      }
      else {
        checked = false
      }
      answerScore = { id: this.getId(), testQuestionScoreId: questionScore.id, testQuestionAnswerId: a.id, checked: checked, correct: this.isItCorrect(checked, a) }
      questionAnswerScores.push(answerScore)
    })

    let flag: boolean = true
    for (let index = 0; index < questionAnswerScores.length; index++) {
      if (!questionAnswerScores[index].correct) {
        flag = false
        break;
      }
    }

    questionScore.testQuestionId = currentQuestion.id
    questionScore.correct = flag
    questionScore.testId = this.test.id
    questionScore.answerScores = questionAnswerScores

    this.addQuestionScore(this.questionScores, questionScore)
  }

  addQuestionScore(scores: TestQuestionScore[], score: TestQuestionScore) {
    let flag = 0
    for (let index = 0; index < scores.length; index++) {
      if (scores[index].testQuestionId == score.testQuestionId) {
        scores[index] = score
        flag = 1
        break;
      }
    }
    if (!flag) {
      scores.push(score)
    }

  }

  isItCorrect(checked: boolean, answer: TestQuestionAnswer): boolean {
    if ((checked && answer.correct) || (!checked && !answer.correct)) {
      return true
    }
    else return false
  }

  getId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  onSelect(question: TestQuestion, answer: TestQuestionAnswer) {
    if (question.type == 1) {
      question.answers.forEach((x) => { if (x.id !== answer.id) x.selected = false; })
    }
    else {
      question.answers.forEach((x) => {
        if (x.id == answer.id) {
          if (x.selected) {
            x.selected = false
            x.selected = true
          }
          else {
            x.selected = true
            x.selected = false
          }
        }
      })
    }
    let show
    for (let i = 0; i < question.answers.length; i++) {
      const ans = question.answers[i];
      if (ans.selected == true) {
        show = true;
        break;
      }
    }
    if (show == true) {
      this.showNextBtn = true
    } else {
      this.showNextBtn = false
    }

  }

  openTestDialog(corrcetAnswersCounter: number, percentage: number, questions: number, points: number, minPercentage: number, maxPoints: number, passedTest: boolean, flag: boolean, numOfTestDrops: number, trainingId: string): void {
    const dialogRef = this.dialog.open(UserTestMessageComponent, {
      disableClose: true,
      width: '650px',
      data: { corrcetAnswersCounter: corrcetAnswersCounter, percentage: percentage, questions: questions, points: points, minPercentage: minPercentage, maxPoints: maxPoints, passedTest: passedTest, timeIsUp: flag, numOfTestDrops: numOfTestDrops, trainingId: trainingId }
    });
  }

  onTick() {
    this.localStorageService.setItem('minutesLeft', this.timer.minutes)
    this.localStorageService.setItem('secondsLeft', this.timer.seconds)
  }

  setTimer() {
    if (this.localStorageService.getItem('minutesLeft') == null) {
      this.currentTime = this.test.durationTime * 60
      console.log(this.currentTime)
      this.localStorageService.setItem('minutesLeft', this.test.durationTime)
      this.localStorageService.setItem('secondsLeft', 0)
      console.log(this.localStorageService.getItem('minutesLeft'))
    }
    else {
      console.log(this.localStorageService.getItem('minutesLeft'))
      this.currentTime = this.localStorageService.getItem('minutesLeft') * 60 + this.localStorageService.getItem('secondsLeft')
      console.log(this.currentTime)
    }
  }

  onTimeIsUp() {
    console.log(this.currentTime)
    let order = this.localStorageService.getItem('currentQuestionIndex') + 1
    console.log(order)
    this.submit(order, true)
  }

  createRemainingScores() {
    console.log(this.questionScores)
    let flag
    let remainingQuestions = []
    for (let i = 0; i < this.test.questions.length; i++) {
      flag = false
      for (let j = 0; j < this.questionScores.length; j++) {
        console.log(this.test.questions[i].id + " : " + this.questionScores[j].testQuestionId)
        if (this.test.questions[i].id == this.questionScores[j].testQuestionId) {
          flag = true
        }
      }
      if (!flag) {
        remainingQuestions.push(this.test.questions[i])
      }
    }
    console.log(remainingQuestions)
    for (let index = 0; index < remainingQuestions.length; index++) {
      let answerScores: TestQuestionAnswerScore[] = []
      this.getScoreForQuestion(answerScores, remainingQuestions[index])

    }
  }

  setFinalTry(id, drops){
    this.userDashboarService.getUserTestNumOfDrops(id).subscribe((response) => {
      this.showVideoHintIcon = (drops - Number(response)) == 1;
    })
  }

  openVideoHintComponent() {

    const dialogRef = this.dialog.open(HintVideoPlayerComponent, {
      height: "75%",
      width: "75%",
      data: {
        videoSrc: this.test.questions[this.index].videoHintPath
      }
    });
  }
}