import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-admin-upload-document',
  templateUrl: './admin-upload-document.component.html',
  styleUrls: ['./admin-upload-document.component.scss']
})
export class AdminUploadDocumentComponent implements OnInit {

  labels;
  labelsSub: Subscription;
  selectedFile: File
  uploading: boolean = false
  extension: string
  @ViewChild('fileUpload') documentUpload: ElementRef


  constructor(public snackBar: MatSnackBar, public adminLanguageService: AdminLanguageService, public dialogRef: MatDialogRef<AdminUploadDocumentComponent>, private dashboardService: DashboardService, @Inject(MAT_DIALOG_DATA) public data, private storage: AngularFireStorage) { }

  ngOnInit(): void {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  onFileChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if (ext == "pdf" || ext == "docx") {
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number: Number = +iConvert
      if (number <= 5) {
        this.selectedFile = event.target.files[0]
        console.log(this.selectedFile.name)
        var a = this.selectedFile.name.split('.');
        this.extension = a[a.length - 1]
        console.log(this.extension)
      }
      else {
        this.snackBar.open(this.labels.lblMaxSizeDocument, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.documentUpload.nativeElement.value = ""
      }

    }
    else {
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsDocument, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.documentUpload.nativeElement.value = ""
    }
  }

  async onSaveDocument() {
    let doc = {
      name: this.selectedFile.name,
      path: ""
    }
    this.uploading = true
    let ref = this.storage.ref(this.selectedFile.name)
    await ref.put(this.selectedFile)
    doc.path = await ref.getDownloadURL().toPromise()
    this.dashboardService.addDocumentForUser(doc, this.data.userId).subscribe(response => {
      console.log(response)
      this.dialogRef.close()
    })
  }



}
