<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblEditMeeting}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #editForm="ngForm">
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblMeetingTitle}}</mat-label>
            <input matInput [ngModel]="data.eventTitle" name="title" required>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="none" fxFlex="60%" class="matFormField">
            <mat-label>{{labels.lblDescription}}</mat-label>
            <textarea class="txtArea" matInput [ngModel]="data.eventDescription" name="description" required></textarea>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>

        <mat-form-filed appearance="none" fxFlex="60%" *ngIf = "data.imagePath == null">
          <input 
          type="file" 
          (change)="onImageChanged($event)" 
          #imageUpload
          style="display: none;"
          >
          <mat-label style="color: #3e4b71;">{{labels.lblUploadImage}} </mat-label>
          <mat-icon class="icon" (click)="imageUpload.click()">camera_alt</mat-icon>
      </mat-form-filed>
      <div *ngIf = "data.imagePath != null" style="width: 100%;">
          <mat-label>{{labels.lblImage}}: {{data.imageName}}</mat-label>
          <mat-icon style="float:right; cursor: pointer;" (click)="updateData()">cancel</mat-icon>
      </div>

        <!-- <mat-form-field appearance="none" fxFlex="60%" class="matFormField">
            <mat-label>{{labels.lblMeetingId}}</mat-label>
            <input class="txtArea" matInput [ngModel]="data.meetingId" name="meetingId" required>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="none" fxFlex="60%" class = "form-field">
            <mat-label>{{labels.lblDate}}</mat-label>
            <input matInput [matDatepicker]="picker" [ngModel] = "data.eventDate" name="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
          </mat-form-field>

          <div style = "width: 100%;">
            <mat-form-field appearance="none" fxFlex="60%" style = "width: 30%">
              <span>h:</span>
              <input type = "number" step = "1" min = "0" max = "23" matInput [ngModel] = "data.hour" name="hour" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
      
            <mat-form-field appearance="none" fxFlex="60%" style = "width: 30%">
              <span>min:</span>
              <input type = "number" step = "15" min = "0" max = "45" matInput [ngModel] = "data.minutes" name="minutes" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
           </div> -->
        
        <div mat-dialog-actions>
            <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
            <button class="tableBtn" cdkFocusInitial type="submit">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div>