import { Component, OnInit,Input } from '@angular/core';
import * as HighCharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
@Component({
  selector: 'app-widget-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {

  @Input() data:[]
  chartOptions:{}
  Highcharts=HighCharts;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions={
    chart:{
      type: 'area'
  },
  title: {
      text: 'Borislav Petric graphic'
  },
  subtitle: {
      text: 'Demo'
  },
  tooltip: {
      split: true,
      valueSuffix: ' millions'
  },
  credits:{
    enabled:false
  },
  exporting:{
    enabled:true
  },
  series: this.data
  }
  HC_exporting(HighCharts)
  setTimeout(()=>{
    window.dispatchEvent(new Event('resize'))
  },300)
} 

}
