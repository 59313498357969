import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserLanguageService } from '../services/user-language-service/user-language.service';

@Component({
  selector: 'app-wrong-user-login-data',
  templateUrl: './wrong-user-login-data.component.html',
  styleUrls: ['./wrong-user-login-data.component.scss']
})
export class WrongUserLoginDataComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  constructor(public dialogRef: MatDialogRef<WrongUserLoginDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data,public userLanguageService: UserLanguageService) { }

  ngOnInit(): void {
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
        })
  }

  onNoClick(){
    this.dialogRef.close()
  }

}
