import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard.service';
import { AdminLanguageService } from 'src/app/modules/services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-delete-test-answer',
  templateUrl: './delete-test-answer.component.html',
  styleUrls: ['./delete-test-answer.component.scss']
})
export class DeleteTestAnswerComponent implements OnInit {
  labels;
  labelsSub: Subscription
  constructor(public dialogRef: MatDialogRef<DeleteTestAnswerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.getLang()
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  deleteAnswer() {
    if (this.data.flag) {
      this.dashboardService.deleteAnswer(
        this.data.testId,
        this.data.testQuestionId,
        this.data.id
      ).subscribe(response => {
        this.snackBar.open(this.labels.lblControlQuestionAnsDeleted, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
      })
    }
    else {
      this.dialogRef.close()
      this.snackBar.open(this.labels.lblCantDeleteSelectedAnswer, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    }

  }
  onNoClick() {
    this.dialogRef.close()
  }
}
