<app-admin-spinner *ngIf="dataSource == null || labels == null || training == null || section == null || testName == null"></app-admin-spinner>
<div fxLayout="row" class="table1" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxFlex="5"></div>
    <div fxFlex="90">
        <!-- <mat-card class="header">
            <div fxLayout="row">
                <mat-card-title fxFlex="90" fxFlex="100">Table of Units</mat-card-title>
                <mat-card-content fxFlex="10" fxFlex="40" fxFlex.sm="60" fxFlex.xs="80">
                    <button mat-raised-button class="tableBtn" (click)="addNewUnit()" style="margin: 0.7vw;">{{labels.lblAddNewUnit}}</button>
                    <button mat-raised-button class="tableBtn" (click)="showChangeButton()" *ngIf='value===true'>
                        {{labels.lblChangeOrder }}     
                    </button>
                    <button mat-raised-button class="tableBtn" (click)="changeOrder()" *ngIf='value===false'>
                        {{labels.lblSaveOrder}}     
                    </button>
                </mat-card-content>
            </div>
        </mat-card> -->
        <div class="row">
            <div class="col-12 col-xl-7 sectorInfo" style="margin-bottom: 3%;">
              <div class="trainingCard" style="background-color:#182038 !important">
                <h2 class="basicInfo">{{labels.lblBasicInfo}}</h2>
                  <mat-card style="padding-top: 0px;">
                      <mat-card-content>
                        <div class="row info">
                            <div class="col-12 col-lg-4">
                                <h3>{{labels.lblETrainingName}}: </h3>
                            </div>
                            <div class="col-12 col-lg-8">
                                <h3 style="color: #9dacd9;">{{training.name}}</h3>
                            </div>
                        </div>
                        <div class="row info">
                            <div class="col-12 col-lg-4">
                                <h3>{{labels.lblSectionName}}: </h3>
                            </div>
                            <div class="col-12 col-lg-8">
                                <h3 style="color: #9dacd9;">{{section.name}}</h3>
                            </div>
                        </div>
                          <div class="row info" *ngIf = "section.testId">
                              <div class="col-12 col-lg-4">
                                  <h3>{{labels.lblTestName}}: </h3>
                              </div>
                              <div class="col-12 col-lg-8">
                                  <h3 class = "link" [routerLink]="['/admin/test-details',section.testId]">{{testName}}</h3>
                              </div>
                          </div>
                          <div class="row" style="text-align: right;">
                            <div class="col-12 text-right" style="text-align: right;">
                                <button mat-raised-button class="tableBtn" style="margin-right: 20px;" (click)="assignTest(section)">
                                    {{labels.lblAssignTest}}
                                  </button>
                            </div>
                          </div>
                      </mat-card-content>
                    </mat-card>
              </div>
            </div>
        <div class="mat-elevation-z8">
            <div class="row header">
                <div class="col-12 col-xl-6 text-left">
                   <div class="row">
                        <div class="col-12">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb" style="background-color: #182038; font-size: 15.7px;">
                                    <li class="breadcrumb-item"><a style="color: #3e4b71" routerLinkActive="list-item-active"
                                            routerLink="/admin/trainings"><mat-icon>home</mat-icon></a></li>
                                    <li class="breadcrumb-item"><a style="color: #6678ab"
                                            [routerLink]="['/admin/training-details',training.id]"><span data-toggle="tooltip" data-placement="top" title={{training.name}}>{{training.name.substring(0,21)}}<span *ngIf = "training.name.length > 21">...</span></span></a>
                                    </li>
                                <li  style="color: #9dacd9; cursor: pointer;" [routerLink]="['/admin/trainings/training-detail',section.trainingId]"><span
                                    style="margin-left: 10px; margin-right: 10px; " >></span>{{labels.lblSections}}</li>
                                    <!-- <li><a style="color: #8293c6"><span
                                                style="margin-left: 10px; margin-right: 10px;">></span><span data-toggle="tooltip" data-placement="top" title={{section.name}}>{{section.name.substring(0,21)}}</span><span *ngIf = "section.name.length > 21">...</span></a></li> -->
                                    <li aria-current="page" style="color: #9dacd9"><span
                                            style="margin-left: 10px; margin-right: 10px;">></span>{{labels.lblUnits}}</li>
                                </ol>
                            </nav>
                        </div>
                        
                   </div>
                </div>
                <div class="col-12 col-xl-6 addNewSectionBtn"> <button mat-raised-button class="tableBtn addNewSectionBtn1" (click)="addNewUnit()" [disabled] = "training.assignedUsers.length > 0"
                        >{{labels.lblAddNewUnit}}</button>
                    <button mat-raised-button class="tableBtn hideColumn" (click)="showChangeButton()"
                        *ngIf='value===true' [disabled] = "training.assignedUsers.length > 0">
                        {{labels.lblChangeOrder }}
                    </button>
                    <button mat-raised-button class="tableBtn hideColumn" (click)="changeOrder()" *ngIf='value===false'>
                        {{labels.lblSaveOrder}}
                    </button>
                </div>
                <div class="col-12">
                    <hr class="hrline" style="width: 100%; margin: 0px;">
                    <div class="row">
                        <div class="col-12 col-xl-6">
                            <div class="col-10 text-left search1">
                                <mat-form-field class="search" appearance="none" fxFlex="100%">
                                    <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- <div class="col-12 col-xl-6 addNewSectionBtn">
                            <h3 class="ok"><span style="color: #8293c6;">{{labels.lblSection}}:</span> {{section.name}}</h3>
                        </div> -->
                    </div>
                </div>
            </div>
            <table matSortDisableClear="true" #table mat-table [dataSource]="dataSource" matSort cdkDropList [cdkDropListData]="dataSource"
                (cdkDropListDropped)="drop($event)">
                <ng-container matColumnDef="order">
                    <th style = "width: 15%" mat-header-cell *matHeaderCellDef mat-sort-header><span class="order">{{labels.lblOrder}}</span><span class="order1">{{labels.lblO}}</span></th>
                    <td style = "width: 15%" mat-cell *matCellDef="let sectionUnit">
                        <mat-icon *ngIf="!value" style="cursor: pointer;" class="settings">
                            import_export
                        </mat-icon>
                        {{sectionUnit.order}}
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblType}}</th>
                <td mat-cell *matCellDef="let sectionUnit" class="hideColumn">
                        <span *ngIf = "sectionUnit.type == 1">{{labels.lblVideoUnit}}</span>
                        <span *ngIf = "sectionUnit.type == 2">{{labels.lblDocUnit}}</span>
                        <span *ngIf = "sectionUnit.type == 3">{{labels.lblImageUnit}}</span>
                        <span *ngIf = "sectionUnit.type == 4">{{labels.lblTextUnit}}</span>
                        <span *ngIf = "sectionUnit.type == 5">{{labels.lblAudioUnit}}</span>
                </td>
            </ng-container> -->
                <!-- userName Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblUnitName}} </th>
                    <td class="unitName" mat-cell data-toggle="tooltip" data-placement="top" title={{sectionUnit.name}} *matCellDef="let sectionUnit">

                        {{sectionUnit.name.substring(0,40)}}<span *ngIf="sectionUnit.name.length > 40">...</span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="duration">
                    <th style = "width: 15%" mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblDuration}}</th>
                    <td style = "width: 15%" mat-cell *matCellDef="let sectionUnit" class="hideColumn">
                        <span *ngIf="sectionUnit.videoDuration != null">{{sectionUnit.videoDuration}}</span>
                        <span *ngIf="sectionUnit.audioDuration != null">{{sectionUnit.audioDuration}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th style="width: 35%" mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
                    <td style = "width: 35%" mat-cell *matCellDef="let sectionUnit">
                        <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="editUnit(sectionUnit)">
                    {{labels.lblEdit}}
                </button>
                <button class="deleteBtn d-none d-sm-inline-flex" (click)="deleteUnit(sectionUnit)">
                    {{labels.lblDelete}}
                </button> -->
                        <button class="addNewBtn controlBtn d-sm-inline-flex" color="primary"
                        [routerLink]="['/admin/trainings/control-question',sectionUnit.trainingId, sectionUnit.trainingSectionId,sectionUnit.id]" *ngIf = "sectionUnit.control == 0"  [ngClass]="{'disabled-btn': assigned.length > 0}">{{labels.lblAddControlQuestion}}</button>
                        <button class="addNewBtn controlBtn d-sm-inline-flex" style="border:1px solid #1494f9; color: #1494f9;" color="primary"
                        [routerLink]="['/admin/trainings/control-question',sectionUnit.trainingId, sectionUnit.trainingSectionId,sectionUnit.id]" *ngIf = "sectionUnit.control > 0">{{labels.lblEditControlQuestion}}</button>
                        <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
                        <mat-menu #settingsMenu="matMenu" class="mMenu">
                            <button mat-menu-item class="menuButton settingsMenuItem" (click)="editUnit(sectionUnit)">
                                <mat-icon style="color: #8293c6;">edit</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                            </button>
                            <button mat-menu-item class="menuButton settingsMenuItem" (click)="deleteUnit(sectionUnit)" [disabled] = "assigned.length > 0">
                                <mat-icon style="color: #8293c6;">delete</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                            </button>
                        </mat-menu>
                        <!-- mobile -->
                        <!-- <button class="editBtn d-block d-sm-none mobBtn" (click)="editUnit(sectionUnit)">
                            {{labels.lblEdit}}
                        </button>
                        <button class="deleteBtn d-block d-sm-none mobBtn" (click)="deleteUnit(sectionUnit)">
                            {{labels.lblDelete}}
                        </button>
                        <button class="addNewBtn d-block d-sm-none mobBtn" color="primary"
                            (click)="openModalForControlQuestion(sectionUnit)">{{labels.lblControlQuestion}}</button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragDisabled]="value"
                    [cdkDragData]="row"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    <div fxFlex="5"></div>
</div>