<app-admin-spinner *ngIf="labels == null || departments == null || dataSource == null || data == null || t == null">
</app-admin-spinner>
<div class="row userInfoCard" style="margin: auto">
    <div class="col-12 col-lg-11" style="margin: auto; margin-bottom: 15px;">
        <div class="trainingCard">
            <mat-card style="padding: 20px;">
                <div style="width: 100%; text-align: center">
                    <img mat-card-image src={{data.profileImagePath}}
                        style="width: 125px; object-fit: cover; border:1px solid transparent; border-radius: 50%; height: 125px; margin: auto;">
                </div>
                <mat-card-content>
                    <div class="row info">
                        <div class="col-12 col-lg-2">
                            <h3 style="text-decoration: underline;">{{labels.lblFirstName}}: </h3>
                        </div>
                        <div class="col-12 col-lg-4 text-left">
                            <h3 style="color: #9dacd9">{{data.firstName}}</h3>
                        </div>
                        <div class="col-12 col-lg-2">
                            <h3 style="text-decoration: underline;">{{labels.lblLastName}}: </h3>
                        </div>
                        <div class="col-12 col-lg-4 text-left">
                            <h3 style="color: #9dacd9">{{data.lastName}}</h3>
                        </div>
                    </div>
                    <div class="row info">
                        <div class="col-12 col-lg-2">
                            <h3 style="text-decoration: underline;">{{labels.lblUserName}}: </h3>
                        </div>
                        <div class="col-12 col-lg-4 text-left">
                            <h3 style="color: #9dacd9">{{data.userName}}</h3>
                        </div>
                        <div class="col-12 col-lg-2">
                            <h3 style="text-decoration: underline;">{{labels.lblEmail}}: </h3>
                        </div>
                        <div class="col-12 col-lg-4 text-left">
                            <h3 style="color: #9dacd9">{{data.email}}</h3>
                        </div>
                    </div>
                    <div class="row info">
                        <div class="col-12 col-lg-2">
                            <h3 style="text-decoration: underline;">{{labels.lblSector}}: </h3>
                        </div>
                        <div class="col-12 col-lg-4 text-left">
                            <h3 style="color: #9dacd9">{{data.departmentName}}</h3>
                        </div>
                        <div class="col-12 col-lg-2">
                            <h3 style="text-decoration: underline;">{{labels.lblPosition}}: </h3>
                        </div>
                        <div class="col-12 col-lg-4 text-left">
                            <h3 style="color: #9dacd9">{{data.position}}</h3>
                        </div>
                    </div>

                    <hr>
                    <div class="row info">
                        <div class="col-12 col-lg-5">
                            <h3 style="text-decoration: underline;">{{labels.lblGenericWorkplace}}: </h3>
                        </div>
                        <div class="col-12 col-lg-7">
                            <h3 style="color: #9dacd9">{{data.positionName}}</h3>
                        </div>
                    </div>
                    <div class="row info" *ngIf="data.description">
                        <div class="col-12 col-lg-5">
                            <h3 style="text-decoration: underline;">{{labels.lblDescription}}: </h3>
                        </div>
                        <div class="col-12 col-lg-7">
                            <h3 style="color: #9dacd9">{{data.description}}</h3>
                        </div>
                    </div>
                    <div class="row info" *ngIf="data.phoneNumber">
                        <div class="col-12 col-lg-5">
                            <h3 style="text-decoration: underline;">{{labels.lblPhoneNumber}}: </h3>
                        </div>
                        <div class="col-12 col-lg-7">
                            <h3 style="color: #9dacd9">{{data.phoneNumber}}</h3>
                        </div>
                    </div>
                    <div class="row info">
                        <div class="col-12 col-lg-5">
                        </div>
                        <div class="col-12 col-lg-7">
                            <button class="tableBtn" mat-raised-button style=" float: right;"
                                (click)="openEditDialog(data)">{{labels.lblEditUser}}</button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>


<div fxLayout="row" class="table1" fxLayoutAlign="space-between center" fxLayoutGap="20px">
    <div fxFlex="5"></div>
    <div fxFlex="90">
        <!-- <mat-card cdkDrag>
              <div fxLayout="row" fxFlex="100">
                  <mat-card-title fxFlex="90">{{labels.lblTrainings}}</mat-card-title>
                    <mat-card-content fxFlex="10" fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                      <button class="tableBtn" mat-raised-button (click)="openInsertDialog()">{{labels.lblAddTraining}}</button>
                
                    </mat-card-content>
                </div>
          </mat-card> -->
        <div class="mat-elevation-z8">
            <div class="row header">
                <div class="col-12 col-lg-7 text-left">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb" style="background-color: #182038; font-size: 16px;">
                            <li class="breadcrumb-item"><a style="color: #3e4b71" routerLinkActive="list-item-active"
                                    routerLink="/admin/users">{{labels.lblUsers}}</a></li>
                            <li class="breadcrumb-item"><a style="color: #8293c6">{{data.firstName}}
                                    {{data.lastName}}</a></li>
                        </ol>
                    </nav>
                </div>
                <div class="col-12 col-lg-5 text-right">
                    <button class="tableBtn assignTrainingBtn" mat-raised-button
                        (click)="openUserTrainingsDialog(data)">{{labels.lblAssignTraining}}</button>
                </div>
                <div class="col-10 text-left search1">
                    <mat-form-field class="search" appearance="none" fxFlex="40%">
                        <span>
                            <mat-icon class="searchIcon" [inline]="true">search</mat-icon>
                        </span><span><input matInput type="text" style="width: 70%"
                                (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
                    </mat-form-field>
                </div>
            </div>
            <table matSortDisableClear="true" mat-table [dataSource]="dataSource" matSort>

                <!-- userName Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblETrainingName}}</th>
                    <td mat-cell data-toggle="tooltip" data-placement="top" title={{training.name}}
                        *matCellDef="let training" [routerLink]="['/admin/training-details',training.id]"
                        style="cursor: pointer;" class="trainingName"> {{training.name.substring(0,21)}}<span
                            *ngIf="training.name.length > 21">...</span> </td>
                </ng-container>

                <!-- firstName Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblDescription}}
                    </th>
                    <td mat-cell data-toggle="tooltip" data-placement="top" title={{training.description}}
                        *matCellDef="let training" class="hideColumn"> {{training.description.substring(0,21)}}<span
                            *ngIf="training.description.length > 21">...</span></td>
                </ng-container>

                <!--Actions column-->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
                    <td mat-cell *matCellDef="let training">
                        <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="openEditDialog(training)">
                          {{labels.lblEdit}}
                        </button>
                        <button class="deleteBtn d-none d-sm-inline-flex" (click)="openDeleteDialog(training)">
                          {{labels.lblDelete}}
                        </button> -->
                        <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
                        <mat-menu #settingsMenu="matMenu" class="mMenu">
                            <button mat-menu-item class="menuButton settingsMenuItem"
                                (click)="openDeleteDialog(training, data)">
                                <mat-icon style="color: #8293c6;">delete</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                            </button>
                            <!-- <button mat-menu-item class="menuButton settingsMenuItem"
                                (click)="openRestartDialog(training, data)">
                                <mat-icon style="color: #8293c6;">refresh</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblRestart}}</span>
                            </button> -->
                        </mat-menu>

                        <!-- mobile -->
                        <!-- <button class="deleteBtn d-block d-sm-none mobBtn" (click)="openDeleteDialog(training)">
                            {{labels.lblDelete}}
                        </button>
                        <button class="editBtn d-block d-sm-none mobBtn" color="primary"
                            [routerLink]="['training-detail',training.id]">
                            {{labels.lblSections}}
                        </button>
                        <button class="addNewBtn d-block d-sm-none mobBtn" color="basic" (click)="assignTest(training)">
                            Dodeli
                        </button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </div>

        <div class="mat-elevation-z8" style="margin-top: 30px;">
            <div class="row header">
                <div class="col-lg-7 text-left">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb" style="background-color: #182038; font-size: 16px;">
                            <li class="breadcrumb-item"><a style="color: #3e4b71" routerLinkActive="list-item-active"
                                    routerLink="/admin/users">{{labels.lblUsers}}</a></li>
                            <li class="breadcrumb-item"><a style="color: #8293c6">{{data.firstName}}
                                    {{data.lastName}}</a></li>
                        </ol>
                    </nav>
                </div>
                <div class="col-lg-5 text-right">
                    <button class="tableBtn assignTrainingBtn" mat-raised-button
                        (click)="openDocumentDialog()">{{labels.lblAddDocument}}</button>
                </div>
                <div class="col-10 text-left search1">
                    <mat-form-field class="search" appearance="none" fxFlex="40%">
                        <span>
                            <mat-icon class="searchIcon" [inline]="true">search</mat-icon>
                        </span><span><input matInput type="text" style="width: 70%"
                                (keyup)="doFilter1($event.target.value)" placeholder={{labels.lblSearch}}></span>
                    </mat-form-field>
                </div>
            </div>
            <table matSortDisableClear="true" mat-table [dataSource]="dataSource1" matSort>

                <!-- userName Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{labels.lblDocumentName}}</th>
                    <td mat-cell data-toggle="tooltip" data-placement="top" title={{document.name}}
                        *matCellDef="let document"> {{document.name.substring(0,21)}}<span
                            *ngIf="document.name.length > 21">...</span> </td>
                </ng-container>

                <!-- firstName Column -->
                <!-- <ng-container matColumnDef="path">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblDescription}}
                    </th>
                    <td mat-cell data-toggle="tooltip" data-placement="top" title={{training.description}}
                        *matCellDef="let training" class="hideColumn"> {{training.description.substring(0,21)}}<span
                            *ngIf="training.description.length > 21">...</span></td>
                </ng-container> -->

                <!--Actions column-->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
                    <td mat-cell *matCellDef="let document">
                        <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="openEditDialog(training)">
                          {{labels.lblEdit}}
                        </button>
                        <button class="deleteBtn d-none d-sm-inline-flex" (click)="openDeleteDialog(training)">
                          {{labels.lblDelete}}
                        </button> -->
                        <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
                        <mat-menu #settingsMenu="matMenu" class="mMenu">
                            <button mat-menu-item class="menuButton settingsMenuItem"
                                (click)="openDocumentDeleteDialog(document, data)">
                                <mat-icon style="color: #8293c6;">delete</mat-icon>
                                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                            </button>
                        </mat-menu>

                        <!-- mobile -->
                        <!-- <button class="deleteBtn d-block d-sm-none mobBtn" (click)="openDeleteDialog(training)">
                            {{labels.lblDelete}}
                        </button>
                        <button class="editBtn d-block d-sm-none mobBtn" color="primary"
                            [routerLink]="['training-detail',training.id]">
                            {{labels.lblSections}}
                        </button>
                        <button class="addNewBtn d-block d-sm-none mobBtn" color="basic" (click)="assignTest(training)">
                            Dodeli
                        </button> -->
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
    
    <div fxFlex="5"></div>
</div>