import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-insert-control-question',
  templateUrl: './insert-control-question.component.html',
  styleUrls: ['./insert-control-question.component.scss']
})
export class InsertControlQuestionComponent implements OnInit {
  @ViewChild('insertForm') insertForm: NgForm
  labels;
  labelsSub: Subscription;
  err: boolean
  unitId
  sectionId
  trainingId
  typeOfQuestion
  constructor(public dialogRef: MatDialogRef<InsertControlQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    public dialog: MatDialog,
    private router: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getLang()
    this.unitId = this.data.unitId
    this.sectionId = this.data.sectionId
    this.trainingId = this.data.trainingId
    console.log(this.unitId)
    console.log(this.sectionId)
    console.log(this.trainingId)
  }

  onNoClick() {
    this.dialogRef.close()
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  setTypeOfAnswer(number) {
    console.log(number)
    this.typeOfQuestion = number
  }

  onSubmit() {
    if (this.insertForm.value.questionText == "" || this.insertForm.value.type == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.dashboardService.insertControlQuestion({
        id: this.unitId,
        trainingSectionId: this.sectionId,
        trainingId: this.trainingId,
        text: this.insertForm.value.questionText,
        type: this.insertForm.value.type
      }).subscribe((response) => {
        console.log(response)
        this.snackBar.open(this.labels.lblControlQuestionAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
      })
    }
  }
}
