import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-insert-control-question-answer',
  templateUrl: './insert-control-question-answer.component.html',
  styleUrls: ['./insert-control-question-answer.component.scss']
})
export class InsertControlQuestionAnswerComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  err
  err1
  insertedAns
  dataSource1
  @ViewChild('insertForm1') insertForm1: NgForm
  constructor (public dialogRef: MatDialogRef<InsertControlQuestionAnswerComponent>,
  @Inject(MAT_DIALOG_DATA) public data,
  private dashboardService: DashboardService,
  public dialog: MatDialog,
  private router: ActivatedRoute,
  private _formBuilder: FormBuilder,
  private adminLanguageService: AdminLanguageService,
  public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getLang()
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  onNoClick() {
    this.dialogRef.close()
  }

  fetchAnswersForControlQuestion() {
    this.dashboardService
      .fetchAnswersForControlQuestion(this.data.trainingId, this.data.sectionId, this.data.unitId)
      .subscribe((response) => {this.dataSource1 = response
        console.log("IZ FUNKCIJE")
        console.log(this.dataSource1.length)
      })
  }

  submitAns() {
    console.log(this.data.dataSource)
    if (this.insertForm1.value.textAns1 == "") {
      this.err1 = true;
      this.err = false;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
     if(this.data.dataSource.length + 1 == 1){
      this.dashboardService.insertControlQuestionAnswer({
        con_id: this.data.unitId,
        sectionId: this.data.sectionId,
        trainingId: this.data.trainingId,
        textAns1: this.insertForm1.value.textAns1,
        correct: true,
        order: this.data.dataSource.length + 1
      }).subscribe((response) => {
        console.log(response)
        this.insertedAns = ""
        this.snackBar.open(this.labels.lblControlQuestionAnsAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
      })
     }else{
      this.dashboardService.insertControlQuestionAnswer({
        con_id: this.data.unitId,
        sectionId: this.data.sectionId,
        trainingId: this.data.trainingId,
        textAns1: this.insertForm1.value.textAns1,
        correct: false,
        order: this.data.dataSource.length + 1
      }).subscribe((response) => {
        console.log(response)
        this.insertedAns = ""
        this.snackBar.open(this.labels.lblControlQuestionAnsAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
      })
     }
    }
  }

  enterClick(){
    return false;
  }

}
