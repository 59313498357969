<mat-drawer-container>
    <mat-drawer-content>
        <app-user-spinner
            *ngIf="showSpinner == true || training == null || lang == null || user == null || labels == null || progress == null || status == null || trainer == null || comments == null">
            <mat-spinner class="spinner"></mat-spinner>
        </app-user-spinner>
        <div *ngIf="showSpinner == false" class="webContainer">
            <div class="row">
                <div class = "goToTrainingDetails" (click) = "goToHomePage()">
                    <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FbackIconWhite.png?alt=media&token=c4a80eba-6c17-4e0d-80a2-03151a41afbf" alt = "Back button image">
                     <span style = "vertical-align: middle; padding-left: 11px;">{{labels.lblTrainings}}</span>
                 </div>
                    <img *ngIf = "training.trngInfoImagePath" style = "width: 100%; max-height: 35vh;" src={{training.trngInfoImagePath}} alt = "Training info image">
            </div>
            <div class="row" style="width:95%; margin: auto; margin-top: 3%; padding: 21px; background-color:white;">
                <div class="col-6" style="text-align: left">
                    <h3 style="font-size: 35px;font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-weight: bold;">{{training.name}}
                    </h3>
                </div>
                <div class="col-6" style="text-align: right;">
                    <button 
                    [disabled]="this.testData?.isBlocked"
                    [class]="buttonStyle"
                        style="width: 45%; background-color: #49D14F; border-radius: 11px; color: white;" mat-button
                        class="mainBtn buttonContinue font" (click)="goToTraining(training, status)"><span
                            *ngIf="status == 1">{{labels.lblTrainingNotStarted.toUpperCase()}}</span>
                        <span *ngIf="status == 2">{{labels.lblTrainingStarted.toUpperCase()}}</span>
                        <span *ngIf="status == 3">{{labels.lblTrainingFinished.toUpperCase()}}</span></button>
                </div>
                <div class="col-12" style="margin-top: 3%;">
                    <div class="row">
                        <div *ngIf="!training.isTestOnly" class="col-3">
                            <div class = "row" style = "width: 100%; margin: auto;">
                                <div class = "col-5" [class.greyBckg]="status == 1" [class.navyBckg]="status == 2"
                                    [class.greenBckg]="status == 3"
                                    style="-webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                        border-radius: 20px; margin: auto; padding-top: 25px; padding-bottom: 20px;">
                                    <div *ngIf="status != 1"
                                        class="col-12"
                                        style="text-align: center;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;"  [style.color] = "status == 2 ? '#FFFFFF' : '#0E0D35'">
                                        {{labels.lblStatus}}
                                    </div>
                                    <div class="col-12" style="text-align: center; color: white;font-family: Lato, 'Alegreya Sans';">
                                        <span *ngIf="status == 1" (click)="goToTraining(training, status)">{{labels.lblStart.toUpperCase()}}</span>
                                        <span *ngIf="status == 2">{{labels.lblStarted.toUpperCase()}}</span>
                                        <span *ngIf="status == 3">{{labels.lblFinished.toUpperCase()}}</span>
                                        <div>{{unitsOrder}}/{{training.num_of_units}}</div>
                                    </div>
                                </div>
                                <div class = "col-2">

                                </div>
                                <div class = "col-5"
                                    style="background: #FFFFFF;
                        -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                        border-radius: 20px; margin: auto; padding-top: 25px; padding-bottom: 20px; padding-right: 10px; padding-left: 10px;">
                                    <div class="col-12"
                                        style="text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;">
                                        {{labels.lblNumOfSections}}
                                    </div>
                                    <div class="col-12" style="text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans';">
                                        {{training.sections.length}}
                                    </div>
                                </div>
                            </div>
                            <div class = "row" style = "width: 100%; margin: auto; margin-top: 5%;">
                                <div class = "col-5"
                                style="background: #FFFFFF;
                    -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                    border-radius: 20px; margin: auto; padding-top: 20px; padding-bottom: 20px; padding-right: 10px; padding-left: 10px;">
                                <div class="col-12"
                                    style="text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;">
                                    {{labels.lblNumOfUnits}}
                                </div>
                                <div class="col-12" style="text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans';">
                                    {{training.num_of_units}}
                                </div>
                            </div>
                            <div class = "col-2">

                            </div>
                            <div class = "col-5"
                                style="background: #FFFFFF;
                    -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                    border-radius: 20px; margin: auto; padding-top: 20px; padding-bottom: 20px; padding-right: 10px; padding-left: 10px;">
                                <div class="col-12"
                                    style="text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;">
                                    {{labels.lblduration}}
                                </div>
                                <div class="col-12" style="text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans';">
                                    {{training.duration}}
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-12">
                                    <h3 style="font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 20px; color: #120D26;">{{training.isTestOnly ? labels.aboutTest : labels.aboutTraining}}</h3>
                                </div>
                                <div class="col-12">
                                    <h5
                                        style="font-family: Lato, 'Alegreya Sans'; font-weight: normal; font-size: 16.5px; color: #0E0D35; opacity: 0.5;">
                                        {{training.description}}</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                        <div class="row" style = "width: 100%; margin-top: 2.5%;">
                            <div class="col-6" style="background: #FFFFFF;
                    -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                    border-radius: 24px; margin: auto; padding: 10px;">
                                <div class="row">
                                    <div class="col-xs-12 col-md-12 col-xl-6"
                                        style="display: flex; text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: normal; font-size: 14px; border-right: 1px solid #F1F1F1;">
                                        <div>
                                            <img style="width: 50px; border-radius: 11px;"
                                                src={{trainer?.profileImagePath}} alt = "Trainer profile image">
                                        </div>
                                        <div style="padding-left: 10px; white-space: break-spaces; text-align: left">
                                            {{trainer.firstName}} {{trainer.lastName}}
                                            <!-- <br> -->
                                            <!-- <span style="font-size: 12px; color: #799ACF">{{trainer.email}}</span> -->
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-12 col-xl-6"  style="padding: 15px; margin: auto; text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 13.5px;">
                                        {{training.isTestOnly ? labels.lblTrainingAuthorTest : labels.lblTrainingAuthor}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class = "col-3" style = "background: #FFFFFF;
                                   -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                    border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                                (click)="whatToDisplay('comments')">
                                    <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                        <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                            <img style = "width: 20px" *ngIf = "!clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGrey.png?alt=media&token=5ecdfd13-50ee-4818-9c02-f42ffbc86553" alt = "forum icon">
                                            <img style = "width: 20px" *ngIf = "clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGreen.png?alt=media&token=04fc5e1c-1b22-4058-a596-2e68720f61e6" alt = "forum icon">
                                        </div>
                                        <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                            <h5 [style.color] = "color1" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblComments}}</h5>
                                        </div>
                                    </div>
                                </div>
                                    <div class = "col-3" style = "background: #FFFFFF;
                                    -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                    border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                                    (click)="whatToDisplay('documents')">
                                        <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                            <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                                <img style = "width: 20px" *ngIf = "!clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGrey.png?alt=media&token=343bf774-b065-40a1-a0fe-d16055d6340f" alt = "Doc icon">
                                                <img style = "width: 20px" *ngIf = "clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGreen.png?alt=media&token=84ef2556-2cb3-4e92-a628-da016cf00441" alt = "doc icon">
                                            </div>
                                            <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                                <h5 [style.color] = "color2" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblDocuments}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!training.isTestOnly" class = "col-3" style = "background: #FFFFFF;
                                        -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                        border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                                        (click)="whatToDisplay('training')">
                                        <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                            <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                                <img style = "width: 20px" *ngIf = "!trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGrey.png?alt=media&token=67aa1708-b907-419c-b1e8-c51cb7d0c1f5" alt = "training icon">
                                                <img style = "width: 20px" *ngIf = "trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGreen.png?alt=media&token=64708943-302a-4b54-8138-72ecfc358422" alt = "Training icon">
                                            </div>
                                            <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                                <h5 [style.color] = "color3" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblTraining}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <!-- KOMENTARI -->
                <div class="row" [style.display]="displayComments"
                    style="width: 100%; margin: auto; margin-top: 3%; padding-top: 25px; border-top: 1px solid #E6E6E6;">
                    <div  style="width: 100%; margin: auto; margin-bottom: 15px;">
                        <div style="width: 100%; margin: auto; background: #FFFFFF;
                -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                border-radius: 16px;font-family: Lato, 'Alegreya Sans' !important;">
                            <form (ngSubmit)="submitComment()" #insertForm="ngForm" style="width: 100%; margin: auto">
                                <div style="width: 90%; display: inline-block;">
                                    <input matInput [ngModel]="user.id" name="userId" hidden>
                                    <input matInput [ngModel]="user.firstName" name="firstName" hidden>
                                    <input matInput [ngModel]="user.lastName" name="lastName" hidden>
                                    <input matInput [ngModel]="user.email" name="email" hidden>
                                    <mat-form-field appearance="none"
                                        style="width:100%; margin: auto; color: #0E0D35 !important;">
                                        <textarea matInput ngModel name="text" placeholder="{{labels.lblComment}}"
                                            style="color: #0E0D35 !important; font-family: Lato, 'Alegreya Sans' !important; padding-top: 5px !important;
                            padding-left: 10px !important; font-size: 17px;"></textarea>
                                    </mat-form-field>
                                </div>
                                <div style="width: 10%; display: inline-block; text-align: center">
                                    <img style="cursor: pointer; padding-bottom: 20px;" (click)="submitComment()"
                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FsendComment.png?alt=media&token=93006d25-1513-4601-bbd1-3e5d8f86f34f" alt = "Send comment icon">
                                </div>
                            </form>
                        </div>
                        <div class="comments" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                            <div class="comment font" *ngFor="let c of comments">
                                <img class="commentImg" style = "width: 50px; margin-top: 15px;" src={{c.imagePath}} alt="comment image">
                                <span class="headerText" style="display: inline-block; top: 4px; vertical-align: middle;">
                                    <div>
                                        <div class="col-12" style = "top: 5px;">
                                            {{c.fullName}}
                                        </div>
                                        <div class="col-12" style = "top: 3px;">
                                            <span
                                                style="color: #799ACF;font-family: Lato, 'Alegreya Sans'; font-size: 13.5px;">{{c.email}}</span>
                                        </div>
                                    </div>
                                </span>
                                <span style="float: right; margin: 7px; font-size: 12.5px">{{c.time}}</span><br>

                                <div style="margin: 10px; top: -11px; font-size: 15.5px; color: #0E0D35; opacity: 0.5;">
                                    {{c.text}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- DOKUMENTI -->
                <div class="row" [style.display]="displayDocuments" style = "width: 100%; margin: auto; margin-top: 3%; padding-top: 25px; border-top: 1px solid #E6E6E6;">
                    <div class="documents" >
                        <div class="row" style="margin: auto;">
                            <div class="col-6">
                                <mat-accordion class="example-headers-align" multi>
                                    <mat-expansion-panel class="expansionPanel" style = "padding: 0px;">
                                        <mat-expansion-panel-header style = "padding: 10px;">
                                            <div class="font1"><span
                                                    style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                                    {{labels.lblAdminDocs}}</span></div>
                                        </mat-expansion-panel-header>
                                        <div class="document" *ngIf="canDownloadTest && (training.testResults == true)">
                                            <div class="documentDiv">
                                                <span><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                <span class="aligned-with-icon font headerText">
                                                    {{labels.lblTestResults}}</span>
                                            </div>
                                            <button mat-button class="buttonDocument"
                                                (click)="downloadTestResults(training.id)"><img
                                                    src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "DOc icon"></button>
                                        </div>
                                        <div class="document"
                                            *ngIf="canDownloadSurvey && (training.surveyResults == true)">
                                            <div class="documentDiv">
                                                <span><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                <span class="aligned-with-icon font headerText">
                                                    {{labels.lblSurveyResults}} </span>
                                            </div>
                                            <button mat-button class="buttonDocument"
                                                (click)="downloadSurveyResults(training.id)"><img
                                                    src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></button>
                                        </div>
                                        <div class="document"
                                            *ngIf="canDownloadStatement && (training.statement == true)">
                                            <div class="documentDiv">
                                                <span><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                <span class="aligned-with-icon font headerText"> {{labels.lblStatement}}
                                                </span>
                                            </div>
                                            <button mat-button class="buttonDocument"
                                                (click)="downloadStatement(training.id)"><img
                                                    src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></button>
                                        </div>
                                        <div style="margin-top: 15px; margin-left: 7px;"
                                            *ngIf="(canDownloadStatement == false) && (canDownloadSurvey == false) && (canDownloadTest == false)">
                                            <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}}
                                            </span>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div class="col-6">
                                <mat-accordion class="example-headers-align" multi>
                                    <mat-expansion-panel class="expansionPanel" style = "padding: 0px;">
                                        <mat-expansion-panel-header style = "padding: 10px;">
                                            <div class="font1"><span
                                                    style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                                    {{labels.lblTrainingDocs}}</span></div>
                                        </mat-expansion-panel-header>

                                        <div class="document" *ngFor="let document of trainingDocuments">
                                            <div class="documentDiv">
                                                <!-- <mat-icon style="color: #0E0D35; vertical-align: middle;"> attach_file</mat-icon> -->
                                                <span><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                <span class="aligned-with-icon font headerText1">
                                                    {{document.sectionName}}/{{document.unitName}} </span>
                                            </div>
                                            <a class="link" target="_blank" href={{document.docPath}}
                                                cdkFocusInitial><img
                                                    src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></a>
                                        </div>
                                        <div style="margin-top: 15px; margin-left: 7px;"
                                            *ngIf="trainingDocuments.length == 0">
                                            <span class="aligned-with-icon font headerText1"> {{labels.lblNoDocuments}}
                                            </span>
                                        </div>

                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div class="col-6" style="margin-top: 3%;">
                                <mat-accordion class="example-headers-align" multi>
                                    <mat-expansion-panel class="expansionPanel" style = "padding: 0px;">
                                        <mat-expansion-panel-header style = "padding: 10px;">
                                            <div class="font1"><span
                                                    style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                                    {{labels.lblAdditionalDocs}}</span></div>
                                        </mat-expansion-panel-header>

                                        <div>
                                            <div class="document" *ngFor="let document of userDocuments">
                                                <div class="documentDiv">
                                                    <span><img
                                                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                    <span class="aligned-with-icon font headerText"> {{document.name}}
                                                    </span>
                                                </div>
                                                <a class="link" target="_blank" href={{document.path}}
                                                    cdkFocusInitial><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></a>
                                            </div>
                                            <div style="margin-top: 15px; margin-left: 7px;"
                                                *ngIf="userDocuments.length == 0">
                                                <span class="aligned-with-icon font headerText1">
                                                    {{labels.lblNoDocuments}} </span>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>

                    </div>
                </div>

                <!--OBLASTI -->
                <div class = "row" style = "width: 100%; margin: auto; margin-top: 3%; padding-top: 25px; border-top: 1px solid #E6E6E6;">
                    <div *ngFor="let section of training.sections" style = "width: 100%;"  [style.display]="displaySections">
                        <div class = "col-12 col-sm-6">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel style="margin-top: 10px; padding: 0px;" class = "expansionPanel">
                                    <mat-expansion-panel-header style = "padding: 10px;">
                                        <div class="sectionName font"><span
                                                style="display:table-cell; padding-left: 10px; line-height: 30px;">
                                                {{section.name}}</span></div>
                                    </mat-expansion-panel-header>
                                    <div *ngFor="let unit of section.units"
                                        class="unitName font"style = "padding: 15px;">
                                        <span class = "navyColor"
                                            style = "width: 80%; vertical-align: middle; margin: 10px; float: left;">
                                            <span style = "vertical-align: bottom;">
                                                <img style = "width: 20px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FplayCircleIcon.png?alt=media&token=f5bd6607-1f38-4c64-b713-701237a7ef9a" alt = "Unit icon">
                                            </span>
                                            {{unit.name}}</span>
                                        <span style = "margin: 10px; float: right;">
                                            <img style = "width: 20px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FunitIconNavy.png?alt=media&token=d93e2985-cb16-4d00-a52f-7d8b1d2e38be" alt = "unit icon">
                                        </span>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- mobile -->
        <div *ngIf="showSpinner == false" class="mobContainer">
            <div class="container2">
                <div class="info">
                    <div class="row">
                        <div class="col-11" style="margin: auto; text-align: center">
                            <img style="width: 100%; height: 100%;" src={{training.imagePath}} alt = "Training image">
                        </div>
                    </div>
                    <div class="row"
                        style="width:95%; margin: auto; margin-top: 3%; padding: 21px; padding-left: 0px; background-color:white;">
                        <div class="col-12" style="text-align: left">
                            <h3 style="font-size: 35px;font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-weight: bold;">
                                {{training.name}}</h3>
                        </div>
                    </div>
                    <div *ngIf="!training.isTestOnly" class="col-12">
                        <div style="display: flex;">
                            <div [class.greyBckg]="status == 1" [class.navyBckg]="status == 2"
                                [class.greenBckg]="status == 3" style="-webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                        border-radius: 20px; margin: auto; padding-top: 20px; padding-bottom: 20px;">
                                <div *ngIf="status != 1" [style.color] = "status == 2 ? '#FFFFFF' : '#0E0D35'" class="col-12"
                                    style="text-align: center;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;">
                                    {{labels.lblStatus}}
                                </div>
                                <div class="col-12" style="text-align: center; color: white;font-family: Lato, 'Alegreya Sans';">
                                    <span *ngIf="status == 1">{{labels.lblStart.toUpperCase()}}</span>
                                    <span *ngIf="status == 2">{{labels.lblStarted.toUpperCase()}}</span>
                                    <span *ngIf="status == 3">{{labels.lblFinished.toUpperCase()}}</span>
                                </div>
                            </div>
                            <div style="background: #FFFFFF;
                        -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                        border-radius: 20px; margin: auto; padding-top: 20px; padding-bottom: 20px;">
                                <div class="col-12"
                                    style="text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;">
                                    {{labels.lblNumOfSections}}
                                </div>
                                <div class="col-12" style="text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans';">
                                    {{training.sections.length}}
                                </div>
                            </div>
                            <div style="background: #FFFFFF;
                        -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                        border-radius: 20px; margin: auto; padding-top: 20px; padding-bottom: 20px;">
                                <div class="col-12"
                                    style="text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;">
                                    {{labels.lblNumOfUnits}}
                                </div>
                                <div class="col-12" style="text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans';">
                                    {{training.num_of_units}}
                                </div>
                            </div>
                            <div style="background: #FFFFFF;
                        -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                        border-radius: 20px; margin: auto; padding-top: 20px; padding-bottom: 20px;">
                                <div class="col-12"
                                    style="text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 14px;">
                                    {{labels.lblduration}}
                                </div>
                                <div class="col-12" style="text-align: center; color: #799ACF;font-family: Lato, 'Alegreya Sans';">
                                    {{training.duration}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 7%; padding: 10px;">
                        <div class="col-12">
                            <h3 style="font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 20px; color: #120D26;">{{training.isTestOnly ? labels.aboutTest : labels.aboutTraining}}
                            </h3>
                        </div>
                        <div class="col-12">
                            <h5
                                style="font-family: Lato, 'Alegreya Sans'; font-weight: normal; font-size: 16.5px; color: #0E0D35; opacity: 0.5;">
                                {{training.description}}</h5>
                        </div>
                    </div>

                    <div class="row" style="width:100%; margin: auto; padding: 10px;">
                        <div class="col-12" style="background: #FFFFFF;
                    -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                    border-radius: 24px; margin: auto; margin-top: 7%; margin-bottom: 7%; padding: 10px;">
                            <div class="row">
                                <div class="col-xs-12 col-lg-6"
                                    style="display: flex; text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: normal; font-size: 14px; border-right: 1px solid #F1F1F1;">
                                    <div>
                                        <img style="width: 50px; border-radius: 11px;" src={{trainer.profileImagePath}} alt = "Trainer image">
                                    </div>
                                    <div style="padding-left: 10px; text-align: left; font-size: 14px;">
                                        {{trainer.firstName}} {{trainer.lastName}}
                                        <!-- <br> -->
                                        <!-- <span style="font-size: 12px; color: #799ACF">{{trainer.email}}</span> -->
                                    </div>
                                </div>
                                <div class="col-xs-12 col-lg-6">
                                    <h5
                                        style="padding: 15px; margin: auto; text-align: center; color: #0E0D35;font-family: Lato, 'Alegreya Sans'; font-weight: bold; font-size: 12px;">
                                        {{training.isTestOnly ? labels.lblTrainingAuthor : labels.lblTrainingAuthor}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-11" style="margin: auto;">
                            <button [class]="buttonStyle"
                                style="background-color: #49D14F; border-radius: 11px; color: white; padding: 7px;"
                                mat-button class="mainBtn buttonContinue font"
                                (click)="goToTraining(training, status)"><span
                                    *ngIf="status == 1">{{labels.lblTrainingNotStarted}}</span>
                                <span *ngIf="status == 2">{{labels.lblTrainingStarted}}</span>
                                <span *ngIf="status == 3">{{labels.lblTrainingFinished}}</span></button>
                        </div>
                    </div>

                    <div class="row" style="width: 100%; margin: auto; margin-top: 7%;">
                        <div class="col-12">
                            <div class="row" styke = "width: 100%; margin: auto;">
                                <div class = "col-3" style = "background: #FFFFFF;
                                -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                            (click)="whatToDisplay('comments')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGrey.png?alt=media&token=5ecdfd13-50ee-4818-9c02-f42ffbc86553" alt = "Comment icon">
                                        <img style = "width: 20px" *ngIf = "clickedComment" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FforumGreen.png?alt=media&token=04fc5e1c-1b22-4058-a596-2e68720f61e6" alt = "Comment icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center; padding-left: 0px; padding-right: 0px;">
                                        <h5 [style.color] = "color1" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblComments}}</h5>
                                    </div>
                                </div>
                            </div>
                                <div class = "col-3" style = "background: #FFFFFF;
                                -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                                (click)="whatToDisplay('documents')">
                                    <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                        <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                            <img style = "width: 20px" *ngIf = "!clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGrey.png?alt=media&token=343bf774-b065-40a1-a0fe-d16055d6340f" alt = "Doc icon">
                                            <img style = "width: 20px" *ngIf = "clickedCouments" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconGreen.png?alt=media&token=84ef2556-2cb3-4e92-a628-da016cf00441" alt = "Doc icon">
                                        </div>
                                        <div class = "col-12" style = "width: 100%; margin: auto; text-align: center; padding-left: 0px; padding-right: 0px;">
                                            <h5 [style.color] = "color2" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblDocuments}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class = "col-3" style = "background: #FFFFFF;
                                -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                                border-radius: 24px; margin: auto; padding: 10px; cursor: pointer"
                                (click)="whatToDisplay('training')">
                                <div class = "row" style = "width: 100%; margin: auto; text-align: center;">
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <img style = "width: 20px" *ngIf = "!trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGrey.png?alt=media&token=67aa1708-b907-419c-b1e8-c51cb7d0c1f5" alt = "Training icon">
                                        <img style = "width: 20px" *ngIf = "trngClicked" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FtrngIconGreen.png?alt=media&token=64708943-302a-4b54-8138-72ecfc358422" alt = "Training icon">
                                    </div>
                                    <div class = "col-12" style = "width: 100%; margin: auto; text-align: center;">
                                        <h5 [style.color] = "color3" style = "font-family: Lato, 'Alegreya Sans'; font-size: 15px;">{{labels.lblTraining}}</h5>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <!-- KOMENTARI -->
                    <div class="row"
                        style="width: 100%; margin: auto; margin-top: 3%; padding-top: 25px; border-top: 1px solid #E6E6E6;">
                        <div [style.display]="displayComments" style="width: 100%; margin: auto; margin-bottom: 15px;">
                            <div style="width: 100%; margin: auto; background: #FFFFFF;
                -webkit-box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07); box-shadow: 0px 8px 25px rgba(83, 89, 144, 0.07);
                border-radius: 16px;font-family: Lato, 'Alegreya Sans' !important;">
                                <form (ngSubmit)="submitComment()" #insertForm="ngForm"
                                    style="width: 100%; margin: auto">
                                    <div style="width: 90%; display: inline-block;">
                                        <input matInput [ngModel]="user.id" name="userId" hidden>
                                        <input matInput [ngModel]="user.firstName" name="firstName" hidden>
                                        <input matInput [ngModel]="user.lastName" name="lastName" hidden>
                                        <input matInput [ngModel]="user.email" name="email" hidden>
                                        <mat-form-field appearance="none"
                                            style="width:100%; margin: auto; color: #0E0D35 !important; opacity: 0.5">
                                            <textarea matInput ngModel name="text" placeholder="{{labels.lblComment}}"
                                                style="color: #0E0D35 !important; opacity: 0.5; font-family: Lato, 'Alegreya Sans' !important; padding-top: 5px !important;
                            padding-left: 10px !important;"></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div style="width: 10%; display: inline-block; text-align: center">
                                        <img style="cursor: pointer; padding-bottom: 20px;" (click)="submitComment()"
                                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FsendComment.png?alt=media&token=93006d25-1513-4601-bbd1-3e5d8f86f34f" alt = "Comment icon">
                                    </div>
                                </form>
                            </div>
                            <div class="comments" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                                <div class="comment font" *ngFor="let c of comments">
                                    <img class="commentImg" src={{c.imagePath}} alt="Comment icon">
                                    <span class="headerText" style="display: inline-block; top: 4px">
                                        <div class="row">
                                            <div class="col-12">
                                                {{c.fullName}}
                                            </div>
                                            <div class="col-12">
                                                <span
                                                    style="color: #799ACF;font-family: Lato, 'Alegreya Sans'; font-size: 13.5px;">{{c.email}}</span>
                                            </div>
                                        </div>
                                    </span>
                                    <span style="float: right; margin:7px">{{c.time}}</span><br>

                                    <div
                                        style="margin: 10px; top: -11px; font-size: 15.5px; color: #0E0D35; opacity: 0.5;">
                                        {{c.text}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- DOKUMENTI -->
                    <div class="row" style="margin: auto;">
                        <div class="documents" [style.display]="displayDocuments">
                            <div class="row" style="margin: auto;">
                                <div class="col-11" style="margin: auto;">
                                    <mat-accordion class="example-headers-align" multi>
                                        <mat-expansion-panel class="expansionPanel">
                                            <mat-expansion-panel-header>
                                                <div class="font1"><span
                                                        style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                                        {{labels.lblAdminDocs}}</span></div>
                                            </mat-expansion-panel-header>
                                            <div class="document"
                                                *ngIf="canDownloadTest && (training.testResults == true)">
                                                <div class="documentDiv">
                                                    <span><img
                                                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                    <span class="aligned-with-icon font headerText">
                                                        {{labels.lblTestResults}}</span>
                                                </div>
                                                <button mat-button class="buttonDocument"
                                                    (click)="downloadTestResults(training.id)"><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "DOc icon"></button>
                                            </div>
                                            <div class="document"
                                                *ngIf="canDownloadSurvey && (training.surveyResults == true)">
                                                <div class="documentDiv">
                                                    <span><img
                                                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                    <span class="aligned-with-icon font headerText">
                                                        {{labels.lblSurveyResults}} </span>
                                                </div>
                                                <button mat-button class="buttonDocument"
                                                    (click)="downloadSurveyResults(training.id)"><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></button>
                                            </div>
                                            <div class="document"
                                                *ngIf="canDownloadStatement && (training.statement == true)">
                                                <div class="documentDiv">
                                                    <span><img
                                                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                    <span class="aligned-with-icon font headerText">
                                                        {{labels.lblStatement}} </span>
                                                </div>
                                                <button mat-button class="buttonDocument"
                                                    (click)="downloadStatement(training.id)"><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></button>
                                            </div>
                                            <div style="margin-top: 15px; margin-left: 7px;"
                                                *ngIf="(canDownloadStatement == false) && (canDownloadSurvey == false) && (canDownloadTest == false)">
                                                <span class="aligned-with-icon font headerText1">
                                                    {{labels.lblNoDocuments}} </span>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                                <div class="col-11" style="margin: auto; margin-top: 5%;">
                                    <mat-accordion class="example-headers-align" multi>
                                        <mat-expansion-panel class="expansionPanel">
                                            <mat-expansion-panel-header>
                                                <div class="font1"><span
                                                        style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                                        {{labels.lblTrainingDocs}}</span></div>
                                            </mat-expansion-panel-header>

                                            <div class="document" *ngFor="let document of trainingDocuments">
                                                <div class="documentDiv">
                                                    <!-- <mat-icon style="color: #0E0D35; vertical-align: middle;"> attach_file</mat-icon> -->
                                                    <span><img
                                                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                    <span class="aligned-with-icon font headerText1">
                                                        {{document.sectionName}}/{{document.unitName}} </span>
                                                </div>
                                                <a class="link" target="_blank" href={{document.docPath}}
                                                    cdkFocusInitial><img
                                                        src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></a>
                                            </div>
                                            <div style="margin-top: 15px; margin-left: 7px;"
                                                *ngIf="trainingDocuments.length == 0">
                                                <span class="aligned-with-icon font headerText1">
                                                    {{labels.lblNoDocuments}} </span>
                                            </div>

                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                                <div class="col-11" style="margin: auto; margin-top: 5%;">
                                    <mat-accordion class="example-headers-align" multi>
                                        <mat-expansion-panel class="expansionPanel">
                                            <mat-expansion-panel-header>
                                                <div class="font1"><span
                                                        style="display:table-cell; vertical-align: middle; padding-left: 10px; font-size: 21px; font-weight: bold;">
                                                        {{labels.lblAdditionalDocs}}</span></div>
                                            </mat-expansion-panel-header>

                                            <div>
                                                <div class="document" *ngFor="let document of userDocuments">
                                                    <div class="documentDiv">
                                                        <span><img
                                                                src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FattachmentIcon.png?alt=media&token=f70e7e54-2df5-4fa1-8e19-53061f8e6b58" alt = "Attachment icon"></span>
                                                        <span class="aligned-with-icon font headerText">
                                                            {{document.name}} </span>
                                                    </div>
                                                    <a class="link" target="_blank" href={{document.path}}
                                                        cdkFocusInitial><img
                                                            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FdocIconBlue.png?alt=media&token=b5d5bff5-dd69-4b75-9389-ea2a1bea1247" alt = "Doc icon"></a>
                                                </div>
                                                <div style="margin-top: 15px; margin-left: 7px;"
                                                    *ngIf="userDocuments.length == 0">
                                                    <span class="aligned-with-icon font headerText1">
                                                        {{labels.lblNoDocuments}} </span>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                      <!--OBLASTI -->
                <div class = "row" style = "width: 100%; margin: auto; margin-top: 3%; padding-top: 25px; border-top: 1px solid #E6E6E6;">
                    <div *ngFor="let section of training.sections" style = "width: 100%;"  [style.display]="displaySections">
                        <div class = "col-12 col-sm-6">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel style="margin-top: 10px; padding-top: 5px; padding-bottom: 5px;" class = "expansionPanel">
                                    <mat-expansion-panel-header>
                                        <div class="sectionName font"><span
                                                style="display:table-cell; padding-left: 10px;">
                                                {{section.name}}</span></div>
                                    </mat-expansion-panel-header>
                                    <div *ngFor="let unit of section.units"
                                        class="unitName font"style = "padding: 15px;">
                                        <span class = "navyColor"
                                            style="width: 80%; vertical-align: middle; margin: 10px; float: left;">
                                            <span style = "vertical-align: bottom;">
                                                <img style = "width: 20px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FplayCircleIcon.png?alt=media&token=f5bd6607-1f38-4c64-b713-701237a7ef9a" alt = "Unit icon">
                                            </span>
                                            {{unit.name}}</span>
                                        <span style = "margin: 10px; float: right;">
                                            <img style = "width: 20px;" src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FunitIconNavy.png?alt=media&token=d93e2985-cb16-4d00-a52f-7d8b1d2e38be" alt = "Unit icon">
                                        </span>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>