import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop'
import { DeleteControlDialogComponent } from '../admin-training-section-detail/delete-control-dialog/delete-control-dialog.component';
import { DeleteControlQuestionDialogComponent } from '../admin-training-section-detail/delete-control-question-dialog/delete-control-question-dialog.component';
import { EditControlDialogComponent } from '../admin-training-section-detail/edit-control-dialog/edit-control-dialog.component';
import { EditControlQuestionDialogComponent } from '../admin-training-section-detail/edit-control-question-dialog/edit-control-question-dialog.component';
import { DashboardService } from '../dashboard.service';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import { InsertControlQuestionAnswerComponent } from './insert-control-question-answer/insert-control-question-answer.component';
import { InsertControlQuestionComponent } from './insert-control-question/insert-control-question.component';
import { ControlQuestionAnswers } from 'src/app/models/controlQuestionAnswers.model';

export interface controlQuestion {
  questionText: string
  type: number
  text: string
  random:boolean
}

@Component({
  selector: 'app-control-question',
  templateUrl: './control-question.component.html',
  styleUrls: ['./control-question.component.scss']
})
export class ControlQuestionComponent implements OnInit {
  id
  labels;
  labelsSub: Subscription;
  trainingId
  sectionId
  dataSource
  dataSource1
  question: controlQuestion
  unit
  training
  section
  value : boolean= true
  assigned
  questionAnswers
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort
  @ViewChild('table') table: MatTable<controlQuestion[]>
  @ViewChild('table1') table1: MatTable<controlQuestion[]>
  displayedColumns1: string[] = ['text', 'type','actions'];
  displayedColumns: string[] = ['order','text', 'correct', 'actions'];
  constructor(private dashboardService: DashboardService,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private userDashboarService: UserDashboardService,
    private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getLang()
    this.id = this.router.parent.snapshot.params['id']
    this.router.params.subscribe((params) => {
      this.id = params['id']
    })
    this.trainingId = this.router.parent.snapshot.params['trainingId']
    this.router.params.subscribe((params) => {
      this.trainingId = params['trainingId']
    })
    this.sectionId = this.router.parent.snapshot.params['sectionId']
    this.router.params.subscribe((params) => {
      this.sectionId = params['sectionId']
    })
    this.fetchControlQuestion()
    this.fetchAnswersForControlQuestion()
    this.getTraining(this.trainingId)
    this.getSectionName(this.trainingId, this.sectionId)
    this.getUnit(this.trainingId, this.sectionId, this.id)
    this.isTrainingAssigned()
    console.log(this.dataSource1)
  }

  isTrainingAssigned(){
    this.dashboardService.isTrainingAssigned(this.trainingId).subscribe((response)=>{
       if(response == null){
       this.assigned = []
     }else{
      this.assigned = response
     }
    })
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  getTraining(id) {
    return this.dashboardService.getTraining(id).subscribe((training) => {
      this.training = training;
      console.log(this.training)
    })
  }

  getSectionName(id, sectionId) {
    this.dashboardService.getSectionName(id, sectionId).subscribe((section) => {
      this.section = section
    })
  }

  getUnit(id, sectionId, unitId) {
    this.dashboardService.getUnitName(id, sectionId, unitId).subscribe((unit) => {
      this.unit = unit
      console.log(this.unit)
    })
  }

  isDisabled(answer) {
    if (this.question[0].type == 1 && answer.correct) {
      return true
    } else if (this.question[0].type == 2 && answer.correct) {
      var flag = this.canChangeStatus(answer)
      if (flag) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }

  }

  canChangeStatus(answer): boolean {
    let answers = this.dataSource1.data
    var cnt = 0
    for (let index = 0; index < answers.length; index++) {
      if (answers[index].correct) {
        cnt++
      }
    }
    if (cnt > 1) {
      return true;
    }
    return false
  }

  deleteDialog(answer) {
    let flag = this.canDeleteAnswer(answer)
    const dialogRef = this.dialog.open(DeleteControlDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, questionId: answer.questionId, id: answer.id, flag: flag }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource1 = null
      this.dashboardService
      .fetchAnswersForControlQuestion(this.trainingId, this.sectionId, this.id)
      .subscribe(response => {
        let answers = response as any[]
        answers = answers.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.questionAnswers = answers
        this.dataSource1 = new MatTableDataSource(answers)
        setTimeout(() => {
          this.dataSource1.sort = this.sort
        }, 2000);
        this.dataSource1.paginator = this.paginator;
        this.changeOrderAnswers1()
      })
      
    })

  }

  canDeleteAnswer(answer): boolean {
    let answers: ControlQuestionAnswers[] = this.dataSource1.data
    let answers1 = answers.filter(a => a.id != answer.id)
    for (let index = 0; index < answers1.length; index++) {
      if (answers1[index].correct) {
        return true;
      }
    }
    return false
  }

  editDialog(answer) {
    const dialogRef = this.dialog.open(EditControlDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, questionId: answer.questionId, id: answer.id, textAns1: answer.textAns1 }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource1 = null
      this.fetchAnswersForControlQuestion()
    })

  }

  fetchControlQuestion() {
    this.dashboardService
      .fetchControlQuestion(this.trainingId, this.sectionId, this.id)
      .subscribe((response) => {
        console.log("question")
        console.log(response)
        this.dataSource = response
        this.question = response as controlQuestion
        console.log(this.question)
      })
  }

  deleteQuestionDialog(question) {
    console.log(question)
    const dialogRef = this.dialog.open(DeleteControlQuestionDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, id: question.unitId }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
      this.dashboardService.fetchControlQuestion(this.trainingId, this.sectionId, question.unitId).subscribe(response => {
        this.dataSource = response
      })
      this.fetchControlQuestion()
      this.dataSource1 = null
      this.dashboardService
      .fetchAnswersForControlQuestion(this.trainingId, this.sectionId, this.id)
      .subscribe(response => {
        let answers = response as any[]
        answers = answers.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.questionAnswers = answers
        this.dataSource1 = new MatTableDataSource(answers)
        setTimeout(() => {
          this.dataSource1.sort = this.sort
        }, 2000);
        this.dataSource1.paginator = this.paginator;
        this.changeOrderAnswers1()
      })
    })
  }

  fetchAnswersForControlQuestion() {
    this.dashboardService
      .fetchAnswersForControlQuestion(this.trainingId, this.sectionId, this.id)
      .subscribe(response => {
        let answers = response as any[]
        console.log("answers")
        console.log(answers)
        answers = answers.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.questionAnswers = answers
        this.dataSource1 = new MatTableDataSource(answers)
        setTimeout(() => {
          this.dataSource1.sort = this.sort
        }, 200);
        this.dataSource1.paginator = this.paginator;
      })
  }

  editQuestionDialog(question) {
    console.log(question)
    const dialogRef = this.dialog.open(EditControlQuestionDialogComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, id: question.id, questionText: question.text }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
      this.fetchControlQuestion()
    })
  }

  changeStatus(answer) {
    if (this.question[0].type == 2) {
      this.dashboardService.changeControlAnswerStatus({ trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, questionId: this.question[0].id, id: answer.id, correct: !answer.correct })
        .subscribe((response) => {
          console.log(response)
          this.fetchAnswersForControlQuestion()
          this.fetchControlQuestion()
        })
      return
    }
    if (this.question[0].type == 1) {
      for (let index = 0; index < this.questionAnswers.length; index++) {
        console.log(this.questionAnswers[index])
        const element = this.questionAnswers[index];
        console.log(element)
        if (element.id === answer.id) {
          this.dashboardService.changeControlAnswerStatus({ trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, questionId: this.question[0].id, id: element.id, correct: !answer.correct }).subscribe((response) => {
            this.fetchAnswersForControlQuestion()
            this.fetchControlQuestion()
          })
        } else {
          this.dashboardService.changeControlAnswerStatus({ trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, questionId: this.question[0].id, id: element.id, correct: answer.correct }).subscribe((response) => {
            this.fetchAnswersForControlQuestion()
            this.fetchControlQuestion()
          })
        }

      }
      return
    }

  }

  insertQuestionDialog() {
    const dialogRef = this.dialog.open(InsertControlQuestionComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id }
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource = null
      this.dataSource1 = null
      this.fetchControlQuestion()
      this.fetchAnswersForControlQuestion()
    })
  }

  insertQuestionAnswerDialog() {
    const dialogRef = this.dialog.open(InsertControlQuestionAnswerComponent, {
      width: '500px',
      data: { trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, dataSource:this.dataSource1.data}
    })

    dialogRef.afterClosed().subscribe(response => {
      this.dataSource1 = null
      this.fetchAnswersForControlQuestion()
    })
  }

  changeRandomAnswers(){
    this.question[0].random = !this.question[0].random
  }

  showChangeButtonAnswers(){
    this.value = !this.value
  }

  changeOrderAnswers(){
    let answers = this.dataSource1.data
    if (this.question[0].random) {
       this.dashboardService.setControlQuestionAnswersOrder(true,  this.trainingId,  this.sectionId,  this.id,  this.question[0].id).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index];
        console.log(element)
        this.dashboardService.changeControlQuestionAnswersOrder({ answerId: element.id, trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, questionId: this.question[0].id, order: index + 1 }).subscribe((response) => {
          console.log(response)
          this.fetchAnswersForControlQuestion()
          this.dashboardService.setControlQuestionAnswersOrder(false,  this.trainingId,  this.sectionId,  this.id,  this.question[0].id).subscribe(response => {
            console.log(response)
          })

        }) 

      }
    }

    this.value = !this.value
  }

  changeOrderAnswers1(){
    let answers = this.dataSource1.data
    if (this.question[0].random) {
       this.dashboardService.setControlQuestionAnswersOrder(true,  this.trainingId,  this.sectionId,  this.id,  this.question[0].id).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index];
        console.log(element)
        this.dashboardService.changeControlQuestionAnswersOrder({ answerId: element.id, trainingId: this.trainingId, sectionId: this.sectionId, unitId: this.id, questionId: this.question[0].id, order: index + 1 }).subscribe((response) => {
          console.log(response)
          this.fetchAnswersForControlQuestion()
          this.dashboardService.setControlQuestionAnswersOrder(false,  this.trainingId,  this.sectionId,  this.id,  this.question[0].id).subscribe(response => {
            console.log(response)
          })

        }) 

      }
    }

  }

  drop1(event) {
    console.log('drop1')
    let answers = this.dataSource1.data
    console.log(answers)
    const prevIndex = answers.findIndex((d) => d === event.item.data)
    moveItemInArray(answers, prevIndex, event.currentIndex);
    this.dataSource1 = new MatTableDataSource(answers)
    this.table1.renderRows()
  }

}
