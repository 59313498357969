import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zoom-verify',
  templateUrl: './zoom-verify.component.html',
  styleUrls: ['./zoom-verify.component.scss']
})
export class ZoomVerifyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('zmmtg-root').style.display = 'none'
  }

}
