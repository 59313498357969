<mat-dialog-container>
    <mat-dialog-content>
        <div class="row" style="height: 95%;">
            <div class="row"
            style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
            <div class="col-11"></div>
            <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
                <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
            </div>
            </div>
            <div class="col-12 col-sm-6" style="text-align: center;">
                <h3 style = "color:#0E0D35;font-family: Lato, 'Alegreya Sans'; font-size: 33px;">{{labels.lblNotifications}}</h3>
            </div>
            <!-- <input matInput [ngModel] = "user.id" name="userId"> -->
            <div class="col-12 col-sm-7 notificationDiv" style="margin: auto;">
                <div *ngFor="let n of notifications">
                    <div class="col-12 notification asignTraining">
                        <div class="row">
                            <div class="col-8 text" style="word-break: break-all;">
                               <!-- <span>
                                   <img style = "width: 50px; height: 50px; border-radius: 11px;" src = {{n.profileImagePath}} alt = "notification image">
                               </span> -->
                               <div style = "display: inline-block; color: #0E0D35; font-weight: bold;font-family: Lato, 'Alegreya Sans'; margin-left: 15px; font-size: 12px; vertical-align: middle;">
                                   <div style = "text-align: left;">
                                    {{n.fullName}}
                                   </div>
                                   <div class = "showEmail" style = "color: #799ACF; font-weight: normal;">
                                    {{n.email}}
                                   </div>
                                </div>
                            </div>
                            <div class="col-4 date" style = "color: #0E0D35;font-family: Lato, 'Alegreya Sans';">
                                {{n.timestamp}}
                            </div>
                            <div class = "col-9" style = "font-family: Lato, 'Alegreya Sans'; color: #0E0D35; opacity: 0.5; font-size: 15px; text-align: left; padding-left: 0px;">
                                {{n.content}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-sm-7 notification randomNotification">
                        <div class="row">
                            <div class="col-7 text">
                                Neko random obavestenje.
                            </div>
                            <div class="col-4 date">
                                15/11/2021
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-12 col-sm-7 notification kasnjenje">
                        <div class="row">
                            <div class="col-7 text">
                                Kasnjenje sa treningom.
                            </div>
                            <div class="col-4 date">
                                11/07/2021
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class = "col-7">
                <button class = "loadMoreBtn" (click) = "loadMore()">
                    {{labels.lblLoadMore}}
                </button>
            </div>
        </div>
    </mat-dialog-content>
</mat-dialog-container>