import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import {Department} from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import {Unit} from './../../../models/unit.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  unitId:string
  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService,
    private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.unitId = this.data.id
    console.log("unit id")
    console.log(this.unitId)
    this.getLang()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  deleteUnit(){
    this.dashboardService.deleteUnit(this.unitId, this.data.trainingSectionId, this.data.trainingId).subscribe((response)=>{
      console.log(response)
      this.snackBar.open(this.labels.lblTrainingUnitDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
  }

  onNoClick(){
    this.dialogRef.close()
  }
}
