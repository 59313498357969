import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { BehaviorSubject, Observable, of } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'
import { User } from 'src/app/models/user.model'
import { Administrator } from 'src/app/models/administrator.model'
import { environment } from 'src/environments/environment'
import { LocalStorageService } from '../localStorage-service/local-storage.service'
import { UserLanguageService } from '../user-language-service/user-language.service'
import { Training } from 'src/app/models/training.model'
import { VideoScore } from 'src/app/models/videoScore.model'
import { Unit } from 'src/app/models/unit.model'
import { TrainingSection } from 'src/app/models/training-section.model'
import { MatDialog } from '@angular/material/dialog'
import { WrongLoginDataComponent } from '../../wrong-login-data/wrong-login-data.component'
import { WrongUserLoginDataComponent } from '../../wrong-user-login-data/wrong-user-login-data.component'
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { SignupMessageComponent } from '../../signup-message/signup-message.component'
import { TermsOfUseModalComponent } from '../../terms-of-use-modal/terms-of-use-modal.component'
import { Router } from '@angular/router'

interface LoginResponse {
  accessToken: string
  refreshToken: string
  user: User
  trainings: Training[]
  err: string,
}


interface trainingResponse {
  trainings: Training[]
  sections: TrainingSection[]
  units: Unit[]
  scores: VideoScore[]
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject(null)
  usersTrainings$ = new BehaviorSubject(null)
  usersScores$ = new BehaviorSubject(null)
  userIsOnline$ = new BehaviorSubject(false)

  constructor(private http: HttpClient,
    public db: AngularFirestore,
    private localStorageService: LocalStorageService, private userLanguageService: UserLanguageService,
    public dialog: MatDialog, private router: Router) { }

    login(form: { email: string; password: string }): Observable<LoginResponse> {
      console.log("OPPP")
      console.log(form)
      this.localStorageService.setItem("userName", form.email)
      this.localStorageService.setItem('pass', form.password)
      this.localStorageService.setItem('lang', "cirilica")
  
      return this.http.post<LoginResponse>(this.localStorageService.serversIpAddress + `users/login`, form)
        .pipe(
          tap(response => {
            console.log(response.user)
            if(response.err == "notAccepted") {
              let pass = this.localStorageService.getItem('pass')
              this.openTermsOfUseModal(response.user, pass)
            }else{
              this.user$.next(response.user)
              this.setToken('token', response.accessToken)
              this.setToken('refreshToken', response.refreshToken)
              this.usersTrainings$.next(response.trainings)
             }
          })
        )
    }

  loginWithGoogle(data): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.localStorageService.serversIpAddress + `users/loginWithGoogle`, data)
      .pipe(
        tap(response => {
          if (response.err == "greska") {
            console.log("greska prilikom prijaviljivanja")
            this.openSignupMessageComponent()
          } else {
            console.log("facebook/google login")
            this.user$.next(response.user)
            this.setToken('token', response.accessToken)
            this.setToken('refreshToken', response.refreshToken)
            this.usersTrainings$.next(response.trainings)
          }

        })
      )
  }

  openWrongLoginDataModal() {
    const dialogRef = this.dialog.open(WrongUserLoginDataComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe((admin) => {
      console.log('The dialog was closed');

    });
  }

  openTermsOfUseModal(user, pass) {
    this.localStorageService.setItem("usrId", user.userId);
  }

  openSignupMessageComponent() {
    const dialogRef = this.dialog.open(SignupMessageComponent, {
      width: '300px'
    });
    dialogRef.afterClosed().subscribe((admin) => {
      console.log('The dialog was closed');

    });
  }

  logout(): void {
    let docRef = this.db.firestore.collection('users').doc(this.user$.value.userId)
    docRef.update({
      online: false
    })
      
    this.localStorageService.clear()
    this.localStorageService.setItem("usrId", null)
    this.localStorageService.setItem("userName", null)
    this.localStorageService.setItem("pass", null)
    this.localStorageService.setItem("rememberUser", false)

    //this.localStorageService.clear()
    this.user$.next(null)
  }

  getCurrentUser(): Observable<User> {
    this.user$.pipe(
      switchMap(user => {
        // check if we already have user data
        if (user) {
          return of(user)
        }

        const token = this.localStorageService.getItem('token')
        // if there is token then fetch the current user
        if (token) {
          return this.fetchCurrentUser()
        }

        return of(null)
      })
    ).subscribe((err) => console.log(err))

    return this.user$
  }

  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>(this.localStorageService.serversIpAddress + `users/current-user`)
      .pipe(
        tap(user => {
          this.user$.next(user);
        })
      );
  }

  private setToken(key: string, token: string): void {
    this.localStorageService.setItem(key, token);
  }

  refreshToken(): Observable<{ accessToken: string; refreshToken: string }> {
    const refreshToken = this.localStorageService.getItem('refreshToken')
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      this.localStorageService.serversIpAddress + `users/refresh-token`,
      {
        refreshToken
      }).pipe(
        tap(response => {
          this.setToken('token', response.accessToken)
          this.setToken('refreshToken', response.refreshToken);
        })
      );
  }

  getCurrentUsersTrainings(): Observable<Training[]> {
    /* this.usersTrainings$.pipe(
      switchMap(trainings => {
        // check if we already have user data
        if (trainings) {
          return of(trainings)
        }

        const token = this.localStorageService.getItem('token')
        // if there is token then fetch the current user
        if (token) {
          return this.fetchCurrentUsersTrainings()
        }

        return of(null)
      })
    ).subscribe((err) => console.log(err)) */
    const token = this.localStorageService.getItem('token')
        // if there is token then fetch the current user
        if (token) {
          return this.fetchCurrentUsersTrainings()
        }

        return of(null)

    //return this.usersTrainings$
  }

  fetchCurrentUsersTrainings(): Observable<Training[]> {
    this.http.get<trainingResponse>(this.localStorageService.serversIpAddress + `users/current-users-trainings`).subscribe(
      response => {
        let trainings = response.trainings
        let sections = response.sections
        let units = response.units
        let scores = response.scores
        sections.forEach((s) => {
          s.units = response.units.filter((u) => u.trainingSectionId == s.id)
        })
        trainings.forEach((t) => {
          t.sections = response.sections.filter((s) => s.trainingId == t.id)
        })

        trainings.forEach((t) => {
          t.num_of_units = 0;
          let allUnits = units.filter((u) => u.trainingId == t.id)
          t.num_of_units = allUnits.length
        })

        let duration:number = 0
        trainings.forEach((t) => {
          duration = 0;
          let trUnits = units.filter((u) => u.trainingId == t.id)
          trUnits.forEach((u) => {
            if(u.videoDuration){
              let dur = u.videoDuration
              var a = dur.split(':');
              let min = parseInt(a[0])
              let sec = parseInt(a[1])
              let seconds = ((+min * 60) + (+sec*1));
              if(Number.isNaN(seconds) == false){
                duration += seconds
              }
            }
          })
          t.duration = this.formatTime(duration)
        })

        let date = new Date()
        let today = date.getTime()
        trainings.forEach((t) => {
         if((t.fromDateTimestamp != null) && (t.toDateTimestamp != null)){
          if((t.fromDateTimestamp < today) && (today < t.toDateTimestamp)){
            t.show = true
          }else{
            t.show = false
          }
         }else if((t.fromDateTimestamp != null) && (t.toDateTimestamp == null)){
          if(t.fromDateTimestamp < today){
            t.show = true
          }else{
            t.show = false
          }
         }else if((t.fromDateTimestamp == null) && (t.toDateTimestamp != null)){
          if(t.toDateTimestamp > today){
            t.show = true
          }else{
            t.show = false
          }
         }else{
           t.show = true
         }
          if(t.num_of_units == 0){
            t.progress = 0
          }else{
            let allScores = scores.filter((sc) => sc.trainingId == t.id)
            let score = allScores.filter((score) => score.numberOfVisits >= 1)
            let p = (score.length / t.num_of_units) * 100
            t.progress = Math.floor(p)
          }
        })

        let unitsWithDocs = []
        trainings.forEach((t) => {
          t.unitsWithDocuments = []
          let allUnits = units.filter((u) => u.trainingId == t.id)
          let allScores = scores.filter((sc) => sc.trainingId == t.id)
          let score = allScores.filter((score) => score.numberOfVisits >= 1)
          allUnits.forEach((u) => {
           if(u.documentPath != null){
             score.forEach((s) => {
               if(s.trainingUnitId == u.id){
                 let section = sections.filter((s) => s.id = u.trainingSectionId)
                 let filter = {sectionName: section[0].name, unitName: u.name, docPath: u.documentPath}
                 t.unitsWithDocuments.push(filter)
               }
             })
           }
          })
          console.log(t.unitsWithDocuments)
        })

        this.usersTrainings$.next(trainings)
        return trainings
      }

    )
    return this.usersTrainings$

  }

  formatTime(seconds) {
    console.log("duration")
    let h = Math.floor(seconds / 3600)
    let m = Math.floor(seconds / 60) % 60
    let s = Math.floor(seconds) % 60;
    let time = h + "h " +  m + "min"
    return String(time)
  }

  getCurrentUsersVideoScores(trId): Observable<VideoScore[]> {
    
        const token = this.localStorageService.getItem('token')
        // if there is token then fetch the current user scores
        if (token) {
          return this.fetchCurrentUsersVideoScores(trId)
        }
        else{
          return null
        }


    
  }

  fetchCurrentUsersVideoScores(trId): Observable<VideoScore[]> {
    this.http.get<VideoScore[]>(this.localStorageService.serversIpAddress + `videoScores/${trId}`).subscribe(
      scores => {
        this.usersScores$.next(scores);
        return this.usersScores$
      }
    )
    return this.usersScores$
  }

  updateUser(user:User){
    this.user$.next(user)
  }



}