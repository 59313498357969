import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import {Administrator} from 'src/app/models/administrator.model';
import { AuthService } from '../auth-service/auth.service';
import { LocalStorageService } from '../localStorage-service/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  user$: Observable<User>;
  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const userName = this.localStorageService.getItem("userName")
      const refreshToken = this.localStorageService.getItem("refreshToken")
      const token = this.localStorageService.getItem("token")

      if(!(userName && refreshToken && token)) {
        this.router.navigateByUrl('/');
      } else {
        return true
      }
}


}

