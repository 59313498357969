<div>
    <div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h3 mat-dialog-title>{{labels.lblAddTrainingToSector}} "{{data.name}}"</h3>
<div mat-dialog-content>
    <div *ngIf="trainings.length == 0">
        <h3 style="font-family: 'Prompt', sans-serif !important;">{{labels.lblNoTraining}}</h3>
    </div>
  <form (ngSubmit)="onSubmit()" #userTrainingsForm="ngForm">
      <input type="hidden" name="id" [ngModel]="data.id">
        <div class="col-sm-12" style="width: 100%; padding-left:0;">
            <mat-checkbox 
            ngModel
            name="user-training"
            [value]="training"
            *ngFor="let training of trainings"
            [checked]="true" 
            class="example-margin checkbox" 
            (change)="getTrainingId($event,training)"
            data-toggle="tooltip" data-placement="top" title={{training.name}}
            >
            {{training.name.substring(0,27)}}<span *ngIf="training.name.length > 27">...</span>
            </mat-checkbox >
        </div>
        <div class="col-sm-12" style="margin: auto;">
            <!-- <button type="button" *ngIf="trainings.length > 0" style="margin: auto" class="noBtn" mat-button (click)="onNoClick()">{{labels.lblNo}}</button> -->
            <button type="button" *ngIf="trainings.length == 0" class="noBtn" style="float:right; padding-right: 10px; padding-left: 10px; margin-right: 0px !important" mat-button (click)="onNoClick()">{{labels.lblOk}}</button>
            <button class="btn" *ngIf="trainings.length > 0" style="float:right; padding-right: 10px; padding-left: 10px; margin-right: 0px !important" cdkFocusInitial type="submit">{{labels.lblAdd}}</button>
        </div>
  </form>
</div>
