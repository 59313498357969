import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';
export interface DialogData{
  userId: string,
  eventId: string,
  eventTitle: string,
  eventDescription: string,
  eventDate: Date
}
@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  err: boolean
  @ViewChild('editForm') editForm: NgForm

  constructor(public dialogRef: MatDialogRef<EditEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private userDashboardService: UserDashboardService, private userLanguageService: UserLanguageService,
    public db: AngularFirestore, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.err = false
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  onNoClick(): void {
    this.dialogRef.close();
   }

   onSubmit(){
      console.log("pozvao onsubmit")
      if (this.editForm.value.title == "" || this.editForm.value.description == "" || this.editForm.value.date == "") {
        this.err = true;
        this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      } else {
        let data = {
          title: this.editForm.value.title,
          description: this.editForm.value.description,
          date: this.editForm.value.date
        }
        this.db.firestore.collection("users").doc(this.data.userId).collection("events").doc(this.data.eventId).update(data)
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblEventEdites, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
      }
    }

}
