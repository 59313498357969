import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { DashboardService } from '../dashboard.service'
import { BehaviorSubject, Subscription, Observable, of as observableOf } from 'rxjs'
import { User } from '../../models/user.model'
import { DataSource } from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './insert-dialog/insert-dialog.component';
import { Training } from './../../models/training.model'
import { ActivatedRoute, Router } from '@angular/router'
import { DeleteDialogComponent } from '././delete-dialog/delete-dialog.component'
import { TrainingSection } from 'src/app/models/training-section.model';
import { Unit } from './../../models/unit.model'
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Department } from 'src/app/models/department.model';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SectorTrainingsComponent } from './sector-trainings/sector-trainings.component';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from '../services/admin-auth-service/admin-auth.service';


@Component({
  selector: 'app-admin-department',
  templateUrl: './admin-department.component.html',
  styleUrls: ['./admin-department.component.scss']

})
export class AdminDepartmentComponent implements OnInit {


  displayedColumns: string[] = ['name', 'actions'];
  dataSource: Department[]
  labels;
  labelsSub: Subscription
  admin:Administrator
  adminSub:Subscription

  nestedTreeControl: NestedTreeControl<Department>;
  nestedDataSource: MatTreeNestedDataSource<Department>;
  nestedDataSource1 : MatTreeNestedDataSource<Department>;

  constructor(private adminAuthService: AdminAuthService, private dashboardService: DashboardService, private dialog: MatDialog, private adminLanguageService: AdminLanguageService, private router: Router) {
    this.nestedTreeControl = new NestedTreeControl<Department>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.nestedDataSource1 = new MatTreeNestedDataSource();
  }

  ngOnInit(): void {
    this.getAdmin()
    this.getLang()
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        this.fetchDepartments(this.admin.headAdmin)
      }, 100);
    });
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  fetchDepartments(headAdmin:boolean) {
    this.dashboardService.fetchDepartments(headAdmin, this.admin.id, this.admin.departmentId).subscribe((departments) => {
      this.nestedDataSource.data = departments
      this.nestedDataSource1.data = departments
    })
  }
  insertNewDepartment() {
    const dialogRef = this.dialog.open(InsertDialogComponent, {
      width: '500px',

    })
    dialogRef.afterClosed().subscribe((response) => {
      this.nestedDataSource1.data = null
      this.nestedDataSource.data = null
      setTimeout(() => {
        this.fetchDepartments(this.admin.headAdmin)
      }, 500);
    })

  }
  openDeleteDialog(department) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: department
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.nestedDataSource1.data = null
      this.nestedDataSource.data = null
      setTimeout(() => {
        this.fetchDepartments(this.admin.headAdmin)
      }, 500);
    })
  }
  openSectorTrainingsDialog(department) {
    const dialogRef = this.dialog.open(SectorTrainingsComponent, {
      width: '400px',
      data: department
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.nestedDataSource1.data = null
      this.nestedDataSource.data = null
      setTimeout(() => {
        this.fetchDepartments(this.admin.headAdmin)
      }, 500);
    })
  }
  openEditDialog(department) {

    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '500px',
      height: '500px',
      data: department
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.nestedDataSource1.data = null
      this.nestedDataSource.data = null
      setTimeout(() => {
        this.fetchDepartments(this.admin.headAdmin)
      }, 500);
    })
  }

  public doFilter = (value: string) => {
    console.log( this.nestedDataSource1.data.length)
    this.nestedDataSource1.data = this.nestedDataSource.data.filter((department) =>  department.name.toLocaleLowerCase().includes(value.trim().toLocaleLowerCase()) )
    console.log( this.nestedDataSource1.data.length)
  }

  sortChange(event) {
    if (event.direction == "asc") {
      this.nestedDataSource1.data = this.nestedDataSource1.data.sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    else {
      this.nestedDataSource1.data = this.nestedDataSource1.data.sort((a, b) => (a.name > b.name ? -1 : 1));
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }



  private _getChildren = (node: Department) => { return observableOf(node.children); };

  hasNestedChild = (_: number, nodeData: Department) => { return (nodeData.children.length > 0 ? true : false); };
}
