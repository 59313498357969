<app-user-spinner *ngIf="meeting == null || user == null || labels == null || test == null"></app-user-spinner>
<div class="container d-none d-sm-flex">
    <div class="unitsContainer">
        <div [style.background-color] = "backgroundColor" style=  "height:40%; text-align: center;">
            <div class="trainingName font">{{meeting.description}}</div>
            <div class="progresValue font" style = "font-size: 2.4vh; padding-top: 15px;">{{testProgress}}% {{labels.lblQuestionsCompleted}}</div>
            <div class="progresValue font" style = "font-size: 2.3vh; padding-top: 11px;">{{test.questions[index].order}}/{{test.questions.length}}</div>
        </div>
        <div class="progress" [style.width]="testProgress+'%'"></div>
        <div class="units">
        </div>
    </div>
    <div class="container2">
        <div class="testHeader font">
            {{test.name}}
            <cd-timer format="user" [countdown]="true" [startTime]="currentTime" (onTick)=onTick() (onComplete)="onTimeIsUp()" #basicTimer style="float: right; color: rgb(241,106,100);" *ngIf="test.durationTime != null">[minutes] : [seconds] </cd-timer>
        </div>
        <div >
            <div class="testQuestion font">
                <h3 class="questionText font" style="line-height: 40px;">
                    <span style="font-weight: bold;">{{test.questions[index].order}}. </span><span style="font-weight: bold;"> {{test.questions[index].text}}</span>
                    <button *ngIf="showVideoHintIcon && test.questions[index].videoHintPath" (click)="openVideoHintComponent()" class="help-btn" mat-raised-button [class]="buttons">{{labels.lblHelp}} <mat-icon>lightbulb</mat-icon></button>
                </h3>
                <div style="width: 300px; text-align: center; background: black; height: 200px;" *ngIf = "test.questions[index].imagePath">
                    <img style="width:100%; height: 100%; margin: auto; object-fit: contain;" src = {{test.questions[index].imagePath}} alt = "zoom test question image">
                </div>
                <h4 *ngIf="test.questions[index].type==2"><span style="color:rgb(241,106,100);">*{{labels.lblSeveralCorrectAnswers}}</span></h4>
                    <div class="answer" *ngFor="let answer of test.questions[index].answers" [class.answerSelectedEMS]='answer.selected && (user.companyId == 1)' [class.answerSelectedECG]='answer.selected && (user.companyId != 1)'>
                        <!-- <input type="checkbox" id="{{answer.id}}" [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);"/> {{answer.text}} -->
                        <mat-checkbox ngDefaultControl [value]="answer" class="checkBox " [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);">
                            <span style="font-size: 2vh; font-weight: bold; white-space: break-spaces;">{{answer.text}}</span>
                        </mat-checkbox>
                    </div>
            </div>
            <div class="buttons">
                <button mat-raised-button class="button" [class] = "buttons" style="float: left;" *ngIf="!(index==0)"
                    (click)="previousQuestion(test.questions[index].order)">
                    {{labels.lblBack}}
                </button>
                <button mat-raised-button class="button" [class] = "buttons" style="float: right;" *ngIf="(!(index==test.questions.length-1) && (showNextBtn == true))"
                    (click)="nextQuestion(test.questions[index].order)">
                    {{labels.lblNext}}
                </button>
                <button mat-raised-button class="button" [class] = "buttons" style="float: right;" *ngIf="(index==test.questions.length-1 && (showNextBtn == true))"
                    (click)="submit(test.questions[index].order, false)">
                    {{labels.lblSubmit}}
                </button>
            </div>
        </div>

    </div>
</div>

<!-- mobile -->
<div class="mobileContainer row">
    <div class="unitsContainer col-xs-12" style="margin:auto; width:100%;">
        <div [style.background-color] = "backgroundColor" style = "height:40%; text-align: center;">
            <div class="trainingName font">{{meeting.description}}</div>
            <div class="progresValue font">{{testProgress}}% {{labels.lblQuestionsCompleted}}</div>
        </div>
        <div class="progress" [style.width]="(index+1)/test.questions.length*100+'%'"></div>
    </div>
    <div class="container2 col-xs-12">
        <div class="testHeader font">
          <div class = "row" style="margin-bottom: 7%;">
            {{test.name}}
          </div>
            <div class = "row">
                <cd-timer format="user" [countdown]="true" [startTime]="currentTime" (onTick)=onTick() (onComplete)="onTimeIsUp()" #basicTimer style="float: right; color: rgb(241,106,100); margin: auto;" *ngIf="test.durationTime != null">[minutes] : [seconds] </cd-timer>
            </div>
        </div>
        <div class="testHeader font" style="padding-top: 10px;">
            {{labels.lblTest}}
        </div>
        <div class="testQuestion">
            <h3 class="questionText">
                <span style="font-weight: bold; line-height: 35px;">{{test.questions[index].text}}</span>
            </h3>
            <div style="width: 300px; text-align: center; background: black; height: 200px;" *ngIf = "test.questions[index].imagePath">
                <img style="width:100%; height: 100%; margin: auto; object-fit: contain;" src = {{test.questions[index].imagePath}} alt = "zoom test question image">
            </div>
            <h4 *ngIf="test.questions[index].type==2"><span style="color:rgb(241,106,100);">*{{labels.lblSeveralCorrectAnswers}}</span></h4>
                    <div class="answer" *ngFor="let answer of test.questions[index].answers" [class.answerSelectedEMS]='answer.selected && (user.companyId == 1)' [class.answerSelectedECG]='answer.selected && (user.companyId != 1)'>
                        <!-- <input type="checkbox" id="{{answer.id}}" [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);"/> {{answer.text}} -->
                        <mat-checkbox ngDefaultControl [value]="answer" class="checkBox " [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);">
                            <span style="font-size: 2vh; font-weight: bold; white-space: break-spaces;">{{answer.text}}</span>
                        </mat-checkbox>
                    </div>
        </div>
        <div class="buttons">
            <button mat-raised-button class="button1" [class] = "buttons" style="float: left;" *ngIf="!(index==0)"
                    (click)="previousQuestion(test.questions[index].order)">
                    {{labels.lblBack}}
                </button>
                <button mat-raised-button class="button1" [class] = "buttons" style="float: right;" *ngIf="(!(index==test.questions.length-1)) && (showNextBtn == true)"
                    (click)="nextQuestion(test.questions[index].order)">
                    {{labels.lblNext}}
                </button>
                <button mat-raised-button class="button1" [class] = "buttons" style="float: right;" *ngIf="(index==test.questions.length-1) && (showNextBtn == true)"
                    (click)="submit(test.questions[index].order, false)">
                    {{labels.lblSubmit}}
                </button>
        </div>
    </div>
</div>