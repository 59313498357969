<div>
    <div class="row"
        style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
        <div class="col-11"></div>
        <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
            <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
        </div>
    </div>
    <h2 mat-dialog-title><span *ngIf = "test.type == 1">{{labels.lblAssignTest}}</span>
        <span *ngIf = "test.type == 2">{{labels.lblAssignPretest}}</span>
        <span *ngIf = "test.type == 3">{{labels.lblAssignSurvey}}</span>
    </h2>
    <div mat-dialog-content>
        <div *ngIf="trainings.length == 0">
            <h3 style="font-family: 'Prompt', sans-serif !important;">{{labels.lblNoTraining}}</h3>
        </div>
        <form (ngSubmit)="onSubmit()" #trainingsTestForm="ngForm">
            <input type="hidden" name="id" [ngModel]="data.id">
            <div class="col-sm-12" style="width: 100%; padding-left:0;">
                <div *ngFor="let training of trainings">
                    <mat-checkbox ngModel name="user-training" [value]="user" 
                        [checked]="true" class="example-margin checkbox" (change)="getTrainingId($event,training)">
                        {{training.name}} 
                    </mat-checkbox>
                </div>
                <div style="margin: auto;">
                    <!-- <button type="button" *ngIf="trainings.length > 0" style="margin: auto" class="noBtn" mat-button (click)="onNoClick()">{{labels.lblNo}}</button> -->
                    <button type="button" *ngIf="trainings.length == 0" class="noBtn"
                        style="float:right; padding-right: 10px; padding-left: 10px; margin-right: 0px !important"
                        mat-button (click)="onNoClick()">{{labels.lblOk}}</button>
                    <button type="button" class="btn" *ngIf="trainings.length > 0"
                        style="float:right; padding-right: 10px; padding-left: 10px; margin-right: 0px !important"
                        mat-button (click) = "onSubmit()">{{labels.lblAdd}}</button>
                </div>
            </div>


        </form>
    </div>
