<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title><span *ngIf = "data.type == 1">{{labels.lblEditTest}}</span>
    <span *ngIf = "data.type == 2">{{labels.lblEditPretest}}</span>
    <span *ngIf = "data.type == 3">{{labels.lblEditSurvey}}</span>
     "{{data.name}}"</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #editForm="ngForm" style="height:350px;">
      <input type="hidden" name="id" [ngModel]='data.id'>
    <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label *ngIf="data.type == 1">{{labels.lblTestName}}</mat-label>
            <mat-label *ngIf="data.type == 2">{{labels.lblPretestName}}</mat-label>
            <mat-label *ngIf="data.type == 3">{{labels.lblSurveyName}}</mat-label>
            <input matInput [ngModel]="data.name" name="name" required>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none" *ngIf="data.type == 1" fxFlex="60%">
            <mat-label>{{labels.lblMinPercentage}}</mat-label>
            <input matInput [ngModel]="data.minPercentage" *ngIf = "assigned == 0" name="minPercentage" required>
            <div *ngIf = "assigned.length > 0">{{data.minPercentage}}</div>
            <input type="number" matInput [ngModel]="data.minPercentage" name="minPercentage" hidden>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-checkbox
        *ngIf = "data.type == 1"
        [checked]="isItChecked" 
        class="example-margin checkbox" 
        (change)="showDurationTime($event)"
        [disabled] = "assigned.length > 0"
        >
        {{labels.lblTestDuration}}  
        </mat-checkbox >
        <mat-form-field appearance="none" *ngIf="data.type == 1 && showDurTime" fxFlex="60%">
            <mat-label>{{labels.lblTestDuration}}</mat-label>
            <input matInput [ngModel]="data.durationTime" *ngIf = "assigned == 0" name="durationTime">
            <div *ngIf = "(assigned.length > 0) && (data.durationTime != null)">{{data.durationTime}}</div>
            <input matInput [ngModel]="data.durationTime" name="durationTime" hidden>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>

        <mat-checkbox
        class="example-margin checkbox"
        *ngIf="data.type == 1"
        [disabled]="assigned.length > 0"
        [checked]="data.required"
        (change)="data.required = !data.required"
        fxFlex="60%"
        name="required"
        >
            <mat-label>{{labels.lblRequiredTest}}</mat-label>
        </mat-checkbox>

        <mat-form-field
        appearance="none"
        fxFlex="60%"
        *ngIf="data.type == 1"
        >
            <mat-label>{{labels.lblNumofAttemptsTest}}</mat-label>
            <input matInput name="numberOfTestDrops" [ngModel]="data.numberOfTestDrops" *ngIf="assigned.length == 0"/>
            <input matInput name="numberOfTestDrops" ngModel="data.numberOfTestDrops" *ngIf="assigned.length > 0" hidden>
            <div *ngIf="assigned.length > 0">{{data.numberOfTestDrops}}</div>
        </mat-form-field>

        <mat-form-field appearance="none" fxFlex="60%">
            <mat-label>{{labels.lblType}}</mat-label>
            <!-- <mat-select [ngModel]="data.type" name='type' disabled>
                <mat-option value="1">Test</mat-option>
                <mat-option value="2">Pretest</mat-option>
                <mat-option value="3">{{labels.lblSurvey}}</mat-option>
            </mat-select> -->
            <input matInput [ngModel] = "data.type" name = "type" style="color: white" hidden>
            <div>
                <span *ngIf = "data.type == 1">{{labels.lblTest}}</span>
                <span *ngIf = "data.type == 2">{{labels.lblPretest}}</span>
                <span *ngIf = "data.type == 3">{{labels.lblSurvey}}</span>
            </div>
            <mat-error style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>

        <mat-form-field  appearance="none" fxFlex="60%">
            <mat-label *ngIf = "admin.isAdmin == true">{{labels.lblTrainer}}</mat-label>
            <h4 style="font-family: 'Prompt', sans-serif !important;" *ngIf = "admin.isAdmin == false">{{labels.lblTrainer}}</h4>
            <input *ngIf = "admin.isAdmin == false" matInput ngModel={{admin.id}} name = "trainerId" hidden>
            <input *ngIf = "(assigned.length > 0) || (admin.isAdmin == false)" matInput ngModel={{data.trainerId}} name = "trainerId" hidden>
            <div *ngIf = "(assigned.length > 0) || (admin.isAdmin == false)">{{trainer}}</div>
            <mat-select *ngIf = "(admin.isAdmin == true) && (assigned.length == 0)" [ngModel] = "data.trainerId" name='trainerId' #trainerId="ngModel" >
                <mat-option *ngFor = "let t of trainers" [value] = "t.id">{{t.firstName}} {{t.lastName}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div mat-dialog-actions>
            <!-- <button class="noBtn" mat-button (click)="onNoClick()" type="button">{{labels.lblNo}}</button> -->
            <button class="btn" cdkFocusInitial type="submit">{{labels.lblEdit}}</button>
        </div>
    </div>
  </form>
</div>



