import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-delete-control-dialog',
  templateUrl: './delete-control-dialog.component.html',
  styleUrls: ['./delete-control-dialog.component.scss']
})
export class DeleteControlDialogComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService:DashboardService,
    private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getLang()
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  deleteControlAns(){
   if(this.data.flag){
    this.dashboardService.deleteControlAns(this.data.trainingId, this.data.sectionId, this.data.unitId, this.data.questionId,this.data.id).subscribe((response)=>{
      console.log(response)
      this.snackBar.open(this.labels.lblControlQuestionAnsDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close()
    })
   }else{
    this.dialogRef.close()
    this.snackBar.open(this.labels.lblCantDeleteSelectedAnswer, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
   }
  }

  onNoClick(){
    this.dialogRef.close()
  }

}
