<app-admin-spinner *ngIf="users == null || trainings == null || labels == null || departments == null || downloading == true"></app-admin-spinner>
<div class="row" style="margin-bottom: 3vh;">
<div class="col-12 col-xl-5 usersDiv" style="position: relative; background-color: #182038; margin: auto; width:90%; padding-right:0px">
    <h2>{{labels.lblUsers}}</h2>
    <form #insertFormUsers="ngForm" (ngSubmit)="generateReportForUsers()">
        <div class="row">
            <div class="col-12 col-lg-5" style="text-align: left;">
                <h4>{{labels.lblReportType}}:</h4>
            </div>
            <div class="col-12 col-lg-6" >
                <mat-form-field appearance="none" style="color:#8293c6">
                    <mat-select ngModel name="type">
                        <mat-option value="1">
                            {{labels.lblSurvey}}
                        </mat-option>
                        <mat-option value="2">
                            {{labels.lblTestResults}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
           <div class="col-12 col-lg-5" style="text-align: left;">
            <h4>{{labels.lblTrainings}}</h4>
           </div>
           <div class="col-12 col-lg-6">
            <mat-form-field appearance="none" style="color:#8293c6">
                <mat-select ngModel name="trainingId">
                    <mat-option *ngFor="let t of trainings" [value]="t">
                        {{t.name}}
                    </mat-option>
                    </mat-select>
            </mat-form-field>
           </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5" style="text-align: left;">
                <h4>{{labels.lblUsers}}</h4>
            </div>
            <div class="col-12 col-lg-6" style="width:100%; float: left">
                <mat-form-field appearance="none" style="color:#8293c6">
                    <mat-select ngModel name="userId" [formControl]="usersForReport" multiple>
                        <mat-option *ngFor="let user of users" [value]="user.id">
                          {{user.firstName}} {{user.lastName}}
                        </mat-option>
                      </mat-select>
                      
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5" style="text-align: left;">
               <h4 class="kalendarH">{{labels.lblFrom}} - {{labels.lblTo}}</h4>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row"  style="float:right">
                    <div class="col-12 col-lg-6" >
                        <mat-form-field class="kalendar" appearance="none" >
                            <input matInput [matDatepicker]="picker" ngModel name="fromDate"  style="color:#8293c6">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                   
                    <div class="col-12 col-lg-6">
                        <mat-form-field  class="kalendar" appearance="none">
                            <input matInput [matDatepicker]="picker2" ngModel name="toDate"  style="color:#8293c6">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="row btn" style="width:100%; margin-right: 0px; margin-bottom:0px; bottom:15px; right:40px;">
            <div class="col-12" style="text-align: right;">
                <button mat-raised-button class="tableBtn" cdkFocusInitial type="submit">{{labels.lblDownload}}</button>
            </div>
        </div>
    </form>
</div>
<!-- background-color: #202A4A -->
<div class="col-12 col-xl-5 sektori" style="background-color: #182038; padding-right:0px;">
    <h2>{{labels.lblSectors}}</h2>
    <form #insertFormSectors="ngForm" (ngSubmit)="generateReportForSectors()">
        <div class="row">
            <div class="col-12 col-lg-5" style="text-align: left;">
                <h4>{{labels.lblReportType}}:</h4>
            </div>
            <div class="col-12 col-lg-6" >
                <mat-form-field appearance="none" style="color:#8293c6">
                    <mat-select ngModel name="type">
                        <mat-option value="1">
                            {{labels.lblSurvey}}
                        </mat-option>
                        <mat-option value="2">
                            {{labels.lblTestResults}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
           <div class="col-12 col-lg-5" style="text-align: left;">
            <h4>{{labels.lblTrainings}}</h4>
           </div>
           <div class="col-12 col-lg-6">
            <mat-form-field appearance="none" style="color:#8293c6">
                <mat-select ngModel name="trainingId">
                    <mat-option *ngFor="let t of trainings" [value]="t">
                        {{t.name}}
                    </mat-option>
                    </mat-select>
            </mat-form-field>
           </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5" style="text-align: left;">
                <h4>{{labels.lblSectors}}</h4>
            </div>
            <div class="col-12 col-lg-6">
                <mat-form-field appearance="none" style="color:#8293c6">
                    <mat-select ngModel name="depId">
                        <mat-option *ngFor="let d of departments" [value]="d.id">
                          {{d.name}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5" style="text-align: left;">
               <h4 class="kalendarH">{{labels.lblFrom}} - {{labels.lblTo}}</h4>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row"  style="float:right">
                    <div class="col-12 col-lg-6">
                        <mat-form-field class="kalendar" appearance="none">
                            <input matInput [matDatepicker]="picker3" ngModel name="fromDate"  style="color:#8293c6">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                   
                    <div class="col-12 col-lg-6">
                        <mat-form-field  class="kalendar" appearance="none">
                            <input matInput [matDatepicker]="picker4" ngModel name="toDate"  style="color:#8293c6">
                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                            <mat-datepicker #picker4></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 col-sm-6" style="text-align: left;">
               <h4 class="kalendarH">DO</h4>
            </div>
            <div class="col-12 col-sm-5">
                <mat-form-field  class="kalendar" appearance="none">
                    <input matInput [matDatepicker]="picker2" ngModel name="toDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div> -->
        <div class="row btn" style="width:100%; margin-right: 0px; margin-bottom:0px; bottom:15px; right:40px;">
            <div class="col-12" style="text-align: right;">
                <button mat-raised-button class="tableBtn" cdkFocusInitial type="submit">{{labels.lblDownload}}</button>
            </div>
        </div>
    </form>
</div>
</div>
<!-- <mat-divider class="divider"></mat-divider> -->
<div class="row" style="margin-top:3vh">
<div class="col-12 col-xl-5" style=" margin: auto; background-color: #182038; padding-right:0px">
    <h2>General</h2>
    <form style="height:200px" #insertForm="ngForm" (ngSubmit)="generateReport()">
        <div class="row">
            <div class="col-12 col-lg-5" style="text-align: left;">
                <h4>{{labels.lblReportType}}:</h4>
            </div>
            <div class="col-12 col-lg-6" >
                <mat-form-field appearance="none" style="color:#8293c6">
                    <mat-select ngModel name="type">
                        <mat-option value="1" (click)="changeMenuVisibility('1')">
                            {{labels.lblSurvey}}
                        </mat-option>
                        <mat-option value="2" (click)="changeMenuVisibility('2')">
                            {{labels.lblTestResults}}
                        </mat-option>
                        <mat-option value="3" (click)="changeMenuVisibility('3')">
                            {{labels.lblGlobalReport}}
                        </mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="menuVisibility">
           <div class="col-12 col-lg-5" style="text-align: left;">
            <h4>{{labels.lblTrainings}}</h4>
           </div>
           <div class="col-12 col-lg-6">
            <mat-form-field appearance="none" style="color:#182038">
                <mat-select ngModel name="trainingId">
                    <mat-option *ngFor="let t of trainings" [value]="t">
                        {{t.name}}
                    </mat-option>
                    </mat-select>
            </mat-form-field>
           </div>
        </div>
        <div class="row btn" style="width:100%; margin-right: 0px; margin-bottom:0px; bottom:15px; right:40px;">
            <div class="col-12" style="text-align: right">
                <button mat-raised-button class="tableBtn" cdkFocusInitial type="submit">{{labels.lblDownload}}</button>
            </div>
        </div>
    </form>
</div>

<div class="col-12 col-lg-5" style="margin: auto; padding-left:0px; padding-right:0px; padding-bottom:25px; padding-top:25px;">
    <app-status-pie class="statusPie">

    </app-status-pie>
</div>
</div>
