<mat-toolbar class="admHeader">
    <mat-toolbar-row>
        <button mat-icon-button (click)="toggleSideBar()">
            <mat-icon>menu</mat-icon>
        </button>
        <!-- <span>APP LOGO</span> -->
        <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px" style="margin-top: 15px; height: 40px">
                <!-- <li>
                    <button mat-icon-button>
                        <mat-icon>
                            settings
                        </mat-icon>
                    </button>
                </li> -->
                <li style = "visibility: hidden;">
                    <div class = "row">
                        <button [matMenuTriggerFor]="zoomMatMenu" [style.visibility]="(meeting.length > 0) ? 'visible' : 'hidden'">
                            <div class="ring">
                                <div class="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
                                    <div class="coccoc-alo-ph-circle"></div>
                                    <div class="coccoc-alo-ph-circle-fill"></div>
                                    <div class="coccoc-alo-ph-img-circle"> <mat-icon style="cursor: pointer;" class="phoneIcon">phone</mat-icon></div>
                                </div>
                            </div>
                        </button>
                        <mat-menu #zoomMatMenu = "matMenu">
                            <div *ngFor = "let m of meeting" style = "margin: 10px;">
                                <h5 style = "margin: auto; font-family: 'Poppins'; font-size: 17px; text-align: center;">{{m.callFrom}} {{labels.lblCalling}}</h5>
                            </div>
                           <div class = "row" style = "margin: auto; margin-bottom: 10px;">
                            <div class = "col-6" style = "text-align: center; border-right: 1px solid gray;">
                                <mat-icon style="color: green; cursor: pointer;" [routerLink] = "['/admin/chat-meeting']">phone</mat-icon>
                            </div>
                            <div class = "col-6" style = "text-align: center">
                                <mat-icon (click) = "endCall()" style = "color: red; cursor: pointer;">phone_disabled</mat-icon>
                            </div>
                           </div>
                        </mat-menu>
                    </div>
                </li>
                <li>
                    <button mat-icon-button [matMenuTriggerFor]="langMenu">
                        <img *ngIf="lang=='srb'" class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                        <img *ngIf="lang=='cirilica'" class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                        <img *ngIf="lang=='eng'" class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-engleski1.png?alt=media&token=236e883a-232b-437e-91a0-3b07e21dc490" alt="">
                    </button>
                    <mat-menu #langMenu="matMenu" class="mMenu">
                        <button mat-menu-item class="menuButton" (click)="selectLang('srb')">
                            <img class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                            <span class="aligned-with-icon"> {{labels.lblSerbian}}</span>
                        </button>
                        <button mat-menu-item class="menuButton" (click)="selectLang('cirilica')">
                            <img class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-srpski1.png?alt=media&token=5f2dbdd0-2187-4e32-ab8a-2c76ef23857a" alt="">
                            <span class="aligned-with-icon"> {{labels.lblSerbianCyrillic}}</span>
                        </button>
                        <button mat-menu-item class="menuButton" (click)="selectLang('eng')">
                            <img class="flag" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/output-engleski1.png?alt=media&token=236e883a-232b-437e-91a0-3b07e21dc490" alt="">
                            <span class="aligned-with-icon"> {{labels.lblEnglish}}</span>
                        </button>
                    </mat-menu>
                </li>
                <li>
                    <button mat-button [matMenuTriggerFor]="menu" style = "top: 0.3rem">
                        <mat-icon>person_outline</mat-icon>
                    </button>
                        <mat-menu #menu="matMenu" class="mMenu">
                            <button mat-menu-item (click)="goToSettings()" style="color: #9dacd9;">
                                <mat-icon style="color: #9dacd9;">settings</mat-icon>
                                {{labels.lblSettings}}
                            </button>
                            <button mat-menu-item (click)="logoutAdmin()" style="color: #9dacd9;">
                                <mat-icon style="color: #9dacd9;">exit_to_app</mat-icon>
                                {{labels.lblLogOut}}
                            </button>
                        </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
