<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<h1 mat-dialog-title>{{labels.lblAddEvent}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #insertForm="ngForm">
    <div fxLayout="column">

      <mat-form-field appearance="none" fxFlex="60%">
        <mat-labels>{{labels.lblEventTitle}}:</mat-labels>
        <input matInput ngModel name="title" #name="ngModel" required>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>

      <mat-form-field class="matFormField" appearance="none" fxFlex="60%">
        <mat-labels>{{labels.lblDescription}}</mat-labels>
        <textarea class="txtArea" style = "margin-top: 0.5vh" matInput maxlength="250" rows="5" cols="5" ngModel name="description"
          #description="ngModel" required></textarea>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>
      
      <mat-form-field appearance="none" fxFlex="60%">
        <mat-labels>{{labels.lblDate}}</mat-labels> 
        <input matInput [matDatepicker]="picker" ngModel name="date" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
      </mat-form-field>

      <div mat-dialog-actions>
        <button class="tableBtn" cdkFocusInitial type="submit" style="margin-top: 15px;">{{labels.lblSave}}</button>
      </div>
    </div>
  </form>
</div>