<app-admin-spinner
  *ngIf="dataSource == null || labels == null || training == null || departments == null || training.name == null">
</app-admin-spinner>
<div class="row">
  <div class="col-12 col-lg-6 trainingInfo">
    <div class="trainingCard">
      <mat-card style="padding: 20px;">
        <div style="width:100%; background-color:black; border-radius: 7px;">
          <img *ngIf="training.imagePath" mat-card-image src={{training.imagePath}}
          style="height: 200px; width:100%; object-fit: contain; margin: auto;" alt = "training image">
        </div>
        <br>
        <hr>
        <mat-card-content>
          <div class="row info">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblTrainingName}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 style="color: #9dacd9">{{training.name}}</h3>
            </div>
          </div>
          <div class="row info">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblDescription}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 style="color: #9dacd9">{{training.description}}</h3>
            </div>
          </div>
          <div class="row info" *ngIf="training.trainerId">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblTrainer}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 class="link" [routerLink]="['/admin/trainer-profile',training.trainerId]">{{training.trainerName}}
              </h3>
            </div>
          </div>
          <div class="row info">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblPriority}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 style="color: #9dacd9">{{training.priority}}</h3>
            </div>
          </div>
          <div class="row info" *ngIf="training.testId">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblTestName}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 [routerLink]="['/admin/test-details',training.testId]" class="link">{{training.testName}}</h3>
            </div>
          </div>
          <div class="row info" *ngIf="training.pretestId">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblPretestName}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 class="link" [routerLink]="['/admin/test-details',training.pretestId]">{{training.pretestName}}</h3>
            </div>
          </div>
          <div class="row info" *ngIf="training.surveyId">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblSurveyName}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 class="link" [routerLink]="['/admin/test-details',training.surveyId]">{{training.surveyName}}</h3>
            </div>
          </div>
          <div class="row info" *ngIf="training.surveyId">
            <div class="col-12 col-md-5">
              <h3>{{labels.lblAssignedDocuments}}: </h3>
            </div>
            <div class="col-12 col-md-7">
              <h3 *ngIf="training.testResults">{{labels.lblTestResults}}</h3>
              <h3 *ngIf="training.surveyResults">{{labels.lblSurveyResults}}</h3>
              <h3 *ngIf="training.statement">{{labels.lblStatement}}</h3>
              <h3 *ngIf="training.genericWorkPlace">{{labels.lblGenericWorkPlaceDoc}}</h3>
            </div>
          </div>
          <div class="row info">
            <div class="col-12 text-right">
              <button mat-raised-button class="tableBtn" style="margin-right: 20px;" (click)="assignTest(training)">
                {{labels.lblAssignTests}}
              </button>
              <button mat-raised-button class="tableBtn" style="float: right;"
                (click)="openEditTrainingDialog(training)">{{labels.lblEditTraining}}</button>
              <button mat-raised-button class="tableBtn" style="float: right; margin-right: 20px;"
                (click)="openAssignDocumentsModal(training)">{{labels.lblAssignDocuments}}</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="trainingCard">
      <mat-card style="padding: 20px;">
        <div class="row buttons">
          <div class="col-12 text-right" style="margin-bottom: 15px; text-align: right;">
            <button mat-raised-button class="tableBtn addNewSectionBtn" style="margin-right: 20px;"
              [routerLink]="['/admin/trainings/training-detail',training.id]">{{labels.lblSections}}</button>
          </div>
        </div>
        <div *ngFor="let section of training.sections" class="section">
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="sectionName font" style="width: 100%; height: 100%;"><span data-toggle="tooltip"
                    data-placement="top" title={{section.name}}
                    style="display:table-cell; vertical-align: middle; padding-left: 10px; float:left">{{section.order}}.
                    <span class="sectionNameLink"
                      [routerLink]="['/admin/trainings/training-detail', section.trainingId, 'section-detail',section.id]">{{section.name.substring(0,21)}}</span><span
                      *ngIf="section.name.length > 21">...</span></span>
                  <mat-icon class="settingsSection" [matMenuTriggerFor]="settingsMenu"
                    (click)="stopPropagation($event)">settings</mat-icon>
                  <mat-menu #settingsMenu="matMenu" class="mMenu">

                    <button mat-menu-item class="menuButton settingsMenuItem" (click)="addNewUnit(section)"
                      [disabled]="assigned.length > 0">
                      <mat-icon style="color: #8293c6;">add</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblAddNewUnit}}</span>
                    </button>
                    <button mat-menu-item class="menuButton settingsMenuItem" (click)="editSection(section)">
                      <mat-icon style="color: #8293c6;">edit</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                    </button>
                    <button mat-menu-item class="menuButton settingsMenuItem" (click)="deleteSection(section)"
                      [disabled]="assigned.length > 0">
                      <mat-icon style="color: #8293c6;">delete</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                    </button>
                  </mat-menu>
                </div>
              </mat-expansion-panel-header>
              <div *ngFor="let unit of section.units" class="unitName font">
                <span data-toggle="tooltip" data-placement="top" title={{unit.name}}
                  style="display:table-cell; vertical-align: middle; padding-left: 10px;"><span>{{section.order}}.{{unit.order}}.</span>
                  {{unit.name.substring(0,21)}}<span *ngIf="unit.name.length > 21">...</span></span>
                <mat-icon class="settingsUnit" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
                <mat-menu #settingsMenu="matMenu" class="mMenu">

                  <!-- <button mat-menu-item class="menuButton settingsMenuItem" [routerLink]="['/admin/user-profile',user.id]">
                                            <mat-icon style="color: #8293c6;">subject</mat-icon>
                                            <span class="aligned-with-icon"> {{labels.lblMoreDetails}}</span>
                                          </button> -->
                  <button mat-menu-item class="menuButton settingsMenuItem" (click)="editUnit(unit, section)">
                    <mat-icon style="color: #8293c6;">edit</mat-icon>
                    <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                  </button>
                  <button mat-menu-item class="menuButton settingsMenuItem" (click)="deleteUnit(unit)"
                    [disabled]="assigned.length > 0">
                    <mat-icon style="color: #8293c6;">delete</mat-icon>
                    <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                  </button>
                </mat-menu>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div class="font" fxLayout="row table1" fxLayoutAlign="space-between center" fxLayoutGap="20px">
  <div fxFlex="5"></div>
  <div fxFlex="90">
    <!-- <mat-card class="header">
          <div fxLayout="row" fxFlex="100">
            <mat-card-title fxFlex="90">{{labels.lblUsers}}</mat-card-title>
              <mat-card-content fxFlex="10" fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                <button  mat-raised-button class="tableBtn" (click)="openInsertDialog()">{{labels.lblAddNewUser}}</button>
              </mat-card-content>
          </div>
        </mat-card> -->
    <div class="mat-elevation-z8">
      <div class="row header">
        <div class="col-md-6 text-left">
          <h5 class="tableName">{{labels.lblUsers}}</h5>
        </div>
        <div class="col-md-6 text-right">
          <button mat-raised-button class="tableBtn" [ngClass]="{'disabled-btn': admin.isAdmin == false}"
            style="margin-right: 20px;"
            (click)="openUserTrainingsDialog(training)">{{labels.lblAssignTraining}}</button>
        </div>
        <div class="col-10 text-left search1">
          <mat-form-field class="search" appearance="none" fxFlex="40%">
            <span>
              <mat-icon class="searchIcon" [inline]="true">search</mat-icon>
            </span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)"
                placeholder={{labels.lblSearch}}></span>
          </mat-form-field>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true">

        <!-- userName Column -->
        <ng-container matColumnDef="userName">
          <th style="width: 20%" mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">
            {{labels.lblUserName}} </th>
          <td style="width: 20%" mat-cell *matCellDef="let user" style="cursor: pointer;"
            [routerLink]="['/admin/user-profile',user.id]" class="hideColumn userName"> {{user.userName}} </td>
        </ng-container>

        <!-- firstName Column -->
        <ng-container matColumnDef="firstName">
          <th class="first" mat-header-cell *matHeaderCellDef mat-sort-header> {{labels.lblFirstName}} </th>
          <td class="first" mat-cell *matCellDef="let user" style="cursor: pointer;"> {{user.firstName}} </td>
        </ng-container>

        <!-- LastName Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{labels.lblLastName}} </th>
          <td mat-cell *matCellDef="let user"> {{user.lastName}} </td>
        </ng-container>
        
        <ng-container matColumnDef="dueDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{labels.lblDueDate}} </th>
          <td mat-cell *matCellDef="let user">{{ user.trainingData?.toDate?.seconds ? (user.trainingData.toDate.seconds * 1000 | date: 'yyyy-MM-dd') : '-' }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="departmentName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn"> {{labels.lblSectorGroup}} </th>
          <td mat-cell *matCellDef="let user" class="hideColumn userName"
            [routerLink]="['/admin/usersInDep',user.departmentId]"> {{user.departmentName}} </td>
        </ng-container>
        <!-- style="color: #19a97e" -->
        <!--Actions column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width: 10%"> {{labels.lblOptions}} </th>
          <td mat-cell *matCellDef="let user" style="width: 10%">
            <!-- <button class="editBtn d-none d-sm-inline-flex" (click)="openEditDialog(user)">
                          {{labels.lblEdit}}
                        </button>
                        <button class="deleteBtn d-none d-sm-inline-flex"  (click)="openDeleteDialog(user)">
                          {{labels.lblDelete}}
                        </button> -->
            <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
            <mat-menu #settingsMenu="matMenu" class="mMenu">

              <!-- <button mat-menu-item class="menuButton settingsMenuItem" [routerLink]="['/admin/user-profile',user.id]">
                  <mat-icon style="color: #8293c6;">subject</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblMoreDetails}}</span>
                </button>
                <button mat-menu-item class="menuButton settingsMenuItem" >
                  <mat-icon style="color: #8293c6;">edit</mat-icon>
                  <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                </button> -->
              <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(training, user)">
                <mat-icon style="color: #8293c6;">delete</mat-icon>
                <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
              </button>
            </mat-menu>

            <!-- mobile -->
            <!-- <button class="editBtn d-block d-sm-none mobBtn" (click)="openEditDialog(user)">
              {{labels.lblEdit}}
            </button>
            <button class="deleteBtn d-block d-sm-none mobBtn" (click)="openDeleteDialog(user)">
              {{labels.lblDelete}}
            </button>
            <button class="addNewBtn d-block d-sm-none mobBtn" (click)="openUserTrainingsDialog(user)">
              {{labels.lblAssignTraining}}
            </button> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div fxFlex="5"></div>
</div>