<div>
    <div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
    <h1 mat-dialog-title>{{labels.lblAddNewUser}}</h1>
    <mat-horizontal-stepper #stepper>
      <form (ngSubmit)="onSubmit()" #insertForm="ngForm">
      <mat-step [stepControl]="firstFormGroup">
        <ng-template matStepLabel>{{labels.lblNewUser}}</ng-template>
        <div>
          <!-- <div class="row header">
            <div class="col-md-6 text-left"><h5 class="tableName">{{labels.lblQuestions}}</h5></div>
          </div> -->
            <div fxLayout="column">
              <mat-form-field appearance="none" fxFlex="60%">
                <mat-label>{{labels.lblFirstName}}</mat-label>
                <input matInput ngModel name="firstName" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="none" fxFlex="50%">
                <mat-label>{{labels.lblLastName}}</mat-label>
                <input matInput ngModel name="lastName" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
                <mat-form-field appearance="none" fxFlex="60%">
                    <mat-label>{{labels.lblUserName}}</mat-label>
                    <input [class] = "errorClass"  matInput ngModel name="userName" required>
                    <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
                </mat-form-field>
                <!-- <mat-form-field appearance="none" fxFlex="60%">
                  <mat-label>{{labels.lblPassword}}</mat-label>
                  <input type="password" matInput ngModel name="password" required>
                  <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="none" fxFlex="60%">
                  <mat-label>{{labels.lblConfirmPassword}}</mat-label>
                  <input type="password" matInput ngModel name="confirmPassword" required>
                  <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
                </mat-form-field> -->
                <div style="text-align: right">
                  <button cdkFocusInitial (click) = "goToNextStep(stepper)" type="submit" class="tableBtn" style="float: right; padding: 7px; padding-left: 10px; padding-right: 10px;">Nastavi</button>
              </div>
            </div>
        </div>
      </mat-step>
    
      <mat-step [stepControl]="secondFormGroup">
        <ng-template matStepLabel>{{labels.lblOtherInfo}}</ng-template>
        <button class="backBtn" (click)="goBack(stepper)">{{labels.lblBack}}</button>
          <div fxLayout="column">
            <input matInput [ngModel] = "data.id" name="id" hidden>
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>E-mail</mat-label>
              <input matInput [ngModel] = "data.email" name="email" hidden>
              <div>{{data.email}}</div>
              <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error> -->
            </mat-form-field>
            <!-- <div>{{data.email}}</div> -->
            <mat-form-field appearance="none">
              <mat-label>{{labels.lblSector}}</mat-label>
              <mat-select ngModel name="departmentId" required>
                <mat-option *ngFor="let department of departments" [value]="department.id">
                  {{department.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>{{labels.lblPosition}}</mat-label>
              <input matInput ngModel name="position" required>
              <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="none">
              <mat-label>{{labels.lblGenericWorkplace}}</mat-label>
              <mat-select ngModel name="positionId">
                <mat-option *ngFor="let position of positions" [value]="position.id">
                  {{position.positionName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-filed appearance="none" fxFlex="60%">
              <mat-label>{{labels.lblDescription}}</mat-label>
              <textarea matInput ngModel name="description" style="border:1px solid #9dacd9; border-radius: 7px;"></textarea>
            </mat-form-filed>
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>Telefon</mat-label>
              <input matInput ngModel name="phoneNumber">
              <!-- <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error> -->
            </mat-form-field>
            
            <mat-form-field appearance="none" fxFlex="60%">
              <mat-label>{{labels.lblSelectADate}}</mat-label>
              <input matInput [matDatepicker]="picker" ngModel name="dateOfBirth">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div>
              <button style="float:right; padding-right: 10px; padding-left: 10px;" mat-raised-button (click) = "onSubmit()" class="tableBtn" cdkFocusInitial type="submit">{{labels.lblAdd}}</button>
            </div>
            </div>
      </mat-step>
    </form>
    </mat-horizontal-stepper>
