<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit()" #insertForm="ngForm" style = "overflow-y: unset;">
    <div fxLayout="column">
      <div class = "row" *ngFor = "let a of attendees">
        <div class = "col-2">
            <h3 class = "userNameClass">{{a.userName}}</h3>
        </div>
        <div class = "col-4">
            <mat-form-field appearance="none" class="inputField1" fxFlex="100%">
                <mat-label>{{labels.lblGrade}}: </mat-label>
                <input type = "number" step = "1" min = "1" max = "5" matInput [ngModel] = "a.grade" name={{a.id}} (change) = "updateGrade(a.id, $event)" required>
                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
            </mat-form-field>
        </div>
        <div class = "col-6">
            <mat-form-field appearance="none" fxFlex="100%">
                <mat-label>{{labels.lblComment}}</mat-label>
                <textarea matInput rows="1" (change)="updateComment(a.id,$event)">{{a.comment}}</textarea>
            </mat-form-field>
        </div>
      </div>
      <div class = "divForBtn">
        <button class="tableBtn" cdkFocusInitial type="submit" style="margin-top: 20px; float: right; padding: 7px;">{{labels.lblSave}}</button>
      </div>
    </div>
  </form>
</div>