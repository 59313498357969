<!-- <app-main-header (toggleSideBarForMe)="sideBarToggler()">ovo je header</app-main-header> -->

<div class="header"></div>
<mat-drawer-container id = "containerId" style = "height: 90%;">
    <mat-drawer mode="side" [opened]="sideBarOpened">
        <app-main-nav>
            ovo je sidebar
        </app-main-nav>
    </mat-drawer>
<mat-drawer-content class="frame">
    <router-outlet></router-outlet>
</mat-drawer-content>
</mat-drawer-container>
<app-main-footer>ovo je footer</app-main-footer>