import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { FooterComponent } from './components/footer/footer.component'

import {MatToolbarModule} from '@angular/material/toolbar'
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon'
import {FlexLayoutModule} from '@angular/flex-layout'
import {MatMenuModule} from '@angular/material/menu'
import {MatDividerModule} from '@angular/material/divider'
import {MatListModule} from '@angular/material/list'
import { RouterModule } from '@angular/router';
import { AreaComponent } from './widgets/area/area.component';
import { HighchartsChartModule} from 'highcharts-angular'
import {MatCardModule} from '@angular/material/card';
import { CardComponent } from './widgets/card/card.component';
import { PieComponent } from './widgets/pie/pie.component';
import { MainNavComponent } from './user-components/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MainFooterComponent } from './user-components/main-footer/main-footer.component';
import { MainHeaderComponent } from './user-components/main-header/main-header.component';
import { StatusPieComponent } from './widgets/status-pie/status-pie.component';
import { TestSuccessPieComponent } from './widgets/test-success-pie/test-success-pie.component';
import { ApexChartComponent } from './widgets/apex-chart/apex-chart.component'
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioGroup, MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AreaComponent,
    CardComponent,
    MainNavComponent,
    MainFooterComponent,
    MainHeaderComponent,
    StatusPieComponent,
    TestSuccessPieComponent,
    PieComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    RouterModule,
    HighchartsChartModule,
    MatCardModule,
    LayoutModule,
    MatSidenavModule,
    NgApexchartsModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatRadioModule
  ],
  exports:[
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AreaComponent,
    CardComponent,
    MainNavComponent,
    MainHeaderComponent,
    MainFooterComponent,
    StatusPieComponent,
    TestSuccessPieComponent,
    PieComponent
    
  ]
})
export class SharedModule { }
