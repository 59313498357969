<highcharts-chart 
[Highcharts]="Highcharts"
[options]="chartOptions"



style="width: 100%; height: 400px; display: block; background-color: #182038;"
>
    
</highcharts-chart>
