import { ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ControlQuestion } from 'src/app/models/controlQuestion.model';
import { ControlQuestionAnswers } from 'src/app/models/controlQuestionAnswers.model';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { ControlCorrectComponent } from '../control-correct/control-correct.component';
import { ControlFalseComponent } from '../control-false/control-false.component';

@Component({
  selector: 'app-control-question',
  templateUrl: './control-question.component.html',
  styleUrls: ['./control-question.component.scss']
})
export class ControlQuestionComponent implements OnInit {
  labels;
  labelsSub: Subscription;

  adminLabels;
  adminLabelsSub: Subscription;
  
  flg: boolean = false;
  isClicked: boolean = false;

  showControl: boolean = true
  correctAnswer: boolean = false
  falseAnswer: boolean = false

  answers = []
  constructor(public dialog: MatDialog, private adminLanguageService: AdminLanguageService, private languageService: UserLanguageService, public dialogRef1: MatDialogRef<ControlQuestionComponent>,
     @Inject(MAT_DIALOG_DATA) public data,
     private cd: ChangeDetectorRef,
     private ngZone: NgZone) { }

  ngOnInit(): void {
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.adminLabelsSub = this.adminLanguageService.labels$.subscribe((adminLabels) => {
      this.adminLabels = adminLabels;
    })

    console.log("CONTROL QUESTION DATA: ", this.data.controlQuestion);
  }

  onSelect(question: ControlQuestion, answer: ControlQuestionAnswers) {
    this.isClicked = true;
    console.log("ON SELECT")
    if (question.type == 1) {
      console.log("type111")
      question.answers.forEach((x) => { if (x.id !== answer.id) x.selected = false; })
    }
    else {
      console.log("type222")
      question.answers.forEach((x) => {
        if (x.id == answer.id) {
          if (x.selected) {
            x.selected = false
            x.selected = true
          }
          else {
            x.selected = true
            x.selected = false
          }
        }
      })
    }
  }

  submit(question: ControlQuestion) {
    console.log("SUBMIT")
      this.dialogRef1.close();
      this.cd.detectChanges();
    this.showControl = false;
    console.log(this.showControl)
      if (question.type == 1) {
        question.answers.forEach((x) => {
          if (x.selected) {
            if (x.correct == true) {
              console.log("SUBMIT 111")
              this.showControl = false;
              this.correctAnswer = true;
              //this.dialogRef.close({correct: "correct", answers: []});
              const dialogRef1 = this.dialog.open(ControlCorrectComponent, {
                backdropClass: "blurBackground2",
                disableClose: true,
                width: '300px',
              });
              // dialogRef1.afterClosed().subscribe((result) => {
              //   this.dialogRef1.close()
              // })
            } else {
              this.showControl = false;
              this.falseAnswer = true;
              let correctAns = []
              question.answers.forEach((t) => {
                if (t.correct) {
                  correctAns.push(t.textAns1)
                  console.log("SUBMIT 222")
                  //this.dialogRef.close({correct: "false", answers: correctAns});
                  const dialogRef2 = this.dialog.open(ControlFalseComponent, {
                    disableClose: true,
                    width: '300px',
                    data: {
                      text: correctAns
                    }
                  });
                  // dialogRef2.afterClosed().subscribe((result) => {
                  //   this.dialogRef1.close()
                  // })
                }
              })
              console.log("ANSWERS::: ", this.answers)
              this.answers = correctAns;
            }
          }
        })
      } else {
        for (let index = 0; index < question.answers.length; index++) {
          const element = question.answers[index];
          if (element.correct) {
            if (element.selected == true) {
              this.flg = false;
            } else {
              this.flg = true;
              break;
            }
          }
          if (element.correct == false) {
            if (element.selected == true) {
              this.flg = true;
              break;
            }
          }
  
        }
        if (this.flg == false) {
          console.log("SUBMIT 333")
          this.showControl = false;
          this.correctAnswer = true;
          //this.dialogRef.close({correct: "correct", answers: []});
          const dialogRef3 = this.dialog.open(ControlCorrectComponent, {
            disableClose: true,
            width: '300px',
          });
          // dialogRef3.afterClosed().subscribe((result) => {
          //   this.dialogRef1.close()
          // })
        } else {
          this.showControl = false;
          this.falseAnswer = true;
          let correctAnswers = []
          question.answers.forEach((t) => {
            if (t.correct) {
              correctAnswers.push(t.textAns1)
              console.log("SUBMIT 444")
              //this.dialogRef.close({correct: "false", answers: correctAnswers});
             
              const dialogRef4 = this.dialog.open(ControlFalseComponent, {
                disableClose: true,
                width: '300px',
                data: {
                  text: correctAnswers
                }
              });
              // dialogRef4.afterClosed().subscribe((result) => {
              //   this.dialogRef1.close()
              // })
            }
          })
          console.log("ANSWERS::: ", this.answers)
          this.answers = correctAnswers
        }
      }
  }

  // submit(question: ControlQuestion) {
  //     this.dialogRef1.close(true);
  // }

  onNoClick(){
    this.dialogRef1.close()
  }

}
