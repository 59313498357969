import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { UserLanguageService } from '../../services/user-language-service/user-language.service';
import { UserDashboardService } from '../../user-dashboard.service';
export interface DialogData{
  userId: string,
  eventId: string
}
@Component({
  selector: 'app-delete-event',
  templateUrl: './delete-event.component.html',
  styleUrls: ['./delete-event.component.scss']
})
export class DeleteEventComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  constructor(public dialogRef: MatDialogRef<DeleteEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private userDashboardService:UserDashboardService, private userLanguageService: UserLanguageService,
    public db: AngularFirestore, 
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  onNoClick(): void {
    this.dialogRef.close();
   }

   deleteEvent(userId, eventId){
      this.db.firestore.collection("users").doc(userId).collection("events").doc(eventId).delete()
      this.dialogRef.close()
      this.snackBar.open(this.labels.lblEventDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
   }

}
