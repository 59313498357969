<div class="row faqRow1">
    <div class="col-12" style="margin: auto; text-align: center;">
        <h3>{{labels.lblFAQ}}: </h3>
    </div>
</div>
<div class="row faqRow">
    <div class="col-12 col-sm-9 faqDiv">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q1}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: 'Poppins' !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a1}}</span>
               </div>

            </mat-expansion-panel>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q2}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: 'Poppins' !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a2}}</span>
               </div>

            </mat-expansion-panel>
            <mat-expansion-panel class="expansionPanel">
                <mat-expansion-panel-header>
                    <div class="font"><span style="display:table-cell; vertical-align: middle; padding-left: 10px">
                            {{labels.q3}}</span></div>
                </mat-expansion-panel-header>

               <div class="answersDiv">
                <span style="font-family: 'Poppins' !important; display:table-cell; vertical-align: middle; padding-left: 10px;">
                    {{labels.a3}}</span>
               </div>

            </mat-expansion-panel>

        </mat-accordion>
    </div>
</div>
