import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { DashboardService } from './../../dashboard.service'
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Department } from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute } from '@angular/router';
import { Unit } from './../../../models/unit.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { AngularFireStorage } from '@angular/fire/storage'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropperDialogComponent } from '../../image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {
  nameControl = new FormControl('', [Validators.required]);
  typeControl = new FormControl('', [Validators.required]);
  field: number
  uploading: boolean = false
  selectedVideo: File = null
  selectedVideoMobile: File = null
  selectedDocument: File = null
  selectedImage: File = null
  err: boolean = false;
  formClass: string
  downloadUrl$: Observable<string>
  downloadUrlSub: Subscription
  showField: boolean
  @ViewChild('insertForm') insertForm: NgForm
  @ViewChild('imageUpload') imageUpload: ElementRef
  @ViewChild('videoUpload') videoUpload: ElementRef
  @ViewChild('videoUploadMobile') videoUploadMobile: ElementRef
  @ViewChild('documentUpload') documentUpload: ElementRef
  labels;
  labelsSub: Subscription;
  duration
  durationMobile
  video
  allVideos
  audioDur
  extension
  extensionMobile
  imgPath
  docPath
  videoPath
  data1
  unitType
  constructor(public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    private adminLanguageService: AdminLanguageService, private storage: AngularFireStorage,
    public snackBar: MatSnackBar, public dialog: MatDialog) {
    this.uploading = false
  }

  ngOnInit(): void {
    this.err = false;
    this.unitType = 0;
    this.getLang()
    this.showField = false
    this.formClass = "height1"
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  onNoClick() {
    this.dialogRef.close()
  }
  formatTime(seconds) {
    // var hh = Math.floor(seconds / 3600)
    let m = Math.floor(seconds / 60) % 60
    let s = Math.floor(seconds) % 60;
    return (m + ":" + (s < 10 ? "0" : "") + s)
  }
  onSelect(value: Number) {
    this.unitType = value
  }
  async onSubmit() {
    
    let uploaded = (this.selectedVideo != null || this.selectedDocument != null || this.selectedImage != null || this.insertForm.value.text !== "" || this.insertForm.value.type != "")
    if (this.insertForm.value.name == "" || !uploaded) {
      // this.errorClass = "errorClass"
      this.err = true
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.uploading = true
      console.log(this.insertForm.value)
      this.data1 = {
        name: this.insertForm.value.name,
        type: Number(this.insertForm.value.type),
        trainingSectionId: this.data.sectionId,
        trainingId: this.data.id,
        order: this.data.order,
        videoPath: "",
        documentPath: "",
        imagePath: "",
        text: "",
        videoDuration: "",
        audioPath: "",
        audioDuration: "",
        videoPathMobile: "",
        audioPathMobile: "",
        videoDurationMobile: "",
        audioDurationMobile: "",
        documentName: ""
      }
      if (this.selectedVideoMobile !== null) {
        console.log('usao u if')
        if (this.extensionMobile == "mp3") {
          console.log('usao u if 1')
          this.data1.audioDurationMobile = this.formatTime(this.durationMobile)
          let ref = this.storage.ref(this.selectedVideoMobile.name)
          await ref.put(this.selectedVideoMobile)
          this.data1.audioPathMobile = await ref.getDownloadURL().toPromise()
        }
        else if (this.extensionMobile == "mp4" || this.extensionMobile == "mov") {
          console.log('usao u if 2')
          this.data1.videoDurationMobile = this.formatTime(this.durationMobile)
          let ref = this.storage.ref(this.selectedVideoMobile.name)
          await ref.put(this.selectedVideoMobile)
          this.data1.videoPathMobile = await ref.getDownloadURL().toPromise()
        }
      }
      console.log(this.data1)
      if (this.selectedVideo !== null) {
        if (this.extension == "mp3") {
          this.data1.audioDuration = this.formatTime(this.duration)
          let ref = this.storage.ref(this.selectedVideo.name)
          await ref.put(this.selectedVideo)
          this.data1.audioPath = await ref.getDownloadURL().toPromise()
        }
        else if (this.extension == "mp4" || this.extension == "mov") {
          this.data1.videoDuration = this.formatTime(this.duration)
          let ref = this.storage.ref(this.selectedVideo.name)
          await ref.put(this.selectedVideo)
          this.data1.videoPath = await ref.getDownloadURL().toPromise()
        }
      }
      if (this.selectedDocument !== null) {
        let ref = this.storage.ref(this.selectedDocument.name)
        await ref.put(this.selectedDocument)
        this.data1.documentPath = await ref.getDownloadURL().toPromise()
        this.data1.documentName = this.selectedDocument.name
      }
      if (this.selectedImage !== null) {
        let ref = this.storage.ref(this.selectedImage.name)
        await ref.put(this.selectedImage)
        this.data1.imagePath = await ref.getDownloadURL().toPromise()
      }
      if (this.insertForm.value.text != null) {
        this.data1.text = this.insertForm.value.text
      }
      console.log(this.data1)
      this.uploading = false
      this.dashboardService.insertUnit(this.data1).subscribe((response) => {
        console.log(response)
        this.snackBar.open(this.labels.lblTrainingUnitAdded, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.dialogRef.close()
      })
    }


  }
  setValueOfType(typeOfUnit) {
    this.formClass = "height2"
    this.field = +typeOfUnit.value
    this.showField = true
  }

  getVideoDuration = async (f) => {
    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
          else fileObj.onloadedmetadata = resolve;
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000);
        }),
      ]);
    };

    const objectUrl = URL.createObjectURL(f);
    // const isVideo = type.startsWith('video/');
    const video = document.createElement("video");
    video.src = objectUrl;
    await fileCallbackToPromise(video);
    this.duration = video.duration
    console.log(this.duration)
    console.log(video.duration)
    return video.duration

  }
  getVideoDurationMobile = async (f) => {
    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
          else fileObj.onloadedmetadata = resolve;
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000);
        }),
      ]);
    };

    const objectUrl = URL.createObjectURL(f);
    // const isVideo = type.startsWith('video/');
    const video = document.createElement("video");
    video.src = objectUrl;
    await fileCallbackToPromise(video);
    this.durationMobile = video.duration
    return video.duration

  }

  getAudioDuration = async (f) => {
    const fileCallbackToPromise = (fileObj) => {
      return Promise.race([
        new Promise((resolve) => {
          if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
          else fileObj.onloadedmetadata = resolve;
        }),
        new Promise((_, reject) => {
          setTimeout(reject, 1000);
        }),
      ]);
    };

    const objectUrl = URL.createObjectURL(f);
    // const isVideo = type.startsWith('video/');
    const audio = document.createElement("audio");
    audio.src = objectUrl;
    await fileCallbackToPromise(audio);
    this.audioDur = audio.duration
    console.log(audio.duration)
    return audio.duration

  }

  onVideoChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if(ext == "mp4" || ext == "mp3"){
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 250){
        const meta = this.getVideoDuration(event.target.files[0]);
        console.log(meta)
        let dur = event.target.files[0].name
        var a = dur.split('.');
        this.extension = a[a.length - 1]
        console.log(this.extension)
        console.log(event.target.files[0].name)
        this.selectedVideo = event.target.files[0]
      }
      else{
        this.snackBar.open(this.labels.lblMaxSizeVideo , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.videoUpload.nativeElement.value = ""
      }
      
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.videoUpload.nativeElement.value = ""
    }
    

    
  }
  onVideoChangedMobile(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if(ext == "mp4" || ext == "mp3"){
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 250){
        const meta = this.getVideoDurationMobile(event.target.files[0]);
        let dur = event.target.files[0].name
        var a = dur.split('.');
        this.extensionMobile = a[a.length - 1]
        this.selectedVideoMobile = event.target.files[0]
        console.log(this.selectedVideoMobile)
      }
      else{
        this.snackBar.open(this.labels.lblMaxSizeVideo , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.videoUploadMobile.nativeElement.value = ""
      }
      
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsVideo , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.videoUploadMobile.nativeElement.value = ""
    }
    

    
  }
  onDocumentChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if(ext == "pdf" || ext == "docx"){
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 250){
        this.selectedDocument = event.target.files[0]
      }
      else{
        this.snackBar.open(this.labels.lblMaxSizeDocument , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.documentUpload.nativeElement.value = ""
      }
      
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsDocument , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.documentUpload.nativeElement.value = ""
    }
  }
  onImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if(ext == "jpg" || ext == "jpeg" || ext == "png"){
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 250){
        this.selectedImage = event.target.files[0]
        const dialogRef=this.dialog.open(ImageCropperDialogComponent,{
          width:"500px",
          height: "600px",
          data:event
        })
    
        dialogRef.afterClosed().subscribe((result)=>{
          this.selectedImage = result
          console.log(this.selectedImage)
        })
      }
      else{
        this.snackBar.open(this.labels.lblMaxFileSizeImage , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }
      
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }
    
    
  }

  updateImage(){
    this.selectedImage = null
  }
  updateVideo(){
    this.selectedVideo = null
  }
  updateVideoMobile(){
    this.selectedVideoMobile = null
  }
  updateDocument(){
    this.selectedDocument = null
  }


}
