  <app-admin-spinner *ngIf="labels == null "></app-admin-spinner>
      <div fxLayout="row table1" fxLayoutAlign="space-between center" fxLayoutGap="20px" *ngIf="outlet.isActivated===false">
        <div fxFlex="5"></div>
        <div fxFlex="90">
          <div class="mat-elevation-z8">
            <div class="row header">
              <div class="col-md-6 text-left">
                <h5 class="tableName">{{labels.lblMeeting}}</h5>
              </div>
              <div class="col-md-6 text-right">
                <button class = "tableBtn" (click) = "addEvent()">
                  {{labels.lblAddMeeting}}</button>
                </div>
              <div class="col-10 text-left search1">
                <mat-form-field class="search" appearance="none" fxFlex="40%">
                  <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
                </mat-form-field>
            </div>
            </div>
            <table mat-table [dataSource]="dataSource">
      
              <!-- userName Column -->
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>{{labels.lblMeetingTitle}}</th>
                <td mat-cell data-toggle="tooltip" data-placement="top" title={{m.title}} *matCellDef="let m"><span>{{m.title.substring(0,25)}}</span><span *ngIf="m.title.length > 25">...</span> </td>
              </ng-container>
      
              <!-- firstName Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef class="hideColumn">{{labels.lblDescription}}</th>
                <td mat-cell data-toggle="tooltip" data-placement="top" title={{m.description}} *matCellDef="let m" class="hideColumn"> {{m.description.substring(0,30)}}<span *ngIf="m.description.length > 30">...</span></td>
              </ng-container>
      
              <!-- dateCreated Column -->
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="hideColumn">{{labels.lblTime}}</th>
                <td mat-cell data-toggle="tooltip" data-placement="top" title={{m.date}} *matCellDef="let m" class="hideColumn"> {{m.date1}}</td>
              </ng-container>
      
              <!--Actions column-->
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> {{labels.lblOptions}} </th>
                <td mat-cell *matCellDef="let m">
                  <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon>
                  <mat-menu #settingsMenu="matMenu" class="mMenu">
                    <!-- <button mat-menu-item class="menuButton settingsMenuItem" style="color: #8293c6;" (click)="assignTest(m)">
                      <mat-icon style = "color: #8293c6">description</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblAssignTest}}</span>
                    </button> -->
                    <button mat-menu-item class="menuButton settingsMenuItem" (click)="editEvent(m)">
                      <mat-icon style="color: #8293c6;">edit</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                    </button>
                    <button mat-menu-item class="menuButton settingsMenuItem" (click)="deleteEvent(m)">
                      <mat-icon style="color: #8293c6;">delete</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                    </button>
                  </mat-menu>
                  <button (click) = "startZoomMeeting(m)" class="addNewBtn" style = "margin-left: 10px;" *ngIf = "!m.finished">{{labels.lblStart}}</button>
                  <button class="editBtn" (click) = "openEvaluationDialog(m)" *ngIf = "m.finished" style = "margin-left: 10px;">{{labels.lblEvaluate}}</button>
                </td>
              </ng-container>
      
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
          </div>
        </div>
        <div fxFlex="5"></div>
      </div>
      
      <router-outlet #outlet="outlet"></router-outlet>
