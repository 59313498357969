<app-admin-spinner *ngIf="nestedDataSource.data == null || nestedDataSource1.data == null ||  labels == null"></app-admin-spinner>
<div fxLayout="row" class="table1" fxLayoutAlign="space-between center" fxLayoutGap="20px">
  <div fxFlex="5"></div>
  <div fxFlex="90">
    <!-- <mat-card cdkDrag>
            <div fxLayout="row" fxFlex='100'>
                <mat-card-title fxFlex="90">{{labels.lblSectors}}</mat-card-title>
                  <mat-card-content fxFlex="20" fxFlex.sm="40" fxFlex.xs="60">
                    <button class="tableBtn" mat-raised-button (click)="insertNewDepartment()">{{labels.lblAddNewSector}}</button>
              
                  </mat-card-content>
              </div>
        </mat-card> -->
    <div class="mat-elevation-z8">
      <div class="row header">
        <div class="col-md-6 text-left">
          <h5 class="tableName">{{labels.lblSectors}}</h5>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 text-right"> <button class="tableBtn" mat-raised-button
            (click)="insertNewDepartment()">{{labels.lblAddNewSector}}</button></div>
        <div class="col-10 text-left search1">
          <mat-form-field class="search" appearance="none" fxFlex="40%">
            <span><mat-icon class="searchIcon" [inline] = "true">search</mat-icon></span><span><input matInput type="text" style="width: 70%" (keyup)="doFilter($event.target.value)" placeholder={{labels.lblSearch}}></span>
          </mat-form-field>
      </div>
      </div>

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort matSortDisableClear="true" (matSortChange)="sortChange($event)">

        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 85%;">{{labels.lblSectorName}} </th>
          <td mat-cell *matCellDef="let department" > {{department.name}} </td>

        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email" class="hideColumn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn" style="width: 25%;">E-mail</th>
          <td mat-cell *matCellDef="let department" class="hideColumn"> {{department.email}} </td>
        </ng-container>

        <ng-container matColumnDef="admin" style="width: 15%;">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="hideColumn">{{labels.lblAdministrator}}</th>
          <td mat-cell *matCellDef="let department" class="hideColumn"> {{department.adminFullName}} </td>

        </ng-container>

        <!--Actions column-->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width:15%"> {{labels.lblOptions}} </th>
          <td mat-cell *matCellDef="let department" >

            <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu" (click)="stopPropagation($event)">settings</mat-icon>
            
            <button class="editBtn mobBtn" [routerLink]="['/admin/sector-details',department.id]">
              {{labels.lblMoreDetails}}
            </button>
            <!-- mobile -->
            <button class="editBtn d-block d-sm-none mobBtn" (click)="openEditDialog(department)">
              {{labels.lblEdit}}
            </button>
            <button class="deleteBtn d-block d-sm-none mobBtn" (click)="openDeleteDialog(department)" [disabled]="department.numberOfUsers > 0">
              <!-- <mat-icon>delete</mat-icon> -->
              {{labels.lblDelete}}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        
      </table>
      
      <mat-tree [dataSource]="nestedDataSource1" [treeControl]="nestedTreeControl" class="example-tree">
        <mat-tree-node *matTreeNodeDef="let department">
            <li class="mat-tree-node">
              <div class="nodeDiv">
                <button mat-icon-button disabled style="display: inline-block;"></button>
                  <div class="nameDiv" [routerLink]="['/admin/usersInDep',department.id]">{{department.name}}</div>
                 <!--  <div class="emailDiv hideColumn">{{department.email}}</div>
                  <div class="adminDiv hideColumn">{{department.adminFullName}}</div> -->
                  <div class="settingsDiv">
                  <button class="addNewBtn d-sm-inline-flex" (click) = "openSectorTrainingsDialog(department)">
                    {{labels.lblAssignTraining}}
                  </button>
                  <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon></div>
                  <mat-menu #settingsMenu="matMenu" class="mMenu">
                    <button mat-menu-item class="menuButton settingsMenuItem" [routerLink]="['/admin/usersInDep',department.id]">
                      <mat-icon style="color: #8293c6;">subject</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblMoreDetails}}</span>
                    </button>
                    <button mat-menu-item class="menuButton settingsMenuItem" (click)="openEditDialog(department)">
                      <mat-icon style="color: #8293c6;">edit</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                    </button>
                    <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(department)" [disabled]="department.numberOfUsers > 0">
                      <mat-icon style="color: #8293c6;">delete</mat-icon>
                      <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                    </button>
                  </mat-menu>
                </div>
                
            </li>
        </mat-tree-node>
    
        <mat-nested-tree-node *matTreeNodeDef="let department; when: hasNestedChild">
            <li>
                <div class="mat-tree-node">
                  <div class="nodeDiv">
                    <button mat-icon-button matTreeNodeToggle style="display: inline-block;">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{nestedTreeControl.isExpanded(department) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                      <div class="nameDiv" [routerLink]="['/admin/usersInDep',department.id]">{{department.name}}</div>
                     <!--  <div class="emailDiv hideColumn">{{department.email}}</div>
                      <div class="adminDiv hideColumn">{{department.adminFullName}}</div> -->

                      <div class="settingsDiv settings" >
                      <button class="addNewBtn d-sm-inline-flex" (click) = "openSectorTrainingsDialog(department)">
                        {{labels.lblAssignTraining}}
                      </button>
                      <mat-icon class="settings" [matMenuTriggerFor]="settingsMenu">settings</mat-icon></div>
                      <mat-menu #settingsMenu="matMenu" class="mMenu">
                        <button mat-menu-item class="menuButton settingsMenuItem" [routerLink]="['/admin/usersInDep',department.id]">
                          <mat-icon style="color: #8293c6;">subject</mat-icon>
                          <span class="aligned-with-icon"> {{labels.lblMoreDetails}}</span>
                        </button>
                        <button mat-menu-item class="menuButton settingsMenuItem" (click)="openEditDialog(department)">
                          <mat-icon style="color: #8293c6;">edit</mat-icon>
                          <span class="aligned-with-icon"> {{labels.lblEdit}}</span>
                        </button>
                        <button mat-menu-item class="menuButton settingsMenuItem" (click)="openDeleteDialog(department)" [disabled]="department.numberOfUsers > 0">
                          <mat-icon style="color: #8293c6;">delete</mat-icon>
                          <span class="aligned-with-icon"> {{labels.lblDelete}}</span>
                        </button>
                      </mat-menu>
                    </div>
                </div>
                <ul [class.example-tree-invisible]="!nestedTreeControl.isExpanded(department)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
    </mat-tree>

      <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator> -->
    </div>
  </div>
  <div fxFlex="5"></div>
</div>

