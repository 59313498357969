import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { UserDashboardService } from '../user-dashboard.service';

@Component({
  selector: 'app-user-chat',
  templateUrl: './user-chat.component.html',
  styleUrls: ['./user-chat.component.scss']
})
export class UserChatComponent implements OnInit {
  users
  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getUsers()
  }

  getUsers(){
    this.dashboardService.getUsers().subscribe((result)=>{
      this.users = result
    })
  }

}
