import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { TrainingSection } from 'src/app/models/training-section.model';
import { Training } from 'src/app/models/training.model';
import { Unit } from 'src/app/models/unit.model';
import { User } from 'src/app/models/user.model';
import { UserBeforePreTestComponent } from '../pre-test/user-before-pre-test/user-before-pre-test.component';
import { AuthService } from '../services/auth-service/auth.service';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserDashboardService } from '../user-dashboard.service';
import { WelcomeComponent } from './welcome/welcome.component';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  training: Training = null
  trainingSub: Subscription
  training$: Observable<Training>
  currentUnit: Unit
  currentUnitSub: Subscription
  currentSection: TrainingSection
  user: User
  userSub: Subscription;
  currentUnit$: Observable<Unit>
  test$: Observable<Test>
  backgroundColor
  constructor(private authService: AuthService, private localStorageService: LocalStorageService, public dialog: MatDialog, private router: Router, private userDashboarService: UserDashboardService, private languageService: UserLanguageService) { }
  

  ngOnInit(): void {
    this.getLang()
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if(this.user.companyId == 1){
          this.backgroundColor = "#bbbcbf"

        }else if(this.user.companyId == 2){
          this.backgroundColor = "#FFE7EF"
        }
        else{
          this.backgroundColor = "#FFE7EF"
        }
      }, 200);
    });
    this.training$ = this.userDashboarService.getTraining().pipe(share())
    this.trainingSub = this.userDashboarService.training$.subscribe((training) => {
      this.training = training;
    })
    this.currentUnitSub = this.userDashboarService.currentUnit$.subscribe((unit: Unit) => {
      setTimeout(() => {
        this.setCurrentUnit(unit)
    }, 1500);
    }) 
   setTimeout(() => {
    this.openModal()
   }, 2000);
  }

  openModal(){
    const dialogRef = this.dialog.open(WelcomeComponent, {
      disableClose: true,
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(this.training)
      console.log(this.user.trainings)
      const tr = this.user.trainings.filter((t) => t.trainingId == this.training.id)[0]
      this.currentUnit$ = this.userDashboarService.getCurrentUnit(this.training.id).pipe(share());
      console.log(tr)
      if (!tr.pretestFlag && this.training.pretestId!="") {
        this.test$ = this.userDashboarService.getTestForTraining(this.localStorageService.getItem('pretestId')).pipe(share())
        this.router.navigateByUrl('user/start-pretest');
      }
      else {
        this.router.navigateByUrl('user/training');
      }
      this.changeStatus(this.training, 2)
    })
  }

  getLang(){
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  changeStatus(training: Training, status: Number){
    console.log("TRAINING ID: " + training.id)
    this.userDashboarService.changeTrainingStatus(training.id, status).subscribe((result)=>{
      console.log(result)
     })
  }

  openTestDialog(): void {
    const dialogRef = this.dialog.open(UserBeforePreTestComponent, {
      disableClose: true,
      width: '350px'
    });
  }

  ondDestroy() {
    this.userSub.unsubscribe();
  }

  setCurrentUnit(unit: Unit): void {
    this.currentUnit = unit;
  }

}
