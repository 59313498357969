<app-user-spinner *ngIf="training == null || user == null || labels == null || test == null"></app-user-spinner>
<div class="row">
    <div class="container2 col-xl-6 col-sm-5" style="margin: auto;">
        <div class="testHeader font" style = "margin-top: 3%;">
           <div class = "testQuestion" style = "line-height: 120%; margin-top: 0px;">
            {{test.name}}
           </div>
        </div>
        <div>
            <div class="testQuestion font" style = "padding-top: 10px">
                <h5 class = "font" style = "font-size: 15px; margin-bottom: 7%;">{{labels.lblQuestionCapsLock}} {{test.questions[index].order}} {{labels.lblOfCL}} {{test.questions.length}}</h5>
                <h3 class="questionText font" style="line-height: 40px;">
                    <span style="font-weight: bold;">{{test.questions[index].text}}</span>
                </h3>
                <div style="width: 100%; text-align: center; background: black; height: 200px;" *ngIf = "test.questions[index].imagePath">
                    <img style="width:100%; height: 100%; margin: auto; object-fit: contain;" src = {{test.questions[index].imagePath}} alt = "test question image">
                </div>
                <!-- <h4 *ngIf="test.questions[index].type==2"><span style="color:rgb(241,106,100);">*Ovo pitanje ima više tačnih odgovora</span></h4> -->
                    <div class="answer" *ngFor="let answer of test.questions[index].answers" [class.answerSelectedEMS]='answer.selected && (user.companyId == 1)' [class.answerSelectedECG]='answer.selected && (user.companyId != 1)'>
                        <!-- <input type="checkbox" id="{{answer.id}}" [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);"/> {{answer.text}} -->
                        <mat-checkbox [value]="answer" class="checkBox " [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);">
                            <span style="font-size: 2.3vh; font-weight: bold; white-space: break-spaces; letter-spacing: 1px;">{{answer.text}}</span>
                        </mat-checkbox>
                    </div>
                    <div class="answer" style="border: 1px solid transparent" *ngIf = "test.questions[index].type == 3">
                        <textarea matInput ngModel required name = "textAnswer" style="width: 100%; height: 100%; background-color: #f4f8f9 !important; outline: none; border-radius: 5px; color: #0E0D35 !important;
                        border: 1px solid rgb(104, 104, 104) !important;" (change) = "changeInput($event)" (keyup) = "onTextInput()"></textarea>
                    </div>
            </div>
        </div>
    </div>

    <div class = "col-xl-7 col-sm-3" style = "position: relative; height: 80px; margin: auto;">
        <div class="buttons" style = "position: absolute; right: 7%; bottom: 10;">
            <!-- <button mat-raised-button class="button" [class] = "buttons" style="float: left;" *ngIf="!(index==0)"
                (click)="previousQuestion(test.questions[index].order)">
                {{labels.lblBack}}
            </button> -->
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == true))" style = "cursor: pointer;" (click)="nextQuestion(test.questions[index].order)" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994' alt = "button image">
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == false))" class = "btnDisabled"  src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994' alt = "disabled button image">
          
            <button mat-raised-button class="button" *ngIf="(index==test.questions.length-1 && (showNextBtn == true))"
                (click)="submit(test.questions[index].order, false)">
                {{labels.lblSubmit}} <mat-icon style = "vertical-align: middle; margin-left: 15px; color: white;">arrow_forward</mat-icon>
            </button>
        </div>
    </div>
    <div class = "row">
        <div class="col-12 progress" style = "left: 0" [style.width]="testProgress+'%'"></div>
    </div>
</div>

<!-- mobile -->
<div class="mobileContainer row">
    <div class="container2 col-xs-12">
        <div class="testHeader font">
            {{test.name}}
        </div>
        <div class="testQuestion">
            <h5 class = "font" style = "font-size: 15px;">{{labels.lblQuestionCapsLock}} {{test.questions[index].order}} {{labels.lblOfCL}} {{test.questions.length}}</h5>
            <h3 class="questionText">
                <span style="font-weight: bold; line-height: 35px;">{{test.questions[index].text}}</span>
            </h3>
            <div style="width: 300px; text-align: center; background: black; height: 200px;" *ngIf = "test.questions[index].imagePath">
                <img style="width:100%; height: 100%; margin: auto; object-fit: contain;" src = {{test.questions[index].imagePath}}>
            </div>
            <!-- <h4 *ngIf="test.questions[index].type==2"><span style="color:rgb(241,106,100);">*Ovo pitanje ima više tačnih odgovora</span></h4> -->
                    <div class="answer" *ngFor="let answer of test.questions[index].answers" [class.answerSelectedEMS]='answer.selected && (user.companyId == 1)' [class.answerSelectedECG]='answer.selected && (user.companyId != 1)'>
                        <!-- <input type="checkbox" id="{{answer.id}}" [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);"/> {{answer.text}} -->
                        <mat-checkbox [value]="answer" class="checkBox " [(ngModel)]="answer.selected" (change)="onSelect(test.questions[index], answer);">
                            <span style="font-size: 2vh; font-weight: bold; white-space: pre-line;">{{answer.text}}</span>
                        </mat-checkbox>
                    </div>
                    <div class="answer" *ngIf = "test.questions[index].type == 3">
                        <textarea style="width: 100%; background-color: #f4f8f9; outline: none; border-radius: 5px; color: #0E0D35;" matInput ngModel required name = "textAnswer" (change) = "changeInput($event)" (keyup) = "onTextInput()"></textarea>
                    </div>
        </div>
        <div class="buttons" style = "margin-right: 15px; text-align: right;">
            <!-- <button mat-raised-button class="button" [class] = "buttons" style="float: left;" *ngIf="!(index==0)"
                (click)="previousQuestion(test.questions[index].order)">
                {{labels.lblBack}}
            </button> -->
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == true))" style = "cursor: pointer;" (click)="nextQuestion(test.questions[index].order)" src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994'>
            <img *ngIf="(!(index==test.questions.length-1) && (showNextBtn == false))" class = "btnDisabled"  src = 'https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testBtnNext.png?alt=media&token=51cd63ca-4837-406c-bfe6-9eceb70a0994'>
          
            <button mat-raised-button class="button" *ngIf="(index==test.questions.length-1 && (showNextBtn == true))"
                (click)="submit(test.questions[index].order, false)">
                {{labels.lblSubmit}} <mat-icon style = "vertical-align: middle; margin-left: 15px; color: white;">arrow_forward</mat-icon>
            </button>
        </div>
    </div>
    <div class = "col-12" class="progress" [style.width]="testProgress+'%'"></div>
</div>
