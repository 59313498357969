import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getMatIconFailedToSanitizeUrlError } from '@angular/material/icon';
import { Observable, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { Test } from 'src/app/models/test.model';
import { TestQuestion } from 'src/app/models/testQuestion.model';
import { TestQuestionAnswer } from 'src/app/models/testQuestionAnswer.model';
import { TestQuestionAnswerScore } from 'src/app/models/testQuestionAnswerScore.model';
import { TestQuestionScore } from 'src/app/models/testQuestionScore.model';
import { Training } from 'src/app/models/training.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../services/auth-service/auth.service';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { UserTestMessageComponent } from '../test/user-test-message/user-test-message.component';
import { UserDashboardService } from '../user-dashboard.service';
import { UserBeforeSurveyMessageComponent } from './user-before-survey-message/user-before-survey-message.component';
import { UserSurveyMessageComponent } from './user-survey-message/user-survey-message.component'

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  host: { '(click)': 'onClick()' }
})
export class SurveyComponent implements OnInit {

  training$: Observable<Training>
  trainingSub: Subscription
  training: Training

  survey$: Observable<Test>
  testSub: Subscription
  test: Test

  userSub: Subscription
  user: User

  labelsSub: Subscription
  labels

  index: number = 0
  checked: boolean = false

  questionScores: TestQuestionScore[] = []

  testProgress
  txtAnswer: string
  showNextBtn

  backgroundColor = ""
  buttons = ""
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  constructor(private userLanguageService: UserLanguageService, public dialog: MatDialog, private authService: AuthService, private userDashboarService: UserDashboardService, private localStorageService: LocalStorageService, private component: UserDashboardComponent) { }

  ngOnInit(): void {
    this.survey$ = this.userDashboarService.getSurveyForTraining(this.localStorageService.getItem('surveyId')).pipe(share())
    this.testSub = this.userDashboarService.survey$.subscribe((test: Test) => {
      console.log("SURVEY: ")
      this.test = test;
       setTimeout(() => {
        console.log(this.test)
        this.index = this.localStorageService.getItem('currentQuestionIndex')
        let p = (this.index) / this.test.questions.length * 100
        this.testProgress = Math.floor(p)
       }, 400);
    });

    //this.openSurveyDialog()

    this.showNextBtn = false
    this.training$ = this.userDashboarService.getTraining().pipe(share())
    this.trainingSub = this.userDashboarService.training$.subscribe((training: Training) => {
      this.training = training;
    });

    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
      setTimeout(() => {
        if (this.user.companyId == 1) {
          this.backgroundColor = "#bbbcbf"
          this.buttons = "buttonEMSGray"

        } else if (this.user.companyId == 2) {
          this.backgroundColor = "rgb(241,106,100)"
          this.buttons = "buttonECG"
        }
        else {
          this.backgroundColor = "rgb(241,106,100)"
          this.buttons = "buttonECG"
        }
      }, 200);
    });


    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    let scores = this.localStorageService.getItem('questionScores')
    if (scores == null) {
      this.questionScores = []
    }
    else {
      this.questionScores = scores
    }
    
  }
  @HostListener("click") onClick() {
    this.component.sideBarOpened = false
  }

  // openSurveyDialog(): void {
  //   const dialogRef = this.dialog.open(UserBeforeSurveyMessageComponent, {
  //     disableClose: true,
  //     width: '350px',
  //   });

  // }

  nextQuestion(order: number) {
    let questionAnswerScores = []
    let currentQuestion: TestQuestion = this.test.questions[order - 1]
    let nextQuestion: TestQuestion = this.test.questions[order]
    this.getScoreForQuestion(questionAnswerScores, currentQuestion)

    this.localStorageService.setItem('questionScores', this.questionScores)
    let show

    if (!(this.test.questions.length == order)) {
      this.index = order
      let p = (this.index) / this.test.questions.length * 100
      this.testProgress = Math.floor(p)
      this.localStorageService.setItem('currentQuestionIndex', order)
      this.localStorageService.setItem('survey', this.test)
      for (let i = 0; i < nextQuestion.answers.length; i++) {
        const ans = nextQuestion.answers[i];
        console.log("*******")
        console.log(ans)
        if (ans.selected == true) {
          show = true;
          break;
        }
      }
      if (show == true) {
        this.showNextBtn = true
      } else {
        this.showNextBtn = false
      }
    }
    else {
    }


  }

  previousQuestion(order: number) {
    this.showNextBtn = true;
    if (order != 1) {
      this.index = order - 2
      let p = (this.index) / this.test.questions.length * 100
      this.testProgress = Math.floor(p)
      this.localStorageService.setItem('currentQuestionIndex', order - 2)
      this.localStorageService.setItem('survey', this.test)
    }
    else {
    }

  }

  changeInput(event) {
    this.txtAnswer = event.target.value;
    console.log(this.txtAnswer)
  }
  onTextInput() {
    this.showNextBtn = true
    console.log(this.showNextBtn)
  }

  submit(order: number) {
    this.testProgress = 100
    this.nextQuestion(order)
    this.openTestDialog()
    this.userDashboarService.setQuestionScores(this.questionScores, true, false).subscribe((response) => {
      console.log(response)
    })
    //this.userDashboarService.updateSurveyFlag()
    this.localStorageService.removeItem('survey')
    this.localStorageService.removeItem('questionScores')
    this.user.trainings.forEach(element => {
      if (element.trainingId == this.training.id) {
        element.surveyFlag = true
      }
    });
    this.authService.updateUser(this.user)

  }

  getScoreForQuestion(questionAnswerScores, currentQuestion: TestQuestion) {
    let questionScore: TestQuestionScore
    questionScore = { id: this.getId(), testQuestionId: currentQuestion.id, userId: this.user.id, correct: false, answerScores: questionAnswerScores, testId: this.test.id }
    let answerScore
    if (currentQuestion.answers.length > 0) {
      currentQuestion.answers.forEach(a => {

        let checked: boolean
        if (a.selected) {
          checked = true
        }
        else {
          checked = false
        }
        answerScore = { id: this.getId(), testQuestionScoreId: questionScore.id, testQuestionAnswerId: a.id, checked: checked, correct: this.isItCorrect(checked, a) }
        console.log("ans score")
        console.log(answerScore)
        questionAnswerScores.push(answerScore)
      })
    } else {
      answerScore = { id: this.getId(), testQuestionScoreId: questionScore.id, testQuestionAnswerId: "123", checked: false, correct: false, textAnswer: this.txtAnswer, testQuestionId: currentQuestion.id }
      console.log("ans score")
      console.log(answerScore)
      questionAnswerScores.push(answerScore)
    }

    let flag: boolean = true
    for (let index = 0; index < questionAnswerScores.length; index++) {
      if (!questionAnswerScores[index].correct) {
        flag = false
        break;
      }
    }
    //questionScore = {id: this.getId(), testQuestionId: this.selectedRadio.testQuestionId, userId: this.user.id, correct: flag, answerScores:questionAnswerScores}
    questionScore.testQuestionId = currentQuestion.id
    questionScore.correct = flag
    questionScore.testId = this.test.id
    questionScore.answerScores = questionAnswerScores

    this.addQuestionScore(this.questionScores, questionScore)
  }

  addQuestionScore(scores: TestQuestionScore[], score: TestQuestionScore) {
    let flag = 0
    for (let index = 0; index < scores.length; index++) {
      if (scores[index].testQuestionId == score.testQuestionId) {
        scores[index] = score
        flag = 1
        break;
      }
    }
    if (!flag) {
      scores.push(score)
    }

  }

  isItCorrect(checked: boolean, answer: TestQuestionAnswer): boolean {
    if ((checked && answer.correct) || (!checked && !answer.correct)) {
      return true
    }
    else return false
  }

  getId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  onSelect(question: TestQuestion, answer: TestQuestionAnswer) {
    if (question.type == 1) {
      question.answers.forEach((x) => { if (x.id !== answer.id) x.selected = false; })
    }
    else {
      question.answers.forEach((x) => {
        if (x.id == answer.id) {
          if (x.selected) {
            x.selected = false
            x.selected = true
          }
          else {
            x.selected = true
            x.selected = false
          }
        }
      })
    }

    let show
    for (let i = 0; i < question.answers.length; i++) {
      const ans = question.answers[i];
      console.log("*******")
      console.log(ans)
      if (ans.selected == true) {
        show = true;
        break;
      }
    }
    if (show == true) {
      this.showNextBtn = true
    } else {
      this.showNextBtn = false
    }

  }

  nextQue(order: number) {


  }

  prevQue(order: number) {
    if (order != 1) {
      this.index = order - 2
      this.localStorageService.setItem('currentQuestionIndex', order - 2)
      //this.localStorageService.setItem('test', this.test)
    }
  }

  openTestDialog(): void {
    const dialogRef = this.dialog.open(UserSurveyMessageComponent, {
      disableClose: true,
      width: '650px',
    });

  }

}
