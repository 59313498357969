<mat-nav-list>
    <div class="navigation">
  <div class="profile-card">
      <div class="header">
          <h1 style="font-family: 'Poppins' !important; color: #262626;">{{user?.firstName + " "+ user?.lastName}}</h1>
          <h3 style="font-family: 'Poppins' !important; color: #4d4d4d">{{user?.email}}</h3>
      </div>
      <img src={{user?.profileImagePath}} alt = "Profile image">
  </div>
  <mat-divider></mat-divider>
  <!-- <h2 matSubheader>Pages</h2> -->
  
  <a  mat-list-item routerLinkActive="colorClass" routerLink="/user/dashboard"><mat-icon [style.color] = "colorIconClass">home</mat-icon>{{labels.lblDashboard}}</a>
  <a  mat-list-item routerLinkActive="colorClass" routerLink="/user/user-profile"><mat-icon [style.color] = "colorIconClass">people</mat-icon>{{labels.lblUserProfile}}</a>
  <a  mat-list-item routerLinkActive="colorClass" routerLink="/user/faq"><mat-icon [style.color] = "colorIconClass">description</mat-icon>FAQ</a>
  <!-- <a  mat-list-item routerLinkActive="colorClass" routerLink="/user/calendar"><mat-icon [style.color] = "colorIconClass">event</mat-icon>{{labels.lblCalendar}}</a> -->
  <!-- <a mat-list-item routerLinkActive="list-item-active" routerLink="/user/training">Articles</a> -->

  <!-- <mat-divider></mat-divider> -->
  <!-- <h2 matSubheader>Tools</h2> -->
  <a mat-list-item routerLinkActive="colorClass" routerLink="/user/messages">
    <mat-icon [style.color] = "colorIconClass">textsms</mat-icon>
    {{labels.lblMessages}}
</a>
  <!-- <a mat-list-item routerLinkActive="list-item-active" routerLink="/user/notifications">
    <mat-icon class="matIcon">notifications_none</mat-icon>
    {{labels.lblNotifications}}
</a> -->
  <!-- <a mat-list-item routerLinkActive="list-item-active" routerLink="/user/change-password">
      <mat-icon>vpn_key</mat-icon>
      Change password
  </a> -->
  <!-- <a mat-list-item routerLinkActive="list-item-active" routerLink="/">
      <mat-icon>contact_phone</mat-icon>
      Leads
  </a> -->
</div>
</mat-nav-list>
