<mat-nav-list class="adminSidebar">
    <div class="navigation">
    <div class="profile-card">
        <div class="header font">
            <h2 class="font" >{{admin.firstName}} {{admin.lastName}}</h2>
            <h4 class="font" style="color: #14abf9"><span *ngIf = "admin.isAdmin == true">
                <span *ngIf = "admin.companyId == 1">Elektromreža Srbije</span>
                <span *ngIf = "admin.companyId == 2">NIS</span>
                <span *ngIf = "admin.companyId == 3">Evropska konsalting grupa</span>
                <span *ngIf = "admin.companyId == 4">RGZ</span>
            </span><span *ngIf = "admin.isAdmin == false">Trener kompanija</span></h4>
            <h4 class="font" >{{admin.email}}</h4>
        </div>
        <img src={{admin.profileImagePath}} alt = "admin profile image">
    </div>
    <mat-divider></mat-divider>
    <!-- <h2 matSubheader>Pages</h2> -->
    <a mat-list-item [class]="dashboardClass" class="font" (click)="changeClass('dashboard')" routerLink="/admin"><mat-icon [class]="dashboardClass" class="sidebarIcons">home</mat-icon>{{labels.lblDashBoard}}</a>
    <a mat-list-item *ngIf = "admin.isAdmin == true" [class]="usersClass" class="font" (click)="changeClass('user')" routerLinkActive="list-item-active" routerLink="/admin/users"><div style="width: 100%;"><mat-icon [class]="usersClass" class="sidebarIcons">people</mat-icon>{{labels.lblUsers}}<mat-icon *ngIf="!usersExpanded" style="float: right; margin-right: 0px;">expand_more</mat-icon><mat-icon *ngIf="usersExpanded" style="float: right; margin-right: 0px;">expand_less</mat-icon></div></a>
    <a mat-list-item [class]="administratorsClass" class="font" (click)="changeClass('administrators')" *ngIf="usersExpanded && admin.headAdmin" routerLink="/admin/administrators"><div class="menuItem">{{labels.lblAdministrators}}</div></a>
    <a mat-list-item [class]="sectorsGroupsClass" class="font" (click)="changeClass('sectorsGroups')" *ngIf="usersExpanded" routerLink="/admin/departments"><div class="menuItem">{{labels.lblSectors}}</div></a>
    <a mat-list-item [class]="trainersClass" class="font" (click)="changeClass('trainers')" *ngIf="usersExpanded" routerLink="/admin/trainers" ><div class="menuItem">{{labels.lblTrainers}}</div></a>
    <a mat-list-item [class]="trainersClass" class="font" (click)="changeClass('trainers')" *ngIf = "admin.isAdmin == false" routerLink="/admin/trainers" ><div><mat-icon [class]="trainersClass" class="sidebarIcons">people</mat-icon>{{labels.lblTrainers}}</div></a>
    <a mat-list-item [class]="trainingsClass" class="font" (click)="changeClass('trainings')" routerLinkActive="list-item-active" routerLink="/admin/trainings"><div style="width: 100%;"><mat-icon [class]="trainingsClass" class="sidebarIcons">subject</mat-icon>{{labels.lblEtraining}}<mat-icon *ngIf="!trainingsExpanded" style="float: right; margin-right: 0px;">expand_more</mat-icon><mat-icon *ngIf="trainingsExpanded" style="float: right; margin-right: 0px;">expand_less</mat-icon></div></a>
    <a mat-list-item [class]="testSurveyClass" class="font" (click)="changeClass('testSurvey')" *ngIf="trainingsExpanded" routerLink="/admin/tests"><div class="menuItem">{{labels.lblTest}}/{{labels.lblSurvey}}</div></a>
    <!-- <a mat-list-item [class]="departmentsClass" class="font" (click)="changeClass('departments')" routerLinkActive="list-item-active" routerLink="/admin/departments"><mat-icon [class]="departmentsClass" class="sidebarIcons">work_outline</mat-icon>{{labels.lblSectors}}</a>
    <a mat-list-item [class]="testsClass" class="font" (click)="changeClass('tests')" routerLinkActive="list-item-active" routerLink="/admin/tests"><mat-icon [class]="testsClass" class="sidebarIcons">assignment</mat-icon>{{labels.lblTests}}</a> -->
    <a mat-list-item [class]="generateReportsClass" class="font" style="margin-top: 1vh;" (click)="changeClass('generateReports')" routerLinkActive="list-item-active" routerLink="/admin/generate-reports"><mat-icon [class]="generateReportsClass" class="sidebarIcons">insert_drive_file</mat-icon>{{labels.lblReports}}</a>
    <a mat-list-item [class]="msgsClass" class="font" style="margin-top: 1vh;" (click)="changeClass('messages')" routerLinkActive="list-item-active"routerLink="/admin/messages"><mat-icon [class]="msgsClass" class="sidebarIcons">textsms</mat-icon>{{labels.lblMessages}}</a>
    <a mat-list-item [class]="calendarClass" class="font" (click)="changeClass('calendar')" routerLinkActive="list-item-active" routerLink="/admin/calendar"><mat-icon [class]="calendarClass" class="sidebarIcons">event_note</mat-icon>{{labels.lblCalendar}}</a>
    <!-- <a mat-list-item [class]="zoomClass" class="font" (click)="changeClass('zoom')" (click)="stopPropagation($event)" routerLinkActive="list-item-active" href="https://zoom.us/oauth/authorize?response_type=code&client_id=cDQPVo5qSoyvYSPlwTQZJw&redirect_uri=https://leste.lesteplatform.com/admin/zoom-meetings"><mat-icon [class]="zoomClass" class="sidebarIcons">event</mat-icon>{{labels.lblMeeting}}</a> -->
    <a mat-list-item [class]="changePassClass" class="font" (click)="changeClass('changepassword')" routerLinkActive="list-item-active" routerLink="/admin/change-password"><mat-icon [class]="changePassClass" class="sidebarIcons">settings</mat-icon>{{labels.lblSettings}}</a>
    <a mat-list-item [class]="faqClass" class="font" (click)="changeClass('faq')" routerLinkActive="list-item-active" routerLink="/admin/faq"><mat-icon [class]="faqClass" class="sidebarIcons">description</mat-icon>FAQ</a>
</div>
</mat-nav-list>
