<!-- splash screen -->
<div class = "splash-screen" [class] = "backgroundClass">
    <div class = "row" style = "width: 100%; margin: auto;">
      <div class = "col-11 col-sm-6 splash-inner">
          <h3 class = "splashScreenHeader">{{header}}</h3>
          <h5 class = "splashScreenHeader2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eget magna.</h5>
          <div class = "row" style = "width: 100%; margin: auto; margin-top: 10%">
            <div class = "col-11 col-sm-6" style = "margin: auto;">
                <span class = "skip" (click) = "goToHome()">{{labels.lblSkip1}}</span>
                <span>
                  <div class = "circle" [class.greenCircle] = "first">
  
                  </div>
                  <div class = "circle" [class.greenCircle] = "second">
  
                  </div>
                  <div class = "circle" [class.greenCircle] = "third">
  
                  </div>
              </span>
                <span class = "next" (click) = "toNextSlide(index)">{{labels.lblNext1}}</span>
            </div>
          </div>
      </div>
    </div>
  </div>
  