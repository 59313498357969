<app-user-spinner *ngIf="data.meeting == null || labels == null ||  test == null"></app-user-spinner>
<button mat-button [class] = "button1" (click)="dialogRef.close()"><mat-icon>close-icon</mat-icon></button>
<div class="container">
  <img style="border:1px solid transparent; border-radius:50%; margin-bottom:20px;" src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/testIcon.png?alt=media&token=b7ba3f32-124c-4fef-82f3-a2e2c858f4e5" alt="test image">
  <h1 style="font-weight: bold;">{{labels.lblTest}}</h1>
  <h3>{{labels.lblTestInFront1}} <br>
    {{labels.forPassingTheTest}} <br>
    {{labels.lblMinPoints}} <span [style.color] = "color">{{test.minPercentage}}%</span><br>
    {{labels.lblGodLuck}}
  </h3>
  <div class="row">
    <div class="col">
      <button mat-button class="btn" [class] = "button1" style="outline: none;-webkit-box-shadow:none; box-shadow: none" (click)="goToZoomTest()">{{labels.lblContinue}}</button>
    </div>
    <!--
    <div class="col">
      <button mat-button class="btn" [class] = "button2" (click)="downloadStatement(training.id)">{{labels.lblStatement}}</button>
    </div>
    -->
  </div>
</div>