import { Component, OnInit,ViewChild ,Inject} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator'
import {MatTable, MatTableDataSource} from '@angular/material/table'
import { DashboardService } from '../dashboard.service'
import {Observable, Subscription } from 'rxjs'
import { User } from '../../models/user.model'
import {DataSource} from '@angular/cdk/collections'
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogComponent } from './../admin-training-detail/edit-dialog/edit-dialog.component';
import { InsertDialogComponent } from './../admin-training-detail/insert-dialog/insert-dialog.component';
import {Training} from './../../models/training.model'
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router'
import {DeleteDialogComponent} from './../admin-training-detail/delete-dialog/delete-dialog.component'
import { TrainingSection } from 'src/app/models/training-section.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CdkDragDrop,moveItemInArray} from '@angular/cdk/drag-drop'
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { AssignTestComponent } from './assign-test/assign-test.component';

@Component({
  selector: 'app-admin-training-detail',
  templateUrl: './admin-training-detail.component.html',
  styleUrls: ['./admin-training-detail.component.scss']
})
export class AdminTrainingDetailComponent implements OnInit {

  value=true 
  showOrderButton=false
  @ViewChild('table') table:MatTable<TrainingSection[]>
  displayedColumns: string[] = ['order', 'name', 'numOfUnits', 'actions'];
  id:number
  dataSource
  labels;
  labelsSub: Subscription;
  training
  assigned
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  constructor(private dashboardService:DashboardService,
    private router:ActivatedRoute,
    public dilaog:MatDialog,
    public snackBar:MatSnackBar,
    public adminLanguageService: AdminLanguageService,
    ) { }

  ngOnInit(): void {
    this.id=this.router.snapshot.params['id']
    this.getTraining(this.id)
    this.getSections(this.id)
    this.isTrainingAssigned()
    console.log("INIIIT")
    this.router.params.subscribe((params)=>{
      this.id=params['id']
      this.getSections(this.id)
    })
    this.getLang();
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  ngOnDestroy(){
    this.dataSource.data = null;
    this.dataSource = null;
  }
  
  getTraining(id){
    return this.dashboardService.getTraining(id).subscribe((training) => {	     this.training = training;
     console.log(this.training)
     })
   }
  getSections(id){
      this.dashboardService.getSectionsForTraining(id).subscribe((sections)=>{
        console.log("sections")
        console.log(sections)
        let trSections = sections as TrainingSection[]
        setTimeout(() => {
          trSections = trSections.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.dataSource=new MatTableDataSource(trSections)
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator
        this.dataSource.filterPredicate = (data : TrainingSection, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
        }, 200);
    })
  }
  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }
  addNewSection(){
    let sections = this.dataSource.data
    console.log(sections)
    const dialogRef=this.dilaog.open(InsertDialogComponent,{
      width:'500px',
      data:{id:this.id,order:sections.length+1}
    })

    dialogRef.afterClosed().subscribe((response)=>{
      this.dataSource = null
      this.getSections(this.id)
    })
  }
  
  deleteSection(trainingSection){
    const dialogRef=this.dilaog.open(DeleteDialogComponent,{
      width:'500px',
      data:trainingSection
    })

    dialogRef.afterClosed().subscribe((response)=>{
      this.dataSource = null
      this.dashboardService.getSectionsForTraining(this.id).subscribe((sections)=>{
        let trSections = sections as TrainingSection[]
        trSections = trSections.sort((a, b) => (a.order < b.order ? -1 : 1));
        this.dataSource=new MatTableDataSource(trSections)
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator
        this.dataSource.filterPredicate = (data : TrainingSection, filter: string) => data.name.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
        this.changeOrder1()
      })
      
    })
  }

  editSection(trainingSection){
    const dialogRef=this.dilaog.open(EditDialogComponent,{
      width:'500px',
      data:trainingSection
    })

    dialogRef.afterClosed().subscribe((response)=>{
      this.dataSource = null
      this.getSections(this.id)
    })
  }
  drop(event: CdkDragDrop<string[]>) {
    let sections = this.dataSource.data
    const prevIndex = sections.findIndex((d) => d === event.item.data)
    moveItemInArray(sections, prevIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(sections)
    this.table.renderRows()
    
  }
  showOrderButtons(){
    this.showOrderButton=!this.showOrderButton
  }

  isTrainingAssigned(){
    this.dashboardService.isTrainingAssigned(this.id).subscribe((response)=>{
       if(response == null){
       this.assigned = []
     }else{
      this.assigned = response
     }
    })
  }

  changeOrder(){
    let sections=this.dataSource.data
    console.log(sections)
    for (let index = 0; index < sections.length; index++) {
      const element = sections[index];
      element.order = index + 1
      this.dashboardService.changeTrainingSectionsOrder(element).subscribe((response)=>{
        console.log(response)
        this.getSections(this.id)
      })
      
    }
    this.value = !this.value
  }

  changeOrder1(){
    let sections=this.dataSource.data
    console.log(sections)
    for (let index = 0; index < sections.length; index++) {
      const element = sections[index];
      element.order = index + 1
      this.dashboardService.changeTrainingSectionsOrder(element).subscribe((response)=>{
        console.log(response)
        this.getSections(this.id)
      })
      
    }
  }
  showChangeButton(){
    
    this.value=!this.value
  }

  assignTest(section) {

    const dialogRef = this.dilaog.open(AssignTestComponent, {
      width: '500px',
      height: '300px',
      data: { id: section.id, trainingId: section.trainingId, testId: section.testId }
    })
  }

}

export class TrainingSectionDataSource extends DataSource<any>{
  constructor(private dashboardService:DashboardService,private id:number){
    super()
  }
  connect():Observable<TrainingSection[]>{
    return this.dashboardService.fetchSectionsForTraining(this.id)
  }
  disconnect(){}

  }
