import { Component, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, RouterLinkActive } from '@angular/router';
import { DashboardService } from '../dashboard.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import {MatTable, MatTableDataSource} from '@angular/material/table'
import {moveItemInArray} from '@angular/cdk/drag-drop'
import { Observable, Subscription } from 'rxjs';
import { AdminLanguageService } from '../services/admin-language-service/admin-language.service';
import { EditTestQuestionComponent } from '../admin-test/admin-test-questions-and-answers/edit-test-question/edit-test-question.component';
import { DeleteTestQuestionComponent } from '../admin-test/admin-test-questions-and-answers/delete-test-question/delete-test-question.component';
import { InsertQuestionDialogComponent } from './insert-question-dialog/insert-question-dialog.component';
import { share } from 'rxjs/operators';
import { Test } from 'src/app/models/test.model';
import { UserDashboardService } from '../user-dashboard.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TestQuestion } from 'src/app/models/testQuestion.model';
export interface TestQuesetion{
  text:string
  order:number
  points:number
}
@Component({
  selector: 'app-admin-test-question',
  templateUrl: './admin-test-question.component.html',
  styleUrls: ['./admin-test-question.component.scss']
})
export class AdminTestQuestionComponent implements OnInit {

  value=true 
  showOrderButton=false
  @ViewChild('table') table:MatTable<TestQuesetion[]>

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort

  constructor(private dashboardService:DashboardService,
    private router:ActivatedRoute,
    private dialog:MatDialog,
    private userDashboarService: UserDashboardService,
    private adminLanguageService: AdminLanguageService
    ) { }

  displayedColumns: string[] = ['order','type','text','points','actions'];
  dataSource
  testId:string
  labels;
  labelsSub: Subscription;
  assigned
  test:Test
  test$: Observable<Test>
  testSub: Subscription
  questions:TestQuesetion[]
  questionsSub:Subscription
  questions$: Observable<TestQuesetion[]>
  ngOnInit(): void {
    this.getLang()
    this.testId=this.router.snapshot.params.id
    console.log(this.testId)
    this.router.params.subscribe((params)=>{
      this.testId=params['id']
      console.log(this.testId)
    })
    this.test$ = this.userDashboarService.getTestForTrainingAdmin(this.testId).pipe(share())
    this.testSub = this.userDashboarService.test$.subscribe((test: Test) => {
      this.test = test;
    });

    this.questions$ = this.dashboardService.fetchQuestionsForTest(this.testId).pipe(share())
    this.questionsSub = this.dashboardService.questions$.subscribe(response => {
      setTimeout(() => {
        console.log(response)
       let questions = response as TestQuestion[]
      questions = questions.sort((a, b) => (a.order < b.order ? -1 : 1));
      this.questions = questions
      console.log(questions)
      this.dataSource = new MatTableDataSource(questions)
      setTimeout(() => {
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (data : TestQuestion, filter: string) => data.text.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase());
      }, 2000);
      }, 200);
      
    })
    //this.fetchQuestionsForTest()
    this.isTestAssigned()
  }

  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

  /* fetchQuestionsForTest() {
    this.dashboardService.fetchQuestionsForTest(this.testId).subscribe((response) => {
     
      
      
    })
  } */

  ngOnDestroy(){
    this.dataSource.data = null;
    this.dataSource = null;
  }

  openEditDialog(test) {
    const dialogRef = this.dialog.open(EditTestQuestionComponent, {
      width: '500px',
      data: test
    })
    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.questions$ = this.dashboardService.fetchQuestionsForTest(this.testId).pipe(share())
    })
  }

  openDeleteDialog(test) {
    const dialogRef = this.dialog.open(DeleteTestQuestionComponent, {
      width: '500px',
      data: test
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.questions$ = this.dashboardService.fetchQuestionsForTest(this.testId).pipe(share())
      setTimeout(() => {
        this.changeOrder1()
      }, 1000);
    })
  }

  openInsertQuestionDialog(test){
    console.log("order 1")
    console.log(this.questions.length)
    const dialogRef = this.dialog.open(InsertQuestionDialogComponent, {
      width: '500px',
      data: {testId: this.testId, order: this.questions.length}
    })

    dialogRef.afterClosed().subscribe((response) => {
      this.dataSource = null
      this.questions$ = this.dashboardService.fetchQuestionsForTest(this.testId).pipe(share())
    })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value
  }

  isTestAssigned(){
    this.dashboardService.isTestAssigned(this.testId).subscribe((response)=>{
    console.log(response)
     if(response == null){
       this.assigned = []
     }else{
      this.assigned = response
     }
     console.log(this.assigned)
    })
  }

  changeOrder() {
    let questions = this.dataSource.data
    if (this.test.random) {
      this.dashboardService.setQuestionsOrder(true, this.testId).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < questions.length; index++) {
        const element = questions[index];
        console.log(element)
        this.dashboardService.changeOrderOfQuestions({ id: element.id, testId: element.testId, order: index + 1 }).subscribe((response) => {
          console.log(response)
          this.questions$ = this.dashboardService.fetchQuestionsForTest(this.testId).pipe(share())
          this.dashboardService.setQuestionsOrder(false, this.testId).subscribe(response => {
            console.log(response)
          })

        })

      }
    }

    this.value = !this.value
  }

  changeOrder1() {
    let questions = this.dataSource.data
    if (this.test.random) {
      this.dashboardService.setQuestionsOrder(true, this.testId).subscribe(response => {
        console.log(response)
      })
    }
    else {
      for (let index = 0; index < questions.length; index++) {
        const element = questions[index];
        console.log(element)
        this.dashboardService.changeOrderOfQuestions({ id: element.id, testId: element.testId, order: index + 1 }).subscribe((response) => {
          console.log(response)
          this.questions$ = this.dashboardService.fetchQuestionsForTest(this.testId).pipe(share())
          this.dashboardService.setQuestionsOrder(false, this.testId).subscribe(response => {
            console.log(response)
          })

        })

      }
    }
  }

  showChangeButton() {

    this.value = !this.value
  }

  drop(event) {
    let questions = this.dataSource.data
    const prevIndex = questions.findIndex((d) => d === event.item.data)
    moveItemInArray(questions, prevIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(questions)
    this.table.renderRows()
  }

  changeRandom() {
    this.test.random = !this.test.random
  }

}
