import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Department } from 'src/app/models/department.model';
import { DashboardService } from '../../dashboard.service';
import { ImageCropperDialogComponent } from '../../image-cropper-dialog/image-cropper-dialog.component';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
@Component({
  selector: 'app-add-meeting',
  templateUrl: './add-meeting.component.html',
  styleUrls: ['./add-meeting.component.scss']
})
export class AddMeetingComponent implements OnInit {
  @ViewChild('insertForm') insertForm: NgForm
  labels;
  labelsSub: Subscription;
  err
  admin
  adminSub: Subscription
  zoomAccessToken
  zoomUserId
  imgPath
  departments
  @ViewChild('imageUpload') imageUpload: ElementRef
  selectedImage: File = null
  defaultValue: Number
  uploading:boolean = false
  adminId
  tests
  constructor(private languageService: AdminLanguageService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AddMeetingComponent>,
    private dashboardService: DashboardService,
    private authService: AdminAuthService,
    public db: AngularFirestore,
    private dialog: MatDialog,
    private storage: AngularFireStorage
    ) { }

  ngOnInit(): void {
    this.err = false
    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })

    this.fetchTests()

    this.adminSub = this.authService.admin$.subscribe((admin) => {
      this.admin = admin;
      setTimeout(() => {
        this.adminId = this.admin.id
        if(admin.headAdmin){
          this.fetchDepartments();
        }
        else{
          this.fetchDepartments1();
        }
      }, 300);
    });
  }

  fetchTests(){
    this.dashboardService.fetchTestsByType(1).subscribe(response=>this.tests = response)
  }

  fetchDepartments() {
    return this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
    })
  }

  fetchDepartments1() {
    return this.dashboardService.fetchAdminsDepartments(this.admin.departmentId).subscribe((response) => {
      this.departments = response as Department[]
    })
  }

  onImageChanged(event) {
    let file = event.target.files[0]
    let selectedFileName = file.name
    let ext = selectedFileName.split(".").pop()
    if(ext == "jpg" || ext == "jpeg" || ext == "png"){
      var iConvert = (file.size / 1024 / 1024).toFixed(2);
      let number:Number = +iConvert
      if(number <= 50){
        this.selectedImage = event.target.files[0]
        const dialogRef=this.dialog.open(ImageCropperDialogComponent,{
          width:"500px",
          height: "600px",
          data:event
        })
    
        dialogRef.afterClosed().subscribe((result)=>{
          this.selectedImage = result
          console.log(this.selectedImage)
        })
      }
      else{
        this.snackBar.open(this.labels.lblMaxFileSizeImage , 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.imageUpload.nativeElement.value = ""
      }
      
    }
    else{
      this.snackBar.open(this.labels.lblWrongFormat + " " + this.labels.lblSupportedFormatsImage , 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.imageUpload.nativeElement.value = ""
    }
  }

  updateImage(){
    this.selectedImage = null
  }

  async onSubmit(){
    if (this.insertForm.value.title == "" || this.insertForm.value.description == "" || this.insertForm.value.date == "" || this.insertForm.value.duration == "" || this.selectedImage == null || this.insertForm.value.departmentId == "") {
      this.err = true;
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      this.uploading = true
      console.log("DATE: ", this.insertForm.value.date)
      let ref = this.storage.ref(this.selectedImage.name)
      await ref.put(this.selectedImage)
      this.imgPath = await ref.getDownloadURL().toPromise()
      this.uploading = false
      let data = {
        topic: this.insertForm.value.title,
        agenda: this.insertForm.value.description,
        date: this.insertForm.value.date,
        duration: this.insertForm.value.duration,
        imagePath: this.imgPath,
        departmentId: this.insertForm.value.departmentId,
        adminId: this.adminId,
        testId: this.insertForm.value.test
      }
     
      console.log("ZOOM MEETING DATA: ", data)
      this.dashboardService.addMeeting(data).subscribe((response) => {
          this.dialogRef.close()
          this.snackBar.open(this.labels.lblMeetingAdded, 'OK', {
                verticalPosition: 'top',
                horizontalPosition: 'end',
                duration: 5000
          })
          console.log("RESPONSE: ")
          console.log(response)
        })
    }
  }

  onNoClick() {
    this.dialogRef.close()
  }

}
