import { Component, OnInit, ViewChild, Inject, AfterViewInit, AfterContentInit } from '@angular/core';

import { DashboardService } from './../../dashboard.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { NgForm } from '@angular/forms';
import { Training } from 'src/app/models/training.model';
import { RequestObject } from './../../../models/trainingUserRequest.model'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { User } from 'src/app/models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface TrainingRequest {
  id: string
  status: number
}
@Component({
  selector: 'app-user-trainings-dialog',
  templateUrl: './user-trainings-dialog.component.html',
  styleUrls: ['./user-trainings-dialog.component.scss']
})
export class UserTrainingsDialogComponent implements OnInit, AfterViewInit, AfterContentInit {

  @ViewChild('userTrainingsForm', { static: true }) userTrainingsForm: NgForm

  filteredUsers: User[];
  searchText: string = '';

  traininigIds
  traininigIdsUser
  users: User[]
  userTrainings
  selectedCheckBoxes: string[]
  selectedUsers: User[]
  training: Training
  reqObject: RequestObject
  user: User
  userSub: Subscription;
  labels;
  labelsSub: Subscription;
  assignedTrainings
  err
  constructor(public snackBar: MatSnackBar, private db: AngularFirestore, private authService: AdminAuthService, public dialogRef: MatDialogRef<UserTrainingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, private adminLanguageService: AdminLanguageService) { }

  ngAfterContentInit() {/* 
    this.extractIdsUser()
    this.extractIds() */
  }
  ngAfterViewInit() {

  }
  ngOnInit(): void {
    this.userSub = this.authService.admin$.subscribe((user: User) => {
      this.user = user;
    });
    // this.fetchTrainings()
    console.log("on init", this.searchText)
    this.fetchUsers()
    this.selectedCheckBoxes = new Array<string>()
    this.selectedUsers = new Array<User>()
    this.getLang();

  }
  /* extractIds(){
    let ids=[]
    for (let index = 0; index < this.trainings.length; index++) {
      const element = this.trainings[index];
      ids.push(element.id)
    }
    this.traininigIds=ids
    
  } */

  fetchUsers(){
    console.log(this.data.admin.headAdmin)
    if(this.data.admin.headAdmin){
      this.fetchUsersForTraining()
    }
    else{
      this.fetchUsersForTraining1()
    }
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  extractIdsUser() {
    let ids = []
    for (let index = 0; index < this.userTrainings.length; index++) {
      const element = this.userTrainings[index];
      ids.push(element.trainingId)
    }
    this.traininigIdsUser = ids
  }
  onNoClick() {
    this.dialogRef.close()
  }
  onSubmit() {
    console.log(this.selectedUsers)
    console.log(this.userTrainingsForm.value.id)
      let timestamp = Math.floor(Date.now() / 1000)
      let trngs = [this.userTrainingsForm.value.id]
      console.log("TRNGS")
      console.log(trngs)
      // trngs.push()
      let fromDate
      let toDate
      if(this.userTrainingsForm.value.fromDate == "" || this.userTrainingsForm.value.fromDate == null){
        fromDate = null
      }else{
        fromDate = this.userTrainingsForm.value.fromDate
      }
      if(this.userTrainingsForm.value.toDate == "" || this.userTrainingsForm.value.toDate == null){
        toDate = null
      }else{
        toDate = this.userTrainingsForm.value.toDate
      }
      for (let index = 0; index < this.selectedUsers.length; index++) {
        this.updateTrainings({ id: this.selectedUsers[index].id, fromDate: fromDate, toDate: toDate, trainings: trngs })
        this.dialogRef.close()
        let id: string = this.selectedUsers[index].id
        let docRef = this.db.firestore.collection('users').doc(id).collection('userNotifications').doc()
        docRef.set({
          "receiverId": id,
          "fullName": this.selectedUsers[index].firstName + " " + this.selectedUsers[index].lastName,
          "timestamp": new Date(),
          "docId": docRef.id,
          "content": "Dodela treninga: " + this.data.training.name,
          "seen": false,
          "type": 2
        })
      }
  }

  fetchUsersForTraining() {
    console.log(this.data.training.id)
    this.dashboardService.getUsersNotForTrainings(this.data.training.id).subscribe((users: User[]) => {
      this.users = users as User[]
      this.filteredUsers = this.users
      this.filteredUsers.sort((a, b) => {
        const firstNameComparison = a.firstName.localeCompare(b.firstName);
        if (firstNameComparison !== 0) {
          return firstNameComparison;
        } else {
          return a.lastName.localeCompare(b.lastName);
        }
      });
      console.log(this.users)
    })
  }
  fetchUsersForTraining1() {
    console.log(this.data.training.id)
    this.dashboardService.getAdminsUsersNotForTrainings(this.data.admin.departmentId, this.data.training.id).subscribe((users: User[]) => {
      this.users = users as User[]
      this.filteredUsers = this.users;
      this.filteredUsers.sort((a, b) => {
        const firstNameComparison = a.firstName.localeCompare(b.firstName);
        if (firstNameComparison !== 0) {
          return firstNameComparison;
        } else {
          return a.lastName.localeCompare(b.lastName);
        }
      });
    })
  }
  filterUsers(): void {
    const draft = this.users.filter(user => 
      user.userName.toLowerCase().includes(this.searchText.toLowerCase()) ||
      user.firstName.toLowerCase().includes(this.searchText.toLowerCase()) ||
      user.lastName.toLowerCase().includes(this.searchText.toLowerCase()) ||
      user.email.toLowerCase().includes(this.searchText.toLowerCase())
    );
    this.filteredUsers = draft.sort((a, b) => {
      const firstNameComparison = a.firstName.localeCompare(b.firstName);
      if (firstNameComparison !== 0) {
        return firstNameComparison;
      } else {
        return a.lastName.localeCompare(b.lastName);
      }
    });
  }

  checkCheckBoxValue(value: string) {

    this.data.trainings.forEach(element => {
      if (element.trainingId === value) {
        return true
      }
      return false
    });
  }

  getUserId(e: any, user: User) {
    console.log(user.firstName)
    if (e.checked) {
      this.selectedUsers.push(user)
    } else {
      this.selectedUsers = this.selectedUsers.filter((element) => element.id != user.id)
    }
    console.log(this.selectedUsers)
  }

  updateTrainings({ id, fromDate, toDate, trainings }) {
    console.log(trainings)
    this.dashboardService.updateTrainings({ id, fromDate, toDate, trainings }).subscribe((response) => console.log(response))
    this.dialogRef.close()
    this.snackBar.open(this.labels.lblTrainingAssigned, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
  }
}
