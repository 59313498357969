import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HostListener } from "@angular/core";
import { DataServiceService } from '../data-service.service'
import { LoginService } from '../login.service'
import { User } from '../../../../../Leste 3.0/models/User'
import { WebRequestService } from 'src/app/web-request.service';
import { Training } from 'src/app/models/training.model';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth-service/auth.service';
import { UserLanguageService } from '../services/user-language-service/user-language.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserDashboardService } from '../user-dashboard.service';
import { filter, share } from 'rxjs/operators';
import { Unit } from 'src/app/models/unit.model';
import { LocalStorageService } from '../services/localStorage-service/local-storage.service';
import { TestQuestionScore } from 'src/app/models/testQuestionScore.model';
import { MatDialog } from '@angular/material/dialog';
import { UserBeforeTestMessageComponent } from '../user-training/user-before-test-message/user-before-test-message.component';
import { UserBeforePreTestComponent } from '../pre-test/user-before-pre-test/user-before-pre-test.component'
import { MainHeaderComponent } from 'src/app/shared/user-components/main-header/main-header.component';
import { UserDashboardComponent } from 'src/app/layouts/user-dashboard/user-dashboard.component';
import { Test } from 'src/app/models/test.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { MessageBeforeZoomTestComponent } from '../zoom-test/message-before-zoom-test/message-before-zoom-test.component';
import { Meeting } from 'src/app/models/meeting.model';

@Component({
  selector: 'app-user-dashboard-content',
  templateUrl: './user-dashboard-content.component.html',
  styleUrls: ['./user-dashboard-content.component.scss'],
  host: { '(click)': 'onClick()' }
})
export class UserDashboardContentComponent implements OnInit {

  test$: Observable<Test>
  user: User
  userSub: Subscription;

  lang
  langSub: Subscription;
  
  labels;
  labelsSub: Subscription;

  userTrainings$: Observable<Training[]>;
  trainings: Training[]
  trainingsSub: Subscription;

  currentUnit$: Observable<Unit>

  toggle: boolean = true
  btnView: String = "Prikaži sve"
  btnInfo: String = "Info"
  btnNastavi: String = "Nastavi"
  lblCourses = "Dodeljeni treninzi"
  lblHeader = "Turn your ambition into a success story"
  numOfUnits
  routerSubscription: Subscription
  colorClass = ""
  backgroundColor
  buttonStyle = ""
  borderStyle
  buttonStyle1
  content = ""
  contentAll = ""
  meetings
  depId
  showTrainings: boolean = true
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter()
  constructor(public dialog: MatDialog, private authService: AuthService, private languageService: UserLanguageService, private router: Router, private userDashboarService: UserDashboardService, private localStorageService: LocalStorageService, private component: UserDashboardComponent,
    public db: AngularFirestore) {

  }

  ngOnInit() {
    this.langSub = this.languageService.lang$.subscribe((lang) => {
      this.lang = lang;
    })

    this.localStorageService.setItem('test', null)
    this.localStorageService.removeItem('minutesLeft')
    this.localStorageService.removeItem('secondsLeft')
    this.localStorageService.removeItem('currentUnit')
    this.colorClass = ""
    this.userSub = this.authService.user$.subscribe((user: User) => {
      this.user = user;
     setTimeout(() => {
       this.depId = this.user?.departmentId
       this.meetings =  this.getZoomMeetings(this.depId)
     }, 300);
    });

    this.labelsSub = this.languageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.userTrainings$ = this.authService.getCurrentUsersTrainings().pipe(share());
    this.trainingsSub = this.authService.usersTrainings$.subscribe((trainings) => {
      this.trainings = trainings;
      
    })
    this.localStorageService.setItem('test', null)
    this.localStorageService.removeItem('survey')
    this.localStorageService.removeItem('pretest')
    this.localStorageService.setItem('currentQuestionIndex', 0)
    this.btnView = this.labels.lblShowAll
    
  }
  ngAfterViewInit() {
    setTimeout(() => window.scroll(0, 0), 0);
  }

  onActivate(event) {
    setTimeout(() => window.scroll(0, 0), 0);
  }

  toggleClass() {
    this.toggle = !this.toggle;
  }

  whatToShow() {
    this.showTrainings = !this.showTrainings
  }

  goToTrainingDetails(training: Training) {

    this.userDashboarService.setTraining(training)
    this.router.navigateByUrl('user/training-details');
  }
  goToTraining(training: Training) {
    if (training.status == 1) {
      this.userDashboarService.setTraining(training)
      this.router.navigateByUrl('user/welcome');
    } else {
      this.userDashboarService.setTraining(training)

      this.userDashboarService.setTraining(training)

      const tr = this.user.trainings.filter((t) => t.trainingId == training.id)[0]

      this.currentUnit$ = this.userDashboarService.getCurrentUnit(training.id).pipe(share());

      if (!tr.pretestFlag && training.pretestId != "") {
        this.test$ = this.userDashboarService.getTestForTraining(this.localStorageService.getItem('pretestId')).pipe(share())
        this.router.navigateByUrl('user/start-pretest');
      }
      else {
        this.router.navigateByUrl('user/training');
      }
      this.changeStatus(training, 2)
    }
  }

  changeStatus(training: Training, status: Number) {
    this.userDashboarService.changeTrainingStatus(training.id, status).subscribe((result) => {
      console.log(result)
    })
  }

  selectLang(lang) {
    this.languageService.setLanguage(lang);
  }


  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }

  getZoomMeetings(depId){
    this.meetings = []
    let meetings = []
    let ref = this.db.firestore.collection('zoomMeetings')
    ref.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        let meeting = change.doc.data()
       if(meeting.departmentId == depId){
        if(change.type == 'modified'){
          let notFilteredMeetings = meetings
          for(let i = 0; i < notFilteredMeetings.length; i++){
            let element = notFilteredMeetings[i].id
            if(element == meeting.id){
              notFilteredMeetings.splice(i, 1)
            }
          }
          console.log("filtered meetings: ", notFilteredMeetings)
        }
        let date = new Date(change.doc.data().correctDate.seconds * 1000)
        var formatDate = date.getDate() +
        "/" + (date.getMonth() + 1) +
        "/" + date.getFullYear() +
        " " + date.getHours() +
        ":" + date.getMinutes()
        meeting.date1 = formatDate
        meetings.push(meeting)
        meetings = meetings.sort((a,b) => (a.started && !b.started ? -1 : 1))
        console.log("MEETINGS")
        console.log(meetings)
       }
      })
      console.log("meetings1")
      this.meetings = meetings
      console.log(this.meetings)
    })
    this.meetings = []
    return meetings
  }

  emptyZoomMeetingsArray(){
    this.meetings = []
    console.log("global zoom meetings: ", this.meetings)
  }

  openTestDialog(): void {
    const dialogRef = this.dialog.open(UserBeforePreTestComponent, {
      disableClose: true,
      width: '350px'
    });

  }

  setNumOfUnits(trainings: Training[]) {
    trainings.forEach((tr) => {
      tr.num_of_units = this.totalNumOfUnits(tr)
    })
  }

  totalNumOfUnits(tr: Training) {
    let allUnits = []
    tr.sections.forEach((s) => {
      s.units.forEach((u) => {
        allUnits.push(u)
      })
    })
    return allUnits.length
  }

  @HostListener("click") onClick() {
    this.component.sideBarOpened = false
  }

  goToZoomTestMessage(meeting: Meeting) {

    let dialogRef = this.dialog.open(MessageBeforeZoomTestComponent,{
      height: "65%",
      width: "25%",
      data: {
        meeting: meeting
      }
    });
  }
}
