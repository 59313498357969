import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-assign-test',
  templateUrl: './assign-test.component.html',
  styleUrls: ['./assign-test.component.scss']
})
export class AssignTestComponent implements OnInit {
  tests
  labels;
  labelsSub: Subscription;
  @ViewChild('assignForm') assignForm:NgForm
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AssignTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.getLang()
    this.fetchTests()
  }

  fetchTests(){
    this.dashboardService.fetchTestsByType(1).subscribe(response=>this.tests=response)
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
      })
  }

  onNoClick(){
    this.dialogRef.close()
  }

  onSubmit(){
    console.log("Pozvano on submit")
    let data = {
      id:this.data.id,
      trainingId: this.data.trainingId,
      testId: this.assignForm.value.test
    }
    console.log(data)

    this.dashboardService.assignTestForSection(data).subscribe(response=>{
      console.log(response)
      this.snackBar.open(this.labels.lblTestAssigned, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      this.dialogRef.close(this.assignForm.value.test)
    })
  }


}
