<div>
    <div class="row"
        style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
        <div class="col-11"></div>
        <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
            <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
        </div>
    </div>
    <h2 mat-dialog-title>{{labels.lblAddTrainingToUser}} {{data.firstName}}</h2>
    <div mat-dialog-content>
        <div *ngIf="users.length == 0">
            <h3 style="font-family: 'Prompt', sans-serif !important;">{{labels.lblNoTraining}}</h3>
        </div>
        <form (ngSubmit)="onSubmit()" #userTrainingsForm="ngForm">

            <mat-form-field appearance="outline">
                <input matInput [(ngModel)]="searchText" (input)="filterUsers()" [ngModelOptions]="{standalone: true}" placeholder="Search">
            </mat-form-field>

            <input name="id" [ngModel]="data.training.id" hidden>
            <div class="row" style="width: 100%;">
                <div class="col-12" style="text-align: left;">
                   <h4 class="kalendarH" style="font-family: 'Prompt', sans-serif;">{{labels.lblTimeLimit}}: ({{labels.lblFrom}} - {{labels.lblTo}})</h4>
                </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <mat-form-field class="kalendar" appearance="none">
                                <input matInput [matDatepicker]="picker" ngModel name="fromDate">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
                            </mat-form-field>
                        </div>
                       
                        <div class="col-12 col-lg-6">
                            <mat-form-field  class="kalendar" appearance="none">
                                <input matInput [matDatepicker]="picker2" ngModel name="toDate">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
            </div>
            <div class="col-sm-12" style="width: 100%; padding-left:0;">
                <div *ngFor="let user of filteredUsers">
                    <mat-checkbox ngModel name="user-training" [value]="user" 
                        [checked]="true" class="example-margin checkbox" (change)="getUserId($event,user)">
                        {{user.firstName}} {{user.lastName}}
                    </mat-checkbox>
                </div>
                <div style="margin: auto;">
                    <!-- <button type="button" *ngIf="trainings.length > 0" style="margin: auto" class="noBtn" mat-button (click)="onNoClick()">{{labels.lblNo}}</button> -->
                    <button type="button" *ngIf="users.length == 0" class="noBtn"
                        style="float:right; padding-right: 10px; padding-left: 10px; margin-right: 0px !important"
                        mat-button (click)="onNoClick()">{{labels.lblOk}}</button>
                    <button type="button" class="btn" *ngIf="users.length > 0"
                        style="float:right; padding-right: 10px; padding-left: 10px; margin-right: 0px !important"
                        mat-button (click) = "onSubmit()">{{labels.lblAdd}}</button>
                </div>
            </div>


        </form>
    </div>