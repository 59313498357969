import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { DeleteDialogComponent } from '../../admin-user/delete-dialog/delete-dialog.component';
import { DashboardService } from '../../dashboard.service';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';

@Component({
  selector: 'app-delete-training-dialog',
  templateUrl: './delete-training-dialog.component.html',
  styleUrls: ['./delete-training-dialog.component.scss']
})
export class DeleteTrainingDialogComponent implements OnInit {

  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private dashboardService:DashboardService, public adminLanguageService: AdminLanguageService) { }

  ngOnInit(): void {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
    this.labels = labels;
      })
  }

  onNoClick(){
    this.dialogRef.close()
  }

  deleteTraining(){
    this.dashboardService.deleteUserXtrainings(this.data.user.id, this.data.training.id).subscribe((response)=>{
      console.log(response)
    })
    this.snackBar.open(this.labels.lblTrainingDeleted, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
     this.dialogRef.close()
    
  }
  deleteTestResults(){
    this.dashboardService.deleteUserXTrainingTest(this.data.user.id, this.data.training.id).subscribe((response)=>{
      console.log(response)
    })
    this.snackBar.open(this.labels.lblTrainingDeleted, 'OK', {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000
    })
     this.dialogRef.close()
    
  }

}
