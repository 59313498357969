<div class="row"
  style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important">
  <div class="col-11"></div>
  <div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;">
    <mat-icon style="float:right; cursor: pointer;" (click)="onNoClick()">cancel</mat-icon>
  </div>
</div>
<h1 mat-dialog-title>{{labels.lblCopyTraining}}</h1>
<div mat-dialog-content>
    <form (ngSubmit)="onSubmit()" #insertForm="ngForm">
      <div fxLayout="column">
        <mat-form-field appearance="none" fxFlex="60%">
          <mat-label>{{labels.lblETrainingName}}</mat-label>
          <input matInput ngModel name="name" #name="ngModel" required>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="none">
          <mat-label>{{labels.lblTrainings}}</mat-label>
          <mat-select ngModel name="trainingId" required>
            <mat-option *ngFor="let t of trainings" [value]="t.id">
              {{t.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="err == true" style="margin-bottom:1.5vh !important;">{{labels.lblfieldRequired}}</mat-error>
        </mat-form-field>
        <div mat-dialog-actions>
          <button class="btn" cdkFocusInitial type="submit" style="margin-top: 15px;">{{labels.lblAdd}}</button>
        </div>
      </div>
    </form>
  </div>
