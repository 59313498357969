import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from 'src/app/modules/services/admin-auth-service/admin-auth.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  admin$: Observable<Administrator>;
  routerSubscription: Subscription
  sideBarOpened = false
  constructor(private router: Router, private adminAuthService: AdminAuthService) { }
  url='user/dashboard'
  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {
        document.querySelector('mat-drawer-content').scrollTop = 0;
      });
    this.admin$ = this.adminAuthService.getCurrentAdmin().pipe(share());
  }
  sideBarToggler(){
    this.sideBarOpened=!this.sideBarOpened
  }
}
