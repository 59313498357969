<div class="row" style="width:100%; float: right; text-align: right; margin-top: 0px; padding-top: 0px; margin-right:0px !important"><div class="col-11"></div><div class="col-1 text-right" style="float:right; margin-right:0px; padding-right:0px;"><mat-icon style="float:right; cursor: pointer;" (click) = "onNoClick()">cancel</mat-icon></div></div>
<h1 mat-dialog-title>{{labels.lblCropImage}}</h1>
    <image-cropper style="height: 200px; margin-bottom: 20px; "
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [cropperStaticWidth] = "1440"
    [cropperStaticHeight] = "320"
    format="jpg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>

<div style="text-align: center;">
    <img [src]="croppedImage"  style="width: 100%; height: 100%;" alt = "cropped image"/>
</div>


<div mat-dialog-actions>
    <button class="btn" cdkFocusInitial (click)="saveImage()" style="margin-top: 15px;">{{labels.lblSave}}</button>
</div>
