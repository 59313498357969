import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { HttpClient } from '@angular/common/http'
import { DashboardService } from './../../dashboard.service'
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Department } from './../../../models/department.model'
import { Observable, Subscription } from 'rxjs';
import { Position } from './../../../models/positon.model'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Unit } from './../../../models/unit.model'
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Test } from 'src/app/models/test.model';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';

@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.scss']
})
export class InsertDialogComponent implements OnInit {
  nameControl = new FormControl('', [Validators.required]);
  minPointsControl = new FormControl('', [Validators.required])
  typeControl = new FormControl('', [Validators.required]);
  testType: number;
  required: boolean;
  blockAfterXFails: boolean;
  attempts: number;
  customFailMessage: string;
  showFields: boolean
  @ViewChild('insertForm') insertForm: NgForm
  labels;
  labelsSub: Subscription;
  admin
  adminSub: Subscription
  trainers
  showDurTime: boolean
  constructor(private router: Router, public dialogRef: MatDialogRef<InsertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dashboardService: DashboardService,
    private adminLanguageService: AdminLanguageService,
    public snackBar: MatSnackBar, private adminAuthService: AdminAuthService) { }

  ngOnInit(): void {
    this.showDurTime = false;
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
    });
    this.getTrainers()
    this.getLang()
    this.showFields = false
    this.required = false;
   
  }
  getLang() {
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }
  onNoClick() {
    this.dialogRef.close()
  }
  getTrainers(){
    this.dashboardService.getTrainers().subscribe((trainers)=>{
      this.trainers = trainers
    })
  }
  onSubmit() {
    console.log(this.insertForm.value.trainerId)
    console.log(this.insertForm.value.type)
    console.log(this.insertForm.value.name)
    console.log(this.insertForm.value.minPercentage)
    console.log(this.attempts)
    console.log(this.customFailMessage)
    if (this.insertForm.value.name == "" || this.insertForm.value.type == "" || this.insertForm.value.trainerId == "") {
      // this.errorClass = "errorClass"
      this.snackBar.open(this.labels.lblAllfieldsError, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
    } else {
      let test :Test = this.insertForm.value
      test.required = this.required;
      test.numberOfTestDrops = this.attempts;
      test.customFailMessage = this.customFailMessage
      test.blockAfterXFails = this.blockAfterXFails
      console.log(test)
      if(this.insertForm.value.durationTime){
        test.durationTime = this.insertForm.value.durationTime
      }else{
        test.durationTime = null
      }
      test.dateCreated = new Date()
      this.dashboardService.addTest(test).subscribe((response) => {
        console.log(response)
        this.dialogRef.close()
        this.snackBar.open(this.labels.lblTestInserted, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000  })
          const url = `/admin/test-details/${response}`;
          this.router.navigateByUrl(url);
      })
    }
  }

  showDurationTime(e:any){
    if (e.checked){
      this.showDurTime = true
    }else{
      this.showDurTime = false;
      this.insertForm.controls.durationTime.reset()
    }
  }

  onSelect(value: number) {
    this.testType = value
    this.showFields = true
  }

  toggleRequired() {
    this.required = !this.required;
  }
  toggleBlockAfterXFails() {
    this.blockAfterXFails = !this.blockAfterXFails;
  }
}
