import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpClient} from '@angular/common/http'
import {DashboardService} from './../../dashboard.service'
import {UserDataSource} from './../admin-user.component'
import {User} from './../../../models/user.model'
import { Subscription } from 'rxjs';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface DialogData{
  id:string,
  name:string
}
@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  userData:User[]

  labels;
  labelsSub: Subscription;
  constructor(public snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,private dashboardService:DashboardService, private adminLanguageService: AdminLanguageService) { }


  onNoClick(): void {
   this.dialogRef.close();
  }
  deleteUser(){
    console.log(this.data.id)
      this.dashboardService.deleteUser(this.data.id)
      this.dashboardService.getUsers().subscribe((data)=>{
        this.userData=data as User[]
      })
      this.dialogRef.close();
      this.snackBar.open(this.labels.lblUserDeleted, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'end',
        duration: 5000
      })
      
  }
  ngOnInit(): void {
    this.getLang();
  }

  getLang(){
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
    this.labels = labels;
      })
  }

}
