import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { Training } from 'src/app/models/training.model';
import { Unit } from 'src/app/models/unit.model';
import { User } from 'src/app/models/user.model';
import { VideoScore } from 'src/app/models/videoScore.model';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { AuthService } from 'src/app/modules/services/auth-service/auth.service';
import { LocalStorageService } from 'src/app/modules/services/localStorage-service/local-storage.service';
import { UserDashboardService } from 'src/app/modules/user-dashboard.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  user$: Observable<User>;
  labels$;

  userTrainings$: Observable<Training[]>;
  userScores$ : Observable<VideoScore[]>
  currentUnit$ : Observable<Unit>
  routerSubscription: Subscription
  public sideBarOpened=false
  constructor(private router: Router, private authService: AuthService, private userDashboarService: UserDashboardService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {
        document.querySelector('mat-drawer-content').scrollTop = 0;
      });
    this.user$ = this.authService.getCurrentUser().pipe(share());
    this.userTrainings$ = this.authService.getCurrentUsersTrainings().pipe(share());

    //this.currentUnit$ = this.userDashboarService.getCurrentUnit(this.localStorageService.getItem('trainingId')).pipe(share());
    //document.getElementById('zmmtg-root').style.display = 'none'
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  sideBarToggler(){
    this.sideBarOpened=!this.sideBarOpened
  }

  onActivate(event) {
    window.scroll(0,0);
  }

}
