import { Component, OnInit } from '@angular/core';
import { UserLanguageService } from '../services/user-language-service/user-language.service';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  labelsSub; Subscription
  labels
  constructor(private userLanguageService: UserLanguageService) { }

  ngOnInit(): void {
    this.labelsSub = this.userLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
  }

}
