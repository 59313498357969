import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AdminLanguageService } from '../../services/admin-language-service/admin-language.service';
import * as XLSX from 'ts-xlsx';
import { Department } from 'src/app/models/department.model';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { DashboardService } from '../../dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/models/user.model';
import { Position } from 'src/app/models/positon.model';
import { Administrator } from 'src/app/models/administrator.model';
import { AdminAuthService } from '../../services/admin-auth-service/admin-auth.service';
import { Training } from 'src/app/models/training.model';

@Component({
  selector: 'app-add-list-message',
  templateUrl: './add-list-message.component.html',
  styleUrls: ['./add-list-message.component.scss']
})
export class AddListMessageComponent implements OnInit {
  labels;
  labelsSub: Subscription;
  selectedFile: File
  arrayBuffer: any
  departments: Department[]
  trainings: Training[]
  users: User[]
  @ViewChild('fileUpload')
  myInputVariable: ElementRef;
  lang
  langSub: Subscription;
  positions
  uploading: boolean = false
  admin:Administrator
  adminSub: Subscription

  constructor(public snackBar: MatSnackBar, public adminLanguageService: AdminLanguageService, public dialogRef: MatDialogRef<AddListMessageComponent>, private dashboardService: DashboardService, @Inject(MAT_DIALOG_DATA) public data, private adminAuthService: AdminAuthService) { }

  ngOnInit(): void {
    this.langSub = this.adminLanguageService.lang$.subscribe((lang) => {
      console.log(lang)
      this.lang = lang;
    })
    this.labelsSub = this.adminLanguageService.labels$.subscribe((labels) => {
      this.labels = labels;
    })
    this.getAdmin()
    this.getUsers()
    this.getPositions()
    this.getTrainings()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]
    console.log(this.selectedFile.name)
  }


  onSaveUsers(e) {
    e.preventDefault();
    this.uploading = true
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let users: any = XLSX.utils.sheet_to_json(worksheet)
      if (users.length == 0) {
        this.uploading = false
        this.snackBar.open(this.labels.lblExcelError, 'OK', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          duration: 5000
        })
        this.selectedFile = null
        this.reset()
      }
      else {
        let lang = ''
        if (users[0]["Korisničko ime"] != null) {
          lang = 'srb'
        }
        else {
          lang = 'eng'
        }
        console.log(lang)
        if (this.checkUsersFromExcel(users, lang)) {
          console.log(this.checkUsersFromExcel(users, lang))
          users.forEach(u => {
            if (lang == "srb") {
              if (this.data.departmentId != "") {
                u["Sektor/grupa"] = this.data.departmentId
              }
              else {
                u["Sektor/grupa"] = this.getDepartmentId(u["Sektor/grupa"])
              }
              u["Generičko radno mesto"] = this.getPositionId(u["Generičko radno mesto"])
              if (u["Datum rođenja"]) {
                u["Datum rođenja"] = this.getDateFromString(u["Datum rođenja"])
              }
              u["Treninzi"] = this.getTrainingsIds(u["Treninzi"])

            }
            else {
              if (this.data.departmentId != "") {
                u["Sector/group"] = this.data.departmentId
              }
              else {
                u["Sector/group"] = this.getDepartmentId(u["Sector/group"])
              }
              u["Generic work place"] = this.getPositionId(u["Generic work place"])
              if (u["Date of birth"]) {
                u["Date of birth"] = this.getDateFromString(u["Date of birth"])
              }
              u["Trainings"] = this.getTrainingsIds(u["Trainings"])
            }

          });
          this.dashboardService.insertUsersFromExcel(users, lang).subscribe(response => {
            console.log(response)
            this.selectedFile = null
            this.dialogRef.close()
          })
        }
        else {
          this.uploading = false
          this.snackBar.open(this.labels.lblExcelError, 'OK', {
            verticalPosition: 'top',
            horizontalPosition: 'end',
            duration: 5000
          })
          this.selectedFile = null
          this.reset()
        }
      }

    }
    fileReader.readAsArrayBuffer(this.selectedFile);
  }

  checkEmail(email: string, user:any): boolean {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (regexp.test(email)) {
      if (!this.emailAlreadyExists(email, user)) {
        return true
      }
      else {
        return true
      }
    }
    else {
      return false
    }
  }

  checkDateOfBirth(date: string): boolean {
    console.log(date)
    if (!date) {
      console.log('nije unet datum')
      return true
    }
    else {
      let regexp = new RegExp("^([0][1-9]|[12][0-9]|3[01]).([0][1-9]|1[012]).(19|20)[0-9][0-9].$")
      var d = "" + date.toString()
      if (regexp.test(d)) {
        console.log('prosao regex')
        console.log(d)
        date = this.createCorrectDateFormat(date)
        console.log(date)
        let re = /\./gi;
        let result = date.replace(re, "/");
        const editedText = result.slice(0, -1)
        console.log(editedText)
        var usersDate = Date.parse(editedText)
        console.log(usersDate)
        var todaysDate = Date.now()
        if (usersDate > todaysDate) {
          console.log('pogresan datum1')
          return false
        }
        else {
          return true
        }
      }
      else {
        console.log('pogresan datum2')
        return false
      }
    }
  }

  getDepartments() {
    this.dashboardService.fetchAllDepartments().subscribe((response) => {
      this.departments = response as Department[]
    })
  }
  getDepartments1() {
    this.dashboardService.fetchAdminsDepartments(this.admin.departmentId).subscribe((response) => {
      this.departments = response as Department[]
    })
  }
  getPositions() {
    this.dashboardService.fetchPositions().subscribe((response) => {
      this.positions = response as Position[]
    })
  }

  getUsers() {
    this.dashboardService.getUsers().subscribe((response) => {
      this.users = response as User[]
    })
  }

  getTrainings(){
    this.dashboardService.getTrainings().subscribe((response) => {
      this.trainings = response as Training[]
      console.log(this.trainings)
    })
  }

  checkDepartment(departmentName: string): boolean {
    if (this.data.departmentId != "") {
      return true
    }
    else {
      for (let index = 0; index < this.departments.length; index++) {
        if (this.departments[index].name == departmentName)
          return true
      }
      return false
    }
  }
  checkPosition(positionName: string): boolean {
    if (!positionName) {
      return true
    }
    for (let index = 0; index < this.positions.length; index++) {
      if (this.positions[index].positionName == positionName) {
        console.log(positionName)
        return true
      }

    }
    console.log('Pogresna pozicija')
    return false
  }

  checkUsersFromExcel(users, lang): boolean {
    console.log(users.length)
    if (users.length == 0) {
      return false
    }
    else {
      console.log('lang: ' + lang)
      if(this.data.flag){
        if (lang == "srb") {
          for (let index = 0; index < users.length; index++) {
            if (!users[index]["Korisničko ime"] || !users[index]["Ime"] || !users[index]["Prezime"] || !users[index]["Pozicija"]  || !users[index]["Lozinka"] || !users[index]["Email"]) {
              return false
            }
            else {
              if (!this.checkDateOfBirth(users[index]["Datum rođenja"])  || !this.checkPosition(users[index]["Generičko radno mesto"]) || !this.checkEmail(users[index]["Email"], users[index]) || this.userNameAlreadyExists(users[index]["Korisničko ime"], users[index])) {
                return false
              }
            }
  
          }
          return true
        }
        else {
          for (let index = 0; index < users.length; index++) {
            if (!users[index]["Username"] || !users[index]["First name"] || !users[index]["Last name"] || !users[index]["Position"]  || !users[index]["Password"] || !users[index]["Email"]) {
              return false
            }
            else {
              if (!this.checkDateOfBirth(users[index]["Date of birth"])  || !this.checkPosition(users[index]["Generic work place"]) || !this.checkEmail(users[index]["Email"], users[index]) || this.userNameAlreadyExists(users[index]["Username"], users[index])) {
                return false
              }
            }
          }
          return true
        }
      }
      else{
        if (lang == "srb") {
          for (let index = 0; index < users.length; index++) {
            if (!users[index]["Korisničko ime"] || !users[index]["Ime"] || !users[index]["Prezime"] || !users[index]["Pozicija"] || !users[index]["Sektor/grupa"] || !users[index]["Lozinka"] || !users[index]["Email"]) {
              return false
            }
            else {
              if (!this.checkDateOfBirth(users[index]["Datum rođenja"]) || !this.checkDepartment(users[index]["Sektor/grupa"]) || !this.checkPosition(users[index]["Generičko radno mesto"]) || !this.checkEmail(users[index]["Email"], users[index]) || this.userNameAlreadyExists(users[index]["Korisničko ime"], users[index])) {
                return false
              }
            }
  
          }
          return true
        }
        else {
          for (let index = 0; index < users.length; index++) {
            if (!users[index]["Username"] || !users[index]["First name"] || !users[index]["Last name"] || !users[index]["Position"] || !users[index]["Sector/group"] || !users[index]["Password"] || !users[index]["Email"]) {
              return false
            }
            else {
              if (!this.checkDateOfBirth(users[index]["Date of birth"]) || !this.checkDepartment(users[index]["Sector/group"]) || !this.checkPosition(users[index]["Generic work place"]) || !this.checkEmail(users[index]["Email"], users[index]) || this.userNameAlreadyExists(users[index]["Username"], users[index])) {
                return false
              }
            }
          }
          return true
        }
      }
      
    }


  }

  getDepartmentId(depName: string): string {
    for (let index = 0; index < this.departments.length; index++) {
      if (depName == this.departments[index].name) {
        return this.departments[index].id
      }
    }
    return ""
  }

  getPositionId(posName: string): string {
    console.log(posName)
    for (let index = 0; index < this.positions.length; index++) {
      console.log(this.positions[index])
      if (posName == this.positions[index].positionName) {
        return this.positions[index].id
      }
    }
    return ""
  }

  getDateFromString(dateString: string): Date {
    dateString = this.createCorrectDateFormat(dateString)
    let re = /\./gi;
    let result = dateString.replace(re, "/");
    const editedText = result.slice(0, -1)
    var usersDate = new Date(editedText)
    return usersDate
  }

  emailAlreadyExists(email: string, user:any): boolean {
    for (let index = 0; index < this.users?.length; index++) {
      if (this.users[index].email == email) {
        user["exists"] = this.users[index].id
        return true
      }
    }
    user["exists"] = 0
    return false
  }

  userNameAlreadyExists(userName: string, user:any): boolean {
    for (let index = 0; index < this.users?.length; index++) {
      if (this.users[index].userName == userName) {
        user["exists"] = this.users[index].id
        return false
      }
    }
    user["exists"] = 0
    return false
  }

  reset() {
    this.myInputVariable.nativeElement.value = "";
  }

  getAdmin(){
    this.adminSub = this.adminAuthService.admin$.subscribe((admin) => {
      this.admin = admin;
      console.log(this.admin)
      setTimeout(() => {
        if(admin.headAdmin){
          this.getDepartments();
        }
        else{
          this.getDepartments1();
        }
      }, 100);
    });
  }

  createCorrectDateFormat(date:string):string{
    let array = date.split('.')
    let days = array[0]
    let months = array[1]
    let year = array[2]
    /* if(days.charAt(0)=='0'){
      days.substring(1,days.length)
    }
    if(months.charAt(0)=='0'){
      months.substring(1,months.length)
    } */
    let newDate = months+"."+days+"."+year+"."
    console.log(newDate)
    return newDate

  }

  getTrainingsIds(trainings:string){
    let trainingIds = []
    let trainingNames = trainings?.split(',')
    trainingNames?.forEach(trName => {
      trName = trName.trim()
      let training = this.trainings.find(training => training.name == trName)
      trainingIds.push(training.id)
    })
    return trainingIds

  }




}
